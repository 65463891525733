import React, { Component } from 'react';
import './member.css';
import PopUp from "../round/PopUp";
import CheckboxIcoNot from "./memberimage/CheckboxIcoNot.png";
import CheckboxIcoHave from "./memberimage/CheckboxIcoHave.png";
import Triangle from "../round/Triangle";
import InfoPopUp from "../round/InfoPopUp";
import {get, post} from "../../ajax/tools";

class PeTeamManage extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            TeamUser:{},//团队信息
            TeamType:'',//团队类型

            TeamName:'',//修改的团队名称
            TeamNameNote:null,
            TeamNowNameState:'有',

            PeId:'',
            PeIdPeIdNote:'No',
            PeIdPeIdNoteWord:'未搜索到相关信息，请您检查ID是否正确',

            PeSend:'',
            PeSendNum: 0,
            PeSendNumArr: [],

            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
            CheckboxIcoToggleOn: true,
            SendState:'申请状态',
            SendStateToggleOn: true,
            SendStateDisplay: 'none',
            SendStateArr:['全部信息','等待回复邀请','已接受邀请','未接受邀请','自申请(未处理)','自申请(同意)','自申请(拒绝)',],
            SendStateAllNum:0,
            SendStateAgreeNum:0,
            SendStateRefuseNum:0,
            SelfApplyNum:0,


            TeamPeInfoArr:[],
            AddPeInfoArr:[],
            TeamPeInfo:[],

            AdminOrInfo:{},

            ManageTeamState:'管理团队',//管理团队或继续添加

            AddPick:'',
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            TeamType:history.TeamType,
            AddPick:history.AddPick,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        if(history.AddPick === 'Add'){
            this.setState({
                ManageTeamState:'继续添加',
            });
        }else if(history.AddPick === 'Pick'){
            this.setState({
                ManageTeamState:'管理团队',
            });
        }
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    User:res.data[0],
                });
            }
        });
        let TeamTypeNum = history.TeamType === '竞技团队' ? 0 : (history.TeamType === '表演团队' ? 1 : '');
        get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeamUser:res.data[0],
                });
                get({url:'flyteam/get_flyteam_info1/'+res.data[0].id}).then((res) => {
                    if(res.errno === '200'){
                        let TeamPeInfoBrr = [];
                        let SendStateAll = [];
                        let SendStateAgree = [];
                        let SendStateRefuse = [];
                        let SelfApply = [];
                        for(let i=0;i<res.data.length;i++){
                            let TeamPeInfoType = res.data[i].fm_type;
                            let TeamPeInfoStatus = res.data[i].fm_status;
                            if(res.data[i].user_id !== this.state.User.id){
                                TeamPeInfoBrr.push(res.data[i]);
                                if(TeamPeInfoType === 0){
                                    SendStateAll.push(res.data[i]);
                                    if(TeamPeInfoStatus === 1){
                                        SendStateAgree.push(res.data[i]);
                                    }else if(TeamPeInfoStatus === 2){
                                        SendStateRefuse.push(res.data[i]);
                                    }
                                }else if(TeamPeInfoType === 1){
                                    SelfApply.push(res.data[i]);
                                }
                            }
                        }
                        this.setState({
                            TeamPeInfoArr:TeamPeInfoBrr,
                            SendStateAllNum:SendStateAll,
                            SendStateAgreeNum:SendStateAgree,
                            SendStateRefuseNum:SendStateRefuse,
                            SelfApplyNum:SelfApply,
                        });
                    }else{
                        this.setState({
                            TeamPeInfoArr:[],
                        });
                    }
                });
            }
        });
        this.setState({
            TeamType:history.TeamType,
            AddPick:history.AddPick,
        });
        document.onclick=this.AllClick;//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
            SendStateToggleOn: true,
            SendStateDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };




    //选择管理团队还是继续添加
    ManageTeam(){
        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
        get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeamUser:res.data[0],
                });
                get({url:'flyteam/get_flyteam_info1/'+res.data[0].id}).then((res) => {
                    if(res.errno === '200'){
                        let TeamPeInfoBrr = [];
                        let SendStateAll = [];
                        let SendStateAgree = [];
                        let SendStateRefuse = [];
                        let SelfApply = [];
                        for(let i=0;i<res.data.length;i++){
                            let TeamPeInfoType = res.data[i].fm_type;
                            let TeamPeInfoStatus = res.data[i].fm_status;
                            if(res.data[i].user_id !== this.state.User.id){
                                TeamPeInfoBrr.push(res.data[i]);
                                if(TeamPeInfoType === 0){
                                    SendStateAll.push(res.data[i]);
                                    if(TeamPeInfoStatus === 1){
                                        SendStateAgree.push(res.data[i]);
                                    }else if(TeamPeInfoStatus === 2){
                                        SendStateRefuse.push(res.data[i]);
                                    }
                                }else if(TeamPeInfoType === 1){
                                    SelfApply.push(res.data[i]);
                                }
                            }
                        }
                        this.setState({
                            TeamPeInfoArr:TeamPeInfoBrr,
                            SendStateAllNum:SendStateAll,
                            SendStateAgreeNum:SendStateAgree,
                            SendStateRefuseNum:SendStateRefuse,
                            SelfApplyNum:SelfApply,
                        });
                    }else{
                        this.setState({
                            TeamPeInfoArr:[],
                        });
                    }
                });
            }
        });
        this.setState({
            ManageTeamState:'管理团队',
        });
    };
    KeepAdd(){
        this.setState({
            ManageTeamState:'继续添加',
            PeSend:'',
            AddPeInfoArr:[],
        });
    };







    //点击发送多个申请
    PePleaseSend=(event)=>{
        let TeamUser = this.state.TeamUser;
        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
        let PeSendNumArrId = [];
        for(let i=0;i<event.length;i++){
            let PeInfoArrListId = event[i].id;
            PeSendNumArrId.push(PeInfoArrListId);
        }
        post({
            url:'flyteam/invite_member',
            data:{
                type:TeamTypeNum,
                fi_id:TeamUser.id,
                member:PeSendNumArrId,
            },
        }).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    PeSend:'More',
                    PopUp:'有两个自定按钮的提示框',
                    MessageTip:'成功发送'+this.state.PeSendNum+'封邀请函,是否继续？',
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'发送邀请函失败，请稍后重试！',
                });
            }
        });
    };


    //委托管理员
    AdminOrClick=(item,index)=>{
        this.setState({
            AdminOrInfo:item,
            AdminOr:'UpData',
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'您将'+this.state.TeamType+'所有权转移给'+item.real_name+'？',
        });
    };

    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        let TeamUser = this.state.TeamUser;
        let AdminOrInfo = this.state.AdminOrInfo;
        if(event === 'CrossOut'){
            if(this.state.PeSend === 'More'){
                let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
                get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TeamUser:res.data[0],
                        });
                        get({url:'flyteam/get_flyteam_info1/'+res.data[0].id}).then((res) => {
                            if(res.errno === '200'){
                                let TeamPeInfoBrr = [];
                                let SendStateAll = [];
                                let SendStateAgree = [];
                                let SendStateRefuse = [];
                                let SelfApply = [];
                                for(let i=0;i<res.data.length;i++){
                                    let TeamPeInfoType = res.data[i].fm_type;
                                    let TeamPeInfoStatus = res.data[i].fm_status;
                                    if(res.data[i].user_id !== this.state.User.id){
                                        TeamPeInfoBrr.push(res.data[i]);
                                        if(TeamPeInfoType === 0){
                                            SendStateAll.push(res.data[i]);
                                            if(TeamPeInfoStatus === 1){
                                                SendStateAgree.push(res.data[i]);
                                            }else if(TeamPeInfoStatus === 2){
                                                SendStateRefuse.push(res.data[i]);
                                            }
                                        }else if(TeamPeInfoType === 1){
                                            SelfApply.push(res.data[i]);
                                        }
                                    }
                                }
                                this.setState({
                                    TeamPeInfoArr:TeamPeInfoBrr,
                                    SendStateAllNum:SendStateAll,
                                    SendStateAgreeNum:SendStateAgree,
                                    SendStateRefuseNum:SendStateRefuse,
                                    SelfApplyNum:SelfApply,
                                });
                            }else{
                                this.setState({
                                    TeamPeInfoArr:[],
                                });
                            }
                        });
                    }
                });
                this.setState({
                    PopUp:'',
                    ManageTeamState:'管理团队',
                });
            }else if(this.state.AdminOr === 'UpData'){
                this.setState({
                    PopUp:'',
                });
            }
        }else if(event === 'Confirm'){
            if(this.state.PeSend === 'More'){
                this.setState({
                    PopUp:'',
                    AddPeInfoArr:[],
                });
            }else if(this.state.AdminOr === 'UpData'){
                post({
                    url:'flyteam/up_flyteam_admin',
                    data:{
                        sub_id:AdminOrInfo.user_id,
                        fi_id:TeamUser.id,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.props.PeTeamCreateOutBackClick('OutAndShow')
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'转移团队失败，请稍后重试！',
                        });
                    }
                });
                this.setState({
                    PopUp:'',
                });
            }

        }
    };





    //点击修改团队名称按钮
    ManageTeamName(){
        this.setState({
            TeamNowNameState:'修改',
        });
    };
    //输入团队的名字
    TeamNameChange(event){
        if (event.target.value !== ''){
            post({
                url:'flyteam/check_flyteam_name',
                data:{
                    name:event.target.value,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TeamNameNote: true,
                    });
                }else {
                    this.setState({
                        TeamNameNote: false,
                    });
                }
            });
        }else if(!event.target.value){
            this.setState({
                TeamNameNote: null,
            });
        }
        this.setState({
            TeamName: event.target.value,
            PeId:'',
        });
    };
    //修改团队名称
    UpDataTeamName(){
        let TeamUser = this.state.TeamUser;
        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
        post({
            url:'flyteam/up_flyteam_name',
            data:{
                fi_id:TeamUser.id,
                name:this.state.TeamName,
            },
        }).then((res) => {
            if(res.errno === '200'){
                get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TeamUser:res.data[0],
                            TeamNowNameState:'有',
                            PopUp:'提示弹窗',
                            MessageTip:'修改保存成功！',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'修改失败，请稍后重试！',
                });
            }
        });
    };










    //输入个人的ID
    PeIdChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            PeId: event.target.value,
        });
    };
    //点击重置备注
    PeIdClick(){
        this.setState({
            PeIdPeIdNote:'No',
        });
    };
    //点击搜索人员
    PeJoinInput=()=>{
        let PeId = this.state.PeId;
        let User = this.state.User;
        let AddPeInfoArr = this.state.AddPeInfoArr;
        let AddPeInfoArrListIdNumArr = [];
        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
        if (PeId !== ''){
            if(PeId !== User.yy_num){
                get({url:'reuse/get_personal_info/'+PeId}).then((res) => {
                    if(res.errno === '200'){
                        let TheUser = res.data[0];
                        post({
                            url:'flyteam/check_is_flyteam_member',
                            data:{
                                sub_id:TheUser.id,
                                type:TeamTypeNum,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                const PeInfoCbArr=[];
                                if(AddPeInfoArr.length !==0){
                                    for(let i=0;i<AddPeInfoArr.length;i++){
                                        let AddPeInfoArrListId = AddPeInfoArr[i].id;
                                        AddPeInfoArrListIdNumArr.push(AddPeInfoArrListId);
                                    }
                                    if(AddPeInfoArrListIdNumArr.includes(TheUser.id)){
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'您已经添加过该人员！',
                                        });
                                    }else {
                                        let PeTheList = TheUser;
                                        PeTheList.Checkbox = false;
                                        AddPeInfoArr.push(PeTheList);
                                    }
                                    this.setState({
                                        AddPeInfoArr:AddPeInfoArr,
                                    });
                                }else {
                                    const PeTheList = TheUser;
                                    PeTheList.Checkbox = false;
                                    PeInfoCbArr.push(PeTheList);
                                    this.setState({
                                        AddPeInfoArr:PeInfoCbArr,
                                    });
                                }
                            }else {
                                this.setState({
                                    PeIdPeIdNote:'Yes',
                                    PeIdPeIdNoteWord:'您已对他发送过邀请!',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PeIdPeIdNote:'Yes',
                            PeIdPeIdNoteWord:'未搜索到相关信息，请您检查ID是否正确!',
                        });
                    }
                });
            }else {
                this.setState({
                    PeIdPeIdNote:'Yes',
                    PeIdPeIdNoteWord:'您为团队创建者，已自动添加进此团队！',
                });
            }
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入人员ID或身份证号！',
            });
        }
    };






    CheckboxIcoAll=(event)=>{
        let PeInfoCbArr=[];
        let PeSendNumArr=[];
        for(let i=0;i<event.length;i++){
            const PeList= event[i];
            if(this.state.CheckboxIcoToggleOn === false){
                PeList.Checkbox = false;
                PeSendNumArr.push(PeList);
                PeInfoCbArr.push(PeList);
            }else if(this.state.CheckboxIcoToggleOn === true){
                PeList.Checkbox = true;
                PeSendNumArr.push(PeList);
                PeInfoCbArr.push(PeList);
            }
        }
        this.setState(prevState => ({
            CheckboxIcoToggleOn: !prevState.CheckboxIcoToggleOn,
            CheckboxIcoBgImg:PeSendNumArr.length === 0 ? 'url('+CheckboxIcoNot+')' : (prevState.CheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
            PeSendNum:prevState.CheckboxIcoToggleOn ? PeSendNumArr.length : 0,
            AddPeInfoArr:PeInfoCbArr,
            PeSendNumArr:prevState.CheckboxIcoToggleOn ? PeSendNumArr : [],
        }));
    };
    CheckboxIco=(item,index)=>{
        const PeInfoList = this.state.AddPeInfoArr;
        PeInfoList[index]['Checkbox'] = item.Checkbox !== true;
        const PeSendNumArr = [...this.state.PeSendNumArr];
        if(item.Checkbox === true){
            PeSendNumArr.push(item);
            this.setState({
                PeSendNumArr:PeSendNumArr,
                PeSendNum:PeSendNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<PeSendNumArr.length;i++){
                if(PeSendNumArr[i].id === item.id){
                    PeSendNumArr.splice(i,1);
                }
            }
            this.setState({
                PeSendNumArr:PeSendNumArr,
                PeSendNum:PeSendNumArr.length,
            });
        }
        this.setState({
            AddPeInfoArr:PeInfoList,
            CheckboxIcoToggleOn:this.state.AddPeInfoArr.length !== PeSendNumArr.length,
        });
    };





    //点击显示下拉菜单
    SendStateDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            SendStateToggleOn: !prevState.SendStateToggleOn,
            SendStateDisplay:prevState.SendStateToggleOn ? 'block' : 'none',
        }));
    };

    //点击所选择的状态
    SendStateDownNav=(item,index)=>{
        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
        get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeamUser:res.data[0],
                });
                get({url:'flyteam/get_flyteam_info1/'+res.data[0].id}).then((res) => {
                    if(res.errno === '200'){
                        let TeamPeInfoBrr = [];
                        let SendStateAll = [];
                        let SendStateAgree = [];
                        let SendStateRefuse = [];
                        let SelfApply = [];
                        for(let i=0;i<res.data.length;i++){
                            let TeamPeInfoType = res.data[i].fm_type;
                            let TeamPeInfoStatus = res.data[i].fm_status;
                            if(res.data[i].user_id !== this.state.User.id){
                                TeamPeInfoBrr.push(res.data[i]);
                                if(TeamPeInfoType === 0){
                                    SendStateAll.push(res.data[i]);
                                    if(TeamPeInfoStatus === 1){
                                        SendStateAgree.push(res.data[i]);
                                    }else if(TeamPeInfoStatus === 2){
                                        SendStateRefuse.push(res.data[i]);
                                    }
                                }else if(TeamPeInfoType === 1){
                                    SelfApply.push(res.data[i]);
                                }
                            }
                        }
                        let TeamPeInfoDataArr = [];
                        for(let i=0;i<TeamPeInfoBrr.length;i++){
                            let TeamPeInfoType = TeamPeInfoBrr[i].fm_type;
                            let TeamPeInfoStatus = TeamPeInfoBrr[i].fm_status;
                            if(item === '全部信息'){
                                TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                            }else if(item === '等待回复邀请'){
                                if (TeamPeInfoType === 0 && TeamPeInfoStatus === 0){
                                    TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                                }
                            }else if(item === '已接受邀请'){
                                if (TeamPeInfoType === 0 && TeamPeInfoStatus === 1){
                                    TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                                }
                            }else if(item === '未接受邀请'){
                                if (TeamPeInfoType === 0 && TeamPeInfoStatus === 2){
                                    TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                                }
                            }else if(item === '自申请(未处理)'){
                                if (TeamPeInfoType === 1 && TeamPeInfoStatus === 0){
                                    TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                                }
                            }else if(item === '自申请(同意)'){
                                if (TeamPeInfoType === 1 && TeamPeInfoStatus === 1){
                                    TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                                }
                            }else if(item === '自申请(拒绝)'){
                                if (TeamPeInfoType === 1 && TeamPeInfoStatus === 2){
                                    TeamPeInfoDataArr.push(TeamPeInfoBrr[i]);
                                }
                            }
                        }
                        this.setState({
                            SendStateAllNum:SendStateAll,
                            SendStateAgreeNum:SendStateAgree,
                            SendStateRefuseNum:SendStateRefuse,
                            SelfApplyNum:SelfApply,
                            TeamPeInfoArr:TeamPeInfoDataArr,
                            SendState:item,
                            SendStateToggleOn: true,
                            SendStateDisplay: 'none',
                        });
                    }else{
                        this.setState({
                            TeamPeInfoArr:[],
                        });
                    }
                });
            }
        });
    };




    //添加人员查看
    DetailsClick=(item,index)=>{
        this.setState({
            TeamPeInfo:item,
            TeamPePopUp:'添加查看',
        });
    };


    //查看操作
    DetailsViews=(item,index)=>{
        if(item.fm_type === 0){
            if(item.fm_status === 0){
                this.setState({
                    TeamPeInfo:item,
                    TeamPePopUp:'添加查看',
                });
            }else if(item.fm_status === 1){
                this.setState({
                    TeamPeInfo:item,
                    TeamPePopUp:'踢他',
                });
            }else if(item.fm_status === 2){
                this.setState({
                    TeamPeInfo:item,
                    TeamPePopUp:'删除',
                });
            }
        }else if(item.fm_type === 1){
            if(item.fm_status === 0){
                this.setState({
                    TeamPeInfo:item,
                    TeamPePopUp:'操作',
                });
            }else if(item.fm_status === 1){
                this.setState({
                    TeamPeInfo:item,
                    TeamPePopUp:'踢他',
                });
            }else if(item.fm_status === 2){
                this.setState({
                    TeamPeInfo:item,
                    TeamPePopUp:'删除',
                });
            }
        }
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    //查看人员详情弹窗
    InfoPopUpBack=(event)=>{
        if(event.type === 'CrossOut'){
            this.setState({
                TeamPePopUp:'',
            });
        }else if(event.type === 'Confirm'){
            if(event.BackInfo.name === '返回'){
                this.setState({
                    TeamPePopUp:'',
                });
            }else if(event.BackInfo.name === '拒绝'){
                post({
                    url:'flyteam/accept_reject',
                    data:{
                        fm_id:event.UserInfo.fm_id,
                        status:2,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
                        get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    TeamUser:res.data[0],
                                });
                                get({url:'flyteam/get_flyteam_info1/'+res.data[0].id}).then((res) => {
                                    if(res.errno === '200'){
                                        let TeamPeInfoBrr = [];
                                        let SendStateAll = [];
                                        let SendStateAgree = [];
                                        let SendStateRefuse = [];
                                        let SelfApply = [];
                                        for(let i=0;i<res.data.length;i++){
                                            let TeamPeInfoType = res.data[i].fm_type;
                                            let TeamPeInfoStatus = res.data[i].fm_status;
                                            if(res.data[i].user_id !== this.state.User.id){
                                                TeamPeInfoBrr.push(res.data[i]);
                                                if(TeamPeInfoType === 0){
                                                    SendStateAll.push(res.data[i]);
                                                    if(TeamPeInfoStatus === 1){
                                                        SendStateAgree.push(res.data[i]);
                                                    }else if(TeamPeInfoStatus === 2){
                                                        SendStateRefuse.push(res.data[i]);
                                                    }
                                                }else if(TeamPeInfoType === 1){
                                                    SelfApply.push(res.data[i]);
                                                }
                                            }
                                        }
                                        this.setState({
                                            TeamPeInfoArr:TeamPeInfoBrr,
                                            SendStateAllNum:SendStateAll,
                                            SendStateAgreeNum:SendStateAgree,
                                            SendStateRefuseNum:SendStateRefuse,
                                            SelfApplyNum:SelfApply,
                                            TeamPePopUp:'',
                                        });
                                    }
                                });
                            }
                        });
                    }else {
                        this.setState({
                            TeamPePopUp:'',
                            PopUp:'提示弹窗',
                            MessageTip:'操作失败，请稍后重试！',
                        });
                    }
                });
            }else if(event.BackInfo.name === '同意'){
                let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
                post({
                    url:'flyteam/check_is_flyteam_member',
                    data:{
                        sub_id:event.UserInfo.user_id,
                        type:TeamTypeNum,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        post({
                            url:'flyteam/accept_reject',
                            data:{
                                fm_id:event.UserInfo.fm_id,
                                status:1,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
                                get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
                                    if(res.errno === '200'){
                                        this.setState({
                                            TeamUser:res.data[0],
                                        });
                                        get({url:'flyteam/get_flyteam_info1/'+res.data[0].id}).then((res) => {
                                            if(res.errno === '200'){
                                                let TeamPeInfoBrr = [];
                                                let SendStateAll = [];
                                                let SendStateAgree = [];
                                                let SendStateRefuse = [];
                                                let SelfApply = [];
                                                for(let i=0;i<res.data.length;i++){
                                                    let TeamPeInfoType = res.data[i].fm_type;
                                                    let TeamPeInfoStatus = res.data[i].fm_status;
                                                    if(res.data[i].user_id !== this.state.User.id){
                                                        TeamPeInfoBrr.push(res.data[i]);
                                                        if(TeamPeInfoType === 0){
                                                            SendStateAll.push(res.data[i]);
                                                            if(TeamPeInfoStatus === 1){
                                                                SendStateAgree.push(res.data[i]);
                                                            }else if(TeamPeInfoStatus === 2){
                                                                SendStateRefuse.push(res.data[i]);
                                                            }
                                                        }else if(TeamPeInfoType === 1){
                                                            SelfApply.push(res.data[i]);
                                                        }
                                                    }
                                                }
                                                this.setState({
                                                    TeamPeInfoArr:TeamPeInfoBrr,
                                                    SendStateAllNum:SendStateAll,
                                                    SendStateAgreeNum:SendStateAgree,
                                                    SendStateRefuseNum:SendStateRefuse,
                                                    SelfApplyNum:SelfApply,
                                                    TeamPePopUp:'',
                                                });
                                            }
                                        });
                                    }
                                });
                            }else {
                                this.setState({
                                    TeamPePopUp:'',
                                    PopUp:'提示弹窗',
                                    MessageTip:'操作失败，请稍后重试！',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            TeamPePopUp:'',
                            PopUp:'提示弹窗',
                            MessageTip:event.UserInfo.real_name+'已加入其他团队！',
                        });
                    }
                });
            }else if(event.BackInfo.name === '移出团队'){
                get({url:'flyteam/del_member/'+event.UserInfo.fm_id}).then((res) => {
                    if(res.errno === '200'){
                        let TeamPeInfoArr = this.state.TeamPeInfoArr;
                        for(let i=0;i<TeamPeInfoArr.length;i++){
                            if(TeamPeInfoArr[i].user_id === event.UserInfo.user_id){
                                TeamPeInfoArr.splice(i,1);
                            }
                        }
                        this.setState({
                            TeamPePopUp:'',
                            TeamPeInfoArr:TeamPeInfoArr,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'删除失败！',
                        });
                    }
                });
            }else if(event.BackInfo.name === '删除'){
                get({url:'flyteam/del_member/'+event.UserInfo.fm_id}).then((res) => {
                    if(res.errno === '200'){
                        let TeamPeInfoArr = this.state.TeamPeInfoArr;
                        for(let i=0;i<TeamPeInfoArr.length;i++){
                            if(TeamPeInfoArr[i].user_id === event.UserInfo.user_id){
                                TeamPeInfoArr.splice(i,1);
                            }
                        }
                        this.setState({
                            TeamPePopUp:'',
                            TeamPeInfoArr:TeamPeInfoArr,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'删除失败！',
                        });
                    }
                });
            }
        }
    };



    render() {
        let ButtonType = [
            {
                name:'返回',
                bgColor:'#6EC2FF',
                color:'#FFFFFF',
            }
        ];
        let ButtonCao = [
            {
                name:'拒绝',
                bgColor:'#BB0000',
                color:'#FFFFFF',
            },
            {
                name:'同意',
                bgColor:'#1ABB00',
                color:'#FFFFFF',
            },
        ];
        let ButtonTi = [
            {
                name:'移出团队 ',
                bgColor:'#BB0000',
                color:'#FFFFFF',
            },
        ];
        let ButtonShan = [
            {
                name:'删除',
                bgColor:'#BB0000',
                color:'#FFFFFF',
            },
        ];
        return (
                <div className={'PeTeamModule'} style={{paddingTop:56,height:612,}}>
                    <div className={'PeTeamCreateInfoBox'} style={{height:100,}}>
                        <div className={'PeTeamCreateInfoConBox'}>
                            <div className={'PeTeamManageInfoConTit'}>团队名称</div>
                            <div className={'PeTeamManageInfoCon clearfix'}>
                                {
                                    this.state.TeamNowNameState === '有'&&
                                    <div>
                                        <div className={'PeTeamManageInfoConWod'}>{this.state.TeamUser.name}</div>
                                        <div className={'PeTeamManageInfoConWod Btn'} onClick={this.ManageTeamName.bind(this)}>修改</div>
                                    </div>
                                }
                                {
                                    this.state.TeamNowNameState === '修改'&&
                                    <div>
                                        <input className={'TeamNameInput'}
                                               type={'text'}
                                               name={'TeamName'}
                                               style={{height:40}}
                                               value={this.state.TeamName}
                                               onChange={this.TeamNameChange.bind(this)}
                                               placeholder={'请输入创建的团队名称'}
                                               autoComplete={'off'}
                                        />
                                        {
                                            this.state.TeamNameNote === false &&
                                            <div className={'PeTeamCreateNote'}>名称已存在</div>
                                        }
                                        {
                                            this.state.TeamNameNote === true &&
                                            <div style={{float:'left',}}>
                                                <div className={'TeamNameNoteIco'} style={{marginRight:20,}}> </div>
                                                <button className={'TwoWordBtn'} onClick={this.UpDataTeamName.bind(this)}>确认</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'PeTeamCreateInfoConBox'}>
                            <div className={'PeTeamManageInfoConTit'}>团队ID</div>
                            <div className={'PeTeamManageInfoCon'}>{this.state.TeamUser.team_num}</div>
                        </div>
                    </div>
                    <div className={'PeJoinTameTitBtnBox'} style={{margin:'20px 0 0 0',}}>
                        <button className={'TeamPleaseSendBtn'}
                                onClick={this.ManageTeam.bind(this)}
                                style={{float:'left',minWidth:110,marginRight:20,}}
                        >管理团队</button>
                        <button className={'TeamPleaseSendBtn'}
                                onClick={this.KeepAdd.bind(this)}
                                style={{float:'left',minWidth:110,marginRight:20,}}
                        >继续添加</button>
                    </div>
                    <div className={'PeTeamCreateInfoBox'} style={{height:20,margin:'20px 0',}}>
                        <div className={'PeTeamManageInfoConTit'} style={{width:90,height:20,lineHeight:'20px',margin:0,color:'#EB6100',}}>{this.state.ManageTeamState}</div>
                        <div className={'GoOnSolid'}> </div>
                    </div>



                    {
                        this.state.ManageTeamState === '管理团队'&&
                        <div>
                            <div className={'PeJoinTameTitBtnBox'}>
                                <div className={'PeTeamManagePeInfo'} style={{color:'#000000',}}>自申请({this.state.SelfApplyNum.length})</div>
                                <div className={'PeTeamManagePeInfo'} style={{color:'#BB0000',}}>未接受邀请({this.state.SendStateRefuseNum.length})</div>
                                <div className={'PeTeamManagePeInfo'} style={{color:'#1ABB00',}}>已接受邀请({this.state.SendStateAgreeNum.length})</div>
                                <div className={'PeTeamManagePeInfo'} style={{color:'#787878',}}>已邀请({this.state.SendStateAllNum.length})</div>
                            </div>
                            <div className={'TeamListHeaderBox'}>
                                <div className={'TeamHeaderList'} style={{width:100,}}>序号</div>
                                <div className={'TeamHeaderList'} style={{width:140,}}>姓名</div>
                                <div className={'TeamHeaderList'} style={{width:172,}}>ID</div>
                                <div className={'TeamHeaderList'} style={{width:180,}}>
                                    <div className={'TableHeaderInfoListBox'} title={'申请状态'} onClick={this.SendStateDown.bind(this)}>
                                        <div className={'TableHeaderDownIco'}>
                                            <Triangle Direction={'down'} Color={'#0488CA'} Width={'7.5px'} Height={'13px'} Top={'10px'} Right={'0'}/>
                                        </div>
                                        <div className={'TableHeaderDownTitle'}>{this.state.SendState}</div>
                                    </div>
                                    <div className={'TableHeaderDownNavBox'} style={{display:this.state.SendStateDisplay}}>
                                        {
                                            this.state.SendStateArr.map((item,index) =>
                                                <div className={'TableHeaderDownNav'}
                                                     key={index}
                                                     onClick={() => this.SendStateDownNav(item,index)}
                                                     style={this.state.SendState === item ? {backgroundColor:'#8DCEFD'} : {}}>
                                                    {item}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={'TeamHeaderList'} style={{width:150,}}>详情</div>
                                <div className={'TeamHeaderList'} style={{width:140,}}>权限转移</div>
                            </div>
                            <div className={'TeamListConBox MemberOverflow'} style={{height:290,}}>
                                {
                                    this.state.TeamPeInfoArr.map((item,index) =>
                                        <div className={'TeamListCon'} key={index}>
                                            <div className={'TeamConList'} style={{width:100,}}>{index+1}</div>
                                            <div className={'TeamConList'} style={{width:140,}}>{item.real_name}</div>
                                            <div className={'TeamConList'} style={{width:172,}}>{item.yy_num}</div>
                                            <div className={'TeamConList'}
                                                 style={{width:180,
                                                     color:item.fm_type === 0 ?
                                                         item.fm_status === 0 ? '#6EC2FF' :
                                                             (item.fm_status === 1 ? '#1ABB00' :
                                                                 (item.fm_status === 2 ? '#BB0000' : ''))
                                                         :
                                                         (item.fm_type === 1 ?
                                                             (item.fm_status === 0 ? '#6EC2FF' :
                                                                 (item.fm_status === 1 ? '#1ABB00':
                                                                     (item.fm_status === 2 ? '#BB0000':'')))
                                                             : '#969696'),
                                                 }}
                                            >
                                                {
                                                    item.fm_type === 0 ?
                                                        item.fm_status === 0 ? '等待回复邀请' :
                                                            (item.fm_status === 1 ? '已接受邀请' :
                                                                (item.fm_status === 2 ? '未接受邀请' : ''))
                                                        :
                                                        (item.fm_type === 1 ?
                                                            (item.fm_status === 0 ? '自申请(未处理)' :
                                                                (item.fm_status === 1 ? '自申请(同意)':
                                                                    (item.fm_status === 2 ? '自申请(拒绝)':'')))
                                                            : '未邀请')
                                                }
                                            </div>
                                            <div className={'TeamConList'} style={{width:150,}}>
                                                <span className={'DetailsViews'} onClick={() => this.DetailsViews(item,index)}>查看/操作</span>
                                            </div>
                                            {
                                                item.fm_status === 1 &&
                                                <div className={'TeamConList'} style={{width:140,}}>
                                                    <div className={'AdminOr'}
                                                         onClick={() => this.AdminOrClick(item,index)}
                                                    >转移队长</div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.ManageTeamState === '继续添加'&&
                        <div>
                            <div className={'PeTeamCreateInfoBox'} style={{height:66,}}>
                                <div className={'PeTeamCreateInfoConBox'} style={{width:'auto',float:'left',margin:0,}}>
                                    <div className={'PeTeamManageInfoConTit'} style={{margin:0,}}>成员ID</div>
                                    <div className={'TeamIdInputBox clearfix'}>
                                        <input className={'TeamIdInput'}
                                               type={'text'}
                                               name={'PeId'}
                                               value={this.state.PeId}
                                               onChange={this.PeIdChange.bind(this)}
                                               onClick={this.PeIdClick.bind(this)}
                                               placeholder={'请输入正确的个人ID号'}
                                               autoComplete={'off'}
                                        />
                                        <button className={'TeamIdInputBtn'} onClick={this.PeJoinInput.bind(this)}>搜索</button>
                                    </div>
                                </div>
                                {
                                    this.state.PeIdPeIdNote === 'Yes' &&
                                    <div className={'TeamIdInputNote'} style={{float:'right',marginRight:40,}}>{this.state.PeIdPeIdNoteWord}</div>
                                }
                            </div>
                            <div className={'PeJoinTameTitBtnBox'}>
                                <button className={'TeamPleaseSendBtn'}
                                        onClick={() => this.PePleaseSend(this.state.AddPeInfoArr)}
                                        style={{float:'left',}}
                                >发送申请（{this.state.PeSendNum}）</button>
                            </div>
                            <div className={'TeamListHeaderBox'}>
                                <div className={'TeamHeaderList'} style={{width:40,}}>
                                    <div className={'TeamHeaderCheckbox'}
                                         onClick={() => this.CheckboxIcoAll(this.state.AddPeInfoArr)}>
                                        {
                                            this.state.CheckboxIcoToggleOn === false &&
                                            <div className={'TeamHeaderCheckboxActive'}> </div>
                                        }
                                    </div>
                                </div>
                                <div className={'TeamHeaderList'} style={{width:210,}}>序号</div>
                                <div className={'TeamHeaderList'} style={{width:214,}}>姓名</div>
                                <div className={'TeamHeaderList'} style={{width:214,}}>ID</div>
                                <div className={'TeamHeaderList'} style={{width:212,}}>详情</div>
                            </div>
                            <div className={'TeamListConBox MemberOverflow'} style={{height:252,}}>
                                {
                                    this.state.AddPeInfoArr.map((item,index) =>
                                        <div className={'TeamListCon'} key={index}>
                                            <div className={'TeamConList'} style={{width:40,}}>
                                                <div className={'CheckboxIco'}
                                                     style={{backgroundImage:item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',marginTop:7,}}
                                                     onClick={() => this.CheckboxIco(item,index)}>
                                                </div>
                                            </div>
                                            <div className={'TeamConList'} style={{width:210,}}>{index+1}</div>
                                            <div className={'TeamConList'} style={{width:214,}}>{item.real_name}</div>
                                            <div className={'TeamConList'} style={{width:214,}}>{item.yy_num}</div>
                                            <div className={'TeamConList'} style={{width:212,}}>
                                                <span className={'DetailsViews'} onClick={() => this.DetailsClick(item,index)}>查看</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '有两个选择按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '有两个自定按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'PeTeamCreate'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.TeamPePopUp === '添加查看'&&
                        <InfoPopUp
                            UserInfo={this.state.TeamPeInfo}
                            InfoPopUpBack={this.InfoPopUpBack.bind(this)}
                            ButtonType={ButtonType}
                        />
                    }
                    {
                        this.state.TeamPePopUp === '操作'&&
                        <InfoPopUp
                            UserInfo={this.state.TeamPeInfo}
                            InfoPopUpBack={this.InfoPopUpBack.bind(this)}
                            ButtonType={ButtonCao}
                        />
                    }
                    {
                        this.state.TeamPePopUp === '踢他'&&
                        <InfoPopUp
                            UserInfo={this.state.TeamPeInfo}
                            InfoPopUpBack={this.InfoPopUpBack.bind(this)}
                            ButtonType={ButtonTi}
                        />
                    }
                    {
                        this.state.TeamPePopUp === '删除'&&
                        <InfoPopUp
                            UserInfo={this.state.TeamPeInfo}
                            InfoPopUpBack={this.InfoPopUpBack.bind(this)}
                            ButtonType={ButtonShan}
                        />
                    }
                </div>
        );
    }
}


export default PeTeamManage;
