import React, { Component } from 'react';
import './round.css';


class Pagination extends Component {

    constructor(props){
        super(props);
        this.state = {
            CurrentPage: 1 , //当前页码
            GroupCount: 5, //页码分组，显示7个页码，其余用省略号显示
            TotalPage: '' ,//总页数
            TotalSize: '' ,//总条数
            PageSize: '', //每页显示数
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        const TotalSize = history.TotalSize;
        const PageSize = history.PageSize;
        this.setState({
            TotalPage:parseInt((TotalSize + PageSize - 1)/PageSize),
        });
    };
    componentDidMount=function() {
        const history = this.props;
        const TotalSize = history.TotalSize;
        const PageSize = history.PageSize;
        this.setState({
            TotalPage:parseInt((TotalSize + PageSize - 1)/PageSize),
        });
    };


    CreatePageList(){
        const {CurrentPage, GroupCount,TotalPage} = this.state;
        let pages = [];
        //下一页
        pages.push(
            <li className={CurrentPage === TotalPage ? 'PageNoMore2' : 'PageListBox2'} onClick={this.nextPageHandeler.bind(this)} key={TotalPage + 1}>
                <div className={'NextIco2 css-icon icon-arrow-left'}> </div>
            </li>
        );
        pages.push(
            <li className={'ActivePage2Box clearfix'} onClick={this.pageClick.bind(this)} key={GroupCount}>
                <div className={'ActivePage2'}>{CurrentPage}</div>
                <div className={'ActivePageBorder'}>/</div>
                <div className={'ActivePageAll'}>{TotalPage}</div>
            </li>
        )
        //上一页
        pages.push(
            <li className={CurrentPage === 1 ? 'PageNoMore2' : 'PageListBox2'} onClick={this.prevPageHandeler.bind(this)} key={0}>
                <div className={'PrevIco2 css-icon icon-arrow-left'}> </div>
            </li>
        );
        // if (TotalPage <= 10) {
        //     //总页码小于等于10时，全部显示出来
        //     for (let i=1; i <= TotalPage; i++) {
        //         pages.push(<li className={CurrentPage === i ? 'ActivePage' : 'PageListBox2'} onClick={this.pageClick.bind(this,i)} key={i}>{i}</li>)
        //     }
        // } else {
        //     //总页码大于10时，部分显示
        //     //第一页
        //     pages.push(<li className={CurrentPage === 1 ? 'ActivePage' : 'PageListBox2'} onClick={this.pageClick.bind(this, 1)} key={1}>1</li>);
        //     //前面省略号(当当前页码比分组的页码大时显示省略号)
        //     if (CurrentPage >= GroupCount) {
        //         pages.push(<li className={'PageListBox2'} key={-1}>···</li>)
        //     }
        //     //非第一页和最后一页显示
        //     let pageLength = 0;
        //     if (GroupCount + CurrentPage > TotalPage) {
        //         pageLength = TotalPage;
        //         for (let i=pageLength-4; i<pageLength; i++) {
        //             if (i <= TotalPage - 1 && i > 1) {
        //                 pages.push(<li className={CurrentPage === i ? 'ActivePage' : 'PageListBox2'} onClick={this.pageClick.bind(this, i)} key={i}>{i}</li>)
        //             }
        //         }
        //     } else {
        //         pageLength = GroupCount + CurrentPage;
        //         for (let i=CurrentPage; i<pageLength; i++) {
        //             if (i <= TotalPage - 1 && i > 1) {
        //                 pages.push(<li className={CurrentPage === i ? 'ActivePage' : 'PageListBox2'} onClick={this.pageClick.bind(this, i)} key={i}>{i}</li>)
        //             }
        //         }
        //     }
        //     //后面省略号
        //     if (TotalPage - 4 >= CurrentPage + 1) {
        //         pages.push(<li className={'PageListBox2'} key={-2}>···</li>)
        //     }
        //     //最后一页
        //     pages.push(<li className={CurrentPage === TotalPage ? 'ActivePage' : 'PageListBox2'} onClick={this.pageClick.bind(this, TotalPage)} key={TotalPage}>{TotalPage}</li>)
        // }
        return pages;
    }

    //页码点击
    pageClick(CurrentPage){
        const {GroupCount} = this.state.GroupCount;
        const getCurrentPage = this.props.pageCallbackFn;
        //当当前页码大于分组的页码时，使当前页前面显示两个页码
        if (CurrentPage >= GroupCount) {
            this.setState({
                StartPage: CurrentPage - 2,
            })
        }
        if (CurrentPage < GroupCount) {
            this.setState({
                StartPage: 1,
            })
        }
        //第一页时重新设置分组的起始页
        if (CurrentPage === 1) {
            this.setState({
                StartPage: 1,
            })
        }
        this.setState({
            CurrentPage
        });
        //将当前页码返回父组件
        getCurrentPage(CurrentPage)
    }

    //上一页事件
    prevPageHandeler() {
        let {CurrentPage} = this.state;
        CurrentPage = CurrentPage-1;
        if (CurrentPage === 0) {
            return false
        }
        this.pageClick(CurrentPage)
    }

    //下一页事件
    nextPageHandeler() {
        let {CurrentPage,TotalPage} = this.state;
        CurrentPage = CurrentPage+1;
        if (CurrentPage > TotalPage) {
            return false
        }
        this.pageClick(CurrentPage)
    }

    render() {
        return (
                <div>
                    <ul className={'PaginationBox2 clearfix'}>
                        {this.CreatePageList()}
                    </ul>
                </div>
        );
    }
}


export default Pagination;
