import React, { Component } from 'react';
import './showfile.css';
import AuthHeader from "../handf/AuthHeader";


class AddMine extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :window.innerHeight,
        };
    }
    componentDidMount=function() {

    };


    render() {
        return (
            <div className={'MainBox'} style={{backgroundImage:'none',}}>
                <AuthHeader/>
                <div className={'MainContent'}>
                    <div className={'FriendLinkBox clearfix'}>
                        {/*<div className={'MineSloGon'}> </div>*/}
                        <div className={'MineWordBox'} style={{marginBottom:20,width:800,}}>
                            {/*<div className={'MineWordTitle'} style={{textIndent:0,fontSize:18,marginTop:20,}}>成为鹰扬科技的一员</div>*/}
                            {/*<div className={'MineWord'}>职位名称：模拟飞行讲师</div>*/}
                            {/*<div className={'MineWord'}>职位类型：全职/兼职</div>*/}
                            {/*<div className={'MineWord'}>工作职能：</div>*/}
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>负责在学校及各个社会团体推广模拟飞行运动</div>*/}
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>负责模拟飞行课程的教研及教学</div>*/}
                            {/*<div className={'MineWord'}>职位要求：</div>*/}
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>1.本科及以上学历</div>*/}
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>2.口齿清晰，普通话标准，沟通能力强</div>*/}
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>3.热爱航空，热爱飞行，对航空知识有一定的了解</div>*/}
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>4.具有教师资格证，航空类院校、师范类院校毕业或研究生在读者优先</div>*/}
                            {/*<div className={'MineWord'}>联系人：杨老师</div>*/}
                            {/*<div className={'MineWord'}>电话：18611701180</div>*/}
                            <div className={'MineWordTitle'} style={{textIndent:0,fontSize:18,marginTop:20,}}>成为合作伙伴</div>
                            <div className={'MineWord'}>如果您有志于为将祖国建设为航空强国贡献自己的一份力量，希望从事航空科普、训练，模拟飞行训练设备制造，模拟飞行相关软件研发等行业，无论您是企业、社会团体还是个人，我们都诚挚的欢迎您加入模拟飞行这个大家庭。</div>
                            <div className={'MineWord'} style={{marginTop:40,}}>合作伙伴身份列表：</div>
                            <div className={'MineWord'} style={{textIndent:'4em',}}>中国航协青少年航校</div>
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>中国航协全国模拟飞行实训基地</div>*/}
                            <div className={'MineWord'} style={{textIndent:'4em',}}>中国航协全国模拟飞行授权训练中心</div>
                            <div className={'MineWord'} style={{textIndent:'4em',}}>中国航协模拟飞行认证设备提供商</div>
                            {/*<div className={'MineWord'} style={{textIndent:'4em',}}>中国航协模拟飞行教练员</div>*/}
                            <div className={'MineWord'} style={{textIndent:0,marginTop:20,}}>以上合作伙伴的申请条件和流程，请访问主页相关板块有详细说明。</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AddMine;