import React, { Component } from 'react';

class JumpPage extends Component {

    constructor(props){
        super(props);
        this.state = {
            router:null,
            TheRouter:null,
            sonRouter:null,
        };
    }
    componentDidMount=function() {
        //关于路由地址的处理
        let Route = this.props.location.pathname;
        let index = Route.lastIndexOf("/");//'/'最后出现的位置
        let TwoIndex = Route.indexOf ("_");//'_'第一次出现的位置
        let ThreeIndex = Route.lastIndexOf ("_");//'_'最后出现的位置
        if(index === 0 && TwoIndex !== ThreeIndex){
            if (Route.substring(0,TwoIndex) === '/game'){
                let info = {
                    type:Route.substring(TwoIndex+1,ThreeIndex),
                    spec:Route.substring(ThreeIndex+1),
                };
                sessionStorage.setItem('Jump',JSON.stringify(info));
                const {history} = this.props;
                history.push('/game');
            }else {

            }
        }
    };


    render() {
        return (
                <div> </div>
        );
    }
}


export default JumpPage;