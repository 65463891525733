import React, { Component } from 'react';
import './round.css';


class Second extends Component {

    constructor(props){
        super(props);
        this.state = {
            Second:60,//验证码按钮屏蔽时间
        };
    }
    componentDidMount=function() {
        let Second = this.state.Second;
        setInterval(() => {
            let i = Second--;
            if (i !== 0 && i > 0){
                this.setState({ Second: i, });
            }else if(i === 0){
                this.props.SecondBackChange('Stop');
                clearInterval();
            }
        }, 1000);
    };

    render() {
        return (
                <div style={{float:'right',}}>({this.state.Second}s)</div>
        );
    }
}


export default Second;
