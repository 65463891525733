import React, { Component } from 'react';
import './exam.css';
import PopUp from "../round/PopUp";
import {get} from "../../ajax/tools";



class BoundCoach extends Component {

    constructor(props){
        super(props);
        this.state = {
            IdNum:'',
            ExamBoundCoachInputId:'',//输入的教练员Id
            CoachIdNum:'',//教练员Id号
            CoachId:'',//教练员Id
            CoachName:'',//教练员姓名
            CoachPhoto:'',//教练员照片
            BoundCoachState:'未绑定',//绑定教练员状态，值为“未绑定”，“查询到”，“已绑定”，
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        if(history.BoundCoachIdNum && history.BoundCoachIdNum !== ''){
            get({url:'reuse/get_coach_info/'+history.BoundCoachIdNum}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        CoachId:res.data[0].id,
                        CoachIdNum:res.data[0].yy_num,
                        CoachName:res.data[0].real_name,
                        CoachPhoto:res.data[0].image,
                        BoundCoachState: '已绑定',
                    });
                }else {
                    this.setState({
                        BoundCoachState: '未绑定',
                    });
                }
            });
        }else {
            this.setState({
                BoundCoachState: '未绑定',
            });
        }
        this.setState({
            IdNum:history.IdNum,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        if(history.BoundCoachIdNum && history.BoundCoachIdNum !== ''){
            get({url:'reuse/get_coach_info/'+history.BoundCoachIdNum}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        CoachId:res.data[0].id,
                        CoachIdNum:res.data[0].yy_num,
                        CoachName:res.data[0].real_name,
                        CoachPhoto:res.data[0].image,
                        BoundCoachState: '已绑定',
                    });
                }else {
                    this.setState({
                        BoundCoachState: '未绑定',
                    });
                }
            });
        }else {
            this.setState({
                BoundCoachState: '未绑定',
            });
        }
        this.setState({
            IdNum:history.IdNum,
        });
    };


    //输入的教练员Id
    ExamBoundCoachInputId(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            ExamBoundCoachInputId: event.target.value,
        });
    };
    //查询到教练员
    ExamBoundCoachInputBtn(){
        let ExamBoundCoachInputId =this.state.ExamBoundCoachInputId;
        if (ExamBoundCoachInputId !== ''){
            get({url:'reuse/get_coach_info/'+ExamBoundCoachInputId}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        CoachId:res.data[0].id,
                        CoachIdNum:res.data[0].yy_num,
                        CoachName:res.data[0].real_name,
                        CoachPhoto:res.data[0].image,
                        BoundCoachState: '查询到',
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'未查询到该教练员信息！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入教练员ID！',
            });
        }
    };
    //绑定教练员
    ExamBoundCoachBtn(){
        this.setState({
            PopUp:'绑定教练员弹窗',
            MessageTip:'确认是否绑定该教练员？',
        });
    };
    //解绑教练员
    ExamBoundCoachNotBtn(){
        this.setState({
            PopUp:'解绑教练员弹窗',
            MessageTip:'确认是否解绑该教练员？',
        });
    };


    //绑定教练员弹窗点击的逻辑处理
    ChildBackBoundCoach=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.props.BoundCoachBack({CoachId:this.state.CoachId,CoachIdNum:this.state.CoachIdNum,});
            this.setState({
                BoundCoachState: '已绑定',
                PopUp:'',
            });
        }
    };
    //解绑教练员弹窗点击的逻辑处理
    ChildBackBoundCoachNot=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.props.BoundCoachBack({CoachId:'',CoachIdNum:'',});
            this.setState({
                ExamBoundCoachInputId:'',
                BoundCoachState: '未绑定',
                PopUp:'',
            });
        }
    };

    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };



    render() {
        return (
            <div>
                <div className={'ExamBoundCoach'}>
                    <div className={'ExamBoundCoachTitBox'}>
                        <div className={'ExamBoundCoachTitle'}>绑定教练员</div>
                        {
                            this.state.BoundCoachState !== '已绑定'&&
                            <div>
                                <input className={'ExamBoundCoachInputId'}
                                       type={'text'}
                                       name={'ExamBoundCoachId'}
                                       value={this.state.ExamBoundCoachInputId}
                                       onChange={this.ExamBoundCoachInputId.bind(this)}
                                       placeholder={'请输入教练员ID'}
                                       autoComplete={'off'}
                                />
                                <button className={'ExamBoundCoachInputBtn'} onClick={this.ExamBoundCoachInputBtn.bind(this)}>搜索</button>
                            </div>
                        }
                    </div>
                    {
                        this.state.BoundCoachState === '未绑定'&&
                        <div className={'ExamBoundCoachNot'}>请输入教练员ID,绑定教练员</div>
                    }
                    {
                        this.state.BoundCoachState === '查询到'&&
                        <div className={'ExamBoundCoachInfoBox'}>
                            <div className={'ExamBoundCoachInfoPhoto'}>
                                <img alt={''}
                                     src={this.state.CoachPhoto}
                                />
                            </div>
                            <div className={'ExamBoundCoachInfo'}>
                                <div className={'ExamBoundCoachInfoWord'}>
                                    <div className={'ExamBoundCoachInfoWordTit'}>姓名：</div>
                                    <div className={'ExamBoundCoachInfoWordCon'}>{this.state.CoachName}</div>
                                </div>
                                <div className={'ExamBoundCoachInfoWord'}>
                                    <div className={'ExamBoundCoachInfoWordTit'}>ID：</div>
                                    <div className={'ExamBoundCoachInfoWordCon'}>{this.state.CoachIdNum}</div>
                                </div>
                            </div>
                            <button className={'ExamBoundCoachBtn'} onClick={this.ExamBoundCoachBtn.bind(this)}>绑定</button>
                        </div>
                    }
                    {
                        this.state.BoundCoachState === '已绑定'&&
                        <div className={'ExamBoundCoachInfoBox'}>
                            <div className={'ExamBoundCoachInfoPhoto'}>
                                <img alt={'UserPhoto'}
                                     src={this.state.CoachPhoto}
                                />
                            </div>
                            <div className={'ExamBoundCoachInfo'}>
                                <div className={'ExamBoundCoachInfoWord'}>
                                    <div className={'ExamBoundCoachInfoWordTit'}>姓名：</div>
                                    <div className={'ExamBoundCoachInfoWordCon'}>{this.state.CoachName}</div>
                                </div>
                                <div className={'ExamBoundCoachInfoWord'}>
                                    <div className={'ExamBoundCoachInfoWordTit'}>ID：</div>
                                    <div className={'ExamBoundCoachInfoWordCon'}>{this.state.CoachIdNum}</div>
                                </div>
                            </div>
                            <button className={'ExamBoundCoachBtn'} onClick={this.ExamBoundCoachNotBtn.bind(this)}>解绑</button>
                        </div>
                    }
                </div>
                {
                    this.state.PopUp === '绑定教练员弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackBoundCoach.bind(this)}
                        type={'成功'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '解绑教练员弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackBoundCoachNot.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default BoundCoach;
