import React, { Component } from 'react';
import './approve.css';
import Pagination from "../round/Pagination";
import {get} from "../../ajax/tools";


class AppRecord extends Component {

    constructor(props){
        super(props);
        this.state = {
            RecordArr:[],
            RecordBrr:[],
            RecordIdt:'',
            RecordArrLength:0,
            RecordBrrLength:'',
            PageSize:'',
            CurrentPage: 1,
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            RecordIdt:history.RecordIdt,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        this.setState({
            RecordIdt:history.RecordIdt,
        });
        let IdtTypeId = null;
        switch(history.RecordIdt){
            case '设备':
                IdtTypeId = 1;
                break;
            case '训练中心':
                IdtTypeId = 2;
                break;
            case '青少年航校':
                IdtTypeId = 3;
                break;
            case '实训基地':
                IdtTypeId = 4;
                break;
            default:
                IdtTypeId = null;
        }
        let H = window.innerHeight-370 > 330 ? window.innerHeight-370 :330;
        let PageSize = Math.ceil(H/52)
        get({url:'agency/read_suborg_apply/'+IdtTypeId}).then((res) => {
            if(res.errno === '200'){
                let RecordArr = res.data;
                let KeyStart = (this.state.CurrentPage-1)*PageSize;
                let KeyEnd = this.state.CurrentPage*PageSize;
                const RecordList = [];
                if(KeyEnd <= RecordArr.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const RecordArrList = RecordArr[i];
                        RecordList.push(RecordArrList)
                    }
                }else {
                    for(let i=KeyStart; i<RecordArr.length; i++){
                        const RecordArrList = RecordArr[i];
                        RecordList.push(RecordArrList)
                    }
                }
                this.setState({
                    RecordArr:res.data,
                    RecordBrr:RecordList,
                    RecordArrLength:res.data.length,
                    RecordBrrLength:res.data.length,
                    PageSize:PageSize,
                });
            }else {
                this.setState({
                    RecordBrrLength:0,
                });
            }
        });
    };


    getCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const RecordList = [];
        if(KeyEnd <= this.state.RecordArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const RecordArrList = this.state.RecordArr[i];
                RecordList.push(RecordArrList)
            }
        }else {
            for(let i=KeyStart; i<this.state.RecordArr.length; i++){
                const RecordArrList = this.state.RecordArr[i];
                RecordList.push(RecordArrList)
            }
        }
        this.setState({
            RecordBrr:RecordList,
        });
    }



    AppState=(item,index)=>{
        let ClickInfo = {
            ClickInfo:item.isyearcheck === 0 ?  '认证' : (item.isyearcheck === 1 ?  '年检' : ''),
            Id:item.id,
            Process:item.process,
            IdtTypeId:item.type,
        };
        this.props.AppStateClick(ClickInfo);
    };









    render() {
        return (
            <div className={'ApproveMainBox'}>
                {
                    this.state.RecordArr.length !== 0 &&
                    <div>
                        <div className={'ApproveHaveHeader clearfix'}>
                            <div className={'ApproveHaveHeaderList'} style={{width:180,}}>申请时间</div>
                            <div className={'ApproveHaveHeaderList'} style={{width:420,}}>{this.state.RecordIdt}申请书上传文件名称</div>
                            <div className={'ApproveHaveHeaderList'} style={{width:180,}}>申请类别</div>
                            <div className={'ApproveHaveHeaderList'} style={{width:180,}}>申请状态</div>
                        </div>
                        <div className={'AppRecordMainBox'} style={{height:window.innerHeight-370 > 330 ? window.innerHeight-370 :330,marginBottom:10,}}>
                            {
                                this.state.RecordBrr.map((item,index) =>
                                    <div className={'ApproveHaveCon clearfix'} style={{height:18,}} key={index}>
                                        <div className={'ApproveHaveConList'} style={{width:180,margin:0,}}>{item.apply_time}</div>
                                        <div className={'ApproveHaveConList'} style={{width:420,margin:0,}}>{item.apply_form_relname.replace('.pdf','')}</div>
                                        <div className={'ApproveHaveConList'} style={{width:180,margin:0,}}>
                                            {item.isyearcheck === 0 ?  '认证' : (item.isyearcheck === 1 ?  '年检' : '')}
                                        </div>
                                        <div className={'ApproveHaveConList'} style={{width:180,margin:0,}}>
                                            <span className={'NotYearOrNotApp'} onClick={() => this.AppState(item,index)}>
                                                查看审核进度>>
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <Pagination
                            TotalSize={this.state.RecordArrLength}
                            PageSize={this.state.PageSize}
                            pageCallbackFn={this.getCurrentPage.bind(this)}
                        />
                    </div>
                }
                {
                    this.state.RecordBrrLength === 0 &&
                    <div className={'NotApproveConBoxTit'} style={{paddingTop:200,}}>您还没有任何{this.state.NowNavListZhName}申请记录</div>
                }
            </div>
        );
    }
}


export default AppRecord;
