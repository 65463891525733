import React, { Component } from 'react';
import './inquire.css';
import {get} from "../../ajax/tools";


class TrainDetails extends Component {

    constructor(props){
        super(props);
        this.state = {
            TrainId:'',
            TrainArr:{},
            TrainAddress:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        this.setState({
            TrainId:nextProps.TrainId,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        get({url:'agency/train_inst_info/'+history.TrainId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TrainArr:res.data[0],
                    TrainAddress:res.data[0].province === res.data[0].city ?
                        res.data[0].city+res.data[0].county+res.data[0].address :
                        res.data[0].province+res.data[0].city+res.data[0].county+res.data[0].address,
                });
            }
        });
        this.setState({
            TrainId:history.TrainId,
        });
    };


    BackButton=()=>{
        this.props.BackButton('BackBtn');
    };



    render() {
        return (
                <div className={'DetailsPackageBox'} style={{minHeight:window.innerHeight-194 > 500 ? window.innerHeight-194 :500,}}>
                    <div className={'DetailsPackageTop'}>
                        <div className={'DetailsBackBox clearfix'} onClick={this.BackButton.bind(this)}>
                            <div className={'DetailsBackIco'}> </div>
                            <div className={'DetailsBackWord'}>返回</div>
                        </div>
                    </div>
                    <div className={'TaAndScDetailsMainBox clearfix'}>
                        <div className={'TaAndScDetailsLeftBox'}>
                            <div className={'TaAndScDetailsCfBox'}>
                                <img alt={''}
                                     src={this.state.TrainArr.image}
                                />
                            </div>
                        </div>
                        <div className={'TaAndScDetailsRightBox'}>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>负责人</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.contact}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>负责人联系方式</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.mobile}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>所属机构</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.org_name}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>训练中心名称</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.name}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>训练地址</div>
                                <div className={'TaAndScInfoCon'}>
                                    {this.state.TrainAddress}
                                </div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>证书到期时间</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.expire_time}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>证书编号</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.code}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>学员数量</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.stuNum}位</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>教练员数量</div>
                                <div className={'TaAndScInfoCon'}>{this.state.TrainArr.coachNum}位</div>
                            </div>
                        </div>
                        {/*
                            this.state.TrainArr.image !== ''&&
                            <div className={'EquipmentInfo'}>
                                <img alt={''}
                                     src={linshifle}
                                     style={{width:'100%',height:'auto',display:'block',border:'1px solid #030303',}}
                                />
                            </div>
                        */}
                    </div>
                </div>
        );
    }
}


export default TrainDetails;
