import React, { Component } from 'react';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import DatePicker from "../round/DatePicker";
import './member.css';
import ExportJsonExcel from "js-export-excel";

class Ranking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StartTime:'',//开始时间
            EndTime:'',//结束时间
            YCItemName:'',//科目名称
            YCItemId:'',//科目名称
            YCItemArr:[],//科目列表
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            SelectInfoArr:[],
            RankingState:'Logo',
            YCSchoolType:'',
            YCSchoolId:'',

            RankingArr:[],
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let history = nextProps;
        this.setState({
            SelectInfoArr:history.SelectInfo,
            YCSchoolId:history.YCSchoolId,
            YCSchoolType:history.YCSchoolType,
        });
    };

    componentDidMount=function() {
        let history = this.props;
        this.setState({
            SelectInfoArr:history.SelectInfo,
            YCSchoolId:history.YCSchoolId,
            YCSchoolType:history.YCSchoolType,
        });
        get({url:'cloudprogram/get_analy_item'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    YCItemArr:res.data,
                });
            }else {
                this.setState({
                    YCItemArr:[],
                });
            }
        });
    };




    AllClick = () => {
        this.setState({
            YCItemToggleOn: true,
            YCItemDisplay:'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    RankingBack(){
        this.props.RankingBackChild('back');
    };



    StartTime=(event)=>{
        this.setState({
            StartTime:event,
        });
    };
    EndTime=(event)=>{
        this.setState({
            EndTime:event,
        });
    };
    YCItemDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            YCItemToggleOn: !prevState.YCItemToggleOn,
            YCItemDisplay:prevState.YCItemToggleOn ? 'block' : 'none',
        }));
    };

    YCItemClick=(item,index)=>{
        this.setState({
            YCItemId:item.id,
            YCItemName:item.name,
            YCItemToggleOn: true,
            YCItemDisplay:'none',
        });
    };


    YCButton(){
        let StartTime = this.state.StartTime;
        let EndTime = this.state.EndTime;
        let YCItemId = this.state.YCItemId;
        let YCSchoolId = this.state.YCSchoolId;
        let YCSchoolType = this.state.YCSchoolType;
        let SelectInfoArr = this.state.SelectInfoArr;
        let SelectInfoBrr = [];
        for(let i=0;i<SelectInfoArr.length;i++){
            SelectInfoBrr.push(SelectInfoArr[i].user_id);
        }
        if (StartTime !==''&&
            EndTime !==''&&
            YCItemId !==''&&
            YCSchoolId !==''&&
            YCSchoolType !==''&&
            SelectInfoArr.length !==0
        ){
            post({
                url:'cloudprogram/stu_score_rank',
                data:{
                    stu_id:SelectInfoBrr,
                    item_id:YCItemId,
                    type:YCSchoolType,
                    ti_id:YCSchoolId,
                    start_time:StartTime,
                    end_time:EndTime,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    this.setState({
                        RankingState:'LogoOk',
                        RankingArr:res.data,
                    });
                }else {
                    this.setState({
                        RankingState:'LogoErr',
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请将开始、结束时间、项目选择完整！',
            });
        }
    };






    DataDown(){
        let RankingArr = this.state.RankingArr;
        let currentPro = [];
        if (RankingArr.length !== 0){
            for(let i=0;i<RankingArr.length;i++){
                const obList = RankingArr[i];
                currentPro.push(obList);
            }
        }
        let option={};
        let dataTable = [];
        let sheetBasicTitle = ['排行','姓名','最好成绩时间','飞行次数','得分率'];
        if (currentPro) {
            for(let i=0;i<currentPro.length;i++){
                let obj = {};
                obj.排行 = i+1;
                obj.姓名 = currentPro[i].real_name;
                obj.最好成绩时间 = currentPro[i].time_score === 0 ? currentPro[i].total_time : currentPro[i].time_score === 1 ? currentPro[i].total_score : currentPro[i].time_score === 2 ? currentPro[i].total_score+'/'+currentPro[i].total_time : '';
                obj.飞行次数 = currentPro[i].total_num;
                obj.得分率 = currentPro[i].dfl === '' ? 0 : currentPro[i].dfl+'%';
                dataTable.push(obj);
            }
            option.fileName = '成绩导出信息表';
            option.datas=[
                {
                    sheetData:dataTable,
                    sheetName:'sheet1',
                    sheetFilter:sheetBasicTitle,
                    sheetHeader:sheetBasicTitle,
                    columnWidths: [10, 10, 10,]
                },
            ];
            if (dataTable.length !== 0){
                //console.log(dataTable)
                const toExcel = new ExportJsonExcel(option);
                toExcel.saveExcel();
            }
        }
    };











    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };




    render() {
        return (
            <div>
                <div className={'DetailsPackageTop'} style={{borderBottom:'2px solid #DDDDDD',padding:'6px 0',}}>
                    <div className={'DetailsBackBox clearfix'} style={{height:20,marginTop:4,}} onClick={this.RankingBack.bind(this)}>
                        <div className={'DetailsBackIco'} style={{width:20,height:20,}}> </div>
                        <div className={'DetailsBackWord'} style={{height:20,lineHeight:'20px',fontSize:16,marginLeft:6,}}>返回</div>
                    </div>
                </div>
                <div className={'YunChengSelectBox'}>
                    <div className={'YunChengTimeBox'}>
                        <DatePicker
                            Type={'开始'}
                            BackTimeClick={this.StartTime.bind(this)}
                        />
                    </div>
                    <div className={'YunChengTimeBox'}>
                        <DatePicker
                            Type={'结束'}
                            BackTimeClick={this.EndTime.bind(this)}
                        />
                    </div>
                    <div className={'YunChengItemBox clearfix'}>
                        <div className={'YCItemName'} onClick={this.YCItemDown.bind(this)}>{this.state.YCItemName === '' ? '请选择项目'  : this.state.YCItemName}</div>
                        <div className={'YCItemIco'} onClick={this.YCItemDown.bind(this)}>
                            <div className={'icon-arrow-down'}> </div>
                        </div>
                        <div className={'YCItemNavBox scrollBar'} style={{display:this.state.YCItemDisplay}}>
                            {
                                this.state.YCItemArr.map((item,index) =>
                                    <div className={'YCItemNav'}
                                         key={index}
                                         onClick={() => this.YCItemClick(item,index)}
                                         style={this.state.YCItemName === item.name ? {backgroundColor:'#6DC1FF'} : {}}>
                                        {item.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={'YCButton'} onClick={this.YCButton.bind(this)}>确认</div>
                </div>
                {
                    this.state.RankingState === 'Logo'&&
                    <div style={{padding:'140px 0',}}>
                        <div className={'TrainDataMain'}>
                            <div className={'keyNumLogo'}> </div>
                            <div className={'keyNumTitle'}>请将开始时间、结束时间、项目选择完整进行成绩排行！</div>
                        </div>
                    </div>
                }
                {
                    this.state.RankingState === 'LogoErr'&&
                    <div style={{padding:'140px 0',}}>
                        <div className={'TrainDataMain'}>
                            <div className={'keyNumLogo'}> </div>
                            <div className={'keyNumTitle'}>暂无数据，请重新选择！</div>
                        </div>
                    </div>
                }
                {
                    this.state.RankingState === 'LogoOk'&&
                    <div>
                        <div className={'GrGameHeaderBox'} style={{height:22,}}>
                            <div className={'GrGameHeaderInfo'} style={{width:100,marginTop:0,}}>排行</div>
                            <div className={'GrGameHeaderInfo'} style={{width:200,marginTop:0,}}>姓名</div>
                            <div className={'GrGameHeaderInfo'} style={{width:300,marginTop:0,}}>(最好)成绩&nbsp;/&nbsp;时间</div>
                            <div className={'GrGameHeaderInfo'} style={{width:150,marginTop:0,}}>飞行次数</div>
                            <div className={'GrGameHeaderInfo'} style={{width:150,marginTop:0,}}>得分率</div>
                        </div>
                        <div className={'GrGameContentBox scrollBar'} style={{height:592,}}>
                            {
                                this.state.RankingArr.map((item,index) =>
                                    <div className={'GrGameContentList'} key={index}>
                                        <div className={'GrGameContentInfo'} style={{width:100,}}>{index+1}</div>
                                        <div className={'GrGameContentInfo'} style={{width:200,}}>{item.real_name}</div>
                                        {
                                            item.time_score === 0&& item.total_time !== ''&&
                                            <div className={'GrGameContentInfo'} style={{width:300,}}>{item.total_time}秒</div>
                                        }
                                        {
                                            item.time_score === 1&& item.total_score !== ''&&
                                            <div className={'GrGameContentInfo'} style={{width:300,}}>{item.total_score}分</div>
                                        }
                                        {
                                            item.time_score === 2&&
                                            <div className={'GrGameContentInfo'} style={{width:300,}}>
                                                    <span
                                                        className={'Ellipsis'}
                                                        style={{display:'block',float:'left',width:'auto',minWidth:140,}}
                                                    >{item.total_score}分</span>
                                                <span
                                                    style={{display:'block',float:'left',width:20,}}
                                                >&nbsp;/&nbsp;</span>
                                                <span
                                                    className={'Ellipsis'}
                                                    style={{display:'block',float:'left',width:'auto',minWidth:140,}}
                                                >{item.total_time}秒</span>
                                            </div>
                                        }
                                        <div className={'GrGameContentInfo'} style={{width:150,}}>{item.total_num}</div>
                                        <div className={'GrGameContentInfo'} style={{width:150,}}>{item.dfl === '' ? 0 : item.dfl+'%'}</div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={'YCAddStuInfoList clearfix'} style={{height:26,}}>
                            <div className={'YCOperatingBtn'}
                                 onClick={this.DataDown.bind(this)}
                                 style={{margin:'0 20px',height:26,lineHeight:'26px',}}
                            >
                                导出数据
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}

export default Ranking;
