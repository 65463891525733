import React,{ Component } from 'react';
import {get, post,} from "../../ajax/tools";
import  "./ask.css";
import  "./report.css";
import PopUp from "../round/PopUp";

class ask extends Component {
    constructor(props){
        super(props);
        this.state = {
            ProblemArr:[],
            AnswerArr:[],
            ProblemArrLength:0,
            askId:'',
            AskType:'ask',
            ispass:'',
            score:'',
            name:'',
        };
    }

    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let askId = this.props.match.params.askId;
                this.setState({
                    askId:askId,
                    name:res.data[0].real_name,
                });
                get({url:'user/assist_write_show'}).then((res) => {
                    if(res.errno === '200'){
                        let ProblemArr = res.data;
                        if(ProblemArr.length !== 0){
                            this.setState({
                                ProblemArr:ProblemArr,
                                ProblemArrLength:ProblemArr.length,
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'题库错误，请联系我们',
                            });
                        }
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                window.close()
            }
        });
    };

    ClickAnswers=(item,index,SonItem,SonIndex) => {
        let ProblemArr = this.state.ProblemArr;
        let AnswerArr = this.state.AnswerArr;
        ProblemArr[index]['Choice'] = String.fromCharCode(SonIndex+65);
        AnswerArr[index] = {id:item.id, answer:SonItem,};
        this.setState({
            ProblemArr:ProblemArr,
            AnswerArr:AnswerArr,
        });
    };

    ClickSubmitBtn(){
        let AnswerArr = this.state.AnswerArr;
        let askId = this.state.askId;
        function count(AnswerArr){
            let n = 0;
            for(let i in AnswerArr){
                n++;
                if (AnswerArr[i]){}
            }
            return n;
        }
        if(count(AnswerArr) === this.state.ProblemArrLength){
            post({
                url:'user/assist_write_score',
                data:{
                    assist_id: askId,
                    answer_list: AnswerArr,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    this.setState({
                        AskType:'report',
                        score:res.score,
                    });
                }else{
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您还有题目未选择!',
            });
        }
    };



    ClickSubmitBtnNo(){
        window.close();
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
            <div>
                {
                    this.state.AskType === 'ask'&&
                    <div className={'AskOutBox'}>
                        <div className={'AskTitle'}>模拟飞行辅导员测试试题卷</div>
                        <div className={'AskExplain'}><div className={'AskNumBer'}>{this.state.ProblemArr.length}</div></div>
                        <div className={'BorderDashed'}> </div>
                        <div className={'Asked'} style={{marginBottom:40,}}>
                            {
                                this.state.ProblemArr.map((item,index)=>
                                    <div className={'AskContent'} key={index}>
                                        <div className={'SubjectTitle clearfix'}>
                                            <div className={'BracketsLeft'} style={{marginLeft:0,}}>[</div>
                                            <div className={'SubjectChoice'}>{item.Choice}</div>
                                            <div className={'BracketsRight'}>]</div>
                                            <div className={'SubjectCon'}>{index+1}、{item.question}</div>
                                        </div>
                                        <ul className={'AnswerBox clearfix'}>
                                            {
                                                item.choices.map((SonItem,SonIndex)=>
                                                    <li className={'AnswerList'} key={SonIndex}>
                                                        <div
                                                            className={String.fromCharCode(SonIndex+65) === item.Choice ? 'AnswerListIcoHave' : 'AnswerListIco'}
                                                            onClick={() => this.ClickAnswers(item,index,SonItem,SonIndex)}> </div>
                                                        <span className={'AnswerListCon'}>{String.fromCharCode(SonIndex+65)}、</span>
                                                        <div className={'AnswerListWord'}>{SonItem}</div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                        <div className={'BorderDashed'} style={{marginTop:0,marginBottom:8,}}> </div>
                        <div className={'BorderDashed'} style={{marginTop:0,}}> </div>
                        <div className={'SubmitBtn'} style={{paddingBottom:80,}} onClick={this.ClickSubmitBtn.bind(this)}> </div>
                    </div>
                }
                {
                    this.state.AskType === 'report'&&
                    <div className={'AskOutBox'}>
                        <div className={'AskTitle'}>模拟飞行辅导员测试成绩单</div>
                        <div className={'ReportBoxOK'}>
                            <div className={'ReportUserName'}>{this.state.name}</div>
                            <div className={'ReportUserGrade'}>{this.state.score}</div>
                        </div>
                        <button className={'ReportBoxBtn'} onClick={this.ClickSubmitBtnNo.bind(this)}>返回</button>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackClick.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'No'}
                    />
                }
            </div>
        );
    }
}

export default ask;
