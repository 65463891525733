import React, { Component } from 'react';
import Pagination from "../round/Pagination";
import './news.css';
import {get} from "../../ajax/tools";
//import {post,} from "../../ajax/tools";


class NewsBulletin extends Component {

    constructor(props){
        super(props);
        this.state = {
            NewsBulletinListArr:[],
            NewsBulletinListArrLength: 0,
            NewsBulletinListBrr:[],
            PageSize: '',
            CurrentPage: 1,

        };
    }
    componentDidMount=function() {
        let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
        let PageSize = Math.ceil(H/52)
        get({url:"index/news_show"}).then(res => {
            if(res.errno === '200'){
                let HomeNewBrr = [];
                for(let i=0;i<res.data.length;i++){
                    let NewType = res.data[i].category_id;
                    let sb = res.data[i].status;
                    if (NewType === 3 && sb === 1){
                        let HomeNewArrList = res.data[i];
                        HomeNewBrr.push(HomeNewArrList);
                    }
                }
                const KeyStart = (this.state.CurrentPage-1)*PageSize;
                const KeyEnd = this.state.CurrentPage*PageSize;
                const NewsCenterList = [];
                if(KeyEnd <= HomeNewBrr.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const NewsCenterListBrr = HomeNewBrr[i];
                        NewsCenterList.push(NewsCenterListBrr)
                    }
                }else {
                    for(let i=KeyStart; i<HomeNewBrr.length; i++){
                        const NewsCenterListBrr = HomeNewBrr[i];
                        NewsCenterList.push(NewsCenterListBrr)
                    }
                }

                this.setState({
                    NewsBulletinListArr:HomeNewBrr,
                    NewsBulletinListBrr:NewsCenterList,
                    NewsBulletinListArrLength:HomeNewBrr.length,
                    PageSize:PageSize,
                });
            }else {
                this.setState({
                    NewsBulletinListArr:[],
                    NewsBulletinListBrr:[],
                });
            }
        });
    };



    NewsBulletinCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const NewsBulletinList = [];
        if(KeyEnd <= this.state.NewsBulletinListArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const NewsBulletinListBrr = this.state.NewsBulletinListArr[i];
                NewsBulletinList.push(NewsBulletinListBrr)
            }
        }else {
            for(let i=KeyStart; i<this.state.NewsBulletinListArr.length; i++){
                const NewsBulletinListBrr = this.state.NewsBulletinListArr[i];
                NewsBulletinList.push(NewsBulletinListBrr)
            }
        }
        this.setState({
            NewsBulletinListBrr:NewsBulletinList,
        });
    }



    NewsInfoList=(item,index)=>{
        if (item.is_pdf && item.is_pdf === 1){
            window.open(item.content);
        }else {
            window.open('/newsInfo?id='+item.id);
        }
    };






    render() {
        return (
            <div>
                <div className={'InfoListBox'} style={{height:window.innerHeight-340 > 360 ? window.innerHeight-340 :360,}}>
                    {
                        this.state.NewsBulletinListBrr.map((item,index) =>
                            <div className={'InfoList'} key={index} onClick={() => this.NewsInfoList(item,index)}>
                                <div className={'InfoListKey'}>[<span style={{color:'#ff0000',}}>政策</span>]</div>
                                <div className={'InfoListTitle'}>{item.title}</div>
                                <div className={'InfoListCenter'}>············</div>
                                <div className={'InfoListTime'}>{item.sort_time}</div>
                            </div>
                        )
                    }
                </div>
                <div className={'InfoListBorder'} style={{marginBottom:10,}}> </div>
                <div style={{marginBottom:20,}}>
                    {
                        this.state.NewsBulletinListArrLength !== 0 &&
                        <Pagination
                            TotalSize={this.state.NewsBulletinListArrLength}
                            PageSize={this.state.PageSize}
                            pageCallbackFn={this.NewsBulletinCurrentPage.bind(this)}
                        />
                    }
                </div>
            </div>
        );
    }
}


export default NewsBulletin;
