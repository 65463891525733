import React, { Component } from 'react';
import './showfile.css';
import AuthHeader from "../handf/AuthHeader";
import VdbjwQwD from "./shpwimage/fslogo.png";
import KZBSZap5 from "./shpwimage/cailogo.png";
import {get} from "../../ajax/tools";


class FriendLink extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :window.innerHeight,
            FriendLinkArr:[],
        };
    }
    componentDidMount=function() {
        get({url:'index/Links'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    FriendLinkArr:res.data,
                });
            }else {
                this.setState({
                    FriendLinkArr:[],
                });
            }
        });
    };


    LogoClick=()=>{
        let a = document.createElement('a');
        a.setAttribute('href',KZBSZap5);
        a.setAttribute('download','Logo');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };
    FsClick=()=>{
        let a = document.createElement('a');
        a.setAttribute('href',VdbjwQwD);
        a.setAttribute('download','FS');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };



    FriendLink=(item,index)=>{
        let a = document.createElement('a');
        a.setAttribute('href',item.link_net);
        a.setAttribute('target','_blank');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
        //let NewsInfoId = index;
        //window.open(item,'download');
    };

    render() {
        return (
            <div className={'MainBox'} style={{backgroundImage:'none',}}>
                <AuthHeader/>
                <div className={'MainContent'}>
                    <div className={'FriendLinkBox clearfix'}>
                        <div className={'FriendLinkLeftBox'}>
                            <div className={'FriendLinkAboutBox'}>
                                <p>本站的友情链接图片和文字：欢迎与我们联系</p>
                                <p>链接文字:模拟飞行服务平台</p>
                                <p>链接地址:www.asfcyy.com</p>
                                <p>链接图片:（点击可下载）</p>
                                <div className={'FriendLinkMyImageBox clearfix'}>
                                    <div className={'FriendLinkMyImage'}>
                                        <img src={KZBSZap5} alt={''} onClick={this.LogoClick.bind(this)}/>
                                    </div>
                                    <div className={'FriendLinkMyImage'}>
                                        <img src={VdbjwQwD} alt={''} onClick={this.FsClick.bind(this)}/>
                                    </div>
                                </div>
                                <p>中国航协模拟飞行服务平台是由中国航空运动协会授权，北京鹰扬科技有限公司运营的中国模拟飞行运动官方服务平台。模拟飞行爱好者与相关单位通过此平台及时获取第一手的政策、标准、规则、模拟飞行全国性赛事活动信息以及丰富的业界动态和新闻，还可以在平台上学习交流航空知识和飞行技能，随时掌握自身成长状态。</p>
                                <hr/>
                                <p>1.违反我国现行法律的网站勿扰；</p>
                                <p>2.友情链接网站之间有义务向对方报告链接失效，图片更新等问题，在解除友情链接之前亦应该通知对方；</p>
                                <p>3.请先在贵站添加友情链接后，将贵站链接地址、内容及logo发送邮件至 asfcyy@asfcyy.com 我们将在核实后尽快添加；</p>
                                <p>以上，北京鹰扬科技有限公司保留全部解释权。</p>
                            </div>
                        </div>
                        <div className={'FriendLinkRightBox'}>
                            <ul className={'clearfix'}>
                                {
                                    this.state.FriendLinkArr.map((item,index) =>
                                        <li key={index} onClick={() => this.FriendLink(item,index)}>
                                            <div className={'FriendLinkImageBox'}>
                                                <img src={item.url} alt={''}/>
                                            </div>
                                            <div className={'FriendLinkName Ellipsis'}>{item.link_name}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default FriendLink;