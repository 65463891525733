import React, { Component } from 'react';
//import AuthHeader from "../handf/AuthHeader";
import './showfile.css';
import AuthHeader from "../handf/AuthHeader";


class Agreement extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :'',
            AgreementFileId:'',
        };
    }
    componentDidMount=function() {
        let search = this.props.location.search;
        let msg = new URLSearchParams(search.slice(1))
        let id = msg.get('id');
        this.setState({
            AgreementFileId : id,
        });

        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight-162,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
    };



    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <AuthHeader/>
                    {
                        this.state.AgreementFileId === 'n50c4dpu8ca8n9sc' &&
                        <div className={'AgreementBox'} style={{height:this.state.winHeight,}}>
                            <div className={'AgreementTitle'}>中国航协模拟飞行服务平台服务协议</div>
                            <div className={'AgreementCon'}>
                                “中国航协模拟飞行服务平台”通过以下条款为您提供服务。您只有完全同意下列所有服务条款并完成注册程序，才能成为“中国航协模拟飞行服务平台”的用户并使用相应服务。您在使用“中国航协模拟飞行服务平台”提供的各项服务之前，应仔细阅读本用户协议。 您在注册程序过程中点击“同意条款，立即注册”按钮即表示您与“中国航协模拟飞行服务平台”达成协议，完全接受本服务条款项下的全部条款。您一旦使用“中国航协模拟飞行服务平台”的服务，即视为您已了解并完全同意本服务条款各项内容，包括“中国航协模拟飞行服务平台”对服务条款随时做的任何修改。
                            </div>
                            <div className={'AgreementTit'}>
                                一、隐私条款
                            </div>
                            <div className={'AgreementCon'}>
                                1.用户在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
                            </div>
                            <div className={'AgreementCon'}>
                                2.个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。
                            </div>
                            <div className={'AgreementCon'}>
                                尊重用户个人隐私信息的私有性是的一贯制度，将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者发现存在发生前述情形的可能时，将及时采取补救措施。 未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (1) 根据法律法规规定或有权机关的指示提供用户的个人隐私信息；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (2) 由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因原因导致的个人隐私信息的泄露；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (3) 用户自行向第三方公开其个人隐私信息；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (4) 用户与及合作单位之间就用户个人隐私信息的使用公开达成约定，因此向合作单位公开用户个人隐私信息；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。
                                </div>
                            </div>
                            <div className={'AgreementCon'}>
                                3.用户同意可在以下事项中使用用户的个人隐私信息：
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (1) 向用户及时发送重要通知，如软件更新、本协议条款的变更；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (2) 内部进行审计、数据分析和研究等，以改进的产品、服务和与用户之间的沟通；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (3) 依本协议约定，管理、审查用户信息及进行处理措施；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    (4) 适用法律法规规定的其他事项。
                                </div>
                                    除上述事项外，如未取得用户事先同意，不会将用户个人隐私信息使用于任何其他用途。
                            </div>
                            <div className={'AgreementCon'}>
                                4.为了改善的技术和服务，向用户提供更好的服务体验，或可会自行收集使用或向第三方提供用户的非个人隐私信息。
                            </div>
                            <div className={'AgreementTit'}>
                                二、用户行为
                            </div>
                            <div className={'AgreementCon'}>
                                用户同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    1.发布或以其它方式传送含有下列内容之一的信息： 反对宪法所确定的基本原则的； 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 损害国家荣誉和利益的； 煽动民族仇恨、民族歧视、破坏民族团结的； 破坏国家宗教政策，宣扬邪教和封建迷信的； 散布谣言，扰乱社会秩序，破坏社会稳定的； 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； 侮辱或者诽谤他人，侵害他人合法权利的； 含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容； 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的； 含有“中国航协模拟飞行服务平台”认为不适合在“中国航协模拟飞行服务平台”展示的内容；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    2.以任何方式危害他人的合法权益；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    3.冒充其他任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    4.将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以发布、发送电子邮件或以其它方式传送；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    5.将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利（以下简称“专属权利”）之内容加以发布或以其它方式传送；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    6.将任何广告信函、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以发布、发送或以其它方式传送；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    7.将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒（包括但不限于木马程序（trojan horses）、蠕虫（worms）、定时炸弹、删除蝇（cancelbots）（以下简称“病毒”）或其它计算机代码、档案和程序之任何资料，加以发布、发送或以其它方式传送；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    8.干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    9.跟踪、人肉搜索或以其它方式骚扰他人； 10.故意或非故意地违反任何适用的当地、国家法律，以及任何具有法律效力的规则；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    11.未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。
                                </div>
                                您已认可“中国航协模拟飞行服务平台”未对用户的使用行为进行全面控制，您使用任何内容时，包括依赖前述内容之正确性、完整性或实用性时，您同意将自行加以判断并承担所有风险，而不依赖于“中国航协模拟飞行服务平台”。但“中国航协模拟飞行服务平台”依其自行之考虑，可拒绝和删除经由本服务提供之违反本条款的或其它引起“中国航协模拟飞行服务平台”反感的任何内容。您了解并同意，“中国航协模拟飞行服务平台”依据法律法规的要求，或基于诚信为了以下目的或在合理必要范围内，认定必须将内容加以保存或揭露时，得加以保存或揭露：
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    1.遵守法律程序；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    2.执行本使用协议；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    3.回应任何第三人提出的权利主张；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    4.保护“中国航协模拟飞行服务平台”、其用户及公众之权利、财产或个人安全；
                                </div>
                                <div className={'AgreementCon'} style={{textIndent:'4em',}}>
                                    5.其它“中国航协模拟飞行服务平台”认为有必要的情况。
                                </div>
                            </div>
                            <div className={'AgreementTit'}>
                                三、遵守法律
                            </div>
                            <div className={'AgreementCon'}>
                                您同意遵守中华人民共和国相关法律法规的所有规定，并对以任何方式使用您的密码和您的帐号使用本服务的任何行为及其结果承担全部责任。如您的行为违反国家法律和法规的任何规定，有可能构成犯罪的，将被追究刑事责任，并由您承担全部法律责任。同时，如果“中国航协模拟飞行服务平台”有理由认为您的任何行为，包括但不限于您的任何言论和其它行为违反或可能违反国家法律和法规的任何规定，“中国航协模拟飞行服务平台”可在任何时候不经任何事先通知终止向您提供服务。
                            </div>
                            <div className={'AgreementTit'}>
                                四、免责声明
                            </div>
                            <div className={'AgreementCon'}>
                                互联网是一个开放平台，用户将照片等个人资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。用户明确同意其使用“中国航协模拟飞行服务平台”服务所存在的风险将完全由其自己承担；因其使用“中国航协模拟飞行服务平台”服务而产生的一切后果也由其自己承担，我们对用户不承担任何责任。我们不保证服务一定能满足用户的要求，也不保证服务不会中断，对服务的及时性、安全性、准确性也都不作保证。对于因不可抗力或“中国航协模拟飞行服务平台”无法控制的原因造成的网络服务中断或其他缺陷，“中国航协模拟飞行服务平台”不承担任何责任。我们不对用户所发布信息的删除或储存失败承担责任。我们有权判断用户的行为是否符合本网站使用协议条款之规定，如果我们认为用户违背了协议条款的规定，我们有终止向其提供网站服务的权利 。
                            </div>
                            <div className={'AgreementTit'}>
                                五、附则
                            </div>
                            <div className={'AgreementCon'}>
                                本用户条款的订立、执行和解释及争议的解决均应适用中华人民共和国法律。 如用户就本协议内容或其执行发生任何争议，用户应尽量与我们友好协商解决；协商不成时，任何一方均可向“中国航协模拟飞行服务平台”所在地的人民法院提起诉讼。我们未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。如本用户条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本用户条款的其余条款仍应有效并且有约束力。本协议所有条款的最终解释权属于“中国航协模拟飞行服务平台”。
                            </div>
                        </div>
                    }
                    {
                        this.state.AgreementFileId === 'f8t8f3muh8w2r7g8' &&
                        <div style={{fontSize:20,}}>《用户注册和隐私保护服务协议》</div>
                    }
                </div>
        );
    }
}


export default Agreement;
