import React, { Component } from 'react';
import './inquire.css';
import {get} from "../../ajax/tools";


class SchoolDetails extends Component {

    constructor(props){
        super(props);
        this.state = {
            SchoolId:'',
            SchoolArr:{},
            SchoolAddress:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        this.setState({
            SchoolId:nextProps.SchoolId,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        get({url:'agency/youth_aviat_school_info/'+history.SchoolId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    SchoolArr:res.data[0],
                    SchoolAddress:res.data[0].province === res.data[0].city ?
                        res.data[0].city+res.data[0].county+res.data[0].address :
                        res.data[0].province+res.data[0].city+res.data[0].county+res.data[0].address,

                });
            }
        });
        this.setState({
            SchoolId:history.SchoolId,
        });
    };


    BackButton=()=>{
        this.props.BackButton('BackBtn');
    };



    render() {
        return (
                <div className={'DetailsPackageBox'} style={{minHeight:window.innerHeight-194 > 500 ? window.innerHeight-194 :500,}}>
                    <div className={'DetailsPackageTop'}>
                        <div className={'DetailsBackBox clearfix'} onClick={this.BackButton.bind(this)}>
                            <div className={'DetailsBackIco'}> </div>
                            <div className={'DetailsBackWord'}>返回</div>
                        </div>
                    </div>
                    <div className={'TaAndScDetailsMainBox clearfix'}>
                        <div className={'TaAndScDetailsLeftBox'}>
                            <div className={'TaAndScDetailsCfBox'}>
                                <img alt={''}
                                     src={this.state.SchoolArr.image}
                                />
                            </div>
                        </div>
                        <div className={'TaAndScDetailsRightBox'}>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>负责人</div>
                                <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.contact}</div>
                            </div>
                            {/*<div className={'TaAndScInfoListBox clearfix'}>*/}
                            {/*    <div className={'TaAndScInfoTit'}>推荐单位</div>*/}
                            {/*    <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.recome_org}</div>*/}
                            {/*</div>*/}
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>航校名称</div>
                                <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.name}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>航校地址</div>
                                <div className={'TaAndScInfoCon'}>
                                    {this.state.SchoolAddress }
                                </div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>证书到期时间</div>
                                <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.expire_time}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>证书编号</div>
                                <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.code}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>电子化管理设备数量</div>
                                <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.device_num}</div>
                            </div>
                            <div className={'TaAndScInfoListBox clearfix'}>
                                <div className={'TaAndScInfoTit'}>其他模拟设备情况</div>
                                <div className={'TaAndScInfoCon'}>{this.state.SchoolArr.other_device}</div>
                            </div>
                        </div>
                        {/*{*/}
                        {/*    this.state.SchoolArr.image !== ''&&*/}
                        {/*    <div className={'EquipmentInfo'}>*/}
                        {/*        <img alt={''}*/}
                        {/*             src={linshifle}*/}
                        {/*             style={{width:'100%',height:'auto',display:'block',border:'1px solid #030303',}}*/}
                        {/*        />*/}
                        {/*    </div> */}
                        {/*}*/}
                    </div>
                </div>
        );
    }
}


export default SchoolDetails;
