import React, {Component} from 'react';
import GGAddPeople from "./GGAddPeople";
import GGItem from "./GGItem";
import GGSignInfo from "./GGSignInfo";
import GGAddPopUp from "./GGAddPopUp";
import GGAddPopEdit from "./GGAddPopEdit";
import PopUp from "../round/PopUp";

import './game.css';
import Upload from "../round/Upload";
import {get, post} from "../../ajax/tools";


class GameGroup extends Component {

    constructor(props){
        super(props);
        this.state = {
            MatchId:'',//比赛Id
            MatchName:'',//比赛Id
            MatchNum:'',//比赛Id

            TeamEnrollMethod:'',//审核相关

            GGWindowSelected:'',//当队伍大于一个时，默认选中第一支队伍
            GGWindowId:null,//当队伍大于一个时，默认选中第一支队伍
            GGWindowArr:[],//队伍的窗口，当队伍大于一个时显示
            SchoolId:'',


            GameGroupAdd:'',//当点击添加时，弹出添加人员页面PopUp

            UserInfoAndItem:[],//当选择完报名信息时的数据

            GroupProjectArr:[],//组别科目信息

            FileList: [],
            Uploading: false,
            UploadBtnState:'NotShow',
            FileName:'',

            PerRemark:'',

            GGWindowDelId:'',
            is_editable:'',
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let MatchId = nextProps.MatchId;
        this.setState({
            MatchId:MatchId,
        });
    };


    componentDidMount=function() {
        window.scrollTo(0, 0);

        const history = this.props;
        let MatchId = history.MatchId;
        this.setState({
            MatchId:MatchId,
        });
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GGWindowArr:res.data,
                    SchoolId:res.data[0].school_enroll_id,
                    GGWindowId:res.data[0].id,
                    GGWindowSelected:res.data[0].name,
                    is_editable:res.data[0].is_editable,
                });
                post({
                    url:'match/show_team_stu',
                    data:{
                        match_num_id:MatchId,
                        team_id:res.data[0].id,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            UserInfoAndItem:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
        get({url:'match/query_school_enroll_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                if(res.data[0].name){
                    this.setState({
                        FileName:res.data[0].name,
                    });
                }else {
                    this.setState({
                        FileName:'',
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗2',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
        get({url:'match/get_match_details/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    MatchName:res.data[0].match_name,
                    MatchNum:res.data[0].team_limit_man_num,
                    TeamEnrollMethod:res.data[0].team_enroll_method,
                    PerRemark:res.data[0].team_remark,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });




        //组别科目信息
        get({url:'match/get_groups/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let ProjectBrr = [];
                for(let i=0;i<res.data.length;i++){
                    let magId = res.data[i].mag_id;
                    let groupName = res.data[i].name;
                    post({
                        url:'match/get_item',
                        data:{
                            match_id:MatchId,
                            mag_id:magId,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            let ProjectTheArr = res.data;
                            for(let k=0;k<ProjectTheArr.length;k++){
                                ProjectTheArr[k]['mag_id'] = magId;
                                ProjectTheArr[k]['MixingName'] = ProjectTheArr[k].name+'('+groupName+')';
                                //ProjectTheArr[k]['MixingName'] = groupName+':'+ProjectTheArr[k].name;
                            }
                            ProjectBrr.push.apply(ProjectBrr, ProjectTheArr)
                            this.setState({
                                GroupProjectArr:ProjectBrr,
                            });
                        }
                    });
                }
            }
        });
    };



    //返回选择赛事按钮
    GameInfoBackBtn=()=>{
        let ClickInfo = {State:'show',};
        this.props.GameGroup(ClickInfo);
    };







    GGWindowBtn=(item,index)=>{
        let GGWindowSelected = this.state.GGWindowSelected;
        let MatchId = this.state.MatchId;
        if (GGWindowSelected !== item.name){
            post({
                url:'match/show_team_stu',
                data:{
                    match_num_id:MatchId,
                    team_id:item.id,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        UserInfoAndItem:res.data,
                        GGWindowSelected:item.name,
                        GGWindowId:item.id,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }
    };

    GGWindowDel=(item,index)=>{
        let is_editable = this.state.is_editable;
        if (is_editable === 1){
            this.setState({
                PopUp:'删除参赛队',
                MessageTip:'确认删除参赛队并删除此队伍报名信息？',
                GGWindowDelId:item.id,
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'已锁定报名操作，如有疑问请联系我们！',
            });
        }
    }


    BackDelTeam=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
                GGWindowDelId:'',
            });
        }else if(event === 'Confirm'){
            let MatchId = this.state.MatchId;
            let GGWindowDelId = this.state.GGWindowDelId;
            post({
                url:'match/show_team_stu',
                data:{
                    match_num_id:MatchId,
                    team_id:GGWindowDelId,
                },
            }).then((res) => {
                if(res.errno === '200' && res.data.length === 0){
                    get({url:'match/del_team_enroll/'+GGWindowDelId}).then((res) => {
                        if(res.errno === '200'){
                            get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
                                if(res.errno === '200'){
                                    let TeamNum = res.data.length-1;
                                    this.setState({
                                        GGWindowArr:res.data,
                                        GGWindowId:res.data[TeamNum].id,
                                        GGWindowSelected:res.data[TeamNum].name,
                                    });
                                    post({
                                        url:'match/show_team_stu',
                                        data:{
                                            match_num_id:MatchId,
                                            team_id:res.data[TeamNum].id,
                                        },
                                    }).then((res) => {
                                        if(res.errno === '200'){
                                            this.setState({
                                                UserInfoAndItem:res.data,
                                                PopUp:'',
                                            });
                                        }else {
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:res.errmsg+'！',
                                            });
                                        }
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:res.errmsg+'！',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'参赛队中还有运动员，禁止删除！',
                    });
                }
            });
        }
    }



    //点击添加人员的添加按钮
    GGAddPeople=(event)=>{
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    this.setState({
                        UserInfo:event,
                        GameGroupAdd:'PopUp',
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };


    //添加人员弹窗页面操作后的返回
    GGAddPopUp=(event)=>{
        if (event.BackType === 'OK'){
            let UserInfoAndItemList = this.state.UserInfoAndItem;
            let MatchNum = this.state.MatchNum;
            let UserInfoIdTest = [];
            let UserInfoIdtTest = [];
            for(let i=0;i<UserInfoAndItemList.length;i++){
                const YYNumList = UserInfoAndItemList[i].yy_num;
                UserInfoIdTest.push(YYNumList);
                const IdtShowList = UserInfoAndItemList[i].IdtShow;
                if (IdtShowList === '运动员'){
                    UserInfoIdtTest.push(IdtShowList);
                }
            }
            if (event.UserInfo.IdtShow === '运动员'){
                if (UserInfoIdtTest.length < MatchNum){
                    if(UserInfoIdTest.includes(event.UserInfo.yy_num)){
                        this.setState({
                            GameGroupAdd:'',
                            PopUp:'提示弹窗',
                            MessageTip:'您已经添加过该成员，请不要重复添加！',
                        });
                    }else {
                        UserInfoAndItemList.push(event.UserInfo);
                        this.setState({
                            GameGroupAdd:'',
                            UserInfoAndItem:UserInfoAndItemList,
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'指示弹窗',
                        MessageTip:'运动员已达上限，请增加新参赛队！',
                    });
                }
            }else {
                if(UserInfoIdTest.includes(event.UserInfo.yy_num)){
                    this.setState({
                        GameGroupAdd:'',
                        PopUp:'提示弹窗',
                        MessageTip:'您已经添加过该成员，请不要重复添加！',
                    });
                }else {
                    UserInfoAndItemList.push(event.UserInfo);
                    this.setState({
                        GameGroupAdd:'',
                        UserInfoAndItem:UserInfoAndItemList,
                    });
                }
            }
        } else if (event.BackType === 'ERR'){
            this.setState({
                GameGroupAdd:'',
            });
        }
    };


    //重新编辑人员弹窗页面操作后的返回
    GGAddPopEdit=(event)=>{
        if (event.BackType === 'OK'){
            let UserInfoAndItemList = this.state.UserInfoAndItem;
            let MatchNum = this.state.MatchNum;
            let UserEditInfo = event.UserEditInfo;
            let UserInfoIdtTest = [];
            let UserInfoIdTest = [];
            for(let i=0;i<UserInfoAndItemList.length;i++){
                const YYNumList = UserInfoAndItemList[i].yy_num;
                UserInfoIdTest.push(YYNumList);
                const IdtShowList = UserInfoAndItemList[i].IdtShow;
                if (IdtShowList === '运动员'){
                    UserInfoIdtTest.push(IdtShowList);
                }
            }
            if (UserEditInfo.IdtShow === '运动员'){
                if (UserInfoIdtTest.length < MatchNum){
                    for(let i=0;i<UserInfoAndItemList.length;i++){
                        let YYNumList = UserInfoAndItemList[i].yy_num;
                        if (YYNumList === UserEditInfo.yy_num){
                            UserInfoAndItemList[i] = UserEditInfo;
                        }
                    }
                    this.setState({
                        GameGroupAdd:'',
                        UserInfoAndItem:UserInfoAndItemList,
                    });
                }else {
                    if(UserInfoIdTest.includes(UserEditInfo.yy_num)){
                        for(let i=0;i<UserInfoAndItemList.length;i++){
                            let YYNumList = UserInfoAndItemList[i].yy_num;
                            if (YYNumList === UserEditInfo.yy_num){
                                UserInfoAndItemList[i] = UserEditInfo;
                            }
                        }
                        this.setState({
                            GameGroupAdd:'',
                            UserInfoAndItem:UserInfoAndItemList,
                        });
                    }else {
                        this.setState({
                            GameGroupAdd:'',
                            PopUp:'指示弹窗',
                            MessageTip:'运动员已达上限，请增加新支参赛队！',
                        });
                    }
                }
            }else {
                for(let i=0;i<UserInfoAndItemList.length;i++){
                    let YYNumList = UserInfoAndItemList[i].yy_num;
                    if (YYNumList === UserEditInfo.yy_num){
                        UserInfoAndItemList[i] = UserEditInfo;
                    }
                }
                this.setState({
                    GameGroupAdd:'',
                    UserInfoAndItem:UserInfoAndItemList,
                });
            }
        } else if (event.BackType === 'ERR'){
            this.setState({
                GameGroupAdd:'',
            });
        }
    };




    //分配科目部分的套娃页面
    GGItem=(event)=>{
        if (event.BackType === '添加项目'){
            this.setState({
                UserInfoAndItem:event.UsersInfo,
            });
        } else if (event.BackType === '删除项目'){
            this.setState({
                UserInfoAndItem:event.UsersInfo,
            });
        }
    };



    //信息汇总部分的套娃页面
    GGSignInfo=(event)=>{
        if (event.BackType === '编辑人员'){
            this.setState({
                GameGroupAdd:'EditPopUp',
                UserEditInfo:event.UserEditInfo,
            });
        }else if (event.BackType === '删除人员'){
            let GGWindowSelected = this.state.GGWindowSelected;
            let GGWindowArr = this.state.GGWindowArr;
            let GGWindowId = this.state.GGWindowId;
            let MatchNum = this.state.MatchNum;
            let MatchId = this.state.MatchId;
            if (GGWindowSelected === '1' && GGWindowArr.length === 1) {
                this.setState({
                    UserInfoAndItem:event.UserInfoAndItemDelArr,
                });
            }else if (GGWindowSelected === '1' && GGWindowArr.length !== 1) {
                if (event.StuArrList.length === 0){
                    this.setState({
                        UserInfoAndItem:event.UserInfoAndItemDelArr,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'默认队伍人数需满足'+MatchNum+'人 请添加人员',
                    });
                }
            }else if (GGWindowSelected !== '1' && GGWindowArr.length !== 1){
                if (event.StuArrList.length === 0){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'此队伍已无运动员,请注意队伍管理！',
                    });
                    post({
                        url:'match/show_team_stu',
                        data:{
                            match_num_id:MatchId,
                            team_id:GGWindowId,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                UserInfoAndItem:res.data,
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        UserInfoAndItem:event.UserInfoAndItemDelArr,
                    });
                }
            }

        }
    };







    //保存信息
    GameGroupBtn(){
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    let GGWindowSelected = this.state.GGWindowSelected;
                    let UserInfoAndItemList = this.state.UserInfoAndItem;
                    let SchoolId = this.state.SchoolId;
                    let UserInfoIdtTest = [];
                    let UserInfoIdtTest2 = [];
                    for(let i=0;i<UserInfoAndItemList.length;i++){
                        const IdtShowList = UserInfoAndItemList[i].IdtShow;
                        if (IdtShowList === '运动员'){
                            UserInfoIdtTest.push(IdtShowList);
                        }
                        if (IdtShowList === '领队'){
                            UserInfoIdtTest2.push(IdtShowList);
                        }
                    }
                    let formData = new FormData();
                    if (UserInfoIdtTest2.length !== 0){
                        if(this.state.FileName !== ''){
                            if(this.state.FileList.length !== 0 && this.state.UserInfoAndItem.length !== 0){
                                if(GGWindowSelected === '1' && UserInfoIdtTest.length === 0){
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'默认队伍人数需大于1人 请添加人员',
                                    });
                                }else {
                                    this.state.FileList.forEach((file) => {
                                        formData.append('file', file);
                                        formData.append('school_enroll_id', SchoolId);
                                    });
                                    post({
                                        url:'match/team_upload_file',
                                        data:formData,
                                    }).then((res) => {
                                        if(res.errno === '200'){
                                            get({url:'match/query_school_enroll_info/'+MatchId}).then((res) => {
                                                if(res.errno === '200'){
                                                    if(res.data[0].name){
                                                        this.setState({
                                                            FileName:res.data[0].name,
                                                            UploadBtnState:'NotShow',
                                                        });
                                                    }else {
                                                        this.setState({
                                                            FileName:'',
                                                            UploadBtnState:'NotShow',
                                                        });
                                                    }
                                                    this.setState({
                                                        PopUp:'提示弹窗',
                                                        MessageTip:'您的报名已接收，等待组委会审核！',
                                                    });
                                                }else {
                                                    this.setState({
                                                        PopUp:'提示弹窗',
                                                        MessageTip:res.errmsg+'！',
                                                    });
                                                }
                                            });
                                        }else {
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:res.errmsg+'！',
                                            });
                                        }
                                    });
                                }
                            }else {
                                if(GGWindowSelected === '1' && UserInfoIdtTest.length !== 0){
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'保存成功！',
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'队伍人数需大于1人 请添加人员',
                                    });
                                }
                            }
                        }else {
                            if(this.state.FileList.length !== 0 && this.state.UserInfoAndItem.length !== 0){
                                if(GGWindowSelected === '1' && UserInfoIdtTest.length === 0){
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'默认队伍人数需大于1人 请添加人员',
                                    });
                                }else {
                                    this.state.FileList.forEach((file) => {
                                        formData.append('file', file);
                                        formData.append('school_enroll_id', SchoolId);
                                    });
                                    post({
                                        url:'match/team_upload_file',
                                        data:formData,
                                    }).then((res) => {
                                        if(res.errno === '200'){
                                            get({url:'match/query_school_enroll_info/'+MatchId}).then((res) => {
                                                if(res.errno === '200'){
                                                    if(res.data[0].name){
                                                        this.setState({
                                                            FileName:res.data[0].name,
                                                            UploadBtnState:'NotShow',
                                                        });
                                                    }else {
                                                        this.setState({
                                                            FileName:'',
                                                            UploadBtnState:'NotShow',
                                                        });
                                                    }
                                                    this.setState({
                                                        PopUp:'提示弹窗',
                                                        MessageTip:'保存成功！',
                                                    });
                                                }else {
                                                    this.setState({
                                                        PopUp:'提示弹窗',
                                                        MessageTip:res.errmsg+'！',
                                                    });
                                                }
                                            });
                                        }else {
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:res.errmsg+'！',
                                            });
                                        }
                                    });
                                }
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'您参赛信息不完全，请仔细检查！',
                                });
                            }
                        }
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'友情提示：还未添加领队。',
                        });
                    }

                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };






    //添加队伍的提示弹窗返回
    BackAddTeam=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            let SchoolId = this.state.SchoolId;
            let MatchId = this.state.MatchId;
            let TeamEnrollMethod = this.state.TeamEnrollMethod;
            get({url:'match/add_team_enroll/'+SchoolId+'/'+TeamEnrollMethod}).then((res) => {
                if(res.errno === '200'){
                    get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
                        if(res.errno === '200'){
                            let TeamNum = res.data.length-1;
                            this.setState({
                                GGWindowArr:res.data,
                                GGWindowId:res.data[TeamNum].id,
                                GGWindowSelected:res.data[TeamNum].name,
                            });
                            post({
                                url:'match/show_team_stu',
                                data:{
                                    match_num_id:MatchId,
                                    team_id:res.data[TeamNum].id,
                                },
                            }).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        PopUp:'',
                                        GameGroupAdd:'',
                                        UserInfoAndItem:res.data,
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:res.errmsg+'！',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }
    };



    //转移队伍的弹窗返回
    BackHoverTeam=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    ChildBackTipTwo=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
            let ClickInfo = {State:'show',};
            this.props.GameGroup(ClickInfo);
        }
    };



    DownloadTemplate(){
        let a = document.createElement('a');
        // a.setAttribute('href','http://oss.asfcyy.com/richTextImage/a2e5a4e81ef411ee9dc800163e3521a2.docx');
        // a.setAttribute('href','http://oss.asfcyy.com/richTextImage/9520f854382e11ef9dcd00163e3521a2.docx');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/db69bc94539d11ef9dcd00163e3521a2.docx');
        a.setAttribute('download','赛事报名表模板');
        a.setAttribute('target','_self');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };




    render() {
        const propsGameGroup = {
            UploadState:this.state.UploadBtnState,
            RemoveUpload:(file) => {
                let FileList = this.state.FileList;
                let index = FileList.indexOf(file);
                let NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileList:NewFileList,
                    Uploading: false,
                    UploadBtnState:'NotShow',
                    FileName:'',
                });
            },
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsGameGroup.BeforeUpload;
                let NewFileList = [];
                let FileName = file.name;
                if(file.type === 'application/x-zip-compressed' || file.type === '' || file.type === 'application/pdf'){
                    if(file.size > 5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过5MB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                            UploadBtnState:'Show',
                            FileName:FileName,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '请上传压缩包文件或pdf格式文件';
                }
            },
        };
        return (
            <div className={'GameMainBox'}>
                <div className={'GameInfoTitleBox clearfix'}>
                    <div className={'GameInfoTitle'}>
                        <span onClick={this.GameInfoBackBtn.bind(this)}>选择赛事</span>
                        >>{this.state.MatchName}
                        {
                            this.state.GGWindowArr.length > 1 &&
                            <span>>>参赛{this.state.GGWindowSelected}队</span>
                        }
                    </div>
                    <div className={'DetailsBackBox clearfix'} style={{height:20,marginTop:4,}} onClick={this.GameInfoBackBtn.bind(this)}>
                        <div className={'DetailsBackIco'} style={{width:20,height:20,}}> </div>
                        <div className={'DetailsBackWord'} style={{height:20,lineHeight:'20px',fontSize:16,marginLeft:6,}}>返回</div>
                    </div>
                </div>
                <div>
                    {
                        this.state.GGWindowArr.length > 1 &&
                        <div className={'GGWindowBox clearfix'}>
                            {
                                this.state.GGWindowArr.map((item,index)=>
                                    <div className={'GGWindowList'}
                                         style={this.state.GGWindowSelected === item.name ? {backgroundColor:'#0068B7',color:'#FFFFFF',} : {}}
                                         key={index}
                                         onClick={() => this.GGWindowBtn(item,index)}
                                    >
                                        参赛{item.name}队
                                        {
                                            item.name !== '1'&&
                                            <div className={'GGWindowDelIco'} onClick={() => this.GGWindowDel(item,index)}>
                                                <div className={'IcoPublic'}> </div>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    }
                    {
                        this.state.PerRemark !== ''&&
                        <div className={'GameGroupTitle clearfix'} style={{minHeight:30,margin:'10px auto',}}>
                            <div className={'PerRemarkWord'}>
                                *备注：{this.state.PerRemark}
                            </div>
                        </div>
                    }
                    {
                        this.state.PerRemark === ''&&
                        <div className={'GameGroupTitle clearfix'} style={{minHeight:30,margin:'10px auto',}}> </div>
                    }
                    <div className={'GameGroupTitle'} style={{marginTop:24,}}>1.添加人员</div>
                    <GGAddPeople
                        GGAddPeople={this.GGAddPeople.bind(this)}
                    />
                    {
                        this.state.GameGroupAdd === 'PopUp'&&
                        <GGAddPopUp
                            GGWindowId={this.state.GGWindowId}
                            MatchId={this.state.MatchId}
                            UserInfo={this.state.UserInfo}
                            GGAddPopUp={this.GGAddPopUp.bind(this)}
                        />
                    }
                    <div className={'GameGroupTitle'} style={{marginTop:24,}}>2.分配科目</div>
                    <GGItem
                        GGItem={this.GGItem.bind(this)}
                        MatchId={this.state.MatchId}
                        UserInfoAndItem={this.state.UserInfoAndItem}
                        GroupProjectArr={this.state.GroupProjectArr}
                        GGWindowId={this.state.GGWindowId}

                    />
                    <div className={'GameGroupTitle'} style={{marginTop:24,}}>3.汇总报名信息</div>
                    <GGSignInfo
                        MatchId={this.state.MatchId}
                        UserInfoAndItem={this.state.UserInfoAndItem}
                        GGSignInfo={this.GGSignInfo.bind(this)}
                    />
                    {
                        this.state.GameGroupAdd === 'EditPopUp'&&
                        <GGAddPopEdit
                            GGWindowId={this.state.GGWindowId}
                            MatchId={this.state.MatchId}
                            UserEditInfo={this.state.UserEditInfo}
                            GGAddPopEdit={this.GGAddPopEdit.bind(this)}
                        />
                    }
                    <div className={'GameGroupTitle'} style={{marginTop:24,}}>4.上传文件</div>
                    <div className={'GGAddPeopleBox'} style={{minHeight:156,marginBottom:80,position:'relative',}}>
                        {
                            this.state.is_editable === 1 &&
                            <div className={'GameGroupUpdateBtn'}>
                                {this.state.FileName === '' ? '上传' : '重新上传'}
                                <Upload{...propsGameGroup}/>
                            </div>
                        }
                        {
                            this.state.is_editable === 0 &&
                            <div className={'GameGroupUpdateBtn'} style={{backgroundColor:'#707070',}}>
                                已锁定
                            </div>
                        }
                        {
                            this.state.MatchId !== 80 &&
                            <div className={'GameGroupUpdateDes'}
                                 onClick={this.DownloadTemplate.bind(this)}
                                 style={{top:20,left:180,cursor:'pointer',color:'#34B5FF',textDecoration:'underline',}}
                            >
                                点击下载 — 参赛组队信息表及附件
                            </div>
                        }
                        {
                            this.state.FileName === ''&&
                            <div className={'GameGroupUpdateDes'}>
                                请将本赛事所需的材料
                                <span style={{color:'#009944',}}>进行压缩打包、或合并为PDF文件</span>
                                上传以进行参赛审核
                            </div>
                        }
                        {
                            this.state.FileName !== ''&&
                            <div className={'GameGroupUpdateDes'}>
                                您已上传文件：
                                <span style={{color:'#ff0000',}}>{this.state.FileName}</span>
                            </div>
                        }
                    </div>
                    {
                        this.state.is_editable === 1 &&
                        <div className={'GamePersonalBtn'} onClick={this.GameGroupBtn.bind(this)}>保存信息</div>
                    }
                    {
                        this.state.is_editable === 0 &&
                        <div className={'GamePersonalBtn'} style={{backgroundColor:'#707070',}}>已锁定</div>
                    }
                    <div className={'GamePersonalBtnTip'}>*您可在报名截止日期前更改报名信息</div>
                </div>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '指示弹窗' &&
                    <PopUp
                        ChildBackClick={this.BackAddTeam.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '删除参赛队' &&
                    <PopUp
                        ChildBackClick={this.BackDelTeam.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '转队弹窗' &&
                    <PopUp
                        ChildBackClick={this.BackHoverTeam.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '提示弹窗2' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTipTwo.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default GameGroup;
