import React, { Component } from 'react';
import './member.css';
import '../auth/auth.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import ThreeLevelLinkage from "../round/ThreeLevelLinkage";
import Second from "../round/Second";


class GrBase extends Component {

    constructor(props){
        super(props);
        this.state = {
            RegisteredAddress:'',//注册地址
            RegisteredAddressType:'Show',
            OfficeAddressType:'Show',
            ReAdProvince: '',//当注册地址的三级联动时
            ReAdCity: '',
            ReAdArea: '',
            RegisteredAddressValue: '',//详细注册地址
            OfAdProvince: '',//当办公地址的三级联动时
            OfAdCity: '',
            OfAdArea: '',
            OfficeAddressValue: '',//详细办公地址
            User:{},
            SaveButton:'',
            AdminMobileType:'Show',//管理员手机号
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            VrCodeErrmsg:'',//验证码获取信息
            OfficePhoneType:'Show',//公司联系电话
            OfficePhone:'',
            OfficeEmailType:'Show',//公司电子邮件
            OfficeEmail:'',
            AdminEmailType:'Show',//管理员电子邮件
            AdminEmail:'',

            OfficeNameType:'Show',//管理员电子邮件
            OfficeName:'',

            AdminNameType:'Show',
            AdminName:'',

            SocialCreditCodeType:'Show',
            SocialCreditCode:'',
        };
    }
    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    User:res.data[0],
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
            ThreeLevelLinkage: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };

    //三级联动的逻辑处理
    ChildLinkClick=(event)=>{
        if(event.Type === '注册地址'){
            if(event.Title === '省'){
                this.setState({
                    ReAdProvince: event.ValueP,
                    ReAdCity: event.ValueC,
                    ReAdArea: event.ValueA,
                });
            }else if(event.Title === '市'){
                this.setState({
                    ReAdProvince: event.ValueP,
                    ReAdCity: event.ValueC,
                    ReAdArea: event.ValueA,
                });
            }else if(event.Title === '区'){
                this.setState({
                    ReAdProvince: event.ValueP,
                    ReAdCity: event.ValueC,
                    ReAdArea: event.ValueA,
                });
            }
        }else if(event.Type === '办公地址'){
            if(event.Title === '省'){
                this.setState({
                    OfAdProvince: event.ValueP,
                    OfAdCity: event.ValueC,
                    OfAdArea: event.ValueA,
                });
            }else if(event.Title === '市'){
                this.setState({
                    OfAdProvince: event.ValueP,
                    OfAdCity: event.ValueC,
                    OfAdArea: event.ValueA,
                });
            }else if(event.Title === '区'){
                this.setState({
                    OfAdProvince: event.ValueP,
                    OfAdCity: event.ValueC,
                    OfAdArea: event.ValueA,
                });
            }
        }
    };







    //点击修改管理员
    UpDataAdminName(){
        this.setState({
            AdminNameType: 'UpData',
            AdminName:'',
        });
    };
    SaveAdminName(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该管理员？',
            SaveButton:'AdminName',
        });
    };
    CancelAdminName(){
        this.setState({
            AdminNameType: 'Show',
            AdminName:'',
        });
    };
    AdminName(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            AdminName: event.target.value,
        });
    };




    // 修改企业名称
    UpDataOfficeName(){
        this.setState({
            OfficeNameType: 'UpData',
            OfficeName:'',
        });
    };
    SaveOfficeName(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该单位名称？',
            SaveButton:'OfficeName',
        });
    };
    CancelOfficeName(){
        this.setState({
            OfficeNameType: 'Show',
            OfficeName:'',
        });
    };
    OfficeName(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            OfficeName: event.target.value,
        });
    };





    //点击修改管理员手机号
    UpDataUserPhone(){
        this.setState({
            AdminMobileType: 'UpData',
            Mobile:'',//手机号
            VrCode:'',//手机验证码
        });
    };
    //取消修改手机号
    CancelUserMobile(){
        this.setState({
            AdminMobileType: 'Show',
            Mobile:'',//手机号
            VrCode:'',//手机验证码
        });
    };
    //手机号
    MobileChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            Mobile: event.target.value,
        });
    };
    //验证码
    VrCodeChange(event){
        this.setState({
            VrCode: event.target.value,
        });
    };
    //点击获取验证码
    ReVrCodeBtnClick(){
        post({
            url:'passport/prefix_sms_check',
            data:{
                mobile:this.state.Mobile,
            },
        }).then((res) => {
            if(res.errno === '4002'){
                post({
                    url:'passport/sms_code',
                    data:{
                        mobile:this.state.Mobile,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            VrCodeErrmsg:res.errmsg,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else if(res.errno === '200'){
                this.setState({
                    PopUp:'短信验证码重复使用提示',
                    MessageTip:'您的此手机号的验证码还未过期，是否重新获取？',
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };
    SecondBackChange=(event)=>{
        if(event === 'Stop'){
            this.setState({
                VrCodeErrmsg:'',
            });
        }
    };
    //保存电话号码按钮
    SaveUserMobile(){
        this.setState({
            PopUp:'管理员手机号修改的提示框',
            MessageTip:'是否确认保存该手机号码！',
        });
    };
    //手机号弹窗的逻辑处理
    MobileBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.Mobile !== '' && this.state.VrCode !== ''){
                post({
                    url:'passport/sms_check',
                    data:{
                        mobile:this.state.Mobile,
                        smscode:this.state.VrCode,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        post({
                            url:'/passport/check_mobile',
                            data:{
                                mobile:this.state.Mobile,
                            },
                        }).then((res) => {
                            if(res.errno === '4002'){
                                get({url:'user/update_mobile/'+this.state.Mobile}).then((res) => {
                                    if(res.errno === '200'){
                                        get({url:'user/basic_info'}).then((res) => {
                                            if(res.errno === '200'){
                                                this.setState({
                                                    User:res.data[0],
                                                    PopUp:'',
                                                    AdminMobileType:'Show',
                                                });
                                            }
                                        });
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:res.errmsg,
                                        });
                                    }
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'您有必要信息没有填写！',
                });
            }
        }
    };








    //点击修改管理员邮箱
    UpDataAdminEmail(){
        this.setState({
            AdminEmailType: 'UpData',
            AdminEmail: '',
        });
    };
    AdminEmail(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            AdminEmail: event.target.value,
        });
    };
    //点击保存修改公司管理员邮箱
    SaveAdminEmail(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该公司邮箱！',
            SaveButton:'AdminEmail',
        });
    };
    //点击取消修公司管理员邮箱
    CancelAdminEmail(){
        this.setState({
            AdminEmailType: 'Show',
            AdminEmail: '',
        });
    };

    //点击修改公司联系方式
    UpDataOfficePhone(){
        this.setState({
            OfficePhoneType: 'UpData',
            OfficePhone: '',
        });
    };
    OfficePhone(event){
        event.target.value = event.target.value.replace(/[^\-\d]/g,'');
        this.setState({
            OfficePhone: event.target.value,
        });
    };
    //点击保存修改公司联系方式
    SaveOfficePhone(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该公司联系方式！',
            SaveButton:'OfficePhone',
        });
    };
    //点击取消修公司联系方式
    CancelOfficePhone(){
        this.setState({
            OfficePhoneType: 'Show',
            OfficePhone: '',
        });
    };


    //点击修改公司社会统一代码
    UpDataSocialCreditCode(){
        this.setState({
            SocialCreditCodeType: 'UpData',
            SocialCreditCode: '',
        });
    };
    SocialCreditCode(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            SocialCreditCode: event.target.value,
        });
    };
    //点击保存修改公司社会统一代码
    SaveSocialCreditCode(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该公司社会统一代码！',
            SaveButton:'SocialCreditCode',
        });
    };
    //点击取消修社会统一代码
    CancelSocialCreditCode(){
        this.setState({
            SocialCreditCodeType: 'Show',
            SocialCreditCode: '',
        });
    };




    //点击修改公司邮箱
    UpDataOfficeEmail(){
        this.setState({
            OfficeEmailType: 'UpData',
            OfficeEmail: '',
        });
    };
    OfficeEmail(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            OfficeEmail: event.target.value,
        });
    };
    //点击保存修改公司公司邮箱
    SaveOfficeEmail(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该公司邮箱！',
            SaveButton:'OfficeEmail',
        });
    };
    //点击取消修公司公司邮箱
    CancelOfficeEmail(){
        this.setState({
            OfficeEmailType: 'Show',
            OfficeEmail: '',
        });
    };

    //点击修改注册地址
    RegisteredAddressUpData(){
        this.setState({
            RegisteredAddressType: 'UpData',
            ReAdProvince: '',//当注册地址的三级联动时
            ReAdCity: '',
            ReAdArea: '',
            RegisteredAddressValue: '',//详细注册地址
        });
    };
    //输入详细注册地址
    RegisteredAddress(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            RegisteredAddressValue: event.target.value,
        });
    };
    //点击保存修改的注册地址
    SaveRegisteredAddress(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该注册地址！',
            SaveButton:'RegisteredAddress',
        });
    };
    //点击取消修改注册地址
    CancelRegisteredAddress(){
        this.setState({
            RegisteredAddressType: 'Show',
            ReAdProvince: '',//当注册地址的三级联动时
            ReAdCity: '',
            ReAdArea: '',
            RegisteredAddressValue: '',//详细注册地址
        });
    };


    //点击修改办公地址
    OfficeAddressUpData(){
        this.setState({
            OfficeAddressType: 'UpData',
            OfAdProvince: '',//当办公地址的三级联动时
            OfAdCity: '',
            OfAdArea: '',
            OfficeAddressValue: '',//详细办公地址
        });
    };
    //输入详细办公地址
    OfficeAddress(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            OfficeAddressValue: event.target.value,
        });
    };
    //点击保存修改的办公地址
    SaveOfficeAddress(){
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认保存该办公地址！',
            SaveButton:'OfficeAddress',
        });
    };
    //点击取消修改办公地址
    CancelOfficeAddress(){
        this.setState({
            OfficeAddressType: 'Show',
            OfAdProvince: '',//当办公地址的三级联动时
            OfAdCity: '',
            OfAdArea: '',
            OfficeAddressValue: '',//详细办公地址
        });
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.SaveButton==='RegisteredAddress'){
                if(this.state.RegisteredAddressValue !== ''&&this.state.ReAdProvince !== ''&&this.state.ReAdCity !== ''&&this.state.ReAdArea !== ''){
                    let RegisteredAddress =this.state.ReAdProvince === this.state.ReAdCity ?
                                            this.state.ReAdCity+this.state.ReAdArea+this.state.RegisteredAddressValue :
                                            this.state.ReAdProvince+this.state.ReAdCity+this.state.ReAdArea+this.state.RegisteredAddressValue;
                    post({
                        url:'user/update_reg_addr',
                        data:{
                            address:RegisteredAddress,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        RegisteredAddressType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的注册地址！',
                    });
                }
            }else if(this.state.SaveButton==='OfficeAddress'){
                if(this.state.OfficeAddressValue !== ''&&this.state.OfAdProvince !== ''&&this.state.OfAdCity !== ''&&this.state.OfAdArea !== ''){
                    post({
                        url:'user/update_off_addr',
                        data:{
                            province: this.state.OfAdProvince,
                            city: this.state.OfAdCity,
                            county: this.state.OfAdArea,
                            address: this.state.OfficeAddressValue,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        OfficeAddressType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的办公地址！',
                    });
                }
            }else if(this.state.SaveButton==='OfficePhone'){
                if(this.state.OfficePhone !== ''){
                    get({url:'user/update_phone/'+this.state.OfficePhone}).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        OfficePhoneType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的联系方式！',
                    });
                }
            }else if(this.state.SaveButton==='OfficeEmail'){
                if(this.state.OfficeEmail !== ''){
                    get({url:'user/update_org_email/'+this.state.OfficeEmail}).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        OfficeEmailType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的企业邮箱！',
                    });
                }
            }else if(this.state.SaveButton==='AdminEmail'){
                if(this.state.AdminEmail !== ''){
                    get({url:'user/update_admin_email/'+this.state.AdminEmail}).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        AdminEmailType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的管理员邮箱！',
                    });
                }
            }else if(this.state.SaveButton==='AdminName'){
                if(this.state.AdminName !== ''){
                    get({url:'user/update_admin_name/'+this.state.AdminName}).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        AdminNameType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的管理员姓名！',
                    });
                }
            }else if(this.state.SaveButton==='OfficeName'){
                if(this.state.OfficeName !== ''){
                    post({
                        url:'agency/change_org_name',
                        data:{
                            org_name:this.state.OfficeName,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        OfficeNameType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的单位名称！',
                    });
                }
            }else if(this.state.SaveButton==='SocialCreditCode'){
                if(this.state.SocialCreditCode !== ''){
                    get({url:'user/update_Social_Code/'+this.state.SocialCreditCode}).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        SocialCreditCodeType:'Show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请填写正确的社会统一代码！',
                    });
                }
            }
        }
    };














    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    //重复获取验证码
    ChildBackVrCode=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            post({
                url:'passport/sms_code',
                data:{
                    mobile:this.state.Mobile,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        PopUp:'',
                        VrCodeErrmsg:res.errmsg,
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }
    };






    render() {
        const RegisteredAddressThree =
            <div className={'ReInfoInputOddBox'} style={{marginBottom:6,float:'left',}}>
                <ThreeLevelLinkage
                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                    type={'注册地址'}
                    Width={418}
                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                />
            </div>;
        const RegisteredAddress =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Address'}
                       value={this.state.RegisteredAddressValue}
                       onChange={this.RegisteredAddress.bind(this)}
                       placeholder={'请输入详细注册地址'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const OfficeAddressThree =
            <div className={'ReInfoInputOddBox'} style={{marginBottom:6,float:'left',}}>
                <ThreeLevelLinkage
                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                    type={'办公地址'}
                    Width={418}
                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                />
            </div>;
        const OfficeAddress =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Address'}
                       value={this.state.OfficeAddressValue}
                       onChange={this.OfficeAddress.bind(this)}
                       placeholder={'请输入详细办公地址'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const OfficePhone =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'OfficePhone'}
                       value={this.state.OfficePhone}
                       onChange={this.OfficePhone.bind(this)}
                       placeholder={'请输入新的联系方式'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const SocialCreditCode =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'OfficePhone'}
                       value={this.state.SocialCreditCode}
                       onChange={this.SocialCreditCode.bind(this)}
                       placeholder={'请输入新的社会统一代码'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const OfficeEmail =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'OfficeEmail'}
                       value={this.state.OfficeEmail}
                       onChange={this.OfficeEmail.bind(this)}
                       placeholder={'请输入新的邮箱'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const AdminEmail =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'AdminEmail'}
                       value={this.state.AdminEmail}
                       onChange={this.AdminEmail.bind(this)}
                       placeholder={'请输入新的邮箱'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const Mobile =
            <div className={'InfoBoxOdd'} style={{width:426,marginBottom:6,}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Mobile'}
                       value={this.state.Mobile}
                       onChange={this.MobileChange.bind(this)}
                       placeholder={'请输入正确的手机号'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
            </div>;
        const VrCode =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                <div className={'ReInfoInputOddBox'} style={{float:'left',}}>
                    <input className={'ReVrCodeInput'}
                           type={'text'}
                           name={'VrCode'}
                           value={this.state.VrCode}
                           onChange={this.VrCodeChange.bind(this)}
                           placeholder={'输入验证码'}
                           autoComplete={'off'}
                    />
                    {
                        this.state.VrCodeErrmsg === '' &&
                        <button className={'ReVrCodeBtn'} onClick={this.ReVrCodeBtnClick.bind(this)}>获取验证码</button>
                    }
                    {
                        this.state.VrCodeErrmsg !== '' &&
                        <button className={'ReVrCodeBtn'} style={{backgroundColor:'#D5D5D5',}}>
                            获取成功<Second SecondBackChange={this.SecondBackChange.bind(this)}/>
                        </button>
                    }
                </div>
            </div>;

        let officeAddress =this.state.User.office_pro === this.state.User.office_city ? this.state.User.office_city + this.state.User.office_county+this.state.User.office_add : this.state.User.office_pro + this.state.User.office_city+this.state.User.office_county + this.state.User.office_add;
        return (
            <div>
                {
                    this.state.User.yy_num &&
                    <div className={'MemberPackageBox scrollBarTwo'} style={{height:window.innerHeight-180,}}>
                        <div className={'GrBaseInfoTitle'}>管理员信息</div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>姓名</div>
                            {
                                this.state.AdminNameType === 'Show' &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.admin_name}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataAdminName.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.AdminNameType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                                            <input className={'ReInfoInputOdd'}
                                                   type={'text'}
                                                   name={'AdminName'}
                                                   value={this.state.AdminName}
                                                   onChange={this.AdminName.bind(this)}
                                                   placeholder={'请输入新的管理员'}
                                                   autoComplete={'off'}
                                                   style={{float:'left',}}
                                            />
                                        </div>
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveAdminName.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelAdminName.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>手机号</div>
                            {
                                this.state.AdminMobileType === 'Show' &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.mobile}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataUserPhone.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.AdminMobileType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {Mobile}
                                        {VrCode}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveUserMobile.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelUserMobile.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>邮箱</div>
                            {
                                this.state.AdminEmailType === 'Show' &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.admin_email}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataAdminEmail.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.AdminEmailType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {AdminEmail}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveAdminEmail.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelAdminEmail.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'SeparateBorder'} style={{marginBottom:2,}}> </div>
                        <div className={'SeparateBorder'} style={{marginBottom:48,}}> </div>
                        <div className={'GrBaseInfoTitle'}>团体信息</div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>单位名称</div>
                            {
                                this.state.OfficeNameType === 'Show' && this.state.User.yy_num &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.org_name}({this.state.User.yy_num})</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataOfficeName.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.OfficeNameType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        <div className={'InfoBoxOdd'} style={{width:426,height:0,}}>
                                            <input className={'ReInfoInputOdd'}
                                                   type={'text'}
                                                   name={'OfficeName'}
                                                   value={this.state.OfficeName}
                                                   onChange={this.OfficeName.bind(this)}
                                                   placeholder={'请输入新的单位名称'}
                                                   autoComplete={'off'}
                                                   style={{float:'left',}}
                                            />
                                        </div>
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveOfficeName.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelOfficeName.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>社会统一代码</div>
                            {
                                this.state.SocialCreditCodeType === 'Show' &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.social_credit_code !== '' ? this.state.User.social_credit_code : '/'}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataSocialCreditCode.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.SocialCreditCodeType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {SocialCreditCode}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveSocialCreditCode.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelSocialCreditCode.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>联系电话</div>
                            {
                                this.state.OfficePhoneType === 'Show' &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.phone}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataOfficePhone.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.OfficePhoneType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {OfficePhone}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveOfficePhone.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelOfficePhone.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>企业邮箱</div>
                            {
                                this.state.OfficeEmailType === 'Show' &&
                                <div className={'GrBaseInfoListCon'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.org_email}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.UpDataOfficeEmail.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.OfficeEmailType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {OfficeEmail}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveOfficeEmail.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelOfficeEmail.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>注册地址</div>
                            {
                                this.state.RegisteredAddressType === 'Show' &&
                                <div className={'GrBaseInfoListCon clearfix'}>
                                    <div className={'GrBaseInfoListConWord'}>{this.state.User.register_add}</div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.RegisteredAddressUpData.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.RegisteredAddressType === 'UpData'&&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {RegisteredAddressThree}
                                        {RegisteredAddress}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveRegisteredAddress.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelRegisteredAddress.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        <div className={'GrBaseInfoListBox clearfix'}>
                            <div className={'GrBaseInfoListTit'}>办公地址</div>
                            {
                                this.state.OfficeAddressType === 'Show' && this.state.User.office_add &&
                                <div className={'GrBaseInfoListCon clearfix'}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {String(officeAddress)}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.OfficeAddressUpData.bind(this)}>修改</div>
                                </div>
                            }
                            {
                                this.state.OfficeAddressType === 'UpData' &&
                                <div className={'GrBaseInfoListCon clearfix'} style={{position:'relative',}}>
                                    <div className={'GrBaseInfoListConWord'}>
                                        {OfficeAddressThree}
                                        {OfficeAddress}
                                    </div>
                                    <div className={'GrBaseInfoListUpDataBtn'} onClick={this.SaveOfficeAddress.bind(this)}>保存</div>
                                    <div className={'GrBaseInfoListUpDataBtn'}
                                         onClick={this.CancelOfficeAddress.bind(this)}
                                         style={{position:'absolute',top:0,right:'-54px',}}
                                    >取消</div>
                                </div>
                            }
                        </div>
                        {
                            this.state.PopUp === '有两个选择按钮的提示框' &&
                            <PopUp
                                ChildBackClick={this.ChildBackClick.bind(this)}
                                type={'成功'}
                                message={this.state.MessageTip}
                                OutButton={'Yes'}
                            />
                        }
                        {
                            this.state.PopUp === '管理员手机号修改的提示框' &&
                            <PopUp
                                ChildBackClick={this.MobileBackClick.bind(this)}
                                type={'成功'}
                                message={this.state.MessageTip}
                                OutButton={'Yes'}
                            />
                        }
                        {
                            this.state.PopUp === '提示弹窗' &&
                            <PopUp
                                ChildBackClick={this.ChildBackTip.bind(this)}
                                type={'提示'}
                                message={this.state.MessageTip}
                                OutButton={'Yes'}
                            />
                        }
                        {
                            this.state.PopUp === '短信验证码重复使用提示' &&
                            <PopUp
                                ChildBackClick={this.ChildBackVrCode.bind(this)}
                                type={'成功'}
                                message={this.state.MessageTip}
                                OutButton={'Yes'}
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}


export default GrBase;
