import React, { Component } from 'react';
import './member.css';
import {get} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class GrDrill extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            MemberTopNav:'基地信息',
            TopNavArr:['基地信息'],
            NavSelected:{
                backgroundColor:'#0488CA',
                color:'#FFFFFF',
                borderLeftColor:'#000000',
                borderTopColor:'#000000',
                borderRightColor:'#000000',
            },
            NoSelected:{
                borderBottomColor:'#000000',
            },
            GrDrillArr:[],
        };
    }
    componentDidMount=function() {
        get({url:'agency/read_train_base'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GrDrillArr:res.data,
                });
            }else {
                this.setState({
                    GrDrillArr:[],
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if(item === '基地信息'){
            this.props.ChildBackNavClick('基地信息');
        }
        this.setState({
            MemberTopNav:item,
        });
    };


    //查看详情
    GrDrillReview=(item,index)=>{
        this.props.BackDrillDetails(item);
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    <div className={'MemberTopNavBox'}>
                        {
                            this.state.TopNavArr.map((item,index) =>
                                <div className={'MemberTopNav'} 
                                    key={index}
                                    onClick={() => this.MemberTopNav(item,index)}
                                    style={item === this.state.MemberTopNav ? this.state.NavSelected : this.state.NoSelected}
                                >
                                    {item}
                                </div>
                            )
                        }
                        <div className={'MemberPosition'} style={{width:808,height:61,borderBottom:'1px solid #000000',}}> </div>
                    </div>
                    <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                        <div className={'TableHeader'}>
                            <div className={'TableHeaderInfo'} style={{width:80,}}>序号</div>
                            <div className={'TableHeaderInfo'} style={{width:244,}}>训练地址</div>
                            <div className={'TableHeaderInfo'} style={{width:244,}}>推荐单位</div>
                            <div className={'TableHeaderInfo'} style={{width:244,}}>证书编号（有效期）</div>
                            <div className={'TableHeaderInfo'} style={{width:104,}}>详情</div>
                        </div>
                        <div className={'TableContentBox'}>
                            {
                                this.state.GrDrillArr.map((item,index) =>
                                    <div className={'TableContent clearfix'} key={index}>
                                        <div className={'TableContentInfo'} style={{width:80,}}>{index+1}</div>
                                        <div className={'TableContentInfo'} style={{width:244,}}>
                                            {item.city+item.county+item.name}
                                        </div>
                                        <div className={'TableContentInfo'} style={{width:244,}}>
                                            <div className={'TableContentInfoBranch'}>{item.recome_org}</div>
                                        </div>
                                        <div className={'TableContentInfo'} style={{width:244,}}>
                                            <div className={'TableContentInfoBranch'}>{item.code}</div>
                                            <div className={'TableContentInfoBranch'}>（{item.expire_time}）</div>
                                        </div>
                                        <div className={'TableContentInfo'} style={{width:104,}}>
                                            <span className={'DetailsViews'} onClick={() => this.GrDrillReview(item,index)}>查看</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default GrDrill;
