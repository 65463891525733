import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import InEquipment from "./InEquipment";
import EquipmentDetails from "./EquipmentDetails";
import InTrain from "./InTrain";
import TrainDetails from "./TrainDetails";
import InSchool from "./InSchool";
import SchoolDetails from "./SchoolDetails";
import InDepot from "./InDepot";
import DepotDetails from "./DepotDetails";
import InTeacher from "./InTeacher";
import TeacherDetails from "./TeacherDetails";
import TeacherDetails2 from "./TeacherDetails2";
import './inquire.css';
// import {get} from "../../ajax/tools";



class Inquire extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : '',
            InquireState:'List',//默认'List'是左侧有菜单的时候样式，当值为'Details'时，是详情渲染页
            InquireDetails:'',//当点击查看具体项的详细信息时，是哪一模块的查询
            InquireNavArr:[
                {
                    id:'1',
                    ZhName:'青少年航校',
                    EnName:'aviation school',
                    router:'/inquire_s',
                },
                {
                    id:'2',
                    ZhName:'设备',
                    EnName:'EQUIPMENT firm',
                    router:'/inquire_d',
                },
                {
                    id:'3',
                    ZhName:'训练中心',
                    EnName:'training center',
                    router:'/inquire_t',
                },
                {
                    id:'4',
                    ZhName:'实训基地',
                    EnName:'training base',
                    router:'/inquire_x',
                },
                {
                    id:'5',
                    ZhName:'教练员',
                    EnName:'INSTRUCTOR',
                    router:'/inquire_j',
                },
            ],
            NowNavListZhName:'',
            NowNavListEnName:'',

            InquireInput:'',

            EquipmentId:'',//设备项时返回的设备Id值
            TrainId:'',//训练中心项时返回的设备Id值
            SchoolId:'',//青少年航校项时返回的设备Id值

            DepotId:'',//实训基地项时返回的设备Id值
            TeacherId:'',//教练员基地项时返回的设备Id值

            SchoolDataArr:[],
            TeacherDataArr:[],
            DepotDataArr:[],
            TrainDataArr:[],
            EquipmentDataArr:[],

        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }


        let ThreeIndex = this.props.location.pathname.lastIndexOf ("_");
        let sb = this.props.location.pathname.substring(ThreeIndex+1);
        if (sb === 'd'){
            this.setState({
                NowNavListZhName:'设备',
                NowNavListEnName:'Equipment firm',
            });
        }else if (sb === 't'){
            this.setState({
                NowNavListZhName:'训练中心',
                NowNavListEnName:'training center',
            });
        }else if (sb === 's'){
            this.setState({
                NowNavListZhName:'青少年航校',
                NowNavListEnName:'aviation school',
            });
        }else if (sb === 'x'){
            this.setState({
                NowNavListZhName:'实训基地',
                NowNavListEnName:'training base',
            });
        }else if (sb === 'j'){
            this.setState({
                NowNavListZhName:'教练员',
                NowNavListEnName:'INSTRUCTOR',
            });
        }
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //点击左侧菜单
    AccountListClick=(item,index) =>{
        const {history} = this.props;
        history.push(item.router);
    };


    //点击子组件中的具体项的返回值
    ActiveSeeDetails=(event)=>{
        if (event.Inquire === 'Equipment'){
            this.setState({
                InquireState:'Details',
                InquireDetails:'Equipment',
                EquipmentId:event.Id,
                EquipmentDataArr:event.DataArr,
            });
        }else if(event.Inquire === 'Train'){
            this.setState({
                InquireState:'Details',
                InquireDetails:'Train',
                TrainId:event.Id,
                TrainDataArr:event.DataArr,
            });
        }else if(event.Inquire === 'School'){
            this.setState({
                InquireState:'Details',
                InquireDetails:'School',
                SchoolId:event.Id,
                SchoolDataArr:event.DataArr,
            });
        }else if(event.Inquire === 'Depot'){
            this.setState({
                InquireState:'Details',
                InquireDetails:'Depot',
                DepotId:event.Id,
                DepotDataArr:event.DataArr,
            });
        }else if(event.Inquire === 'TeacherName'){
            this.setState({
                InquireState:'Details',
                InquireDetails:'TeacherName2',
                TeacherId:event.Id,
                TeacherDataArr:event.DataArr,
            });
        }
    };

    //点击子组件中的返回按钮
    BackButton=(event)=>{
        if (event === 'BackBtn'){
            this.setState({
                InquireState:'List',
                InquireDetails:'',
                EquipmentId:'',
                TrainId:'',
                SchoolId:'',
                DepotId:'',
                TeacherId:'',
            });
        }
    };






    //搜索框
    InquireInput(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            InquireInput: event.target.value,
        });
    };
    InquireInputBtn(){
        this.setState({
            InquireInput: '',
        });
    };

    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>{this.state.NowNavListZhName}查询服务</div>
                                <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}</div>
                            {/*
                                this.state.InquireState === 'List'&&
                                <div className={'InquireInputBox'}>
                                    <input className={'InquireInput'}
                                           type={'text'}
                                           name={'InquireInput'}
                                           value={this.state.InquireInput}
                                           onChange={this.InquireInput.bind(this)}
                                           placeholder={'您可输入关键词查询...'}
                                           autoComplete={'off'}
                                    />
                                    <button className={'InquireInputBtn'} onClick={this.InquireInputBtn.bind(this)}>搜索</button>
                                </div>
                            */}
                        </div>
                        <div className={'ContentMainBox clearfix'} style={{height:window.innerHeight-191,}}>
                            {
                                this.state.InquireState === 'List'&&
                                <div className={'MainLeftNavBox'} style={{height:window.innerHeight-191,}}>
                                    <ul className={'MainLeftNavListBox'}>
                                        {
                                            this.state.InquireNavArr.map((item,index) =>
                                                <li className={item.ZhName === this.state.NowNavListZhName ? 'MainLeftNavActiveList' : 'MainLeftNavList'}
                                                    key={index}
                                                    onClick={() => this.AccountListClick(item,index)}
                                                >
                                                    {item.ZhName}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                this.state.InquireState === 'List'&&
                                <div className={'MainThisNavBox'} style={{height:window.innerHeight-191,}}>
                                    {
                                        this.state.NowNavListZhName === '设备'&&
                                        <InEquipment
                                            EquipmentDataArr={this.state.EquipmentDataArr}
                                            ActiveSeeDetails={this.ActiveSeeDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowNavListZhName === '训练中心'&&
                                        <InTrain
                                            TrainDataArr={this.state.TrainDataArr}
                                            ActiveSeeDetails={this.ActiveSeeDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowNavListZhName === '青少年航校'&&
                                        <InSchool
                                            SchoolDataArr={this.state.SchoolDataArr}
                                            ActiveSeeDetails={this.ActiveSeeDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowNavListZhName === '实训基地'&&
                                        <InDepot
                                            DepotDataArr={this.state.DepotDataArr}
                                            ActiveSeeDetails={this.ActiveSeeDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowNavListZhName === '教练员'&&
                                        <InTeacher
                                            TeacherDataArr={this.state.TeacherDataArr}
                                            ActiveSeeDetails={this.ActiveSeeDetails.bind(this)}
                                        />
                                    }
                                </div>
                            }
                            {
                                this.state.InquireState === 'Details'&&
                                <div>
                                    {
                                        this.state.InquireDetails === 'Equipment'&&
                                        <EquipmentDetails
                                            EquipmentId={this.state.EquipmentId}
                                            BackButton={this.BackButton.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.InquireDetails === 'Train'&&
                                        <TrainDetails
                                            TrainId={this.state.TrainId}
                                            BackButton={this.BackButton.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.InquireDetails === 'School'&&
                                        <SchoolDetails
                                            SchoolId={this.state.SchoolId}
                                            BackButton={this.BackButton.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.InquireDetails === 'Depot'&&
                                        <DepotDetails
                                            DepotId={this.state.DepotId}
                                            BackButton={this.BackButton.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.InquireDetails === 'TeacherName'&&
                                        <TeacherDetails
                                            TeacherId={this.state.TeacherId}
                                            BackButton={this.BackButton.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.InquireDetails === 'TeacherName2'&&
                                        <TeacherDetails2
                                            TeacherId={this.state.TeacherId}
                                            BackButton={this.BackButton.bind(this)}
                                        />
                                    }
                                </div>
                            }
                        </div>
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                </div>
        );
    }
}


export default Inquire;
