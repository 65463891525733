import React, { Component } from 'react';
import PopUp from "../round/PopUp";
import './game.css';
import '../round/round.css';
import BoundCoach from "../exam/BoundCoach";
import Triangle from "../round/Triangle";
import {get, post} from "../../ajax/tools";

class GGAddPopUp extends Component {

    constructor(props){
        super(props);
        this.state = {
            IdNum:'',//用户id号
            BoundCoachId:'',//绑定教练员（辅导员）的ID
            BoundCoachIdNum:'',//绑定教练员（辅导员）的ID号

            MatchId:'',
            GGWindowId:'',


            IdtArr: [{id:2,idt:'运动员',},{id:3,idt:'教练员（辅导员）',},{id:4,idt:'领队',}],//身份
            IdtIsToggleOn: true,
            IdtBtn: 'false',
            IdtShow:'',
            IdtShowId:'',
            GroupArr: [],//组别
            GroupInfo: {},//已选择组别信息
            GroupIsToggleOn: true,
            GroupBtn: 'false',
            GroupShow:'',
            ProjectArr: [],//项目总数据
            ProjectBrr: [],//已经选择的项目
            ProjectIsToggleOn: true,
            ProjectBtn: 'false',
            ProjectShow:'',
            ProjectBrrNum:0,

            ProjectMaxNum: null,//最多兼项数目

            UserInfo:{},

            BgMainHeight:document.documentElement.scrollTop,
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        let UserInfo = history.UserInfo;
        this.setState({
            IdNum:UserInfo.id,
            MatchId:history.MatchId,
            GGWindowId:history.GGWindowId,
        });
    };



    componentDidMount=function() {
        const history = this.props;
        let UserInfoYYId = history.UserInfo.yy_num;
        let UserInfo = history.UserInfo;
        let MatchId = history.MatchId;
        let GGWindowId = history.GGWindowId;
        this.setState({
            IdNum:UserInfo.id,
            MatchId:MatchId,
            GGWindowId:GGWindowId,
        });
        get({url:'reuse/get_personal_info/'+UserInfoYYId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    UserInfo:res.data[0],
                });
            }else {
                this.setState({
                    PopUp:'运动员信息错误',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
        get({url:'match/get_groups/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GroupArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });


        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
            IdtIsToggleOn: true,
            IdtBtn: 'false',
            GroupIsToggleOn: true,
            GroupBtn: 'false',
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //下拉菜单按钮
    ClickIdtDb(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            IdtIsToggleOn: !prevState.IdtIsToggleOn,
            IdtBtn:prevState.IdtIsToggleOn ? 'true' : 'false',
            GroupIsToggleOn: true,
            GroupBtn:"false",
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
        }));
    };
    ClickGroupDb(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            GroupIsToggleOn: !prevState.GroupIsToggleOn,
            GroupBtn:prevState.GroupIsToggleOn ? 'true' : 'false',
            IdtIsToggleOn: true,
            IdtBtn: 'false',
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
        }));
    };
    ClickProjectAdd(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            ProjectIsToggleOn: !prevState.ProjectIsToggleOn,
            ProjectBtn:prevState.ProjectIsToggleOn ? 'true' : 'false',
            IdtIsToggleOn: true,
            IdtBtn: 'false',
            GroupIsToggleOn: true,
            GroupBtn:"false",
        }));
    };



    //点击选择下拉菜单的内容
    ClickIdtArr=(item,index) => {
        this.setState({
            IdtIsToggleOn: true,
            IdtBtn:"false",
            IdtShow:item.idt,
            IdtShowId:item.id,
            ProjectBrr: [],
            ProjectBrrNum:0,
            GroupShow:'',
        });
    };
    ClickGroupArr=(item,index) => {
        this.setState({
            GroupIndex: index,
            GroupIsToggleOn: true,
            GroupBtn:"false",
            GroupShow:item.name,
            GroupInfo:item,
            ProjectBrr:[],
            ProjectBrrNum:0,
        });
        let MatchId = this.state.MatchId;
        post({
            url:'match/get_item',
            data:{
                match_id:MatchId,
                mag_id:item.mag_id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                let ProjectTheArr = res.data;
                for(let k=0;k<ProjectTheArr.length;k++){
                    ProjectTheArr[k]['mag_id'] = item.mag_id;
                    ProjectTheArr[k]['MixingName'] = ProjectTheArr[k].name+'('+item.name+')';
                }
                this.setState({
                    ProjectArr:ProjectTheArr,
                    ProjectMaxNum:res.allow_item_num,
                });
            }
        });
    };
    ClickProjectArr=(item,index) => {
        let ProjectBrr=this.state.ProjectBrr;
        let ProjectMaxNum = this.state.ProjectMaxNum;
        let GroupInfo = this.state.GroupInfo;
        let ProjectTest = [];
        let ProjectTrr = [];
        for(let i=0;i<ProjectBrr.length;i++){
            let obList = ProjectBrr[i].item_name;
            let ProjectType = ProjectBrr[i].is_concurrent;
            if (ProjectType === 1) {
                ProjectTrr.push(ProjectBrr[i])
            }
            ProjectTest.push(obList)
        }
        if(this.state.ProjectBrrNum === 0){
            const Projects = {};
            Projects['item_name'] = item.name;
            Projects['item_id'] = item.item_id;
            Projects['mag_id'] = GroupInfo.mag_id;
            Projects['mai_id'] = item.id;
            Projects['Num'] = (this.state.ProjectBrrNum+1);
            Projects['is_concurrent'] = item.is_concurrent;
            ProjectBrr.push(Projects);
        }else {
            if (ProjectTrr.length < ProjectMaxNum){
                if(ProjectTest.includes(item.name)){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'您已经选择该项目，请不要重复选择！',
                    });
                }else {
                    const Projects = {};
                    Projects['item_name'] = item.name;
                    Projects['item_id'] = item.item_id;
                    Projects['mag_id'] = GroupInfo.mag_id;
                    Projects['mai_id'] = item.id;
                    Projects['Num'] = (this.state.ProjectBrrNum+1);
                    Projects['is_concurrent'] = item.is_concurrent;
                    ProjectBrr.push(Projects);
                }
            }else {
                if(item.is_concurrent !== 1){
                    if(ProjectTest.includes(item.name)){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'您已经选择该项目，请不要重复选择！',
                        });
                    }else {
                        const Projects = {};
                        Projects['item_name'] = item.name;
                        Projects['item_id'] = item.item_id;
                        Projects['mag_id'] = GroupInfo.mag_id;
                        Projects['mai_id'] = item.id;
                        Projects['Num'] = (this.state.ProjectBrrNum+1);
                        Projects['is_concurrent'] = item.is_concurrent;
                        ProjectBrr.push(Projects);
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'计入兼项科目数量已达到最多兼项限制',
                    });
                }
            }
        }
        this.setState({
            ProjectIndex: index,
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
            ProjectShow:item.name,
            ProjectBrr:ProjectBrr,
            ProjectBrrNum:ProjectBrr.length,
        });
    };



    //删除已选择的项目
    ClickProjectDelete=(e,index) => {
        const dataSource = [...this.state.ProjectBrr];
        dataSource.splice(index, 1);
        if(dataSource.length !== 0){
            if(index < dataSource.length){
                dataSource[index].Num = (index+1);
            }
            if(index+1 < dataSource.length){
                dataSource[index+1].Num = (index+2);
            }
        }
        this.setState({
            ProjectBrr:dataSource,
            ProjectBrrNum:dataSource.length,
        });
    };





    //绑定教练员（辅导员）的返回逻辑处理
    BoundCoachBack(event){
        this.setState({
            BoundCoachId:event.CoachId,
            BoundCoachIdNum:event.CoachIdNum,
        });
    };








    GGAddPopUpBtnOK(){
        let IdtShow = this.state.IdtShow;
        let IdtShowId = this.state.IdtShowId;
        let GroupShow = this.state.GroupShow;
        let GroupInfo = this.state.GroupInfo;
        let ProjectBrr = this.state.ProjectBrr;
        let BoundCoachId = this.state.BoundCoachId;
        let BoundCoachIdNum = this.state.BoundCoachIdNum;
        let MatchId = this.state.MatchId;
        let ProjectMaxNum = this.state.ProjectMaxNum;
        let GGWindowId = this.state.GGWindowId;
        if (IdtShow !== ''&& IdtShow === '运动员'){
            if(GroupShow !== ''&& ProjectBrr.length !== 0&& BoundCoachIdNum !== ''){
                let UserInfo = this.state.UserInfo;
                let UserId = UserInfo.id;
                UserInfo['province'] = '';
                UserInfo['allow_item_num'] = ProjectMaxNum;
                UserInfo['match_id'] = MatchId;
                UserInfo['IdtShow'] = IdtShow;
                UserInfo['identity'] = IdtShowId;
                UserInfo['groups'] = GroupShow;
                UserInfo['group_id'] = GroupInfo.group_id;
                UserInfo['item'] = ProjectBrr;
                UserInfo['coach_id'] = BoundCoachId;
                UserInfo['coach_yy_num'] = BoundCoachIdNum;
                UserInfo['team_id'] = GGWindowId;
                UserInfo['user_id'] = UserId;
                get({url:'user/basic_info'}).then((res) => {
                    if(res.errno === '200'){
                        let User = res.data[0];
                        if(User.type === 1){
                            post({
                                url:'match/match_enroll_team',
                                data:UserInfo,
                            }).then((res) => {
                                if(res.errno === '200'){
                                    UserInfo['id'] = res.enroll_id;
                                    let ClickInfo = {BackType:'OK',UserInfo:UserInfo,};
                                    this.props.GGAddPopUp(ClickInfo);
                                }else if(res.errno === '201'){
                                    let ClickInfo = {BackType:'OK',UserInfo:UserInfo,};
                                    this.props.GGAddPopUp(ClickInfo);
                                }else{
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:res.errmsg+'！',
                                    });
                                }
                            });
                        }else if (User.type === 0) {
                            this.setState({
                                PopUp:'账号被顶',
                                MessageTip:'当前设备已登录其他账户，请重新登录！',
                            });
                        }
                    }else {
                        // sessionStorage.setItem('routesAddress','/game');
                        // const {history} = this.props;
                        // history.push('/login');
                        window.location.href='/login';
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'您有必要的选项未选择！',
                });
            }
        }else if (IdtShow !== ''&& IdtShow !== '运动员'){
            let UserInfo = this.state.UserInfo;
            let UserId = UserInfo.id;
            UserInfo['province'] = '';
            UserInfo['allow_item_num'] = ProjectMaxNum;
            UserInfo['match_id'] = MatchId;
            UserInfo['IdtShow'] = IdtShow;
            UserInfo['identity'] = IdtShowId;
            UserInfo['groups'] = GroupShow;
            UserInfo['group_id'] = '';
            UserInfo['item'] = ProjectBrr;
            UserInfo['coach_id'] = BoundCoachId;
            UserInfo['coach_yy_num'] = BoundCoachIdNum;
            UserInfo['team_id'] = GGWindowId;
            UserInfo['user_id'] = UserId;
            get({url:'user/basic_info'}).then((res) => {
                if(res.errno === '200'){
                    let User = res.data[0];
                    if(User.type === 1){
                        post({
                            url:'match/match_enroll_team',
                            data:UserInfo,
                        }).then((res) => {
                            if(res.errno === '200'){
                                UserInfo['id'] = res.enroll_id;
                                let ClickInfo = {BackType:'OK',UserInfo:UserInfo,};
                                this.props.GGAddPopUp(ClickInfo);
                            }else if(res.errno === '201'){
                                let ClickInfo = {BackType:'OK',UserInfo:UserInfo,};
                                this.props.GGAddPopUp(ClickInfo);
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                });
                            }
                        });
                    }else if (User.type === 0) {
                        this.setState({
                            PopUp:'账号被顶',
                            MessageTip:'当前设备已登录其他账户，请重新登录！',
                        });
                    }
                }else {
                    sessionStorage.setItem('routesAddress','/game');
                    const {history} = this.props;
                    history.push('/login');
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您有必要的选项未选择！',
            });
        }
    };


    GGAddPopUpBtnERR(){
        let ClickInfo = {BackType:'ERR',};
        this.props.GGAddPopUp(ClickInfo);
    };












    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };
    //提示弹窗
    ErrBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
            let ClickInfo = {BackType:'ERR',};
            this.props.GGAddPopUp(ClickInfo);
        }
    };

    AccountNext=(event)=>{
        if(event === 'CrossOut'){
            window.location.reload();
        }else if(event === 'Confirm'){
            window.location.reload();
        }
    };



    render() {
        return (
                <div>
                    <div className={'PopUpBoxBg'}> </div>
                    <div className={'GGAddPopUpBgBox'} style={{top:this.state.BgMainHeight+2,}}>
                        <div className={'GGAddPopBorder'} style={{marginTop:60,}}>
                            <div className={'GGAddPopTitleBox'} style={{marginTop:6,}}>添加人员信息</div>
                            <div className={'GGAddPopMainBox clearfix'} style={{marginTop:2,marginBottom:5,}}>
                                <div className={'GGAPInfoBox'}>
                                    <div className={'GGAPInfoPhoto'}>
                                        <img alt={''}
                                             src={this.state.UserInfo.image}
                                        />
                                    </div>
                                    <div className={'GGAPInfoListBox clearfix'}>
                                        <div className={'GGAPInfoListTit'}>姓名：</div>
                                        <div className={'GGAPInfoListCon'}>{this.state.UserInfo.real_name}</div>
                                    </div>
                                    <div className={'GGAPInfoListBox clearfix'}>
                                        <div className={'GGAPInfoListTit'}>ID：</div>
                                        <div className={'GGAPInfoListCon'}>{this.state.UserInfo.yy_num}</div>
                                    </div>
                                </div>
                                <div className={'GGAPSelectBox'}>
                                    <ul className={'GPClickUlBox clearfix'} style={{marginTop:40,}}>
                                        <li className={'GPClickLiBox'}>
                                            <div className={'GPClickInfoTit'}>*参赛身份</div>
                                            <div className={'GPClickInfoCon'}>
                                                <div className={'GPDownBtn'} onClick={this.ClickIdtDb.bind(this)}>
                                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'7px'} Height={'10px'} Top={'10px'} Right={'23px'}/>
                                                </div>
                                                <div className={'GPSelectShow'}>
                                                    {this.state.IdtShow === ''? '请选择参赛身份' : this.state.IdtShow}
                                                </div>
                                                {
                                                    this.state.IdtBtn === 'true' &&
                                                    <div className={'GPSelect'}>
                                                        {
                                                            this.state.IdtArr.map((item,index)=>
                                                                <div className={'GPOption'}
                                                                     key={index}
                                                                     onClick={() => this.ClickIdtArr(item,index)}>
                                                                    {item.idt}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                        {
                                            this.state.IdtShow === '教练员（辅导员）' || this.state.IdtShow === '领队'||
                                            <div>
                                                <li className={'GPClickLiBox'}>
                                                    <div className={'GPClickInfoTit'}>*参赛组别</div>
                                                    <div className={'GPClickInfoCon'}>
                                                        <div className={'GPDownBtn'} onClick={this.ClickGroupDb.bind(this)}>
                                                            <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'7px'} Height={'10px'} Top={'10px'} Right={'23px'}/>
                                                        </div>
                                                        <div className={'GPSelectShow'}>
                                                            {this.state.GroupShow === ''? '请选择参赛组别' : this.state.GroupShow}
                                                        </div>
                                                        {
                                                            this.state.GroupBtn === 'true' &&
                                                            <div className={'GPSelect'}>
                                                                {
                                                                    this.state.GroupArr.map((item,index)=>
                                                                        <div className={'GPOption'}
                                                                             key={index}
                                                                             onClick={() => this.ClickGroupArr(item,index)}>
                                                                            {item.name}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </li>
                                                <li className={'GPClickLiBox clearfix'}>
                                                    <div className={'GPClickInfoTit'}>*参赛项目</div>
                                                    <div className={'GPClickInfoCon'}>
                                                        {
                                                            this.state.ProjectBrrNum !== 0 &&
                                                            <div>
                                                                {
                                                                    this.state.ProjectBrr.map((item,index) =>
                                                                        <div className={'GPProjectSelectShow'} key={index}>
                                                                            <div className={'GPProjectSelectWord'} title={item.item_name}>{item.item_name}</div>
                                                                            <div className={'GPProjectSelectDelete'}
                                                                                 onClick={() => this.ClickProjectDelete(item,index)}> </div>
                                                                            <span className={'GPManyNum'}>{item.Num}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                        <div className={'GPProjectAdd'}>
                                                            <div className={'GPProjectAddBtn'} onClick={this.ClickProjectAdd.bind(this)}>
                                                                <span>添加</span>
                                                                <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'7px'} Height={'10px'} Top={'10px'} Right={'12px'}/>
                                                            </div>
                                                            {
                                                                this.state.ProjectBtn === 'true' &&
                                                                <div className={'GPProjectSelect'}>
                                                                    {
                                                                        this.state.ProjectArr.map((item,index)=>
                                                                            <div className={'GPProjectOption'}
                                                                                 key={index}
                                                                                 onClick={() => this.ClickProjectArr(item,index)}>
                                                                                {item.name}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        }
                                    </ul>
                                    {
                                        this.state.IdtShow === '教练员（辅导员）' || this.state.IdtShow === '领队'||
                                        <BoundCoach
                                            BoundCoachBack={this.BoundCoachBack.bind(this)}
                                            IdNum={this.state.IdNum}
                                            BoundCoachIdNum={this.state.BoundCoachIdNum}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'GGAddPopUpBtnBox clearfix'}>
                            <div className={'GGAddPopUpBtnOK'} onClick={this.GGAddPopUpBtnOK.bind(this)}>确定</div>
                            <div className={'GGAddPopUpBtnERR'} onClick={this.GGAddPopUpBtnERR.bind(this)}>取消</div>
                        </div>
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '运动员信息错误' &&
                        <PopUp
                            ChildBackClick={this.ErrBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '账号被顶' &&
                        <PopUp
                            ChildBackClick={this.AccountNext.bind(this)}
                            type={'失败'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default GGAddPopUp;
