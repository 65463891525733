import React, { Component } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import './round.css';
import './ReactDatePicker.css';


class ReactDatePicker extends Component {

    constructor(props){
        super(props);
        this.state = {
            time:'',
            timeType:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let history = nextProps;
        this.setState({
            timeType:history.Type,
        });
    };

    componentDidMount=function() {
        let history = this.props;
        this.setState({
            timeType:history.Type,
            time:'请选择'+history.Type+'时间',
        });
    };


    DataVal=(date)=>{
        let timeType = this.state.timeType;
        if(date !== null){
            this.setState({
                time:moment(date).format('YYYY-MM-DD'),
            });
            this.props.BackTimeClick(moment(date).format('YYYY-MM-DD'));
        }else {
            this.setState({
                time:'请选择'+timeType+'时间',
            });
            this.props.BackTimeClick('');
        }
    };

    render() {
        return (
                <div className={'ReactDatePicker'}>
                    <DatePicker
                        dateFormat={'YYYY-MM-DD'}
                        onChange={this.DataVal.bind(this)}
                    />
                    <div className={'TimeShow'}>{this.state.time}</div>
                </div>
        );
    }
}


export default ReactDatePicker;
