import React, { Component } from 'react';
import './round.css';


class Timer extends Component {

    constructor(props){
        super(props);
        this.state = {
            Second:'',//倒计时总秒数量
            SecondId:'',//倒计时项目的ID
            Time:'',//倒计时项目的ID
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            Second:history.Second,
            SecondId:history.SecondId,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        let k = history.Second;
        if(k>0){
            let hour = Math.floor(k / 3600) ;
            let minute = Math.floor(k / 60) - (hour * 60);
            let second = Math.floor(k) - (hour * 60 * 60) - (minute * 60);
            if (hour <= 9) hour = '0' + hour; //格式设置
            if (minute <= 9) minute = '0'+ minute;
            if (second <= 9) second = '0' + second;
            this.setState({
                Second:history.Second,
                SecondId:history.SecondId,
                Time: hour+':'+minute+':'+second,
            });
        }else {
            this.setState({ Time: '——', });
        }
        let Second = history.Second;
        setInterval(() => {
            let i = Second--;
            if(i >= 0){
                let hour = Math.floor(i / 3600) ;
                let minute = Math.floor(i / 60) - (hour * 60);
                let second = Math.floor(i) - (hour * 60 * 60) - (minute * 60);
                if (hour <= 9) hour = '0' + hour; //格式设置
                if (minute <= 9) minute = '0'+ minute;
                if (second <= 9) second = '0' + second;
                this.setState({ Time: hour+':'+minute+':'+second, });
            }else if(i < 0){
                this.props.ChildTimerBack({SecondId:history.SecondId,});
                this.setState({ Time: '——', });
                clearInterval();
            }
        }, 1000);
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };

    render() {
        return (
                <span>{this.state.Time}</span>
        );
    }
}


export default Timer;
