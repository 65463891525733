import React, { Component } from 'react';
import AuthHeader from "../handf/AuthHeader";
import md5 from 'md5'
//import {API_URL} from "../../ajax/config";
import './auth.css';
import PopUp from "../round/PopUp";
import Second from "../round/Second";
import {post} from "../../ajax/tools";


class Forget extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : window.innerHeight-164,
            ForgetStep: 1,//修改密码步骤
            IdtPeopleStyle:{height:37,borderBottom:'3px solid #FB611D',color:'#000000',},
            IdtGroupStyle:{},
            Identity:'个人',
            IdOrCom:'',//输入身份证号或单位全称
            Phone:'',//输入手机号
            ForgetCode:'',//输入验证码
            ForgetPasswordNew:'',//输入新密码
            ForgetPasswordNewRepeat:'',//确认输入新密码
            PopUp:'',
            ForgetCodeErrmsg:'',
            KeyID:'',
        };
    }
    componentDidMount=function() {
        sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        if (contentHeight < winHeight) {
            this.setState({
                winHeight : winHeight-164,
            });
        }else{
            this.setState({
                winHeight : contentHeight-164,
            });
        }
    };

    //当选择个人的时候
    IdtPeopleClick(){
        this.setState({
            IdtPeopleStyle:{height:37,borderBottom:'3px solid #FB611D',color:'#000000',},
            IdtGroupStyle:{},
            Identity:'个人',
        });
    };
    //当选择团体的时候
    IdtGroupClick(){
        this.setState({
            IdtPeopleStyle:{},
            IdtGroupStyle:{height:37,borderBottom:'3px solid #FB611D',color:'#000000',},
            Identity:'团体',
        });
    };

    //输入身份证号或单位全称
    IdOrComChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            IdOrCom: event.target.value,
        });
    };
    //输入手机号
    PhoneChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            Phone: event.target.value,
        });
    };
    //输入验证码
    ForgetCodeChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            ForgetCode: event.target.value,
        });
    };

    //输入新密码
    ForgetPasswordNew(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            ForgetPasswordNew: event.target.value,
        });
    };
    //确认输入新密码
    ForgetPasswordNewRepeat(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            ForgetPasswordNewRepeat: event.target.value,
        });
    };

    //点击获取验证码
    ForgetCodeBtnClick(){
        post({
            url:'passport/sms_code',
            data:{
                mobile:this.state.Phone,
            },
        }).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    ForgetCodeErrmsg:res.errmsg,
                });
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };
    SecondBackChange=(event)=>{
        if(event === 'Stop'){
            this.setState({
                ForgetCodeErrmsg:'',
            });
        }
    };



    //点击第一步的下一步按钮
    ForgetStepOneBtnClick(){
        post({
            url:'passport/sms_check',
            data:{
                mobile:this.state.Phone,
                smscode:this.state.ForgetCode,
            },
        }).then((res) => {
            if(res.errno === '200'){
                post({
                    url:'passport/find_password1',
                    data:{
                        username:this.state.IdOrCom,
                        mobile:this.state.Phone,
                        type:this.state.Identity === '个人' ? 1 : (this.state.Identity === '团体' ? 2 : ''),
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            ForgetStep: 2,
                            KeyID:res.keyID,
                        });
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };
    //点击第二步的下一步按钮
    ForgetStepTwoBtnClick= () => {
        let a = this.state.ForgetPasswordNew;
        let b = this.state.ForgetPasswordNewRepeat;
        // let regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        let regex = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}$/;
        let sb = regex.test(a)
        if(a !== '' && b !== ''){
            if(a === b){
                if(a.length >= 8  && b.length >= 8){
                    if (sb === true){
                        const ForgetPasswordNew = md5(a);
                        post({
                            url:'passport/find_password2',
                            data:{
                                id:this.state.KeyID,
                                password:ForgetPasswordNew,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    ForgetStep: 3,
                                });
                            }else {
                                this.setState({
                                    PopUp:'错误提示同一弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:'密码要求必须含有英文和数字！',
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'密码必须不少于八位！',
                    });
                }
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:'两次输入的密码必须一致！',
                });
            }
        }else {
            this.setState({
                PopUp:'错误提示同一弹窗',
                MessageTip:'您有必要信息没有填写！',
            });
        }

    };


    //点击返回登录
    ReturnLoginClick(){
        const {history} = this.props;
        history.push('/login');
    };
    //点击第二步的上一步按钮
    ReturnUpClick(){
        this.setState({
            ForgetStep: 1,
        });
    };



    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
        }
    };

    render() {
        return (
                <div className={'AuthBox'}>
                    <AuthHeader/>
                    <div className={'AuthBg'} style={{height:this.state.winHeight,}}>
                        <div className={'AuthBgColor'} style={{height:this.state.winHeight,}}> </div>
                        <div className={'LoginAndForgetBox'}>
                            <div className={'LoginAndForgetLogo'}>
                                <div className={'FSLogo'}> </div>
                                <div className={'FSTitle'}>中国航协模拟飞行服务平台</div>
                            </div>
                            <div className={'LoginAndForgetContent'}>
                                <div className={'ForgetTitle'}>
                                    <div className={'ForgetTitleCenter'}>
                                        <div className={'ForgetTitleIco'}> </div>
                                        <div className={'ForgetTitleWord'}>修改密码</div>
                                    </div>
                                    {
                                        this.state.ForgetStep === 1 &&
                                        <div className={'ForgetStepOne'}>
                                            <div className={'IdtBox'}>
                                                <div className={'IdtPeople'} style={this.state.IdtPeopleStyle} onClick={this.IdtPeopleClick.bind(this)}>个人</div>
                                                <div className={'IdtEmpty'}> </div>
                                                <div className={'IdtGroup'} style={this.state.IdtGroupStyle} onClick={this.IdtGroupClick.bind(this)}>团体</div>
                                            </div>
                                            <input className={'IdOrComInput'}
                                                   type={'text'}
                                                   name={'IdOrCom'}
                                                   value={this.state.IdOrCom}
                                                   onChange={this.IdOrComChange.bind(this)}
                                                   placeholder={this.state.Identity === '个人' ? '身份证号' : (this.state.Identity === '团体' ? '单位全称':'')}
                                                   autoComplete={'off'}
                                            />
                                            <input className={'PhoneInput'}
                                                   type={'text'}
                                                   name={'Phone'}
                                                   value={this.state.Phone}
                                                   onChange={this.PhoneChange.bind(this)}
                                                   placeholder={this.state.Identity === '个人' ? '手机号' : (this.state.Identity === '团体' ? '管理员手机号':'')}
                                                   autoComplete={'off'}
                                            />
                                            <div className={'ForgetCodeBox'}>
                                                <input className={'ForgetCodeInput'}
                                                       type={'text'}
                                                       name={'ForgetCode'}
                                                       value={this.state.ForgetCode}
                                                       onChange={this.ForgetCodeChange.bind(this)}
                                                       placeholder={'请输入验证码'}
                                                       autoComplete={'off'}
                                                />
                                                {
                                                    this.state.ForgetCodeErrmsg === '' &&
                                                    <button className={'ForgetCodeBtn'} onClick={this.ForgetCodeBtnClick.bind(this)}>获取验证码</button>

                                                }
                                                {
                                                    this.state.ForgetCodeErrmsg !== '' &&
                                                    <button className={'ForgetCodeBtn'} style={{backgroundColor:'#D5D5D5',}}>
                                                        <Second SecondBackChange={this.SecondBackChange.bind(this)}/>
                                                        <span style={{width:'auto',float:'right',}}>成功</span>
                                                    </button>
                                                }
                                            </div>
                                            <button className={'ForgetStepBtn'} onClick={this.ForgetStepOneBtnClick.bind(this)}>下一步</button>
                                            <div className={'ReturnLogin'} onClick={this.ReturnLoginClick.bind(this)}>返回登录</div>
                                        </div>
                                    }
                                    {
                                        this.state.ForgetStep === 2 &&
                                        <div className={'ForgetStepTwo'}>
                                            <div className={'IdtTitle'}>
                                                {
                                                    this.state.Identity === '个人' &&
                                                    <div className={'IdtTitlePeIco'}> </div>
                                                }
                                                {
                                                    this.state.Identity === '团体' &&
                                                    <div className={'IdtTitleComIco'}> </div>
                                                }
                                                <div className={'IdtTitleWord'}>{this.state.Identity}密码修改</div>
                                            </div>
                                            <div>
                                                <form>
                                                    <input className={'ForgetPassword'}
                                                           style={{display:'none',}}
                                                           type={'text'}
                                                           name={'Account'}
                                                           autoComplete={'off'}
                                                    />
                                                    <input className={'ForgetPassword'}
                                                           style={{display:'none',}}
                                                           type={'password'}
                                                           name={'LoginPassword'}
                                                           autoComplete={'new-password'}
                                                    />
                                                    <input className={'ForgetPassword'}
                                                           type={'password'}
                                                           name={'ForgetPasswordNew'}
                                                           value={this.state.ForgetPasswordNew}
                                                           onChange={this.ForgetPasswordNew.bind(this)}
                                                           placeholder={'设置新密码'}
                                                           autoComplete={'new-password'}
                                                           minLength={'6'}
                                                    />
                                                    <input className={'ForgetPassword'}
                                                           style={{marginBottom:2,}}
                                                           type={'password'}
                                                           name={'ForgetPasswordNewRepeat'}
                                                           value={this.state.ForgetPasswordNewRepeat}
                                                           onChange={this.ForgetPasswordNewRepeat.bind(this)}
                                                           placeholder={'设置新密码'}
                                                           autoComplete={'new-password'}
                                                           minLength={'6'}
                                                    />
                                                    <div className={'SetupPasswordWord'} style={{textIndent:0,fontSize:11,marginBottom:36,}}>*密码要求必须含有英文和数字、不少于八位。</div>
                                                </form>
                                                <button className={'ForgetStepBtn'} onClick={this.ForgetStepTwoBtnClick.bind(this)}>下一步</button>
                                            </div>
                                            <div className={'ReturnLogin'} style={{width:200,}}>
                                                <div className={'ReturnLoginLeft'} onClick={this.ReturnUpClick.bind(this)}>上一步</div>
                                                <div className={'ReturnLoginRight'} onClick={this.ReturnLoginClick.bind(this)}>返回登录</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.ForgetStep === 3 &&
                                        <div className={'ForgetStepThree'}>
                                            <div className={'IdtTitle'}>
                                                {
                                                    this.state.Identity === '个人' &&
                                                    <div className={'IdtTitlePeIco'}> </div>
                                                }
                                                {
                                                    this.state.Identity === '团体' &&
                                                    <div className={'IdtTitleComIco'}> </div>
                                                }
                                                <div className={'IdtTitleWord'}>{this.state.Identity}密码修改</div>
                                            </div>
                                            <div className={'ForgetPasswordResult'} style={{marginTop:60,marginBottom:14,color:'#ffa502',fontSize:19,}}>您已成功设置新密码</div>
                                            <div className={'ForgetPasswordResult'} style={{marginTop:14,marginBottom:100,color:'#ffa502',fontSize:17,}}>请尝试新密码登录</div>
                                            <button className={'ForgetStepBtn'} onClick={this.ReturnLoginClick.bind(this)}>马上登录</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.PopUp === '错误提示同一弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'错误'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default Forget;
