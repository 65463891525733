import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import GrBase from "./GrBase";
import GrTrain from "./GrTrain";
import GrAvSchool from "./GrAvSchool";
import GrDrill from "./GrDrill";
import GrGame from "./GrGame";
import GrDevice from "./GrDevice";
import GrCounselor from "./GrCounselor";
import GrYunCheng from "./GrYunCheng";
import GrService from "./GrService";
import GrYunChengPer from "./GrYunChengPer";
import TeacherDetails from "../inquire/TeacherDetails";
import TrainDetails from "../inquire/TrainDetails";
import EquipmentDetails from "../inquire/EquipmentDetails";
import DepotDetails from "../inquire/DepotDetails";
import SchoolDetails from "../inquire/SchoolDetails";
import GrDiploma from "./GrDiploma";
import Six1 from "./Six1";
import PopUp from "../round/PopUp";
import './member.css';
import {get,} from "../../ajax/tools";




class Member extends Component {
    constructor(props){
        super(props);
        this.state = {
            winHeight :'',
            AccountType:'Group',

            PageState:'member',
            awardType:'',

            User:{},
            NowPositionName:'',
            NowPositionNextName:'',

            GroupArr:JSON.parse(sessionStorage.getItem('GroupArr')),


            user_name:'',

        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }

        let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
        if (!tokenAuth) {
            sessionStorage.removeItem('tokenAuth');
            sessionStorage.removeItem('GroupArr');
            sessionStorage.removeItem('PersonalArr');
            window.location.reload();
        }else {
            this.setState({
                user_name:tokenAuth.user_name,
            });
        }
        let GroupArr = JSON.parse(sessionStorage.getItem('GroupArr'));
        if (!GroupArr) {
            alert('请登录单位账号！')
            sessionStorage.removeItem('tokenAuth');
            sessionStorage.removeItem('GroupArr');
            sessionStorage.removeItem('PersonalArr');
            window.location.reload();
        }

        let Route = this.props.location.pathname;
        // let index = Route.lastIndexOf("/");//'/'最后出现的位置
        let TwoIndex = Route.indexOf ("_");//'_'第一次出现的位置
        let ThreeIndex = Route.lastIndexOf ("_");//'_'最后出现的位置
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                let sb = Route.substring(ThreeIndex+1);
                let dsb = Route.substring(TwoIndex+1,ThreeIndex);
                if (sb === 'grBase'){
                    this.setState({
                        NowPositionName:'基础信息',
                    });
                }else if (sb === 'grGame'){
                    this.setState({
                        NowPositionName:'赛事活动',
                    });
                }else if (sb === 'grTJF'){
                    this.setState({
                        NowPositionName:'推荐辅导员',
                    });
                }else if (sb === 'gryc'){
                    this.setState({
                        NowPositionName:'云程数据',
                    });
                }else if (sb === 'service'){
                    this.setState({
                        NowPositionName:'服务中心',
                    });
                    if (dsb === 'gryc'){
                        this.setState({
                            NowPositionNextName:'> 云程数据',
                        });
                    }else if (dsb === 'peyc'){
                        this.setState({
                            NowPositionNextName:'> 个人云程',
                        });
                    }
                }else if (sb === 'grsix'){
                    this.setState({
                        NowPositionName:'初教六激活',
                    });
                }else if (sb === 'grtr'){
                    if(User.isinstitutions !== 0){
                        this.setState({
                            NowPositionName:'训练中心档案',
                            NowPositionNextName:'>机构信息',
                        });
                    }else {
                        const {history} = this.props;
                        history.push('/member_grBase');
                    }

                }else if (sb === 'grschool'){
                    if(User.isyoung_school !== 0){
                        this.setState({
                            NowPositionName:'青少年航校',
                            NowPositionNextName:'>航校信息',
                        });
                    }else {
                        const {history} = this.props;
                        history.push('/member_grBase');
                    }

                }else if (sb === 'grtrba'){
                    if(User.istrain_base !== 0){
                        this.setState({
                            NowPositionName:'实训基地',
                            NowPositionNextName:'>基地信息',
                        });
                    }else {
                        const {history} = this.props;
                        history.push('/member_grBase');
                    }

                }else if (sb === 'grdiv'){
                    if(User.isdivice !== 0){
                        this.setState({
                            NowPositionName:'设备制造商档案',
                            NowPositionNextName:'>设备信息',
                        });
                    }else {
                        const {history} = this.props;
                        history.push('/member_grBase');
                    }
                }else if (sb === 'grryq'){
                    get({url:'user/get_honor_diploma'}).then((res) => {
                        if(res.errno === '200' && res.data.length !== 0){
                            this.setState({
                                NowPositionName:'荣誉墙',
                            });
                        }else {
                            const {history} = this.props;
                            history.push('/member_grBase');
                        }
                    });
                }
                get({url:'user/sys_msg_show_read'}).then((res) => {
                    if(res.errno === '200'){
                        if (res.data === 0){
                            this.setState({
                                newMsg:'0',
                            });
                        }else {
                            this.setState({
                                PopUpSYS:'未读系统消息',
                                MessageTipSYS:'您有未读的系统消息！',
                            });
                        }
                    }
                });
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    }


    AllClick = () => {
        this.setState({
        });
    };


    //退出
    DropOut(){
        sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
        window.location.reload();
        this.setState({
        });
    };
    //密码修改
    ChangePassword(){
        const {history} = this.props;
        history.push('/forget');
    };

    //点击会员中心的左侧菜单
    AccountListClick=(item,index) =>{
        const {history} = this.props;
        history.push(item.router);
    };


    //下一级菜单组件点击返回
    ChildBackNavClick=(event)=>{
        let NowPositionName = this.state.NowPositionName;
        if (event !== ''){
            if (event === '云程数据'){
                const {history} = this.props;
                history.push('/member_gryc_service');
            }else if (event === '个人云程'){
                const {history} = this.props;
                history.push('/member_peyc_service');
            }
            this.setState({
                NowPositionNextName:'> '+event,
            });
        }else {
            if (NowPositionName === '服务中心'){
                const {history} = this.props;
                history.push('/member_service');
            }else {
                const {history} = this.props;
                history.push('/member_grBase');
            }
            this.setState({
                NowPositionNextName:'',
            });
        }
    };

    //点击子组件中的返回按钮
    BackButton=(event)=>{
        if (event === 'BackBtn'){
            this.setState({
                PageState:'member',
                TeacherId:''
            });
        }
    };




    //个人个人能力点击教练员查看详情
    BackCoachDetails=(event)=>{
        if(event.coach_id){
            this.setState({
                PageState:'coachDetails',
                TeacherId:event.coach_id,
            });
        }
    };
    //教练员信息点击查看机构详情
    BackTrainDetails=(event)=>{
        this.setState({
            PageState:'trainDetails',
            TrainId:event.id,
        });
    };


    BackDeviceDetails=(event)=>{
        this.setState({
            PageState:'deviceDetails',
            DeviceId:event.id,
        });
    };


    BackDrillDetails=(event)=>{
        this.setState({
            PageState:'drillDetails',
            DepotId:event.id,
        });
    };

    GrAvSchoolDetails=(event)=>{
        this.setState({
            PageState:'avSchoolDetails',
            AvSchoolId:event.id,
        });
    };




    ChildBackSYSClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUpSYS:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUpSYS:'',
            });
            const {history} = this.props;
            history.push('/message');
        }
    };





    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };





    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    {
                        this.state.PageState === 'member'&&
                        <div className={'MainContent'}>
                            <div className={'MemberTopInfo clearfix'}>
                                <div className={'NowUserName MemberTopInfoWord'}>当前用户：{this.state.user_name}</div>
                                <div className={'MemberTopTitle MemberTopInfoWord'}>欢迎使用中国航协模拟飞行服务平台</div>
                                <div className={'MemberTopRight'}>
                                    <div className={'DropOut'} onClick={this.DropOut.bind(this)}>退出</div>
                                    <div className={'ChangePassword'} onClick={this.ChangePassword.bind(this)}>密码修改</div>
                                </div>
                            </div>
                            <div className={'MemberMainBox clearfix'}>
                                <div className={'MemberMainBoxLeft'} style={{height:window.innerHeight-137}}>
                                    <div style={{width:194,height:42,backgroundColor:'0488CA',}}> </div>
                                    <div className={'MemberMainBoxLeft clearfix scrollBarTwo'} style={{height:window.innerHeight-180}}>
                                        {
                                            this.state.GroupArr&&
                                            <ul className={'MemberUList'}>
                                                {
                                                    this.state.GroupArr.map((item,index) =>
                                                        <li className={'MemberList'}
                                                            key={index}
                                                            style={item.nav === this.state.NowPositionName ? {backgroundColor:'#FFFFFF',borderLeftColor:'#EB6100',color:'#000000'}:{}}
                                                            onClick={() => this.AccountListClick(item,index)}
                                                        >
                                                            {item.nav}
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        }
                                    </div>
                                </div>
                                <div className={'MemberMainBoxRight'} style={{height:window.innerHeight-137}}>
                                    <div className={'MemberMainBoxTop'}>您当前位置：会员中心 > {this.state.NowPositionName} {this.state.NowPositionNextName}</div>
                                    {
                                        this.state.NowPositionName === '基础信息'&& this.state.AccountType === 'Group'&&
                                        <GrBase/>
                                    }
                                    {
                                        this.state.NowPositionName === '训练中心档案'&& this.state.AccountType === 'Group'&&
                                        <GrTrain
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                            BackCoachDetails={this.BackCoachDetails.bind(this)}
                                            BackTrainDetails={this.BackTrainDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '青少年航校'&& this.state.AccountType === 'Group'&&
                                        <GrAvSchool
                                            GrAvSchoolDetails={this.GrAvSchoolDetails.bind(this)}
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '实训基地'&& this.state.AccountType === 'Group'&&
                                        <GrDrill
                                            BackDrillDetails={this.BackDrillDetails.bind(this)}
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '赛事活动'&& this.state.AccountType === 'Group'&&
                                        <GrGame/>
                                    }
                                    {
                                        this.state.NowPositionName === '推荐辅导员'&& this.state.AccountType === 'Group'&&
                                        <GrCounselor/>
                                    }
                                    {
                                        this.state.NowPositionName === '设备制造商档案'&& this.state.AccountType === 'Group'&&
                                        <GrDevice
                                            BackDeviceDetails={this.BackDeviceDetails.bind(this)}
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '荣誉墙'&& this.state.AccountType === 'Group'&&
                                        <GrDiploma/>
                                    }
                                    {
                                        this.state.NowPositionName === '初教六激活'&&
                                        <Six1/>
                                    }



                                    {
                                        this.state.NowPositionName === '云程数据'&& this.state.AccountType === 'Group'&&
                                        <GrYunCheng
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }



                                    {
                                        this.state.NowPositionName === '服务中心'&& this.state.AccountType === 'Group'&& this.state.NowPositionNextName === '> 云程数据'&&
                                        <GrYunCheng
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }

                                    {
                                        this.state.NowPositionName === '服务中心'&& this.state.AccountType === 'Group'&& this.state.NowPositionNextName === '> 个人云程'&&
                                        <GrYunChengPer
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }

                                    {
                                        this.state.NowPositionName === '服务中心'&& this.state.AccountType === 'Group'&& this.state.NowPositionNextName === ''&&
                                        <GrService
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }



                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.PageState === 'coachDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>教员查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>INSTRUCTOR</div>
                            </div>
                            <TeacherDetails
                                TeacherId={this.state.TeacherId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    {
                        this.state.PageState === 'trainDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>训练中心查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>training center</div>
                            </div>
                            <TrainDetails
                                TrainId={this.state.TrainId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    {
                        this.state.PageState === 'deviceDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>设备查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>EQUIPMENT</div>
                            </div>
                            <EquipmentDetails
                                EquipmentId={this.state.DeviceId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    {
                        this.state.PageState === 'drillDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>实训基地查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>training base</div>
                            </div>
                            <DepotDetails
                                DepotId={this.state.DepotId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    {
                        this.state.PageState === 'avSchoolDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>青少年航校查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>aviation school</div>
                            </div>
                            <SchoolDetails
                                SchoolId={this.state.AvSchoolId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                    {
                        this.state.PopUpSYS=== '未读系统消息' &&
                        <PopUp
                            ChildBackClick={this.ChildBackSYSClick.bind(this)}
                            type={'失败'}
                            message={this.state.MessageTipSYS}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Member;
