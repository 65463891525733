import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import ApproveList from "./ApproveList";
import NewApprove from "./NewApprove";
import AllYear from "./AllYear";
import AppRecord from "./AppRecord";
import EquipmentDetails from "../inquire/EquipmentDetails";
import TrainDetails from "../inquire/TrainDetails";
import SchoolDetails from "../inquire/SchoolDetails";
import DepotDetails from "../inquire/DepotDetails";
import PopUp from "../round/PopUp";
import './approve.css';
import {get,} from "../../ajax/tools";

class Approve extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : '',
            ApproveModule:'',//默认'List'是已认证信息列表，'NewApprove'是新的认证，'AllYear'是年检，'Record'是记录
            ApproveState:'',//'Have'为已经有认证，'Not'为未有认证时
            NewApproveStep:1,//新认证的进度，用于正在认证的进度
            AllYearStep:1,//年检的进度，用于正在年检的进度
            ApproveNavArr:[
                {
                    id:'0',
                    ZhName:'返回会员中心',
                    EnName:'aviation school',
                    router:'/member',
                },
                {
                    id:'1',
                    ZhName:'青少年航校',
                    EnName:'aviation school',
                    router:'/approve_s',
                },
                {
                    id:'3',
                    ZhName:'训练中心',
                    EnName:'training center',
                    router:'/approve_t',
                },
                {
                    id:'2',
                    ZhName:'设备',
                    EnName:'Equipment firm',
                    router:'/approve_d',
                },
                {
                    id:'4',
                    ZhName:'实训基地',
                    EnName:'training base',
                    router:'/approve_x',
                },
            ],
            GroupOrPersonalName:'',
            NowNavListZhName:'',
            NowNavListEnName:'',
            RecordPage:'',//是否是记录页面进入到进度模块的
            DetailsPage:'not',//是否是详情页面状态
            ApproveId:'',//查看详情的产品ID
            RecordPageId:'',//记录页面进入申请流程第三步时的获取的该订单id
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }

        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 1){
                    this.setState({
                        User:User,
                        GroupOrPersonalName:User.org_name,
                    });
                }else if(User.type === 0){
                    this.setState({
                        PopUp:'提示弹窗2',
                        MessageTip2:'请使用机构账户登录！',
                    });
                }
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });

        let ThreeIndex = this.props.location.pathname.lastIndexOf ("_");
        let sb = this.props.location.pathname.substring(ThreeIndex+1);
        if (sb === 'd'){
            this.setState({
                NowNavListZhName:'设备',
                NowNavListEnName:'Equipment firm',
            });
            get({url:'agency/read_device_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveState:'Have',
                        ApproveModule:'List',
                    });
                }else{
                    get({url:'agency/read_suborg_apply/1'}).then((res) => {
                        if(res.errno === '200'){
                            let RecordArr = res.data;
                            if (RecordArr.length !== 0){
                                this.setState({
                                    ApproveModule:'Record',
                                    ApproveState:'Not',
                                });
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        }else {
                            this.setState({
                                ApproveState:'Not',
                                ApproveModule:'List',
                            });
                        }
                    });
                }
            });
        }else if (sb === 't'){
            this.setState({
                NowNavListZhName:'训练中心',
                NowNavListEnName:'training center',
            });
            get({url:'agency/read_train_institute'}).then((res) => {
                if(res.errno === '200'){
                    let a = res.data;
                    let sb = [];
                    for(let i=0; i<a.length; i++){
                        let s = a[i];
                        if (s.code !== ''){
                            sb.push(s);
                        }
                    }
                    if (sb.length !== 0){
                        this.setState({
                            ApproveState:'Have',
                            ApproveModule:'List',
                        });
                    }else {
                        get({url:'agency/read_suborg_apply/2'}).then((res) => {
                            if(res.errno === '200'){
                                let RecordArr = res.data;
                                if (RecordArr.length !== 0){
                                    this.setState({
                                        ApproveModule:'Record',
                                        ApproveState:'Not',
                                    });
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        });
                    }
                }else{
                    get({url:'agency/read_suborg_apply/2'}).then((res) => {
                        if(res.errno === '200'){
                            let RecordArr = res.data;
                            if (RecordArr.length !== 0){
                                this.setState({
                                    ApproveModule:'Record',
                                    ApproveState:'Not',
                                });
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        }else {
                            this.setState({
                                ApproveState:'Not',
                                ApproveModule:'List',
                            });
                        }
                    });
                }
            });
        }else if (sb === 's'){
            this.setState({
                NowNavListZhName:'青少年航校',
                NowNavListEnName:'aviation school',
            });
            get({url:'agency/read_youth_aviat_school'}).then((res) => {
                if(res.errno === '200'){
                    let a = res.data;
                    let sb = [];
                    for(let i=0; i<a.length; i++){
                        let s = a[i];
                        if (s.code !== ''){
                            sb.push(s);
                        }
                    }
                    if (sb.length !== 0){
                        this.setState({
                            ApproveState:'Have',
                            ApproveModule:'List',
                        });
                    }else {
                        get({url:'agency/read_suborg_apply/3'}).then((res) => {
                            if(res.errno === '200'){
                                let RecordArr = res.data;
                                if (RecordArr.length !== 0){
                                    this.setState({
                                        ApproveModule:'Record',
                                        ApproveState:'Not',
                                    });
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        });
                    }
                }else{
                    get({url:'agency/read_suborg_apply/3'}).then((res) => {
                        if(res.errno === '200'){
                            let RecordArr = res.data;
                            if (RecordArr.length !== 0){
                                this.setState({
                                    ApproveModule:'Record',
                                    ApproveState:'Not',
                                });
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        }else {
                            this.setState({
                                ApproveState:'Not',
                                ApproveModule:'List',
                            });
                        }
                    });
                }
            });
        }else if (sb === 'x'){
            this.setState({
                NowNavListZhName:'实训基地',
                NowNavListEnName:'training base',
            });
            get({url:'agency/read_train_base'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveState:'Have',
                        ApproveModule:'List',
                    });
                }else{
                    get({url:'agency/read_suborg_apply/4'}).then((res) => {
                        if(res.errno === '200'){
                            let RecordArr = res.data;
                            if (RecordArr.length !== 0){
                                this.setState({
                                    ApproveModule:'Record',
                                    ApproveState:'Not',
                                });
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        }else {
                            this.setState({
                                ApproveState:'Not',
                                ApproveModule:'List',
                            });
                        }
                    });
                }
            });
        }
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //点击左侧菜单
    AccountListClick=(item,index) =>{
        const {history} = this.props;
        history.push(item.router);
    };


    //点击按钮开始认证新的一项
    ApproveStateBtn=()=>{
        this.setState({
            ApproveModule:'NewApprove',
            NewApproveStep:1,
        });
    };
    //点击按钮进入当前部分的申请记录
    RecordStateBtn=()=>{
        this.setState({
            ApproveModule:'Record',
        });
    };


    //点击按钮返回认证信息列表他页面
    BackButton=()=>{
        // console.log(this.state.RecordPage)
        // console.log(this.state.ApproveModule)
        if (this.state.RecordPage === 'yes'&& this.state.ApproveModule !== 'Record'){
            this.setState({
                ApproveModule:'Record',
                RecordPage:'',
            });
        }else if (this.state.RecordPage === ''&& this.state.ApproveModule === 'NewApprove'){
            this.setState({
                RecordPage:'',
                ApproveModule:'',
                ApproveState:'',
            });
            if(this.state.NowNavListZhName === '设备'){
                get({url:'agency/read_device_info'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            ApproveState:'Have',
                            ApproveModule:'List',
                        });
                    }else{
                        get({url:'agency/read_suborg_apply/1'}).then((res) => {
                            if(res.errno === '200'){
                                let RecordArr = res.data;
                                if (RecordArr.length !== 0){
                                    this.setState({
                                        ApproveModule:'Record',
                                        ApproveState:'Not',
                                    });
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        });
                    }
                });
            }else if(this.state.NowNavListZhName === '训练中心'){
                get({url:'agency/read_train_institute'}).then((res) => {
                    if(res.errno === '200'){
                        let a = res.data;
                        let sb = [];
                        for(let i=0; i<a.length; i++){
                            let s = a[i];
                            if (s.code !== ''){
                                sb.push(s);
                            }
                        }
                        if (sb.length !== 0){
                            this.setState({
                                ApproveState:'Have',
                                ApproveModule:'List',
                            });
                        }else {
                            get({url:'agency/read_suborg_apply/2'}).then((res) => {
                                if(res.errno === '200'){
                                    let RecordArr = res.data;
                                    if (RecordArr.length !== 0){
                                        this.setState({
                                            ApproveModule:'Record',
                                            ApproveState:'Not',
                                        });
                                    }else {
                                        this.setState({
                                            ApproveState:'Not',
                                            ApproveModule:'List',
                                        });
                                    }
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            });
                        }
                    }else{
                        get({url:'agency/read_suborg_apply/2'}).then((res) => {
                            if(res.errno === '200'){
                                let RecordArr = res.data;
                                if (RecordArr.length !== 0){
                                    this.setState({
                                        ApproveModule:'Record',
                                        ApproveState:'Not',
                                    });
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        });
                    }
                });
            }else if(this.state.NowNavListZhName === '青少年航校'){
                get({url:'agency/read_youth_aviat_school'}).then((res) => {
                    if(res.errno === '200'){
                        let a = res.data;
                        let sb = [];
                        for(let i=0; i<a.length; i++){
                            let s = a[i];
                            if (s.code !== ''){
                                sb.push(s);
                            }
                        }
                        if (sb.length !== 0){
                            this.setState({
                                ApproveState:'Have',
                                ApproveModule:'List',
                            });
                        }else {
                            get({url:'agency/read_suborg_apply/3'}).then((res) => {
                                if(res.errno === '200'){
                                    let RecordArr = res.data;
                                    if (RecordArr.length !== 0){
                                        this.setState({
                                            ApproveModule:'Record',
                                            ApproveState:'Not',
                                        });
                                    }else {
                                        this.setState({
                                            ApproveState:'Not',
                                            ApproveModule:'List',
                                        });
                                    }
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            });
                        }
                    }else{
                        get({url:'agency/read_suborg_apply/3'}).then((res) => {
                            if(res.errno === '200'){
                                let RecordArr = res.data;
                                if (RecordArr.length !== 0){
                                    this.setState({
                                        ApproveModule:'Record',
                                        ApproveState:'Not',
                                    });
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        });
                    }
                });
            }else if(this.state.NowNavListZhName === '实训基地'){
                get({url:'agency/read_train_base'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            ApproveState:'Have',
                            ApproveModule:'List',
                        });
                    }else{
                        get({url:'agency/read_suborg_apply/4'}).then((res) => {
                            if(res.errno === '200'){
                                let RecordArr = res.data;
                                if (RecordArr.length !== 0){
                                    this.setState({
                                        ApproveModule:'Record',
                                        ApproveState:'Not',
                                    });
                                }else {
                                    this.setState({
                                        ApproveState:'Not',
                                        ApproveModule:'List',
                                    });
                                }
                            }else {
                                this.setState({
                                    ApproveState:'Not',
                                    ApproveModule:'List',
                                });
                            }
                        });
                    }
                });
            }
        }else {
            this.setState({
                ApproveModule:'List',
                RecordPage:'',
            });
        }
    };
    BackTableButton=()=>{
        this.setState({
            ApproveModule:'List',
            NewApproveStep:1,
            AllYearStep:1,
            RecordPage:'',
            DetailsPage:'not',
        });
    };

    //关于年检的下一步按钮子组件返回
    AllYearNextBtn=(event)=>{
        this.setState({
            AllYearStep:2,
        });
    };
    //关于认证的下一步按钮子组件返回
    NewApproveNextBtn=(event)=>{
        // console.log(event)
    };

    //关于信息列表的具体状态点击
    ListInfoClick=(event)=>{
        if(event.ClickInfoBtn === '去年检'){
            this.setState({
                ApproveModule:'AllYear',
                AllYearStep:1,
            });
        }else if(event.ClickInfoBtn === '重新认证'){
            this.setState({
                ApproveModule:'NewApprove',
                NewApproveStep:1,
            });
        }else if(event.ClickInfoBtn === '查看详情'){
            this.setState({
                DetailsPage:'have',
                ApproveId:event.Id,
            });
        }
    };

    //关于申请记录页面返回查看申请状态的返回
    AppStateClick=(event)=>{
        if(event.ClickInfo === '年检'){
            this.setState({
                RecordPage:'yes',
                ApproveModule:'AllYear',
                AllYearStep:event.Process,
                RecordPageId:event.Id,
            });
        }else if(event.ClickInfo === '认证'){
            get({url:'agency/read_suborg_apply/'+event.IdtTypeId}).then((res) => {
                if(res.errno === '200'){
                    function Info(data) {
                        for(let i=0;i<data.length;i++){
                            let q = data[i].id;
                            if (q === event.Id){
                                return data[i];
                            }
                        }
                    }
                    let RecordArrList = Info(res.data);
                    this.setState({
                        RecordPage:'yes',
                        ApproveModule:'NewApprove',
                        NewApproveStep:RecordArrList.process+1,
                        RecordPageId:RecordArrList.id,
                    });
                }
            });
        }
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };
    ChildBackTip2=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
            const {history} = this.props;
            history.push('/login');
        }
    };


    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'} style={{position:'relative',}}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>{this.state.NowNavListZhName}申请服务</div>
                                <div className={'MainConTitEN'}>AUTHENTICATION&nbsp;&nbsp;SERVICE</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}</div>
                        </div>
                        {
                            this.state.DetailsPage === 'not' &&
                            <div className={'ContentMainBox clearfix'}>
                                <div className={'MainLeftNavBox'}>
                                    <ul className={'MainLeftNavListBox'}>
                                        {
                                            this.state.ApproveNavArr.map((item,index) =>
                                                <li className={item.ZhName === this.state.NowNavListZhName ? 'MainLeftNavActiveList' : 'MainLeftNavList'}
                                                    key={index}
                                                    onClick={() => this.AccountListClick(item,index)}
                                                >
                                                    {item.ZhName}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className={'MainThisNavBox'} style={{height:window.innerHeight-191,}}>
                                    <div className={'ApproveGroupTitBox'}>
                                        <div className={'ApproveGroupName'}>
                                            {this.state.GroupOrPersonalName}
                                            {
                                                this.state.ApproveModule === 'List'&& this.state.GroupOrPersonalName !== ''&& this.state.ApproveState === 'Have'&&
                                                <span> >> 已认证信息</span>
                                            }
                                            {
                                                this.state.ApproveModule === 'List'&& this.state.GroupOrPersonalName !== ''&&this.state.ApproveState === 'Not'&&
                                                <span> </span>
                                            }
                                            {
                                                this.state.ApproveModule === 'NewApprove'&& this.state.GroupOrPersonalName !== ''&&
                                                <span> >> 申请认证</span>
                                            }
                                            {
                                                this.state.ApproveModule === 'AllYear'&& this.state.GroupOrPersonalName !== ''&&
                                                <span> >> 年检</span>
                                            }
                                            {
                                                this.state.ApproveModule === 'Record'&& this.state.GroupOrPersonalName !== ''&&
                                                <span> >> 申请记录</span>
                                            }
                                            {
                                                this.state.ApproveModule === ''&& this.state.GroupOrPersonalName !== ''&&
                                                <span> </span>
                                            }
                                        </div>
                                        {
                                            this.state.ApproveState === 'Have'&& this.state.ApproveModule === 'List'&&
                                            <div>
                                                <button className={'ApproveStateBtn'}
                                                        onClick={this.RecordStateBtn.bind(this)}
                                                        style={{marginRight:0,minWidth:100,}}
                                                >
                                                    申请记录
                                                </button>
                                                <button className={'ApproveStateBtn'}
                                                        onClick={this.ApproveStateBtn.bind(this)}
                                                        style={{marginRight:10,minWidth:100,backgroundColor:'#ff732e',}}
                                                >
                                                    再次申请
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.ApproveModule !== 'List'&&this.state.ApproveModule !== ''&&
                                            <div className={'DetailsBackBox clearfix'} style={{marginTop:10,}} onClick={this.BackButton.bind(this)}>
                                                <div className={'DetailsBackIco'}> </div>
                                                <div className={'DetailsBackWord'}>返回</div>
                                            </div>
                                        }
                                    </div>
                                    <div className={'ApproveGroupConBox'}>
                                        {
                                            this.state.ApproveState === 'Not'&& this.state.ApproveModule === 'List'&&
                                            <div className={'NotApproveConBox'}>
                                                <div className={'NotApproveConBoxTit'}>您还未申请通过任何{this.state.NowNavListZhName}</div>
                                                <button className={'ApproveStateBtn'}
                                                        onClick={this.ApproveStateBtn.bind(this)}
                                                        style={{float:'none',margin:'20px auto',backgroundColor:'#ff732e',}}
                                                >
                                                    申请{this.state.NowNavListZhName}
                                                </button>
                                                <button className={'ApproveStateBtn'}
                                                        onClick={this.RecordStateBtn.bind(this)}
                                                        style={{float:'none',margin:'20px auto',}}
                                                >
                                                    申请记录
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.ApproveState === 'Have'&& this.state.ApproveModule === 'List'&&
                                            <ApproveList
                                                ApproveListIdt={this.state.NowNavListZhName}
                                                ListInfoClick={this.ListInfoClick.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ApproveModule === 'Record'&&
                                            <AppRecord
                                                RecordIdt={this.state.NowNavListZhName}
                                                AppStateClick={this.AppStateClick.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ApproveModule === 'NewApprove'&&
                                            <NewApprove
                                                NewApproveStep={this.state.NewApproveStep}
                                                NewApproveIdt={this.state.NowNavListZhName}
                                                BackTableInfo={this.BackTableButton.bind(this)}
                                                NewApproveNextBtn={this.NewApproveNextBtn.bind(this)}
                                                RecordPageId={this.state.RecordPage === 'yes' ?  this.state.RecordPageId : null}
                                            />
                                        }
                                        {
                                            this.state.ApproveModule === 'AllYear'&&
                                            <AllYear
                                                AllYearStep={this.state.AllYearStep}
                                                AllYearIdt={this.state.NowNavListZhName}
                                                BackTableInfo={this.BackTableButton.bind(this)}
                                                AllYearNextBtn={this.AllYearNextBtn.bind(this)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.DetailsPage === 'have' && this.state.NowNavListZhName === '设备'&&
                            <EquipmentDetails
                                EquipmentId={this.state.ApproveId}
                                BackButton={this.BackTableButton.bind(this)}
                            />
                        }
                        {
                            this.state.DetailsPage === 'have' && this.state.NowNavListZhName === '训练中心'&&
                            <TrainDetails
                                TrainId={this.state.ApproveId}
                                BackButton={this.BackTableButton.bind(this)}
                            />
                        }
                        {
                            this.state.DetailsPage === 'have' && this.state.NowNavListZhName === '青少年航校'&&
                            <SchoolDetails
                                SchoolId={this.state.ApproveId}
                                BackButton={this.BackTableButton.bind(this)}
                            />
                        }
                        {
                            this.state.DetailsPage === 'have' && this.state.NowNavListZhName === '实训基地'&&
                            <DepotDetails
                                DepotId={this.state.ApproveId}
                                BackButton={this.BackTableButton.bind(this)}
                            />
                        }
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗2' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip2.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip2}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Approve;
