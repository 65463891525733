import React, { Component } from 'react';
import './member.css';
import Triangle from "../round/Triangle";
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class PeTeamNews extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            TeamNewsType:['全部类型','我被邀请','我的申请',],
            TeamNewsName:'消息类型',
            TeamNewsArr:[],
            TeamNewsToggleOn: true,
            TeamNewsDisplay: 'none',
        };
    }
    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    User:res.data[0],
                });
            }
        });
        let TeamNewsInfoArr = [];
        let TeamNewsInfoBrr = [];
        post({
            url:'flyteam/per_invite_info',
            data:{
                type: 0,
            },
        }).then((res) => {
            if(res.errno === '200'){
                for(let i=0;i<res.data.length;i++){
                    let TeamNewsInfoArrList = res.data[i];
                    TeamNewsInfoArr.push(TeamNewsInfoArrList);
                }
            }
            post({
                url:'flyteam/per_invite_info',
                data:{
                    type: 1,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    for(let i=0;i<res.data.length;i++){
                        let TeamNewsInfoBrrList = res.data[i];
                        TeamNewsInfoBrr.push(TeamNewsInfoBrrList);
                    }
                }
                let TeamNewsArr = TeamNewsInfoArr.concat(TeamNewsInfoBrr);
                TeamNewsArr.sort(function(x,y){
                    return Date.parse(y.ctime) - Date.parse(x.ctime) || y.id - x.id;
                });
                this.setState({
                    TeamNewsArr: TeamNewsArr,
                });
            });
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
            TeamNewsToggleOn: true,
            TeamNewsDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //点击显示下拉菜单
    TeamNewsDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            TeamNewsToggleOn: !prevState.TeamNewsToggleOn,
            TeamNewsDisplay:prevState.TeamNewsToggleOn ? 'block' : 'none',
        }));
    };
    //点击所选择的类型
    TeamNewsDownNav=(item,index)=>{
        if(item === '全部类型'){
            let TeamNewsInfoArr = [];
            let TeamNewsInfoBrr = [];
            post({
                url:'flyteam/per_invite_info',
                data:{
                    type: 0,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    for(let i=0;i<res.data.length;i++){
                        let TeamNewsInfoArrList = res.data[i];
                        TeamNewsInfoArr.push(TeamNewsInfoArrList);
                    }
                }
                post({
                    url:'flyteam/per_invite_info',
                    data:{
                        type: 1,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<res.data.length;i++){
                            let TeamNewsInfoBrrList = res.data[i];
                            TeamNewsInfoBrr.push(TeamNewsInfoBrrList);
                        }
                    }
                    let TeamNewsArr = TeamNewsInfoArr.concat(TeamNewsInfoBrr);
                    TeamNewsArr.sort(function(x,y){
                        return Date.parse(y.ctime) - Date.parse(x.ctime) || y.id - x.id;
                    });
                    this.setState({
                        TeamNewsArr: TeamNewsArr,
                    });
                });
            });
        }else if(item === '我被邀请'){
            let TeamNewsInfoArr = [];
            let TeamNewsInfoBrr = [];
            post({
                url:'flyteam/per_invite_info',
                data:{
                    type: 0,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    for(let i=0;i<res.data.length;i++){
                        let TeamNewsInfoArrList = res.data[i];
                        if(TeamNewsInfoArrList.type === 0){
                            TeamNewsInfoArr.push(TeamNewsInfoArrList);
                        }
                    }
                }
                post({
                    url:'flyteam/per_invite_info',
                    data:{
                        type: 1,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<res.data.length;i++){
                            let TeamNewsInfoBrrList = res.data[i];
                            if(TeamNewsInfoBrrList.type === 0){
                                TeamNewsInfoBrr.push(TeamNewsInfoBrrList);
                            }
                        }
                    }
                    let TeamNewsArr = TeamNewsInfoArr.concat(TeamNewsInfoBrr);
                    TeamNewsArr.sort(function(x,y){
                        return Date.parse(y.ctime) - Date.parse(x.ctime) || y.id - x.id;
                    });
                    this.setState({
                        TeamNewsArr: TeamNewsArr,
                    });
                });
            });
        }else if(item === '我的申请'){
            let TeamNewsInfoArr = [];
            let TeamNewsInfoBrr = [];
            post({
                url:'flyteam/per_invite_info',
                data:{
                    type: 0,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    for(let i=0;i<res.data.length;i++){
                        let TeamNewsInfoArrList = res.data[i];
                        if(TeamNewsInfoArrList.type === 1){
                            TeamNewsInfoArr.push(TeamNewsInfoArrList);
                        }
                    }
                }
                post({
                    url:'flyteam/per_invite_info',
                    data:{
                        type: 1,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<res.data.length;i++){
                            let TeamNewsInfoBrrList = res.data[i];
                            if(TeamNewsInfoBrrList.type === 1){
                                TeamNewsInfoBrr.push(TeamNewsInfoBrrList);
                            }
                        }
                    }
                    let TeamNewsArr = TeamNewsInfoArr.concat(TeamNewsInfoBrr);
                    TeamNewsArr.sort(function(x,y){
                        return Date.parse(y.ctime) - Date.parse(x.ctime) || y.id - x.id;
                    });
                    this.setState({
                        TeamNewsArr: TeamNewsArr,
                    });
                });
            });
        }
        this.setState({
            TeamNewsName:item,
            TeamNewsToggleOn: true,
            TeamNewsDisplay: 'none',
        });
    };

    TeamNewsBtnOk=(item,index)=>{
        post({
            url:'flyteam/check_is_flyteam_member',
            data:{
                sub_id:this.state.User.id,
                type:item.fm_type,
            },
        }).then((res) => {
            if (res.errno === '200') {
                post({
                    url:'flyteam/accept_reject',
                    data:{
                        fm_id:item.fm_id,
                        status:1,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        let TeamNewsInfoArr = [];
                        let TeamNewsInfoBrr = [];
                        post({
                            url:'flyteam/per_invite_info',
                            data:{
                                type: 0,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                for(let i=0;i<res.data.length;i++){
                                    let TeamNewsInfoArrList = res.data[i];
                                    TeamNewsInfoArr.push(TeamNewsInfoArrList);
                                }
                            }
                            post({
                                url:'flyteam/per_invite_info',
                                data:{
                                    type: 1,
                                },
                            }).then((res) => {
                                if(res.errno === '200'){
                                    for(let i=0;i<res.data.length;i++){
                                        let TeamNewsInfoBrrList = res.data[i];
                                        TeamNewsInfoBrr.push(TeamNewsInfoBrrList);
                                    }
                                }
                                let TeamNewsArr = TeamNewsInfoArr.concat(TeamNewsInfoBrr);
                                TeamNewsArr.sort(function(x,y){
                                    return Date.parse(y.ctime) - Date.parse(x.ctime) || y.id - x.id;
                                });
                                this.setState({
                                    TeamNewsArr: TeamNewsArr,
                                    PopUp:'提示弹窗',
                                    MessageTip:'操作成功',
                                });
                            });
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'操作失败，请稍后重试！',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'您已加入其他团队，请先退出再接受！',
                });
            }
        });
    };


    TeamNewsBtnNo=(item,index)=>{
        post({
            url:'flyteam/accept_reject',
            data:{
                fm_id:item.fm_id,
                status:2,
            },
        }).then((res) => {
            if(res.errno === '200'){
                let TeamNewsInfoArr = [];
                let TeamNewsInfoBrr = [];
                post({
                    url:'flyteam/per_invite_info',
                    data:{
                        type: 0,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<res.data.length;i++){
                            let TeamNewsInfoArrList = res.data[i];
                            TeamNewsInfoArr.push(TeamNewsInfoArrList);
                        }
                    }
                    post({
                        url:'flyteam/per_invite_info',
                        data:{
                            type: 1,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            for(let i=0;i<res.data.length;i++){
                                let TeamNewsInfoBrrList = res.data[i];
                                TeamNewsInfoBrr.push(TeamNewsInfoBrrList);
                            }
                        }
                        let TeamNewsArr = TeamNewsInfoArr.concat(TeamNewsInfoBrr);
                        TeamNewsArr.sort(function(x,y){
                            return Date.parse(y.ctime) - Date.parse(x.ctime) || y.id - x.id;
                        });
                        this.setState({
                            TeamNewsArr: TeamNewsArr,
                            PopUp:'提示弹窗',
                            MessageTip:'操作成功',
                        });
                    });
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'操作失败，请稍后重试！',
                });
            }
        });
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
            <div className={'PeStudentInfoBox'} style={{position:'relative',minHeight:window.innerHeight-270,height:'auto',}}>
                <div className={'PeDataTopBox'} style={{height:38,}}>
                    <div className={'TableHeader'} style={{width:978,}}>
                        <div className={'TableHeaderInfo'} style={{width:200,margin:0,}}>时间</div>
                        <div className={'TableHeaderInfo'} style={{width:310,margin:0,}}>团队名称（ID）</div>
                        <div className={'TableHeaderInfo'} style={{width:200,margin:0,}}>
                            <div className={'TableHeaderInfoWord'} onClick={this.TeamNewsDown.bind(this)}>
                                <div className={'SubjectDownIco'}>
                                    <Triangle Direction={'down'} Color={'#0488CA'} Width={'7px'} Height={'10px'} Top={'0'} Right={'0'}/>
                                </div>
                                <div className={'SubjectDownTitle'}>{this.state.TeamNewsName}</div>
                            </div>
                            <div className={'TeamNewsDownNavBox'} style={{display:this.state.TeamNewsDisplay}}>
                                {
                                    this.state.TeamNewsType.map((item,index) =>
                                        <div className={'TeamNewsDownNav'}
                                             key={index}
                                             onClick={() => this.TeamNewsDownNav(item,index)}
                                             style={this.state.TeamNewsName === item ? {backgroundColor:'#8DCEFD'} : {}}>
                                            {item}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'TableHeaderInfo'} style={{width:260,margin:0,}}>状态（操作）</div>
                    </div>
                </div>

                <div className={'TableContentBox'} style={{width:978,margin:0,}}>
                    {
                        this.state.TeamNewsArr.map((item,index) =>
                            <div className={'TableContent'} key={index} style={{width:978,margin:'10px auto',maxHeight:'630px',}}>
                                <div className={'TableContentInfo'} style={{width:200,}}>{item.ctime}</div>
                                <div className={'TableContentInfo'} style={{width:310,}}>{item.name}（{item.team_num}）</div>
                                <div className={'TableContentInfo'} style={{width:200,}}>
                                    {item.type === 0 ? '我被邀请' : (item.type === 1 ? '我的申请' : '')}
                                </div>
                                <div className={'TableContentInfo'} style={{width:260,}}>
                                    {
                                        item.status === 1 &&
                                        <span style={{color:'#1ABB00',}}>
                                            {item.type === 0 ? '我已同意' : (item.type === 1 ? '已被接受' : '')}
                                        </span>
                                    }
                                    {
                                        item.status === 2 &&
                                        <span style={{color:'#BB0000',}}>
                                            {item.type === 0 ? '我已拒绝' : (item.type === 1 ? '已被拒绝' : '')}
                                        </span>
                                    }
                                    {
                                        item.status === 0 && item.type === 1 &&
                                        <span style={{color:'#6EC2FF',}}>等待对方处理</span>
                                    }
                                    {
                                        item.status === 0 && item.type === 0 &&
                                        <div className={'DisplayFlex'} style={{height:20,}}>
                                            <button className={'TeamNewsBtn'}
                                                    style={{height:20,backgroundColor:'#1ABB00',}}
                                                    onClick={() => this.TeamNewsBtnOk(item,index)}
                                            >接受</button>
                                            <button className={'TeamNewsBtn'}
                                                    style={{height:20,backgroundColor:'#BB0000',}}
                                                    onClick={() => this.TeamNewsBtnNo(item,index)}
                                            >拒绝</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default PeTeamNews;
