import React, { Component } from 'react';
import './round.css';
import PopUp from "./PopUp";
//import {post} from "../../ajax/tools";


class Upload extends Component {
    constructor(props){
        super(props);
        this.state = {
            FileName:'',
            File:{},
            FileLength: 0,
            WarnMark:'Not',
            WarnMessage:'',
            UploadState:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let history = nextProps;
        this.setState({
            UploadState:history.UploadState,
        });
    };
    componentDidMount=function() {
        let history = this.props;
        this.setState({
            UploadState:history.UploadState,
        });
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };

    UploadClick(){
        if(this.FileInputClick){
            this.FileInputClick.click();
        }
    };
    FileInputChange=(event) =>{
        let files =  [...event.target.files];
        let file =  files[0];
        if (file){
            this.props.BeforeUpload(file);
        }
        let history = this.props;
        if(history.BeforeUpload.WarnMessage !== ''){
            // console.log(history.BeforeUpload.WarnMessage)
            if(file){
                this.setState({
                    PopUp:'叹号错误提示框',
                    WarnMessage:history.BeforeUpload.WarnMessage,
                });
            }
        }else if(file){
            this.setState({
                WarnMessage:'',
                FileName:file.name,
                File:file,
                FileLength:files.length,
                WarnMark:'Have',
            });
        }
        event.target.value = '';
    };
    FileRemove(){
        this.setState({
            WarnMark:'Not',
        });
        this.props.RemoveUpload(this.state.File);
    };



    render() {
        return (
                <div className={'UploadBox'}>
                    <div className={'Upload'} onClick={this.UploadClick.bind(this)}>
                        <input type={'file'}
                               name={'Upload'}
                               accept={''}
                               ref={(input)=>{this.FileInputClick = input}}
                               style={{display:'none',}}
                               onChange={this.FileInputChange}
                        />
                    </div>
                    {
                        this.state.WarnMark === 'Have' && this.state.UploadState === 'Show'&&
                        <div className={'FileNameBox'}>
                            <div className={'FileName'}>{this.state.FileName}</div>
                            <div className={'FileRemove'} onClick={this.FileRemove.bind(this)}> </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '叹号错误提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'错误'}
                            message={this.state.WarnMessage}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default Upload;
