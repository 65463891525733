import React, { Component } from 'react';
import './member.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import CheckboxIcoNot from "./memberimage/CheckboxIcoNot.png";
import TeamSendIcoHave from "./memberimage/TeamSendIcoHave.png";
import TeamSendIcoNot from "./memberimage/TeamSendIcoNot.png";
import CheckboxIcoHave from "./memberimage/CheckboxIcoHave.png";


class PeTeacher extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            MemberTopNav:'考核信息',
            TopNavArr:['考核信息','赛事活动','任职信息','年审状态',],
            NavSelected:{
                backgroundColor:'#0488CA',
                color:'#FFFFFF',
                borderLeftColor:'#000000',
                borderTopColor:'#000000',
                borderRightColor:'#000000',
            },
            NoSelected:{
                borderBottomColor:'#000000',
            },
            ExamInfoArr:[],
            WorkInfoArr:[],
            GameInfoArr:[],
            YearInfoArr:[],



            TrainId:'',
            TrainIdNote:'No',
            TrainAddressNote:'No',
            TrainAddressArr:[],
            TrainInfoArr:[],//添加的机构组

            TrainSendNum:0,
            TrainSendNumArr: [],
            TrainSendIcoHaveStyle:{backgroundImage:'url('+TeamSendIcoHave+')'},
            TrainSendIcoNotStyle:{backgroundImage:'url('+TeamSendIcoNot+')'},
            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
            CheckboxIcoToggleOn: true,
            TrainSendIcoIndex:'',
            TrainSendInfo:{},
            TrainPageShow:'',
        };
    }

    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 0){
                    let TopNavArr = this.state.TopNavArr;
                    if(User.iscoach === 1){
                        TopNavArr = TopNavArr.concat(['申请挂靠',]);
                    }
                    this.setState({
                        TopNavArr:TopNavArr,
                        User:User,
                    });
                }
            }
        });
        get({url:'user/coach_exam_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    ExamInfoArr:res.data,
                });
            }else {
                this.setState({
                    ExamInfoArr:[],
                });
            }
        });
        // get({url:'user/coach_match_info'}).then((res) => {
        //     if(res.errno === '200'){
        //         this.setState({
        //             GameInfoArr:res.data,
        //         });
        //     }else {
        //         this.setState({
        //             GameInfoArr:[],
        //         });
        //     }
        // });
        // get({url:'agency/show_train_ins'}).then((res) => {
        //     if(res.errno === '200'){
        //         let WorkInfoListBrr = [];
        //         for(let i=0;i<res.data.length;i++){
        //             let WorkInfoList = res.data[i];
        //             WorkInfoList.BigName= WorkInfoList.city+WorkInfoList.address+WorkInfoList.name;
        //             WorkInfoListBrr.push(WorkInfoList);
        //         }
        //         this.setState({
        //             WorkInfoArr:WorkInfoListBrr,
        //         });
        //     }else {
        //         this.setState({
        //             WorkInfoArr:[],
        //         });
        //     }
        // });
        // get({url:'user/coach_year_check'}).then((res) => {
        //     if(res.errno === '200'){
        //         this.setState({
        //             YearInfoArr:res.data,
        //         });
        //     }else {
        //         this.setState({
        //             YearInfoArr:[],
        //         });
        //     }
        // });
        const TrainInfoArr = JSON.parse(sessionStorage.getItem('TrainInfoArr'));
        if (TrainInfoArr) {
            sessionStorage.setItem('TrainInfoArr',null);
            this.setState({
                TrainInfoArr:TrainInfoArr,
            });
        }
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if(item === '考核信息'){
            get({url:'user/coach_exam_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamInfoArr:res.data,
                    });
                }else {
                    this.setState({
                        ExamInfoArr:[],
                    });
                }
            });
            this.props.ChildBackNavClick('考核信息');
        }else if(item === '赛事活动'){
            get({url:'user/coach_match_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        GameInfoArr:res.data,
                    });
                }else {
                    this.setState({
                        GameInfoArr:[],
                    });
                }
            });
            this.props.ChildBackNavClick('赛事活动');
        }else if(item === '任职信息'){
            get({url:'agency/show_train_ins'}).then((res) => {
                if(res.errno === '200'){
                    let WorkInfoListBrr = [];
                    for(let i=0;i<res.data.length;i++){
                        let WorkInfoList = res.data[i];
                        WorkInfoList.BigName= WorkInfoList.city+WorkInfoList.address+WorkInfoList.name;
                        WorkInfoListBrr.push(WorkInfoList);
                    }
                    this.setState({
                        WorkInfoArr:WorkInfoListBrr,
                    });
                }else {
                    this.setState({
                        WorkInfoArr:[],
                    });
                }
            });
            this.props.ChildBackNavClick('任职信息');
        }else if(item === '年审状态'){
            get({url:'user/coach_year_check'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        YearInfoArr:res.data,
                    });
                }else {
                    this.setState({
                        YearInfoArr:[],
                    });
                }
            });
            this.props.ChildBackNavClick('年审状态');
        }else if(item === '申请挂靠'){
            post({
                url:'agency/check_is_train_coach',
                data:{
                    sub_id: this.state.User.id,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TrainPageShow:'无挂靠',
                    });
                }else {
                    this.setState({
                        TrainPageShow:'已挂靠',
                    });
                }
            });
            this.props.ChildBackNavClick('申请挂靠');
        }
        this.setState({
            MemberTopNav:item,
        });
    };



    //点击信息中的证书选项
    PeTeacherView=(item,index) =>{
        post({
            url:'user/show_diploma_ord',
            data:{
                type: 1,
                level:item.level,
            },
        }).then((res) => {
            if(res.errno === '200'){
                let diplomaCode = res.data[0].diploma_code;
                window.open('/certificate/'+diplomaCode);
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'解析证书失败，请稍后重试！',
                });
            }
        });
    };
    //点击信息中的查看详情
    PeTeacherDetails=(item,index) =>{
        //console.log(item)
    };
    //点击信息中的年审状态详情
    PeTeacherReview=(item,index) =>{
        //console.log(item)
    };






    TeamNewsBtnOk=(item,index)=>{
        post({
            url:'agency/check_is_train_coach',
            data:{
                sub_id: item.coach_id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                post({
                    url:'agency/accept_coach',
                    data:{
                        tic_id: item.id,
                        status: 1,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        get({url:'agency/show_train_ins'}).then((res) => {
                            if(res.errno === '200'){
                                let WorkInfoListBrr = [];
                                for(let i=0;i<res.data.length;i++){
                                    let WorkInfoList = res.data[i];
                                    WorkInfoList.BigName= WorkInfoList.city+WorkInfoList.address+WorkInfoList.name;
                                    WorkInfoListBrr.push(WorkInfoList);
                                }
                                this.setState({
                                    WorkInfoArr:WorkInfoListBrr,
                                    PopUp:'提示弹窗',
                                    MessageTip:'操作成功',
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'操作失败',
                                });
                            }
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'您已在训练机构任职,此操作无效！',
                });
            }
        });
    };

    TeamNewsBtnNo=(item,index)=>{
        post({
            url:'agency/accept_coach',
            data:{
                tic_id: item.id,
                status: 2,
            },
        }).then((res) => {
            if(res.errno === '200'){
                get({url:'agency/show_train_ins'}).then((res) => {
                    if(res.errno === '200'){
                        let WorkInfoListBrr = [];
                        for(let i=0;i<res.data.length;i++){
                            let WorkInfoList = res.data[i];
                            WorkInfoList.BigName= WorkInfoList.city+WorkInfoList.address+WorkInfoList.name;
                            WorkInfoListBrr.push(WorkInfoList);
                        }
                        this.setState({
                            WorkInfoArr:WorkInfoListBrr,
                            PopUp:'提示弹窗',
                            MessageTip:'操作成功',
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'操作失败',
                        });
                    }
                });
            }
        });
    };







    //输入教机构用户Id
    TrainIdChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            TrainId: event.target.value,
        });
    };
    //点击搜索机构用户
    TrainIdInput=()=>{
        let TrainId = this.state.TrainId;
        if (TrainId !== ''){
            get({url:'reuse/get_train_info/'+TrainId}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TrainAddressArr:res.data,
                        TrainAddressNote:'Yes',
                    });
                }else {
                    this.setState({
                        TrainIdNote:'Yes',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'输入ID不能为空！',
            });
        }
    };
    //点击重置找不到机构用户的备注
    TrainIdClick(){
        this.setState({
            TrainIdNote:'No',
            TrainAddressNote:'No',
        });
    };

    //添加训练机构信息
    TrainActiveAd=(item,index)=>{
        let TrainId = this.state.TrainId;
        let TrainInfoArr = this.state.TrainInfoArr;
        let TrainListIdNumArr = [];
        get({url:'reuse/get_org_info/'+TrainId}).then((res) => {
            if(res.errno === '200'){
                sessionStorage.setItem('TrainInfoArr',null);
                let TheTrainUsername = res.data[0].username;
                let TrainInfoCbArr=[];
                if(TrainInfoArr.length !==0){
                    for(let i=0;i<TrainInfoArr.length;i++){
                        let TrainListId = TrainInfoArr[i].id;
                        TrainListIdNumArr.push(TrainListId);
                    }
                    if(TrainListIdNumArr.includes(item.id)){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'您已经搜索添加过该训练机构！',
                        });
                    }else {
                        let TrainTheList = item;
                        TrainTheList.Checkbox= false;
                        TrainTheList.send= false;
                        TrainTheList.BigName= item.city+item.address+TheTrainUsername;
                        TrainInfoArr.push(TrainTheList);
                    }
                    this.setState({
                        TrainInfoArr:TrainInfoArr,
                        TrainIdNote:'No',
                        TrainAddressNote:'No',
                    });
                }else {
                    let TrainTheList = item;
                    TrainTheList.Checkbox= false;
                    TrainTheList.send= false;
                    TrainTheList.BigName= TheTrainUsername+item.address;
                    TrainInfoCbArr.push(TrainTheList);
                    this.setState({
                        TrainInfoArr:TrainInfoCbArr,
                        TrainIdNote:'No',
                        TrainAddressNote:'No',
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'未知错误，请联系我们！',
                });
            }
        });
    };



    //点击多选框全选
    CheckboxIcoAll=(event)=>{
        const TrainInfoCbArr=[];
        const TrainSendNumArr=[];
        for(let i=0;i<event.length;i++){
            const TrainList= event[i];
            if(this.state.CheckboxIcoToggleOn === false){
                if(TrainList.send === false){
                    TrainList.Checkbox = false;
                    TrainList.index = i;
                    TrainSendNumArr.push(TrainList);
                }
                TrainInfoCbArr.push(TrainList);
            }else if(this.state.CheckboxIcoToggleOn === true){
                if(TrainList.send === false){
                    TrainList.Checkbox = true;
                    TrainList.index = i;
                    TrainSendNumArr.push(TrainList);
                }
                TrainInfoCbArr.push(TrainList);
            }
        }
        this.setState(prevState => ({
            CheckboxIcoToggleOn: !prevState.CheckboxIcoToggleOn,
            CheckboxIcoBgImg:TrainSendNumArr.length === 0 ? 'url('+CheckboxIcoNot+')' : (prevState.CheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
            TrainSendNum:prevState.CheckboxIcoToggleOn ? TrainSendNumArr.length : 0,
            TrainInfoArr:TrainInfoCbArr,
            TrainSendNumArr:prevState.CheckboxIcoToggleOn ? TrainSendNumArr : [],
        }));
    };
    //点击单个多选框
    CheckboxIco=(item,index)=>{
        const TrainInfoList = this.state.TrainInfoArr;
        TrainInfoList[index]['Checkbox'] = item.Checkbox !== true;
        const TrainSendNumArr = [...this.state.TrainSendNumArr];
        if(item.Checkbox === true){
            item.index = index;
            TrainSendNumArr.push(item);
            this.setState({
                TrainSendNumArr:TrainSendNumArr,
                TrainSendNum:TrainSendNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<TrainSendNumArr.length;i++){
                if(TrainSendNumArr[i].id === item.id){
                    TrainSendNumArr.splice(i,1);
                }
            }
            this.setState({
                TrainSendNumArr:TrainSendNumArr,
                TrainSendNum:TrainSendNumArr.length,
            });
        }
        const TrainSendNoArr=[];
        for(let i=0;i<this.state.TrainInfoArr.length;i++){
            const TrainList= this.state.TrainInfoArr[i];
            if(TrainList.send === false){
                TrainSendNoArr.push(TrainList)
            }
        }
        this.setState({
            TrainInfoArr:TrainInfoList,
            CheckboxIcoToggleOn:TrainSendNoArr.length !== TrainSendNumArr.length,
        });
    };








    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.TeamSend === 'One'){
                let TrainInfoCbArr = this.state.TrainInfoArr;
                let TrainIndex = this.state.TrainSendIcoIndex;
                let TrainSendInfo = this.state.TrainSendInfo;
                post({
                    url:'agency/join_train_ins',
                    data:{
                        ti_id_list:[{ti_id:TrainSendInfo.id,},],
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        TrainInfoCbArr[TrainIndex]['send'] = true;
                        const TrainSendNumArr = [...this.state.TrainSendNumArr];
                        if(TrainSendNumArr.length !==0){
                            if(TrainSendInfo.Checkbox === true){
                                for(let i=0;i<TrainSendNumArr.length;i++){
                                    if(TrainSendNumArr[i].id === TrainSendInfo.id){
                                        TrainSendNumArr.splice(i,1);
                                    }
                                    this.setState({
                                        TrainSendNumArr:TrainSendNumArr,
                                        TrainSendNum:TrainSendNumArr.length,
                                    });
                                }
                            }
                        }
                        this.setState({
                            TrainId:'',
                            TrainInfoArr:TrainInfoCbArr,
                            PopUp:'提示弹窗',
                            MessageTip:'成功发送邀请',
                        });
                    }else {
                        this.setState({
                            TrainId:'',
                            PopUp:'提示弹窗',
                            MessageTip:'发送申请失败，请稍后重试！',
                        });
                    }
                });
            }else if(this.state.TeamSend === 'More'){
                let TrainInfoCbArr = this.state.TrainInfoArr;
                let TrainSendNumArr = this.state.TrainSendNumArr;
                let FiIdListArr = [];
                for(let i=0;i<TrainSendNumArr.length;i++){
                    let FiIdList = {ti_id:TrainSendNumArr[i].id};
                    FiIdListArr.push(FiIdList);
                }
                post({
                    url:'agency/join_train_ins',
                    data:{
                        ti_id_list:FiIdListArr,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<TrainSendNumArr.length;i++){
                            TrainInfoCbArr[TrainSendNumArr[i].index]['send'] = true;
                        }
                        this.setState({
                            TrainId:'',
                            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
                            CheckboxIcoToggleOn: true,
                            TrainSendNumArr:[],
                            TrainSendNum:0,
                            TrainInfoArr:TrainInfoCbArr,
                            PopUp:'提示弹窗',
                            MessageTip:'成功发送邀请',
                        });
                    }else {
                        this.setState({
                            TeamId:'',
                            PopUp:'提示弹窗',
                            MessageTip:'发送申请失败，请稍后重试！',
                        });
                    }
                });
            }
        }
    };
    //点击发送单个申请
    TrainSendIco=(item,index)=>{
        this.setState({
            TrainSendIcoIndex:index,
            TrainSendInfo:item,
            TeamSend:'One',
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否发送该申请到'+item.name+'！',
        });
    };
    //点击发送多个申请
    TrainPleaseSend=()=>{
        this.setState({
            TeamSend:'More',
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否发送该申请到这些训练机构！',
        });
    };







    //点击教练员查看机构详细信息
    TrainDetails=(item,index) =>{
        if(item.id){
            let TrainInfoArr = this.state.TrainInfoArr;
            sessionStorage.setItem('TrainInfoArr',JSON.stringify(TrainInfoArr));
            this.props.BackTrainDetails(item);
        }
    };






    //点击学员的教练员查看详细信息
    CoachDetails=(item,index) =>{
        if(item.coach_id !== ''){
            this.props.BackCoachDetails(item);
        }
    };






    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    <div className={'MemberTopNavBox'}>
                        {
                            this.state.TopNavArr.map((item,index) =>
                                <div className={'MemberTopNav'} 
                                    key={index}
                                    onClick={() => this.MemberTopNav(item,index)}
                                    style={item === this.state.MemberTopNav ? this.state.NavSelected : this.state.NoSelected}
                                >
                                    {item}
                                </div>
                            )
                        }
                        <div className={'MemberPosition'} style={{width:this.state.User.iscoach === 1 ? 120 : 292 ,height:61,borderBottom:'1px solid #000000',}}> </div>
                    </div>
                    {
                       this.state.MemberTopNav === '考核信息' &&
                       <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                           <div className={'TableHeader'}>
                               <div className={'TableHeaderInfo'} style={{width:120,}}>时间</div>
                               <div className={'TableHeaderInfo'} style={{width:380,}}>地点</div>
                               <div className={'TableHeaderInfo'} style={{width:180,}}>等级</div>
                               <div className={'TableHeaderInfo'} style={{width:230,}}>状态</div>
                               {/*<div className={'TableHeaderInfo'} style={{width:150,}}>详情</div>*/}
                           </div>
                           <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                               {
                                   this.state.ExamInfoArr.map((item,index) =>
                                       <div className={'TableContent'} key={index}>
                                           <div className={'TableContentInfo'} style={{width:120,}}>{item.ctime.substring(0,10)}</div>
                                           <div className={'TableContentInfo'} style={{width:380,}}>{item.address}</div>
                                           <div className={'TableContentInfo'} style={{width:180,}}>{item.name}</div>
                                           {
                                               item.isvalid === 0 &&
                                               <div className={'TableContentInfo'} style={{width:230,color:'#BB0000',}}>未通过</div>
                                           }
                                           {
                                               item.isvalid === 1 &&
                                               <div className={'TableContentInfo'} style={{width:230,color:'#787878',}}>未测试</div>
                                           }
                                           {
                                               item.isvalid === 2 &&
                                               <div className={'TableContentInfo'} style={{width:230,}}>
                                                   <div className={'TableContentInfoWord'} title={'查看证书'} onClick={() => this.PeTeacherView(item,index)}>
                                                       <div style={{float:'left',}} className={'ClickDetails'}>通过(查看证书)</div>
                                                       <div className={'ViewIco'}> </div>
                                                   </div>
                                               </div>
                                           }
                                           {/*
                                            <div className={'TableContentInfo'} style={{width:150,}} onClick={() => this.PeTeacherDetails(item,index)}>
                                               <span className={'DetailsViews'}>查看</span>
                                            </div>
                                           */}
                                       </div>
                                   )
                               }
                           </div>
                       </div>
                    }
                    {
                        this.state.MemberTopNav === '赛事活动' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TableHeader'}>
                                <div className={'TableHeaderInfo'} style={{width:270,}}>名称</div>
                                <div className={'TableHeaderInfo'} style={{width:153,}}>时间</div>
                                <div className={'TableHeaderInfo'} style={{width:153,}}>项目</div>
                                <div className={'TableHeaderInfo'} style={{width:113,}}>名次</div>
                                <div className={'TableHeaderInfo'} style={{width:113,}}>成绩</div>
                                <div className={'TableHeaderInfo'} style={{width:116,}}>学生姓名</div>
                            </div>
                            <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                                {
                                    this.state.GameInfoArr.map((item,index) =>
                                        <div className={'TableContent'} key={index}>
                                            <div className={'TableContentInfo'} style={{width:270,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:153,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:153,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:113,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:113,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:116,}}>
                                                <span className={'ClickDetails'} onClick={() => this.CoachDetails(item,index)}>{item.real_name}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '任职信息' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TableHeader'}>
                                <div className={'TableHeaderInfo'} style={{width:514,}}>机构名称（地址）</div>
                                <div className={'TableHeaderInfo'} style={{width:200,}}>时间</div>
                                <div className={'TableHeaderInfo'} style={{width:200,}}>状态</div>
                            </div>
                            <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                                {
                                    this.state.WorkInfoArr.map((item,index) =>
                                        <div className={'TableContent'} key={index}>
                                            <div className={'TableContentInfo'} style={{width:514,}}>{item.BigName}</div>
                                            <div className={'TableContentInfo'} style={{width:200,}}>{item.ctime.substring(0,10)}</div>
                                            <div className={'TableContentInfo'} style={{width:200,}}>
                                                {
                                                    item.status === 1 &&
                                                    <span>任职中···</span>
                                                }
                                                {
                                                    item.status === 2 &&
                                                    <span style={{color:'#BB0000',}}>
                                                        {item.type === 0 ? '我已拒绝' : (item.type === 1 ? '已被拒绝' : '')}
                                                    </span>
                                                }
                                                {
                                                    item.status === 0 && item.type === 1 &&
                                                    <span style={{color:'#6EC2FF',}}>等待对方处理</span>
                                                }
                                                {
                                                    item.status === 0 && item.type === 0 &&
                                                    <div className={'DisplayFlex'} style={{height:20,}}>
                                                        <button className={'TeamNewsBtn'}
                                                                style={{height:20,backgroundColor:'#1ABB00',}}
                                                                onClick={() => this.TeamNewsBtnOk(item,index)}
                                                        >接受</button>
                                                        <button className={'TeamNewsBtn'}
                                                                style={{height:20,backgroundColor:'#BB0000',}}
                                                                onClick={() => this.TeamNewsBtnNo(item,index)}
                                                        >拒绝</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '年审状态' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TableHeader'}>
                                <div className={'TableHeaderInfo'} style={{width:234,}}>年度</div>
                                <div className={'TableHeaderInfo'} style={{width:228,}}>考核人次</div>
                                <div className={'TableHeaderInfo'} style={{width:228,}}>通过人次</div>
                                <div className={'TableHeaderInfo'} style={{width:228,}}>状态</div>
                            </div>
                            <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                                {
                                    this.state.YearInfoArr.map((item,index) =>
                                        <div className={'TableContent'} key={index}>
                                            <div className={'TableContentInfo'} style={{width:234,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:228,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:228,}}> </div>
                                            <div className={'TableContentInfo'} style={{width:228,}}>
                                               <span className={'DetailsViews'} onClick={() => this.PeTeacherReview(item,index)}>正常</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '申请挂靠' && this.state.TrainPageShow === '无挂靠' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'PeTeamCreateInfoConBox'} style={{margin:'0 auto',width:916,padding:'28px 0',position:'relative',}}>
                                <div className={'PeTeamManageInfoConTit'}>挂靠机构（单位）ID号</div>
                                <div className={'TeamIdInputBox clearfix'}>
                                    <input className={'TeamIdInput'}
                                           type={'text'}
                                           name={'PeId'}
                                           value={this.state.TrainId}
                                           onChange={this.TrainIdChange.bind(this)}
                                           onClick={this.TrainIdClick.bind(this)}
                                           placeholder={'请输入正确的机构（单位）ID号'}
                                           autoComplete={'off'}
                                    />
                                    <button className={'TeamIdInputBtn'} onClick={this.TrainIdInput.bind(this)}>搜索</button>
                                </div>
                                {
                                    this.state.TrainIdNote === 'Yes' &&
                                    <div className={'TeamIdInputNote'} style={{marginLeft:10,}}>未搜索到训练机构，请您仔细检查</div>
                                }
                                {
                                    this.state.TrainAddressNote === 'Yes' &&
                                    <div  className={'TrainActiveBox'}>
                                        {
                                            this.state.TrainAddressArr.map((item,index) =>
                                                <div className={'TableHeaderDownNav Ellipsis'}
                                                     key={index}
                                                     onClick={() => this.TrainActiveAd(item,index)}
                                                     style={{width:296,}}
                                                >
                                                    {item.city+item.address}
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <button className={'TeamPleaseSendBtn'}
                                    onClick={this.state.TrainInfoArr.length === 0 ? null : this.TrainPleaseSend.bind(this)}
                                    style={this.state.TrainInfoArr.length === 0 ? {backgroundColor:'#D2D2D2',marginLeft:36,}:{marginLeft:36,}}
                            >
                                发送申请（{this.state.TrainSendNum}）
                            </button>
                            <div className={'TeamListHeaderBox'} style={{margin:'0 auto',width:916,}}>
                                <div className={'TeamHeaderList'} style={{width:40,}}>
                                    <div className={'TeamHeaderCheckbox'}
                                         onClick={() =>this.state.TrainInfoArr.length === 0 ? null : this.CheckboxIcoAll(this.state.TrainInfoArr)}>
                                        {
                                            this.state.CheckboxIcoToggleOn === false &&
                                            <div className={'TeamHeaderCheckboxActive'}> </div>
                                        }
                                    </div>
                                </div>
                                <div className={'TeamHeaderList'} style={{width:100,}}>序号</div>
                                <div className={'TeamHeaderList'} style={{width:512,}}>机构名称/地址（ID号）</div>
                                <div className={'TeamHeaderList'} style={{width:120,}}>详情</div>
                                <div className={'TeamHeaderList'} style={{width:140,}}>发送申请</div>
                            </div>
                            <div className={'TeamListConBox'} style={{margin:'0 auto',width:916,}}>
                                {
                                    this.state.TrainInfoArr.map((item,index) =>
                                        <div className={'TeamListCon'} key={index}>
                                            <div className={'TeamConList'} style={{width:40,}}>
                                                <div className={'CheckboxIco'}
                                                     style={{backgroundImage:item.send === true ? '' : (item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
                                                         marginTop:7,
                                                     }}
                                                     onClick={item.send === true ? null : () => this.CheckboxIco(item,index)}>
                                                </div>
                                            </div>
                                            <div className={'TeamConList'} style={{width:100,}}>{index+1}</div>
                                            <div className={'TeamConList Ellipsis'} style={{width:512,fontSize:12,}}>{item.BigName}</div>
                                            <div className={'TeamConList'} style={{width:120,}}>
                                                <span className={'DetailsViews'} onClick={() => this.TrainDetails(item,index)}>查看</span>
                                            </div>
                                            <div className={'TeamConList'} style={{width:140,}}>
                                                <div className={'TeamSendIco'}
                                                     style={item.send === true ? this.state.TrainSendIcoNotStyle : this.state.TrainSendIcoHaveStyle}
                                                     onClick={item.send === true ? null : () => this.TrainSendIco(item,index)}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '申请挂靠' && this.state.TrainPageShow === '已挂靠' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TrainPageShowWord'}>您已有挂靠单位，请先与您的挂靠单位协商解除挂靠关系再进行本操作！</div>
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '有两个选择按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default PeTeacher;
