import React, { Component } from 'react';
import './showfile.css';
import AuthHeader from "../handf/AuthHeader";


class AboutMine extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :window.innerHeight,
        };
    }
    componentDidMount=function() {

    };


    render() {
        return (
            <div className={'MainBox'} style={{backgroundImage:'none',}}>
                <AuthHeader/>
                <div className={'MainContent'}>
                    <div className={'FriendLinkBox clearfix'}>
                        <div className={'MineSloGon'}> </div>
                        <div className={'MineWordBox'} style={{marginBottom:40,}}>
                            <div className={'MineWordTitle'} style={{textIndent:0,}}>模拟飞行运动项目</div>
                            <div className={'MineWordTitle'}>模拟飞行是一项体育运动，受到国家专业机构管理和引导发展</div>
                            <div className={'MineWord'}>模拟飞行运动是我国的航空体育运动项目之一，2005年由国家体育总局立项，航空无线电模型运动管理中心、中国航空运动协会进行日常管理的全国正式体育项目。</div>
                            <div className={'MineWord'}>模拟飞行运动是以航空为核心开展的知识普及、人员训练、竞赛活动等形式的总称。除民航、军队管理规章要求的获取飞行经历的模拟飞行训练、检查、考试外，均属模拟飞行运动范畴。</div>
                            <div className={'MineWordTitle'}>模拟飞行是科技体育的最佳体现，得到政策环境支持，具备市场成长空间。</div>
                            <div className={'MineWord'}>模拟飞行是航空体育项目和计算机互联网技术充分融合的成果，也是航空体育科技的代表项目之一，可以作为信息化教学产品、企业及个人的数据服务、虚拟现实软硬件技术等多种科技产品和服务落地的最佳的载体。</div>
                            <div className={'MineWord'}>目前国家正在大力发展航空事业，航空人才培养和未来航空人员储备是主要发展方向，也得到国家及社会各界的重视。现阶段出台的相关政策譬如“体教融合”、“中国航空运动协会两期管理办法”等突出的就是航空、体育相关基础人才培养政策。这些条件对促成此类行业的兴起提供了平台和发展空间。</div>
                            <div className={'MineWordTitle'} style={{textIndent:0,marginTop:40,}}>北京鹰扬科技有限公司</div>
                            <div className={'MineWord'}>北京鹰扬科技有限公司是中国最具影响力模拟飞行运动企业，成立即专注于模拟飞行运动的推广，为参与模拟飞行运动的企业、学校、社会团体和个人爱好者群体提供专业的技术平台与信息服务，并将虚拟现实、虚拟仿真可视化技术应用于模拟飞行的软硬件系统中作为公司的发展方向，推动校企结合，打造横跨教育训练、航空、竞技体育三大领域的航空科技体育生态圈。</div>
                            <div className={'MineWord'}>公司现为北京航空运动协会会员单位、中国地理学会会员单位、中关村高新技术企业、中国虚拟现实与可视化产业技术创新战略联盟理事单位，模拟飞行大学生联盟创始单位，中国航空运动协会《模拟飞行运动管理办法》授权推广单位，建设运营中国航协模拟飞行服务平台（www.asfcyy.com）。与微软（微软模拟飞行）、Eagle Dynamics（数字战斗模拟世界）、北大虚拟仿真可视化工程研究中心等多个模拟飞行、虚拟现实软硬件企业紧密合作，大力投入模拟飞行相关系统软硬件研发，拥有10多项模拟飞行相关软件著作权、并将技术成果应用到模拟飞行的日常训练、测试、赛事活动中，协助中国航空运动协会举办多场全国性赛事。</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AboutMine;