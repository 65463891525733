import React, { Component } from 'react';
import './pay.css';
import PayWeChat from './PayWeChat';
import PayAlipay from './PayAlipay';
import PayTransfer from './PayTransfer';

//import {get,} from "../../ajax/tools";



class PayActive extends Component {

    constructor(props){
        super(props);
        this.state = {
            PageType:'',//从何页面转过来进行交钱支付
            PayState:'支付方式',//'支付方式','支付宝支付','微信支付','对公转账'
            OrderIdInfo:{},//订单信息
            PaySuccess:'正在支付',
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            PageType:history.PageType,
            OrderIdInfo:history.OrderIdInfo,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        this.setState({
            PageType:history.PageType,
            OrderIdInfo:history.OrderIdInfo,
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    PayActiveBackBox=()=>{
        if (this.state.PayState === '支付方式'){
            this.props.PayActiveBack(this.state.PageType);
        }else {
            this.setState({
                PayState:'支付方式'
            });
        }
    };

    PayWeChat(){
        this.setState({
            PayState:'微信支付'
        });
    };

    PayAlipay(){
        this.setState({
            PayState:'支付宝支付'
        });
    };

    PayTransfer(){
        this.setState({
            PayState:'对公转账'
        });
    };



    PayPageSuccess=(event)=>{
        if(event === 'Success'){
            this.setState({
                PaySuccess:'成功支付'
            });
        }else if(event === 'PayTypeBack'){
            this.setState({
                PayState:'支付方式'
            });
        }else if(event === 'SuccessTransfer'){
            this.setState({
                PaySuccess:'对公转账开始'
            });
        }
    };

    render() {
        //console.log(this.state.OrderIdInfo)
        return (
            <div className={'PayActiveMainBoxes'}>
                {
                    this.state.PaySuccess === '正在支付'&&
                    <div>
                        <div className={'PayActiveMainBoxesTit PayActiveBorderBottom'}>
                            {this.state.PayState}
                            <div className={'DetailsBackBox PayActiveBackBox clearfix'}
                                 onClick={this.PayActiveBackBox.bind(this)}
                            >
                                <div className={'DetailsBackIco'}> </div>
                                <div className={'DetailsBackWord'}>返回</div>
                            </div>
                        </div>
                        {
                            this.state.PayState === '支付方式' &&
                            <div style={{height:window.innerHeight-310,}}>
                                <div className={'PayActiveLittleTitle'} style={{marginBottom:120,}}>目前仅支持支付宝\微信扫码、对公转账方式付款</div>
                                <button className={'PayActiveButton'}
                                        style={{color:'#FFFFFF',backgroundColor:'#138AE0',}}
                                        onClick={this.PayAlipay.bind(this)}
                                >
                                    支付宝
                                    <div className={'PayArrowIco'}> </div>
                                </button>
                                <button className={'PayActiveButton'}
                                        style={{color:'#121212',backgroundColor:'#77CD38',}}
                                        onClick={this.PayWeChat.bind(this)}
                                >
                                    微信
                                    <div className={'PayArrowIco'}> </div>
                                </button>
                                <button className={'PayActiveButton'}
                                        style={{color:'#313131',backgroundColor:'#6EC2FF',}}
                                        onClick={this.PayTransfer.bind(this)}
                                >
                                    对公转账
                                    <div className={'PayArrowIco'}> </div>
                                </button>
                            </div>
                        }
                        {
                            this.state.PayState === '微信支付' &&
                            <PayWeChat
                                PayPageSuccess={this.PayPageSuccess.bind(this)}
                                OrderIdInfo={this.state.OrderIdInfo}
                            />
                        }
                        {
                            this.state.PayState === '支付宝支付' &&
                            <PayAlipay
                                PayPageSuccess={this.PayPageSuccess.bind(this)}
                                OrderIdInfo={this.state.OrderIdInfo}
                            />
                        }
                        {
                            this.state.PayState === '对公转账' &&
                            <PayTransfer
                                PayPageSuccess={this.PayPageSuccess.bind(this)}
                                OrderIdInfo={this.state.OrderIdInfo}
                            />
                        }
                    </div>
                }
                {
                    this.state.PaySuccess === '成功支付'&&
                        <div>
                            <div className={'ReSuccessBox DisplayFlex'} style={{marginTop:180,marginBottom:24,}}>
                                <div className={'ReSuccessIco'}> </div>
                                <div className={'ReSuccessWord'} style={{float:'left',marginLeft:20,}}>支付成功！</div>
                            </div>
                            <div className={'ManualReviewBlackWord'} style={{marginTop:100,}} onClick={this.PayActiveBackBox.bind(this)}>返回申请信息列表</div>
                        </div>
                }
                {
                    this.state.PaySuccess === '对公转账开始'&&
                        <div>
                            <div className={'ReSuccessBox DisplayFlex'} style={{marginTop:180,marginBottom:24,}}>
                                <div className={'ReSuccessIco'}> </div>
                                <div className={'ReSuccessWord'} style={{float:'left',marginLeft:20,}}>已生成对公转账订单</div>
                            </div>
                            <div>
                                <div className={'NewAppWorkersInfo'}>如有问题请联系我们：</div>
                                <div className={'NewAppWorkersInfo'}>韩昱老师 ：13671194098</div>
                                {/*<div className={'NewAppWorkersInfo'}>韩昱老师 ：13671194098 (西北地区、西南地区)</div>*/}
                                {/*<div className={'NewAppWorkersInfo'}>杨旭老师 ：15603712839 (华中地区、华南地区)</div>*/}
                            </div>
                            <div className={'ManualReviewBlackWord'} style={{marginTop:100,}} onClick={this.PayActiveBackBox.bind(this)}>返回申请信息列表</div>
                        </div>
                }
            </div>
        );
    }
}


export default PayActive;
