import React, { Component } from 'react'
import Triangle from './Triangle'
import './round.css'
import { get } from '../../ajax/tools'

class ThreeLevelLinkage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Width: '',
      Height: '',
      TopHeight: '',
      SonTopHeight: '',
      Type: '',
      ProvinceName: '省/自治区',
      ProvinceArr: [],
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      CityName: '市/地区',
      CityArr: [],
      cityToggleOn: true,
      cityDisplay: 'none',
      AreaName: '县/区',
      AreaArr: [],
      areaToggleOn: true,
      areaDisplay: 'none',
      Year: '',
      YearArr: [],
      YearToggleOn: true,
      YearDisplay: 'none',
      Month: '',
      MonthArr: [],
      MonthToggleOn: true,
      MonthDisplay: 'none',
      Day: '',
      DayArr: [],
      DayToggleOn: true,
      DayDisplay: 'none',
      TwoType: '',
    }
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const history = nextProps
    if (history.Height) {
      this.setState({
        Height: history.Height + 'px',
        TopHeight: (history.Height - 10) / 2 + 1 + 'px',
        SonTopHeight: history.Height + 2 + 'px',
      })
    } else {
      this.setState({
        Height: '42px',
        TopHeight: '17px',
        SonTopHeight: '44px',
      })
    }
    if (history.TwoType) {
      this.setState({
        TwoType: history.TwoType,
      })
    }
    if (history.ThreeLevelLinkage === 'none') {
      this.setState({
        ProvinceToggleOn: true,
        ProvinceDisplay: 'none',
        cityToggleOn: true,
        cityDisplay: 'none',
        areaToggleOn: true,
        areaDisplay: 'none',
        YearToggleOn: true,
        YearDisplay: 'none',
        MonthToggleOn: true,
        MonthDisplay: 'none',
        DayToggleOn: true,
        DayDisplay: 'none',
      })
    }
    this.setState({
      Type: history.type,
      Width: history.Width / 3 - 3 + 'px',
      WidthWord: history.Width / 3 - 33 + 'px',
    })
  }
  componentDidMount = function () {
    const history = this.props
    if (history.Height) {
      this.setState({
        Height: history.Height + 'px',
        TopHeight: (history.Height - 10) / 2 + 1 + 'px',
        SonTopHeight: history.Height + 2 + 'px',
      })
    } else {
      this.setState({
        Height: '42px',
        TopHeight: '17px',
        SonTopHeight: '44px',
      })
    }
    if (history.TwoType) {
      this.setState({
        TwoType: history.TwoType,
      })
    }
    if (history.ThreeLevelLinkage === 'none') {
      this.setState({
        ProvinceToggleOn: true,
        ProvinceDisplay: 'none',
        cityToggleOn: true,
        cityDisplay: 'none',
        areaToggleOn: true,
        areaDisplay: 'none',
        YearToggleOn: true,
        YearDisplay: 'none',
        MonthToggleOn: true,
        MonthDisplay: 'none',
        DayToggleOn: true,
        DayDisplay: 'none',
      })
    }
    this.setState({
      Type: history.type,
      Width: history.Width / 3 - 3 + 'px',
      WidthWord: history.Width / 3 - 33 + 'px',
    })
    get({ url: 'reuse/province' }).then((res) => {
      if (res.errno === '200') {
        const provinces = []
        for (let i = 0; i < res.data.length; i++) {
          const provincesList = res.data[i]
          provinces.push(provincesList)
        }
        this.setState({
          ProvinceArr: provinces,
        })
      } else {
        //alert('网络错误！！！')
      }
    })
    let Year = function () {
      let date = new Date()
      let ToDayYear = date.getFullYear()
      const Years = []
      if (history.TwoType === '未来时间') {
        for (let i = ToDayYear; i <= ToDayYear + 5; i++) {
          Years.push(i)
        }
      } else {
        for (let i = 1950; i <= ToDayYear - 1; i++) {
          Years.push(i)
        }
      }
      return Years
    }
    this.setState({
      YearArr: Year(),
    })
    document.onclick = this.AllClick.bind(this) //下拉菜单监听隐藏
  }

  AllClick = () => {
    this.setState({
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      cityToggleOn: true,
      cityDisplay: 'none',
      areaToggleOn: true,
      areaDisplay: 'none',
      YearToggleOn: true,
      YearDisplay: 'none',
      MonthToggleOn: true,
      MonthDisplay: 'none',
      DayToggleOn: true,
      DayDisplay: 'none',
    })
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  ProvinceClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      ProvinceToggleOn: !prevState.ProvinceToggleOn,
      ProvinceDisplay: prevState.ProvinceToggleOn ? 'block' : 'none',
      cityToggleOn: true,
      cityDisplay: 'none',
      areaToggleOn: true,
      areaDisplay: 'none',
      SchoolToggleOn: true,
      SchoolDisplay: 'none',
      YearToggleOn: true,
      YearDisplay: 'none',
      MonthToggleOn: true,
      MonthDisplay: 'none',
      DayToggleOn: true,
      DayDisplay: 'none',
    }))
  }
  CityClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      cityToggleOn: !prevState.cityToggleOn,
      cityDisplay: prevState.cityToggleOn ? 'block' : 'none',
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      areaToggleOn: true,
      areaDisplay: 'none',
      SchoolToggleOn: true,
      SchoolDisplay: 'none',
      YearToggleOn: true,
      YearDisplay: 'none',
      MonthToggleOn: true,
      MonthDisplay: 'none',
      DayToggleOn: true,
      DayDisplay: 'none',
    }))
  }
  AreaClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      areaToggleOn: !prevState.areaToggleOn,
      areaDisplay: prevState.areaToggleOn ? 'block' : 'none',
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      cityToggleOn: true,
      cityDisplay: 'none',
      SchoolToggleOn: true,
      SchoolDisplay: 'none',
      YearToggleOn: true,
      YearDisplay: 'none',
      MonthToggleOn: true,
      MonthDisplay: 'none',
      DayToggleOn: true,
      DayDisplay: 'none',
    }))
  }

  YearClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      YearToggleOn: !prevState.YearToggleOn,
      YearDisplay: prevState.YearToggleOn ? 'block' : 'none',
      SchoolToggleOn: true,
      SchoolDisplay: 'none',
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      cityToggleOn: true,
      cityDisplay: 'none',
      areaToggleOn: true,
      areaDisplay: 'none',
      MonthToggleOn: true,
      MonthDisplay: 'none',
      DayToggleOn: true,
      DayDisplay: 'none',
    }))
  }
  MonthClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      MonthToggleOn: !prevState.MonthToggleOn,
      MonthDisplay: prevState.MonthToggleOn ? 'block' : 'none',
      SchoolToggleOn: true,
      SchoolDisplay: 'none',
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      cityToggleOn: true,
      cityDisplay: 'none',
      areaToggleOn: true,
      areaDisplay: 'none',
      YearToggleOn: true,
      YearDisplay: 'none',
      DayToggleOn: true,
      DayDisplay: 'none',
    }))
  }
  DayClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      DayToggleOn: !prevState.DayToggleOn,
      DayDisplay: prevState.DayToggleOn ? 'block' : 'none',
      SchoolToggleOn: true,
      SchoolDisplay: 'none',
      ProvinceToggleOn: true,
      ProvinceDisplay: 'none',
      cityToggleOn: true,
      cityDisplay: 'none',
      areaToggleOn: true,
      areaDisplay: 'none',
      YearToggleOn: true,
      YearDisplay: 'none',
      MonthToggleOn: true,
      MonthDisplay: 'none',
    }))
  }

  ProvinceArrClick = (event, index) => {
    this.setState({
      ProvinceName: event.areas,
      CityName: '市/地区',
      AreaName: '县/区',
    })
    get({ url: 'reuse/city?f_id=' + event.id }).then((res) => {
      if (res.errno === '200') {
        const citys = []
        for (let i = 0; i < res.data.length; i++) {
          const citysList = res.data[i]
          citys.push(citysList)
        }
        this.setState({
          CityArr: citys,
        })
      } else {
        //alert('网络错误！！！')
      }
    })
    let BackFather = {
      ValueP: event.areas,
      ValueC: '',
      ValueA: '',
      Type: this.state.Type,
      Title: '省',
    }
    this.props.ChildLinkClick(BackFather)
  }
  CityArrClick = (event, index) => {
    this.setState({
      CityName: event.areas,
      AreaName: '县/区',
    })
    get({ url: 'reuse/county?f_id=' + event.id }).then((res) => {
      if (res.errno === '200') {
        const areas = []
        for (let i = 0; i < res.data.length; i++) {
          const areasList = res.data[i]
          areas.push(areasList)
        }
        this.setState({
          AreaArr: areas,
        })
      } else {
        //alert('网络错误！！！')
      }
    })
    let BackFather = {
      ValueP: this.state.ProvinceName,
      ValueC: event.areas,
      ValueA: '',
      Type: this.state.Type,
      Title: '市',
    }
    this.props.ChildLinkClick(BackFather)
  }
  AreaArrClick = (event, index) => {
    this.setState({
      AreaName: event.areas,
    })
    let BackFather = {
      ValueP: this.state.ProvinceName,
      ValueC: this.state.CityName,
      ValueA: event.areas,
      Type: this.state.Type,
      Title: '区',
    }
    this.props.ChildLinkClick(BackFather)
  }

  YearArrClick = (event, index) => {
    let date = new Date()
    let ToDayYear = date.getFullYear()
    let ToDayMonth = date.getMonth() + 1
    let TwoType = this.state.TwoType
    let Month = function () {
      const Months = []
      if (TwoType === '未来时间' && event === ToDayYear) {
        for (let i = ToDayMonth; i <= 12; i++) {
          if (i < 10) {
            i = '0' + i
          }
          Months.push(i)
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            i = '0' + i
          }
          Months.push(i)
        }
      }
      return Months
    }
    this.setState({
      Year: event,
      Month: '',
      Day: '',
      MonthArr: Month(),
    })
    let BackFather = {
      ValueY: event,
      ValueM: '',
      ValueD: '',
      Type: this.state.Type,
      Title: '年',
    }
    this.props.ChildLinkClick(BackFather)
  }
  MonthArrClick = (event, index) => {
    let Year = this.state.Year
    let Month = event
    let date = new Date()
    let ToDayYear = date.getFullYear()
    let ToDayMonth = date.getMonth()
    let ToDayDate = date.getDate()
    let TwoType = this.state.TwoType
    const dayLast = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    let Day = function () {
      if (Year % 4 === 0 && (Year % 100 !== 0 || Year % 400 === 0)) {
        if (Month === 2) {
          dayLast[Month - 1]++
        }
      }
      const Days = []
      if (
        TwoType === '未来时间' &&
        event - 1 === ToDayMonth &&
        ToDayYear === Year
      ) {
        for (let i = ToDayDate + 1; i <= dayLast[Month - 1]; i++) {
          if (i < 10) {
            i = '0' + i
          }
          Days.push(i)
        }
      } else {
        for (let i = 1; i <= dayLast[Month - 1]; i++) {
          if (i < 10) {
            i = '0' + i
          }
          Days.push(i)
        }
      }
      return Days
    }
    this.setState({
      Month: event,
      Day: '',
      DayArr: Day(),
    })
    let BackFather = {
      ValueY: this.state.Year,
      ValueM: event,
      ValueD: '',
      Type: this.state.Type,
      Title: '月',
    }
    this.props.ChildLinkClick(BackFather)
  }
  DayArrClick = (event, index) => {
    this.setState({
      Day: event,
    })
    let BackFather = {
      ValueY: this.state.Year,
      ValueM: this.state.Month,
      ValueD: event,
      Type: this.state.Type,
      Title: '日',
    }
    this.props.ChildLinkClick(BackFather)
  }

  render() {
    return (
      <div className={'ThreeLevelLinkageBox clearfix'}>
        {this.state.Type !== '出生日期' && this.state.Type !== '灰度' && (
          <div>
            <div
              className={'LevelLinkBox clearfix'}
              style={{ width: this.state.Width }}
              tabIndex={'1'}
              onClick={this.ProvinceClick.bind(this)}
            >
              <div
                className={'LevelLinkWordBox clearfix'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.ProvinceName}
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
              <div
                className={'LevelLinkListBox'}
                style={{
                  display: this.state.ProvinceDisplay,
                  top: this.state.SonTopHeight,
                }}
              >
                {this.state.ProvinceArr.map((item, index) => (
                  <div
                    className={'LevelLinkList'}
                    key={index}
                    onClick={() => this.ProvinceArrClick(item, index)}
                  >
                    {item.areas}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={'LevelLinkBox'}
              style={{ width: this.state.Width, margin: '0 4px' }}
              tabIndex={'1'}
              onClick={this.CityClick.bind(this)}
            >
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.CityName}
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
              <div
                className={'LevelLinkListBox'}
                style={{
                  display: this.state.cityDisplay,
                  top: this.state.SonTopHeight,
                }}
              >
                {this.state.CityArr.map((item, index) => (
                  <div
                    className={'LevelLinkList'}
                    key={index}
                    onClick={() => this.CityArrClick(item, index)}
                  >
                    {item.areas}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={'LevelLinkBox'}
              style={{ width: this.state.Width }}
              tabIndex={'1'}
              onClick={this.AreaClick.bind(this)}
            >
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.AreaName}
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
              <div
                className={'LevelLinkListBox'}
                style={{
                  display: this.state.areaDisplay,
                  top: this.state.SonTopHeight,
                }}
              >
                {this.state.AreaArr.map((item, index) => (
                  <div
                    className={'LevelLinkList'}
                    key={index}
                    onClick={() => this.AreaArrClick(item, index)}
                  >
                    {item.areas}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.Type === '出生日期' && (
          <div>
            <div
              className={'LevelLinkBox'}
              style={{ width: this.state.Width }}
              tabIndex={'3'}
              onClick={this.YearClick.bind(this)}
            >
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.Year}年
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
              <div
                className={'LevelLinkListBox'}
                style={{
                  display: this.state.YearDisplay,
                  top: this.state.SonTopHeight,
                }}
              >
                {this.state.YearArr.map((item, index) => (
                  <div
                    className={'LevelLinkList'}
                    key={index}
                    onClick={() => this.YearArrClick(item, index)}
                  >
                    {item}年
                  </div>
                ))}
              </div>
            </div>
            <div
              className={'LevelLinkBox'}
              style={{ width: this.state.Width, margin: '0 4px' }}
              tabIndex={'3'}
              onClick={this.MonthClick.bind(this)}
            >
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.Month}月
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
              <div
                className={'LevelLinkListBox'}
                style={{
                  display: this.state.MonthDisplay,
                  top: this.state.SonTopHeight,
                }}
              >
                {this.state.MonthArr.map((item, index) => (
                  <div
                    className={'LevelLinkList'}
                    key={index}
                    onClick={() => this.MonthArrClick(item, index)}
                  >
                    {item}月
                  </div>
                ))}
              </div>
            </div>
            <div
              className={'LevelLinkBox'}
              style={{ width: this.state.Width }}
              tabIndex={'3'}
              onClick={this.DayClick.bind(this)}
            >
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.Day}日
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
              <div
                className={'LevelLinkListBox'}
                style={{
                  display: this.state.DayDisplay,
                  top: this.state.SonTopHeight,
                }}
              >
                {this.state.DayArr.map((item, index) => (
                  <div
                    className={'LevelLinkList'}
                    key={index}
                    onClick={() => this.DayArrClick(item, index)}
                  >
                    {item}日
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.Type === '灰度' && (
          <div>
            <div className={'LevelLinkBox'} style={{ width: this.state.Width }}>
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.ProvinceName}
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
            </div>
            <div
              className={'LevelLinkBox'}
              style={{ width: this.state.Width, margin: '0 4px' }}
            >
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.CityName}
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
            </div>
            <div className={'LevelLinkBox'} style={{ width: this.state.Width }}>
              <div
                className={'LevelLinkWordBox'}
                style={{
                  width: this.state.WidthWord,
                  lineHeight: this.state.Height,
                }}
              >
                {this.state.AreaName}
              </div>
              <Triangle
                Direction={'down'}
                Color={'#FFFFFF'}
                Width={'6.5px'}
                Height={'10px'}
                Top={this.state.TopHeight}
                Right={'10px'}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ThreeLevelLinkage
