import axios from 'axios';
import {API_URL} from './config';
axios.defaults.baseURL=API_URL;
/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const get = ({url, headers={}}) =>{
    let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
    if (tokenAuth && tokenAuth.KeyToken) {
        headers['Access_Token'] = tokenAuth.KeyToken
    }
    return axios.get(url,{headers:headers}).then(res => res.data).catch(err => {
        var status = "";
        if(err.response&&err.response.data){
            status = err.response.status;
        }
        return {error: true, status: status}
    });
};

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const post = ({url, data, headers={}}) =>{
    let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
    if (tokenAuth && tokenAuth.KeyToken) {
        headers['Access_Token'] = tokenAuth.KeyToken
    }
    return axios.post(url, data,{headers:headers}).then(res => res.data).catch(err => {
        var status = "";
        if(err.response&&err.response.data){
            status = err.response.status;
        }
        return {error: true, status: status}
    });
};
