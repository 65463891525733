import React, { Component } from 'react'
import './news.css'
import { get } from '../../ajax/tools'
import Header from '../handf/Header'

class NewsInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      winHeight: window.innerHeight,
      NewsTitle: '',
      NewsTime: '',
      NewsInfo: '',
    }
  }
  componentDidMount = function () {
    let search = this.props.location.search
    let msg = new URLSearchParams(search.slice(1))
    let id = msg.get('id')
    get({ url: 'news/news_detail/' + id }).then((res) => {
      if (res.errno === '200') {
        let data = res.data[0]
        this.setState({
          NewsTitle: data.title,
          NewsTime: data.sort_time,
          NewsInfo: data.content,
        })
      }
    })
  }

  render() {
    return (
      <div className={'MainBox'}>
        <Header />
        <div
          className={'MainContent scrollBarTwo'}
          style={{
            height:
              window.innerHeight - 85 > 569 ? window.innerHeight - 85 : 569,
            backgroundColor: '#FFFFFF',
            width: 1000,
          }}
        >
          <div className={'GYInfoTitle'}>{this.state.NewsTitle}</div>
          <div className={'GYInfoTimeTitle'}>{this.state.NewsTime}</div>
          <div className={'GYInfoContent'}>
            <div style={{ margin: '0 48px' }}>
              <div dangerouslySetInnerHTML={{ __html: this.state.NewsInfo }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsInfo
