import React, { Component } from 'react';
import './member.css';
import '../round/round.css';
import PopUp from "../round/PopUp";

class GrService extends Component {

    constructor(props){
        super(props);
        this.state = {
            GrServiceListArr:[
                {
                    id:1,
                    nav:'个人云程',
                    router:'/member_peyc_service',
                    navNext:'',
                },
                {
                    id:2,
                    nav:'云程数据',
                    router:'/member_gryc_service',
                    navNext:'',
                },
                {
                    id:3,
                    nav:'设备仓库',
                    router:'/member_service',
                    navNext:'',
                },
                {
                    id:4,
                    nav:'周边产品',
                    router:'/member_service',
                    navNext:'',
                },
                {
                    id:5,
                    nav:'其他',
                    router:'/member_service',
                    navNext:'',
                },
            ],
        };
    }
    componentDidMount=function() {

        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if (item.id === 1 || item.id === 2){
            this.props.ChildBackNavClick(item.nav);
        }
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'MemberPackageBox scrollBar clearfix'} style={{height:window.innerHeight-180,}}>
                    <div className={'GrServiceListBox clearfix'} style={{height:window.innerHeight-344,}}>
                        {
                            this.state.GrServiceListArr.map((item,index) =>
                                <div className={'GrServiceList'} key={index} onClick={() => this.MemberTopNav(item,index)}>
                                    {item.nav}
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GrService;
