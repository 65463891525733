import React, { Component } from 'react';
import './member.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import Header from "../handf/Header";


class OldRealName extends Component {

    constructor(props){
        super(props);
        this.state = {
            RealName:'',//真实姓名或管理员姓名
            IdCard:'',//身份证号
        };
    }
    componentDidMount=function() {

    };



    //真实姓名或管理员姓名
    RealNameChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            RealName: event.target.value,
        });
    };
    //身份证号
    IdCardChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            IdCard: event.target.value,
        });
    };
    AboutMyself(){
        window.open('/aboutMyself');
    };

    OldRealNameBtn(){
        if (this.state.RealName !== '' && this.state.IdCard !== ''){
            this.setState({
                PopUp:'等候弹窗',
                MessageTip:'身份实名验证中，请稍后...',
            });
            get({url:"passport/idcard_check_old?idcard="+this.state.IdCard+"&name="+this.state.RealName}).then(res => {
                if(res.status === '01'){
                    post({
                        url:'passport/old_register',
                        data:{
                            real_name:this.state.RealName,
                            sex: res.sex,
                            birth: res.birthday,
                            birth_province: res.province,
                            birth_city: res.city,
                            birth_county: res.prefecture,
                            birth_addr: res.area,
                            idcard: this.state.IdCard
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'实名认证成功。',
                            });
                            alert('实名认证成功。')
                            const {history} = this.props;
                            history.push('/member');
                            // this.props.ConfirmButton('实名认证');
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else{
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.msg,
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入完整的实名信息！',
            });
        }
    };



    OutLogin(){
        sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
        window.location.reload();
        this.setState({
        });
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };
    ChildBack=(event)=>{
    };

    render() {
        return (
            <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                <Header/>
                <div className={'MemberPackageBox'}>
                    <div className={'OldRealNameBox'} style={{height:window.innerHeight-87}}>
                        <div className={'OldRealNameTitle'}>请完成实名认证</div>
                        <div className={'OldRealNameTit'}>
                            *如使用其他证件实名请
                            <span className={'ClickDetails'} onClick={this.AboutMyself.bind(this)}>联系我们</span>
                            ！
                        </div>
                        <div className={'InfoBoxOdd'} style={{margin:'0 auto',}}>
                            <div className={'InfoTitBox'} style={{width:100,marginRight:30,}}>
                                <div className={'InfoTit'} style={{textAlign:'right',}}>*姓名</div>
                            </div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'RealName'}
                                   value={this.state.RealName}
                                   onChange={this.RealNameChange.bind(this)}
                                   placeholder={'请输入真实姓名'}
                                   autoComplete={'off'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{margin:'28px auto',}}>
                            <div className={'InfoTitBox'} style={{width:100,marginRight:30,}}>
                                <div className={'InfoTit'} style={{textAlign:'right',}}>*身份证号</div>
                            </div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'RealName'}
                                   value={this.state.IdCard}
                                   onChange={this.IdCardChange.bind(this)}
                                   placeholder={'请输入身份证号'}
                                   autoComplete={'off'}
                            />
                        </div>
                        <button className={'NextButton'} style={{marginTop:160,}} onClick={this.OldRealNameBtn.bind(this)}>确认实名</button>
                        <div className={'OutLogin ClickDetails'} onClick={this.OutLogin.bind(this)}>注销登录</div>
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '等候弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBack.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
            </div>
        );
    }
}


export default OldRealName;
