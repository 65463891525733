import React, { Component } from 'react';
import './game.css';
import {get} from "../../ajax/tools";



class GameSignUpIco extends Component {

    constructor(props){
        super(props);
        this.state = {
            GameSignUpIcoArr:[],
            GameSignUpIcoArrLength:null,
            GameSignUpIcoErr:'',
        };
    }
    componentDidMount=function() {
        get({url:'match/get_match'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GameSignUpIcoArr:res.data,
                    GameSignUpIcoArrLength:res.data.length,
                });
            }else {
                this.setState({
                    GameSignUpIcoErr:'Err',
                });
            }
        });
    };


    GameSignUpIcoBtn=(item,index) =>{
        let ClickInfo = {GameId:item.id,GameName:item.match_name,GameNum:item.team_limit_man_num,GameType:item.enroll_type,};
        this.props.GameSignUpIco(ClickInfo);
    };


    ShowProcess(){
        let a = document.createElement('a');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/22fd070efe8811ec9dc800163e3521a2.pdf');
        a.setAttribute('target','_blank');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };

    ShowProcessNet(){
        let a = document.createElement('a');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/8349d9b6fe8811ec9dc800163e3521a2.mp4');
        a.setAttribute('target','_blank');
        a.setAttribute('download','FS');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };
    ShowProcessNet2(){
        let a = document.createElement('a');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/2d7bb5d8718611ed9dc800163e3521a2.pdf');
        a.setAttribute('target','_blank');
        a.setAttribute('download','FS');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };
    ShowProcessVerb(){
        let a = document.createElement('a');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/f8826b0a031d11ed9dc800163e3521a2.pdf');
        a.setAttribute('target','_blank');
        a.setAttribute('download','FS');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };
    ShowProcessList(){
        let a = document.createElement('a');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/49f54de67a1811ee9dc800163e3521a2.docx');
        a.setAttribute('target','_blank');
        a.setAttribute('download','FS');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };
    ShowPAsk(){
        let a = document.createElement('a');
        a.setAttribute('href','http://oss.asfcyy.com/richTextImage/35cafc4610d411ef9dca00163e3521a2.pdf');
        a.setAttribute('target','_blank');
        a.setAttribute('download','FS');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
    };




    render() {
        return (
            <div className={'GameMainBox scrollBar'} style={{height:window.innerHeight-191,}}>
                <div className={'InfoListPositionTitBox'} style={{height:50,}}>
                    {/*<div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>*/}
                    {/*    <div className={'InfoListPosition'}>选择赛事</div>*/}
                    {/*</div>*/}
                    <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>
                        <div className={'InfoListPosition'}>
                            点击查看 <span style={{color:'#FB621E', textDecoration: 'underline',}} onClick={this.ShowProcess.bind(this)}>参赛报名流程</span>
                        </div>
                        <div className={'InfoListPosition'} style={{textAlign:'right',marginTop:4,position:'relative',zIndex:'9898'}}>
                            <span style={{color:'#FB621E', textDecoration: 'underline',}} onClick={this.ShowProcessList.bind(this)}>参赛组队附表</span>
                        </div>
                    </div>
                    <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>
                        <div className={'InfoListPosition'}>
                            点击查看 <span style={{color:'#FB621E', textDecoration: 'underline',}} onClick={this.ShowProcessNet.bind(this)}>全国赛网赛视频拍摄流程</span>
                        </div>
                        <div className={'InfoListPosition'} style={{textAlign:'right',marginTop:4,position:'relative',zIndex:'9898'}}>
                            <span style={{color:'#FB621E', textDecoration: 'underline',}} onClick={this.ShowProcessNet2.bind(this)}>参赛视频录制及上传要求</span>
                        </div>
                    </div>
                    <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>
                        <div className={'InfoListPosition'}>
                            点击查看 <span style={{color:'#FB621E', textDecoration: 'underline',}} onClick={this.ShowProcessVerb.bind(this)}>全国赛解说口才线上参赛须知</span>
                        </div>
                        <div className={'InfoListPosition'} style={{textAlign:'right',marginTop:4,position:'relative',zIndex:'9898',marginRight:80,}}>
                            <span style={{color:'#FB621E', textDecoration: 'underline',}} onClick={this.ShowPAsk.bind(this)}>知识竞赛答题说明</span>
                        </div>
                    </div>
                </div>
                {
                    this.state.GameSignUpIcoArrLength !== 0 && this.state.GameSignUpIcoArrLength !== null &&
                    <div className={'GameSignUpIcoBoxes clearfix'}>
                        {
                            this.state.GameSignUpIcoArr.map((item,index) => (
                                <div className={'GameSignUpIcoBox'} key={index}>
                                    <div className={'GameSignUpIco'} onClick={() => this.GameSignUpIcoBtn(item,index)}> </div>
                                    <div className={'GameSignUpIcoTitle'} style={{width:360,}}>{item.match_name}</div>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    this.state.GameSignUpIcoArrLength === 0 &&
                    <div className={'NotGameTip'}>
                        <div className={'TrainDataMain'}>
                            <div className={'GameYearNum'} style={{position:'unset',fontSize:54,}}>当前未有赛事活动！</div>
                        </div>
                    </div>
                }
                {
                    this.state.GameSignUpIcoErr === 'Err' &&
                    <div className={'NotGameTip'}>
                        <div className={'TrainDataMain'}>
                            <div className={'GameYearNum'} style={{position:'unset',fontSize:54,}}>当前未有赛事活动！</div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export default GameSignUpIco;
