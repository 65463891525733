import React, { Component } from 'react';
import './inquire.css';
import {get} from "../../ajax/tools";
import Triangle from "../round/Triangle";
import PopUp from "../round/PopUp";


class InEquipment extends Component {

    constructor(props){
        super(props);
        this.state = {
            EquipmentArr:[],//设备具体信息数组
            SortArr:[{id:'1',typeNum:'0',type:'外设类',},{id:'2',typeNum:'1',type:'模拟器类',},{id:'3',typeNum:'2',type:'软件类',},{id:'4',typeNum:'3',type:'系统类',},],//设备类别项
            SortName:'',
            SortToggleOn:true,
            SortDisplay:'none',
            LevelArr:['初级','中级','高级','体验级'],//设备适用等级项
            LevelName:'',
            LevelToggleOn:true,
            LevelDisplay:'none',
            BrandArr:[],//设备品牌名称项
            BrandName:'',
            BrandToggleOn:true,
            BrandDisplay:'none',
            loading: false,
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        if (nextProps.EquipmentDataArr.length !== 0){
            this.setState({
                EquipmentArr:nextProps.EquipmentDataArr,
            });
        }else {
            get({url:'query/device_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        EquipmentArr:res.data,
                    });
                }
            });
        }
    };

    componentDidMount=function() {
        let history = this.props;
        if (history.EquipmentDataArr.length !== 0){
            this.setState({
                EquipmentArr:history.EquipmentDataArr,
            });
        }else {
            get({url:'query/device_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        EquipmentArr:res.data,
                    });
                }
            });
        }

        get({url:'query/get_device_brand'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    BrandArr:res.data,
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllDataBtn(){
        get({url:'query/device_query'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    EquipmentArr:res.data,
                });
            }
        });
    };

    AllClick = () => {
        this.setState({
            SortToggleOn: true,
            SortDisplay: 'none',
            LevelToggleOn:true,
            LevelDisplay:'none',
            BrandToggleOn:true,
            BrandDisplay:'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //设备类别的下拉菜单
    SortDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            SortToggleOn: !prevState.SortToggleOn,
            SortDisplay:prevState.SortToggleOn ? 'block' : 'none',
            LevelToggleOn: true,
            LevelDisplay: 'none',
            BrandToggleOn:true,
            BrandDisplay:'none',
        }));
    };
    SortDownNav=(item,index)=>{
        get({url:'query/device_type_query/'+item.typeNum}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    EquipmentArr:res.data,
                    SortName:item.type,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'查询无数据！',
                });
            }
        });
        this.setState({
            SortToggleOn: true,
            SortDisplay: 'none',
        });
    };

    //设备适用等级的下拉菜单
    LevelDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            LevelToggleOn: !prevState.LevelToggleOn,
            LevelDisplay:prevState.LevelToggleOn ? 'block' : 'none',
            SortToggleOn: true,
            SortDisplay: 'none',
            BrandToggleOn:true,
            BrandDisplay:'none',
        }));
    };
    LevelDownNav=(item,index)=>{
        get({url:'query/device_level_query/'+item}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    EquipmentArr:res.data,
                    LevelName:item,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'查询无数据！',
                });
            }
        });
        this.setState({
            LevelToggleOn: true,
            LevelDisplay: 'none',
        });
    };


    //设备品牌名称的下拉菜单
    BrandDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            BrandToggleOn: !prevState.BrandToggleOn,
            BrandDisplay:prevState.BrandToggleOn ? 'block' : 'none',
            SortToggleOn: true,
            SortDisplay: 'none',
            LevelToggleOn: true,
            LevelDisplay: 'none',
        }));
    };
    BrandDownNav=(item,index)=>{
        get({url:'query/device_brand_query/'+item.brand}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    EquipmentArr:res.data,
                    BrandName:item.brand,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'查询无数据！',
                });
            }
        });
        this.setState({
            BrandToggleOn:true,
            BrandDisplay:'none',
        });
    };


    //点击设备名称详情
    ActiveEquipment=(item,index)=>{
        let ActiveEquipment = {
            Inquire:'Equipment',
            Id:item.id,
            DataArr:this.state.EquipmentArr,
        };
        this.props.ActiveSeeDetails(ActiveEquipment);
    };

    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'InquirePackageBox'} style={{minHeight:window.innerHeight-430 > 450 ? window.innerHeight-430 :450,}}>
                    <div className={'InquireAllDataBtn clearfix'} onClick={this.AllDataBtn.bind(this)}>
                        <div className={'InquireAllDataIco'}> </div>
                        <div className={'InquireAllDataWord'}>全部信息</div>
                    </div>
                    <div className={'InquirePackageHeaderBox'}>
                        <div className={'InquirePackageHeaderList'} style={{width:194,}}>
                            <div className={'InquireHeaderSelect'}>
                                <div style={{height:26,}} onClick={this.SortDown.bind(this)}>
                                    <div className={'InquireSelectWord'}>设备类别</div>
                                    {
                                        this.state.SortToggleOn === true &&
                                        <Triangle Direction={'right'} Color={'#FFFFFF'}
                                                  Width={'9px'} Height={'6px'}
                                                  Top={'7px'} Right={'0'}
                                        />
                                    }
                                    {
                                        this.state.SortToggleOn === false &&
                                        <Triangle Direction={'down'} Color={'#FFFFFF'}
                                                  Width={'6px'} Height={'9px'}
                                                  Top={'10px'} Right={'9px'}
                                        />
                                    }
                                </div>
                                <div className={'EquipmentDownNavBox'} style={{display:this.state.SortDisplay,}}>
                                    {
                                        this.state.SortArr.map((item,index) =>
                                            <div className={'EquipmentDownNav'}
                                                 key={index}
                                                 onClick={() => this.SortDownNav(item,index)}
                                                 style={this.state.SortName === item ? {backgroundColor:'#8DCEFD'} : {}}>
                                                {item.type}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'InquirePackageHeaderList'} style={{width:194,}}>
                            <div className={'InquireHeaderSelect'}>
                                <div style={{height:26,}} onClick={this.LevelDown.bind(this)}>
                                    <div className={'InquireSelectWord'}>适用等级</div>
                                    {
                                        this.state.LevelToggleOn === true &&
                                        <Triangle Direction={'right'} Color={'#FFFFFF'}
                                                  Width={'9px'} Height={'6px'}
                                                  Top={'7px'} Right={'0'}
                                        />
                                    }
                                    {
                                        this.state.LevelToggleOn === false &&
                                        <Triangle Direction={'down'} Color={'#FFFFFF'}
                                                  Width={'6px'} Height={'9px'}
                                                  Top={'10px'} Right={'9px'}
                                        />
                                    }
                                </div>
                                <div className={'EquipmentDownNavBox'} style={{display:this.state.LevelDisplay}}>
                                    {
                                        this.state.LevelArr.map((item,index) =>
                                            <div className={'EquipmentDownNav'}
                                                 key={index}
                                                 onClick={() => this.LevelDownNav(item,index)}
                                                 style={this.state.LevelName === item ? {backgroundColor:'#8DCEFD'} : {}}>
                                                {item}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'InquirePackageHeaderList'} style={{width:180,}}>
                            <div className={'InquireHeaderSelect'}>
                                <div style={{height:26,}} onClick={this.BrandDown.bind(this)}>
                                    <div className={'InquireSelectWord'}>品牌名称</div>
                                    {
                                        this.state.BrandToggleOn === true &&
                                        <Triangle Direction={'right'} Color={'#FFFFFF'}
                                                  Width={'9px'} Height={'6px'}
                                                  Top={'7px'} Right={'0'}
                                        />
                                    }
                                    {
                                        this.state.BrandToggleOn === false &&
                                        <Triangle Direction={'down'} Color={'#FFFFFF'}
                                                  Width={'6px'} Height={'9px'}
                                                  Top={'10px'} Right={'9px'}
                                        />
                                    }
                                </div>
                                <div className={'EquipmentDownNavBox'} style={{display:this.state.BrandDisplay}}>
                                    {
                                        this.state.BrandArr.map((item,index) =>
                                            <div className={'EquipmentDownNav'}
                                                 key={index}
                                                 onClick={() => this.BrandDownNav(item,index)}
                                                 style={this.state.BrandName === item.brand ? {backgroundColor:'#8DCEFD'} : {}}>
                                                {item.brand}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'InquirePackageHeaderList'} style={{width:386,}}>设备名称(型号)</div>
                    </div>
                    {
                        this.state.loading === true&&
                        <div className={'loadEffect'}>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </div>
                    }
                    {
                        this.state.loading === false&&
                        <div className={'InquirePackageContentBox'} style={{minHeight:window.innerHeight-272 > 460 ? window.innerHeight-272 :460,maxHeight:window.innerHeight-264 > 450 ? 'calc(100vh - 264px)' : '450px'}}>
                            {
                                this.state.EquipmentArr.map((item,index) =>
                                    <div className={'InquirePackageContentListBox'} key={index}>
                                        <div className={'InquirePackageContentList'} style={{width:180,}}>
                                            {item.type_remark}
                                        </div>
                                        <div className={'InquirePackageContentList'} style={{width:194,}}>{item.level}</div>
                                        <div className={'InquirePackageContentList'} style={{width:194,}}>{item.brand}</div>
                                        <div className={'InquirePackageContentList InquireListHover'} style={{width:380,}}>
                                            <div className={'InquirePaConLiWord'} style={{maxWidth:380,}}>
                                                <span className={'ClickDetails'} onClick={() => this.ActiveEquipment(item,index)}>{item.name}</span>
                                            </div>
                                            <div className={'InquirePaConLiWord'} style={{maxWidth:380,}}>
                                                <span className={'ClickDetails'} onClick={() => this.ActiveEquipment(item,index)}>（{item.model}）</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default InEquipment;
