import React, { Component } from 'react';
import './member.css';
//import {get} from "../../ajax/tools";


class GrGame extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            GrGameArr:[],
        };
    }
    componentDidMount=function() {

    };

    



    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    <div className={'GrGameHeaderBox'}>
                        <div className={'GrGameHeaderInfo'} style={{width:260,}}>赛事名称</div>
                        <div className={'GrGameHeaderInfo'} style={{width:186,}}>赛事时间</div>
                        <div className={'GrGameHeaderInfo'} style={{width:260,}}>参与项目</div>
                        <div className={'GrGameHeaderInfo'} style={{width:190,}}>参与人次</div>
                    </div>
                    <div className={'GrGameContentBox'} style={{height:window.innerHeight-234,}}>
                        {
                            this.state.GrGameArr.map((item,index) =>
                                <div className={'GrGameContentList'} key={index}>
                                    <div className={'GrGameContentInfo'} style={{width:260,}}> </div>
                                    <div className={'GrGameContentInfo'} style={{width:186,}}> </div>
                                    <div className={'GrGameContentInfo'} style={{width:260,}}> </div>
                                    <div className={'GrGameContentInfo'} style={{width:190,}}> </div>
                                </div>
                            )
                        }
                    </div>
                </div>
        );
    }
}


export default GrGame;
