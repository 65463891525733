import React, { Component } from 'react';
import './approve.css';
import Upload from "../round/Upload";
//import {get} from "../../ajax/tools";


class AllYear extends Component {

    constructor(props){
        super(props);
        this.state = {
            AllYearStep: 1,
            AllYearStepName:'填写申请表',
            AllYearIdt:'',

            FileList: [],
            Uploading: false,
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        // let history = nextProps;
    };

    componentDidMount=function() {
        let history = this.props;
        if(history.AllYearStep === 1){
            this.setState({
                AllYearStepName:'审核材料',
            });
        }else if(history.AllYearStep === 2){
            this.setState({
                AllYearStepName:'设备检测',
            });
        }else if(history.AllYearStep === 3){
            this.setState({
                AllYearStepName:'专家组审核',
            });
        }else if(history.AllYearStep === 4){
            this.setState({
                AllYearStepName:'年检完成',
            });
        }
        this.setState({
            AllYearStep:history.AllYearStep,
            AllYearIdt:history.AllYearIdt,
        });
    };




    AllYearNextBtn=()=>{
        this.setState({
            AllYearStep: 2,
        });
        this.props.AllYearNextBtn('AllYearNext');
    };



    //查看已认证的信息列表
    BackTableInfo=()=>{
        this.props.BackTableInfo(this.state.AllYearIdt);
    };



    render() {
        const props = {
            RemoveUpload:(file) => {
                const FileList = this.state.FileList;
                const index = FileList.indexOf(file);
                const NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileList:NewFileList,
                    Uploading: false,
                });
            },
            BeforeUpload:(file) => {
                console.log(file);
                const Warn = props.BeforeUpload;
                const NewFileList = [];
                if( file.type === 'application/x-zip-compressed' || file.type === '' || file.type === 'application/zip'){
                    if(file.size > 5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过5M!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '请上传zip、rar、7z格式的文件压缩包!';
                }
            },
        };
        const NewAppWorkersInfo =
            <div>
                <div className={'NewAppWorkersInfo'} style={{minWidth:528,}}>韩昱老师 ：13671194098</div>
                <div className={'NewAppWorkersInfo'} style={{minWidth:528,}}>杨旭老师 ：15603712839</div>
            </div>;
        return (
            <div className={'scrollBar'} style={{height:window.innerHeight-270,}}>
                <div className={'ApproveMainBox'}>
                    <div className={'ApproveMainStepTitle'}>{this.state.AllYearStep}.{this.state.AllYearStepName}</div>
                    <div className={'ApproveMainStepBox clearfix AllYearStep'+this.state.AllYearStep} style={{width:240,}}>
                        <div className={'NewAppStepListBox'} style={this.state.AllYearStep >= 1 ? {color:'#FFFFFF',} : {}}>1</div>
                        <div className={'NewAppStepListBox'} style={this.state.AllYearStep >= 2 ? {color:'#FFFFFF',} : {}}>2</div>
                        <div className={'NewAppStepListBox'} style={this.state.AllYearStep >= 3 ? {color:'#FFFFFF',} : {}}>3</div>
                        <div className={'NewAppStepListBox'} style={this.state.AllYearStep >= 4 ? {color:'#FFFFFF',} : {}}>4</div>
                    </div>
                    {
                        this.state.AllYearStep === 1 &&
                        <div className={'NewApproveStep1ConBox'} style={{marginTop:120,}}>
                            <div className={'NewApproveUploadBox'}>
                                <Upload {...props}/>
                                <div className={'ActiveUploadFileBtn'}
                                     style={this.state.UploadBtnState === 'Show' ? {backgroundColor:'#D2D2D2',} : {}}
                                >上传压缩文件</div>
                            </div>
                            {
                                this.state.AllYearIdt === '设备'&&
                                <div className={'UploadFileExplain'}>
                                    请将
                                    <span style={{color:'#009944',}}>模拟飞行设备认证服务申请书、模拟飞行设备生产企业及设备过检申请表</span>
                                    等材料
                                    <span style={{color:'#009944',}}>进行压缩后</span>
                                    上传压缩文件以审核
                                </div>
                            }
                            {
                                this.state.AllYearIdt === '训练中心'&&
                                <div className={'UploadFileExplain'}>
                                    请将
                                    <span style={{color:'#009944',}}>模拟飞行训练机构认证服务申请书、中国航空协会模拟飞行训练机构年审申请表、模拟飞行训练机构承诺书</span>
                                    等材料
                                    <span style={{color:'#009944',}}>进行压缩后</span>
                                    上传压缩文件以审核
                                </div>
                            }
                            <button className={'NewAppStepNext'}
                                    onClick={this.state.Uploading === true ? null : this.AllYearNextBtn.bind(this)}
                            >下一步</button>
                        </div>
                    }
                    {
                        this.state.AllYearStep === 2 &&
                        <div className={'NewApproveStepExplanationBox'}>
                            <div className={'NewAppExplanationTit'}>请您联系工作人员，以安排审核时间等相关事宜</div>
                            {NewAppWorkersInfo}
                        </div>
                    }
                    {
                        this.state.AllYearStep === 3 &&
                        <div className={'NewApproveStepExplanationBox'}>
                            <div className={'NewAppExplanationTit'}>请您联系工作人员，以安排专家组审核等相关事宜</div>
                            {NewAppWorkersInfo}
                        </div>
                    }
                    {
                        this.state.AllYearStep === 4 &&
                        <div className={'NewApproveStepEndBox'}>
                            <div className={'NewAppStepEndTitBox clearfix'}>
                                <div className={'NewAppStepEndTitIco'}> </div>
                                <div className={'NewAppStepEndTitWord'}>恭喜您，完成年检</div>
                            </div>
                            <div className={'BackTableInfo'}
                                 onClick={this.BackTableInfo.bind(this)}
                                 style={{margin:'120px auto',}}
                            >查看已年检{this.state.AllYearIdt}</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}


export default AllYear;
