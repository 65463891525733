import React, { Component } from 'react';
import './member.css';
import '../auth/auth.css';
import ThreeLevelLinkage from "../round/ThreeLevelLinkage";
import PopUp from "../round/PopUp";
import Upload from "../round/Upload";
import Second from "../round/Second";
import {get,post} from "../../ajax/tools";
import Triangle from "../round/Triangle";


class PeBase extends Component {
    constructor(props){
        super(props);
        this.state = {
            User:{},
            MoveIcoToggleOn: null, //信息公开点击同意
            MoveIcoFloat: '',//信息公开按钮默认左边,即不公开
            MoveIcoBackgroundColor: '',//信息公开背景默认白色

            PhoneMoveIcoToggleOn: null, //信息公开点击同意
            PhoneMoveIcoFloat: '',//信息公开按钮默认左边,即不公开
            PhoneMoveIcoBackgroundColor: '',//信息公开背景默认白色
            UpDataUserAddress: 'show',//通讯地址状态
            UpDataUserPhone: 'show',//手机号状态
            UpDataUserEmail: 'show',//邮箱状态
            UpDataUserSchool: 'show',//学校状态
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
            ScProvince:'',//当时地址的三级联动时
            ScCity:'',
            ScArea:'',
            ScAddress:'',//详细地址
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            VrCodeErrmsg:'',//验证码获取信息
            Email:'',//邮箱



            SchoolArr:[],//获取学校数组
            SchoolName:'请选择学校',//详细学校
            SchoolId:'',//详细学校ID
            SchoolToggleOn: true,
            SchoolDisplay: 'none',
            SchoolInput: false,

            FileList: [],
            Uploading: false,
            FileSrc:'',
            UploadBtnState:'NotShow',


            VipType: '',
        };
    }
    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let a = res.data[0];
                this.setState({
                    User:a,
                    VipType: a.is_vip,
                });
                if (res.data[0].isshow && res.data[0].isshow === 0){
                    this.setState({
                        MoveIcoToggleOn: true, //信息公开点击同意
                        MoveIcoFloat: 'left',//信息公开按钮默认左边,即不公开
                        MoveIcoBackgroundColor: '#FFFFFF',//信息公开背景默认白色
                        PhoneMoveIcoToggleOn: true, //信息公开点击同意
                        PhoneMoveIcoFloat: 'left',//信息公开按钮默认左边,即不公开
                        PhoneMoveIcoBackgroundColor: '#FFFFFF',
                    });
                }else if (res.data[0].isshow && res.data[0].isshow === 1){
                    this.setState({
                        MoveIcoToggleOn: false,
                        MoveIcoFloat: 'right',
                        MoveIcoBackgroundColor: '#EB6100',
                        PhoneMoveIcoToggleOn: true, //信息公开点击同意
                        PhoneMoveIcoFloat: 'left',//信息公开按钮默认左边,即不公开
                        PhoneMoveIcoBackgroundColor: '#FFFFFF',
                    });
                }else if (res.data[0].isshow && res.data[0].isshow === 2){
                    this.setState({
                        MoveIcoToggleOn: false,
                        MoveIcoFloat: 'right',
                        MoveIcoBackgroundColor: '#EB6100',
                        PhoneMoveIcoToggleOn: false, //信息公开点击同意
                        PhoneMoveIcoFloat: 'right',//信息公开按钮默认左边,即不公开
                        PhoneMoveIcoBackgroundColor: '#EB6100',
                    });
                }else {
                    this.setState({
                        MoveIcoToggleOn: true, //信息公开点击同意
                        MoveIcoFloat: 'left',//信息公开按钮默认左边,即不公开
                        MoveIcoBackgroundColor: '#FFFFFF',//信息公开背景默认白色
                        PhoneMoveIcoToggleOn: true, //信息公开点击同意
                        PhoneMoveIcoFloat: 'left',//信息公开按钮默认左边,即不公开
                        PhoneMoveIcoBackgroundColor: '#FFFFFF',
                    });
                }
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
            ThreeLevelLinkage: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };

    //点击信息公开移动按钮
    MoveIco(){
        let isShow = this.state.User.isshow;
        if (isShow === 0) {
            get({url:'user/update_coach_show/1'}).then((res) => {
                if(res.errno === '200'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState(prevState => ({
                                User:res.data[0],
                                MoveIcoToggleOn: !prevState.MoveIcoToggleOn,
                                MoveIcoFloat:prevState.MoveIcoToggleOn ? 'right' : 'left',
                                MoveIcoBackgroundColor:prevState.MoveIcoToggleOn ? '#EB6100' : '#FFFFFF',
                            }));
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else if (isShow === 1){
            get({url:'user/update_coach_show/0'}).then((res) => {
                if(res.errno === '200'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState(prevState => ({
                                User:res.data[0],
                                MoveIcoToggleOn: !prevState.MoveIcoToggleOn,
                                MoveIcoFloat:prevState.MoveIcoToggleOn ? 'right' : 'left',
                                MoveIcoBackgroundColor:prevState.MoveIcoToggleOn ? '#EB6100' : '#FFFFFF',
                            }));
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else if (isShow === 2){
            get({url:'user/update_coach_show/0'}).then((res) => {
                if(res.errno === '200'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState(prevState => ({
                                User:res.data[0],
                                MoveIcoToggleOn: !prevState.MoveIcoToggleOn,
                                MoveIcoFloat:prevState.MoveIcoToggleOn ? 'right' : 'left',
                                MoveIcoBackgroundColor:prevState.MoveIcoToggleOn ? '#EB6100' : '#FFFFFF',
                                PhoneMoveIcoToggleOn: !prevState.PhoneMoveIcoToggleOn,
                                PhoneMoveIcoFloat:prevState.PhoneMoveIcoToggleOn ? 'right' : 'left',
                                PhoneMoveIcoBackgroundColor:prevState.PhoneMoveIcoToggleOn ? '#EB6100' : '#FFFFFF',
                            }));
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }
    };
    //通讯公开
    PhoneMoveIco(){
        let isShow = this.state.User.isshow;
        if (isShow === 1) {
            get({url:'user/update_coach_show/2'}).then((res) => {
                if(res.errno === '200'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState(prevState => ({
                                User:res.data[0],
                                PhoneMoveIcoToggleOn: !prevState.PhoneMoveIcoToggleOn,
                                PhoneMoveIcoFloat:prevState.PhoneMoveIcoToggleOn ? 'right' : 'left',
                                PhoneMoveIcoBackgroundColor:prevState.PhoneMoveIcoToggleOn ? '#EB6100' : '#FFFFFF',
                            }));
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else if (isShow === 2){
            get({url:'user/update_coach_show/1'}).then((res) => {
                if(res.errno === '200'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState(prevState => ({
                                User:res.data[0],
                                PhoneMoveIcoToggleOn: !prevState.PhoneMoveIcoToggleOn,
                                PhoneMoveIcoFloat:prevState.PhoneMoveIcoToggleOn ? 'right' : 'left',
                                PhoneMoveIcoBackgroundColor:prevState.PhoneMoveIcoToggleOn ? '#EB6100' : '#FFFFFF',
                            }));
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else if (isShow === 0){
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请先公开证书信息！',
            });
        }
    };


    ChildLinkClick=(event)=>{
        if(event.Type === '地址'){
            if(event.Title === '省'){
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }else if(event.Title === '市'){
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }else if(event.Title === '区'){
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }
        }else if(event.Type === '学校单位'){
            if(event.Title === '省'){
                this.setState({
                    ScProvince: event.ValueP,
                    ScCity: event.ValueC,
                    ScArea: event.ValueA,
                });
            }else if(event.Title === '市'){
                this.setState({
                    ScProvince: event.ValueP,
                    ScCity: event.ValueC,
                    ScArea: event.ValueA,
                });
            }else if(event.Title === '区'){
                this.setState({
                    ScProvince: event.ValueP,
                    ScCity: event.ValueC,
                    ScArea: event.ValueA,
                });
            }
        }
    };



    //点击修改通讯地址
    UpDataUserAddress(){
        this.setState({
            UpDataUserAddress: '修改',
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
        });
    };
    //取消修改通讯地址
    CancelUserAddress(){
        this.setState({
            UpDataUserAddress: 'show',
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
        });
    };
    //详细地址
    AddressChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            Address: event.target.value,
        });
    };
    SaveUserAddress(){
        this.setState({
            PopUp:'地址修改的提示框',
            MessageTip:'是否确认保存该通讯地址？',
        });
    };
    //地址弹窗的逻辑处理
    AddressBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.Address !== ''&&this.state.AdProvince !== ''&&this.state.AdCity !== ''&&this.state.AdArea !== ''){
                post({
                    url:'user/update_address',
                    data:{
                        province: this.state.AdProvince,
                        city: this.state.AdCity,
                        county: this.state.AdArea,
                        address: this.state.Address,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        get({url:'user/basic_info'}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    User:res.data[0],
                                    PopUp:'',
                                    UpDataUserAddress:'show',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'请填写正确的通讯地址！',
                });
            }
        }
    };






    //点击修改学校
    UpDataUserSchool(){
        this.setState({
            UpDataUserSchool: '修改',
            ScProvince:'',//当时地址的三级联动时
            ScCity:'',
            ScArea:'',
            ScAddress:'',//详细地址
            SchoolName:'所属学校名称',//详细学校
            SchoolId:'',//详细学校ID
            SchoolInput: false,
        });
    };
    //取消修改学校
    CancelUserScAddress(){
        this.setState({
            UpDataUserSchool: 'show',
            ScProvince:'',//当时地址的三级联动时
            ScCity:'',
            ScArea:'',
            ScAddress:'',//详细地址
            SchoolName:'所属学校名称',//详细学校
            SchoolId:'',//详细学校ID
            SchoolInput: false,
        });
    };

    //详细地址
    ScAddressChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            ScAddress: event.target.value,
        });
    };
    SaveUserScAddress(){
        this.setState({
            PopUp:'学校修改的提示框',
            MessageTip:'是否确认保存该信息？',
        });
    };
    ScAddressBackClick=(event)=>{
        let ScAddress = this.state.ScAddress;
        let ScProvince = this.state.ScProvince;
        let ScCity = this.state.ScCity;
        let ScArea = this.state.ScArea;
        let SchoolName = this.state.SchoolName;
        let SchoolId = this.state.SchoolId;
        let SchoolInput = this.state.SchoolInput;
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if (SchoolInput === false){
                if (ScProvince!== ''&& ScCity!== ''&& ScArea!== ''&& SchoolId!== ''&& SchoolName!== '请选择学校'){
                    post({
                        url:'user/update_school',
                        data:{
                            province: ScProvince,
                            city: ScCity,
                            county: ScArea,
                            school: SchoolName,
                            schoolID: SchoolId,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        UpDataUserSchool:'show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                } else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请将信息选择完整！',
                    });
                }
            } else if (SchoolInput === true){
                if (ScProvince!== ''&& ScCity!== ''&& ScArea!== ''&& ScAddress!== ''){
                    post({
                        url:'user/update_school',
                        data:{
                            province: ScProvince,
                            city: ScCity,
                            county: ScArea,
                            school: ScAddress,
                            schoolID: '',
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        User:res.data[0],
                                        PopUp:'',
                                        UpDataUserSchool:'show',
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                } else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请将信息输入完整！',
                    });
                }
            }
        }
    };









    //点击修改手机号
    UpDataUserPhone(){
        this.setState({
            UpDataUserPhone: '修改',
            Mobile:'',//手机号
            VrCode:'',//手机验证码
        });
    };
    //取消修改手机号
    CancelUserMobile(){
        this.setState({
            UpDataUserPhone: 'show',
            Mobile:'',//手机号
            VrCode:'',//手机验证码
        });
    };
    //手机号
    MobileChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            Mobile: event.target.value,
        });
    };
    //验证码
    VrCodeChange(event){
        this.setState({
            VrCode: event.target.value,
        });
    };
    //点击获取验证码
    ReVrCodeBtnClick(){
        post({
            url:'passport/prefix_sms_check',
            data:{
                mobile:this.state.Mobile,
            },
        }).then((res) => {
            if(res.errno === '4002'){
                post({
                    url:'passport/sms_code',
                    data:{
                        mobile:this.state.Mobile,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            VrCodeErrmsg:res.errmsg,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else if(res.errno === '200'){
                this.setState({
                    PopUp:'短信验证码重复使用提示',
                    MessageTip:'您的此手机号的验证码还未过期，是否重新获取？',
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };
    SecondBackChange=(event)=>{
        if(event === 'Stop'){
            this.setState({
                VrCodeErrmsg:'',
            });
        }
    };
    //保存电话号码按钮
    SaveUserMobile(){
        this.setState({
            PopUp:'手机号修改的提示框',
            MessageTip:'是否确认保存该手机号码！',
        });
    };
    //手机号弹窗的逻辑处理
    MobileBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.Mobile !== '' && this.state.VrCode !== ''){
                post({
                    url:'passport/sms_check',
                    data:{
                        mobile:this.state.Mobile,
                        smscode:this.state.VrCode,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        get({url:'user/update_mobile/'+this.state.Mobile}).then((res) => {
                            if(res.errno === '200'){
                                get({url:'user/basic_info'}).then((res) => {
                                    if(res.errno === '200'){
                                        this.setState({
                                            User:res.data[0],
                                            PopUp:'',
                                            UpDataUserPhone:'show',
                                        });
                                    }
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'您有必要信息没有填写！',
                });
            }
        }
    };


    //点击修改邮箱
    UpDataUserEmail(){
        this.setState({
            UpDataUserEmail: '修改',
            Email:'',
        });
    };
    //取消修改邮箱
    CancelUserEmail(){
        this.setState({
            UpDataUserEmail: 'show',
            Email:'',
        });
    };
    //邮箱
    EmailChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            Email: event.target.value,
        });
    };
    //保存邮箱
    SaveUserEmail(){
        this.setState({
            PopUp:'邮箱修改的提示框',
            MessageTip:'是否确认保存该通讯地址！',
        });
    };
    //邮箱弹窗的逻辑处理
    EmailBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.Email !== ''){
                get({url:'user/update_email/'+this.state.Email}).then((res) => {
                    if(res.errno === '200'){
                        get({url:'user/basic_info'}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    User:res.data[0],
                                    PopUp:'',
                                    UpDataUserEmail:'show',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'请填写正确的邮箱！',
                });
            }
        }
    };



    //重复获取验证码
    ChildBackVrCode=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            post({
                url:'passport/sms_code',
                data:{
                    mobile:this.state.Mobile,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        PopUp:'',
                        VrCodeErrmsg:res.errmsg,
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }
    };




    //点击获取所属学校
    SchoolClick(e){
        e.nativeEvent.stopImmediatePropagation();
        if(this.state.ScProvince !== '' && this.state.ScCity !== '' && this.state.ScArea !== '' ){
            post({
                url:'reuse/school',
                data:{
                    province:this.state.ScProvince,
                    city:this.state.ScCity,
                    county:this.state.ScArea,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    const schools = [];
                    for(let i=0;i<res.data.length;i++){
                        const schoolsList = res.data[i];
                        schools.push(schoolsList)
                    }
                    this.setState(prevState => ({
                        SchoolToggleOn: !prevState.SchoolToggleOn,
                        SchoolDisplay:prevState.SchoolToggleOn ? 'block' : 'none',
                        ThreeLevelLinkage: 'none',
                        SchoolArr:schools,
                    }));
                }else{
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'网络错误，请稍后再试！',
                        ThreeLevelLinkage: 'none',
                    });
                }
            });
        }else {
            this.setState({
                SchoolToggleOn: true,
                SchoolDisplay: 'none',
            });
        }
    };
    schoolArrClick=(event,index) => {
        this.setState({
            SchoolName:event.org_name,
            SchoolId:event.user_id,
        });
    };


    //学校输入模式
    SchoolInputClick(){
        this.setState({
            SchoolInput:true,
        });
    };






    //上传照片
    UploadBtnClick(){
        const formData = new FormData();
        if(this.state.FileList.length !== 0){
            this.setState({Uploading:true,});
            this.state.FileList.forEach((file) => {
                formData.append('image', file);
               });
            post({
                url:'user/upload_image',
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                User:res.data[0],
                                FileList: [],
                                Uploading: false,
                                FileSrc:'',
                                UploadBtnState:'NotShow',
                                PopUp:'提示弹窗',
                                MessageTip:'上传保存成功！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'上传失败，请稍后重试！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'图片已损坏，请重新选择！',
            });
        }
    };



    // Vip按钮
    BaseVipBtn(){
        let VipType = this.state.VipType;
        this.props.BackVip({VipType:VipType});
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };






    render() {
        const PhonePublicIco=
            <div className={'InfoPublicIco'} style={{backgroundColor:this.state.PhoneMoveIcoBackgroundColor}}>
                <div className={'MoveIco'} style={{float:this.state.PhoneMoveIcoFloat,marginRight:-2,}} onClick={this.PhoneMoveIco.bind(this)}> </div>
            </div>;
        const InfoPublicIco =
            <div className={'InfoPublicIco'} style={{backgroundColor:this.state.MoveIcoBackgroundColor}}>
                <div className={'MoveIco'} style={{float:this.state.MoveIcoFloat,marginRight:-2,}} onClick={this.MoveIco.bind(this)}> </div>
            </div>;
        const AddressThree =
            <div className={'ReInfoInputOddBox'} style={{marginBottom:6,float:'left',}}>
                <ThreeLevelLinkage
                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                    type={'地址'}
                    Width={418}
                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                />
            </div>;
        const ScAddressThree =
            <div className={'ReInfoInputOddBox'} style={{marginBottom:6,float:'left',}}>
                <ThreeLevelLinkage
                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                    type={'学校单位'}
                    Width={418}
                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                />
            </div>;
        const Address =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,position:'relative',}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Address'}
                       value={this.state.Address}
                       onChange={this.AddressChange.bind(this)}
                       placeholder={'请输入详细地址'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
                <div className={'UpDataBtn'} onClick={this.SaveUserAddress.bind(this)}>保存</div>
                <div className={'UpDataBtn'} onClick={this.CancelUserAddress.bind(this)} style={{right:'-120px',}}>取消</div>
            </div>;
        const ScAddress =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,position:'relative',}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Address'}
                       value={this.state.ScAddress}
                       onChange={this.ScAddressChange.bind(this)}
                       placeholder={'请输入学校或单位，没有则填无'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
                <div className={'UpDataBtn'} onClick={this.SaveUserScAddress.bind(this)}>保存</div>
                <div className={'UpDataBtn'} onClick={this.CancelUserScAddress.bind(this)} style={{right:'-120px',}}>取消</div>
            </div>;
        const ScSchool =
            <div className={'InfoBoxOdd'} style={{width:426,height:0,position:'relative',}}>
                <div className={'ReInfoInputOddBox'} style={{float:'left',}}>
                    <div className={'SchoolLinkBox'} tabIndex={'2'} onClick={this.SchoolClick.bind(this)}>
                        <div className={'SchoolWordBox'}>{this.state.SchoolName}</div>
                        <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'17px'} Right={'10px'}/>
                        <div className={'SchoolLinkListBox'} style={{display:this.state.SchoolDisplay,}}>
                            <div className={'SchoolLevelLinkList'} onClick={this.SchoolInputClick.bind(this)}>没有学校（单位）信息，请手动输入</div>
                            {
                                this.state.SchoolArr.map((item,index) =>
                                    <div className={'SchoolLevelLinkList'} key={index} onClick={() => this.schoolArrClick(item,index)}>{item.org_name}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className={'UpDataBtn'} onClick={this.SaveUserScAddress.bind(this)}>保存</div>
                <div className={'UpDataBtn'} onClick={this.CancelUserScAddress.bind(this)} style={{right:'-120px',}}>取消</div>
            </div>;
        const Mobile =
            <div className={'InfoBoxOdd'} style={{width:426,marginBottom:6,position:'relative',}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Mobile'}
                       value={this.state.Mobile}
                       onChange={this.MobileChange.bind(this)}
                       placeholder={'请输入正确的手机号'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
                <div className={'UpDataBtn'} onClick={this.SaveUserMobile.bind(this)}>保存</div>
                <div className={'UpDataBtn'} onClick={this.CancelUserMobile.bind(this)} style={{right:'-120px',}}>取消</div>
            </div>;
        const VrCode =
            <div className={'InfoBoxOdd'} style={{width:426,marginBottom:20,}}>
                <div className={'ReInfoInputOddBox'} style={{float:'left',}}>
                    <input className={'ReVrCodeInput'}
                           type={'text'}
                           name={'VrCode'}
                           value={this.state.VrCode}
                           onChange={this.VrCodeChange.bind(this)}
                           placeholder={'输入验证码'}
                           autoComplete={'off'}
                    />
                    {
                        this.state.VrCodeErrmsg === '' &&
                        <button className={'ReVrCodeBtn'} onClick={this.ReVrCodeBtnClick.bind(this)}>获取验证码</button>
                    }
                    {
                        this.state.VrCodeErrmsg !== '' &&
                        <button className={'ReVrCodeBtn'} style={{backgroundColor:'#D5D5D5',}}>
                            获取成功<Second SecondBackChange={this.SecondBackChange.bind(this)}/>
                        </button>
                    }
                </div>
            </div>;
        const Email =
            <div className={'InfoBoxOdd'} style={{width:426,marginBottom:20,position:'relative',}}>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Email'}
                       value={this.state.Email}
                       onChange={this.EmailChange.bind(this)}
                       placeholder={'请输入正确的邮箱'}
                       autoComplete={'off'}
                       style={{float:'left',}}
                />
                <div className={'UpDataBtn'} onClick={this.SaveUserEmail.bind(this)}>保存</div>
                <div className={'UpDataBtn'} onClick={this.CancelUserEmail.bind(this)} style={{right:'-120px',}}>取消</div>
            </div>;
        const props = {
            UploadState:this.state.UploadBtnState,
            RemoveUpload:(file) => {
                let FileList = this.state.FileList;
                let index = FileList.indexOf(file);
                let NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileSrc:'',
                    FileList:NewFileList,
                    Uploading: false,
                    UploadBtnState:'NotShow',
                });
            },
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = props.BeforeUpload;
                let FileSrc = window.URL.createObjectURL(file);
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'){
                    if(file.size > 2*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过52MB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileSrc:FileSrc,
                            FileList:NewFileList,
                            Uploading: false,
                            UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };

        return (
                <div className={'MemberPackageBox scrollBarTwo'} style={{height:window.innerHeight-180,}}>
                    <div className={'InfoPublic'}>
                        {/*{*/}
                        {/*    this.state.VipType !== ''&&*/}
                        {/*    <div className={'BaseVipBox clearfix'}>*/}
                        {/*        {*/}
                        {/*            this.state.VipType === 0&&*/}
                        {/*            <div className={'BaseVipIco VipIcoNo'} onClick={this.BaseVipBtn.bind(this)}> </div>*/}
                        {/*        }*/}
                        {/*        {*/}
                        {/*            this.state.VipType === 1&&*/}
                        {/*            <div className={'BaseVipIco VipIcoYes'} onClick={this.BaseVipBtn.bind(this)}> </div>*/}
                        {/*        }*/}
                        {/*        {*/}
                        {/*            this.state.User.vip_expire === this.state.User.vip_create && this.state.VipType === 0&&*/}
                        {/*            <div className={'BaseVipWord'} onClick={this.BaseVipBtn.bind(this)}>*/}
                        {/*                未开通（点击开通VIP）*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*        {*/}
                        {/*            this.state.User.vip_expire !== this.state.User.vip_create && this.state.VipType === 0&&*/}
                        {/*            <div className={'BaseVipWord'} onClick={this.BaseVipBtn.bind(this)}>*/}
                        {/*                VIP会员已到期（点击开通VIP）*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*        {*/}
                        {/*            this.state.User.vip_expire !== this.state.User.vip_create && this.state.VipType === 1&&*/}
                        {/*            <div className={'BaseVipWord'} style={{color:'#CEBC17',}} onClick={this.BaseVipBtn.bind(this)}>*/}
                        {/*                已开通 到期时间为 {this.state.User.vip_expire.substring(0,10)}*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className={'InfoPublicBox'}>通讯信息公开{PhonePublicIco}</div>
                        <div className={'InfoPublicBox'} style={{marginLeft:20,}}>证书信息公开{InfoPublicIco}</div>
                    </div>
                    <div className={'PeBaseInfoBox clearfix'} style={{position:'relative',}}>
                        <div className={'UserPhotoBox'}>
                            <div className={'UserPhotoBoxMask'}>上传/修改<Upload{...props}/></div>
                            {
                                this.state.User.image === ''&& this.state.FileSrc === ''&&
                                <div className={'UserPhotoBoxMasks'}>上传证件照片完善信息</div>
                            }
                            {
                                this.state.User.image === ''&& this.state.FileSrc !== ''&&
                                <img alt={''}
                                     src={this.state.FileSrc}
                                     style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',}}
                                />
                            }
                            {
                                this.state.User.image !== ''&&
                                <img alt={''}
                                     src={this.state.FileSrc === '' ? this.state.User.image : this.state.FileSrc}
                                     style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',}}
                                />
                            }
                        </div>
                        {
                           this.state.UploadBtnState === 'Show' &&
                           <button
                               className={'MemberUploadBtn'}
                               onClick={this.state.Uploading ? null : this.UploadBtnClick.bind(this)}
                               disabled={this.state.Uploading === true}
                               style={this.state.Uploading ? {backgroundColor:'#D2D2D2',cursor:'wait',}:{}}
                           >{this.state.Uploading ? '正在上传···' : '确认上传'}</button>
                        }
                        <div className={'UserBaseInfo'}>
                            <ul className={'UserBaseInfoTitle'}>
                                <li className={'UserBaseInfoTitleList'}>姓名(ID)</li>
                                <li className={'UserBaseInfoTitleList'}>出生日期</li>
                                <li className={'UserBaseInfoTitleList'}>性别</li>
                                <li className={'UserBaseInfoTitleList'}>证件号</li>
                                <li className={'UserBaseInfoTitleList'}>模拟飞行教练员</li>
                                <li className={'UserBaseInfoTitleList'}>运动员等级</li>
                                {
                                    this.state.User.isIdCard === 0&&
                                    <li className={'UserBaseInfoTitleList'} style={{marginBottom:0,}}>其他类型证件审核</li>
                                }
                            </ul>
                            <ul className={'UserBaseInfoContent'}>
                                <li className={'UserBaseInfoContentList'}>{this.state.User.real_name}（{this.state.User.yy_num}）</li>
                                <li className={'UserBaseInfoContentList'}>{this.state.User.birth}</li>
                                <li className={'UserBaseInfoContentList'}>{this.state.User.gender}</li>
                                {
                                    this.state.User.isIdCard === 1 &&
                                    <li className={'UserBaseInfoContentList'}>
                                        {this.state.User.idcard}<span className={'Comment'}>（身份证）</span>
                                    </li>
                                }
                                {
                                    this.state.User.isIdCard !== 1 &&
                                    <li className={'UserBaseInfoContentList'} style={{letterSpacing:'0.045em',}}>
                                        {this.state.User.other_card}<span className={'Comment'}>（其他证件:{this.state.User.card_type}）</span>
                                    </li>
                                }
                                <li className={'UserBaseInfoContentList'}>{this.state.User.iscoach === 1 ? '是' : '否'}</li>
                                <li className={'UserBaseInfoContentList'}>
                                    {this.state.User.athlevel === -1 ? '无' : this.state.User.athlevel === 0 ? '启蒙级' : this.state.User.athlevel+'级'}
                                    {
                                        this.state.User.athlevel !== -1 &&
                                        <span className={'Comment'}>（仅显示最高等级）</span>
                                    }
                                </li>
                                {
                                    this.state.User.isIdCard === 0&&
                                    <li className={'UserBaseInfoContentList'} style={{marginBottom:0,lineHeight:'26px',fontSize:19,}}>
                                        {
                                            this.state.User.isvalid !== 1 &&
                                            <span style={{color:'#ff0000',}}>审核暂未通过</span>
                                        }
                                        {
                                            this.state.User.isvalid === 1 &&
                                            <span style={{color:'#009944',}}>审核通过</span>
                                        }
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={'PeBaseInfoSolid'} style={{marginTop:30,marginBottom:3,}}> </div>
                    <div className={'PeBaseInfoSolid'} style={{marginBottom:40,}}> </div>
                    <div className={'OtherPeBaseInfoBox clearfix'}>
                        <ul className={'UserBaseInfoTitle'}>
                            <li className={'UserBaseInfoTitleList'} style={this.state.UpDataUserPhone === 'show' ? {marginBottom:32,}:{marginBottom:80,}}>手机号</li>
                            <li className={'UserBaseInfoTitleList'} style={{marginBottom:32,}}>邮箱</li>
                            <li className={'UserBaseInfoTitleList'} style={this.state.UpDataUserAddress === 'show' ? {marginBottom:32,}:{marginBottom:80,}}>通信地址</li>
                            <li className={'UserBaseInfoTitleList'} style={{marginBottom:32,}}>学校（单位）</li>
                        </ul>
                        <ul className={'UserBaseInfoContent'}>
                            {
                                this.state.UpDataUserPhone === 'show'&&
                                <li className={'UserBaseInfoContentList'} style={{marginBottom:32,}}>
                                    <div className={'UpDataBtn'} onClick={this.UpDataUserPhone.bind(this)}>修改</div>
                                    {this.state.User.mobile}
                                </li>
                            }
                            {
                                this.state.UpDataUserPhone === '修改'&&
                                <div>
                                    {Mobile}
                                    {VrCode}
                                </div>
                            }
                            {
                                this.state.UpDataUserEmail === 'show'&&
                                <li className={'UserBaseInfoContentList'} style={{marginBottom:32,}}>
                                    <div className={'UpDataBtn'} onClick={this.UpDataUserEmail.bind(this)}>修改</div>
                                    {this.state.User.email}
                                </li>
                            }
                            {
                                this.state.UpDataUserEmail === '修改'&&
                                <div>
                                    {Email}
                                </div>
                            }
                            {
                                this.state.UpDataUserAddress === 'show'&&
                                <li className={'UserBaseInfoContentList'} style={{marginBottom:32,}}>
                                    <div className={'UpDataBtn'} onClick={this.UpDataUserAddress.bind(this)}>修改</div>
                                    {this.state.User.province === this.state.User.city ?
                                        this.state.User.city+this.state.User.county+this.state.User.address :
                                        this.state.User.province+this.state.User.city+this.state.User.county+this.state.User.address
                                    }
                                </li>
                            }
                            {
                                this.state.UpDataUserAddress === '修改'&&
                                <div className={'clearfix'} style={{marginBottom:20,}}>
                                    {AddressThree}
                                    {Address}
                                </div>
                            }
                            {
                                this.state.UpDataUserSchool === 'show'&&
                                <li className={'UserBaseInfoContentList'} style={{marginBottom:32,}}>
                                    <div className={'UpDataBtn'} onClick={this.UpDataUserSchool.bind(this)}>修改</div>
                                    {this.state.User.school === '' ? '自由职业' : this.state.User.school}
                                </li>
                            }
                            {
                                this.state.UpDataUserSchool === '修改'&& this.state.SchoolInput === false &&
                                <div className={'clearfix'} style={{marginBottom:20,}}>
                                    {ScAddressThree}
                                    {ScSchool}
                                </div>
                            }
                            {
                                this.state.UpDataUserSchool === '修改'&& this.state.SchoolInput === true &&
                                <div className={'clearfix'} style={{marginBottom:20,}}>
                                    {ScAddressThree}
                                    {ScAddress}
                                </div>
                            }
                        </ul>
                    </div>
                    {
                        this.state.PopUp === '手机号修改的提示框' &&
                        <PopUp
                            ChildBackClick={this.MobileBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '邮箱修改的提示框' &&
                        <PopUp
                            ChildBackClick={this.EmailBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '地址修改的提示框' &&
                        <PopUp
                            ChildBackClick={this.AddressBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '学校修改的提示框' &&
                        <PopUp
                            ChildBackClick={this.ScAddressBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '短信验证码重复使用提示' &&
                        <PopUp
                            ChildBackClick={this.ChildBackVrCode.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default PeBase;
