import React, { Component } from 'react';
import BoundCoach from "../exam/BoundCoach";
import Triangle from "../round/Triangle";
import PopUp from "../round/PopUp";
import Upload from "../round/Upload";
import {get, post} from "../../ajax/tools";
import './game.css';

class GamePersonal extends Component {

    constructor(props){
        super(props);
        this.state = {
            MatchId:'',//比赛Id
            MatchName:'',//比赛Id

            IdNum:'',//用户id号
            BoundCoachId:'',//绑定教练员的ID
            BoundCoachIdNum:'',//绑定教练员的ID号


            CityArr: [],//地区
            CityIsToggleOn: true,
            CityBtn: 'false',
            CityShow:'',
            GroupArr: [],//组别
            GroupIsToggleOn: true,
            GroupBtn: 'false',
            GroupShow:'',
            GroupId:'',
            ProjectArr: [],//项目总数据
            ProjectBrr: [],//已经选择的项目
            ProjectIsToggleOn: true,
            ProjectBtn: 'false',
            ProjectShow:'',
            ProjectBrrNum:0,

            GPInfoState:'',//页面的步骤，报名选项还是展示信息

            BoundCoach:'1',

            IdCardFileList: [],//身份证文件
            IdCardFileName:'',//身份证文件名
            IdCard:false,
            SafeFileList: [],//保险文件
            SafeFileName:'',//保险文件名
            Safe:false,
            StuCardFileList: [],//学籍文件
            StuCardFileName:'',//学籍文件名
            StuCard:false,
            InSchoolFileList: [],//在校证明文件
            InSchoolFileName:'',//在校证明文件名
            InSchool:false,
            KnowFileList: [],//告知函文件
            KnowFileName:'',//告知函文件名
            Know:false,
            ProjectMaxNum: null,//最多兼项数目
            GPInfoStateSave: '报名保存',
            FileArr:[],

            UpFile:true,

            teiId:'',
            PerRemark:'',

            PersonalInfo:{},

            other_prove:'',
            other_prove_url:'',

            adminOtherProve:'',
            adminOtherProveRemarks:'',
            OtherName:'',
            OtherFileList:[],

            indexOtherProve:'',
            indexOtherProveUrl:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        let MatchId = history.MatchId;
        this.setState({
            MatchId:MatchId,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        let MatchId = history.MatchId;
        this.setState({
            MatchId:MatchId,
        });
        get({url:'match/get_groups/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GroupArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });

        get({url:'match/get_about_file/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let data = res.data;
                for(let i=0;i<data.length;i++){
                    this.setState({FileArr:data,});
                    const Type = data[i].type;
                    switch(Type) {
                        case 'IdCardFileName':
                            this.setState({
                                IdCard: true,
                                UpFile: false,
                            });
                            break;
                        case 'SafeFileName':
                            this.setState({
                                Safe: true,
                                UpFile: false,
                            });
                            break;
                        case 'StuCardFileName':
                            this.setState({
                                StuCard: true,
                                UpFile: false,
                            });
                            break;
                        case 'InSchoolFileName':
                            this.setState({
                                InSchool: true,
                                UpFile: false,
                            });
                            break;
                        case 'KnowFileName':
                            this.setState({
                                Know: true,
                                UpFile: false,
                            });
                            break;
                        default:
                            this.setState({
                                UpFile:true,
                            });
                    }
                }
            }else {
                this.setState({
                    UpFile:true,
                    FileArr:[],
                });
            }
        });

        get({url:'match/get_match_details/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    MatchName:res.data[0].match_name,
                    PerRemark:res.data[0].per_remark,
                    adminOtherProve:res.data[0].other_prove,
                    adminOtherProveRemarks:res.data[0].other_prove_remarks,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });

        post({
            url:'match/query_match_enroll_per ',
            data:{
                match_id:MatchId,
            },
        }).then((res) => {
            if(res.errno === '200'){
                let data = res.data[0];
                this.setState({
                    BoundCoachIdNum:data.coach_yy_num,
                    BoundCoachId:data.coach_id,
                    GroupShow:data.groups,
                    GroupId:data.group_id,
                    ProjectBrr:data.item,
                    FileArr:data.file,
                    GPInfoState:'save',
                    teiId:data.id,
                    indexOtherProve:data.other_prove,
                    indexOtherProveUrl:data.other_prove_url,
                    PersonalInfo:data,
                });
                for(let i=0;i<data.file.length;i++){
                    const fileType = data.file[i].type;
                    switch(fileType) {
                        case 'IdCardFileName':
                            this.setState({
                                IdCardFileName: data.file[i].name,
                            });
                            break;
                        case 'SafeFileName':
                            this.setState({
                                SafeFileName: data.file[i].name,
                            });
                            break;
                        case 'StuCardFileName':
                            this.setState({
                                StuCardFileName: data.file[i].name,
                            });
                            break;
                        case 'InSchoolFileName':
                            this.setState({
                                InSchoolFileName: data.file[i].name,
                            });
                            break;
                        case 'KnowFileName':
                            this.setState({
                                KnowFileName: data.file[i].name,
                            });
                            break;
                        default:
                            this.setState({});
                    }
                }
            }else {
                this.setState({
                    GPInfoState:'select',
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
            CityIsToggleOn: true,
            CityBtn:"false",
            GroupIsToggleOn: true,
            GroupBtn: 'false',
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //下拉菜单按钮
    // ClickCityDb(e){
    //     e.nativeEvent.stopImmediatePropagation();
    //     this.setState(prevState => ({
    //         CityIsToggleOn: !prevState.CityIsToggleOn,
    //         CityBtn:prevState.CityIsToggleOn ? 'true' : 'false',
    //         GroupIsToggleOn: true,
    //         GroupBtn:"false",
    //         ProjectIsToggleOn: true,
    //         ProjectBtn:"false",
    //     }));
    // };
    ClickGroupDb(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            GroupIsToggleOn: !prevState.GroupIsToggleOn,
            GroupBtn:prevState.GroupIsToggleOn ? 'true' : 'false',
            CityIsToggleOn: true,
            CityBtn:"false",
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
        }));
    };
    ClickProjectAdd(e){
        e.nativeEvent.stopImmediatePropagation();
        let GroupShow = this.state.GroupShow;
        if(GroupShow !== ''){
            this.setState(prevState => ({
                ProjectIsToggleOn: !prevState.ProjectIsToggleOn,
                ProjectBtn:prevState.ProjectIsToggleOn ? 'true' : 'false',
                CityIsToggleOn: true,
                CityBtn:"false",
                GroupIsToggleOn: true,
                GroupBtn:"false",
            }));
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请先选择组别！',
            });
        }
    };
    //点击选择下拉菜单的内容
    // ClickCityArr=(item,index) => {
    //     this.setState({
    //         CityIsToggleOn: true,
    //         CityBtn:"false",
    //         CityShow:item.school,
    //     });
    // };
    ClickGroupArr=(item,index) => {
        let MatchId = this.state.MatchId;
        this.setState({
            GroupIndex: index,
            GroupIsToggleOn: true,
            GroupBtn:"false",
            GroupShow:item.name,
            GroupId:item.group_id,
        });
        post({
            url:'match/get_item',
            data:{
                match_id:MatchId,
                mag_id:item.mag_id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    ProjectArr:res.data,
                    ProjectMaxNum:res.allow_item_num,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };
    ClickProjectArr=(item,index) => {
        let ProjectBrr=this.state.ProjectBrr;
        let ProjectMaxNum = this.state.ProjectMaxNum;
        let ProjectTest = [];
        let ProjectTrr = [];
        for(let i=0;i<ProjectBrr.length;i++){
            let obList = ProjectBrr[i].name;
            let ProjectType = ProjectBrr[i].is_concurrent;
            if (ProjectType === 1) {
                ProjectTrr.push(ProjectBrr[i])
            }
            ProjectTest.push(obList)
        }
        if(this.state.ProjectBrrNum === 0){
            const Projects = {};
            Projects['name'] = item.name;
            Projects['item_id'] = item.item_id;
            Projects['mag_id'] = item.mag_id;
            Projects['mai_id'] = item.id;
            Projects['Num'] = (this.state.ProjectBrrNum+1);
            Projects['is_concurrent'] = item.is_concurrent;
            ProjectBrr.push(Projects);
        }else {
            if (ProjectTrr.length < ProjectMaxNum){
                if(ProjectTest.includes(item.name)){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'您已经选择该项目，请不要重复选择！',
                    });
                }else {
                    const Projects = {};
                    Projects['name'] = item.name;
                    Projects['item_id'] = item.item_id;
                    Projects['mag_id'] = item.mag_id;
                    Projects['mai_id'] = item.id;
                    Projects['Num'] = (this.state.ProjectBrrNum+1);
                    Projects['is_concurrent'] = item.is_concurrent;
                    ProjectBrr.push(Projects);
                }
            }else {
                if(item.is_concurrent !== 1){
                    if(ProjectTest.includes(item.name)){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'您已经选择该项目，请不要重复选择！',
                        });
                    }else {
                        const Projects = {};
                        Projects['name'] = item.name;
                        Projects['item_id'] = item.item_id;
                        Projects['mag_id'] = item.mag_id;
                        Projects['mai_id'] = item.id;
                        Projects['Num'] = (this.state.ProjectBrrNum+1);
                        Projects['is_concurrent'] = item.is_concurrent;
                        ProjectBrr.push(Projects);
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'计入兼项科目数量已达到最多兼项限制',
                    });
                }
            }
        }
        this.setState({
            ProjectIndex: index,
            ProjectIsToggleOn: true,
            ProjectBtn:"false",
            ProjectShow:item.name,
            ProjectBrr:ProjectBrr,
            ProjectBrrNum:ProjectBrr.length,
        });
    };
    //删除已选择的项目
    ClickProjectDelete=(e,index) => {
        const dataSource = [...this.state.ProjectBrr];
        dataSource.splice(index, 1);
        if(dataSource.length !== 0){
            if(index < dataSource.length){
                dataSource[index].Num = (index+1);
            }
            if(index+1 < dataSource.length){
                dataSource[index+1].Num = (index+2);
            }
        }
        this.setState({
            ProjectBrr:dataSource,
            ProjectBrrNum:dataSource.length,
        });
    };







    //返回选择赛事按钮
    GameInfoBackBtn=()=>{
        let ClickInfo = {State:'show',};
        this.props.GamePersonal(ClickInfo);
    };



    //绑定教练员的返回逻辑处理
    BoundCoachBack(event){
        this.setState({
            BoundCoachId:event.CoachId,
            BoundCoachIdNum:event.CoachIdNum,
        });
    };













    //修改信息
    GamePersonalInfoBtn(){
        this.setState({
            GPInfoState:'select',
            GPInfoStateSave:'修改保存',
            GroupShow:'',
            GroupId:'',
            ProjectBrr:[],
            IdCardFileName:'',
            SafeFileName:'',
            StuCardFileName:'',
            InSchoolFileName:'',
            KnowFileName:'',
            IdNum:'',//用户id号
            BoundCoachId:'',//绑定教练员的ID
            BoundCoachIdNum:'',//绑定教练员的ID号
            BoundCoach:this.state.BoundCoach === '1' ? '2' : '1',
            other_prove:'',
            other_prove_url:'',
            OtherName:'',
            OtherFileList:[],
        });
    };


    //取消报名
    GamePersonalDelBtn(){
        let teiId = this.state.teiId;
        let MatchId = this.state.MatchId;
        post({
            url:'match/del_match_enroll_team',
            data:{
                data:[{id:teiId}],
            },
        }).then((res) => {
            if (res.errno === '200') {
                post({
                    url:'match/query_match_enroll_per ',
                    data:{
                        match_id:MatchId,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        let data = res.data[0];
                        this.setState({
                            BoundCoachIdNum:data.coach_yy_num,
                            BoundCoachId:data.coach_id,
                            GroupShow:data.groups,
                            GroupId:data.group_id,
                            ProjectBrr:data.item,
                            FileArr:data.file,
                            GPInfoState:'save',
                            teiId:data.id,
                            PersonalInfo:data,
                            indexOtherProve:data.other_prove,
                            indexOtherProveUrl:data.other_prove_url,
                        });
                        for(let i=0;i<data.file.length;i++){
                            const fileType = data.file[i].type;
                            switch(fileType) {
                                case 'IdCardFileName':
                                    this.setState({
                                        IdCardFileName: data.file[i].name,
                                    });
                                    break;
                                case 'SafeFileName':
                                    this.setState({
                                        SafeFileName: data.file[i].name,
                                    });
                                    break;
                                case 'StuCardFileName':
                                    this.setState({
                                        StuCardFileName: data.file[i].name,
                                    });
                                    break;
                                case 'InSchoolFileName':
                                    this.setState({
                                        InSchoolFileName: data.file[i].name,
                                    });
                                    break;
                                case 'KnowFileName':
                                    this.setState({
                                        KnowFileName: data.file[i].name,
                                    });
                                    break;
                                default:
                                    this.setState({});
                            }
                        }
                    }else {
                        this.setState({
                            GPInfoState:'select',
                            GroupShow:'',
                            GroupId:'',
                            ProjectBrr:[],
                            IdCardFileName:'',
                            SafeFileName:'',
                            StuCardFileName:'',
                            InSchoolFileName:'',
                            KnowFileName:'',
                            IdNum:'',//用户id号
                            BoundCoachId:'',//绑定教练员的ID
                            BoundCoachIdNum:'',//绑定教练员的ID号
                            BoundCoach:this.state.BoundCoach === '1' ? '2' : '1',
                            other_prove:'',
                            other_prove_url:'',
                            OtherName:'',
                            OtherFileList:[],

                        });
                    }
                });
            }else{
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };




    //保存信息
    GamePersonalBtn(){
        let MatchId = this.state.MatchId;
        let BoundCoachId = this.state.BoundCoachId;
        let CityShow = this.state.CityShow;
        let GroupShow = this.state.GroupShow;
        let GroupId = this.state.GroupId;
        let ProjectBrr = this.state.ProjectBrr;
        let ProjectMaxNum = this.state.ProjectMaxNum;
        let BoundCoachIdNum = this.state.BoundCoachIdNum;
        // let IdCardFileList = this.state.IdCardFileList;
        // let SafeFileList = this.state.SafeFileList;
        // let StuCardFileList = this.state.StuCardFileList;
        // let InSchoolFileList = this.state.InSchoolFileList;
        // let KnowFileList = this.state.KnowFileList;
        let url = this.state.other_prove_url;
        let formData = new FormData();
        this.state.IdCardFileList.forEach((file) => {
            formData.append('IdCardFileName', file);
        });
        this.state.SafeFileList.forEach((file) => {
            formData.append('SafeFileName', file);
        });
        this.state.StuCardFileList.forEach((file) => {
            formData.append('StuCardFileName', file);
        });
        this.state.InSchoolFileList.forEach((file) => {
            formData.append('InSchoolFileName', file);
        });
        this.state.KnowFileList.forEach((file) => {
            formData.append('KnowFileName', file);
        });
        formData.append('province', CityShow);
        formData.append('groups', GroupShow);
        formData.append('group_id', GroupId);
        formData.append('coach_id', BoundCoachId);
        formData.append('item', JSON.stringify(ProjectBrr));
        formData.append('identity', '1');
        formData.append('match_id', MatchId);
        formData.append('allow_item_num', ProjectMaxNum);
        formData.append('other_prove_url', url);
        formData.append('other_prove', url === '' ? 0 : 1);
        if (BoundCoachId !== ''&&
            GroupShow !== ''&&
            GroupId !== ''&&
            BoundCoachIdNum !== ''&&
            ProjectBrr.length !== 0
            // IdCardFileList.length !== 0&&
            // SafeFileList.length !== 0&&
            // StuCardFileList.length !== 0&&
            // InSchoolFileList.length !== 0&&
            // KnowFileList.length !== 0
        ){
            post({
                url:'match/match_enroll_individual',
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                    post({
                        url:'match/query_match_enroll_per ',
                        data:{
                            match_id:MatchId,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            let data = res.data[0];
                            this.setState({
                                GPInfoState:'save',
                                BoundCoachIdNum:data.coach_yy_num,
                                BoundCoachId:data.coach_id,
                                GroupShow:data.groups,
                                GroupId:data.group_id,
                                ProjectBrr:data.item,
                                FileArr:data.file,
                                teiId:data.id,
                                indexOtherProve:data.other_prove,
                                indexOtherProveUrl:data.other_prove_url,
                            });
                            for(let i=0;i<data.file.length;i++){
                                const fileType = data.file[i].type;
                                switch(fileType) {
                                    case 'IdCardFileName':
                                        this.setState({
                                            IdCardFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'SafeFileName':
                                        this.setState({
                                            SafeFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'StuCardFileName':
                                        this.setState({
                                            StuCardFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'InSchoolFileName':
                                        this.setState({
                                            InSchoolFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'KnowFileName':
                                        this.setState({
                                            KnowFileName: data.file[i].name,
                                        });
                                        break;
                                    default:
                                        this.setState({});
                                }
                            }
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'未知错误请刷新重试！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您还有必要信息未填写完全！',
            });
        }
    };




    //修改保存
    GamePersonalUpData(){
        let MatchId = this.state.MatchId;
        let BoundCoachId = this.state.BoundCoachId;
        let CityShow = this.state.CityShow;
        let GroupShow = this.state.GroupShow;
        let GroupId = this.state.GroupId;
        let ProjectBrr = this.state.ProjectBrr;
        let ProjectMaxNum = this.state.ProjectMaxNum;
        let BoundCoachIdNum = this.state.BoundCoachIdNum;
        // let IdCardFileList = this.state.IdCardFileList;
        // let SafeFileList = this.state.SafeFileList;
        // let StuCardFileList = this.state.StuCardFileList;
        // let InSchoolFileList = this.state.InSchoolFileList;
        // let KnowFileList = this.state.KnowFileList;
        let url = this.state.other_prove_url;
        let FileArr = this.state.FileArr;
        let formData = new FormData();
        this.state.IdCardFileList.forEach((file) => {
            formData.append('IdCardFileName', file);
        });
        this.state.SafeFileList.forEach((file) => {
            formData.append('SafeFileName', file);
        });
        this.state.StuCardFileList.forEach((file) => {
            formData.append('StuCardFileName', file);
        });
        this.state.InSchoolFileList.forEach((file) => {
            formData.append('InSchoolFileName', file);
        });
        this.state.KnowFileList.forEach((file) => {
            formData.append('KnowFileName', file);
        });
        formData.append('province', CityShow);
        formData.append('groups', GroupShow);
        formData.append('group_id', GroupId);
        formData.append('coach_id', BoundCoachId);
        formData.append('item', JSON.stringify(ProjectBrr));
        formData.append('identity', '1');
        formData.append('match_id', MatchId);
        formData.append('allow_item_num', ProjectMaxNum);
        formData.append('file', JSON.stringify(FileArr));
        formData.append('other_prove_url', url);
        formData.append('other_prove', url === '' ? 0 : 1);
        if (BoundCoachId !== ''&&
            GroupShow !== ''&&
            BoundCoachIdNum !== ''&&
            ProjectBrr.length !== 0
            // IdCardFileList.length !== 0&&
            // SafeFileList.length !== 0&&
            // StuCardFileList.length !== 0&&
            // InSchoolFileList.length !== 0&&
            // KnowFileList.length !== 0
        ){
            post({
                url:'match/up_match_enroll_individual',
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                    post({
                        url:'match/query_match_enroll_per',
                        data:{
                            match_id:MatchId,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            let data = res.data[0];
                            this.setState({
                                GPInfoState:'save',
                                BoundCoachIdNum:data.coach_yy_num,
                                BoundCoachId:data.coach_id,
                                GroupShow:data.groups,
                                GroupId:data.group_id,
                                ProjectBrr:data.item,
                                FileArr:data.file,
                                teiId:data.id,
                                indexOtherProve:data.other_prove,
                                indexOtherProveUrl:data.other_prove_url,
                            });
                            for(let i=0;i<data.file.length;i++){
                                const fileType = data.file[i].type;
                                switch(fileType) {
                                    case 'IdCardFileName':
                                        this.setState({
                                            IdCardFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'SafeFileName':
                                        this.setState({
                                            SafeFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'StuCardFileName':
                                        this.setState({
                                            StuCardFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'InSchoolFileName':
                                        this.setState({
                                            InSchoolFileName: data.file[i].name,
                                        });
                                        break;
                                    case 'KnowFileName':
                                        this.setState({
                                            KnowFileName: data.file[i].name,
                                        });
                                        break;
                                    default:
                                        this.setState({});
                                }
                            }
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'未知错误请刷新重试！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您还有必要信息未填写完全！',
            });
        }
    };





    query(){
        let teiId = this.state.teiId;
        post({
            url:'match/net_match_write_show',
            data:{
                tei_id: teiId,
                type: 2,
            },
        }).then((res) => {
            if (res.errno === '200') {
                window.open('/internetAsk/'+teiId);
            }else{
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };









    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        const propsIdCard = {
            UploadState:'NotShow',
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsIdCard.BeforeUpload;
                let FileName = file.name;
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            IdCardFileName:FileName,
                            IdCardFileList:NewFileList,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };
        const propsSafe = {
            UploadState:'NotShow',
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsSafe.BeforeUpload;
                let FileName = file.name;
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            SafeFileName:FileName,
                            SafeFileList:NewFileList,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };
        const propsStuCard = {
            UploadState:'NotShow',
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsStuCard.BeforeUpload;
                let FileName = file.name;
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            StuCardFileName:FileName,
                            StuCardFileList:NewFileList,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };
        const propsInSchool = {
            UploadState:'NotShow',
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsInSchool.BeforeUpload;
                let FileName = file.name;
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            InSchoolFileName:FileName,
                            InSchoolFileList:NewFileList,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };
        const propsKnow = {
            UploadState:'NotShow',
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsKnow.BeforeUpload;
                let FileName = file.name;
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            KnowFileName:FileName,
                            KnowFileList:NewFileList,
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };


        const propsOther = {
            UploadState:'NotShow',
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsOther.BeforeUpload;
                let FileName = file.name;
                let NewFileList = [];
                console.log(file.type)
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        let formData = new FormData();
                        NewFileList.forEach((file) => {
                            formData.append('file', file);
                        });
                        post({
                            url:'a_news/rich_text_upload_image',
                            data:formData,
                        }).then((res) => {
                            if (res.errno === '200') {
                                this.setState({
                                    other_prove_url:res.url,
                                    OtherName:FileName,
                                    OtherFileList:NewFileList,
                                });
                            }else{
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };


        return (
            <div className={'GameMainBox'}>
                <div className={'GameInfoTitleBox clearfix'}>
                    <div className={'GameInfoTitle'}>
                        <span onClick={this.GameInfoBackBtn.bind(this)}>选择赛事</span>
                        >>{this.state.MatchName}
                    </div>
                    <div className={'DetailsBackBox clearfix'} style={{height:20,marginTop:4,}} onClick={this.GameInfoBackBtn.bind(this)}>
                        <div className={'DetailsBackIco'} style={{width:20,height:20,}}> </div>
                        <div className={'DetailsBackWord'} style={{height:20,lineHeight:'20px',fontSize:16,marginLeft:6,}}>返回</div>
                    </div>
                </div>
                <div>
                    {
                        this.state.PerRemark !== ''&&
                        <div className={'GPClickBox clearfix'} style={{minHeight:30,margin:'10px auto',}}>
                            <div className={'PerRemarkWord'}>
                                *备注：{this.state.PerRemark}
                            </div>
                        </div>
                    }
                    <div className={'GPClickBox clearfix'}>
                        {
                            this.state.GPInfoState === 'select' &&
                            <ul className={'GPClickUlBox clearfix'}>
                                {/*
                                <li className={'GPClickLiBox'}>
                                    <div className={'GPClickInfoTit'}>*参赛地区</div>
                                    <div className={'GPClickInfoCon'}>
                                        <div className={'GPDownBtn'} onClick={this.ClickCityDb.bind(this)}>
                                            <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'7px'} Height={'10px'} Top={'10px'} Right={'23px'}/>
                                        </div>
                                        <div className={'GPSelectShow'}>
                                            {this.state.CityShow === ''? '请选择参赛地区' : this.state.CityShow}
                                        </div>
                                        {
                                            this.state.CityBtn === 'true' &&
                                            <div className={'GPSelect'}>
                                                {
                                                    this.state.CityArr.map((item,index)=>
                                                        <div className={'GPOption'}
                                                             key={index}
                                                             onClick={() => this.ClickCityArr(item,index)}>
                                                            {item.school}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
                            */}
                                <li className={'GPClickLiBox'}>
                                    <div className={'GPClickInfoTit'}>*参赛组别</div>
                                    <div className={'GPClickInfoCon'}>
                                        <div className={'GPDownBtn'} onClick={this.ClickGroupDb.bind(this)}>
                                            <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'7px'} Height={'10px'} Top={'10px'} Right={'23px'}/>
                                        </div>
                                        <div className={'GPSelectShow'}>
                                            {this.state.GroupShow === ''? '请选择参赛组别' : this.state.GroupShow}
                                        </div>
                                        {
                                            this.state.GroupBtn === 'true' &&
                                            <div className={'GPSelect'}>
                                                {
                                                    this.state.GroupArr.map((item,index)=>
                                                        <div className={'GPOption'}
                                                             key={index}
                                                             onClick={() => this.ClickGroupArr(item,index)}>
                                                            {item.name}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className={'GPClickLiBox clearfix'}>
                                    <div className={'GPClickInfoTit'}>*参赛项目</div>
                                    <div className={'GPClickInfoCon'}>
                                        {
                                            this.state.ProjectBrrNum !== 0 &&
                                            <div>
                                                {
                                                    this.state.ProjectBrr.map((item,index) =>
                                                        <div className={'GPProjectSelectShow'} key={index}>
                                                            <div className={'GPProjectSelectWord'} title={item.name}>{item.name}</div>
                                                            <div className={'GPProjectSelectDelete'}
                                                                 onClick={() => this.ClickProjectDelete(item,index)}> </div>
                                                            <span className={'GPManyNum'}>{item.Num}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                        <div className={'GPProjectAdd'}>
                                            <div className={'GPProjectAddBtn'} onClick={this.ClickProjectAdd.bind(this)}>
                                                <span>添加</span>
                                                <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'7px'} Height={'10px'} Top={'10px'} Right={'12px'}/>
                                            </div>
                                            {
                                                this.state.ProjectBtn === 'true' &&
                                                <div className={'GPProjectSelect'}>
                                                    {
                                                        this.state.ProjectArr.map((item,index)=>
                                                            <div className={'GPProjectOption'}
                                                                 key={index}
                                                                 onClick={() => this.ClickProjectArr(item,index)}>
                                                                {item.name}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        }
                        {
                            this.state.GPInfoState === 'save' &&
                            <ul className={'GPClickUlBox clearfix'}>
                                {/*
                                <li className={'GPClickLiBox'}>
                                    <div className={'GPClickInfoTit'}>*参赛地区</div>
                                    <div className={'GPClickInfoConShowSave'}>上海</div>
                                </li>
                            */}
                                <li className={'GPClickLiBox'}>
                                    <div className={'GPClickInfoTit'}>*参赛组别</div>
                                    <div className={'GPClickInfoConShowSave'}>{this.state.GroupShow}</div>
                                </li>
                                <li className={'GPClickLiBox clearfix'}>
                                    <div className={'GPClickInfoTit'}>*参赛项目</div>
                                    {
                                        this.state.ProjectBrr.map((item,index) =>
                                            <div className={'GPClickInfoConShowSave'} key={index}>{item.item_name}</div>
                                        )
                                    }
                                </li>
                                {/*<div className={'GamePersonalInfoBtn'} onClick={this.GamePersonalInfoBtn.bind(this)}>更改信息</div>*/}
                            </ul>
                        }
                        <div className={'perBoundCoachBox'}>
                            {
                                this.state.BoundCoach === '1'&&
                                <BoundCoach
                                    BoundCoachBack={this.BoundCoachBack.bind(this)}
                                    IdNum={this.state.IdNum}
                                    BoundCoachIdNum={this.state.BoundCoachIdNum}
                                />
                            }
                            {
                                this.state.BoundCoach === '2'&&
                                <BoundCoach
                                    BoundCoachBack={this.BoundCoachBack.bind(this)}
                                    IdNum={this.state.IdNum}
                                    BoundCoachIdNum={this.state.BoundCoachIdNum}
                                />
                            }
                            <div className={'perBoundCoachWord'}>
                                *自学无教练员者请输入‘000000’绑定机器人完成报名！
                            </div>
                        </div>
                    </div>
                    {
                        this.state.UpFile !== true&& this.state.FileArr.length !== 0&&
                        <div className={'GPListTitle'} style={{marginBottom:20,}}>*上传文件</div>
                    }
                    {
                        this.state.FileArr.length !== 0&&
                        <div className={'GPUploadFileBox'}>
                            {
                                this.state.IdCard === true&&
                                <div className={'GPUploadFileList clearfix'}>
                                    <div className={'GPUploadFileBtn'}
                                         style={this.state.IdCardFileName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',} : {}}
                                    >
                                        身份证
                                        {
                                            this.state.GPInfoState === 'select' &&
                                            <Upload{...propsIdCard}/>
                                        }
                                    </div>
                                    <div className={'GPUploadFileName'}>{this.state.IdCardFileName}</div>
                                    {
                                        this.state.IdCardFileName !== '' &&
                                        <div className={'GPUploadFileIco'}> </div>
                                    }
                                </div>
                            }
                            {
                                this.state.Safe === true&&
                                <div className={'GPUploadFileList clearfix'}>
                                    <div className={'GPUploadFileBtn'}
                                         style={this.state.SafeFileName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',} : {}}
                                    >
                                        保险
                                        {
                                            this.state.GPInfoState === 'select' &&
                                            <Upload{...propsSafe}/>
                                        }
                                    </div>
                                    <div className={'GPUploadFileName'}>{this.state.SafeFileName}</div>
                                    {
                                        this.state.SafeFileName !== '' &&
                                        <div className={'GPUploadFileIco'}> </div>
                                    }
                                </div>
                            }
                            {
                                this.state.StuCard === true&&
                                <div className={'GPUploadFileList clearfix'}>
                                    <div className={'GPUploadFileBtn'}
                                         style={this.state.StuCardFileName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',} : {}}
                                    >
                                        学籍卡
                                        {
                                            this.state.GPInfoState === 'select' &&
                                            <Upload{...propsStuCard}/>
                                        }
                                    </div>
                                    <div className={'GPUploadFileName'}>{this.state.StuCardFileName}</div>
                                    {
                                        this.state.StuCardFileName !== '' &&
                                        <div className={'GPUploadFileIco'}> </div>
                                    }
                                </div>
                            }
                            {
                                this.state.InSchool === true&&
                                <div className={'GPUploadFileList clearfix'}>
                                    <div className={'GPUploadFileBtn'}
                                         style={this.state.InSchoolFileName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',} : {}}
                                    >
                                        在校证明
                                        {
                                            this.state.GPInfoState === 'select' &&
                                            <Upload{...propsInSchool}/>
                                        }
                                    </div>
                                    <div className={'GPUploadFileName'}>{this.state.InSchoolFileName}</div>
                                    {
                                        this.state.InSchoolFileName !== '' &&
                                        <div className={'GPUploadFileIco'}> </div>
                                    }
                                </div>
                            }
                            {
                                this.state.Know === true&&
                                <div className={'GPUploadFileList clearfix'}>
                                    <div className={'GPUploadFileBtn'}
                                         style={this.state.KnowFileName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',} : {}}
                                    >
                                        监护人知情涵
                                        {
                                            this.state.GPInfoState === 'select' &&
                                            <Upload{...propsKnow}/>
                                        }
                                    </div>
                                    <div className={'GPUploadFileName'}>{this.state.KnowFileName}</div>
                                    {
                                        this.state.KnowFileName !== '' &&
                                        <div className={'GPUploadFileIco'}> </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.adminOtherProve === 1&& this.state.GPInfoState === 'select' &&
                        <div className={'GPListTitle'} style={{marginBottom:20,}}>*上传其他文件（非必须）</div>
                    }
                    {
                        this.state.adminOtherProve === 1&& this.state.GPInfoState === 'select' &&
                        <div className={'GPUploadFileBox'}>
                            <div className={'GPUploadFileList clearfix'} style={{height:'auto',minHeight:26,}}>
                                <div className={'GPUploadFileBtn'}
                                     style={this.state.OtherName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',height:'auto',minHeight:26,textAlign:'justify',} : {height:'auto',minHeight:26,textAlign:'justify',}}
                                >
                                    {this.state.adminOtherProveRemarks}<span style={{color:'#ff0000',verticalAlign:'super',fontSize:12,}}>(点击上传)</span>
                                    <Upload{...propsOther}/>
                                </div>
                                <div className={'GPUploadFileName'}>{this.state.OtherName}</div>
                                {
                                    this.state.OtherName !== '' &&
                                    <div className={'GPUploadFileIco'}> </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.GPInfoState === 'save' && this.state.indexOtherProve === 1&&
                        <div className={'GPListTitle'} style={{marginBottom:20,}}>*上传其他文件（非必须）</div>
                    }
                    {
                        this.state.indexOtherProve === 1&& this.state.GPInfoState === 'save' &&
                        <div className={'GPUploadFileBox'}>
                            <div className={'GPUploadFileList clearfix'} style={{height:'auto',minHeight:26,}}>
                                <div className={'GPUploadFileBtn'}
                                     style={this.state.OtherName !== '' ? {backgroundColor:'#8DCEFD',color:'#3F3F3F',height:'auto',minHeight:26,textAlign:'justify',} : {height:'auto',minHeight:26,textAlign:'justify',}}
                                >
                                    {this.state.adminOtherProveRemarks}
                                </div>
                                <div className={'GPUploadFileName'}>{this.state.indexOtherProveUrl}</div>
                                {
                                    this.state.indexOtherProveUrl !== '' &&
                                    <div className={'GPUploadFileIco'}> </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.GPInfoState === 'select' && this.state.GPInfoStateSave === '报名保存' &&  !this.state.PersonalInfo.team_id &&
                        <div>
                            <div className={'GamePersonalBtn'} style={{marginTop:48,}} onClick={this.GamePersonalBtn.bind(this)}>保存信息</div>
                            <div className={'GamePersonalBtnTip'}>*您可在报名截止日期前更改报名信息</div>
                        </div>
                    }
                    {
                        this.state.GPInfoState === 'select' && this.state.GPInfoStateSave === '修改保存' &&  !this.state.PersonalInfo.team_id &&
                        <div>
                            <div className={'GamePersonalBtn'} style={{marginTop:48,}} onClick={this.GamePersonalUpData.bind(this)}>保存信息</div>
                            <div className={'GamePersonalBtnTip'}>*您可在报名截止日期前更改报名信息</div>
                        </div>
                    }
                    {
                        this.state.GPInfoState === 'save' && !this.state.PersonalInfo.team_id &&
                        <div>
                            <div className={'GamePersonalBtn'} style={{marginTop:48,}} onClick={this.GamePersonalInfoBtn.bind(this)}>更改信息</div>
                            {
                                this.state.PersonalInfo.type === 2 &&
                                <div className={'GamePersonalBtn'} style={{marginTop:18,}} onClick={this.GamePersonalDelBtn.bind(this)}>取消报名</div>
                            }
                            <div className={'GamePersonalBtnTip'}>*您可在报名截止日期前更改报名信息</div>
                            {
                                this.state.MatchName === '"天空很辽阔，我们一起飞"江苏省第二届网络全民健身运动会 模拟飞行竞赛'&&
                                <div
                                    className={'GamePersonalBtnTip'}
                                    style={{color:'#6DC1FF',textDecoration:'underline',cursor:'pointer',}}
                                    onClick={this.query.bind(this)}
                                >
                                    &gt; &gt; &gt; &gt; &gt; &gt; 点击进入模拟飞行网络竞赛知识问答 &lt; &lt; &lt; &lt; &lt; &lt;
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.GPInfoState === 'save' && this.state.PersonalInfo.team_id &&
                        <div className={'GamePersonalBtn'} style={{width:267,marginTop:48,cursor:'unset',color:'#060606',}}>您已经参与团体报名</div>
                    }
                    {
                        this.state.GPInfoState === 'save' && this.state.PersonalInfo.team_id && this.state.MatchName === '"天空很辽阔，我们一起飞"江苏省第二届网络全民健身运动会 模拟飞行竞赛'&&
                        <div
                            className={'GamePersonalBtnTip'}
                            style={{color:'#6DC1FF',textDecoration:'underline',cursor:'pointer',}}
                            onClick={this.query.bind(this)}
                        >
                            &gt; &gt; &gt; &gt; &gt; &gt; 点击进入模拟飞行网络竞赛知识问答 &lt; &lt; &lt; &lt; &lt; &lt;
                        </div>
                    }
                </div>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default GamePersonal;
