import React, { Component } from 'react'
import './member.css'
import { get, post } from '../../ajax/tools'
import PopUp from '../round/PopUp'
import { API_URL } from '../../ajax/config'
import Triangle from '../round/Triangle'
class Six1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Step: 1,
      User: {},
      sun_number: '000000',
      sun_code: '000000',
      orderId: '',
      type: '',
      InvoiceTitle: '',
      InvoiceNumber: '',
      sixNum: '',
      LookArr: [],
      Ren: '',
    }
  }
  componentDidMount = function () {
    get({ url: 'user/basic_info' }).then((res) => {
      if (res.errno === '200') {
        let User = res.data[0]
        this.setState({
          User: User,
          type: User.type,
        })
      } else {
        const { history } = this.props
        history.push('/login')
      }
    })
    get({ url: 'user/query_ChuJiaoLiu_order' }).then((res) => {
      if (res.errno === '200') {
        let sb = []
        for (let i = 0; i < res.data.length; i++) {
          let a = res.data[i]
          if (a.pay_method === 3) {
            if (
              a.isdel === 0 &&
              a.status === 1 &&
              a.isrefund === 0 &&
              a.confirm_receipt === 1
            ) {
              sb.push(a)
            }
          } else {
            if (a.isdel === 0 && a.status === 1 && a.isrefund === 0) {
              sb.push(a)
            }
          }
        }
        if (sb.length !== 0) {
          this.setState({
            Ren: '1',
          })
        }
      }
    })
  }

  componentWillUnmount = () => {
    clearInterval(this.PayWeChat)
    this.setState = () => {}
  }

  SixStepBtn() {
    get({ url: '/user/get_nopay_order/7' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          orderId: res.order_id,
          Step: 3,
          PopUp: '提示弹窗',
          MessageTip: '您有未支付订单！',
        })
        setTimeout(() => {
          this.setState({
            PopUp: '',
          })
        }, 800)
        this.PayWeChat = setInterval(() => {
          get({ url: '/user/get_order_state/' + res.order_id }).then((res) => {
            if (res.state === 1) {
              clearInterval(this.PayWeChat)
              alert('支付成功！')
              this.setState({
                Step: 4,
              })
            }
          })
        }, 2000)
      } else {
        this.setState({
          Step: 2,
        })
      }
    })
  }

  // 查看订单
  SixLookBtn() {
    get({ url: 'user/query_ChuJiaoLiu_order' }).then((res) => {
      if (res.errno === '200') {
        let sb = []
        let dsb = []
        for (let i = 0; i < res.data.length; i++) {
          let a = res.data[i]
          if (a.pay_method === 3) {
            if (
              a.isdel === 0 &&
              a.status === 1 &&
              a.isrefund === 0 &&
              a.confirm_receipt === 1
            ) {
              sb.push(a)
            }
          } else {
            if (a.isdel === 0 && a.status === 1 && a.isrefund === 0) {
              sb.push(a)
            }
          }
          if (a.isdel === 0) {
            dsb.push(a)
          }
        }
        if (sb.length !== 0) {
          this.setState({
            Ren: '1',
          })
        }
        if (dsb.length !== 0) {
          this.setState({
            LookArr: res.data,
            Step: 7,
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: '暂无服务订单记录！',
          })
        }
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  Back1() {
    clearInterval(this.PayWeChat)
    get({ url: 'user/query_ChuJiaoLiu_order' }).then((res) => {
      if (res.errno === '200') {
        let sb = []
        for (let i = 0; i < res.data.length; i++) {
          let a = res.data[i]
          if (a.pay_method === 3) {
            if (
              a.isdel === 0 &&
              a.status === 1 &&
              a.isrefund === 0 &&
              a.confirm_receipt === 1
            ) {
              sb.push(a)
            }
          } else {
            if (a.isdel === 0 && a.status === 1 && a.isrefund === 0) {
              sb.push(a)
            }
          }
        }
        if (sb.length !== 0) {
          this.setState({
            Ren: '1',
          })
        }
      }
    })
    this.setState({
      Step: 1,
      sixNum: '',
    })
  }

  sun_number(e) {
    e.target.value = e.target.value.replace(/[^\d]/g, '')
    this.setState({
      sun_number: e.target.value,
    })
  }
  sun_code(e) {
    e.target.value = e.target.value.replace(/[^\d]/g, '')
    this.setState({
      sun_code: e.target.value,
    })
  }

  sixNum(event) {
    event.target.value = event.target.value
      .replace(/[^\d]/g, '')
      .replace(/^0/g, '')
    this.setState({
      sixNum: event.target.value,
    })
  }

  SixBtn() {
    let sun_number = this.state.sun_number
    let sun_code = this.state.sun_code
    let sixNum = this.state.sixNum
    let mobile = this.state.User.mobile
    let email = this.state.User.email
      ? this.state.User.email
      : this.state.User.org_email
    if (sixNum !== '') {
      post({
        url: 'user/ChuJiaoLiu_order',
        data: {
          sun_code: sun_code,
          sun_number: sun_number,
          mobile: mobile,
          email: email,
          file_url: '',
          order_remark: '神翼初教六',
          num: '2',
          purchase_quantity: sixNum,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            orderId: res.order_id,
            Step: 3,
          })
          this.PayWeChat = setInterval(() => {
            get({ url: '/user/get_order_state/' + res.order_id }).then(
              (res) => {
                if (res.state === 1) {
                  clearInterval(this.PayWeChat)
                  alert('支付成功！')
                  this.setState({
                    Step: 4,
                    sixNum: '',
                  })
                }
              }
            )
          }, 2000)
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请输入服务购买数量！',
      })
    }
  }

  DGBtn() {
    clearInterval(this.PayWeChat)
    this.setState({
      Step: 5,
      InvoiceTitle: '',
      InvoiceNumber: '',
    })
  }
  InvoiceTitle(event) {
    //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
    this.setState({
      InvoiceTitle: event.target.value,
    })
  }
  InvoiceNumber(event) {
    //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
    this.setState({
      InvoiceNumber: event.target.value,
    })
  }
  SaveDGBtn() {
    let InvoiceTitle = this.state.InvoiceTitle
    let InvoiceNumber = this.state.InvoiceNumber
    let orderId = this.state.orderId
    let sixNum = this.state.sixNum
    if (InvoiceTitle !== '' && InvoiceNumber !== '') {
      post({
        url: 'user/add_invoice',
        data: {
          order_id: orderId,
          invoice_type: '1',
          invoice_top: InvoiceTitle,
          invoice_code: InvoiceNumber,
        },
      }).then((res) => {
        if (res.errno === '200') {
          post({
            url: 'payment/re_goods_order',
            data: {
              order_id: orderId,
              cash_fee: 398 * sixNum,
            },
          }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                Step: 6,
                sixNum: '',
              })
            } else {
              this.setState({
                PopUp: '提示弹窗',
                MessageTip: res.errmsg + '！',
              })
            }
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    } else {
      post({
        url: 'payment/re_goods_order',
        data: {
          order_id: orderId,
          cash_fee: 398 * sixNum,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            Step: 6,
            sixNum: '',
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    }
  }

  Back2() {
    let sixNum = this.state.sixNum
    let mobile = this.state.User.mobile
    let email = this.state.User.email
      ? this.state.User.email
      : this.state.User.org_email
    get({ url: '/user/get_nopay_order/7' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          orderId: res.order_id,
          Step: 3,
        })
        this.PayWeChat = setInterval(() => {
          get({ url: '/user/get_order_state/' + res.order_id }).then((res) => {
            if (res.state === 1) {
              clearInterval(this.PayWeChat)
              alert('支付成功！')
              this.setState({
                Step: 4,
                sixNum: '',
              })
            }
          })
        }, 2000)
      } else {
        post({
          url: 'user/ChuJiaoLiu_order',
          data: {
            sun_code: '000000',
            sun_number: '000000',
            mobile: mobile,
            email: email,
            file_url: '',
            order_remark: '神翼初教六',
            num: '2',
            purchase_quantity: sixNum,
          },
        }).then((res) => {
          if (res.errno === '200') {
            this.setState({
              orderId: res.order_id,
              Step: 3,
            })
            this.PayWeChat = setInterval(() => {
              get({ url: '/user/get_order_state/' + res.order_id }).then(
                (res) => {
                  if (res.state === 1) {
                    clearInterval(this.PayWeChat)
                    alert('支付成功！')
                    this.setState({
                      Step: 4,
                      sixNum: '',
                    })
                  }
                }
              )
            }, 2000)
          } else {
            this.setState({
              PopUp: '提示弹窗',
              MessageTip: res.errmsg + '！',
            })
          }
        })
      }
    })
  }

  About() {
    window.location.href = '/aboutMyself'
  }

  message() {
    window.location.href = '/message'
  }

  //提示弹窗
  ChildBackTip = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    }
  }

  render() {
    return (
      <div
        className={'clearfix scrollBarTwo'}
        style={{
          position: 'relative',
          width: 896,
          height:
            window.innerHeight - 180 > 600 ? window.innerHeight - 180 : 600,
          minHeight: 40,
          margin: '0 auto',
        }}
      >
        {this.state.Step === 1 && (
          <div>
            <div
              className={'clearfix scrollBarTwo'}
              style={{
                position: 'relative',
                width: 896,
                height: 'auto',
                marginTop: 20,
                marginBottom: 40,
              }}
            >
              <div className={'PromoCodeStepTitle'}>
                初教六模拟飞行软件激活须知
              </div>
              <div className={'PromoCodeStepTit'}>尊敬的用户：</div>
              <div className={'PromoCodeStepCon'}>
                神翼2020模拟飞行软件，是由北京鹰扬科技有限公司（模拟飞行服务平台）及北京微视威信息科技有限公司（北京大学图形图像实验室）联合开发的具有完整自主知识产权，采用自主地景，自主飞控与机模的通用型飞行软件，满足飞行学员驾驶初教6飞机在不同时间、季节、气象等复杂环境中进行模拟飞行训练。后续软件将持续完善。
              </div>
              <div className={'PromoCodeStepCon'}>
                目前提供软件授权使用服务。
              </div>
              <div className={'PromoCodeStepTit'}>重要信息：</div>
              <div className={'PromoCodeStepCon'}>
                1、<span style={{ color: '#ff0000' }}>购买前请务必阅读</span>
                《神翼2020初教六模拟飞行软件安装激活说明》
              </div>
              <div className={'PromoCodeStepCon'}>
                2、您所购买的产品为1台电脑的专用授权，仅限下单账号使用，无法转移或转让。
              </div>
              <div className={'PromoCodeStepCon'}>
                3、发送激活邮件时请务必包含购买账号的ID和用户名，如信息不符将无法激活。
              </div>
              <div className={'PromoCodeStepCon'}>
                4、购买后的
                <span style={{ color: '#ff0000' }}>第二个工作日</span>
                内邮件回复激活码，请妥善安排您的使用计划。
              </div>
              <div className={'PromoCodeStepCon'}>
                5、技术支持时间为工作日的9点至18点。
              </div>
              <div className={'PromoCodeStepCon'}>
                6、为了促进软件开发，提高使用者的模拟飞行体验感。您的意见和建议将是我们宝贵的财富。
              </div>
              <div className={'PromoCodeStepTit'} style={{ marginTop: 14 }}>
                购买问题请联系：
              </div>
              <div
                className={'PromoCodeStepCon'}
                style={{ fontSize: 20, color: '#ff0000' }}
              >
                米老师 13691581580
              </div>
              {this.state.Ren !== '' && (
                <div>
                  <div className={'PromoCodeStepTit'}>技术服务联系人：</div>
                  <div
                    className={'PromoCodeStepCon'}
                    style={{ fontSize: 20, color: '#ff0000' }}
                  >
                    李智老师 18701581413{' '}
                    <span style={{ fontSize: 16, color: '#000' }}>邮箱：</span>
                    vsvec_com@126.com
                  </div>
                </div>
              )}
              <div className={'PromoCodeStepTit'}>备注：</div>
              <div className={'PromoCodeStepCon'}>
                <a
                  href={'https://pan.baidu.com/s/1Iy1uqwvpy1qSc_LwVuhIjg'}
                  target={'_blank'}
                >
                  神翼2020初教六模拟飞行软件下载
                </a>
                &nbsp;&nbsp;提取码：5bwi
              </div>
              <div className={'PromoCodeStepCon'}>
                <a
                  href={
                    'http://oss.asfcyy.com/richTextImage/af6a2588731b11ee9dc800163e3521a2.pdf'
                  }
                  target={'_blank'}
                >
                  神翼2020初教六模拟飞行软件安装激活说明
                </a>
              </div>
              <div className={'PromoCodeStepCon'}>
                <a
                  href={
                    'http://oss.asfcyy.com/richTextImage/1a784c544df311ee9dc800163e3521a2.pdf'
                  }
                  target={'_blank'}
                >
                  初教六摇杆功能按键说明
                </a>
              </div>
            </div>
            <div
              className={'TrickInfoBtn'}
              style={{ fontSize: 16, margin: '20px auto', width: 220 }}
              onClick={this.SixStepBtn.bind(this)}
            >
              我已阅读并同意(下一步)
            </div>
            <div
              className={'TrickInfoBtn'}
              style={{
                fontSize: 16,
                margin: '40px auto',
                width: 180,
                backgroundColor: '#009944',
              }}
              onClick={this.SixLookBtn.bind(this)}
            >
              查看购买记录进度
            </div>
          </div>
        )}
        {this.state.Step === 2 && (
          <div>
            <div
              className={'TrickTitle'}
              style={{ marginTop: 40, color: '#000' }}
            >
              初教六模拟飞行软件激活相关信息
            </div>
            <div
              className={'TrickRemarks'}
              style={{ color: '#ff0000', fontSize: 14, fontWeight: 'bold' }}
            >
              *请先确认此账号邮箱与联系方式是否正确，如有改变，请至个人中心 >
              基础信息 > 邮箱/联系方式修改
            </div>
            <div
              className={'TrickRemarks'}
              style={{ color: '#ff0000', fontSize: 14, fontWeight: 'bold' }}
            >
              *工作人员将通过以下方式与您联络，请务必保证正确！
            </div>
            <div className={'TrickInfoBox clearfix'}>
              <div className={'TrickInfoTit'}>联系方式：</div>
              <div
                className={'TrickInfoWord'}
                style={{ border: '0', fontSize: 18, lineHeight: '32px' }}
              >
                {this.state.User.mobile}
              </div>
            </div>
            <div className={'TrickInfoBox clearfix'}>
              <div className={'TrickInfoTit'}>邮箱：</div>
              <div
                className={'TrickInfoWord'}
                style={{ border: '0', fontSize: 18, lineHeight: '32px' }}
              >
                {this.state.User.email
                  ? this.state.User.email
                  : this.state.User.org_email}
              </div>
            </div>
            <div className={'TrickInfoBox clearfix'}>
              <div className={'TrickInfoTit'}>服务购买数量：</div>
              <div
                className={'TrickInfoWord'}
                style={{ border: '0', fontSize: 18, lineHeight: '32px' }}
              >
                <div
                  className={'AddInfoMainCon'}
                  style={{ height: 30, width: 80 }}
                >
                  <input
                    className={'AddInfoMainInput'}
                    style={{
                      height: 28,
                      width: 76,
                      lineHeight: '28px',
                      fontSize: 22,
                    }}
                    type={'text'}
                    name={'SearchInput'}
                    value={this.state.sixNum}
                    onChange={this.sixNum.bind(this)}
                    placeholder={''}
                    autoComplete={'off'}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ color: '#ff0000', fontSize: 15 }}>
                  *请输入服务购买数量!
                </span>
              </div>
            </div>
            <div
              className={'TrickInfoBtn'}
              style={{
                position: 'absolute',
                left: 0,
                bottom: 80,
                right: 0,
                margin: 'auto',
              }}
              onClick={this.SixBtn.bind(this)}
            >
              提交
            </div>
          </div>
        )}
        {this.state.Step === 3 && (
          <div>
            <div className={'VipMainTitBox clearfix'}>
              <div
                className={'SubjectDownIco'}
                style={{
                  float: 'left',
                  width: 38,
                  height: 48,
                  position: 'relative',
                }}
                onClick={this.Back1.bind(this)}
              >
                <Triangle
                  Direction={'left'}
                  Color={'#0488CA'}
                  Width={'10px'}
                  Height={'10px'}
                  Top={'14px'}
                  Right={'8px'}
                />
              </div>
              <div className={'VipBackWord'} onClick={this.Back1.bind(this)}>
                返回
              </div>
            </div>
            <div
              className={'TrickTitle'}
              style={{ marginTop: 40, color: '#000' }}
            >
              支付订单
            </div>
            <div>
              <div className={'MoneyWord'}>
                <i className={'weixinIco'}> </i>使用微信扫码支付
              </div>
              <div className={'MoneyPhoto'} style={{ width: 192, height: 192 }}>
                <img
                  className={'PayCodePhoto'}
                  alt={''}
                  src={
                    API_URL + '/payment/WXpay_goods_order/' + this.state.orderId
                  }
                />
              </div>
              <div className={'AltWord'}>*请稍等片刻生成二维码</div>
              <div
                className={'AddressShow'}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                如有问题请&nbsp;&nbsp;
                <span
                  style={{ color: '#ff0000', fontSize: 16, cursor: 'pointer' }}
                  onClick={this.About.bind(this)}
                >
                  联系我们
                </span>
                &nbsp;&nbsp;我们会及时回复！
              </div>
            </div>
            {this.state.type === 1 && (
              // <div className={'ManualReviewBlackWord'} style={{marginTop:30,color:'#F5495D',}} onClick={this.DGBtn.bind(this)}>选择对公转账</div>
              <div
                className={'TrickInfoBtn'}
                style={{
                  width: 320,
                  height: 80,
                  lineHeight: '80px',
                  fontSize: 32,
                  margin: 'auto',
                }}
                onClick={this.DGBtn.bind(this)}
              >
                选择对公转账
              </div>
            )}
          </div>
        )}
        {this.state.Step === 4 && (
          <div>
            <div
              className={'ReSuccessBox DisplayFlex'}
              style={{ marginTop: 180, marginBottom: 24 }}
            >
              <div className={'ReSuccessIco'}> </div>
              <div
                className={'ReSuccessWord'}
                style={{ float: 'left', marginLeft: 20 }}
              >
                支付成功！
              </div>
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{
                marginTop: 20,
                color: '#ff0000',
                fontSize: 15,
                textDecoration: 'none',
              }}
            >
              *您已操作成功，请耐心等待并关注{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: '#1B7CFF',
                  cursor: 'pointer',
                }}
                onClick={this.message.bind(this)}
              >
                系统消息
              </span>
              ，我们会尽快联系您。
            </div>
            <div style={{ width: 520, margin: '40px auto' }}>
              <div className={'PromoCodeStepTit'}>技术服务联系人：</div>
              <div
                className={'PromoCodeStepCon'}
                style={{ fontSize: 20, color: '#ff0000', textIndent: 'unset' }}
              >
                李智老师 18701581413{' '}
                <span style={{ fontSize: 16, color: '#000' }}>邮箱：</span>
                vsvec_com@126.com
              </div>
            </div>
            <div style={{ width: 520, margin: '40px auto' }}>
              <div className={'PromoCodeStepTit'}>如需开具发票请联系：</div>
              <div
                className={'PromoCodeStepCon'}
                style={{ fontSize: 20, color: '#ff0000', textIndent: 'unset' }}
              >
                米老师 13691581580
              </div>
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{ marginTop: 60, color: '#F5495D' }}
              onClick={this.Back1.bind(this)}
            >
              返回
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{ marginTop: 40, color: '#F5495D' }}
              onClick={this.SixLookBtn.bind(this)}
            >
              查看购买记录进度
            </div>
          </div>
        )}
        {this.state.Step === 6 && (
          <div>
            <div className={'ManualReviewBox'}>
              <div className={'ManualReviewIco'}> </div>
              <div className={'ManualReviewWordBox'}>
                <div
                  className={'ManualReviewWord'}
                  style={{ textAlign: 'left' }}
                >
                  确认收款中...
                </div>
                <div
                  className={'ManualReviewWord'}
                  style={{ textAlign: 'right' }}
                >
                  请您耐心等待
                </div>
              </div>
              <div className={'ManualReviewStep'}>
                <div className={'ManualReviewStepHave'}> </div>
              </div>
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{ marginTop: 30, color: '#F5495D' }}
              onClick={this.Back1.bind(this)}
            >
              返回
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{ marginTop: 30, color: '#F5495D' }}
              onClick={this.SixLookBtn.bind(this)}
            >
              查看购买记录进度
            </div>
          </div>
        )}
        {this.state.Step === 5 && (
          <div>
            <div
              className={'PayInfoBox'}
              style={{
                width: '96%',
                margin: '40px auto',
                height: 180,
                position: 'relative',
              }}
            >
              {/*<div className={'PayYYInfoBox'}>*/}
              {/*    <div className={'PayYYListBox'}>*/}
              {/*        <div className={'PayYYListTit'}>收款户名</div>*/}
              {/*        <div className={'PayYYListCon'}>北京鹰扬科技有限公司</div>*/}
              {/*    </div>*/}
              {/*    <div className={'PayYYListBox'}>*/}
              {/*        <div className={'PayYYListTit'}>收款账号</div>*/}
              {/*        <div className={'PayYYListCon'}>0200213509020041724</div>*/}
              {/*    </div>*/}
              {/*    <div className={'PayYYListBox'}>*/}
              {/*        <div className={'PayYYListTit'}>收款银行</div>*/}
              {/*        <div className={'PayYYListCon'}>中国工商银行北京安华桥西支行</div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className={'PayYYInfoBox'}>
                <div className={'PayYYListBox'}>
                  <div className={'PayYYListTit'}>收款户名</div>
                  <div className={'PayYYListCon'}>河南鹰扬软件科技有限公司</div>
                </div>
                <div className={'PayYYListBox'}>
                  <div className={'PayYYListTit'}>收款账号</div>
                  <div className={'PayYYListCon'}>1702100609100074228</div>
                </div>
                <div className={'PayYYListBox'}>
                  <div className={'PayYYListTit'}>收款银行</div>
                  <div className={'PayYYListCon'}>
                    中国工商银行郑州宝龙广场支行
                  </div>
                </div>
              </div>
              <div
                className={'PayMoneyWord'}
                style={{ marginRight: 40, marginTop: 130 }}
              >
                元
              </div>
              <div
                className={'PayMoney￥Word'}
                style={{ margin: '110px 10px 0 10px' }}
              >
                ￥{398 * this.state.sixNum}
              </div>
              <div className={'PayMoneyWord'} style={{ marginTop: 130 }}>
                转账
              </div>
            </div>
            <div
              className={'PayInfoTitBox'}
              style={{ height: 'auto', margin: '40px auto' }}
            >
              <div className={'TrickInfoBox clearfix'}>
                <div className={'TrickInfoTit'} style={{ width: 260 }}>
                  发票抬头：
                </div>
                <div className={'TrickInfoWord'}>
                  <input
                    className={'TrickInfoInput'}
                    type={'text'}
                    name={'name'}
                    value={this.state.InvoiceTitle}
                    onChange={this.InvoiceTitle.bind(this)}
                    placeholder={''}
                    autoComplete={'off'}
                  />
                </div>
              </div>
              <div className={'TrickInfoBox clearfix'}>
                <div className={'TrickInfoTit'} style={{ width: 260 }}>
                  纳税人识别号：
                </div>
                <div className={'TrickInfoWord'}>
                  <input
                    className={'TrickInfoInput'}
                    type={'text'}
                    name={'QQNum'}
                    value={this.state.InvoiceNumber}
                    onChange={this.InvoiceNumber.bind(this)}
                    placeholder={''}
                    autoComplete={'off'}
                  />
                </div>
              </div>
            </div>
            <div
              className={'TrickRemarks'}
              style={{ color: '#ff0000', fontSize: 14, fontWeight: 'bold' }}
            >
              *不填写发票抬头、纳税人识别号则认为不需要开具发票，望周知！
            </div>
            <div
              className={'TrickInfoBtn'}
              style={{
                width: 320,
                height: 60,
                lineHeight: '60px',
                fontSize: 18,
                margin: '40px auto',
              }}
              onClick={this.SaveDGBtn.bind(this)}
            >
              保存信息并生成订单
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{ marginTop: 60, color: '#1B7CFF' }}
              onClick={this.Back2.bind(this)}
            >
              返回二维码支付
            </div>
          </div>
        )}
        {this.state.Step === 7 && (
          <div>
            <div className={'VipMainTitBox clearfix'}>
              <div
                className={'SubjectDownIco'}
                style={{
                  float: 'left',
                  width: 38,
                  height: 48,
                  position: 'relative',
                }}
                onClick={this.Back1.bind(this)}
              >
                <Triangle
                  Direction={'left'}
                  Color={'#0488CA'}
                  Width={'10px'}
                  Height={'10px'}
                  Top={'14px'}
                  Right={'8px'}
                />
              </div>
              <div className={'VipBackWord'} onClick={this.Back1.bind(this)}>
                返回
              </div>
              {this.state.Ren !== '' && (
                <div
                  className={'PromoCodeStepCon'}
                  style={{
                    fontSize: 20,
                    color: '#ff0000',
                    float: 'left',
                    width: 'auto',
                    height: 40,
                    lineHeight: '40px',
                    textIndent: '1em',
                  }}
                >
                  <span style={{ fontSize: 16, color: '#000' }}>
                    技术服务联系人：
                  </span>
                  李智老师 18701581413{' '}
                  <span style={{ fontSize: 16, color: '#000' }}>邮箱：</span>
                  vsvec_com@126.com
                </div>
              )}
            </div>
            <div className={'PeDataTopBox'}>
              <div className={'TableHeader'} style={{ width: 890 }}>
                <div className={'TableHeaderInfo'} style={{ width: 170 }}>
                  时间
                </div>
                <div className={'TableHeaderInfo'} style={{ width: 160 }}>
                  数量
                </div>
                <div className={'TableHeaderInfo'} style={{ width: 160 }}>
                  金额
                </div>
                <div className={'TableHeaderInfo'} style={{ width: 200 }}>
                  付款方式
                </div>
                <div className={'TableHeaderInfo'} style={{ width: 200 }}>
                  进度
                </div>
              </div>
            </div>
            <div
              className={'PeDataConBoxes'}
              style={{ height: window.innerHeight - 284 }}
            >
              <div
                className={'PeDataConBox'}
                style={{ height: window.innerHeight - 284 }}
              >
                {this.state.LookArr.map((item, index) => (
                  <div key={index}>
                    {item.isdel === 0 && (
                      <div className={'TableContent'}>
                        <div
                          className={'TableContentInfo'}
                          style={{ width: 170 }}
                        >
                          {item.up_time.substring(0, 10)}
                        </div>
                        <div
                          className={'TableContentInfo'}
                          style={{ width: 160 }}
                        >
                          {item.purchase_quantity !== null
                            ? item.purchase_quantity
                            : 1}
                        </div>
                        <div
                          className={'TableContentInfo'}
                          style={{ width: 160 }}
                        >
                          {item.price}
                        </div>
                        <div
                          className={'TableContentInfo'}
                          style={{ width: 200 }}
                        >
                          {item.pay_method === 3 && (
                            <div style={{ display: 'table', margin: '0 auto' }}>
                              <div style={{ color: '#333' }}>对公转账</div>
                            </div>
                          )}
                          {item.pay_method !== 3 && (
                            <div style={{ display: 'table', margin: '0 auto' }}>
                              <div style={{ color: '#333' }}>扫码支付</div>
                            </div>
                          )}
                        </div>
                        <div
                          className={'TableContentInfo'}
                          style={{ width: 200 }}
                        >
                          {item.status === 0 && item.pay_method === 3 && (
                            <div style={{ display: 'table', margin: '0 auto' }}>
                              <div style={{ color: '#ff0000' }}>未确认收款</div>
                            </div>
                          )}
                          {item.status === 0 && item.pay_method !== 3 && (
                            <div style={{ display: 'table', margin: '0 auto' }}>
                              <div style={{ color: '#ff0000' }}>未付款</div>
                            </div>
                          )}
                          {item.status === 1 &&
                            item.pay_method === 3 &&
                            item.isrefund === 0 &&
                            item.confirm_receipt === 0 && (
                              <div
                                style={{ display: 'table', margin: '0 auto' }}
                              >
                                <div style={{ color: '#d3d3d3' }}>等待确认</div>
                              </div>
                            )}
                          {item.status === 1 &&
                            item.pay_method === 3 &&
                            item.isrefund === 0 &&
                            item.confirm_receipt === 1 && (
                              <div
                                style={{ display: 'table', margin: '0 auto' }}
                              >
                                <div style={{ color: '#009944' }}>
                                  已确认收款
                                </div>
                              </div>
                            )}
                          {item.status === 1 &&
                            item.pay_method !== 3 &&
                            item.isrefund === 0 && (
                              <div
                                style={{ display: 'table', margin: '0 auto' }}
                              >
                                <div style={{ color: '#009944' }}>支付成功</div>
                              </div>
                            )}
                          {item.status === 1 && item.isrefund === 1 && (
                            <div style={{ display: 'table', margin: '0 auto' }}>
                              <div style={{ color: '#FD7B4D' }}>已退款</div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {this.state.PopUp === '提示弹窗' && (
          <PopUp
            ChildBackClick={this.ChildBackTip.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
      </div>
    )
  }
}

export default Six1
