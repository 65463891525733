import React, { Component } from 'react';
import './exam.css';
import PopUp from "../round/PopUp";
import {get,} from "../../ajax/tools";



class ExamPeInfoList extends Component {

    constructor(props){
        super(props);
        this.state = {
            ExamPeInfoListArr:[],
            OrderInfo:{},//传到此页面的订单信息
            MoneySum: 0,//申请合计总钱数
            SelectedOrderInfo:{},//选中的订单信息
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            OrderInfo:history.OrderInfo,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        this.setState({
            OrderInfo:history.OrderInfo,
        });
        get({url:'exam/read_order_detail/'+history.OrderInfo.id}).then((res) => {
            if(res.errno === '200'){
                let MoneySum= 0 ;
                for(let i=0;i<res.data.length;i++){
                    let ExamPeopleList = res.data[i];
                    MoneySum= MoneySum+Number(ExamPeopleList.price);
                }
                this.setState({
                    ExamPeInfoListArr:res.data,
                    MoneySum:MoneySum,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'未知错误，请联系我们！',
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };



    AllClick = () => {
        this.setState({
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };



    //点击删除单个信息
    DeleteExamPeInfo=(item,index)=>{
        this.setState({
            PopUp:'确认是否删除弹窗',
            MessageTip:'是否删除该项测试申请？',
        });
    };


    //点击变更的编辑按钮
    ExamPeInfoChange=(item,index)=>{
        item['type'] = '变更编辑';
        this.props.ExamPeInfoListBack(item);
    };

    ExamPeInfoListPayBtn=()=>{
        let OrderInfo = this.state.OrderInfo;
        OrderInfo['BackType']='立即支付';
        this.props.ExamPeInfoListBack(OrderInfo);
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        //console.log(this.state.ExamPeopleListArr)
        return (
            <div className={'ExamMainBoxes'} style={{width:970,position:'relative',}}>
                {
                    this.state.OrderInfo.status === 0 &&
                    <div className={'ExamHaveHeader clearfix'}>
                        <div className={'ExamHaveHeaderList'} style={{width:240,}}>考场</div>
                        <div className={'ExamHaveHeaderList'} style={{width:280,}}>测试时间</div>
                        <div className={'ExamHaveHeaderList'} style={{width:240,}}>测试项目</div>
                        <div className={'ExamHaveHeaderList'} style={{width:200,}}>科目费用</div>
                    </div>
                }
                {
                    this.state.OrderInfo.status === 1 &&
                    <div className={'ExamHaveHeader clearfix'}>
                        <div className={'ExamHaveHeaderList'} style={{width:200,}}>考场</div>
                        <div className={'ExamHaveHeaderList'} style={{width:220,}}>测试时间</div>
                        <div className={'ExamHaveHeaderList'} style={{width:200,}}>测试项目</div>
                        <div className={'ExamHaveHeaderList'} style={{width:200,}}>测试号码</div>
                        <div className={'ExamHaveHeaderList'} style={{width:140,}}>科目费用</div>
                    </div>
                }
                {
                    this.state.OrderInfo.status === 2 &&
                    <div className={'ExamHaveHeader clearfix'}>
                        <div className={'ExamHaveHeaderList'} style={{width:240,}}>考场</div>
                        <div className={'ExamHaveHeaderList'} style={{width:280,}}>测试时间</div>
                        <div className={'ExamHaveHeaderList'} style={{width:240,}}>测试项目</div>
                        <div className={'ExamHaveHeaderList'} style={{width:200,}}>科目费用</div>
                    </div>
                }
                {
                    this.state.OrderInfo.status === 0 &&
                    <div className={'ExamMainOverflow scrollBar'} style={{height:window.innerHeight-390,}}>
                        {
                            this.state.ExamPeInfoListArr.map((item,index) =>
                                <div className={'ExamHaveCon clearfix'} key={index}>
                                    <div className={'ExamHaveConList'} style={{width:240,marginTop:8,}}>{item.address}</div>
                                    <div className={'ExamHaveConList'} style={{width:280,}}>
                                        <div style={{marginBottom:6,}}>{item.start_time.substring(0,10)}</div>
                                        {
                                            item.time_paragraph === '0'&&
                                            <div style={{marginBottom:6,}}>上午 {item.start_time.substring(11,16)}——{item.end_time.substring(11,16)}</div>
                                        }
                                        {
                                            item.time_paragraph === '1'&&
                                            <div style={{marginBottom:6,}}>下午 {item.start_time.substring(11,16)}——{item.end_time.substring(11,16)}</div>
                                        }
                                    </div>
                                    <div className={'ExamHaveConList'} style={{width:240,marginTop:8,}}>{item.exam_kind}</div>
                                    <div className={'ExamHaveConList'} style={{width:200,marginTop:8,}}>￥{item.price}.00</div>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    this.state.OrderInfo.status === 1 &&
                    <div className={'ExamMainOverflow scrollBar'} style={{height:window.innerHeight-298,}}>
                        {
                            this.state.ExamPeInfoListArr.map((item,index) =>
                                <div className={'ExamHaveCon clearfix'} key={index}>
                                    <div className={'ExamHaveConList'} style={{width:200,marginTop:8,}}>{item.address}</div>
                                    <div className={'ExamHaveConList'} style={{width:220,}}>
                                        <div style={{marginBottom:6,}}>{item.start_time == null ? item.start_time :item.start_time.substring(0,10)}</div>
                                        {
                                            item.time_paragraph === '0'&&
                                            <div style={{marginBottom:6,}}>上午 {item.start_time.substring(11,16)}——{item.end_time.substring(11,16)}</div>
                                        }
                                        {
                                            item.time_paragraph === '1'&&
                                            <div style={{marginBottom:6,}}>下午 {item.start_time.substring(11,16)}——{item.end_time.substring(11,16)}</div>
                                        }
                                    </div>
                                    <div className={'ExamHaveConList'} style={{width:200,marginTop:8,}}>{item.exam_kind}</div>
                                    <div className={'ExamHaveConList'} style={{width:200,marginTop:8,}}>{item.time_paragraph}</div>
                                    <div className={'ExamHaveConList'} style={{width:140,marginTop:8,}}>￥{item.price}.00</div>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    this.state.OrderInfo.status === 2 &&
                    <div className={'ExamMainOverflow scrollBar'} style={{height:window.innerHeight-298,}}>
                        {
                            this.state.ExamPeInfoListArr.map((item,index) =>
                                <div className={'ExamHaveCon clearfix'} key={index}>
                                    <div className={'ExamHaveConList'} style={{width:240,marginTop:8,}}>{item.address}</div>
                                    <div className={'ExamHaveConList'} style={{width:280,}}>
                                        <div style={{marginBottom:6,}}>{item.start_time.substring(0,10)}</div>
                                        {
                                            item.time_paragraph === '0'&&
                                            <div style={{marginBottom:6,}}>上午 {item.start_time.substring(11,16)}——{item.end_time.substring(11,16)}</div>
                                        }
                                        {
                                            item.time_paragraph === '1'&&
                                            <div style={{marginBottom:6,}}>下午 {item.start_time.substring(11,16)}——{item.end_time.substring(11,16)}</div>
                                        }
                                    </div>
                                    <div className={'ExamHaveConList'} style={{width:240,marginTop:8,}}>{item.exam_kind}</div>
                                    <div className={'ExamHaveConList'} style={{width:200,marginTop:8,}}>￥{item.price}.00</div>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    this.state.OrderInfo.status === 0 &&
                    <div className={'ExamPeInfoListBottomBox'} style={{marginTop:16,}}>
                        <button className={'ExamPeInfoListPayBtn'} onClick={this.ExamPeInfoListPayBtn.bind(this)}>立即支付</button>
                        <div className={'ExAddPeTotalMoney'} style={{marginTop:20,}}>{this.state.MoneySum}.00</div>
                        <div className={'ExAddPe￥Money'} style={{marginTop:20,}}>￥</div>
                        <div className={'ExAddPeTotalMoney ExAddPeTotalWord'} style={{marginTop:20,}}>总合计：</div>
                    </div>
                }
                {
                    this.state.PopUp === '确认是否删除弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackClick.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ExamPeInfoList;
