import React, { Component } from 'react'
import './member.css'
import '../round/round.css'
import { get, post } from '../../ajax/tools'
import PopUp from '../round/PopUp'
import { API_URL } from '../../ajax/config'
import DatePicker from '../round/DatePicker'
import PeYCECharts from './PeYCECharts'
import Pagination from '../round/Pagination'
import RadarECharts from './RadarECharts'
import HistoryECharts from './HistoryECharts'
import Triangle from '../round/Triangle'

class PeYunChengVIP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: {},
      Address: '',
      isCloudVip: '',

      PayNum1: '680',
      PayNum2: '1180',
      PayNumXF: '100',
      iconCheck: '',

      iconCheckIn: '',
      iconCheckAll: '',
      WeChatPay: '',
      VipPayStep: '',
      orderId: '',

      verifcatCode: '',
      VipTime: {},

      StartTime: '', //开始时间
      EndTime: '', //结束时间
      YCItemName: '', //科目名称
      YCItemId: '', //科目名称
      YCItemArr: [], //科目列表
      YCItemToggleOn: true,
      YCItemDisplay: 'none',
      YCAnalysisState: '',
      YCAnalysisInfo: {},

      HistoryDataArr: [],
      HistoryDataArrLength: 0,
      HistoryDataBrr: [],
      HistoryFive: '',
      HistoryRadarInfo: {},
      PageSize: '',
      CurrentPage: 1,

      detailedArr: [],
      detailedPopUp: '',
      detailedName: '',

      RadarItemArr: [],
      RadarType: '',
      RadarId: '',
      RadarName: '',
      RadarStart: '',
      RadarInfo: {},

      NavBoxType: '',

      InvoiceTitle: '',
      InvoiceNumber: '',

      BindMac: '',
    }
  }
  componentDidMount = function () {
    get({ url: 'user/basic_info' }).then((res) => {
      if (res.errno === '200') {
        let SbUser = res.data[0]
        let SbAddress =
          SbUser.province === SbUser.city
            ? SbUser.city + SbUser.county + SbUser.address
            : SbUser.province + SbUser.city + SbUser.county + SbUser.address
        this.setState({
          User: SbUser,
          Address: SbAddress,
          isCloudVip: SbUser.is_cloud_vip,
        })
        if (SbUser.is_cloud_vip === 1) {
          get({ url: 'user/get_VIP_time' }).then((res) => {
            if (res.errno === '200') {
              let a = res.data[0]
              this.setState({
                VipTime: a,
                verifcatCode: res.verificat_code,
              })
            }
          })
          get({ url: 'user/show_per_cloud_MAC' }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                BindMac: res.data[0].MAc,
              })
            } else {
              this.setState({
                BindMac: 'sb',
              })
            }
          })
        }
      }
    })
    let vm = this
    document.onclick = vm.AllClick.bind(this) //下拉菜单监听隐藏
  }

  AllClick = () => {
    this.setState({
      YCItemToggleOn: true,
      YCItemDisplay: 'none',
      NavBoxType: 'sb',
    })
  }
  componentDidUpdate = () => {
    function isFirefox() {
      return navigator.userAgent.toLowerCase().indexOf('firefox') > 0
    }
    if (isFirefox() && document.querySelector('.scrollBar')) {
      // console.log('您正在使用Firefox浏览器');
      document.querySelector('.scrollBar').classList.add('scrollBarFox')
      document.querySelector('.RadarStart').classList.add('scrollBarFox')
    }
  }
  componentWillUnmount = () => {
    this.setState = () => {}
  }

  iconCheckIn() {
    let iconCheckIn = this.state.iconCheckIn
    let iconCheckAll = this.state.iconCheckAll
    if (iconCheckIn === '') {
      this.setState({
        iconCheckIn: 'in',
      })
    }
    if (iconCheckAll === 'all') {
      this.setState({
        iconCheckIn: 'in',
        iconCheckAll: '',
      })
    }
  }

  iconCheckAll() {
    let iconCheckIn = this.state.iconCheckIn
    let iconCheckAll = this.state.iconCheckAll
    if (iconCheckAll === '') {
      this.setState({
        iconCheckAll: 'all',
      })
    }
    if (iconCheckIn === 'in') {
      this.setState({
        iconCheckAll: 'all',
        iconCheckIn: '',
      })
    }
  }

  // 开通个人云程套装
  PageOkBtn() {
    let SbUser = this.state.User
    let SbAddress =
      SbUser.province === SbUser.city
        ? SbUser.city + SbUser.county + SbUser.address
        : SbUser.province + SbUser.city + SbUser.county + SbUser.address
    this.setState({
      WeChatPay: '1',
      orderId: '',
      VipPayStep: '',
    })
    get({ url: '/user/get_nopay_order/11' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          orderId: res.order_id,
        })
        this.PayWeChat = setInterval(() => {
          get({ url: '/user/get_order_state/' + res.order_id }).then((res) => {
            if (res.state === 1) {
              clearInterval(this.PayWeChat)
              this.setState({
                VipPayStep: 'sb',
              })
            }
          })
        }, 2000)
      } else {
        post({
          url: 'user/cloud_VIP_order',
          data: {
            address: SbAddress,
            mobile: SbUser.mobile,
            cloud_VIP_type: 11,
          },
        }).then((res) => {
          if (res.errno === '200') {
            this.setState({
              orderId: res.order_id,
            })
            this.PayWeChat = setInterval(() => {
              get({ url: '/user/get_order_state/' + res.order_id }).then(
                (res) => {
                  if (res.state === 1) {
                    clearInterval(this.PayWeChat)
                    this.setState({
                      VipPayStep: 'sb',
                    })
                  }
                }
              )
            }, 2000)
          } else {
            this.setState({
              PopUp: '提示弹窗',
              MessageTip: res.errmsg + '！',
            })
          }
        })
      }
    })
  }

  // 修改信息
  PageUpDataBtn() {
    clearInterval(this.PayWeChat)
    window.location.href = '/member_peBase'
  }

  // 开通个人云程
  PeYcVipBtn() {
    let iconCheckIn = this.state.iconCheckIn
    let iconCheckAll = this.state.iconCheckAll
    let SbUser = this.state.User
    let SbAddress =
      SbUser.province === SbUser.city
        ? SbUser.city + SbUser.county + SbUser.address
        : SbUser.province + SbUser.city + SbUser.county + SbUser.address
    let aaa =
      iconCheckIn !== '' && iconCheckAll === ''
        ? 10
        : iconCheckIn === '' && iconCheckAll !== ''
        ? 11
        : 0
    this.setState({
      iconCheck: aaa,
      orderId: '',
      VipPayStep: '',
    })
    if (aaa === 10) {
      this.setState({
        WeChatPay: '1',
        isCloudVip: '',
      })
      get({ url: '/user/get_nopay_order/' + aaa }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            orderId: res.order_id,
          })
          this.PayWeChat = setInterval(() => {
            get({ url: '/user/get_order_state/' + res.order_id }).then(
              (res) => {
                if (res.state === 1) {
                  clearInterval(this.PayWeChat)
                  this.setState({
                    VipPayStep: 'sb',
                  })
                }
              }
            )
          }, 2000)
        } else {
          post({
            url: 'user/cloud_VIP_order',
            data: {
              address: SbAddress,
              mobile: SbUser.mobile,
              cloud_VIP_type: aaa,
            },
          }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                orderId: res.order_id,
              })
              this.PayWeChat = setInterval(() => {
                get({ url: '/user/get_order_state/' + res.order_id }).then(
                  (res) => {
                    if (res.state === 1) {
                      clearInterval(this.PayWeChat)
                      this.setState({
                        VipPayStep: 'sb',
                      })
                    }
                  }
                )
              }, 2000)
            } else {
              this.setState({
                PopUp: '提示弹窗',
                MessageTip: res.errmsg + '！',
              })
            }
          })
        }
      })
    } else if (aaa === 11) {
      this.setState({
        WeChatPay: '0',
        isCloudVip: '',
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请选择开通的服务类别！',
      })
    }
  }

  OutPage() {
    clearInterval(this.PayWeChat)
    get({ url: 'user/basic_info' }).then((res) => {
      if (res.errno === '200') {
        let SbUser = res.data[0]
        this.setState({
          User: SbUser,
          isCloudVip: SbUser.is_cloud_vip,
          WeChatPay: '',
        })
        if (SbUser.is_cloud_vip === 1) {
          get({ url: 'user/get_VIP_time' }).then((res) => {
            if (res.errno === '200') {
              let b = res.data[0]
              this.setState({
                VipTime: b,
                verifcatCode: res.verificat_code,
              })
            }
          })
          get({ url: 'user/show_per_cloud_MAC' }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                BindMac: res.data[0].MAc,
              })
            } else {
              this.setState({
                BindMac: 'sb',
              })
            }
          })
        }
      }
    })
  }
  OutWeChatPay() {
    clearInterval(this.PayWeChat)
    get({ url: 'user/basic_info' }).then((res) => {
      if (res.errno === '200') {
        let SbUser = res.data[0]
        this.setState({
          User: SbUser,
          isCloudVip: SbUser.is_cloud_vip,
          WeChatPay: '',
        })
        if (SbUser.is_cloud_vip === 1) {
          get({ url: 'user/get_VIP_time' }).then((res) => {
            if (res.errno === '200') {
              let b = res.data[0]
              this.setState({
                VipTime: b,
                verifcatCode: res.verificat_code,
              })
            }
          })
          get({ url: 'user/show_per_cloud_MAC' }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                BindMac: res.data[0].MAc,
              })
            } else {
              this.setState({
                BindMac: 'sb',
              })
            }
          })
        }
      }
    })
  }

  // 历史数据的返回
  DataBack() {
    this.setState({
      isCloudVip: 1,
      StartTime: '', //开始时间
      EndTime: '', //结束时间
      YCItemName: '', //科目名称
      YCItemId: '', //科目名称
      YCItemArr: [], //科目列表
      YCItemToggleOn: true,
      YCItemDisplay: 'none',
      YCAnalysisState: '',
      YCAnalysisInfo: {},
    })
  }

  // 历史数据
  HistoryData() {
    get({ url: 'user/get_all_routine_item' }).then((res) => {
      if (res.errno === '200') {
        function sortId(a, b) {
          return a.item_level - b.item_level
        }
        let sb = res.data.sort(sortId)
        this.setState({
          isCloudVip: 3,
          YCItemArr: sb,

          RadarItemArr: [],
          RadarType: '',
          RadarId: '',
          RadarName: '',
          RadarStart: '',
          RadarInfo: {},
          NavBoxType: '',
        })
        this.props.ChildBackNavClick('历史数据')
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }
  YCHistoryDataBtn() {
    let H = window.innerHeight - 360 > 200 ? window.innerHeight - 360 : 200
    let PageSize = Math.ceil(H / 32)
    let StartTime = this.state.StartTime
    let EndTime = this.state.EndTime
    let YCItemId = this.state.YCItemId
    if (StartTime !== '' && EndTime !== '' && YCItemId !== '') {
      this.setState({ YCAnalysisState: 'dsb' })
      post({
        url: 'user/train_score_query_vip',
        data: {
          item_id: YCItemId,
          start_time: StartTime,
          end_time: EndTime,
        },
      }).then((res) => {
        if (res.errno === '200') {
          let KeyStart = (this.state.CurrentPage - 1) * PageSize
          let KeyEnd = this.state.CurrentPage * PageSize
          let DownloadList = []
          if (KeyEnd <= res.data.length) {
            for (let i = KeyStart; i < KeyEnd; i++) {
              const DownloadListBrr = res.data[i]
              DownloadList.push(DownloadListBrr)
            }
          } else {
            for (let i = KeyStart; i < res.data.length; i++) {
              const DownloadListBrr = res.data[i]
              DownloadList.push(DownloadListBrr)
            }
          }
          this.setState({
            YCAnalysisState: 'h',
            HistoryDataArr: res.data,
            HistoryDataBrr: DownloadList,
            HistoryDataArrLength: res.data.length,
            PageSize: PageSize,
          })
        } else {
          this.setState({
            YCAnalysisState: 'sb',
            AnAlyErrMsg: res.errmsg + '。',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请将开始、结束时间、项目选择完整。',
        YCAnalysisState: '',
      })
    }
  }

  HistoryDataCurrentPage(CurrentPage) {
    const KeyStart = (CurrentPage - 1) * this.state.PageSize
    const KeyEnd = CurrentPage * this.state.PageSize
    const DownloadList = []
    if (KeyEnd <= this.state.HistoryDataArr.length) {
      for (let i = KeyStart; i < KeyEnd; i++) {
        const DownloadBList = this.state.HistoryDataArr[i]
        DownloadList.push(DownloadBList)
      }
    } else {
      for (let i = KeyStart; i < this.state.HistoryDataArr.length; i++) {
        const DownloadBList = this.state.HistoryDataArr[i]
        DownloadList.push(DownloadBList)
      }
    }
    this.setState({
      HistoryDataBrr: DownloadList,
      CurrentPage: CurrentPage,
    })
  }

  HistoryDataDetails = (item, index) => {
    let YCItemId = this.state.YCItemId
    post({
      url: 'user/train_score_info_query_vip',
      data: {
        mac: item.mac,
        item_id: YCItemId,
        tts_id: item.id,
      },
    }).then((res) => {
      if (res.errno === '200') {
        let Radar = {
          tts_id: item.id,
          num: 1,
          item_id: YCItemId,
        }
        this.setState({
          detailedPopUp: 'sb',
          detailedArr: res.data,
          detailedName: item.name,
          HistoryRadarInfo: Radar,
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  // 数据分析
  AnalysisData() {
    get({ url: 'user/get_all_routine_item' }).then((res) => {
      if (res.errno === '200') {
        function sortId(a, b) {
          return a.item_level - b.item_level
        }
        let sb = res.data.sort(sortId)
        this.setState({
          isCloudVip: 2,
          YCItemArr: sb,
        })
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  // 雷达图分析
  RadarData() {
    get({ url: 'user/get_all_routine_item' }).then((res) => {
      if (res.errno === '200') {
        function sortId(a, b) {
          return a.item_level - b.item_level
        }
        let sb = res.data.sort(sortId)
        this.setState({
          isCloudVip: 4,
          RadarItemArr: sb,

          NavBoxType: '',
          StartTime: '', //开始时间
          EndTime: '', //结束时间
          YCItemName: '', //科目名称
          YCItemId: '', //科目名称
          YCItemArr: [], //科目列表
          YCItemToggleOn: true,
          YCItemDisplay: 'none',
          YCAnalysisState: '',
          YCAnalysisInfo: {},
        })
        this.props.ChildBackNavClick('数据分析')
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg + '！',
        })
      }
    })
  }

  // 雷达图项目显示按钮
  RadarItemNavBtn(e) {
    e.nativeEvent.stopImmediatePropagation()
    let NavBoxType = this.state.NavBoxType
    if (NavBoxType === '') {
      this.setState({
        NavBoxType: 'sb',
      })
    } else {
      this.setState({
        NavBoxType: '',
      })
    }
  }

  // 1是五边0是竞速
  RadarItemClick = (item, index) => {
    this.setState({
      RadarId: item.id,
      RadarName: item.name,
      RadarStart: 'dsb',
      NavBoxType: 'sb',
    })
    if (item.time_score === 0) {
      post({
        url: 'cloudprogram/stu_score_analy_pentagon',
        data: {
          item_id: item.id,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({ RadarStart: 'sb' })
          let data = res.data
          let a = []
          for (let i = 0; i < data.length; i++) {
            let q = data[i].value
            let b = Math.floor(q * 1000) / 1000
            a.push(b)
            data[i]['max'] = data[i].max_constraint
          }
          let Radar = {
            info: data,
            value: a,
            num: 0,
            item_id: item.id,
            item_name: item.name,
          }
          this.setState({ RadarInfo: Radar })
        } else if (res.errno === '4502') {
          window.location.href = '/login'
        } else {
          this.setState({
            RadarStart: res.errmsg,
          })
        }
      })
    } else if (item.time_score === 1) {
      post({
        url: 'cloudprogram/stu_score_analy_pentagon_score',
        data: {
          item_id: item.id,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({ RadarStart: 'sb' })
          let data = res.data
          let a = []
          for (let i = 0; i < data.length; i++) {
            let q = data[i].value
            let b = Math.floor(q * 1000) / 1000
            a.push(b)
            data[i]['max'] = data[i].max_constraint
          }
          let Radar = {
            info: data,
            value: a,
            num: 1,
            item_id: item.id,
            item_name: item.name,
          }
          this.setState({ RadarInfo: Radar })
        } else if (res.errno === '4502') {
          window.location.href = '/login'
        } else {
          this.setState({
            RadarStart: res.errmsg,
          })
        }
      })
    } else {
      this.setState({
        RadarStart: '暂无该项目数据。',
      })
    }
  }

  RadarDataBack() {
    this.setState({
      isCloudVip: 1,
      RadarItemArr: [],
      RadarType: '',
      RadarId: '',
      RadarName: '',
      RadarStart: '',
      RadarInfo: {},
      NavBoxType: '',
    })
  }

  StartTime = (event) => {
    this.setState({
      StartTime: event,
    })
  }
  EndTime = (event) => {
    this.setState({
      EndTime: event,
    })
  }

  YCItemDown(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      YCItemToggleOn: !prevState.YCItemToggleOn,
      YCItemDisplay: prevState.YCItemToggleOn ? 'block' : 'none',
    }))
  }

  YCItemClick = (item, index) => {
    this.setState({
      YCItemId: item.id,
      YCItemName: item.name,
      YCItemToggleOn: true,
      YCItemDisplay: 'none',
      HistoryFive: item.time_score,
    })
  }

  YCButton() {
    let StartTime = this.state.StartTime
    let EndTime = this.state.EndTime
    let YCItemId = this.state.YCItemId
    let YCItemName = this.state.YCItemName
    let TheStuId = this.state.User.user_id
    if (StartTime !== '' && EndTime !== '' && YCItemName !== '') {
      this.setState({ YCAnalysisState: 'dsb' })
      let SelectInfo = {
        StartTime: StartTime,
        EndTime: EndTime,
        YCItemId: YCItemId,
        YCItemName: YCItemName,
        TheStuId: TheStuId,
      }
      post({
        url: 'cloudprogram/cloud_stu_score_analy_ber',
        data: {
          item_id: YCItemId,
          start_time: StartTime,
          end_time: EndTime,
          stu_id: TheStuId,
          ti_id: '',
        },
      }).then((res) => {
        if (res.errno === '200') {
          post({
            url: 'cloudprogram/cloud_stu_score_analy_chart_ber',
            data: {
              item_id: YCItemId,
              start_time: StartTime,
              end_time: EndTime,
              stu_id: TheStuId,
              ti_id: '',
            },
          }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                YCAnalysisState: 'y',
                YCAnalysisInfo: SelectInfo,
              })
            } else {
              this.setState({
                YCAnalysisState: 'sb',
                AnAlyErrMsg: res.errmsg + '。',
              })
            }
          })
        } else {
          this.setState({
            YCAnalysisState: 'sb',
            AnAlyErrMsg: res.errmsg + '。',
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请将开始、结束时间、项目选择完整。',
        YCAnalysisState: '',
      })
    }
  }

  EChartsBackChild = (event) => {
    if (event === 'Err') {
      this.setState({
        YCAnalysisState: 'sb',
        AnAlyErrMsg: '暂无数据，请重新选择其他项目。',
      })
    } else if (event === 'Login') {
      window.location.href = '/login'
    }
  }

  RadarBackChild = (event) => {
    if (event === '关闭菜单') {
      this.setState({
        NavBoxType: 'sb',
      })
    }
  }

  // 解绑
  delBind() {
    this.setState({
      WeChatPay: 'jb',
      isCloudVip: '',
    })
  }
  delBindBtn() {
    let verifcatCode = this.state.verifcatCode
    post({
      url: 'plugin/MAC_relieve_bind_cloud',
      data: {
        verificat_code: verifcatCode,
      },
    }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg,
        })
        get({ url: 'user/basic_info' }).then((res) => {
          if (res.errno === '200') {
            let SbUser = res.data[0]
            this.setState({
              User: SbUser,
              isCloudVip: SbUser.is_cloud_vip,
              WeChatPay: '',
            })
            if (SbUser.is_cloud_vip === 1) {
              get({ url: 'user/get_VIP_time' }).then((res) => {
                if (res.errno === '200') {
                  let b = res.data[0]
                  this.setState({
                    VipTime: b,
                    verifcatCode: res.verificat_code,
                  })
                }
              })
              get({ url: 'user/show_per_cloud_MAC' }).then((res) => {
                if (res.errno === '200') {
                  this.setState({
                    BindMac: res.data[0].MAc,
                  })
                } else {
                  this.setState({
                    BindMac: 'sb',
                  })
                }
              })
            }
          }
        })
      } else if (res.errno === '4502') {
        window.location.href = '/login'
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: res.errmsg,
        })
      }
    })
  }

  // 续费
  PeYcVipXuBtn() {
    this.setState({
      WeChatPay: '2',
      isCloudVip: '',
      iconCheckIn: '',
      iconCheckAll: '',
      VipPayStep: '',
    })
  }
  PageXuOkBtn() {
    let SbUser = this.state.User
    let SbAddress =
      SbUser.province === SbUser.city
        ? SbUser.city + SbUser.county + SbUser.address
        : SbUser.province + SbUser.city + SbUser.county + SbUser.address
    this.setState({
      orderId: '',
      VipPayStep: '',
    })
    get({ url: '/user/get_nopay_order/14' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          orderId: res.order_id,
          WeChatPay: 'xf',
        })
        this.PayWeChat = setInterval(() => {
          get({ url: '/user/get_order_state/' + res.order_id }).then((res) => {
            if (res.state === 1) {
              clearInterval(this.PayWeChat)
              this.setState({
                VipPayStep: 'sb',
              })
            }
          })
        }, 2000)
      } else {
        post({
          url: 'user/cloud_VIP_order_renew',
          data: {
            address: SbAddress,
            mobile: SbUser.mobile,
            cloud_VIP_type: 14,
          },
        }).then((res) => {
          if (res.errno === '200') {
            this.setState({
              orderId: res.order_id,
              WeChatPay: 'xf',
            })
            this.PayWeChat = setInterval(() => {
              get({ url: '/user/get_order_state/' + res.order_id }).then(
                (res) => {
                  if (res.state === 1) {
                    clearInterval(this.PayWeChat)
                    this.setState({
                      VipPayStep: 'sb',
                    })
                  }
                }
              )
            }, 2000)
          } else {
            this.setState({
              PopUp: '提示弹窗',
              MessageTip: res.errmsg,
            })
          }
        })
      }
    })
  }

  // 开票
  MakeInvoice() {
    this.setState({
      WeChatPay: 'kp',
      isCloudVip: '',
      VipPayStep: '',
    })
  }
  InvoiceTitle(event) {
    //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
    this.setState({
      InvoiceTitle: event.target.value,
    })
  }
  InvoiceNumber(event) {
    //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
    this.setState({
      InvoiceNumber: event.target.value,
    })
  }
  InvoiceOkBtn() {
    let InvoiceTitle = this.state.InvoiceTitle
    let InvoiceNumber = this.state.InvoiceNumber
    let orderId = this.state.orderId
    if (InvoiceTitle !== '' && InvoiceNumber !== '') {
      post({
        url: 'user/add_invoice',
        data: {
          order_id: orderId,
          invoice_type: 0,
          invoice_top: InvoiceTitle,
          invoice_code: InvoiceNumber,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            VipPayStep: 'kp',
          })
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg,
          })
        }
      })
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请将信息填写完整。',
      })
    }
  }

  CrossOut() {
    this.setState({
      detailedPopUp: '',
      detailedName: '',
      detailedArr: [],
    })
  }

  //提示弹窗
  ChildBackTip = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    }
  }

  render() {
    return (
      // 服务displaynone
      <div style={{ fontSize: '36px', color: 'red', margin: '200px' }}>
        线上服务暂未开放,请耐心等待。。。
        <div
          className={'MemberPackageBox'}
          style={{
            height: window.innerHeight - 180,
            position: 'relative',
            display: 'none',
          }}
        >
          {/*未开通个人云程服务*/}
          {this.state.isCloudVip === 0 && (
            <div style={{ padding: '20px 40px' }}>
              <div
                className={'TrainDataMain'}
                style={{
                  width: '100%',
                  border: '1px solid #dddddd',
                  borderRadius: '7px',
                }}
              >
                <div className={'PeYcVipTitle'}>开通云程服务</div>
                <div
                  className={'PeYcVipTitle clearfix'}
                  style={{ fontSize: 18 }}
                >
                  <div className={'clearfix'}>
                    {this.state.iconCheckIn === '' && (
                      <div
                        className={'iconCheckBox'}
                        style={{ float: 'left' }}
                        onClick={this.iconCheckIn.bind(this)}
                      >
                        {' '}
                      </div>
                    )}
                    {this.state.iconCheckIn === 'in' && (
                      <div
                        className={'css-icon icon-check iconCheck'}
                        style={{ float: 'left' }}
                        onClick={this.iconCheckIn.bind(this)}
                      >
                        {' '}
                      </div>
                    )}
                    <div
                      style={{
                        float: 'left',
                        margin: '0 100px 0 16px',
                        minWidth: 180,
                      }}
                    >
                      云程服务
                    </div>
                    <div style={{ float: 'left', color: '#e97e47' }}>
                      ￥{this.state.PayNum1}
                    </div>
                  </div>
                  <div className={'PeYcVipPayTip clearfix'}>
                    <div style={{ float: 'left' }}>服务内容：</div>
                    <div style={{ float: 'left', width: 600 }}>
                      ·服务内容 &nbsp;&nbsp;&nbsp;·服务内容
                    </div>
                  </div>
                </div>
                <div
                  className={'PeYcVipTitle clearfix'}
                  style={{ fontSize: 18 }}
                >
                  <div className={'clearfix'}>
                    {this.state.iconCheckAll === '' && (
                      <div
                        className={'iconCheckBox'}
                        style={{ float: 'left' }}
                        onClick={this.iconCheckAll.bind(this)}
                      >
                        {' '}
                      </div>
                    )}
                    {this.state.iconCheckAll === 'all' && (
                      <div
                        className={'css-icon icon-check iconCheck'}
                        style={{ float: 'left' }}
                        onClick={this.iconCheckAll.bind(this)}
                      >
                        {' '}
                      </div>
                    )}
                    <div
                      style={{
                        float: 'left',
                        margin: '0 100px 0 16px',
                        minWidth: 180,
                      }}
                    >
                      云程服务套装
                    </div>
                    <div style={{ float: 'left', color: '#e97e47' }}>
                      ￥{this.state.PayNum2}
                    </div>
                  </div>
                  <div className={'PeYcVipPayTip clearfix'}>
                    <div style={{ float: 'left' }}>服务内容：</div>
                    <div style={{ float: 'left', width: 600 }}>
                      ·服务内容 &nbsp;&nbsp;&nbsp;·服务内容
                    </div>
                  </div>
                </div>
                <div
                  className={'PeYcVipTitle clearfix'}
                  style={{ fontSize: 18 }}
                >
                  <div
                    className={'PeYcVipBtn'}
                    onClick={this.PeYcVipBtn.bind(this)}
                  >
                    开通
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*开通云程服务后的展示页面*/}
          {this.state.isCloudVip === 1 && (
            <div style={{ padding: '20px 40px' }}>
              <div
                className={'TrainDataMain'}
                style={{
                  width: '100%',
                  border: '1px solid #dddddd',
                  borderRadius: '7px',
                }}
              >
                <div className={'PeYcVipTitle'}>您已开通个人云程服务</div>
                <div className={'PeYcVipList clearfix'}>
                  服务起止时间：&nbsp;&nbsp;
                  {this.state.verifcatCode !== '' && (
                    <span style={{ color: '#000' }}>
                      {this.state.VipTime.vip_create}&nbsp;&nbsp;至&nbsp;&nbsp;
                      {this.state.VipTime.vip_expire}
                    </span>
                  )}
                </div>
                <div className={'PeYcVipList clearfix'}>
                  激活码：&nbsp;&nbsp;
                  <span style={{ color: '#000' }}>
                    {this.state.verifcatCode}
                  </span>
                </div>
                {this.state.User.isvalid === 1 &&
                  this.state.BindMac !== '' &&
                  this.state.BindMac !== 'sb' && (
                    <div
                      className={'PeYcVipTitle clearfix'}
                      style={{ height: 40 }}
                    >
                      <div
                        className={'PeYcVipBtn2'}
                        onClick={this.RadarData.bind(this)}
                      >
                        数据分析<div className={'PeYcVipBtnIco1'}> </div>
                      </div>
                      <div
                        className={'PeYcVipBtn2'}
                        onClick={this.HistoryData.bind(this)}
                      >
                        历史数据<div className={'PeYcVipBtnIco2'}> </div>
                      </div>
                    </div>
                  )}
                {this.state.BindMac === '' && (
                  <div
                    className={'PeYcVipTitle clearfix'}
                    style={{ height: 40 }}
                  >
                    {' '}
                  </div>
                )}
                {this.state.BindMac === 'sb' && (
                  <div
                    className={'PeYcVipTitle clearfix'}
                    style={{ height: 40 }}
                  >
                    <div
                      className={'PeYcVipBtn2'}
                      style={{ backgroundColor: '#ddd', cursor: 'no-drop' }}
                    >
                      数据分析<div className={'PeYcVipBtnIco1'}> </div>
                    </div>
                    <div
                      className={'PeYcVipBtn2'}
                      style={{ backgroundColor: '#ddd', cursor: 'no-drop' }}
                    >
                      历史数据<div className={'PeYcVipBtnIco2'}> </div>
                    </div>
                  </div>
                )}
                {/*<div className={'PeYcVipList clearfix'}>*/}
                {/*    <span style={{color:'#0488CA',cursor:'pointer',}} onClick={this.HistoryData.bind(this)}>*/}
                {/*        历史数据*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className={'PeYcVipList clearfix'}>*/}
                {/*    <span style={{color:'#0488CA',cursor:'pointer',}} onClick={this.RadarData.bind(this)}>*/}
                {/*        数据分析*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className={'PeYcVipList clearfix'}>*/}
                {/*    <span style={{color:'#0488CA',cursor:'pointer',}} onClick={this.AnalysisData.bind(this)}>*/}
                {/*        数据分析*/}
                {/*    </span>*/}
                {/*</div>*/}
                <div className={'PeYcVipList clearfix'}>
                  <span
                    style={{ color: '#0488CA', cursor: 'pointer' }}
                    onClick={this.PeYcVipXuBtn.bind(this)}
                  >
                    续费
                  </span>
                  {this.state.User.isvalid === 1 &&
                    this.state.BindMac !== '' &&
                    this.state.BindMac !== 'sb' && (
                      <span
                        style={{
                          color: '#fd4e44',
                          cursor: 'pointer',
                          marginLeft: 20,
                        }}
                        onClick={this.delBind.bind(this)}
                      >
                        解绑
                      </span>
                    )}
                  {this.state.BindMac === 'sb' && (
                    <span
                      style={{ color: '#ff1111', marginLeft: 20, fontSize: 13 }}
                    >
                      *请及时绑定相关设备以使用云程服务。
                    </span>
                  )}
                  {/*<div className={'PeYcVipBtn'} onClick={this.PeYcVipXuBtn.bind(this)}>续费</div>*/}
                </div>
              </div>
            </div>
          )}

          {/*传统数据分析*/}
          {this.state.isCloudVip === 2 && (
            <div>
              <div
                className={'DetailsPackageTop clearfix'}
                style={{ borderBottom: '2px solid #DDDDDD', padding: '6px 0' }}
              >
                <div
                  className={'DetailsBackBox clearfix'}
                  style={{ height: 20, marginTop: 4 }}
                  onClick={this.DataBack.bind(this)}
                >
                  <div
                    className={'DetailsBackIco'}
                    style={{ width: 20, height: 20 }}
                  >
                    {' '}
                  </div>
                  <div
                    className={'DetailsBackWord'}
                    style={{
                      height: 20,
                      lineHeight: '20px',
                      fontSize: 16,
                      marginLeft: 6,
                    }}
                  >
                    返回
                  </div>
                </div>
              </div>
              <div className={'YunChengSelectBox'}>
                <div className={'YunChengTimeBox'}>
                  <DatePicker
                    Type={'开始'}
                    BackTimeClick={this.StartTime.bind(this)}
                  />
                </div>
                <div className={'YunChengTimeBox'}>
                  <DatePicker
                    Type={'结束'}
                    BackTimeClick={this.EndTime.bind(this)}
                  />
                </div>
                <div className={'YunChengItemBox clearfix'}>
                  <div
                    className={'YCItemName'}
                    onClick={this.YCItemDown.bind(this)}
                  >
                    {this.state.YCItemName === ''
                      ? '请选择项目'
                      : this.state.YCItemName}
                  </div>
                  <div
                    className={'YCItemIco'}
                    onClick={this.YCItemDown.bind(this)}
                  >
                    <div className={'icon-arrow-down'}> </div>
                  </div>
                  <div
                    className={'YCItemNavBox scrollBar'}
                    style={{ display: this.state.YCItemDisplay }}
                  >
                    {this.state.YCItemArr.map((item, index) => (
                      <div
                        className={'YCItemNav'}
                        key={index}
                        onClick={() => this.YCItemClick(item, index)}
                        style={
                          this.state.YCItemName === item.name
                            ? { backgroundColor: '#6DC1FF' }
                            : {}
                        }
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={'YCButton'} onClick={this.YCButton.bind(this)}>
                  确认
                </div>
              </div>
              <div
                className={'scrollBar'}
                style={{ height: window.innerHeight - 296 }}
              >
                {this.state.YCAnalysisState === '' && (
                  <div style={{ padding: '160px 0' }}>
                    <div className={'TrainDataMain'}>
                      <div className={'keyNumLogo'}> </div>
                      <div className={'keyNumTitle'}>
                        请将开始时间、结束时间、项目选择完整进行数据分析。
                      </div>
                    </div>
                  </div>
                )}
                {this.state.YCAnalysisState === 'sb' && (
                  <div style={{ padding: '160px 0' }}>
                    <div className={'TrainDataMain'}>
                      <div className={'keyNumLogo'}> </div>
                      <div className={'keyNumTitle'}>
                        {this.state.AnAlyErrMsg}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.YCAnalysisState === 'dsb' && (
                  <div style={{ padding: '160px 0' }}>
                    <div className={'TrainDataMain'}>
                      <div className={'keyNumLogo'}> </div>
                      <div
                        className={'keyNumTitle'}
                        style={{ fontSize: 28, color: '#094' }}
                      >
                        正在加载···
                      </div>
                    </div>
                  </div>
                )}
                {this.state.YCAnalysisState === 'y' && (
                  <PeYCECharts
                    EChartsBackChild={this.EChartsBackChild.bind(this)}
                    SelectInfo={this.state.YCAnalysisInfo}
                  />
                )}
              </div>
            </div>
          )}

          {/*数据分析雷达图*/}
          {this.state.isCloudVip === 4 && (
            <div>
              <div
                className={'DetailsPackageTop'}
                style={{ borderBottom: '2px solid #DDDDDD', padding: '6px 0' }}
              >
                <div
                  className={'SwitchBtn'}
                  onClick={this.HistoryData.bind(this)}
                >
                  切换到 历史数据
                </div>
                <div
                  className={'DetailsBackBox clearfix'}
                  style={{ height: 20, marginTop: 4 }}
                  onClick={this.RadarDataBack.bind(this)}
                >
                  <div
                    className={'DetailsBackIco'}
                    style={{ width: 20, height: 20 }}
                  >
                    {' '}
                  </div>
                  <div
                    className={'DetailsBackWord'}
                    style={{
                      height: 20,
                      lineHeight: '20px',
                      fontSize: 16,
                      marginLeft: 6,
                    }}
                  >
                    返回
                  </div>
                </div>
              </div>
              <div
                className={'clearfix'}
                style={{
                  height: window.innerHeight - 220,
                  position: 'relative',
                }}
              >
                <div
                  className={'RadarItemNavBox'}
                  style={
                    this.state.NavBoxType === ''
                      ? { height: window.innerHeight - 220, zIndex: 99 }
                      : {
                          height: window.innerHeight - 220,
                          width: 0,
                          borderRight: '1px solid #666',
                        }
                  }
                >
                  <div
                    className={'RadarItemNav scrollBar'}
                    style={{ boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5' }}
                  >
                    {this.state.RadarItemArr.map((item, index) => (
                      <div
                        className={'RadarItemNavList'}
                        key={index}
                        onClick={() => this.RadarItemClick(item, index)}
                        style={
                          this.state.RadarId === item.id
                            ? {
                                backgroundColor: '#fdad4d',
                                borderRightColor: '#60c7ef',
                                color: '#333',
                                fontSize: 15,
                              }
                            : {}
                        }
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                  <div
                    className={'trapezoid'}
                    onClick={this.RadarItemNavBtn.bind(this)}
                  >
                    {this.state.NavBoxType === '' && (
                      <Triangle
                        Direction={'left'}
                        Color={'#FFFFFF'}
                        Width={'4.5px'}
                        Height={'8px'}
                        Top={'17px'}
                        Right={'6.5px'}
                      />
                    )}
                    {this.state.NavBoxType !== '' && (
                      <Triangle
                        Direction={'right'}
                        Color={'#FFFFFF'}
                        Width={'4.5px'}
                        Height={'8px'}
                        Top={'17px'}
                        Right={'1.5px'}
                      />
                    )}
                  </div>
                </div>
                {this.state.RadarStart !== 'sb' && (
                  <div
                    className={'RadarStart scrollBar'}
                    style={this.state.NavBoxType === '' ? {} : { width: 996 }}
                  >
                    {this.state.RadarStart === '' && (
                      <div style={{ padding: '160px 0' }}>
                        <div
                          className={'TrainDataMain'}
                          style={{ width: 'auto' }}
                        >
                          <div className={'keyNumLogo'}> </div>
                          <div
                            className={'keyNumTitle'}
                            style={{ fontSize: 26 }}
                          >
                            请选择项目查看雷达图
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.RadarStart !== '' &&
                      this.state.RadarStart !== 'sb' &&
                      this.state.RadarStart !== 'dsb' && (
                        <div style={{ padding: '160px 0' }}>
                          <div
                            className={'TrainDataMain'}
                            style={{ width: 'auto' }}
                          >
                            <div className={'keyNumLogo'}> </div>
                            <div
                              className={'keyNumTitle'}
                              style={{ fontSize: 26 }}
                            >
                              {this.state.RadarName}
                            </div>
                            <div
                              className={'keyNumTitle'}
                              style={{ fontSize: 26, marginTop: 20 }}
                            >
                              {this.state.RadarStart}
                            </div>
                          </div>
                        </div>
                      )}
                    {this.state.RadarStart === 'dsb' && (
                      <div style={{ padding: '160px 0' }}>
                        <div
                          className={'TrainDataMain'}
                          style={{ width: 'auto' }}
                        >
                          <div className={'keyNumLogo'}> </div>
                          <div
                            className={'keyNumTitle'}
                            style={{ fontSize: 26, color: '#094' }}
                          >
                            {this.state.RadarName}
                          </div>
                          <div
                            className={'keyNumTitle'}
                            style={{
                              fontSize: 26,
                              color: '#094',
                              marginTop: 20,
                            }}
                          >
                            数据正在加载···
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {this.state.RadarStart === 'sb' && (
                  <div
                    className={'RadarStart scrollBar'}
                    style={{ width: 996 }}
                  >
                    <RadarECharts
                      RadarBackChild={this.RadarBackChild.bind(this)}
                      RadarInfo={this.state.RadarInfo}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/*历史数据*/}
          {this.state.isCloudVip === 3 && (
            <div>
              <div
                className={'DetailsPackageTop'}
                style={{ borderBottom: '2px solid #DDDDDD', padding: '6px 0' }}
              >
                <div
                  className={'SwitchBtn'}
                  onClick={this.RadarData.bind(this)}
                >
                  切换到 数据分析
                </div>
                <div
                  className={'DetailsBackBox clearfix'}
                  style={{ height: 20, marginTop: 4 }}
                  onClick={this.DataBack.bind(this)}
                >
                  <div
                    className={'DetailsBackIco'}
                    style={{ width: 20, height: 20 }}
                  >
                    {' '}
                  </div>
                  <div
                    className={'DetailsBackWord'}
                    style={{
                      height: 20,
                      lineHeight: '20px',
                      fontSize: 16,
                      marginLeft: 6,
                    }}
                  >
                    返回
                  </div>
                </div>
              </div>
              <div
                className={'YunChengSelectBox'}
                style={{ margin: '10px auto' }}
              >
                <div className={'YunChengTimeBox'}>
                  <DatePicker
                    Type={'开始'}
                    BackTimeClick={this.StartTime.bind(this)}
                  />
                </div>
                <div className={'YunChengTimeBox'}>
                  <DatePicker
                    Type={'结束'}
                    BackTimeClick={this.EndTime.bind(this)}
                  />
                </div>
                <div className={'YunChengItemBox clearfix'}>
                  <div
                    className={'YCItemName'}
                    onClick={this.YCItemDown.bind(this)}
                  >
                    {this.state.YCItemName === ''
                      ? '请选择项目'
                      : this.state.YCItemName}
                  </div>
                  <div
                    className={'YCItemIco'}
                    onClick={this.YCItemDown.bind(this)}
                  >
                    <div className={'icon-arrow-down'}> </div>
                  </div>
                  <div
                    className={'YCItemNavBox scrollBar'}
                    style={{ display: this.state.YCItemDisplay }}
                  >
                    {this.state.YCItemArr.map((item, index) => (
                      <div
                        className={'YCItemNav'}
                        key={index}
                        onClick={() => this.YCItemClick(item, index)}
                        style={
                          this.state.YCItemName === item.name
                            ? { backgroundColor: '#6DC1FF' }
                            : {}
                        }
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={'YCButton'}
                  onClick={this.YCHistoryDataBtn.bind(this)}
                >
                  确认
                </div>
              </div>
              <div
                className={'scrollBar'}
                style={{ height: window.innerHeight - 276 }}
              >
                {this.state.YCAnalysisState === '' && (
                  <div style={{ padding: '160px 0' }}>
                    <div className={'TrainDataMain'}>
                      <div className={'keyNumLogo'}> </div>
                      <div className={'keyNumTitle'}>
                        请将开始时间、结束时间、项目选择完整进行历史数据查询。
                      </div>
                    </div>
                  </div>
                )}
                {this.state.YCAnalysisState === 'sb' && (
                  <div style={{ padding: '160px 0' }}>
                    <div className={'TrainDataMain'}>
                      <div className={'keyNumLogo'}> </div>
                      <div className={'keyNumTitle'}>
                        {this.state.AnAlyErrMsg}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.YCAnalysisState === 'dsb' && (
                  <div style={{ padding: '160px 0' }}>
                    <div className={'TrainDataMain'}>
                      <div className={'keyNumLogo'}> </div>
                      <div
                        className={'keyNumTitle'}
                        style={{ fontSize: 28, color: '#094' }}
                      >
                        正在加载···
                      </div>
                    </div>
                  </div>
                )}
                {this.state.YCAnalysisState === 'h' && (
                  <div
                    style={{
                      height:
                        window.innerHeight - 280 > 300
                          ? window.innerHeight - 280
                          : 300,
                      margin: '0 auto',
                      width: 920,
                      border: '1px solid #999',
                    }}
                  >
                    <div
                      className={'MainListBox clearfix'}
                      style={{
                        backgroundColor: '#F1F4FA',
                        position: 'relative',
                        width: 920,
                        height: 32,
                        lineHeight: '32px',
                      }}
                    >
                      <div className={'MainList'} style={{ width: 100 }}>
                        序号
                      </div>
                      <div className={'MainList'} style={{ width: 350 }}>
                        日期
                      </div>
                      <div className={'MainList'} style={{ width: 350 }}>
                        成绩/时间
                      </div>
                      <div className={'MainList'} style={{ width: 120 }}>
                        操作
                      </div>
                    </div>
                    <div
                      className={'MainContentBox'}
                      style={{
                        height:
                          window.innerHeight - 360 > 200
                            ? window.innerHeight - 360
                            : 200,
                      }}
                    >
                      {this.state.HistoryDataBrr.map((item, index) => (
                        <div
                          className={'MainListBox clearfix'}
                          key={index}
                          style={{ width: 920, height: 30, lineHeight: '30px' }}
                        >
                          <div className={'MainList'} style={{ width: 100 }}>
                            {this.state.PageSize *
                              (this.state.CurrentPage - 1) +
                              (index + 1)}
                          </div>
                          <div className={'MainList'} style={{ width: 350 }}>
                            {item.uptime}
                          </div>
                          {item.time_score === 0 && (
                            <div className={'MainList'} style={{ width: 350 }}>
                              {item.total_time !== ''
                                ? item.total_time + '秒'
                                : item.iscrash === 1
                                ? '坠机'
                                : item.iscrash === 2
                                ? '违规'
                                : '异常'}
                            </div>
                          )}
                          {item.time_score === 1 && (
                            <div className={'MainList'} style={{ width: 350 }}>
                              {item.total_score !== ''
                                ? item.total_score + '分'
                                : item.iscrash === 1
                                ? '坠机'
                                : item.iscrash === 2
                                ? '违规'
                                : '异常'}
                            </div>
                          )}
                          {item.time_score === 2 && (
                            <div className={'MainList'} style={{ width: 350 }}>
                              {item.total_score !== ''
                                ? item.total_score + '分'
                                : item.iscrash === 1
                                ? '坠机'
                                : item.iscrash === 2
                                ? '违规'
                                : '异常'}{' '}
                              /{' '}
                              {item.total_time !== ''
                                ? item.total_time + '秒'
                                : item.iscrash === 1
                                ? '坠机'
                                : item.iscrash === 2
                                ? '违规'
                                : '异常'}
                            </div>
                          )}
                          <div className={'MainList'} style={{ width: 120 }}>
                            <span
                              className={'ClickDetails'}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                this.HistoryDataDetails(item, index)
                              }
                            >
                              查看详情
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    {this.state.HistoryDataArrLength !== 0 && (
                      <Pagination
                        TotalSize={this.state.HistoryDataArrLength}
                        PageSize={this.state.PageSize}
                        pageCallbackFn={this.HistoryDataCurrentPage.bind(this)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/*历史数据详情弹窗*/}
          {this.state.YCAnalysisState === 'h' &&
            this.state.detailedPopUp !== '' && (
              <div className={'PopUpBoxBg'} style={{ minHeight: 520 }}>
                {this.state.HistoryFive === 0 && (
                  <div
                    className={'AddItemBox'}
                    style={{ width: 1100, height: 700 }}
                  >
                    <div className={'AddItemBigTitle'}>
                      <div>{this.state.detailedName}数据监测详情：</div>
                      <div
                        className={'AddItemBackIco'}
                        onClick={this.CrossOut.bind(this)}
                      >
                        {' '}
                      </div>
                    </div>
                    <div
                      className={'AddItemMainListBox clearfix'}
                      style={{ width: 1100 }}
                    >
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 275, color: '#333' }}
                      >
                        名称
                      </div>
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 275, color: '#333' }}
                      >
                        数据
                      </div>
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 275, color: '#333' }}
                      >
                        分值
                      </div>
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 272, color: '#333' }}
                      >
                        时间
                      </div>
                    </div>
                    <div
                      className={'AddItemMainBox scrollBar'}
                      style={{ width: 1100, height: 640 }}
                    >
                      {this.state.detailedArr.map((item, index) => (
                        <div
                          className={'AddItemMainListBox clearfix'}
                          key={index}
                          style={{
                            backgroundColor: '#fff',
                            marginTop: '2px',
                            width: 1100,
                          }}
                        >
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 275, color: '#333', fontSize: 13 }}
                          >
                            {item.monitor_name}
                          </div>
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 275, color: '#333', fontSize: 12 }}
                          >
                            {item.actual_data === '' ? '/' : item.actual_data}
                          </div>
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 275, color: '#333', fontSize: 13 }}
                          >
                            {item.score_value === '' ? '/' : item.score_value}
                          </div>
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 272, color: '#333', fontSize: 13 }}
                          >
                            {item.ctime}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {this.state.HistoryFive === 1 && (
                  <div
                    className={'AddItemBox clearfix'}
                    style={{ width: 1100, height: 700 }}
                  >
                    <div className={'AddItemBigTitle'}>
                      <div>{this.state.detailedName}数据监测详情：</div>
                      <div
                        className={'AddItemBackIco'}
                        onClick={this.CrossOut.bind(this)}
                      >
                        {' '}
                      </div>
                    </div>
                    <HistoryECharts
                      RadarBackChild={this.RadarBackChild.bind(this)}
                      HistoryRadarInfo={this.state.HistoryRadarInfo}
                    />
                    <div
                      className={'AddItemMainListBox clearfix'}
                      style={{ width: 800, float: 'right' }}
                    >
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 200, color: '#333' }}
                      >
                        名称
                      </div>
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 190, color: '#333' }}
                      >
                        数据
                      </div>
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 190, color: '#333' }}
                      >
                        分值
                      </div>
                      <div
                        className={'AddItemMainList'}
                        style={{ width: 216, color: '#333' }}
                      >
                        时间
                      </div>
                    </div>
                    <div
                      className={'AddItemMainBox scrollBar'}
                      style={{ width: 800, height: 640 }}
                    >
                      {this.state.detailedArr.map((item, index) => (
                        <div
                          className={'AddItemMainListBox clearfix'}
                          key={index}
                          style={{ backgroundColor: '#fff', marginTop: '2px' }}
                        >
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 200, color: '#333', fontSize: 13 }}
                          >
                            {item.monitor_name}
                          </div>
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 190, color: '#333', fontSize: 12 }}
                          >
                            {item.actual_data === '' ? '/' : item.actual_data}
                          </div>
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 190, color: '#333', fontSize: 13 }}
                          >
                            {item.score_value === '' ? '/' : item.score_value}
                          </div>
                          <div
                            className={'AddItemMainList Ellipsis clearfix'}
                            style={{ width: 216, color: '#333', fontSize: 13 }}
                          >
                            {item.ctime}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

          {/*开通个人云程*/}
          {this.state.WeChatPay === '1' && this.state.isCloudVip === '' && (
            <div className={'NoVipBox'}>
              <div className={'NoVipTitBox clearfix'}>
                <div
                  className={'css-icon icon-close NoVipClose'}
                  onClick={this.OutWeChatPay.bind(this)}
                >
                  {' '}
                </div>
                <div className={'NoVipPeImg'}>
                  {this.state.User.image && (
                    <img src={this.state.User.image} alt={''} />
                  )}
                </div>
                <div className={'NoVipPeInfoBOx'}>
                  <div>姓名：{this.state.User.real_name}</div>
                  <div>ID：{this.state.User.yy_num}</div>
                </div>
                <div
                  className={'BaseVipBox clearfix'}
                  style={{ left: 'unset', right: 0, minWidth: '180px' }}
                >
                  <div
                    className={'NoVipPeInfoBOx'}
                    style={{
                      width: 180,
                      height: 50,
                      lineHeight: '25px',
                      fontSize: 16,
                      fontWeight: 'bold',
                      margin: '20px 0 0 0',
                      letterSpacing: '0.15em',
                    }}
                  >
                    <div>正在付款：</div>
                    <div>云程服务</div>
                  </div>
                </div>
              </div>
              <div className={'NoVipMain clearfix'}>
                <div className={'NoVipPayBox'}>
                  <div className={'NoVipPayTit'}>365天</div>
                  <div className={'NoVipPayBg'}>
                    <div className={'NoVipPayBg2'}>
                      <div
                        className={'NoVipPay'}
                        style={{ height: 132, lineHeight: '132px' }}
                      >
                        ￥
                        {this.state.iconCheck === 10
                          ? this.state.PayNum1
                          : this.state.iconCheck === 11
                          ? this.state.PayNum2
                          : '错误'}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.VipPayStep === '' && (
                  <div className={'NoVipCodeBox'}>
                    <div className={'NoVipCodeTitBox clearfix'}>
                      <div className={'SMZF'}>扫码支付</div>
                      <div className={'XZF'}>
                        需支付:
                        <span style={{ fontSize: 24, color: '#0488CA' }}>
                          ￥
                          {this.state.iconCheck === 10
                            ? this.state.PayNum1
                            : this.state.iconCheck === 11
                            ? this.state.PayNum2
                            : '错误'}
                        </span>
                      </div>
                    </div>
                    <div className={'NoVipPayCode'}>
                      {this.state.orderId !== '' && (
                        <img
                          style={{ display: 'block', width: 140, height: 140 }}
                          alt={''}
                          src={
                            API_URL +
                            '/payment/WXpay_goods_order/' +
                            this.state.orderId
                          }
                        />
                      )}
                    </div>
                    <div style={{ display: 'table', margin: '0 auto' }}>
                      <div className={'WeChatPayIco'}> </div>
                      <div className={'WeChatPayWord'}>微信支付</div>
                    </div>
                  </div>
                )}
                {this.state.VipPayStep !== '' && (
                  <div className={'NoVipCodeBox'}>
                    <div style={{ display: 'table', margin: '40px auto' }}>
                      <div
                        className={'WeChatPayIco'}
                        style={{ width: 70, height: 64 }}
                      >
                        {' '}
                      </div>
                      <div
                        className={'WeChatPayWord'}
                        style={{
                          height: 64,
                          lineHeight: '64px',
                          fontSize: 32,
                          letterSpacing: '0.2em',
                        }}
                      >
                        支付成功
                      </div>
                    </div>
                    <div
                      className={'YCMakeInvoiceBox'}
                      onClick={this.MakeInvoice.bind(this)}
                    >
                      申请开具发票
                    </div>
                  </div>
                )}
              </div>
              <div
                className={'NoVipTip'}
                style={{ textAlign: 'center', textIndent: 'unset' }}
              >
                如有任何疑问，请联系我们
              </div>
            </div>
          )}

          {/*开通个人信息确认*/}
          {this.state.WeChatPay === '0' && this.state.isCloudVip === '' && (
            <div
              className={'NoVipBox'}
              style={{
                boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5',
                height: 390,
              }}
            >
              <div
                className={'NoVipTitBox clearfix'}
                style={{ height: 40, width: '99%', margin: '3px auto' }}
              >
                <div
                  className={'css-icon icon-close NoVipClose'}
                  onClick={this.OutPage.bind(this)}
                  style={{
                    backgroundColor: 'unset',
                    color: '#fff',
                    top: 3,
                    right: 3,
                  }}
                >
                  {' '}
                </div>
                <div className={'PageTypeTitleWord'}>
                  邮寄信息：
                  <span style={{ fontSize: 17 }}>
                    信息如有变动请在{' '}
                    <span style={{ color: '#fff000' }}>
                      {' '}
                      会员中心 > 基础信息{' '}
                    </span>
                    中修改
                  </span>
                </div>
              </div>
              <div
                className={'PageTypeContent'}
                style={{ height: 'auto', minHeight: 230 }}
              >
                <div
                  className={'PageTypeContentCon'}
                  style={{
                    height: 30,
                    lineHeight: '30px',
                    textIndent: 0,
                    marginTop: 20,
                  }}
                >
                  联系人：{' '}
                  <span style={{ color: '#0488ca' }}>
                    {this.state.User.real_name}
                  </span>
                </div>
                <div
                  className={'PageTypeContentCon'}
                  style={{
                    height: 30,
                    lineHeight: '30px',
                    textIndent: 0,
                    marginTop: 20,
                  }}
                >
                  联系方式：{' '}
                  <span style={{ color: '#0488ca' }}>
                    {this.state.User.mobile}
                  </span>
                </div>
                <div
                  className={'PageTypeContentCon clearfix'}
                  style={{
                    height: 60,
                    lineHeight: '30px',
                    textIndent: 0,
                    marginTop: 20,
                    overflow: 'hidden',
                    marginBottom: 0,
                  }}
                >
                  <div style={{ float: 'left', width: 100, height: '100%' }}>
                    邮寄地址：{' '}
                  </div>
                  <div style={{ float: 'left', width: 460, color: '#0488ca' }}>
                    {this.state.Address}
                  </div>
                </div>
                <div style={{ display: 'table', margin: '0 auto' }}>
                  <div
                    className={'PageTypeContentOkBtn'}
                    onClick={this.PageOkBtn.bind(this)}
                  >
                    下一步
                  </div>
                  <div
                    className={'PageTypeContentOkBtn'}
                    onClick={this.PageUpDataBtn.bind(this)}
                    style={{ backgroundColor: '#fff000' }}
                  >
                    去修改
                  </div>
                </div>
              </div>
              <div
                className={'NoVipTip'}
                style={{ textAlign: 'center', textIndent: 'unset' }}
              >
                如有任何疑问，请联系我们
              </div>
            </div>
          )}

          {/*续费个人云程*/}
          {this.state.WeChatPay === '2' && this.state.isCloudVip === '' && (
            <div
              className={'NoVipBox'}
              style={{
                boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5',
                height: 355,
              }}
            >
              <div
                className={'NoVipTitBox clearfix'}
                style={{ height: 40, width: '99%', margin: '3px auto' }}
              >
                <div
                  className={'css-icon icon-close NoVipClose'}
                  onClick={this.OutPage.bind(this)}
                  style={{
                    backgroundColor: 'unset',
                    color: '#fff',
                    top: 3,
                    right: 3,
                  }}
                >
                  {' '}
                </div>
                <div className={'PageTypeTitleWord'}>续费说明：</div>
              </div>
              <div className={'PageTypeContent'}>
                <div
                  className={'PeYcVipTitle clearfix'}
                  style={{
                    fontSize: 18,
                    width: '92%',
                    margin: '20px auto',
                    height: 110,
                  }}
                >
                  续费说明文案。。。
                </div>
                <div style={{ display: 'table', margin: '0 auto' }}>
                  <div
                    className={'PageTypeContentOkBtn'}
                    onClick={this.PageXuOkBtn.bind(this)}
                  >
                    下一步
                  </div>
                </div>
              </div>
              <div
                className={'NoVipTip'}
                style={{ textAlign: 'center', textIndent: 'unset' }}
              >
                如有任何疑问，请联系我们
              </div>
            </div>
          )}
          {this.state.WeChatPay === 'xf' && this.state.isCloudVip === '' && (
            <div className={'NoVipBox'}>
              <div className={'NoVipTitBox clearfix'}>
                <div
                  className={'css-icon icon-close NoVipClose'}
                  onClick={this.OutWeChatPay.bind(this)}
                >
                  {' '}
                </div>
                <div className={'NoVipPeImg'}>
                  {this.state.User.image && (
                    <img src={this.state.User.image} alt={''} />
                  )}
                </div>
                <div className={'NoVipPeInfoBOx'}>
                  <div>姓名：{this.state.User.real_name}</div>
                  <div>ID：{this.state.User.yy_num}</div>
                </div>
                <div
                  className={'BaseVipBox clearfix'}
                  style={{ left: 'unset', right: 0, minWidth: '180px' }}
                >
                  <div
                    className={'NoVipPeInfoBOx'}
                    style={{
                      width: 180,
                      height: 50,
                      lineHeight: '25px',
                      fontSize: 16,
                      fontWeight: 'bold',
                      margin: '20px 0 0 0',
                      letterSpacing: '0.15em',
                    }}
                  >
                    <div>正在付款：</div>
                    <div>续费云程服务</div>
                  </div>
                </div>
              </div>
              <div className={'NoVipMain clearfix'}>
                <div className={'NoVipPayBox'}>
                  <div className={'NoVipPayTit'}>+365天</div>
                  <div className={'NoVipPayBg'}>
                    <div className={'NoVipPayBg2'}>
                      <div
                        className={'NoVipPay'}
                        style={{ height: 132, lineHeight: '132px' }}
                      >
                        ￥{this.state.PayNumXF}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.VipPayStep === '' && (
                  <div className={'NoVipCodeBox'}>
                    <div className={'NoVipCodeTitBox clearfix'}>
                      <div
                        className={'SMZF'}
                        style={{ backgroundColor: '#6bcc03', borderRadius: 7 }}
                      >
                        扫码支付
                      </div>
                      <div className={'XZF'}>
                        需支付:
                        <span style={{ fontSize: 24, color: '#0488CA' }}>
                          ￥{this.state.PayNumXF}
                        </span>
                      </div>
                    </div>
                    <div className={'NoVipPayCode'}>
                      {this.state.orderId !== '' && (
                        <img
                          style={{ display: 'block', width: 140, height: 140 }}
                          alt={''}
                          src={
                            API_URL +
                            '/payment/WXpay_goods_order/' +
                            this.state.orderId
                          }
                        />
                      )}
                    </div>
                    <div style={{ display: 'table', margin: '0 auto' }}>
                      <div className={'WeChatPayIco'}> </div>
                      <div className={'WeChatPayWord'}>微信支付</div>
                    </div>
                  </div>
                )}
                {this.state.VipPayStep !== '' && (
                  <div className={'NoVipCodeBox'}>
                    <div style={{ display: 'table', margin: '40px auto' }}>
                      <div
                        className={'WeChatPayIco'}
                        style={{ width: 70, height: 64 }}
                      >
                        {' '}
                      </div>
                      <div
                        className={'WeChatPayWord'}
                        style={{
                          height: 64,
                          lineHeight: '64px',
                          fontSize: 32,
                          letterSpacing: '0.2em',
                        }}
                      >
                        支付成功
                      </div>
                    </div>
                    <div
                      className={'YCMakeInvoiceBox'}
                      onClick={this.MakeInvoice.bind(this)}
                    >
                      申请开具发票
                    </div>
                  </div>
                )}
              </div>
              <div
                className={'NoVipTip'}
                style={{ textAlign: 'center', textIndent: 'unset' }}
              >
                如有任何疑问，请联系我们
              </div>
            </div>
          )}

          {/*开具发票*/}
          {this.state.WeChatPay === 'kp' && this.state.isCloudVip === '' && (
            <div
              className={'NoVipBox'}
              style={{ boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5' }}
            >
              <div
                className={'NoVipTitBox clearfix'}
                style={{ height: 40, width: '99%', margin: '3px auto' }}
              >
                <div
                  className={'css-icon icon-close NoVipClose'}
                  onClick={this.OutPage.bind(this)}
                  style={{
                    backgroundColor: 'unset',
                    color: '#fff',
                    top: 3,
                    right: 3,
                  }}
                >
                  {' '}
                </div>
                <div className={'PageTypeTitleWord'}>开具发票：</div>
              </div>
              {this.state.VipPayStep === '' && (
                <div className={'NoVipMain clearfix'} style={{ height: 260 }}>
                  <div className={'TrickInfoBox clearfix'}>
                    <div className={'TrickInfoTit'} style={{ width: 140 }}>
                      发票抬头：
                    </div>
                    <div
                      className={'TrickInfoWord'}
                      style={{ borderColor: '#0488CA' }}
                    >
                      <input
                        className={'TrickInfoInput'}
                        type={'text'}
                        name={'name'}
                        value={this.state.InvoiceTitle}
                        onChange={this.InvoiceTitle.bind(this)}
                        placeholder={''}
                        autoComplete={'off'}
                      />
                    </div>
                  </div>
                  <div className={'TrickInfoBox clearfix'}>
                    <div className={'TrickInfoTit'} style={{ width: 140 }}>
                      纳税人识别号：
                    </div>
                    <div
                      className={'TrickInfoWord'}
                      style={{ borderColor: '#0488CA' }}
                    >
                      <input
                        className={'TrickInfoInput'}
                        type={'text'}
                        name={'QQNum'}
                        value={this.state.InvoiceNumber}
                        onChange={this.InvoiceNumber.bind(this)}
                        placeholder={''}
                        autoComplete={'off'}
                      />
                    </div>
                  </div>
                  <div
                    className={'TrickRemarks'}
                    style={{
                      color: '#ff0000',
                      fontSize: 11,
                      fontWeight: 'bold',
                      letterSpacing: 0,
                    }}
                  >
                    *目前仅支持一次填写机会，关闭后不能重新填写；不填写发票抬头、纳税人识别号则认为不需要开具发票，望周知！
                  </div>
                  <div style={{ display: 'table', margin: '0 auto' }}>
                    <div
                      className={'PageTypeContentOkBtn'}
                      style={{ marginTop: 36 }}
                      onClick={this.InvoiceOkBtn.bind(this)}
                    >
                      确认申请
                    </div>
                  </div>
                </div>
              )}
              {this.state.VipPayStep === 'kp' && (
                <div className={'NoVipMain clearfix'} style={{ height: 260 }}>
                  <div className={'ManualSuccessIco'}> </div>
                  <div
                    className={'ManualReviewWordBox'}
                    style={{ width: 230, margin: '30px auto' }}
                  >
                    <div
                      className={'ManualReviewWord'}
                      style={{ textAlign: 'left', width: '100%' }}
                    >
                      申请成功
                    </div>
                    <div
                      className={'ManualReviewWord'}
                      style={{ textAlign: 'right', width: '100%' }}
                    >
                      请您耐心等待我们的反馈
                    </div>
                  </div>
                  <div className={'ManualReviewStep'}>
                    <div className={'ManualReviewStepHave'}> </div>
                  </div>
                  <div
                    className={'YCMakeInvoiceBox'}
                    onClick={this.OutPage.bind(this)}
                    style={{ marginTop: 30 }}
                  >
                    返回云程服务
                  </div>
                </div>
              )}
              <div
                className={'NoVipTip'}
                style={{ textAlign: 'center', textIndent: 'unset' }}
              >
                如有任何疑问，请联系我们
              </div>
            </div>
          )}

          {/*解除绑定*/}
          {this.state.WeChatPay === 'jb' && this.state.isCloudVip === '' && (
            <div
              className={'NoVipBox'}
              style={{
                boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5',
                height: 380,
              }}
            >
              <div
                className={'NoVipTitBox clearfix'}
                style={{ height: 40, width: '99%', margin: '3px auto' }}
              >
                <div
                  className={'css-icon icon-close NoVipClose'}
                  onClick={this.OutPage.bind(this)}
                  style={{
                    backgroundColor: 'unset',
                    color: '#fff',
                    top: 3,
                    right: 3,
                  }}
                >
                  {' '}
                </div>
                <div className={'PageTypeTitleWord'}>解除绑定操作：</div>
              </div>
              <div
                className={'PageTypeContent'}
                style={{ height: 255, position: 'relative' }}
              >
                <div
                  className={'PeYcVipTitle clearfix'}
                  style={{
                    fontSize: 18,
                    width: '92%',
                    margin: '20px auto',
                    height: 150,
                  }}
                >
                  解除绑定文案说明。。。
                </div>
                <div
                  style={{
                    display: 'table',
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <div
                    className={'PageTypeContentOkBtn'}
                    style={{ backgroundColor: '#fd4e44' }}
                    onClick={this.delBindBtn.bind(this)}
                  >
                    确定解绑
                  </div>
                </div>
              </div>
              <div
                className={'NoVipTip'}
                style={{ textAlign: 'center', textIndent: 'unset' }}
              >
                如有任何疑问，请联系我们
              </div>
            </div>
          )}

          {this.state.PopUp === '提示弹窗' && (
            <PopUp
              ChildBackClick={this.ChildBackTip.bind(this)}
              type={'提示'}
              message={this.state.MessageTip}
              OutButton={'Yes'}
            />
          )}
        </div>
      </div>
    )
  }
}

export default PeYunChengVIP
