import React, { Component } from 'react';
import Pagination from "../round/Pagination";
import './member.css';
import {get} from "../../ajax/tools";


class GrDevice extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            MemberTopNav:'设备信息',
            TopNavArr:['设备信息'],
            NavSelected:{
                backgroundColor:'#0488CA',
                color:'#FFFFFF',
                borderLeftColor:'#000000',
                borderTopColor:'#000000',
                borderRightColor:'#000000',
            },
            NoSelected:{
                borderBottomColor:'#000000',
            },

            GrDeviceArr:[],
            GrDeviceArrLength:0,
            GrDeviceBrr:[],
            PageSize: 4,
            CurrentPage: 1,

        };
    }
    componentDidMount=function() {
        get({url:'agency/read_device_info'}).then((res) => {
            if(res.errno === '200'){
                const KeyStart = (this.state.CurrentPage-1)*this.state.PageSize;
                const KeyEnd = this.state.CurrentPage*this.state.PageSize;
                const GrDeviceList = [];
                if(KeyEnd <= res.data.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const GrDeviceBList = res.data[i];
                        GrDeviceList.push(GrDeviceBList)
                    }
                }else {
                    for(let i=KeyStart; i<res.data.length; i++){
                        const GrDeviceBList = res.data[i];
                        GrDeviceList.push(GrDeviceBList)
                    }
                }
                this.setState({
                    GrDeviceBrr:GrDeviceList,
                    GrDeviceArrLength:this.state.GrDeviceArr.length,
                });
            }else{
                this.setState({
                    GrDeviceArr:[],
                });
            }
        });
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if(item === '设备信息'){
            this.props.ChildBackNavClick('设备信息');
        }
        this.setState({
            MemberTopNav:item,
        });
    };

    //查看详情
    GrDeviceReview=(item,index)=>{
        if(item.id !== ''){
            this.props.BackDeviceDetails(item);
        }
    };

    getCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const GrDeviceList = [];
        if(KeyEnd <= this.state.GrDeviceArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const GrDeviceBList = this.state.GrDeviceArr[i];
                GrDeviceList.push(GrDeviceBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.GrDeviceArr.length; i++){
                const GrDeviceBList = this.state.GrDeviceArr[i];
                GrDeviceList.push(GrDeviceBList)
            }
        }
        this.setState({
            GrDeviceBrr:GrDeviceList,
        });
    }




    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    <div className={'MemberTopNavBox'}>
                        {
                            this.state.TopNavArr.map((item,index) =>
                                <div className={'MemberTopNav'} 
                                    key={index}
                                    onClick={() => this.MemberTopNav(item,index)}
                                    style={item === this.state.MemberTopNav ? this.state.NavSelected : this.state.NoSelected}
                                >
                                    {item}
                                </div>
                            )
                        }
                        <div className={'MemberPosition'} style={{width:808,height:61,borderBottom:'1px solid #000000',}}> </div>
                    </div>
                    <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                        <div className={'TableHeader'}>
                            <div className={'TableHeaderInfo'} style={{width:200,}}>设备详情</div>
                            <div className={'TableHeaderInfo'} style={{width:360,}}>设备名称（型号）</div>
                            <div className={'TableHeaderInfo'} style={{width:240,}}>证书编号（有效期）</div>
                            <div className={'TableHeaderInfo'} style={{width:118,}}>认证级别</div>
                        </div>
                        <div className={'GrDeviceConListBox'} style={{height:window.innerHeight-380,}}>
                            {
                                this.state.GrDeviceBrr.map((item,index) =>
                                    <div className={'GrDeviceConList'} key={index}>
                                        <div className={'GrDeviceConInfo'} style={{width:200,}}>
                                            <div className={'GrDeviceLogoBox'}>
                                                <img
                                                    className={'GrDeviceLogo'}
                                                    alt={''}
                                                    src={item.image}
                                                />
                                                <div className={'GrDeviceLogoHoverBox'} onClick={() => this.GrDeviceReview(item,index)}>
                                                    <div className={'BrandName'}>{item.brand}</div>
                                                    <div className={'GrDeviceReview'}>设备详情</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'GrDeviceConInfo'} style={{width:360,}}>
                                            <div className={'GrDeviceConInfoWord'} style={{marginTop:36,}}>{item.name}</div>
                                            <div className={'GrDeviceConInfoWord'}>（{item.model}）</div>
                                        </div>
                                        <div className={'GrDeviceConInfo'} style={{width:240,}}>
                                            <div className={'GrDeviceConInfoWord'} style={{marginTop:36,}}>{item.code}</div>
                                            <div className={'GrDeviceConInfoWord'}>（{item.expire_time}）</div>
                                        </div>
                                        <div className={'GrDeviceConInfo'} style={{width:118,}}>
                                            <div className={'GrDeviceConInfoWord'} style={{marginTop:44,}}> {item.level}</div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {
                            this.state.GrDeviceArrLength !== 0 &&
                            <Pagination
                                TotalSize={this.state.GrDeviceArrLength}
                                PageSize={this.state.PageSize}
                                pageCallbackFn={this.getCurrentPage.bind(this)}
                            />
                        }
                    </div>
                </div>
        );
    }
}


export default GrDevice;
