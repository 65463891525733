import React, { Component } from 'react';
import './exam.css';
import PopUp from "../round/PopUp";
import {get} from "../../ajax/tools";
//import {get,} from "../../ajax/tools";



class ExamRoomList extends Component {

    constructor(props){
        super(props);
        this.state = {
            ExamRoomListArr:[],
            ExamRoomInfo:{},
        };
    }
    componentDidMount=function() {
        get({url:'exam/get_exam_room_all'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    ExamRoomListArr:res.data,
                });
            }else if(res.errno === '4002'){
                this.props.ExamRoomListBack('无列表信息');
            }else {
                this.setState({
                    PopUp:'接口未知错误请求提示框',
                    MessageTip:'未知错误，请联系我们！',
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            get({url:'exam/del_exam_room/'+this.state.ExamRoomInfo.id}).then((res) => {
                if(res.errno === '200'){
                    get({url:'exam/get_exam_room_all'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                ExamRoomListArr:res.data,
                                PopUp:'提示弹窗',
                                MessageTip:'删除成功！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'接口未知错误请求提示框',
                        MessageTip:'删除失败！',
                    });
                }
            });
        }
    };
    ChildBackUnKnown=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };


    //点击删除(取消申请)
    CanceledIco=(item,index)=>{
        this.setState({
            PopUp:'确认是否删除弹窗',
            MessageTip:'确认是否删除(取消申请)？',
            ExamRoomInfo:item,
        });
    };

    render() {
        return (
            <div className={'ExamMainBoxes ExamMainOverflow'} style={{width:970,}}>
                <div className={'ExamHaveHeader clearfix'}>
                    <div className={'ExamHaveHeaderList'} style={{width:220,}}>申请时间</div>
                    <div className={'ExamHaveHeaderList'} style={{width:280,}}>考试时间</div>
                    <div className={'ExamHaveHeaderList'} style={{width:240,}}>审核状态</div>
                    <div className={'ExamHaveHeaderList'} style={{width:218,}}>删除(取消申请)</div>
                </div>
                <div className={'scrollBar'} style={{height:window.innerHeight-298,}}>
                    {
                        this.state.ExamRoomListArr.map((item,index) =>
                            <div className={'ExamHaveCon clearfix'} key={index}>
                                <div className={'ExamHaveConList'} style={{width:220,marginTop:28,}}>{item.ctime}</div>
                                <div className={'ExamHaveConList'} style={{width:280,}}>
                                    <div style={{marginBottom:6,}}>{item.time}</div>
                                    {
                                        item.forenoon !== null &&
                                        <div style={{marginBottom:6,}}>上午 {item.morning_start.substring(11,16)}——{item.morning_end.substring(11,16)}</div>
                                    }
                                    {
                                        item.afternoon !== null &&
                                        <div style={{marginBottom:6,}}>下午 {item.afternoon_start.substring(11,16)}——{item.afternoon_end.substring(11,16)}</div>
                                    }
                                </div>
                                <div className={'ExamHaveConList DisplayFlex'} style={{width:240,marginTop:24,}}>
                                    <div className={'AuditStateWord'} style={{color:item.process === 2 ? '#FF0000' : ''}}>
                                        {item.process === 0 ? '待审核...' : (item.process === 1 ? '审核通过' : (item.process === 2 ? '审核未通过' : ''))}
                                    </div>
                                    {/*{*/}
                                    {/*    item.process === 1 &&*/}
                                    {/*    <div className={'AuditStateIco'}> </div>*/}
                                    {/*}*/}
                                </div>
                                <div className={'ExamHaveConList'} style={{width:218,marginTop:24,}}>
                                    <div className={'CanceledIco'} onClick={() => this.CanceledIco(item,index)}> </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    this.state.PopUp === '确认是否删除弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackClick.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '接口未知错误请求提示框' &&
                    <PopUp
                        ChildBackClick={this.ChildBackUnKnown.bind(this)}
                        type={'错误'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ExamRoomList;
