import React, { Component } from 'react';
import './member.css';
import {get} from "../../ajax/tools";


class GrAvSchool extends Component {

    constructor(props){
        super(props);
        this.state = {
            AvSchool:{},
            MemberTopNav:'航校信息',
            TopNavArr:['航校信息'],
            NavSelected:{
                backgroundColor:'#0488CA',
                color:'#FFFFFF',
                borderLeftColor:'#000000',
                borderTopColor:'#000000',
                borderRightColor:'#000000',
            },
            NoSelected:{
                borderBottomColor:'#000000',
            },
        };
    }
    componentDidMount=function() {
        get({url:'agency/read_youth_aviat_school'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    AvSchool:res.data[0],
                });
            }
        });
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if(item === '航校信息'){
            this.props.ChildBackNavClick('航校信息');
        }
        this.setState({
            MemberTopNav:item,
        });
    };

    GrAvSchoolReview(){
        this.props.GrAvSchoolDetails({id:this.state.AvSchool.id});
    };




    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    <div className={'MemberTopNavBox'}>
                        {
                            this.state.TopNavArr.map((item,index) =>
                                <div className={'MemberTopNav'} 
                                    key={index}
                                    onClick={() => this.MemberTopNav(item,index)}
                                    style={item === this.state.MemberTopNav ? this.state.NavSelected : this.state.NoSelected}
                                >
                                    {item}
                                </div>
                            )
                        }
                        <div className={'MemberPosition'}  style={{width:808,height:61,borderBottom:'1px solid #000000',}}> </div>
                    </div>
                    <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                        <div className={'GrAvSchoolBox'}>
                            <div className={'GrAvSchoolListBox'}>
                                <div className={'GrAvSchoolListTit'}>负责人</div>
                                <div className={'GrAvSchoolListCon'}>{this.state.AvSchool.contact}</div>
                            </div>
                            {
                                this.state.AvSchool.recome_org && this.state.AvSchool.recome_org !== ''&&
                                <div className={'GrAvSchoolListBox'}>
                                    <div className={'GrAvSchoolListTit'}>推荐单位</div>
                                    <div className={'GrAvSchoolListCon'}>{this.state.AvSchool.recome_org}</div>
                                </div>
                            }
                            <div className={'GrAvSchoolListBox'}>
                                <div className={'GrAvSchoolListTit'}>航校地址</div>
                                {
                                    this.state.AvSchool.address &&
                                    <div className={'GrAvSchoolListCon'}>
                                        {
                                            this.state.AvSchool.province === this.state.AvSchool.city ?
                                                this.state.AvSchool.city+this.state.AvSchool.county+this.state.AvSchool.address :
                                                this.state.AvSchool.province+this.state.AvSchool.city+this.state.AvSchool.county+this.state.AvSchool.address
                                        }
                                    </div>
                                }
                            </div>
                            <div className={'GrAvSchoolListBox clearfix'}>
                                <div className={'GrAvSchoolListTit'}>证书到期时间</div>
                                <div className={'GrAvSchoolListCon'}>{this.state.AvSchool.expire_time}</div>
                            </div>
                            <div className={'GrAvSchoolListBox clearfix'}>
                                <div className={'GrAvSchoolListTit'}>证书编号</div>
                                <div className={'GrAvSchoolListCon'}>{this.state.AvSchool.code}</div>
                            </div>
                            <div className={'GrAvSchoolListBox clearfix'}>
                                <div className={'GrAvSchoolListTit'}>电子化管理设备数量</div>
                                <div className={'GrAvSchoolListCon'}>{this.state.AvSchool.device_num}</div>
                            </div>
                            <div className={'GrAvSchoolListBox clearfix'}>
                                <div className={'GrAvSchoolListTit'}>其他模拟设备情况</div>
                                <div className={'GrAvSchoolListCon'}>{this.state.AvSchool.other_device}</div>
                            </div>
                            <div className={'GrAvSchoolListBox'}>
                                <div className={'GrAvSchoolListTit'}>状态</div>
                                <div className={'GrAvSchoolListCon'}>
                                    <span className={'ClickDetails'} onClick={this.GrAvSchoolReview.bind(this)}>查看</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}


export default GrAvSchool;
