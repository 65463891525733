import React, { Component } from 'react';
//import {Layout,} from 'antd';
import './NotFound.css';

class NotFound extends Component {
    constructor(props){
        super(props);
        this.state = {
        }

    }

    componentWillMount=function() {

    };


    render() {
        return (
            <div className={'GameJumpsBg'}>
                <div className={'GameJumpsCon'}>*网站竟然404了。。。</div>
            </div>
        );
    }

}

export default NotFound;

