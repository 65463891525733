import React, { Component } from 'react';
import eCharts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import './member.css';
import {post} from "../../ajax/tools";
import PopUp from "../round/PopUp";

class ECharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StartTime:'',//开始时间
            EndTime:'',//结束时间
            YCItemName:'',//科目名称
            YCItemId:'',//科目名称
            TheStuId:'',
            YCSchoolType:'',
            YCSchoolId:'',
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let history = nextProps;
        let StartTime = history.SelectInfo.StartTime;
        let EndTime = history.SelectInfo.EndTime;
        let YCItemId = history.SelectInfo.YCItemId;
        let YCItemName = history.SelectInfo.YCItemName;
        let TheStuId = history.SelectInfo.TheStuId;
        let YCSchoolType = history.SelectInfo.YCSchoolType;
        let YCSchoolId = history.SelectInfo.YCSchoolId;
        this.setState({
            StartTime:StartTime,
            EndTime:EndTime,
            YCItemId:YCItemId,
            YCItemName:YCItemName,
            TheStuId:TheStuId,
            YCSchoolType:YCSchoolType,
            YCSchoolId:YCSchoolId,
        });
        // 基于准备好的dom，初始化echarts实例
        let myLineChart = eCharts.init(document.getElementById('LineEChartsMain'),<span> </span>);
        let LineEChartsOne = eCharts.init(document.getElementById('LineEChartsOne'),<span> </span>);
        let LineEChartsTwo = eCharts.init(document.getElementById('LineEChartsTwo'),<span> </span>);
        let LineEChartsThree = eCharts.init(document.getElementById('LineEChartsThree'),<span> </span>);
        let myPieChart = eCharts.init(document.getElementById('PieEChartsMain'),<span> </span>);
        // 绘制图表
        let LineInfoArr = ['坠机次数', '超时次数', '成功次数',];
        let LineInfoOneArr = ['最好成绩',];
        let LineInfoTwoArr = ['得分率',];
        let LineInfoThreeArr = ['飞行次数',];
        let PieInfoArr = ['坠机率', '超时率', '成功率',];
        post({
            url:'cloudprogram/stu_score_analy_ber',
            data:{
                item_id:YCItemId,
                start_time:StartTime,
                end_time:EndTime,
                stu_id:TheStuId,
                type:YCSchoolType,
                ti_id:YCSchoolId,
            },
        }).then((res) => {
            if (res.errno === '200') {
                myPieChart.setOption({
                    title: {
                        text: YCItemName+'训练情况统计',
                        left: 'center',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                        data: PieInfoArr,
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series: [
                        {
                            name: '概率统计',
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            data: [
                                {value: res.data[0].crashNum, name: '坠机率',itemStyle:{color: '#C23531',},},
                                {value: res.data[0].overtimeNum, name: '超时率',itemStyle:{color: '#CA8622',},},
                                {value: res.data[0].passNum, name: '成功率',itemStyle:{color: '#91C7AE',},},
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                });
            }else if (res.errno === '4502'){
                this.props.EChartsBackChild('Login');
            }else {
                this.props.EChartsBackChild('Err');
            }
        });
        post({
            url:'cloudprogram/stu_score_analy_chart_ber',
            data:{
                item_id:YCItemId,
                start_time:StartTime,
                end_time:EndTime,
                stu_id:TheStuId,
                type:YCSchoolType,
                ti_id:YCSchoolId,
            },
        }).then((res) => {
            if (res.errno === '200') {
                const crashNum=[];
                const overtimeNum=[];
                const passNum=[];
                const timeX=[];
                for(let i=0;i<res.data.length;i++){
                    const ItemList = res.data[i];
                    crashNum.push(ItemList.crashNum);
                    overtimeNum.push(ItemList.overtimeNum);
                    passNum.push(ItemList.passNum);
                    timeX.push(ItemList.xtime.substring(5));
                }
                myLineChart.setOption({
                    title: {
                        text: YCItemName+'训练数据统计',
                        left:'left',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        data: LineInfoArr,
                        left:'right',
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: timeX,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} 次'
                        }
                    },
                    series: [
                        {
                            name: '坠机次数',
                            type: 'line',
                            color: '#C23531',
                            data: crashNum,
                        },
                        {
                            name: '超时次数',
                            type: 'line',
                            color: '#CA8622',
                            data: overtimeNum,
                        },
                        {
                            name: '成功次数',
                            type: 'line',
                            color: '#91C7AE',
                            data: passNum,
                        },
                    ]
                });
            }else if (res.errno === '4502'){
                this.props.EChartsBackChild('Login');
            }else{
                this.props.EChartsBackChild('Err');
            }
        });









        post({
            url:'cloudprogram/stu_score_analy_chart_ber_day',
            data:{
                item_id:YCItemId,
                start_time:StartTime,
                end_time:EndTime,
                stu_id:TheStuId,
                type:YCSchoolType,
                ti_id:YCSchoolId,
            },
        }).then((res) => {
            if (res.errno === '200') {
                let bestScore=[];
                let dfl=[];
                let totalNum=[];
                let timeX=[];
                for(let i=0;i<res.data.length;i++){
                    let ItemList = res.data[i];
                    bestScore.push(ItemList.bast_score);
                    dfl.push(ItemList.dfl*100);
                    totalNum.push(ItemList.total_num);
                    timeX.push(ItemList.xtime.substring(5));
                }
                LineEChartsOne.setOption({
                    title: {
                        text: YCItemName+'训练最好成绩',
                        left:'left',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        data: LineInfoOneArr,
                        left:'right',
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: timeX,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} 分'
                        }
                    },
                    series: [
                        {
                            name: '最好成绩',
                            type: 'line',
                            color: '#C23531',
                            data: bestScore,
                        },
                    ]
                });
                LineEChartsTwo.setOption({
                    title: {
                        text: YCItemName+'训练得分率',
                        left:'left',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        data: LineInfoTwoArr,
                        left:'right',
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: timeX,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    },
                    series: [
                        {
                            name: '得分率',
                            type: 'line',
                            color: '#C23531',
                            data: dfl,
                        },
                    ]
                });
                LineEChartsThree.setOption({
                    title: {
                        text: YCItemName+'训练飞行次数',
                        left:'left',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        data: LineInfoThreeArr,
                        left:'right',
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: timeX,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} 次'
                        }
                    },
                    series: [
                        {
                            name: '飞行次数',
                            type: 'line',
                            color: '#C23531',
                            data: totalNum,
                        },
                    ]
                });
            }else if (res.errno === '4502'){
                this.props.EChartsBackChild('Login');
            }else{
                this.props.EChartsBackChild('Err');
            }
        });

    };


    componentDidMount=function() {
        let history = this.props;
        let StartTime = history.SelectInfo.StartTime;
        let EndTime = history.SelectInfo.EndTime;
        let YCItemId = history.SelectInfo.YCItemId;
        let YCItemName = history.SelectInfo.YCItemName;
        let TheStuId = history.SelectInfo.TheStuId;
        let YCSchoolType = history.SelectInfo.YCSchoolType;
        let YCSchoolId = history.SelectInfo.YCSchoolId;
        this.setState({
            StartTime:StartTime,
            EndTime:EndTime,
            YCItemId:YCItemId,
            YCItemName:YCItemName,
            TheStuId:TheStuId,
            YCSchoolType:YCSchoolType,
            YCSchoolId:YCSchoolId,
        });
        // 基于准备好的dom，初始化echarts实例
        let myLineChart = eCharts.init(document.getElementById('LineEChartsMain'),<span> </span>);
        let myPieChart = eCharts.init(document.getElementById('PieEChartsMain'),<span> </span>);
        // 绘制图表
        let LineInfoArr = ['坠机次数', '超时次数', '成功次数',];
        let PieInfoArr = ['坠机率', '超时率', '成功率',];
        post({
            url:'cloudprogram/stu_score_analy_ber',
            data:{
                item_id:YCItemId,
                start_time:StartTime,
                end_time:EndTime,
                stu_id:TheStuId,
                type:YCSchoolType,
                ti_id:YCSchoolId,
            },
        }).then((res) => {
            if (res.errno === '200') {
                myPieChart.setOption({
                    title: {
                        text: YCItemName+'训练情况统计',
                        left: 'center',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                        data: PieInfoArr,
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series: [
                        {
                            name: '概率统计',
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            data: [
                                {value: res.data[0].crashNum, name: '坠机率',itemStyle:{color: '#C23531',},},
                                {value: res.data[0].overtimeNum, name: '超时率',itemStyle:{color: '#CA8622',},},
                                {value: res.data[0].passNum, name: '成功率',itemStyle:{color: '#91C7AE',},},
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                });
            }else if (res.errno === '4502'){
                this.props.EChartsBackChild('Login');
            }else{
                this.props.EChartsBackChild('Err');
            }
        });
        post({
            url:'cloudprogram/stu_score_analy_chart_ber',
            data:{
                item_id:YCItemId,
                start_time:StartTime,
                end_time:EndTime,
                stu_id:TheStuId,
                type:YCSchoolType,
                ti_id:YCSchoolId,
            },
        }).then((res) => {
            if (res.errno === '200') {
                const crashNum=[];
                const overtimeNum=[];
                const passNum=[];
                const timeX=[];
                for(let i=0;i<res.data.length;i++){
                    const ItemList = res.data[i];
                    crashNum.push(ItemList.crashNum);
                    overtimeNum.push(ItemList.overtimeNum);
                    passNum.push(ItemList.passNum);
                    timeX.push(ItemList.xtime.substring(5));
                }
                myLineChart.setOption({
                    title: {
                        text: YCItemName+'训练数据统计',
                        left:'left',
                        subtext: res.start_time+' ~ '+res.end_time,
                        subtextStyle:{
                            color:'#B03A5B',
                        },
                    },
                    legend: {
                        data: LineInfoArr,
                        left:'right',
                        selectedMode:false,
                        textStyle:{
                            color:'#000000',
                            fontFamily:'bold',
                            fontSize:14,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: timeX,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value} 次'
                        }
                    },
                    series: [
                        {
                            name: '坠机次数',
                            type: 'line',
                            color: '#C23531',
                            data: crashNum,
                        },
                        {
                            name: '超时次数',
                            type: 'line',
                            color: '#CA8622',
                            data: overtimeNum,
                        },
                        {
                            name: '成功次数',
                            type: 'line',
                            color: '#91C7AE',
                            data: passNum,
                        },
                    ]
                });
            }else if (res.errno === '4502'){
                this.props.EChartsBackChild('Login');
            }else{
                this.props.EChartsBackChild('Err');
            }
        });

    };




    AllClick = () => {
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };




    render() {
        return (
            <div className={'EChartsBox'}>
                <div id={'PieEChartsMain'}> </div>
                <div id={'LineEChartsMain'}> </div>
                <div id={'LineEChartsOne'}> </div>
                <div id={'LineEChartsTwo'}> </div>
                <div id={'LineEChartsThree'}> </div>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}

export default ECharts;
