import React, { Component } from 'react';
import './game.css';
import {get} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class GGAddPeople extends Component {

    constructor(props){
        super(props);
        this.state = {
            AddPeopleId:'',//输入的添加人员ID或身份证号
            MyGroupPeoArr:[],//所属本机构的人员数据
        };
    }
    componentDidMount=function() {
        get({url:'match/query_school_stu'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    MyGroupPeoArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };








    //输入ID或身份证号
    AddPeopleId(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            AddPeopleId: event.target.value,
        });
    };




    //点击搜索ID
    GGAPSearchBtn(){
        let AddPeopleId = this.state.AddPeopleId;
        let MyGroupPeoArr = this.state.MyGroupPeoArr;
        let yyNumArr = [];
        if (AddPeopleId !== ''){
            get({url:'reuse/get_personal_info/'+AddPeopleId}).then((res) => {
                if(res.errno === '200'){
                    for(let i=0;i<MyGroupPeoArr.length;i++){
                        let MyGroupPeoArrList = MyGroupPeoArr[i].yy_num;
                        yyNumArr.push(MyGroupPeoArrList);
                    }
                    if(yyNumArr.includes(res.data[0].yy_num)){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'该人员已经存在人员列表中！',
                        });
                    }else {
                        let NewMyGroupPeoArr = res.data.concat(MyGroupPeoArr);
                        this.setState({
                            MyGroupPeoArr:NewMyGroupPeoArr,
                            AddPeopleId:'',
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入人员ID或身份证号！',
            });
        }
    };


    //添加人员按钮
    GGAPListMainBtn(item,index){
        this.props.GGAddPeople(item);
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'GGAddPeopleBox'}>
                    <div className={'GGAPSearchBox clearfix'}>
                        <div className={'GGAPSearchTit'}>人员ID</div>
                        <div className={'GGAPSearchMainBox'}>
                            <input className={'GGAPSearchInput'}
                                   type={'text'}
                                   name={'AddPeopleId'}
                                   value={this.state.AddPeopleId}
                                   onChange={this.AddPeopleId.bind(this)}
                                   placeholder={'输入人员的ID号搜索以添加'}
                                   autoComplete={'off'}
                            />
                            <div className={'GGAPSearchBtn'} onClick={this.GGAPSearchBtn.bind(this)}>搜索</div>
                        </div>
                        <div className={'GGAPSearchDescription'}>*若以下列表内没有您要添加的人员，需输入“完整ID号”后点击搜索</div>
                    </div>
                    <div className={'GGAPListTitleBox clearfix'}>
                        <div className={'GGAPListTitleName'}>人员姓名</div>
                        <div className={'GGAPListTitleName'}>人员ID</div>
                    </div>
                    <div className={'GGAPBorder'}> </div>
                    <div className={'GGAPListMainBox scrollBar'}>
                        {
                            this.state.MyGroupPeoArr.map((item,index)=>
                                <div className={'GGAPListMainList clearfix'} key={index}>
                                    <div className={'GGAPListMainListContent'}>{item.real_name}</div>
                                    <div className={'GGAPListMainListContent'}>{item.yy_num}</div>
                                    <div className={'GGAPListMainListContent'}>
                                        <div className={'GGAPListMainBtn'} onClick={() => this.GGAPListMainBtn(item,index)}>报名</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GGAddPeople;
