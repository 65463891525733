import React, { Component } from 'react';
import './member.css';
import Triangle from "../round/Triangle";
import CheckboxIcoHave from "./memberimage/CheckboxIcoHave.png";
import CheckboxIcoNot from "./memberimage/CheckboxIcoNot.png";
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class GrTrain extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            MemberTopNav:'机构信息',
            TopNavArr:['机构信息','任职教练','邀请教练','教练申请',],
            NavSelected:{
                backgroundColor:'#0488CA',
                color:'#FFFFFF',
                borderLeftColor:'#000000',
                borderTopColor:'#000000',
                borderRightColor:'#000000',
            },
            NoSelected:{
                borderBottomColor:'#000000',
            },
            TeacherId:'',
            TeacherIdNote:'No',
            GrTrainAd:'所属训练地址',
            GrTrainAdId:'',
            GrTrainAdToggleOn: true,
            GrTrainAdDisplay: 'none',

            ActTrainAddressToggleOn:true,
            ActTrainAddressDisplay:'none',
            ActiveTrainWord:'请选择所属训练机构',
            ActiveTrainId:'',
            ActiveTrain:{},


            RemoveNum: 0,
            RemoveNumArr: [],

            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
            CheckboxIcoToggleOn: true,


            GrTrainAdArr:[],
            GrTrainInfoArr:[],
            TrainCoachInfoArr:[],
            TeacherInfoArr:[],
            CoachAppInfoArr:[],
        };
    }


    componentDidMount=function() {
        get({url:'agency/read_train_institute'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GrTrainInfoArr:res.data,
                });
            }else {
                this.setState({
                    GrTrainInfoArr:[],
                });
            }
        });
        get({url:'agency/get_agency_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TrainCoachInfoArr:res.data,
                });
            }else {
                this.setState({
                    TrainCoachInfoArr:[],
                });
            }
        });
        get({url:'agency/get_agency_info_all'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    CoachAppInfoArr:res.data,
                });
            }else {
                this.setState({
                    CoachAppInfoArr:[],
                });
            }
        });
        const TeacherInfoArr = JSON.parse(sessionStorage.getItem('TeacherInfoArr'));
        if (TeacherInfoArr) {
            sessionStorage.setItem('TeacherInfoArr',null);
            this.setState({
                TeacherInfoArr:TeacherInfoArr,
            });
        }

        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
            GrTrainAdToggleOn: true,
            GrTrainAdDisplay: 'none',
            ActTrainAddressToggleOn: true,
            ActTrainAddressDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if(item === '机构信息'){
            get({url:'agency/read_train_institute'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        GrTrainInfoArr:res.data,
                    });
                }else {
                    this.setState({
                        GrTrainInfoArr:[],
                    });
                }
            });
            this.props.ChildBackNavClick('机构信息');
        }else if(item === '任职教练'){
            get({url:'agency/read_train_institute'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        GrTrainAdArr:res.data,
                    });
                }else {
                    this.setState({
                        GrTrainAdArr:[],
                    });
                }
            });
            get({url:'agency/get_agency_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TrainCoachInfoArr:res.data,
                    });
                }else {
                    this.setState({
                        TrainCoachInfoArr:[],
                    });
                }
            });
            this.setState({
                GrTrainAdToggleOn: true,
                GrTrainAdDisplay: 'none',
            });
            this.props.ChildBackNavClick('任职教练');
        }else if(item === '邀请教练'){
            get({url:'agency/read_train_institute'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        GrTrainAdArr:res.data,
                    });
                }else {
                    this.setState({
                        GrTrainAdArr:[],
                    });
                }
            });
            this.setState({
                TeacherId:'',
                GrTrainAdToggleOn: true,
                GrTrainAdDisplay: 'none',
                ActiveTrainWord:'请选择所属训练机构',
                ActTrainAddressToggleOn: true,
                ActTrainAddressDisplay: 'none',
            });
            this.props.ChildBackNavClick('邀请教练');
        }if(item === '教练申请'){
            get({url:'agency/get_agency_info_all'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        CoachAppInfoArr:res.data,
                    });
                }else {
                    this.setState({
                        CoachAppInfoArr:[],
                    });
                }
            });
            this.setState({
                GrTrainAdToggleOn: true,
                GrTrainAdDisplay: 'none',
            });
            this.props.ChildBackNavClick('教练申请');
        }
        this.setState({
            MemberTopNav:item,
        });
    };





    //点击信息中的状态详情
    PeTeacherReview=(item,index) =>{
        if(item.id){
            this.props.BackTrainDetails(item);
        }
    };


    //输入教练员号
    TeacherIdChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            TeacherId: event.target.value,
        });
    };
    //点击搜索教练员
    TeacherIdInput=()=>{
        let TeacherId = this.state.TeacherId;
        let ActiveTrainWord = this.state.ActiveTrainWord;
        let TeacherInfoArr = this.state.TeacherInfoArr;
        let ActiveTrain = this.state.ActiveTrain;
        let TeacherListIdNumArr = [];
        if (ActiveTrainWord !== '请选择所属训练机构'){
            if (TeacherId !== ''){
                get({url:'agency/check_is_train_coach_yy/'+TeacherId}).then((res) => {
                    if(res.errno === '200'){
                        get({url:'reuse/get_coach_info/'+TeacherId}).then((res) => {
                            if(res.errno === '200'){
                                sessionStorage.setItem('TeacherInfoArr',null);
                                let ThisTeacher = res.data[0];
                                let TeacherInfoCbArr=[];
                                if(TeacherInfoArr.length !==0){
                                    for(let i=0;i<TeacherInfoArr.length;i++){
                                        let TeacherListId = TeacherInfoArr[i].id;
                                        TeacherListIdNumArr.push(TeacherListId);
                                    }
                                    if(TeacherListIdNumArr.includes(ThisTeacher.id)){
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'您已经搜索添加过教练员！',
                                        });
                                    }else {
                                        let TeacherTheList = ThisTeacher;
                                        TeacherTheList.Checkbox= false;
                                        TeacherTheList.send= false;
                                        TeacherTheList.BigName= ActiveTrainWord;
                                        TeacherTheList.coach_id= ThisTeacher.id;
                                        TeacherTheList.TrainId= ActiveTrain.id;
                                        TeacherInfoArr.push(TeacherTheList);
                                    }
                                    this.setState({
                                        TeacherInfoArr:TeacherInfoArr,
                                        TeacherIdNote:'No',
                                    });
                                }else {
                                    let TeacherTheList = ThisTeacher;
                                    TeacherTheList.Checkbox= false;
                                    TeacherTheList.send= false;
                                    TeacherTheList.BigName= ActiveTrainWord;
                                    TeacherTheList.coach_id= ThisTeacher.id;
                                    TeacherTheList.TrainId= ActiveTrain.id;
                                    TeacherInfoCbArr.push(TeacherTheList);
                                    this.setState({
                                        TeacherInfoArr:TeacherInfoCbArr,
                                        TeacherIdNote:'No',
                                    });
                                }
                            }else {
                                this.setState({
                                    TrainIdNote:'Yes',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'此用户已经在训练机构任职,不可邀请！',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'请输入教练员用户ID！',
                });
            }
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请先选择所属训练机构！',
            });
        }
    };
    //点击重置找不到教练员的备注
    TeacherIdClick(){
        this.setState({
            TeacherIdNote:'No',
        });
    };

    //点击邀请按钮
    SendTeacher=()=>{
        let TeacherInfoArr = this.state.TeacherInfoArr;
        let RemoveNumArr = this.state.RemoveNumArr;
        let FiIdListArr = [];
        for(let i=0;i<RemoveNumArr.length;i++){
            let FiIdList = {ti_id:RemoveNumArr[i].TrainId,coach:RemoveNumArr[i].id};
            FiIdListArr.push(FiIdList);
        }
        post({
            url:'agency/invite_coach',
            data:{
                ti_list:FiIdListArr,
            },
        }).then((res) => {
            if(res.errno === '200'){
                for(let i=0;i<RemoveNumArr.length;i++){
                    TeacherInfoArr[RemoveNumArr[i].index]['send'] = true;
                }
                this.setState({
                    TeacherId:'',
                    ActiveTrainWord:'请选择所属训练机构',
                    CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
                    CheckboxIcoToggleOn: true,
                    RemoveNumArr:[],
                    RemoveNum:0,
                    TeacherInfoArr:TeacherInfoArr,
                    PopUp:'提示弹窗',
                    MessageTip:'成功发送邀请',
                });
            }else {
                this.setState({
                    TeacherId:'',
                    ActiveTrainWord:'请选择所属训练机构',
                    PopUp:'提示弹窗',
                    MessageTip:'发送申请失败，请稍后重试！',
                });
            }
        });
    };



    //点击选择机构的下拉按钮
    ActiveTrainBtn(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            ActTrainAddressToggleOn: !prevState.ActTrainAddressToggleOn,
            ActTrainAddressDisplay:prevState.ActTrainAddressToggleOn ? 'block' : 'none',
            GrTrainAdToggleOn: true,
            GrTrainAdDisplay:'none',
        }));
    };
    //邀请教练选择机构
    ActiveTrainAddress=(item,index)=>{
        this.setState({
            ActiveTrainWord:item.city+item.county+item.address,
            ActiveTrain:item,
            ActTrainAddressToggleOn: true,
            ActTrainAddressDisplay: 'none',
        });
    };








    //从此机构中移除此教练员
    RemoveThisTeacher=(item,index)=>{
        get({url:'agency/del_tra_coach/'+item.id}).then((res) => {
            if(res.errno === '200'){
                get({url:'agency/get_agency_info'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TrainCoachInfoArr:res.data,
                            PopUp:'提示弹窗',
                            MessageTip:'操作成功！',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'操作失败，请稍后再试！',
                });
            }
        });
    };


    //点击多选框全选
    CheckboxIcoAll=(event)=>{
        const TeacherInfoCbArr=[];
        const TeacherSendNumArr=[];
        for(let i=0;i<event.length;i++){
            const PeList= event[i];
            if(this.state.CheckboxIcoToggleOn === false){
                if(PeList.send === false){
                    PeList.Checkbox = false;
                    PeList.index = i;
                    TeacherSendNumArr.push(PeList);
                }
                TeacherInfoCbArr.push(PeList);
            }else if(this.state.CheckboxIcoToggleOn === true){
                if(PeList.send === false){
                    PeList.Checkbox = true;
                    PeList.index = i;
                    TeacherSendNumArr.push(PeList);
                }
                TeacherInfoCbArr.push(PeList);
            }
        }
        this.setState(prevState => ({
            CheckboxIcoToggleOn: !prevState.CheckboxIcoToggleOn,
            CheckboxIcoBgImg:prevState.CheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',
            RemoveNum:prevState.CheckboxIcoToggleOn ? TeacherSendNumArr.length : 0,
            TeacherInfoArr:TeacherInfoCbArr,
            RemoveNumArr:prevState.CheckboxIcoToggleOn ? TeacherSendNumArr : [],
        }));
    };
    //点击单个多选框
    CheckboxIco=(item,index)=>{
        const PeInfoList = this.state.TeacherInfoArr;
        PeInfoList[index]['Checkbox'] = item.Checkbox !== true;
        const PeSendNumArr = [...this.state.RemoveNumArr];
        if(item.Checkbox === true){
            item.index = index;
            PeSendNumArr.push(item);
            this.setState({
                RemoveNumArr:PeSendNumArr,
                RemoveNum:PeSendNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<PeSendNumArr.length;i++){
                if(PeSendNumArr[i].id === item.id){
                    PeSendNumArr.splice(i,1);
                }
            }
            this.setState({
                RemoveNumArr:PeSendNumArr,
                RemoveNum:PeSendNumArr.length,
            });
        }

        const TeacherSendNoArr=[];
        for(let i=0;i<this.state.TeacherInfoArr.length;i++){
            const TrainList= this.state.TeacherInfoArr[i];
            if(TrainList.send === false){
                TeacherSendNoArr.push(TrainList)
            }
        }

        this.setState({
            TeacherInfoArr:PeInfoList,
            CheckboxIcoToggleOn:TeacherSendNoArr.length !== PeSendNumArr.length,
        });
    };






    //点击学员的教练员查看详细信息
    CoachDetails=(item,index) =>{
        if(item.coach_id !== ''){
            this.props.BackCoachDetails(item);
        }
    };
    TeacherDetails=(item,index) =>{
        if(item.coach_id !== ''){
            let TeacherInfoArr = this.state.TeacherInfoArr;
            sessionStorage.setItem('TeacherInfoArr',JSON.stringify(TeacherInfoArr));
            this.props.BackCoachDetails(item);
        }
    };


    TeamNewsBtnOk=(item,index)=>{
        post({
            url:'agency/check_is_train_coach',
            data:{
                sub_id: item.coach_id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                post({
                    url:'agency/accept_coach',
                    data:{
                        tic_id: item.id,
                        status: 1,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        get({url:'agency/get_agency_info_all'}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    CoachAppInfoArr:res.data,
                                    PopUp:'提示弹窗',
                                    MessageTip:'操作成功',
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'操作失败',
                                });
                            }
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'此教练员已在训练机构任职,此操作无效！',
                });
            }
        });
    };

    TeamNewsBtnNo=(item,index)=>{
        post({
            url:'agency/accept_coach',
            data:{
                tic_id: item.id,
                status: 2,
            },
        }).then((res) => {
            if(res.errno === '200'){
                get({url:'agency/get_agency_info_all'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            CoachAppInfoArr:res.data,
                            PopUp:'提示弹窗',
                            MessageTip:'操作成功',
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'操作失败',
                        });
                    }
                });
            }
        });
    };





    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };






    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    <div className={'MemberTopNavBox'}>
                        {
                            this.state.TopNavArr.map((item,index) =>
                                <div className={'MemberTopNav'}
                                     key={index}
                                     onClick={() => this.MemberTopNav(item,index)}
                                     style={item === this.state.MemberTopNav ? this.state.NavSelected : this.state.NoSelected}
                                >
                                    {item}
                                </div>
                            )
                        }
                        <div className={'MemberPosition'} style={{width:292,height:61,borderBottom:'1px solid #000000',}}> </div>
                    </div>
                    {
                        this.state.MemberTopNav === '机构信息' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TableHeader'}>
                                <div className={'TableHeaderInfo'} style={{width:100,}}>序号</div>
                                <div className={'TableHeaderInfo'} style={{width:298,}}>训练地址</div>
                                <div className={'TableHeaderInfo'} style={{width:298,}}>证书编号（有效期）</div>
                                <div className={'TableHeaderInfo'} style={{width:120,}}>训练范围</div>
                                <div className={'TableHeaderInfo'} style={{width:100,}}>状态</div>
                            </div>
                            <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                                {
                                    this.state.GrTrainInfoArr.map((item,index) =>
                                        <div className={'TableContent clearfix'} key={index}>
                                            <div className={'TableContentInfo'} style={{width:100,}}>{index+1}</div>
                                            <div className={'TableContentInfo'} style={{width:298,}}>{item.city+item.county+item.address}</div>
                                            <div className={'TableContentInfo'} style={{width:294,}}>
                                                <div className={'TableContentInfoBranch'}>{item.code === '' ? '000000' : item.code}</div>
                                                <div className={'TableContentInfoBranch'}>（{item.expire_time}）</div>
                                            </div>
                                            <div className={'TableContentInfo'} style={{width:120,}}>
                                                {
                                                    item.level1 === '认证' &&
                                                    <span>初级</span>
                                                }
                                                {
                                                    item.level2 === '认证' &&
                                                    <span>/中级</span>
                                                }
                                                {
                                                    item.level3 === '认证' &&
                                                    <span>/高级</span>
                                                }
                                                {
                                                    item.level1 === '' &&
                                                    <span>未认证</span>
                                                }
                                            </div>
                                            <div className={'TableContentInfo'} style={{width:100,}}>
                                                <span className={'DetailsViews'} onClick={() => this.PeTeacherReview(item,index)}>查看</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '任职教练' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TableHeader'}>
                                <div className={'TableHeaderInfo'} style={{width:60,}}>序号</div>
                                <div className={'TableHeaderInfo'} style={{width:396,position:'relative',}}>所属训练机构（地址）</div>
                                <div className={'TableHeaderInfo'} style={{width:260,}}>姓名（用户ID）</div>
                                <div className={'TableHeaderInfo'} style={{width:100,}}>详情</div>
                                <div className={'TableHeaderInfo'} style={{width:100,}}>移除</div>
                            </div>
                            <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                                {
                                    this.state.TrainCoachInfoArr.map((item,index) =>
                                        <div className={'TableContent clearfix'} key={index}>
                                            <div className={'TableContentInfo'} style={{width:60,}}>{index+1}</div>
                                            <div className={'TableContentInfo'} style={{width:396,}}>{item.city+item.address}</div>
                                            <div className={'TableContentInfo'} style={{width:260,}}>{item.real_name}（{item.yy_num}）</div>
                                            <div className={'TableContentInfo'} style={{width:100,}}>
                                                <span className={'DetailsViews'} onClick={() => this.CoachDetails(item,index)}>查看</span>
                                            </div>
                                            <div className={'TableContentInfo'} style={{width:100,}}>
                                                <div className={'RemoveThisTeacher'} onClick={() => this.RemoveThisTeacher(item,index)}> </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '邀请教练' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'GrTrainTeacherConBox'} style={{height:window.innerHeight-270,}}>
                                <div className={'PeTeamCreateInfoConBox'} style={{marginTop:0,paddingTop:18,marginBottom:18,}}>
                                    <div className={'PeTeamManageInfoConTit'}>选择所属训练机构</div>
                                    <div className={'TeamIdInputBox clearfix'} style={{position:'relative',}}>
                                        <div className={'ActiveTrainWord'}>{this.state.ActiveTrainWord}</div>
                                        <div className={'ActiveTrainBtn'} onClick={this.ActiveTrainBtn.bind(this)}>
                                            <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'14px'} Right={'15px'}/>
                                        </div>
                                        <div className={'ActiveTrainMenuListBox'} style={{display:this.state.ActTrainAddressDisplay}}>
                                            {
                                                this.state.GrTrainAdArr.map((item,index) =>
                                                    <div className={'ActiveTrainMenuList'}
                                                         key={index}
                                                         onClick={() => this.ActiveTrainAddress(item,index)}
                                                    >{item.city+item.county+item.address}</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'PeTeamCreateInfoConBox'} style={{marginTop:0,marginBottom:38,}}>
                                    <div className={'PeTeamManageInfoConTit'}>教练员用户 ID 号</div>
                                    <div className={'TeamIdInputBox clearfix'}>
                                        <input className={'TeamIdInput'}
                                               type={'text'}
                                               name={'PeId'}
                                               value={this.state.TeacherId}
                                               onChange={this.TeacherIdChange.bind(this)}
                                               onClick={this.TeacherIdClick.bind(this)}
                                               placeholder={'请输入正确的个人用户ID号'}
                                               autoComplete={'off'}
                                        />
                                        <button className={'TeamIdInputBtn'} onClick={this.TeacherIdInput.bind(this)}>搜索</button>
                                    </div>
                                    {
                                        this.state.TeacherIdNote === 'Yes' &&
                                        <div className={'TeamIdInputNote'} style={{marginLeft:10,}}>未搜索到教练员，请您检查输入是否正确</div>
                                    }
                                </div>
                                <div className={'PeJoinTameTitBtnBox'} style={{marginBottom:18,}}>
                                    <button className={'TeamPleaseSendBtn'}
                                            onClick={() => this.state.RemoveNumArr.length === 0 ? null : this.SendTeacher(this.state.TeacherInfoArr)}
                                            style={this.state.RemoveNumArr.length === 0 ? {backgroundColor:'#D2D2D2',}:{}}
                                    >邀请({this.state.RemoveNum})</button>
                                </div>
                                <div className={'TeamListHeaderBox'} style={{margin:'0 auto',}}>
                                    <div className={'TeamHeaderList'} style={{width:40,}}>
                                        <div className={'TeamHeaderCheckbox'}
                                             onClick={() =>this.state.TeacherInfoArr.length === 0 ? null : this.CheckboxIcoAll(this.state.TeacherInfoArr)}>
                                            {
                                                this.state.CheckboxIcoToggleOn === false &&
                                                <div className={'TeamHeaderCheckboxActive'}> </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={'TeamHeaderList'} style={{width:458,}}>所属训练机构地址</div>
                                    <div className={'TeamHeaderList'} style={{width:280,}}>姓名（用户ID）</div>
                                    <div className={'TeamHeaderList'} style={{width:120,}}>详情</div>
                                </div>
                                <div className={'TeamListConBox'} style={{height:window.innerHeight-520,}}>
                                    {
                                        this.state.TeacherInfoArr.map((item,index) =>
                                            <div className={'TeamListCon'} key={index}>
                                                <div className={'TeamConList'} style={{width:40,}}>
                                                    <div className={'CheckboxIco'}
                                                         style={{backgroundImage:item.send === true ? '' : (item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),marginTop:7,}}
                                                         onClick={() =>item.send === true ? null : this.CheckboxIco(item,index)}>
                                                    </div>
                                                </div>
                                                <div className={'TeamConList Ellipsis'} style={{width:458,}}>{item.BigName}</div>
                                                <div className={'TeamConList'} style={{width:280,}}>{item.real_name}（{item.yy_num}）</div>
                                                <div className={'TeamConList'} style={{width:120,}}>
                                                    <span className={'DetailsViews'} onClick={() => this.TeacherDetails(item,index)}>查看</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '教练申请' &&
                        <div className={'PeStudentInfoBox'} style={{height:window.innerHeight-270,}}>
                            <div className={'TableHeader'}>
                                <div className={'TableHeaderInfo'} style={{width:156,}}>时间</div>
                                <div className={'TableHeaderInfo'} style={{width:320,}}>申请训练机构（地址）</div>
                                <div className={'TableHeaderInfo'} style={{width:160,}}>姓名（用户ID）</div>
                                <div className={'TableHeaderInfo'} style={{width:135,}}>详情</div>
                                <div className={'TableHeaderInfo'} style={{width:135,}}>状态（操作）</div>
                            </div>
                            <div className={'TableContentBox'} style={{height:window.innerHeight-338,}}>
                                {
                                    this.state.CoachAppInfoArr.map((item,index) =>
                                        <div className={'TableContent'} key={index}>
                                            <div className={'TableContentInfo'} style={{width:156,}}>{item.ctime.substring(0,10)}</div>
                                            <div className={'TableContentInfo'} style={{width:320,}}>{item.city+item.county+item.address}</div>
                                            <div className={'TableContentInfo'} style={{width:160,}}>{item.real_name}（{item.yy_num}）</div>
                                            <div className={'TableContentInfo'} style={{width:135,}}>
                                                <span className={'DetailsViews'} onClick={() => this.CoachDetails(item,index)}>查看</span>
                                            </div>
                                            <div className={'TableContentInfo'} style={{width:135,}}>
                                                {
                                                    item.status === 1 &&
                                                    <span style={{color:'#1ABB00',}}>已同意</span>
                                                }
                                                {
                                                    item.status === 2 &&
                                                    <span style={{color:'#BB0000',}}>已拒绝</span>
                                                }
                                                {
                                                    item.status === 0 &&
                                                    <div className={'DisplayFlex'} style={{height:20,}}>
                                                        <button className={'TeamNewsBtn'}
                                                                style={{height:20,backgroundColor:'#1ABB00',}}
                                                                onClick={() => this.TeamNewsBtnOk(item,index)}
                                                        >接受</button>
                                                        <button className={'TeamNewsBtn'}
                                                                style={{height:20,backgroundColor:'#BB0000',}}
                                                                onClick={() => this.TeamNewsBtnNo(item,index)}
                                                        >拒绝</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GrTrain;
