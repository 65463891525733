import React, { Component } from 'react';
import CheckboxIcoNot from "../../style/image/CheckboxIcoNot.png";
import CheckboxIcoHave from "../../style/image/CheckboxIcoHave.png";
import PopUp from "../round/PopUp";
import ExportJsonExcel from "js-export-excel";
import './game.css';
import {get, post} from "../../ajax/tools";
//import {post,} from "../../ajax/tools";


class GGSignInfo extends Component {

    constructor(props){
        super(props);
        this.state = {
            UserInfoAndItemArr:[],

            LeaderNum: 0,//领队的数量
            CoachNum: 0,//教练员（辅导员）的数量
            StuNum: 0,//运动员的数量
            LeaderArr: [],//人员数组
            CoachArr: [],
            StuArr: [],
            AlreadyLeaderArr: [],//被选中项目的数组
            AlreadyCoachArr: [],
            AlreadyStuArr: [],

            MatchId:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        let UserInfoAndItemArr = history.UserInfoAndItem;
        let LeaderArr = [];
        let CoachArr = [];
        let StuArr = [];
        for(let i=0;i<UserInfoAndItemArr.length;i++){
            UserInfoAndItemArr[i]['Checkbox'] = false;
            let IdtList = UserInfoAndItemArr[i].IdtShow;
            let UserInfoList = UserInfoAndItemArr[i];
            if (IdtList === '领队'){
                LeaderArr.push(UserInfoList);
            } else if (IdtList === '教练员（辅导员）'){
                CoachArr.push(UserInfoList);
            } else if (IdtList === '运动员'){
                StuArr.push(UserInfoList);
            }
        }
        this.setState({
            LeaderNum: LeaderArr.length,
            CoachNum: CoachArr.length,
            StuNum: StuArr.length,
            LeaderArr: LeaderArr,
            CoachArr: CoachArr,
            StuArr: StuArr,
            UserInfoAndItemArr:history.UserInfoAndItem,
            MatchId:history.MatchId,
        });
    };

    componentDidMount=function() {
        // const history = this.props;
        // let UserInfoAndItemArr = history.UserInfoAndItem;
        // //let UserInfoAndItemArr = this.state.UserInfoAndItemArr;
        // let LeaderArr = [];
        // let CoachArr = [];
        // let StuArr = [];
        // for(let i=0;i<UserInfoAndItemArr.length;i++){
        //     let IdtList = UserInfoAndItemArr[i].IdtShow;
        //     let UserInfoList = UserInfoAndItemArr[i];
        //     if (IdtList === '领队'){
        //         LeaderArr.push(UserInfoList);
        //     } else if (IdtList === '教练员（辅导员）'){
        //         CoachArr.push(UserInfoList);
        //     } else if (IdtList === '运动员'){
        //         StuArr.push(UserInfoList);
        //     }
        // }
        // this.setState({
        //     LeaderNum: LeaderArr.length,
        //     CoachNum: CoachArr.length,
        //     StuNum: StuArr.length,
        //     LeaderArr: LeaderArr,
        //     CoachArr: CoachArr,
        //     StuArr: StuArr,
        //     UserInfoAndItemArr:history.UserInfoAndItem,
        //     MatchId:history.MatchId,
        // });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };





    //单选框
    LeaderCheckboxIco=(item,index)=>{
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    let LeaderArrList = this.state.LeaderArr;
                    LeaderArrList[index]['Checkbox'] = item.Checkbox !== true;
                    let AlreadyLeaderArr = [...this.state.AlreadyLeaderArr];
                    if(item.Checkbox === true){
                        AlreadyLeaderArr.push(item);
                        this.setState({
                            AlreadyLeaderArr:AlreadyLeaderArr,
                        });
                    }else if(item.Checkbox === false){
                        for(let i=0;i<AlreadyLeaderArr.length;i++){
                            if(AlreadyLeaderArr[i].id === item.id){
                                AlreadyLeaderArr.splice(i,1);
                            }
                        }
                        this.setState({
                            AlreadyLeaderArr:AlreadyLeaderArr,
                        });
                    }
                    this.setState({
                        LeaderArr:LeaderArrList,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };



    CoachCheckboxIco=(item,index)=>{
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    let CoachArrList = this.state.CoachArr;
                    CoachArrList[index]['Checkbox'] = item.Checkbox !== true;
                    let AlreadyCoachArr = [...this.state.AlreadyCoachArr];
                    if(item.Checkbox === true){
                        AlreadyCoachArr.push(item);
                        this.setState({
                            AlreadyCoachArr:AlreadyCoachArr,
                        });
                    }else if(item.Checkbox === false){
                        for(let i=0;i<AlreadyCoachArr.length;i++){
                            if(AlreadyCoachArr[i].id === item.id){
                                AlreadyCoachArr.splice(i,1);
                            }
                        }
                        this.setState({
                            AlreadyCoachArr:AlreadyCoachArr,
                        });
                    }
                    this.setState({
                        CoachArr:CoachArrList,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };

    StuCheckboxIco=(item,index)=>{
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    let StuArrList = this.state.StuArr;
                    StuArrList[index]['Checkbox'] = item.Checkbox !== true;
                    let AlreadyStuArr = [...this.state.AlreadyStuArr];
                    if(item.Checkbox === true){
                        AlreadyStuArr.push(item);
                        this.setState({
                            AlreadyStuArr:AlreadyStuArr,
                        });
                    }else if(item.Checkbox === false){
                        for(let i=0;i<AlreadyStuArr.length;i++){
                            if(AlreadyStuArr[i].id === item.id){
                                AlreadyStuArr.splice(i,1);
                            }
                        }
                        this.setState({
                            AlreadyStuArr:AlreadyStuArr,
                        });
                    }
                    this.setState({
                        StuArr:StuArrList,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };



    //删除按钮选项
    LeaderDel(){
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 1){
                    let LeaderArrList = this.state.LeaderArr;
                    let CoachArrList = this.state.CoachArr;
                    let StuArrList = this.state.StuArr;
                    let AlreadyLeaderArrList = this.state.AlreadyLeaderArr;
                    post({
                        url:'match/del_match_enroll_team',
                        data:{
                            data:AlreadyLeaderArrList,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            LeaderArrList= LeaderArrList.filter(item => {
                                let idList= AlreadyLeaderArrList.map(val => val.id);
                                return !idList.includes(item.id);
                            });
                            let UserInfoAndItemDelArr = LeaderArrList.concat(CoachArrList).concat(StuArrList);
                            let ClickInfo = {BackType:'删除人员',UserInfoAndItemDelArr:UserInfoAndItemDelArr,StuArrList:StuArrList,};
                            this.props.GGSignInfo(ClickInfo);
                            this.setState({
                                LeaderArr:LeaderArrList,
                                AlreadyLeaderArr:[],
                                LeaderNum: LeaderArrList.length,
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else if (User.type === 0) {
                    this.setState({
                        PopUp:'账号被顶',
                        MessageTip:'当前设备已登录其他账户，请重新登录！',
                    });
                }
            }else {
                sessionStorage.setItem('routesAddress','/game');
                const {history} = this.props;
                history.push('/login');
            }
        });
    };

    CoachDel(){
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 1){
                    let LeaderArrList = this.state.LeaderArr;
                    let CoachArrList = this.state.CoachArr;
                    let StuArrList = this.state.StuArr;
                    let AlreadyCoachArrList = this.state.AlreadyCoachArr;
                    post({
                        url:'match/del_match_enroll_team',
                        data:{
                            data:AlreadyCoachArrList,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            CoachArrList= CoachArrList.filter(item => {
                                let idList= AlreadyCoachArrList.map(val => val.id);
                                return !idList.includes(item.id);
                            });
                            let UserInfoAndItemDelArr = LeaderArrList.concat(CoachArrList).concat(StuArrList);
                            let ClickInfo = {BackType:'删除人员',UserInfoAndItemDelArr:UserInfoAndItemDelArr,StuArrList:StuArrList,};
                            this.props.GGSignInfo(ClickInfo);
                            this.setState({
                                CoachArr:CoachArrList,
                                AlreadyCoachArr:[],
                                CoachNum: CoachArrList.length,
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else if (User.type === 0) {
                    this.setState({
                        PopUp:'账号被顶',
                        MessageTip:'当前设备已登录其他账户，请重新登录！',
                    });
                }
            }else {
                sessionStorage.setItem('routesAddress','/game');
                const {history} = this.props;
                history.push('/login');
            }
        });
    };

    StuDel(){
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 1){
                    let LeaderArrList = this.state.LeaderArr;
                    let CoachArrList = this.state.CoachArr;
                    let StuArrList = this.state.StuArr;
                    let AlreadyStuArrList = this.state.AlreadyStuArr;
                    post({
                        url:'match/del_match_enroll_team',
                        data:{
                            data:AlreadyStuArrList,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            StuArrList= StuArrList.filter(item => {
                                let idList= AlreadyStuArrList.map(val => val.id);
                                return !idList.includes(item.id);
                            });
                            let UserInfoAndItemDelArr = LeaderArrList.concat(CoachArrList).concat(StuArrList);
                            let ClickInfo = {BackType:'删除人员',UserInfoAndItemDelArr:UserInfoAndItemDelArr,StuArrList:StuArrList,};
                            this.props.GGSignInfo(ClickInfo);
                            this.setState({
                                StuArr:StuArrList,
                                AlreadyStuArr:[],
                                StuNum: StuArrList.length,
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else if (User.type === 0) {
                    this.setState({
                        PopUp:'账号被顶',
                        MessageTip:'当前设备已登录其他账户，请重新登录！',
                    });
                }
            }else {
                sessionStorage.setItem('routesAddress','/game');
                const {history} = this.props;
                history.push('/login');
            }
        });
    };





    //编辑点击
    GGSignInfoEdit=(item,index)=>{
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    let ClickInfo = {BackType:'编辑人员',UserEditInfo:item,};
                    this.props.GGSignInfo(ClickInfo);
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };


    DownData(){
        this.setState({
            PopUp:'提示弹窗',
            MessageTip:'正在导出···',
        });
        let currentPro = this.state.UserInfoAndItemArr;
        let option={};
        let dataTable = [];
        let sheetBasicTitle = ['姓名','ID','身份','组别','项目','联系方式','教练员',];
        if (currentPro) {
            for(let i=0;i<currentPro.length;i++){
                let Item = currentPro[i].item;
                for(let k=0;k<Item.length;k++){
                    let obj = {};
                    obj.姓名 = currentPro[i].real_name;
                    obj.ID = currentPro[i].yy_num;
                    obj.项目 = Item[k].item_name ? Item[k].item_name : '';
                    obj.组别 = currentPro[i].groups;
                    obj.身份 = currentPro[i].identity === 2 ? '运动员' : (currentPro[i].identity === 3 ? '教练员' : (currentPro[i].identity === 4 ? '领队' : '运动员'));
                    obj.联系方式 = currentPro[i].mobile;
                    obj.教练员 = currentPro[i].coach_name === '' ? '' : currentPro[i].coach_name;
                    dataTable.push(obj);
                }
                option.fileName = '团队报名信息表';
                option.datas=[
                    {
                        sheetData:dataTable,
                        sheetName:'sheet1',
                        sheetFilter:sheetBasicTitle,
                        sheetHeader:sheetBasicTitle,
                        columnWidths: [8, 8, 8, 8, 10, 8, 8,]
                    },
                ];
                if (dataTable.length !== 0 && (i+1) === currentPro.length){
                    // console.log(dataTable)
                    const toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'成功',
                    });
                }
            }
        }
    };





    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };
    AccountNext=(event)=>{
        if(event === 'CrossOut'){
            window.location.reload();
        }else if(event === 'Confirm'){
            window.location.reload();
        }
    };

    render() {
        return (
                <div className={'GGAddPeopleBox'} style={{minHeight:546,position:'relative',}}>
                    <div className={'TopOperateBtn clearfix CTBtnWidth'} style={{margin:0,backgroundColor:'#02ba45',position:'absolute',right:'3px',top:'-35px',}} onClick={this.DownData.bind(this)}>
                        <div className={'ZXIco downIco'}> </div>
                    </div>
                    <div className={'GGSInfoTopBox clearfix'}>
                        <div className={'GGSInfoLeaderBox'}>
                            <div className={'GGSInfoTitBox clearfix'}>
                                <div style={{float:'left'}}>领队</div>
                                <div style={{float:'left'}}>[ {this.state.LeaderNum} ]</div>
                                <div className={'GGSInfoTitBtn'}
                                     style={this.state.AlreadyLeaderArr.length !== 0 ? {backgroundColor:'#0068B7',} : {}}
                                     onClick={this.state.AlreadyLeaderArr.length !== 0 ? this.LeaderDel.bind(this) : null}
                                >删除</div>
                            </div>
                            <div className={'GGSInfoTopConBox scrollBar clearfix'}>
                                {
                                    this.state.LeaderArr.map((item,index)=>
                                        <div className={'GGSInfoTopNameList clearfix'} key={index}>
                                            <div className={'GGSInfoListCheckbox'}
                                                 style={{backgroundImage:item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',}}
                                                 onClick={() => this.LeaderCheckboxIco(item,index)}
                                            > </div>
                                            <div className={'GGSInfoListTopName'} onClick={() => this.GGSignInfoEdit(item,index)}>{item.real_name}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'GGSInfoCoachBox'}>
                            <div className={'GGSInfoTitBox clearfix'}>
                                <div style={{float:'left'}}>教练员（辅导员）</div>
                                <div style={{float:'left'}}>[ {this.state.CoachNum} ]</div>
                                <div className={'GGSInfoTitBtn'}
                                     style={this.state.AlreadyCoachArr.length !== 0 ? {backgroundColor:'#0068B7',} : {}}
                                     onClick={this.state.AlreadyCoachArr.length !== 0 ? this.CoachDel.bind(this) : null}
                                >删除</div>
                            </div>
                            <div className={'GGSInfoTopConBox scrollBar clearfix'}>
                                {
                                    this.state.CoachArr.map((item,index)=>
                                        <div className={'GGSInfoTopNameList clearfix'} key={index}>
                                            <div className={'GGSInfoListCheckbox'}
                                                 style={{backgroundImage:item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',}}
                                                 onClick={() => this.CoachCheckboxIco(item,index)}
                                            > </div>
                                            <div className={'GGSInfoListTopName'} onClick={() => this.GGSignInfoEdit(item,index)}>{item.real_name}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'GGSInfoStuBox'} style={{marginTop:42,}}>
                        <div className={'GGSInfoTitBox clearfix'}>
                            <div style={{float:'left'}}>参赛运动员</div>
                            <div style={{float:'left'}}>[ {this.state.StuNum} ]</div>
                            <div className={'GGSInfoTitBtn'}
                                 style={this.state.AlreadyStuArr.length !== 0 ? {backgroundColor:'#0068B7',} : {}}
                                 onClick={this.state.AlreadyStuArr.length !== 0 ? this.StuDel.bind(this) : null}
                            >删除</div>
                        </div>
                        <div className={'GGSInfoStuConTit clearfix'}>
                            <div className={'GGSInfoStuTitList'} style={{width:60}}> </div>
                            <div className={'GGSInfoStuTitList'} style={{width:324}}>姓名（ID）</div>
                            <div className={'GGSInfoStuTitList'} style={{width:200}}>组别</div>
                            <div className={'GGSInfoStuTitList'} style={{width:180}}>兼项数量</div>
                            <div className={'GGSInfoStuTitList'} style={{width:180}}>更改信息</div>
                        </div>
                        <div className={'GGSInfoStuConBorder'}> </div>
                        <div className={'GGSInfoStuContent scrollBar'}>
                            {
                                this.state.StuArr.map((item,index)=>
                                    <div className={'GGSInfoStuContentList clearfix'} key={index}>
                                        <div className={'GGSInfoStuConList'} style={{width:60}}>
                                            <div className={'GGSInfoStuListCheckbox'}
                                                 style={{backgroundImage:item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',}}
                                                 onClick={() => this.StuCheckboxIco(item,index)}
                                            > </div>
                                        </div>
                                        <div className={'GGSInfoStuConList'} style={{width:324}}>{item.real_name}（{item.yy_num}）</div>
                                        <div className={'GGSInfoStuConList'} style={{width:200}}>{item.groups}</div>
                                        <div className={'GGSInfoStuConList'} style={{width:180}}>{item.item.length}</div>
                                        <div className={'GGSInfoStuConList GGSInfoStuUpBtn'} style={{width:180}}
                                             onClick={() => this.GGSignInfoEdit(item,index)}
                                        >编辑</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '账号被顶' &&
                        <PopUp
                            ChildBackClick={this.AccountNext.bind(this)}
                            type={'失败'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default GGSignInfo;
