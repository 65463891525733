import React, { Component } from 'react';
import './exam.css';
import PopUp from "../round/PopUp";
import Triangle from "../round/Triangle";
import ExamAddPeople from "./ExamAddPeople";
import {get, post} from "../../ajax/tools";
//import {get,} from "../../ajax/tools";



class ExamPersonal extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            SubjectIdentity:'教员',//申请个人测试时选择的身份“学员”，“教员”
            ExamRoomName:'请选择考场',//申请个人测试时选择考场
            ExamRoomNameID:'',//申请个人测试时选择考场ID
            ExamRoomNameArr:[],
            ExamRoomNameToggleOn: true,
            ExamRoomNameDisplay: 'none',
            ExamRoomTime:'请选择时间',//申请个人测试时选择时间
            ExamRoomTimeID:'',//申请个人测试时选择时间ID
            ExamRoomTimeArr:[],
            ExamRoomTimeToggleOn: true,
            ExamRoomTimeDisplay: 'none',
            ExamPeTeacherArr:[],//教员时添加的项目
            ExamPeStudentArr:[],//学员时添加的项目
            ExamPersonalArr:[],//个人测试时添加的所有项目
            ExamPageShow:'填写信息',//个人测试的显示页面选项，值为“填写信息”；“添加人员”

            AddPeopleIdNum:'',//添加人员的ID

            MoneySumTeacher:0,//教员总钱数
            MoneySumStudent:0,//学员总钱数


            SelectedPeTeacherList:{},//选中的教员信息的一条
            SelectedPeStudentList:{},//选中的学员信息的一条

            coachId:'',//教练员ID
            coachIdNum:'',//教练员ID号

            PeopleNumber:'no',//剩余人数
            PeopleNum:'',//剩余人数
        };
    }
    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    User:res.data[0],
                    AddPeopleIdNum:res.data[0].yy_num,
                });
            }
        });
        get({url:'exam/get_exam_room'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    ExamRoomNameArr:res.data,
                });
            }else {
                this.setState({
                    ExamRoomNameArr:[],
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
            ExamRoomNameToggleOn: true,
            ExamRoomNameDisplay: 'none',
            ExamRoomTimeToggleOn: true,
            ExamRoomTimeDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //点击选择教员身份
    SubjectTeacherBtn(){
        this.setState({
            SubjectIdentity:'教员',
        });
    };
    //点击选择学员身份
    SubjectStudenBtn(){
        this.setState({
            SubjectIdentity:'学员',
        });
    };



    //点击选择考场的下拉按钮
    ActiveExamRoomNameBtn(e){
        e.nativeEvent.stopImmediatePropagation();
        get({url:'exam/get_exam_room'}).then((res) => {
            if(res.errno === '200'){
                this.setState(prevState => ({
                    ExamRoomNameToggleOn: !prevState.ExamRoomNameToggleOn,
                    ExamRoomNameDisplay:prevState.ExamRoomNameToggleOn ? 'block' : 'none',
                    ExamRoomTimeToggleOn: true,
                    ExamRoomTimeDisplay: 'none',
                }));
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'当前未有开考考场！',
                });
            }
        });
    };
    //选择考场
    ActiveExamRoomName(item,index){
        this.setState({
            ExamRoomName:item.address,
            ExamRoomNameID:item.id,
            ExamRoomTime:'请选择时间',
            ExamRoomTimeID:'',
            PeopleNumber:'no',
            PeopleNum:'',
        });
        get({url:'exam/get_exam_time/'+item.id}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    ExamRoomTimeArr:res.data,
                });
            }else {
                this.setState({
                    ExamRoomTimeArr:[],
                });
            }
        });
    };
    //点击选择时间的下拉按钮
    ActiveExamRoomTimeBtn(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            ExamRoomTimeToggleOn: !prevState.ExamRoomTimeToggleOn,
            ExamRoomTimeDisplay:prevState.ExamRoomTimeToggleOn ? 'block' : 'none',
            ExamRoomNameToggleOn: true,
            ExamRoomNameDisplay: 'none',
        }));
    };
    //选择时间
    ActiveExamRoomTime(item,index){
        this.setState({
            ExamRoomTime:item.start_time.substring(0,16)+' — '+item.end_time.substring(11,16),
            ExamRoomTimeID:item.id,
            PeopleNumber:'show',
            PeopleNum:item.surplus_num,
        });
    };



    //添加人员项目信息
    ExamAddInfoBtn(){
        let AddPeopleIdNum = this.state.AddPeopleIdNum;
        let ExamRoomNameID = this.state.ExamRoomNameID;
        let ExamRoomTimeID = this.state.ExamRoomTimeID;
        let SubjectIdentity = this.state.SubjectIdentity;
        if (ExamRoomNameID !== ''){
            if (ExamRoomTimeID !== ''){
                get({url:'exam/get_exam_per/'+ExamRoomTimeID}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            PeopleNum:res.data[0].surplus_num,
                        });
                    }
                });
                get({url:'reuse/get_personal_info/'+AddPeopleIdNum}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            AddPeopleIdNum:res.data[0].yy_num,
                        });
                        if(SubjectIdentity === '教员'){
                            get({url:'exam/get_exam_room_kind/'+ExamRoomNameID+'/1'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        ExamPageShow:'添加人员',
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'该考场未申请教员考核项目！',
                                    });
                                }
                            });
                        }else if(SubjectIdentity === '学员'){
                            get({url:'exam/get_exam_room_kind/'+ExamRoomNameID+'/0'}).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        ExamPageShow:'添加人员',
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'该考场未申请学员考核项目！',
                                    });
                                }
                            });
                        }
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            } else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'请先选择测试时间！',
                });
            }
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请先选择考场！',
            });
        }
    };




    //个人测试的申请添加信息填写返回
    ExamAddPeopleBack=(event)=>{
        if(event.BackType === 'Cancel'){
            this.setState({
                ExamPageShow:'填写信息',
            });
        }else if(event.BackType === 'Ok'){
            const ExamPeTeacherArr = this.state.ExamPeTeacherArr;
            const ExamPeStudentArr = this.state.ExamPeStudentArr;
            const ExamPeKindIdArr = [];
            const SelectedPeTeacherList = this.state.SelectedPeTeacherList;
            const SelectedPeStudentList = this.state.SelectedPeStudentList;
            if(event.type === '1'){
                if(ExamPeTeacherArr.length !== 0){
                    for(let i=0;i<ExamPeTeacherArr.length;i++){
                        if(ExamPeTeacherArr[i].UserIdNum === event.UserIdNum){
                            let ExamPeKindIdArrList = ExamPeTeacherArr[i].exam_kind_id;
                            ExamPeKindIdArr.push(ExamPeKindIdArrList);
                        }
                    }
                    if(ExamPeKindIdArr.includes(event.exam_kind_id)){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'该人员已经添加过此项目！',
                        });
                    }else {
                        if(event.ApplyType === '个人测试编辑'){
                            for(let i=0;i<ExamPeTeacherArr.length;i++){
                                if(ExamPeTeacherArr[i].UserIdNum === SelectedPeTeacherList.UserIdNum &&
                                    ExamPeTeacherArr[i].exam_kind_id === SelectedPeTeacherList.exam_kind_id
                                ){
                                    ExamPeTeacherArr.splice(i,1,event);
                                }
                            }
                        }else {
                            ExamPeTeacherArr.push(event);
                        }
                    }
                }else {
                    ExamPeTeacherArr.push(event);
                }
                let MoneySum= 0 ;
                for(let i=0;i<ExamPeTeacherArr.length;i++){
                    let ExamPeTeacherList = ExamPeTeacherArr[i];
                    MoneySum= MoneySum+Number(ExamPeTeacherList.price-ExamPeTeacherList.cash_price);
                }
                this.setState({
                    ExamPeTeacherArr:ExamPeTeacherArr,
                    MoneySumTeacher:MoneySum,
                    ExamPageShow:'填写信息',
                });
            }else if (event.type === '0'){
                if(ExamPeStudentArr.length !== 0){
                    for(let i=0;i<ExamPeStudentArr.length;i++){
                        if(ExamPeStudentArr[i].UserIdNum === event.UserIdNum){
                            let ExamPeKindIdArrList = ExamPeStudentArr[i].exam_kind_id;
                            ExamPeKindIdArr.push(ExamPeKindIdArrList);
                        }
                    }
                    if(ExamPeKindIdArr.includes(event.exam_kind_id)){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'该人员已经添加过此项目！',
                        });
                    }else {
                        if(event.ApplyType === '个人测试编辑'){
                            for(let i=0;i<ExamPeStudentArr.length;i++){
                                if(ExamPeStudentArr[i].UserIdNum === SelectedPeStudentList.UserIdNum &&
                                    ExamPeStudentArr[i].exam_kind_id === SelectedPeStudentList.exam_kind_id
                                ){
                                    ExamPeStudentArr.splice(i,1,event);
                                }
                            }
                        }else {
                            ExamPeStudentArr.push(event);
                        }
                    }
                }else {
                    ExamPeStudentArr.push(event);
                }
                let MoneySum= 0 ;
                for(let i=0;i<ExamPeStudentArr.length;i++){
                    let ExamPeStudentList = ExamPeStudentArr[i];
                    MoneySum= MoneySum+Number(ExamPeStudentList.price-ExamPeStudentList.cash_price);
                }
                this.setState({
                    ExamPeStudentArr:ExamPeStudentArr,
                    MoneySumStudent:MoneySum,
                    ExamPageShow:'填写信息',
                });
            }
            this.setState({
                ExamPersonalArr:this.state.ExamPeTeacherArr.concat(this.state.ExamPeStudentArr),
            });
        }
    };



    //教员编辑
    TeacherEdit=(item,index)=>{
        this.setState({
            SelectedPeTeacherList:item,
            SubjectIdentity:'教员',
            AddPeopleIdNum:item.UserIdNum,
            ExamRoomNameID:item.ExamRoomNameID,
            ExamRoomTimeID:item.exam_time_id,
            ExamItemID:item.exam_kind_id,
            ExamPageShow:'编辑人员',
        });
    };
    //学员编辑
    StudentEdit=(item,index)=>{
        this.setState({
            SelectedPeStudentList:item,
            SubjectIdentity:'学员',
            AddPeopleIdNum:item.UserIdNum,
            ExamRoomNameID:item.ExamRoomNameID,
            ExamRoomTimeID:item.exam_time_id,
            ExamItemID:item.exam_kind_id,
            coachIdNum:item.BoundCoachIdNum,
            ExamPageShow:'编辑人员',
        });
    };








    //点击删除(教员)
    TeacherCanceledIco=(item,index)=>{
        this.setState({
            SelectedPeTeacherList:item,
            PopUp:'确认是否删除弹窗',
            MessageTip:'确认是否删除(取消申请)？',
        });
        //console.log(item);
    };
    //点击删除(学员)
    StudentCanceledIco=(item,index)=>{
        this.setState({
            SelectedPeStudentList:item,
            PopUp:'确认是否删除弹窗',
            MessageTip:'确认是否删除(取消申请)？',
        });
        //console.log(item);
    };



    //确认是否删除逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            const ExamPeTeacherArr = this.state.ExamPeTeacherArr;
            const SelectedPeTeacherList = this.state.SelectedPeTeacherList;
            const ExamPeStudentArr = this.state.ExamPeStudentArr;
            const SelectedPeStudentList = this.state.SelectedPeStudentList;
            const SubjectIdentity = this.state.SubjectIdentity;
            if(SubjectIdentity === '教员'){
                for(let i=0;i<ExamPeTeacherArr.length;i++){
                    if(ExamPeTeacherArr[i].UserIdNum === SelectedPeTeacherList.UserIdNum &&
                        ExamPeTeacherArr[i].exam_kind_id === SelectedPeTeacherList.exam_kind_id
                    ){
                        ExamPeTeacherArr.splice(i,1);
                    }
                }
                let MoneySum= 0 ;
                for(let i=0;i<ExamPeTeacherArr.length;i++){
                    let ExamPeTeacherList = ExamPeTeacherArr[i];
                    MoneySum= MoneySum+Number(ExamPeTeacherList.price-ExamPeTeacherList.cash_price);
                }
                this.setState({
                    ExamPeTeacherArr:ExamPeTeacherArr,
                    MoneySumTeacher:MoneySum,
                    PopUp:'',
                });
            }else if(SubjectIdentity === '学员'){
                for(let i=0;i<ExamPeStudentArr.length;i++){
                    if(ExamPeStudentArr[i].UserIdNum === SelectedPeStudentList.UserIdNum &&
                        ExamPeStudentArr[i].exam_kind_id === SelectedPeStudentList.exam_kind_id
                    ){
                        ExamPeStudentArr.splice(i,1);
                    }
                }
                let MoneySum= 0 ;
                for(let i=0;i<ExamPeStudentArr.length;i++){
                    let ExamPeStudentList = ExamPeStudentArr[i];
                    MoneySum= MoneySum+Number(ExamPeStudentList.price-ExamPeStudentList.cash_price);
                }
                this.setState({
                    ExamPeStudentArr:ExamPeStudentArr,
                    MoneySumStudent:MoneySum,
                    PopUp:'',
                });
            }
            this.setState({
                ExamPersonalArr:this.state.ExamPeTeacherArr.concat(this.state.ExamPeStudentArr),
            });
        }
    };



    //保存，稍后支付按钮
    SavePayLater=()=>{
        this.setState({
            PopUp:'保存稍后支付弹窗',
            MessageTip:'是否保存申请信息？并稍后支付 ',
        });
    };
    //立即支付按钮
    SavePay=()=>{
        let ExamPersonalArr = this.state.ExamPersonalArr;
        if(ExamPersonalArr.length !== 0){
            post({
                url:'exam/order_create',
                data:{
                    sub_order:ExamPersonalArr,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.props.ExamPersonalBack({
                        BackType:'立即支付',
                        OrderID:res.orderID,
                    });
                    this.setState({
                        PopUp:'',
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }
    };





    //保存稍后支付弹窗处理
    ChildBackSavePayLater=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            let ExamPersonalArr = this.state.ExamPersonalArr;
            if(ExamPersonalArr.length !== 0){
                post({
                    url:'exam/order_create',
                    data:{
                        sub_order:ExamPersonalArr,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.props.ExamPersonalBack('保存稍后支付');
                        this.setState({
                            PopUp:'',
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            }
        }
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };



    render() {
        return (
            <div className={'scrollBar'} style={{height:window.innerHeight-246,}}>
                {
                    this.state.ExamPageShow === '填写信息' &&
                    <div className={'ExamMainBoxes'}>
                        <div className={'ExamMainBoxesTit'}>填写申请信息</div>
                        <div className={'ExamTimeBox clearfix'} style={{marginBottom:10,}}>
                            <div className={'ExamItemTitName'}>*考场</div>
                            <div className={'DropDownMenuBox clearfix'}>
                                <div className={'DropDownMenuWord'}>{this.state.ExamRoomName}</div>
                                <div className={'DropDownMenuBtn'} onClick={this.ActiveExamRoomNameBtn.bind(this)}>
                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'10px'} Right={'15px'}/>
                                    <div className={'DropDownMenuListBox'} style={{display:this.state.ExamRoomNameDisplay}}>
                                        {
                                            this.state.ExamRoomNameArr.map((item,index) =>
                                                <div className={'DropDownMenuList'}
                                                     key={index}
                                                     onClick={() => this.ActiveExamRoomName(item,index)}
                                                     title={item.address}
                                                >{item.address}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'ExamTimeBox clearfix'} style={{marginBottom:18,}}>
                            <div className={'ExamItemTitName'}>*时间</div>
                            <div className={'DropDownMenuBox clearfix'}>
                                <div className={'DropDownMenuWord'}>{this.state.ExamRoomTime}</div>
                                <div className={'DropDownMenuBtn'} onClick={this.ActiveExamRoomTimeBtn.bind(this)}>
                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'10px'} Right={'15px'}/>
                                    <div className={'DropDownMenuListBox'} style={{display:this.state.ExamRoomTimeDisplay}}>
                                        {
                                            this.state.ExamRoomTimeArr.map((item,index) =>
                                                <div className={'DropDownMenuList'}
                                                     key={index}
                                                     onClick={() => this.ActiveExamRoomTime(item,index)}
                                                >{item.start_time.substring(0,16)} — {item.end_time.substring(11,16)}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.PeopleNumber === 'show'&&
                                <div className={'ExamItemTitName'} style={{marginLeft:20,}}>剩余人数：{this.state.PeopleNum}</div>
                            }
                        </div>

                        <div className={'ExamTimeBox clearfix'}>
                            <div className={'ExamItemTitName ExamSubjectTit'}>*测试信息（人员性质）</div>
                            <div className={'ExamSubjectIdBtn clearfix'}>
                                <div className={'SubjectIdBtnBox'}
                                     style={this.state.SubjectIdentity === '教员' ? {backgroundColor:'#6EC2FF',}:{borderBottom:'1px solid #000000',}}
                                     onClick={this.SubjectTeacherBtn.bind(this)}
                                >教员</div>
                                <div className={'SubjectIdBtnBox'}
                                     style={this.state.SubjectIdentity === '学员' ? {backgroundColor:'#6EC2FF',}:{borderBottom:'1px solid #000000',}}
                                     onClick={this.SubjectStudenBtn.bind(this)}
                                >学员</div>
                            </div>
                        </div>
                        <div className={'ExamSubjectActiveBox clearfix'} style={{height:358,marginBottom:32,}}>
                            <div className={'ExamAddInfoBox'}>
                                <button className={'ExamAddInfoBtn'} onClick={this.ExamAddInfoBtn.bind(this)}>添加信息</button>
                            </div>
                            <div className={'ExamHaveHeader clearfix'} style={{padding:0,color:'#3D3D3D',}}>
                                <div className={'ExamHaveHeaderList'} style={{width:240,}}>项目名称</div>
                                <div className={'ExamHaveHeaderList'} style={{width:198,}}>项目费用</div>
                                <div className={'ExamHaveHeaderList'} style={{width:178,}}>信息变更</div>
                                <div className={'ExamHaveHeaderList'} style={{width:176,}}>删除</div>
                            </div>
                            {
                                this.state.SubjectIdentity === '教员' &&
                                <div className={'ExamListContentBox'}>
                                    {
                                        this.state.ExamPeTeacherArr.map((item,index) =>
                                            <div className={'ExamHaveHeader clearfix'}
                                                 style={{padding:0,color:'#3F3F3F',fontSize:14,margin:'12px 0',}}
                                                 key={index}
                                            >
                                                <div className={'ExamHaveHeaderList'} style={{width:240,}}>{item.kind_name}</div>
                                                <div className={'ExamHaveHeaderList'} style={{width:198,}}>￥{item.price-item.cash_price}.00</div>
                                                <div className={'ExamHaveHeaderList'} style={{width:178,}}>
                                                    <span className={'ClickDetails'} onClick={() => this.TeacherEdit(item,index)}>编辑</span>
                                                </div>
                                                <div className={'ExamHaveHeaderList'} style={{width:176,}}>
                                                    <div className={'CanceledIco'} style={{width:18,height:18,}} onClick={() => this.TeacherCanceledIco(item,index)}> </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            }
                            {
                                this.state.SubjectIdentity === '学员' &&
                                <div className={'ExamListContentBox'}>
                                    {
                                        this.state.ExamPeStudentArr.map((item,index) =>
                                            <div className={'ExamHaveHeader clearfix'}
                                                 style={{padding:0,color:'#3F3F3F',fontSize:14,margin:'12px 0',}}
                                                 key={index}
                                            >
                                                <div className={'ExamHaveHeaderList'} style={{width:240,}}>{item.kind_name}</div>
                                                <div className={'ExamHaveHeaderList'} style={{width:198,}}>￥{item.price-item.cash_price}.00</div>
                                                <div className={'ExamHaveHeaderList'} style={{width:178,}}>
                                                    <span className={'ClickDetails'} onClick={() => this.StudentEdit(item,index)}>编辑</span>
                                                </div>
                                                <div className={'ExamHaveHeaderList'} style={{width:176,}}>
                                                    <div className={'CanceledIco'} style={{width:18,height:18,}} onClick={() => this.StudentCanceledIco(item,index)}> </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            }
                            <div className={'ExamListTotalBox'}>
                                <div className={'ExamListTotal'}>科目数：
                                    {this.state.SubjectIdentity === '教员' ? this.state.ExamPeTeacherArr.length :
                                        (this.state.SubjectIdentity === '学员' ? this.state.ExamPeStudentArr.length : '')}
                                </div>
                                <div className={'ExamListTotal'}> 合计：
                                    <span style={{fontSize:24,color:'#EB3C00',}}>￥
                                        {this.state.SubjectIdentity === '教员' ? this.state.MoneySumTeacher:
                                            (this.state.SubjectIdentity === '学员' ? this.state.MoneySumStudent : '')}
                            </span>
                                </div>
                            </div>
                        </div>
                        <div className={'SettlementBoxes'}>
                            <div className={'SettlementWord'}>[
                                <span style={{color:'#EB3C00',}}>￥{this.state.MoneySumTeacher}</span>(教员) +
                                <span style={{color:'#EB3C00',}}>￥{this.state.MoneySumStudent}</span>(学员)]
                            </div>
                            <div className={'SettlementWordTotal'}>总合计：
                                <span style={{color:'#EB3C00',}}>￥{this.state.MoneySumTeacher+this.state.MoneySumStudent}</span>
                            </div>
                            <div className={'SettlementBtnBox'}>
                                <button className={'SettlementBtn'}
                                        style={{float:'left',borderRadius:'24px 0 0 24px',backgroundColor:this.state.ExamPersonalArr.length === 0 ? '' : '#6DC1FF',}}
                                        onClick={this.state.ExamPersonalArr.length === 0 ? null : this.SavePayLater.bind(this)}
                                >
                                    保存，稍后支付
                                </button>
                                <button className={'SettlementBtn'}
                                        style={{float:'right',borderRadius:'0 24px 24px 0',backgroundColor:this.state.ExamPersonalArr.length === 0 ? '' : '#6DC1FF',}}
                                        onClick={this.state.ExamPersonalArr.length === 0 ? null : this.SavePay.bind(this)}
                                >
                                    立即支付
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.ExamPageShow === '添加人员' &&
                    <ExamAddPeople
                        ExamAddPeopleBack={this.ExamAddPeopleBack.bind(this)}
                        ApplyType={'个人测试添加'}
                        IdType={this.state.SubjectIdentity}
                        IdNum={this.state.AddPeopleIdNum}
                        ExamRoomNameID={this.state.ExamRoomNameID}
                        ExamRoomTimeID={this.state.ExamRoomTimeID}
                    />
                }
                {
                    this.state.ExamPageShow === '编辑人员' &&
                    <ExamAddPeople
                        ExamAddPeopleBack={this.ExamAddPeopleBack.bind(this)}
                        ApplyType={'个人测试编辑'}
                        IdType={this.state.SubjectIdentity}
                        IdNum={this.state.AddPeopleIdNum}
                        ExamRoomNameID={this.state.ExamRoomNameID}
                        ExamRoomTimeID={this.state.ExamRoomTimeID}
                        ExamItemID={this.state.ExamItemID}
                        coachIdNum={this.state.coachIdNum}
                    />
                }
                {
                    this.state.PopUp === '确认是否删除弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackClick.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '保存稍后支付弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackSavePayLater.bind(this)}
                        type={'成功'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ExamPersonal;
