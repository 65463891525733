import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import './msg.css';
import {get} from "../../ajax/tools";
import Pagination from "../round/Pagination";
import PopUp from "../round/PopUp";



class Msg extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : '',
            InquireState:'List',//默认'List'是左侧有菜单的时候样式，当值为'Details'时，是详情渲染页
            InquireDetails:'',//当点击查看具体项的详细信息时，是哪一模块的查询
            InquireNavArr:[
                {
                    ZhName:'系统消息',
                    EnName:'system information',
                },
            ],
            NowNavListZhName:'系统消息',
            NowNavListEnName:'system information',

            MsgArr:[],
            MsgBrr:[],
            newMsg:'',
            MsgInfo:{},

            PageSize: '',
            CurrentPage: 1,
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                get({url:'user/sys_msg_show'}).then((res) => {
                    if(res.errno === '200'){
                        if (res.data === 0){
                            this.setState({
                                newMsg:'0',
                            });
                        }else {
                            let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
                            let PageSize = Math.ceil(H/52)
                            const KeyStart = (this.state.CurrentPage-1)*PageSize;
                            const KeyEnd = this.state.CurrentPage*PageSize;
                            const NewsCenterList = [];
                            if(KeyEnd <= res.data.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const NewsCenterListBrr = res.data[i];
                                    NewsCenterList.push(NewsCenterListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<res.data.length; i++){
                                    const NewsCenterListBrr = res.data[i];
                                    NewsCenterList.push(NewsCenterListBrr)
                                }
                            }

                            this.setState({
                                MsgArr:res.data,
                                MsgBrr:NewsCenterList,
                                PageSize:PageSize,
                                newMsg:'1',
                            });
                        }
                    }else {
                        this.setState({
                            newMsg:'0',
                        });
                    }
                });
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    }


    //点击左侧菜单
    AccountListClick=(item,index) =>{
        this.setState({
            NowNavListZhName:item.ZhName,
            NowNavListEnName:item.EnName,
        });
    };


    NewsBulletinCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const NewsBulletinList = [];
        if(KeyEnd <= this.state.MsgArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const NewsBulletinListBrr = this.state.MsgArr[i];
                NewsBulletinList.push(NewsBulletinListBrr)
            }
        }else {
            for(let i=KeyStart; i<this.state.MsgArr.length; i++){
                const NewsBulletinListBrr = this.state.MsgArr[i];
                NewsBulletinList.push(NewsBulletinListBrr)
            }
        }
        this.setState({
            MsgBrr:NewsBulletinList,
        });
    }



    MsgInfoList=(item,index) =>{
        this.setState({
            MsgInfo:item,
            InquireState:'Details',
        });
    };

    MsgDel=(item,index) =>{
        get({url:'user/sys_msg_del/'+item.id}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
                get({url:'user/sys_msg_show'}).then((res) => {
                    if(res.errno === '200'){
                        if (res.data === 0){
                            this.setState({
                                newMsg:'0',
                            });
                        }else {
                            let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
                            let PageSize = Math.ceil(H/52)
                            const KeyStart = (this.state.CurrentPage-1)*PageSize;
                            const KeyEnd = this.state.CurrentPage*PageSize;
                            const NewsCenterList = [];
                            if(KeyEnd <= res.data.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const NewsCenterListBrr = res.data[i];
                                    NewsCenterList.push(NewsCenterListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<res.data.length; i++){
                                    const NewsCenterListBrr = res.data[i];
                                    NewsCenterList.push(NewsCenterListBrr)
                                }
                            }

                            this.setState({
                                MsgArr:res.data,
                                MsgBrr:NewsCenterList,
                                PageSize:PageSize,
                                newMsg:'1',
                            });
                        }
                    }else {
                        this.setState({
                            newMsg:'0',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };

    BackButton=()=>{
        this.setState({
            InquireState:'List',
        });
    };

    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>{this.state.NowNavListZhName}</div>
                                <div className={'MainConTitEN'}>message</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}</div>
                        </div>
                        <div className={'ContentMainBox clearfix'} style={{minHeight:window.innerHeight-191 > 460 ? window.innerHeight-191 :460,}}>
                            {
                                this.state.InquireState === 'List'&&
                                <div className={'MainLeftNavBox'} style={{minHeight:window.innerHeight-191 > 460 ? window.innerHeight-191 :460,}}>
                                    <ul className={'MainLeftNavListBox'}>
                                        {
                                            this.state.InquireNavArr.map((item,index) =>
                                                <li className={item.ZhName === this.state.NowNavListZhName ? 'MainLeftNavActiveList' : 'MainLeftNavList'}
                                                    key={index}
                                                    onClick={() => this.AccountListClick(item,index)}
                                                >
                                                    {item.ZhName}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                this.state.InquireState === 'List'&& this.state.newMsg === '1' &&
                                <div className={'MainThisNavBox'} style={{height:window.innerHeight-191,}}>
                                    <div className={'InfoListPositionTitBox'}>
                                        <div className={'InfoListPositionTit'}>
                                            <div className={'InfoListPosition'}>
                                                系统消息&gt;&gt;{this.state.NowNavListZhName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'InfoListBorder'}> </div>
                                    <div className={'InfoListBox'} style={{height:window.innerHeight-340 > 360 ? window.innerHeight-340 :360,}}>
                                        {
                                            this.state.MsgBrr.map((item,index) =>
                                                <div className={'clearfix'} key={index}>
                                                    <div className={'InfoListDel'} onClick={() => this.MsgDel(item,index)}><span style={{color:'#ff0000',}}>[删除]</span></div>
                                                    <div className={'InfoList'} style={{float:'left',width:'auto',}}>
                                                        <div className={'InfoListTitle'} onClick={() => this.MsgInfoList(item,index)}>{item.title}</div>
                                                        <div className={'InfoListCenter'} onClick={() => this.MsgInfoList(item,index)}>············</div>
                                                        <div className={'InfoListTime'} onClick={() => this.MsgInfoList(item,index)}>{item.ctime.substring(0,10)}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className={'InfoListBorder'} style={{marginBottom:10,}}> </div>
                                    <div style={{marginBottom:20,}}>
                                        {
                                            this.state.MsgArr.length !== 0 &&
                                            <Pagination
                                                TotalSize={this.state.MsgArr.length}
                                                PageSize={this.state.PageSize}
                                                pageCallbackFn={this.NewsBulletinCurrentPage.bind(this)}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.InquireState === 'List'&& this.state.newMsg === '0' &&
                                <div className={'MainThisNavBox'} style={{height:window.innerHeight-191,}}>
                                    <div className={'InfoListBox scrollBar'} style={{minHeight:window.innerHeight-272 > 460 ? window.innerHeight-272 :460,maxHeight:window.innerHeight-264 > 450 ? 'calc(100vh - 264px)' : '450px'}}>
                                        <div className={'TrainDataMain'} style={{marginTop:100,}}>
                                            <div className={'GameYearNum'} style={{position:'unset',fontSize:54,}}>无系统消息！</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.InquireState === 'Details'&&
                                <div className={'DetailsPackageBox'} style={{minHeight:window.innerHeight-194 > 500 ? window.innerHeight-194 :500,}}>
                                    <div className={'DetailsPackageTop'}>
                                        <div className={'DetailsBackBox clearfix'} onClick={this.BackButton.bind(this)}>
                                            <div className={'DetailsBackIco'}> </div>
                                            <div className={'DetailsBackWord'}>返回</div>
                                        </div>
                                    </div>
                                    <div className={'GYInfoTitle'}>{this.state.MsgInfo.title}</div>
                                    <div className={'GYInfoTimeTitle'}>{this.state.MsgInfo.ctime}</div>
                                    {
                                        this.state.MsgInfo.admin_id !== null &&
                                        <div className={'GYInfoContent'}>
                                            <div dangerouslySetInnerHTML = {{__html: this.state.MsgInfo.content}}/>
                                        </div>
                                    }
                                    {
                                        this.state.MsgInfo.admin_id === null &&
                                        <div className={'MsgInfoContent'}>
                                            {this.state.MsgInfo.content}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Msg;
