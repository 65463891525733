import React, { Component } from 'react'
import './round.css'

class SeTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Second: 10, //验证码按钮屏蔽时间
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      Second: nextProps.Second,
    })
  }
  componentDidMount = function () {
    let Second = this.state.Second
    setInterval(() => {
      let i = Second--
      if (i !== 0 && i > 0) {
        this.setState({ Second: i })
      } else if (i === 0) {
        this.props.SeTimeChange('Stop')
        clearInterval()
      }
    }, 1000)
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  render() {
    return <div style={{ float: 'right' }}>({this.state.Second}s)</div>
  }
}

export default SeTime
