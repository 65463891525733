import React, { Component } from 'react';
import './game.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import Pagination from "../round/Pagination";



class NetGame extends Component {

    constructor(props){
        super(props);
        this.state = {
            NetGameArr:[],
            NetGameArrLength:-1,
            NetGameErr:'',

            NetGameState:'Ico',
            NetListState:'',

            MatchId:'',
            MatchName:'',
            MatchInfo: {},


            StartTime:'',//开始时间
            EndTime:'',//结束时间
            YCItemName:'',//科目名称
            YCItemId:'',//科目Id
            YCItemArr:[],//科目列表
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            GroupName:'',//组别名称
            GroupId:'',//组别Id
            GroupArr:[],//组别列表
            GroupToggleOn: true,
            GroupDisplay:'none',

            ScoreArr:[],
            ScoreBrr:[],
            ScoreBrrLength:0,
            PageSize: '',
            CurrentPage: 1,

            AskScoreArr:[],
            AskScoreBrr:[],
            AskScoreBrrLength:0,

            Pag:1,
            PagBool:false,

            AskHave:'',
        };
    }
    componentDidMount=function() {
        get({url:'match/get_net_match'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    NetGameArr:res.data,
                    NetGameArrLength:res.data.length,
                });
            }else {
                this.setState({
                    NetGameErr:'Err',
                    NetGameArrLength:0,
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            GroupToggleOn: true,
            GroupDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };

    NetGameBtn=(item,index) =>{
        this.setState({
            MatchId:item.id,
            NetGameState:'List',
            MatchInfo:item,
            AskHave:item.promotionQA,
            NetListState:item.promotionQA === 0 ? 'Game' : 'Ask',
            MatchName:item.match_name,
        });
        get({url:'match/get_groups/'+item.id}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GroupArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
        if (item.promotionQA !== 0){
            post({
                url:'match/net_match_write_score_rank',
                data:{
                    match_num_id:item.id,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    if(res.data.length !== 0){
                        const KeyStart = (this.state.CurrentPage-1)*this.state.PageSize;
                        const KeyEnd = this.state.CurrentPage*this.state.PageSize;
                        const SubjectList = [];
                        if(KeyEnd <= res.data.length){
                            for(let i=KeyStart; i<KeyEnd; i++){
                                const SubjectBList = res.data[i];
                                SubjectList.push(SubjectBList)
                            }
                        }else {
                            for(let i=KeyStart; i<res.data.length; i++){
                                const SubjectBList = res.data[i];
                                SubjectList.push(SubjectBList)
                            }
                        }
                        this.setState({
                            AskScoreArr:SubjectList,
                            AskScoreBrr:res.data,
                            AskScoreBrrLength:res.data.length,
                            Pag:this.state.CurrentPage,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'暂无问答成绩数据！',
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }
    };




    GroupDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            GroupToggleOn: !prevState.GroupToggleOn,
            GroupDisplay:prevState.GroupToggleOn ? 'block' : 'none',
        }));
    };

    GroupClick=(item,index)=>{
        let MatchId = this.state.MatchId;
        this.setState({
            GroupId:item.group_id,
            GroupName:item.name,
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            GroupToggleOn: true,
            GroupDisplay: 'none',
            YCItemName:'',//科目名称
            YCItemId:'',//科目Id
        });
        post({
            url:'match/get_item ',
            data:{
                match_id:MatchId,
                mag_id:item.mag_id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    YCItemArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };
    YCItemDown(e){
        e.nativeEvent.stopImmediatePropagation();
        let GroupId = this.state.GroupId;
        if(GroupId !== ''){
            this.setState(prevState => ({
                YCItemToggleOn: !prevState.YCItemToggleOn,
                YCItemDisplay:prevState.YCItemToggleOn ? 'block' : 'none',
            }));
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请先选择组别！',
            });
        }
    };

    YCItemClick=(item,index)=>{
        this.setState({
            YCItemId:item.item_id,
            YCItemName:item.name,
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            GroupToggleOn: true,
            GroupDisplay: 'none',
        });
    };






    //返回选择赛事按钮
    GameInfoBackBtn=()=>{
        this.setState({
            NetGameState:'Ico',
            MatchId:'',
            StartTime:'',//开始时间
            EndTime:'',//结束时间
            YCItemName:'',//科目名称
            YCItemId:'',//科目Id
            YCItemArr:[],//科目列表
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            GroupName:'',//组别名称
            GroupId:'',//组别Id
            GroupArr:[],//组别列表
            GroupToggleOn: true,
            GroupDisplay:'none',
            ScoreArr:[],
            ScoreBrr:[],
            ScoreBrrLength:0,
        });
    };



    YCButton(){
        let PagBool = this.state.PagBool;
        let StartTime = this.state.MatchInfo.match_start_time;
        let EndTime = this.state.MatchInfo.match_end_time;
        let GroupId = this.state.GroupId;
        let YCItemId = this.state.YCItemId;
        let MatchId = this.state.MatchId;
        if (
            GroupId !==''&&
            YCItemId !==''
        ){
            post({
                url:'match/net_match_rank',
                data:{
                    item_id:YCItemId,
                    start_time:StartTime,
                    end_time:EndTime,
                    group_id:GroupId,
                    match_id:MatchId,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    if(res.data.length !== 0){
                        let H = window.innerHeight-387 > 260 ? window.innerHeight-387 :260;
                        let PageSize = Math.ceil(H/32)
                        const KeyStart = (this.state.CurrentPage-1)*PageSize;
                        const KeyEnd = this.state.CurrentPage*PageSize;
                        const SubjectList = [];
                        if(KeyEnd <= res.data.length){
                            for(let i=KeyStart; i<KeyEnd; i++){
                                const SubjectBList = res.data[i];
                                SubjectList.push(SubjectBList)
                            }
                        }else {
                            for(let i=KeyStart; i<res.data.length; i++){
                                const SubjectBList = res.data[i];
                                SubjectList.push(SubjectBList)
                            }
                        }
                        this.setState({
                            ScoreArr:SubjectList,
                            ScoreBrr:res.data,
                            ScoreBrrLength:res.data.length,
                            Pag:this.state.CurrentPage,
                            PageSize:PageSize,
                        });
                        if (PagBool === true){
                            this.setState({
                                PagBool:false,
                            });
                        }else if (PagBool === false){
                            this.setState({
                                PagBool:true,
                            });
                        }
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'暂无飞行数据！',
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        } else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请将必要信息选择完整！',
            });
        }
    };



    getCurrentPage(CurrentPage) {
        let KeyStart = (CurrentPage-1)*this.state.PageSize;
        let KeyEnd = CurrentPage*this.state.PageSize;
        let SubjectList = [];
        if(KeyEnd <= this.state.ScoreBrr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const SubjectBList = this.state.ScoreBrr[i];
                SubjectList.push(SubjectBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.ScoreBrr.length; i++){
                const SubjectBList = this.state.ScoreBrr[i];
                SubjectList.push(SubjectBList)
            }
        }
        this.setState({
            ScoreArr:SubjectList,
            Pag:CurrentPage,
        });
    }
    AskGetCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const SubjectList = [];
        if(KeyEnd <= this.state.AskScoreBrr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const SubjectBList = this.state.AskScoreBrr[i];
                SubjectList.push(SubjectBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.AskScoreBrr.length; i++){
                const SubjectBList = this.state.AskScoreBrr[i];
                SubjectList.push(SubjectBList)
            }
        }
        this.setState({
            AskScoreArr:SubjectList,
            Pag:CurrentPage,
        });
    }


    GameListButton(){
        this.setState({
            NetListState:'Game',
            YCItemName:'',//科目名称
            YCItemId:'',//科目Id
            YCItemArr:[],//科目列表
            YCItemToggleOn: true,
            YCItemDisplay:'none',
            GroupName:'',//组别名称
            GroupId:'',//组别Id
            GroupToggleOn: true,
            GroupDisplay:'none',
            ScoreArr:[],
            ScoreBrr:[],
            ScoreBrrLength:0,


        });
    };
    GameAskButton(){
        this.setState({
            NetListState:'Ask',
        });
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
            <div>
                {
                    this.state.NetGameState === 'Ico' &&
                    <div className={'GameMainBox scrollBar'} style={{height:window.innerHeight-191,}}>
                        <div className={'InfoListPositionTitBox'}>
                            <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>
                                <div className={'InfoListPosition'}>选择赛事查看成绩排行</div>
                            </div>
                        </div>
                        {
                            this.state.NetGameArrLength !== 0 &&
                            <div className={'GameSignUpIcoBoxes clearfix'}>
                                {
                                    this.state.NetGameArr.map((item,index) => (
                                        <div className={'GameSignUpIcoBox'} key={index}>
                                            <div className={'GameSignUpIco'} onClick={() => this.NetGameBtn(item,index)}> </div>
                                            <div className={'GameSignUpIcoTitle'}>{item.match_name}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        {
                            this.state.NetGameArrLength === 0 &&
                            <div className={'NotGameTip'}>
                                <div className={'TrainDataMain'}>
                                    <div className={'GameYearNum'} style={{position:'unset',fontSize:72,}}>当前未有成绩排行！</div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.NetGameState === 'List' && this.state.NetListState === 'Game' &&
                    <div className={'GameMainBox'}>
                        <div className={'GameInfoTitleBox clearfix'}>
                            <div className={'InfoListPosition'} style={{height:28,lineHeight:'28px',textAlign:'left',textIndent:'2em',}}>{this.state.MatchName}</div>
                        </div>
                        <div className={'NetGameListTitleBox'}>
                            <div className={'YunChengTimeBox GameWordPublic'} style={{width:170,lineHeight:'34px',margin:'0 4px',}}>
                                *请选择组别项目：
                            </div>
                            <div className={'YunChengItemBox'} style={{margin:'0 6px',width:220,cursor:'pointer',}}>
                                <div className={'YCItemName'} style={{width:190,}} onClick={this.GroupDown.bind(this)}>{this.state.GroupName === '' ? '请选择组别'  : this.state.GroupName}</div>
                                <div className={'YCItemIco'} onClick={this.GroupDown.bind(this)}>
                                    <div className={'icon-arrow-down'}> </div>
                                </div>
                                <div className={'YCItemNavBox scrollBar'} style={{width:220,display:this.state.GroupDisplay,height:'auto',minHeight:'36px',maxHeight:'360px',}}>
                                    {
                                        this.state.GroupArr.map((item,index) =>
                                            <div className={'YCItemNav'}
                                                 key={index}
                                                 onClick={() => this.GroupClick(item,index)}
                                                 style={this.state.GroupName === item.name ? {backgroundColor:'#6DC1FF',width:219,} : {width:219,}}>
                                                {item.name}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={'YunChengItemBox'} style={{margin:'0 6px',width:260,cursor:'pointer',}}>
                                <div className={'YCItemName'} style={{width:230,}} onClick={this.YCItemDown.bind(this)}>{this.state.YCItemName === '' ? '请选择项目'  : this.state.YCItemName}</div>
                                <div className={'YCItemIco'} onClick={this.YCItemDown.bind(this)}>
                                    <div className={'icon-arrow-down'}> </div>
                                </div>
                                <div className={'YCItemNavBox scrollBar'} style={{width:260,display:this.state.YCItemDisplay,height:'auto',minHeight:'36px',maxHeight:'360px',}}>
                                    {
                                        this.state.YCItemArr.map((item,index) =>
                                            <div className={'YCItemNav'}
                                                 key={index}
                                                 onClick={() => this.YCItemClick(item,index)}
                                                 style={this.state.YCItemName === item.name ? {backgroundColor:'#6DC1FF',width:258,} : {width:258,}}>
                                                {item.name}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={'YCButton'} style={{margin:'0 6px',textIndent:'0.4em',}} onClick={this.YCButton.bind(this)}>确认</div>
                            {
                                this.state.AskHave === 1 &&
                                <div className={'YCButton'}
                                     style={{float:'left',margin:'0 10px 0 8px',width:'auto',padding:'0 6px',letterSpacing:'0.02em',}}
                                     onClick={this.GameAskButton.bind(this)}
                                >知识问答排行</div>
                            }
                            <div className={'TopOperateBtn clearfix'} style={{margin:'0 20px 0 0',height:34,backgroundColor:'#DB6464',}} onClick={this.GameInfoBackBtn.bind(this)}>
                                <div className={'BackIcoBox'}>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-35deg)',top:'4px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-145deg)',top:'14px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{top:'9px',left:'1px',width:20,}}> </div>
                                </div>
                                <div className={'TopOperateBtnWord'} style={{lineHeight:'34px',}}>返回</div>
                            </div>
                        </div>
                        <div className={'NetGameListBox'} style={{height:window.innerHeight-333 > 320 ? window.innerHeight-333 :320,marginBottom:10,}}>
                            <div className={'NetGameTitleBox GameWordPublic clearfix'}>
                                <div className={'NetGameTitleList'} style={{width:194,}}>排名</div>
                                <div className={'NetGameTitleList'} style={{width:380,}}>姓名</div>
                                <div className={'NetGameTitleList'} style={{width:380,}}>成绩（时间）</div>
                            </div>
                            <div className={'NetGameListMainBox'} style={{height:window.innerHeight-355 > 294 ? window.innerHeight-355 :294,}}>
                                {
                                    this.state.ScoreArr.map((item,index) =>
                                        <div className={'NetGameTitleBox GameWordPublic clearfix'} style={{border:0,}} key={index}>
                                            <div className={'NetGameTitleList'} style={{width:194,}}>{(this.state.Pag-1)*this.state.PageSize+index+1}</div>
                                            <div className={'NetGameTitleList'} style={{width:380,}}>{item.real_name}</div>
                                            <div className={'NetGameTitleList'} style={{width:380,}}>{item.score}</div>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                this.state.ScoreBrrLength !== 0 && this.state.PagBool !== true &&
                                <Pagination
                                    TotalSize={this.state.ScoreBrrLength}
                                    PageSize={this.state.PageSize}
                                    pageCallbackFn={this.getCurrentPage.bind(this)}
                                />
                            }
                            {
                                this.state.ScoreBrrLength !== 0 && this.state.PagBool !== false &&
                                <Pagination
                                    TotalSize={this.state.ScoreBrrLength}
                                    PageSize={this.state.PageSize}
                                    pageCallbackFn={this.getCurrentPage.bind(this)}
                                />
                            }
                        </div>
                    </div>
                }
                {
                    this.state.NetGameState === 'List' && this.state.NetListState === 'Ask' &&
                    <div className={'GameMainBox'}>
                        <div className={'GameInfoTitleBox clearfix'}>
                            <div className={'GameInfoTitleBox clearfix'}>
                                <div className={'InfoListPosition'} style={{height:28,lineHeight:'28px',textAlign:'left',textIndent:'2em',}}>{this.state.MatchName}</div>
                            </div>
                        </div>
                        <div className={'NetGameListTitleBox'}>
                            <div className={'YunChengTimeBox GameWordPublic'} style={{width:'auto',lineHeight:'34px',}}>
                                *知识问答项目排行展示：
                            </div>
                            <div className={'TopOperateBtn clearfix'} style={{margin:'0 20px 0 0',height:34,backgroundColor:'#DB6464',}} onClick={this.GameInfoBackBtn.bind(this)}>
                                <div className={'BackIcoBox'}>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-35deg)',top:'4px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-145deg)',top:'14px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{top:'9px',left:'1px',width:20,}}> </div>
                                </div>
                                <div className={'TopOperateBtnWord'} style={{lineHeight:'34px',}}>返回</div>
                            </div>
                            <div className={'YCButton'}
                                 style={{float:'right',marginRight:20,width:'auto',padding:'0 16px',textIndent:'0.4em'}}
                                 onClick={this.GameListButton.bind(this)}
                            >查看飞行成绩排行</div>
                        </div>
                        <div className={'NetGameListBox'} style={{height:window.innerHeight-333 > 320 ? window.innerHeight-333 :320,}}>
                            <div className={'NetGameTitleBox GameWordPublic clearfix'}>
                                <div className={'NetGameTitleList'} style={{width:54,}}>序号</div>
                                <div className={'NetGameTitleList'} style={{width:200,}}>姓名</div>
                                <div className={'NetGameTitleList'} style={{width:350,}}>成绩</div>
                                <div className={'NetGameTitleList'} style={{width:350,}}>用时</div>
                            </div>
                            <div className={'NetGameListMainBox'} style={{height:window.innerHeight-355 > 294 ? window.innerHeight-355 :294,}}>
                                {
                                    this.state.AskScoreArr.map((item,index) =>
                                        <div className={'NetGameTitleBox GameWordPublic clearfix'} style={{border:0,}} key={index}>
                                            <div className={'NetGameTitleList'} style={{width:54,}}>{(this.state.Pag-1)*this.state.PageSize+index+1}</div>
                                            <div className={'NetGameTitleList'} style={{width:200,}}>{item.real_name}</div>
                                            <div className={'NetGameTitleList'} style={{width:350,}}>{item.all_score}</div>
                                            <div className={'NetGameTitleList'} style={{width:350,}}>{item.all_time} s</div>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                this.state.AskScoreBrrLength !== 0 &&
                                <Pagination
                                    TotalSize={this.state.AskScoreBrrLength}
                                    PageSize={this.state.PageSize}
                                    pageCallbackFn={this.AskGetCurrentPage.bind(this)}
                                />
                            }
                        </div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default NetGame;
