import React, { Component } from 'react';
import './member.css';
import '../auth/auth.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
class Trick extends Component {
    constructor(props){
        super(props);
        this.state = {
            name:'',
            QQNum:'',
            signUp:'',
		}
		 
	}
    componentDidMount=function() {
        get({url:'user/check_train_class'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    signUp:'yes',
                });
            }else {
                this.setState({
                    signUp:'not',
                });
            }
        });
    };


    nameChange(e){
        this.setState({
            name: e.target.value
        })
    };
    QQNumChange(e){
        e.target.value = e.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            QQNum: e.target.value
        })
    };

    TrickBtn(){
        let name = this.state.name;
        let QQNum = this.state.QQNum;
        if(name !== '' && QQNum !== ''){
            post({
                url:'user/train_class_enroll',
                data:{
                    QQNumber:QQNum,
                    Nictname:name,
                },
            }).then((res) => {
                if (res.Status === 200) {
                    this.setState({
                        signUp:'yes',
                    });
                } else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请将信息填写完整！',
            });
        }
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

  render() {
        return (
            <div style={{position:'relative',width:800,margin:'100px auto',height:'auto',minHeight:40,}}>
                {
                    this.state.signUp === 'not'&&
                    <div>
                        <div className={'TrickTitle'}>ASFC虚拟特技飞行训练班申请入口</div>
                        <div className={'TrickRemarks'}>申请成功后请询问群管理员索要临时编号！QQ群:1146487016</div>
                        <div className={'TrickInfoBox clearfix'}>
                            <div className={'TrickInfoTit'}>您的群内昵称：</div>
                            <div className={'TrickInfoWord'}>
                                <input className={'TrickInfoInput'}
                                       type={'text'}
                                       name={'name'}
                                       value={this.state.name}
                                       onChange={this.nameChange.bind(this)}
                                       placeholder={''}
                                       autoComplete={'off'}
                                />
                            </div>
                        </div>
                        <div className={'TrickInfoBox clearfix'}>
                            <div className={'TrickInfoTit'}>请输入在群内QQ号：</div>
                            <div className={'TrickInfoWord'}>
                                <input className={'TrickInfoInput'}
                                       type={'text'}
                                       name={'QQNum'}
                                       value={this.state.QQNum}
                                       onChange={this.QQNumChange.bind(this)}
                                       placeholder={''}
                                       autoComplete={'off'}
                                />
                            </div>
                        </div>
                        <div className={'TrickInfoBtn'} onClick={this.TrickBtn.bind(this)}>提交</div>
                    </div>
                }
                {
                    this.state.signUp === 'yes'&&
                    <div>
                        <div className={'ReSuccessBox'} style={{marginTop:180,marginBottom:24,}}>
                            <div className={'ReSuccessIco'}> </div>
                            <div className={'ReSuccessWord'}>您已经申请成功！</div>
                        </div>
                        <div className={'TrickRemarks'} style={{fontSize:16,color:'#FF0000',}}>申请成功后请询问群管理员索要临时编号！QQ群:1146487016</div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>

        );
    }

}

export default Trick;

