import React, { Component } from 'react';
import './approve.css';
import {get} from "../../ajax/tools";


class ApproveList extends Component {

    constructor(props){
        super(props);
        this.state = {
            ApproveArr:[],
            AllYearState:'去年检',//年检状态的的标记识别，值为'去年检'，'年检中'，'已年检'，
            NewApproveState:'重新认证',//认证状态的的标记识别，值为'重新认证'，'已认证'，
            ApproveListIdt:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        if(history.ApproveListIdt === '设备'){
            get({url:'agency/read_device_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveArr:res.data,
                    });
                }
            });
        }else if(history.ApproveListIdt === '训练中心'){
            get({url:'agency/read_train_institute'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveArr:res.data,
                    });
                }
            });

        }else if(history.ApproveListIdt === '青少年航校'){
            get({url:'agency/read_youth_aviat_school'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveArr:res.data,
                    });
                }
            });
        }else if(history.ApproveListIdt === '实训基地'){
            get({url:'agency/read_train_base'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveArr:res.data,
                    });
                }
            });
        }
        this.setState({
            ApproveListIdt:history.ApproveListIdt,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        if(history.ApproveListIdt === '设备'){
            get({url:'agency/read_device_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveArr:res.data,
                    });
                }
            });
        }else if(history.ApproveListIdt === '训练中心'){
            get({url:'agency/read_train_institute'}).then((res) => {
                if(res.errno === '200'){
                    let a = res.data;
                    let sb = [];
                    for(let i=0; i<a.length; i++){
                        let s = a[i];
                        if (s.code !== ''){
                            sb.push(s);
                        }
                    }
                    this.setState({
                        ApproveArr:sb,
                    });
                }
            });

        }else if(history.ApproveListIdt === '青少年航校'){
            get({url:'agency/read_youth_aviat_school'}).then((res) => {
                if(res.errno === '200'){
                    let a = res.data;
                    let sb = [];
                    for(let i=0; i<a.length; i++){
                        let s = a[i];
                        if (s.code !== ''){
                            sb.push(s);
                        }
                    }
                    this.setState({
                        ApproveArr:sb,
                    });
                }
            });
        }else if(history.ApproveListIdt === '实训基地'){
            get({url:'agency/read_train_base'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ApproveArr:res.data,
                    });
                }
            });
        }
        this.setState({
            ApproveListIdt:history.ApproveListIdt,
        });
    };



    //查看详情
    ApproveDeviceReview=(item,index)=>{
        let ClickInfo = {
            ClickInfoBtn:'查看详情',
            Id:item.id,
        };
        this.props.ListInfoClick(ClickInfo);
    };


    //点击去年检
    GoAllYear=(item,index)=>{
        let ClickInfo = {
            ClickInfoBtn:'去年检',
            Id:item,
        };
        this.props.ListInfoClick(ClickInfo);
    };
    //点击重新认证
    GoNewApprove=(item,index)=>{
        let ClickInfo = {
            ClickInfoBtn:'重新认证',
            Id:item,
        };
        this.props.ListInfoClick(ClickInfo);
    };


    render() {
        return (
            <div className={'ApproveMainBox'}>
                <div className={'ApproveHaveHeader clearfix'}>
                    <div className={'ApproveHaveHeaderList'} style={{width:140,}}>{this.state.ApproveListIdt}详情</div>
                    <div className={'ApproveHaveHeaderList'} style={{width:380,}}>已认证{this.state.ApproveListIdt}名称</div>
                    {
                        this.state.ApproveListIdt === '设备' || this.state.ApproveListIdt === '训练中心'||
                        <div className={'ApproveHaveHeaderList'} style={{width:150,}}>基础设备数量</div>
                    }
                    {
                        this.state.ApproveListIdt === '青少年航校' || this.state.ApproveListIdt === '实训基地'||
                        <div className={'ApproveHaveHeaderList'} style={{width:150,}}>认证级别</div>
                    }
                    <div className={'ApproveHaveHeaderList'} style={{width:150,}}>年检状态</div>
                    <div className={'ApproveHaveHeaderList'} style={{width:150,}}>认证状态</div>
                </div>
                <div className={'scrollBar'} style={{height:window.innerHeight-324,}}>
                    {
                        this.state.ApproveArr.map((item,index) =>
                            <div className={'ApproveHaveCon clearfix'} key={index}>
                                <div className={'ApproveHaveConList'} style={{width:140,margin:'0 auto',}}>
                                    <div className={'ApproveDeviceLogoBox'}>
                                        <img
                                            className={'ApproveDeviceLogo'}
                                            alt={''}
                                            src={item.image}
                                        />
                                        <div className={'ApproveDeviceLogoHoverBox'} onClick={() => this.ApproveDeviceReview(item,index)}>
                                            <div className={'BrandName'}>{item.brand}</div>
                                            <div className={'ApproveDeviceReview'}>{this.state.ApproveListIdt}详情</div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.ApproveListIdt === '设备'&&
                                    <div className={'ApproveHaveConList'} title={item.name} style={{width:380,}}>{item.name}</div>
                                }
                                {
                                    this.state.ApproveListIdt === '设备'||
                                    <div className={'ApproveHaveConList'} title={item.name} style={{width:380,}}>{item.name}</div>
                                }
                                {
                                    this.state.ApproveListIdt === '设备'&&
                                    <div className={'ApproveHaveConList'} style={{width:150,}}>{item.level}</div>
                                }
                                {
                                    this.state.ApproveListIdt === '训练中心' &&
                                    <div className={'ApproveHaveConList'} style={{width:150,}}>
                                        {
                                            item.level1 === '认证' &&
                                            <span>初级</span>
                                        }
                                        {
                                            item.level2 === '认证' &&
                                            <span>/中级</span>
                                        }
                                        {
                                            item.level3 === '认证' &&
                                            <span>/高级</span>
                                        }
                                    </div>
                                }
                                {
                                    this.state.ApproveListIdt === '设备' || this.state.ApproveListIdt === '训练中心'||
                                    <div className={'ApproveHaveConList'} style={{width:150,}}>{item.device_num}</div>
                                }
                                <div className={'ApproveHaveConList'} style={{width:150,}}>
                                    {
                                        item.ischeck === '1' && item.status === 1 &&
                                        <span>正常</span>
                                    }
                                    {
                                        item.ischeck === '0' && item.status === 1 &&
                                        <span className={'NotYearOrNotApp'} onClick={() => this.GoAllYear(item,index)}>去年检>></span>
                                    }
                                    {
                                        item.status === 2 &&
                                        <span>无效</span>
                                    }
                                </div>
                                <div className={'ApproveHaveConList'} style={{width:150,}}>
                                    {
                                        item.status === 1 &&
                                        <span>已认证</span>
                                    }
                                    {
                                        item.status === 2 &&
                                        <span>未认证</span>
                                    }
                                    {
                                        item.status === 0 &&
                                        <span className={'NotYearOrNotApp'} onClick={() => this.GoNewApprove(item,index)}>重新认证>></span>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}


export default ApproveList;
