import React, { Component } from 'react';
import AuthHeader from "../handf/AuthHeader";
import './showfile.css';


class AboutMyself extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }
    componentDidMount=function() {

    };



    render() {
        return (
            <div className={'MainBox'} style={{backgroundImage:'none'}}>
                <AuthHeader/>
                <div className={'MainContent'}>
                    <div className={'FriendLinkBox clearfix'}>
                        <div className={'MineSloGon'}> </div>
                        <div className={'MineWordBox'} style={{marginTop:80,marginBottom:40,}}>
                            <div style={{margin:'0 auto',height:360}}>
                                <div className={'AboutMyselfInfoInfo'} style={{textAlign:'center',}}>韩昱老师 ：13671194098 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AboutMyself;
