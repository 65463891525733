import React, {Component} from 'react';
import './pay.css';
import CheckboxIcoNot from "../../style/image/CheckboxIcoNot.png";
import CheckboxIcoHave from "../../style/image/CheckboxIcoHave.png";
import {get, post,} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class PayTransfer extends Component {

    constructor(props){
        super(props);
        this.state = {
            orderId:'',//二维码的orderId
            InvoiceToggleOn: true,//全选框
            InvoiceBgImg:'url('+CheckboxIcoNot+')',//全选框的背景图
            InvoiceTitToggleOn: '',
            InvoiceTitle: '',
            InvoiceNumber: '',
            InvoiceSave: false,

            OrderIdInfo:{},
            OrderInfo:{realpay:0,},
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        this.setState({
            OrderIdInfo:nextProps.OrderIdInfo,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        this.setState({
            OrderIdInfo:history.OrderIdInfo,
        });
        get({url:"payment/order_fee/"+history.OrderIdInfo.OrderID}).then(res => {
            if (res.errno === '200') {
                this.setState({
                    OrderInfo:res.data[0],
                });
                if(res.data[0].is_invoice === 1){
                    this.setState({
                        InvoiceSave: true,
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };

    PayTypeBackBtn(){
        this.props.PayPageSuccess('PayTypeBack');
    };


    InvoiceCheckbox=()=>{
        this.setState(prevState => ({
            InvoiceToggleOn: !prevState.InvoiceToggleOn,
            InvoiceBgImg:prevState.InvoiceToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',
        }));
    };

    InvoiceTitPe(){
        this.setState({
            InvoiceTitToggleOn: '个人',
        });
    };

    InvoiceTitGr(){
        this.setState({
            InvoiceTitToggleOn: '公司',
        });
    };

    InvoiceTitle(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            InvoiceTitle: event.target.value,
        });
    };
    InvoiceNumber(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            InvoiceNumber: event.target.value,
        });
    };


    InvoiceSaveBtn(){
        let InvoiceTitToggleOn = this.state.InvoiceTitToggleOn;
        let InvoiceTitle = this.state.InvoiceTitle;
        let InvoiceNumber = this.state.InvoiceNumber;
        let OrderID = this.state.OrderIdInfo.OrderID;
        if(InvoiceTitToggleOn !==''&& InvoiceTitle !==''&& InvoiceNumber!==''){
            post({
                url:'exam/add_invoice',
                data:{
                    order_id:OrderID,
                    invoice_type:InvoiceTitToggleOn === '个人' ? '0' : (InvoiceTitToggleOn === '公司' ? '1' : ''),
                    invoice_top:InvoiceTitle,
                    invoice_code:InvoiceNumber,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    get({url:"payment/order_fee/"+OrderID}).then(res => {
                        if (res.errno === '200') {
                            this.setState({
                                OrderInfo:res.data[0],
                            });
                            if(res.data[0].is_invoice === 1){
                                this.setState({
                                    InvoiceSave: true,
                                });
                            }
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg+'！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您还有必要信息未填写！',
            });
        }
    };

    SavePayTransferBtn(){
        let OrderInfo = this.state.OrderInfo;
        post({
            url:'payment/re_transfer_accounts_exam_order',
            data:OrderInfo,
        }).then((res) => {
            if(res.errno === '200'){
                this.props.PayPageSuccess('SuccessTransfer');
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    }


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
            <div className={'PayBoxes scrollBar'} style={{height:window.innerHeight-310,}}>
                {/*<div className={'PayTypeBackBtn'} style={{top:-68,}} onClick={this.PayTypeBackBtn.bind(this)}> </div>*/}
                <div className={'PayInfoTitBox'} style={{marginTop:20,}}>
                    <div className={'PayInfoTit'}>收款方信息</div>
                </div>
                <div className={'PayInfoBox'} style={{marginBottom:20,height:'auto',minHeight:200,position:'relative',}}>
                    {/*<div className={'PayYYInfoBox'}>*/}
                    {/*    <div className={'PayYYListBox'}>*/}
                    {/*        <div className={'PayYYListTit'}>收款户名</div>*/}
                    {/*        <div className={'PayYYListCon'}>北京鹰扬科技有限公司</div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'PayYYListBox'}>*/}
                    {/*        <div className={'PayYYListTit'}>收款账号</div>*/}
                    {/*        <div className={'PayYYListCon'}>0200213509020041724</div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'PayYYListBox'}>*/}
                    {/*        <div className={'PayYYListTit'}>收款银行</div>*/}
                    {/*        <div className={'PayYYListCon'}>中国工商银行北京安华桥西支行</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'PayYYInfoBox'}>
                        <div className={'PayYYListBox'}>
                            <div className={'PayYYListTit'}>收款户名</div>
                            <div className={'PayYYListCon'}>河南鹰扬软件科技有限公司</div>
                        </div>
                        <div className={'PayYYListBox'}>
                            <div className={'PayYYListTit'}>收款账号</div>
                            <div className={'PayYYListCon'}>1702100609100074228</div>
                        </div>
                        <div className={'PayYYListBox'}>
                            <div className={'PayYYListTit'}>收款银行</div>
                            <div className={'PayYYListCon'}>中国工商银行郑州宝龙广场支行</div>
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    河南鹰扬软件科技有限公司*/}
                    {/*    通讯地址：河南省郑州市金水区东风路28号院19号楼21层2116号*/}
                    {/*    开户名称：河南鹰扬软件科技有限公司*/}
                    {/*    开户银行：中国工商银行股份有限公司郑州宝龙广场支行*/}
                    {/*    帐号：1702100609100074228*/}
                    {/*    纳税人识别号：91410105MACG761200*/}
                    {/*</div>*/}
                    <div className={'PayMoneyWord'} style={{marginRight:56,marginTop:80,}}>元</div>
                    <div className={'PayMoney￥Word'} style={{marginTop:60,}}>￥{this.state.OrderInfo.realpay}</div>
                    <div className={'PayMoneyWord'} style={{marginTop:80,}}>转账</div>
                    <button className={'SavePayTransferBtn'} onClick={this.SavePayTransferBtn.bind(this)}>生成订单</button>
                </div>
                <div className={'PayInfoTitBox'}>
                    <div className={'PayInfoTit'}>产品信息</div>
                </div>
                <div className={'PayInfoBox'} style={{marginBottom:20,}}>
                    <div className={'PayInfoHeaderBox'}>
                        <div className={'PayInfoHeaderList'} style={{width:320,borderRight:'2px solid #DCDCDC',}}>收款方</div>
                        <div className={'PayInfoHeaderList'} style={{width:320,borderRight:'2px solid #DCDCDC',}}>产品</div>
                        <div className={'PayInfoHeaderList'} style={{width:320,}}>合计</div>
                    </div>
                    <div className={'PayInfoMainBox'}>
                        <div className={'PayInfoMainList'} style={{width:320,borderRight:'2px solid #DCDCDC',}}>中国航协模拟飞行服务平台</div>
                        <div className={'PayInfoMainList'} style={{width:320,borderRight:'2px solid #DCDCDC',}}>测试项目费用</div>
                        <div className={'PayInfoMainList'} style={{width:320,}}>￥{this.state.OrderInfo.realpay}</div>
                    </div>
                </div>
                <div className={'PayInfoTitBox'} style={{marginBottom:20,}}>
                    <div className={'InvoiceInfoCheckbox'}
                         style={{backgroundImage:this.state.InvoiceBgImg}}
                         onClick={this.InvoiceCheckbox.bind(this)}
                    > </div>
                    <div className={'InvoiceInfoTit'}>发票<span style={{color:'#7B7B7B',}}>(勾选是否需要发票)</span></div>
                </div>
                {
                    this.state.InvoiceToggleOn === false && this.state.InvoiceSave === false &&
                    <div>
                        <div className={'PayInfoTitBox'} style={{marginBottom:20,}}>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:98,}}>*抬头类型</div>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:24,}} onClick={this.InvoiceTitPe.bind(this)}>
                                <div className={'InvoiceInfoListIco'} style={{backgroundColor:this.state.InvoiceTitToggleOn === '个人' ? '#00B7EF' : ''}}> </div>
                                <span style={{cursor:'pointer',}}>个人</span>
                            </div>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:24,}} onClick={this.InvoiceTitGr.bind(this)}>
                                <div className={'InvoiceInfoListIco'} style={{backgroundColor:this.state.InvoiceTitToggleOn === '公司' ? '#00B7EF' : ''}}> </div>
                                <span style={{cursor:'pointer',}}>公司</span>
                            </div>
                        </div>
                        <div className={'PayInfoTitBox'} style={{marginBottom:20,}}>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:98,}}>*发票抬头</div>
                            <input className={'InvoiceInput'}
                                   type={'text'}
                                   name={'InvoiceTitle'}
                                   value={this.state.InvoiceTitle}
                                   onChange={this.InvoiceTitle.bind(this)}
                                   placeholder={''}
                                   autoComplete={'off'}
                            />
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:54,}}>*纳税人识别号</div>
                            <input className={'InvoiceInput'}
                                   type={'text'}
                                   name={'InvoiceNumber'}
                                   value={this.state.InvoiceNumber}
                                   onChange={this.InvoiceNumber.bind(this)}
                                   placeholder={''}
                                   autoComplete={'off'}
                            />
                        </div>
                        <button className={'InvoiceSaveBtn'} onClick={this.InvoiceSaveBtn.bind(this)}>保存</button>
                    </div>
                }
                {
                    this.state.InvoiceToggleOn === false && this.state.InvoiceSave === true &&
                    <div>
                        <div className={'PayInfoTitBox'} style={{marginBottom:20,}}>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:98,}}>*抬头类型</div>
                            {
                                this.state.OrderInfo.invoice_type === 0&&
                                <div className={'InvoiceInfoListTit'} style={{marginLeft:24,}}>
                                    <span style={{cursor:'pointer',}}>个人</span>
                                </div>
                            }
                            {
                                this.state.OrderInfo.invoice_type === 1&&
                                <div className={'InvoiceInfoListTit'} style={{marginLeft:24,}} onClick={this.InvoiceTitGr.bind(this)}>
                                    <span style={{cursor:'pointer',}}>公司</span>
                                </div>
                            }
                        </div>
                        <div className={'PayInfoTitBox'} style={{marginBottom:20,}}>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:98,}}>*发票抬头</div>
                            <div className={'InvoiceInputYes'}>{this.state.OrderInfo.invoice_top}</div>
                            <div className={'InvoiceInfoListTit'} style={{marginLeft:54,}}>*纳税人识别号</div>
                            <div className={'InvoiceInputYes'}>{this.state.OrderInfo.invoice_code}</div>
                        </div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default PayTransfer;
