import React, { Component } from 'react';
import './handf.css';
import Nav from "./nav";
import {withRouter} from 'react-router-dom';
import {get} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            NavArr:[],
            router:'',
            LoginState:'',
            User:{},
            newMsg:'',
            user_name:'',
        };
        //console.log(this.props.location.pathname);
    }
    componentDidMount=function() {
        //关于路由地址的处理
        let Route = this.props.location.pathname;
        // let index = Route.lastIndexOf("/");//'/'最后出现的位置
        // let TwoIndex = Route.indexOf ("_");//'_'第一次出现的位置
        let ThreeIndex = Route.lastIndexOf ("_");//'_'最后出现的位置
        // console.log(Route.substring(0,ThreeIndex));
        // console.log(this.state.router.substring(0,index));
        if (ThreeIndex > 0){
            this.setState({
                router:Route.substring(0,ThreeIndex),
            });
        }else {
            this.setState({
                router:Route,
            });
        }

        let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
        if (!tokenAuth) {
            this.setState({
                LoginState:'未登录',
            });
            // sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
            // window.location.reload();
        }else {
            this.setState({
                LoginState:'已登录',
                user_name:tokenAuth.user_name,
            });
        }


        //获取Nav数据
        const NavArr=[];
        for(let i=0;i<Nav.length;i++){
            const NavList = Nav[i];
            NavArr.push(NavList);
        }
        this.setState({
            NavArr:NavArr,
        });

        get({url:'user/sys_msg_show_read'}).then((res) => {
            if(res.errno === '200'){
                if (res.data === 0){
                    this.setState({
                        newMsg:'0',
                    });
                }else {
                    this.setState({
                        newMsg:'1',
                    });
                }
            }
        });
    };


    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    }

    //父Nav点击事件
    NavListClick=(item,index) =>{
        sessionStorage.setItem('gameId','');
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno !== '200'){
                sessionStorage.removeItem('tokenAuth');
            }
        });
        const {history} = this.props;
        history.push(item.router);
        // if(item.router === '/approve'){
        //     if(this.state.User.type === 1){
        //         const {history} = this.props;
        //         history.push(item.router);
        //     }else if(this.state.User.type === 0){
        //         this.setState({
        //             PopUp:'提示弹窗',
        //             MessageTip:'请使用机构账户登录！',
        //         });
        //     }else {
        //         const {history} = this.props;
        //         history.push('/login');
        //     }
        // }else {
        //     const {history} = this.props;
        //     history.push(item.router);
        // }
    };
    SonNavListClick=(SonItem,SonIndex) =>{
        //console.log(SonItem)
        //console.log(SonIndex)
    };


    //未登录状态点击登录
    loginClick(){
        const {history} = this.props;
        history.push('/login');
    };
    //已登录状态点击跳转会员中心
    memberClick(){
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                const {history} = this.props;
                history.push('/member');
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });
    };
    //注销登录按钮
    SignOutBtnClick(){
        sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
        window.location.reload();
        this.setState({
        });
    };


    //购物车按钮
    HeaderIcoShop(){
        this.setState({
            PopUp:'提示弹窗',
            MessageTip:'暂未开放！',
        });
    };


    //会员消息
    HeaderIcoMessage(){
        const {history} = this.props;
        history.push('/message');
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'Header'}>
                    <div className={'HeaderBox'}>
                        <div className={'HeaderLogo'}> </div>
                        <div className={'NavBoxes'}>
                            <ul className={'NavBox'}>
                                  {
                                    this.state.NavArr.map((item,index) => (
                                        <li className={'NavList'} key={item.id} onClick={() => this.NavListClick(item,index)}>
                                            <div className={'NavListSpan'}
                                                 style={item.router === this.state.router ? {fontSize:18,}:
                                                     (item.route === this.state.router ? {fontSize:18,}:{})
                                                 }>
                                                {item.fathername}
                                            </div>
                                            <div className={'NavListBorderBottom'}
                                                 style={item.router === this.state.router ? {backgroundColor:'#FB611D',width:97,}:
                                                     (item.route === this.state.router ? {backgroundColor:'#FB611D',width:97,}:{})
                                                 }
                                            > </div>
                                            {
                                                item.sonlist.length !== 0&&
                                                <ul className={'SonNavBox'}>
                                                    {
                                                        item.sonlist.map((SonItem,SonIndex) => (
                                                            <li className={'SonNavList'} key={SonItem.id} onClick={() => this.SonNavListClick(SonItem,SonIndex)}>{SonItem.sonname}</li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </li>
                                    ))
                                }
                                {
                                    this.state.LoginState === '未登录' &&
                                    <li className={'NavList'} onClick={this.loginClick.bind(this)}>
                                        <div className={'NavListSpan'}
                                             style={this.state.router === '/login' ? {fontSize:18,}:{}}>登录
                                        </div>
                                        <div className={'NavListBorderBottom'}
                                             style={this.state.router === '/login' ? {backgroundColor:'#FB611D',width:97,}:{}}> </div>
                                    </li>
                                }
                                {
                                    this.state.LoginState === '已登录' &&
                                    <li className={'NavList'} onClick={this.memberClick.bind(this)}>
                                        <div className={'NavListSpan'}
                                             style={this.state.router === '/member' ? {fontSize:18,}:{}}>会员中心
                                        </div>
                                        <div className={'NavListBorderBottom'}
                                             style={this.state.router === '/member' ? {backgroundColor:'#FB611D',width:97,}:{}}> </div>
                                    </li>
                                }
                            </ul>
                        </div>
                        {
                            this.state.LoginState === '已登录' &&
                            <div>
                                <div className={this.state.newMsg === '1' ? 'HeaderIcoMessageHove' : 'HeaderIcoMessage'} onClick={this.HeaderIcoMessage.bind(this)}> </div>
                                <div className={'HeaderIcoShop'} onClick={this.HeaderIcoShop.bind(this)}> </div>
                            </div>
                        }
                        {
                            this.state.LoginState === '已登录' &&
                            <div className={'MemberUserBox'}>
                                <div className={'MemberUserName'}>您好：{this.state.user_name}</div>
                                <div className={'SignOutBtn'} onClick={this.SignOutBtnClick.bind(this)}>【<span className={'SignOut'}>注销</span>】</div>
                            </div>
                        }
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default withRouter(Header);
