import React, { Component } from 'react';
import './handf.css';
import {withRouter} from 'react-router-dom';
//import {post,} from "../../ajax/tools";


class AuthHeader extends Component {

    constructor(props){
        super(props);
        this.state = {
            router:this.props.location.pathname,
        };
        //console.log(this.props.location.pathname);
    }
    componentDidMount=function() {
        //关于路由地址的处理
        let index = this.state.router.lastIndexOf("/");
        //console.log(index);
        if(index !== 0){
            this.setState({
                router:this.state.router.substring(0,index),
            });
        }else {
            this.setState({
                router:this.state.router,
            });
        }
    };


    //返回首页
    BackHomeClick(){
        const {history} = this.props;
        history.push('/home');
    };
    //返回登录
    BackLoginClick(){
        const {history} = this.props;
        history.push('/login');
    };


    render() {
        return (
                <div className={'AuthHeader'}>
                    <div className={'AuthHeaderBox'}>
                        <div className={'AuthHeaderLogo'}> </div>
                        <div className={'AHLogoBorderRight'}> </div>
                        {
                            this.state.router === '/login'&&
                            <div className={'AuthHeaderTitle'}>欢迎登录</div>
                        }
                        {
                            this.state.router === '/forget'&&
                            <div className={'AuthHeaderTitle'}>账号安全</div>
                        }
                        {
                            this.state.router === '/register'&&
                            <div className={'AuthHeaderTitle'}>欢迎注册</div>
                        }
                        {
                            this.state.router === '/agreement'&&
                            <div className={'AuthHeaderTitle'}>服务协议</div>
                        }
                        {
                            this.state.router === '/friendLink'&&
                            <div className={'AuthHeaderTitle'}>友情链接</div>
                        }
                        {
                            this.state.router === '/aboutMine'&&
                            <div className={'AuthHeaderTitle'}>关于我们</div>
                        }
                        {
                            this.state.router === '/addMine'&&
                            <div className={'AuthHeaderTitle'}>加入我们</div>
                        }
                        {
                            this.state.router === '/aboutMyself'&&
                            <div className={'AuthHeaderTitle'}>联系我们</div>
                        }
                        <div className={'BackHome'} onClick={this.BackHomeClick.bind(this)}>返回首页</div>
                        {
                            this.state.router === '/register'&&
                            <div className={'BackLogin'} onClick={this.BackLoginClick.bind(this)}>已注册？去登录-></div>
                        }
                    </div>
                </div>
        );
    }
}


export default withRouter(AuthHeader);
