import React, { Component } from 'react';
import TeamSendIcoNot from "./memberimage/TeamSendIcoNot.png";
import TeamSendIcoHave from "./memberimage/TeamSendIcoHave.png";
import CheckboxIcoNot from "./memberimage/CheckboxIcoNot.png";
import CheckboxIcoHave from "./memberimage/CheckboxIcoHave.png";
import PopUp from "../round/PopUp";
import './member.css';
import '../round/round.css';
import {get, post} from "../../ajax/tools";


class PeTeamJoin extends Component {

    constructor(props){
        super(props);
        this.state = {
            TeamType:'',//团队类型
            TeamId:'',
            TeamIdInputNote:'No',
            TeamSend:'',
            TeamSendNum: 0,
            TeamSendNumArr: [],
            TeamSendIcoHaveStyle:{backgroundImage:'url('+TeamSendIcoHave+')'},
            TeamSendIcoNotStyle:{backgroundImage:'url('+TeamSendIcoNot+')'},
            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
            CheckboxIcoToggleOn: true,
            TeamSendIcoIndex:'',
            User:{},
            TeamInfoArr:[],
            TeamUserInfo:{},
            TeamAdminInfo:{},
            TeamInfoPopUp:''
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            TeamType:history.TeamType,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        this.setState({
            TeamType:history.TeamType,
        });
    };


    //输入团队的ID
    TeamIdChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            TeamId: event.target.value,
        });
    };

    //点击重置备注
    TeamIdClick(){
        this.setState({
            TeamIdInputNote:'No',
        });
    };
    //点击搜索团队
    TeamJoinInput=(event)=>{
        let TeamTypeNum = this.state.TeamType === '竞技团队' ? 0 : (this.state.TeamType === '表演团队' ? 1 : '');
        let TeamInfoArr = this.state.TeamInfoArr;
        let TeamInfoArrListIdNumArr = [];
        if(event !== ''){
            get({url:'flyteam/query_team/'+event}).then((res) => {
                if(res.errno === '200'){
                    if(res.data[0].type === TeamTypeNum){
                        const TeamInfoCbArr=[];
                        if(TeamInfoArr.length !==0){
                            for(let i=0;i<TeamInfoArr.length;i++){
                                let TeamListId = TeamInfoArr[i].id;
                                TeamInfoArrListIdNumArr.push(TeamListId);
                            }
                            if(TeamInfoArrListIdNumArr.includes(res.data[0].id)){
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'您已经搜索添加过该团队！',
                                });
                            }else {
                                let TeamTheList = res.data[0];
                                TeamTheList.Checkbox= false;
                                TeamTheList.send= false;
                                TeamInfoArr.push(TeamTheList);
                            }
                            this.setState({
                                TeamInfoArr:TeamInfoArr,
                            });
                        }else {
                            let TeamTheList = res.data[0];
                            TeamTheList.Checkbox = false;
                            TeamTheList.send= false;
                            TeamInfoCbArr.push(TeamTheList);
                            this.setState({
                                TeamInfoArr:TeamInfoCbArr,
                            });
                        }
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'您搜索的团队与您选择的团队类型不符！',
                        });
                    }
                }else {
                    this.setState({
                        TeamIdInputNote:'Yes',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入ID号！',
            });
        }
    };






    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.TeamSend === 'One'){
                let TeamInfoCbArr = this.state.TeamInfoArr;
                let TeamIndex = this.state.TeamSendIcoIndex;
                let TeamUserInfo = this.state.TeamUserInfo;
                post({
                    url:'flyteam/join_flyteam',
                    data:{
                        fi_id_list:[{fi_id:TeamUserInfo.id,},],
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        TeamInfoCbArr[TeamIndex]['send'] = true;
                        const TeamSendNumArr = [...this.state.TeamSendNumArr];
                        if(TeamSendNumArr.length !==0){
                            if(TeamUserInfo.Checkbox === true){
                                for(let i=0;i<TeamSendNumArr.length;i++){
                                    if(TeamSendNumArr[i].id === TeamUserInfo.id){
                                        TeamSendNumArr.splice(i,1);
                                    }
                                    this.setState({
                                        TeamSendNumArr:TeamSendNumArr,
                                        TeamSendNum:TeamSendNumArr.length,
                                    });
                                }
                            }
                        }
                        this.setState({
                            TeamId:'',
                            TeamInfoArr:TeamInfoCbArr,
                            PopUp:'提示弹窗',
                            MessageTip:'成功发送邀请',
                        });
                    }else {
                        this.setState({
                            TeamId:'',
                            PopUp:'提示弹窗',
                            MessageTip:'发送申请失败，请稍后重试！',
                        });
                    }
                });
            }else if(this.state.TeamSend === 'More'){
                let TeamInfoCbArr = this.state.TeamInfoArr;
                let TeamSendNumArr = this.state.TeamSendNumArr;
                let FiIdListArr = [];
                for(let i=0;i<TeamSendNumArr.length;i++){
                    let FiIdList = {fi_id:TeamSendNumArr[i].id};
                    FiIdListArr.push(FiIdList);
                }
                post({
                    url:'flyteam/join_flyteam',
                    data:{
                        fi_id_list:FiIdListArr,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<TeamSendNumArr.length;i++){
                            TeamInfoCbArr[TeamSendNumArr[i].index]['send'] = true;
                        }
                        this.setState({
                            TeamId:'',
                            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
                            CheckboxIcoToggleOn: true,
                            TeamSendNumArr:[],
                            TeamSendNum:0,
                            TeamInfoArr:TeamInfoCbArr,
                            PopUp:'提示弹窗',
                            MessageTip:'成功发送邀请',
                        });
                    }else {
                        this.setState({
                            TeamId:'',
                            PopUp:'提示弹窗',
                            MessageTip:'发送申请失败，请稍后重试！',
                        });
                    }
                });
            }
        }
    };
    //点击发送多个申请
    TeamPleaseSend=(event)=>{
        this.setState({
            TeamSend:'More',
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否发送该申请到这些团队！',
        });
    };
    //点击发送单个申请
    TeamSendIco=(item,index)=>{
        this.setState({
            TeamSendIcoIndex:index,
            TeamUserInfo:item,
            TeamSend:'One',
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否发送该申请到'+item.name+'团队！',
        });
    };

    //点击多选框全选
    CheckboxIcoAll=(event)=>{
        const TeamInfoCbArr=[];
        const TeamSendNumArr=[];
        for(let i=0;i<event.length;i++){
            const TeamList= event[i];
            if(this.state.CheckboxIcoToggleOn === false){
                if(TeamList.send === false){
                    TeamList.Checkbox = false;
                    TeamList.index = i;
                    TeamSendNumArr.push(TeamList);
                }
                TeamInfoCbArr.push(TeamList);
            }else if(this.state.CheckboxIcoToggleOn === true){
                if(TeamList.send === false){
                    TeamList.Checkbox = true;
                    TeamList.index = i;
                    TeamSendNumArr.push(TeamList);
                }
                TeamInfoCbArr.push(TeamList);
            }
        }
        this.setState(prevState => ({
            CheckboxIcoToggleOn: !prevState.CheckboxIcoToggleOn,
            CheckboxIcoBgImg:TeamSendNumArr.length === 0 ? 'url('+CheckboxIcoNot+')' : (prevState.CheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
            TeamSendNum:prevState.CheckboxIcoToggleOn ? TeamSendNumArr.length : 0,
            TeamInfoArr:TeamInfoCbArr,
            TeamSendNumArr:prevState.CheckboxIcoToggleOn ? TeamSendNumArr : [],
        }));
    };
    //点击单个多选框
    CheckboxIco=(item,index)=>{
        const TeamInfoList = this.state.TeamInfoArr;
        TeamInfoList[index]['Checkbox'] = item.Checkbox !== true;
        const TeamSendNumArr = [...this.state.TeamSendNumArr];
        if(item.Checkbox === true){
            item.index = index;
            TeamSendNumArr.push(item);
            this.setState({
                TeamSendNumArr:TeamSendNumArr,
                TeamSendNum:TeamSendNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<TeamSendNumArr.length;i++){
                if(TeamSendNumArr[i].id === item.id){
                    TeamSendNumArr.splice(i,1);
                }
            }
            this.setState({
                TeamSendNumArr:TeamSendNumArr,
                TeamSendNum:TeamSendNumArr.length,
            });
        }
        const TeamSendNoArr=[];
        for(let i=0;i<this.state.TeamInfoArr.length;i++){
            const TeamList= this.state.TeamInfoArr[i];
            if(TeamList.send === false){
                TeamSendNoArr.push(TeamList)
            }
        }
        this.setState({
            TeamInfoArr:TeamInfoList,
            CheckboxIcoToggleOn:TeamSendNoArr.length !== TeamSendNumArr.length,
        });
    };






    //察看人员等级详情
    DetailsClick=(item,index)=>{
        get({url:'reuse/get_personal_info_id/'+item.user_id}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeamInfoPopUp:'团队信息详情',
                    TeamUserInfo:item,
                    TeamAdminInfo:res.data[0],
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'查看详情失败！',
                });
            }
        });
    };
    CrossOutThis(){
        this.setState({
            TeamInfoPopUp:'',
            TeamUserInfo:{},
            TeamAdminInfo:{},
        });
    };






    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'PeTeamModule'}>
                    <div className={'TeamIdInputBoxes clearfix'}>
                        <div className={'TeamIdInputTit'}>团队ID</div>
                        <div className={'TeamIdInputBox clearfix'}>
                            <input className={'TeamIdInput'}
                                   type={'text'}
                                   name={'TeamId'}
                                   value={this.state.TeamId}
                                   onChange={this.TeamIdChange.bind(this)}
                                   onClick={this.TeamIdClick.bind(this)}
                                   placeholder={'请输入正确的团队ID号'}
                                   autoComplete={'off'}
                            />
                            <button className={'TeamIdInputBtn'} onClick={() => this.TeamJoinInput(this.state.TeamId)}>搜索</button>
                        </div>
                        {
                            this.state.TeamIdInputNote === 'Yes' &&
                            <div className={'TeamIdInputNote'}>未搜索到相关信息，请您检查ID号是否正确</div>
                        }
                    </div>
                    <button className={'TeamPleaseSendBtn'} onClick={() => this.TeamPleaseSend(this.state.TeamId)}>发送申请（{this.state.TeamSendNum}）</button>
                    <div className={'TeamListHeaderBox'}>
                        <div className={'TeamHeaderList'} style={{width:40,}}>
                            <div className={'TeamHeaderCheckbox'}
                                 onClick={() => this.CheckboxIcoAll(this.state.TeamInfoArr)}>
                                {
                                    this.state.CheckboxIcoToggleOn === false &&
                                    <div className={'TeamHeaderCheckboxActive'}> </div>
                                }
                            </div>
                        </div>
                        <div className={'TeamHeaderList'} style={{width:104,}}>序号</div>
                        <div className={'TeamHeaderList'} style={{width:218,}}>团队名称</div>
                        <div className={'TeamHeaderList'} style={{width:162,}}>团队ID</div>
                        <div className={'TeamHeaderList'} style={{width:204,}}>详情</div>
                        <div className={'TeamHeaderList'} style={{width:162,}}>发送申请</div>
                    </div>
                    <div className={'TeamListConBox'}>
                        {
                            this.state.TeamInfoArr.map((item,index) =>
                                <div className={'TeamListCon'} key={index}>
                                    <div className={'TeamConList'} style={{width:40,}}>
                                        <div className={'CheckboxIco'}
                                             style={{backgroundImage:item.send === true ? '' : (item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
                                                    marginTop:7,
                                             }}
                                             onClick={item.send === true ? null : () => this.CheckboxIco(item,index)}>
                                        </div>
                                    </div>
                                    <div className={'TeamConList'} style={{width:104,}}>{index+1}</div>
                                    <div className={'TeamConList'} style={{width:218,}}>{item.name}</div>
                                    <div className={'TeamConList'} style={{width:162,}}>{item.team_num}</div>
                                    <div className={'TeamConList'} style={{width:204,}}>
                                        <span className={'DetailsViews'} onClick={() => this.DetailsClick(item,index)}>查看</span>
                                    </div>
                                    <div className={'TeamConList'} style={{width:162,}}>
                                        <div className={'TeamSendIco'}
                                             style={item.send === true ? this.state.TeamSendIcoNotStyle : this.state.TeamSendIcoHaveStyle}
                                             onClick={item.send === true ? null : () => this.TeamSendIco(item,index)}>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.PopUp === '有两个选择按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.TeamInfoPopUp === '团队信息详情' &&
                        <div className={'PopUpBoxBg'}>
                            <div className={'InfoPopUp'} >
                                <div className={'InfoPopUpCrossBtn'} onClick={this.CrossOutThis.bind(this)}> </div>
                                <div className={'InfoPopUpImgBox'} style={{width:192,height:174,}}>
                                    <img
                                        alt={''}
                                        src={this.state.TeamUserInfo.flag_url}
                                    />
                                </div>
                                <div className={'InfoPopUpListBox'}>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>团队名称：</div>
                                        <div className={'InfoPopUpListCon Ellipsis'}>{this.state.TeamUserInfo.name}</div>
                                    </div>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>团队ID：</div>
                                        <div className={'InfoPopUpListCon'}>{this.state.TeamUserInfo.team_num}</div>
                                    </div>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>团队类型：</div>
                                        <div className={'InfoPopUpListCon'}>{this.state.TeamUserInfo.type === 0 ? '竞技团队' : (this.state.TeamUserInfo.type === 1 ? '表演团队' : '')}</div>
                                    </div>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>团队创建时间：</div>
                                        <div className={'InfoPopUpListCon'}>{this.state.TeamUserInfo.creat_time}</div>
                                    </div>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>管理员姓名：</div>
                                        <div className={'InfoPopUpListCon'}>{this.state.TeamAdminInfo.real_name}</div>
                                    </div>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>管理员ID号：</div>
                                        <div className={'InfoPopUpListCon'}>{this.state.TeamAdminInfo.yy_num}</div>
                                    </div>
                                    <div className={'InfoPopUpList clearfix'}>
                                        <div className={'InfoPopUpListTit'}>管理员联系方式：</div>
                                        <div className={'InfoPopUpListCon'}>{this.state.TeamAdminInfo.mobile}</div>
                                    </div>
                                </div>
                                <div className={'InfoPopUpBtnBox DisplayFlex'}>
                                    <button className={'InfoPopUpBtn'}
                                            style={{color:'#FFFFFF',backgroundColor:'#6EC2FF',}}
                                            onClick={this.CrossOutThis.bind(this)}
                                    >
                                       返回
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
        );
    }
}


export default PeTeamJoin;
