import React, { Component } from 'react'
import './round.css'

class InfoPopUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ButtonType: [],
      UserInfo: {},
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const history = nextProps
    this.setState({
      ButtonType: history.ButtonType,
      UserInfo: history.UserInfo,
    })
  }

  componentDidMount = function () {
    const history = this.props
    this.setState({
      ButtonType: history.ButtonType,
      UserInfo: history.UserInfo,
    })
  }

  CrossOut = () => {
    let BackInfo = {
      type: 'CrossOut',
    }
    this.props.InfoPopUpBack(BackInfo)
  }
  InfoPopUpBtnClick = (item, index) => {
    let BackInfo = {
      type: 'Confirm',
      BackInfo: item,
      UserInfo: this.state.UserInfo,
    }
    this.props.InfoPopUpBack(BackInfo)
  }

  render() {
    return (
      <div className={'PopUpBoxBg'} style={{ minHeight: 500 }}>
        <div className={'AddItemBox'} style={{ width: 696, height: 450 }}>
          <div
            className={'AddItemBigTitle'}
            style={{ textIndent: 0, textAlign: 'center' }}
          >
            用户信息
            <div
              className={'AddItemBackIco'}
              onClick={this.CrossOut.bind(this)}
            >
              {' '}
            </div>
          </div>
          <div
            className={'AddItemMainBox scrollBar'}
            style={{ width: 696, height: 420 }}
          >
            <div className={'AccountInfoBox'}>
              <div className={'AccountInfoPhoto'}>
                <img alt={''} src={this.state.UserInfo.image} />
              </div>
              <div className={'AccountInfoName'}>
                姓名：{this.state.UserInfo.real_name}
              </div>
              <div className={'AccountInfoNum'}>
                ID号码：{this.state.UserInfo.yy_num}
              </div>
              <div className={'AccountInfoName'} style={{ top: 110 }}>
                联系方式：{this.state.UserInfo.mobile}
              </div>
              <div className={'AccountInfoName'} style={{ top: 150 }}>
                教练员：{this.state.UserInfo.iscoach === 1 ? '是' : '否'}
              </div>
              <div className={'AccountInfoName'} style={{ top: 190 }}>
                运动员：{' '}
                {this.state.UserInfo.athlevel === 0
                  ? '启蒙级'
                  : this.state.UserInfo.athlevel === -1
                  ? '否'
                  : this.state.UserInfo.athlevel + '级'}
              </div>
            </div>
            <div className={'AccountInfoBtnBox'}>
              {this.state.ButtonType.map((item, index) => (
                <div
                  className={'AccountInfoBtn'}
                  key={index}
                  style={{ color: item.color, backgroundColor: item.bgColor }}
                  onClick={() => this.InfoPopUpBtnClick(item, index)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*<div className={'InfoPopUp'} >*/}
        {/*    <div className={'InfoPopUpCrossBtn'} onClick={this.CrossOut.bind(this)}> </div>*/}
        {/*    <div className={'InfoPopUpImgBox'}>*/}
        {/*        <img*/}
        {/*            alt={''}*/}
        {/*            src={this.state.UserInfo.image}*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*    <div className={'InfoPopUpListBox'}>*/}
        {/*        <div className={'InfoPopUpList clearfix'}>*/}
        {/*            <div className={'InfoPopUpListTit'}>姓名：</div>*/}
        {/*            <div className={'InfoPopUpListCon'}>{this.state.UserInfo.real_name}</div>*/}
        {/*        </div>*/}
        {/*        <div className={'InfoPopUpList clearfix'}>*/}
        {/*            <div className={'InfoPopUpListTit'}>ID号码：</div>*/}
        {/*            <div className={'InfoPopUpListCon'}>{this.state.UserInfo.yy_num}</div>*/}
        {/*        </div>*/}
        {/*        <div className={'InfoPopUpList clearfix'}>*/}
        {/*            <div className={'InfoPopUpListTit'}>联系方式：</div>*/}
        {/*            <div className={'InfoPopUpListCon'}>{this.state.UserInfo.mobile}</div>*/}
        {/*        </div>*/}
        {/*        <div className={'InfoPopUpList clearfix'}>*/}
        {/*            <div className={'InfoPopUpListTit'}>教练员：</div>*/}
        {/*            <div className={'InfoPopUpListCon'}>{this.state.UserInfo.iscoach === 1 ? '是' : '否'}</div>*/}
        {/*        </div>*/}
        {/*        <div className={'InfoPopUpList clearfix'}>*/}
        {/*            <div className={'InfoPopUpListTit'}>运动员：</div>*/}
        {/*            <div className={'InfoPopUpListCon'}>{this.state.UserInfo.athlevel === 0 ? '否' : '是'}</div>*/}
        {/*        </div>*/}
        {/*        {*/}
        {/*            this.state.UserInfo.athlevel !== 0 &&*/}
        {/*            <div className={'InfoPopUpList clearfix'}>*/}
        {/*                <div className={'InfoPopUpListTit'}>运动员等级：</div>*/}
        {/*                <div className={'InfoPopUpListCon'}>{this.state.UserInfo.athlevel}级</div>*/}
        {/*            </div>*/}
        {/*        }*/}
        {/*    </div>*/}
        {/*    <div className={'InfoPopUpBtnBox DisplayFlex'}>*/}
        {/*        {*/}
        {/*            this.state.ButtonType.map((item,index) =>*/}
        {/*                <button className={'InfoPopUpBtn'}*/}
        {/*                        key={index}*/}
        {/*                        style={{color:item.color,backgroundColor:item.bgColor,}}*/}
        {/*                        onClick={() => this.InfoPopUpBtnClick(item,index)}*/}
        {/*                >*/}
        {/*                    {item.name}*/}
        {/*                </button>*/}
        {/*            )*/}
        {/*        }*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    )
  }
}

export default InfoPopUp
