import React, { Component } from 'react';
import './game.css';
import './active.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import not from "../round/roundimage/ErrorIco.png";
import yes from "../auth/authimage/ReSuccessIco.png";
import {API_URL} from "../../ajax/config";



class Activity extends Component {

    constructor(props){
        super(props);
        this.state = {
            ActiveArr:[],
            ActiveId:'',
            ActiveType:'list',
            SingUpType:'',
            User:{},
            ActiveList:{},
            SingUpInfo:{},
            payType:'',
            orderId:'',
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let MatchId = nextProps.MatchId;
        this.setState({
            ActiveId:MatchId,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        let MatchId = history.MatchId;
        if(MatchId && MatchId !== ''){
            this.setState({
                ActiveId:MatchId,
                ActiveType:'info',
            });
            get({url:'user/basic_info'}).then((res) => {
                if(res.errno === '200'){
                    let User = res.data[0];
                    if(User.type === 0){
                        this.setState({
                            ActiveType:'info',
                            User:User,
                        });
                        get({url:"activity/show_activity_enroll/"+MatchId}).then(res => {
                            if(res.errno === '200'){
                                this.setState({
                                    SingUpType:'wait',
                                    SingUpInfo:res.data[0],
                                });
                            }else {
                                this.setState({
                                    SingUpType:'btn',
                                });
                            }
                        });
                        get({url:"activity/show_activity/"+MatchId}).then(res => {
                            if(res.errno === '200'){
                                this.setState({
                                    ActiveList:res.data[0],
                                });
                            }
                        });
                    }else{
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'请使用个人账户登录！',
                        });
                    }
                }else {
                    const {history} = this.props;
                    history.push('/login');
                }
            });
        }else {
            get({url:"activity/show_all_activity"}).then(res => {
                if(res.errno === '200'){
                    this.setState({
                        ActiveArr:res.data,
                    });
                }else {
                    this.setState({
                        ActiveArr:[],
                    });
                }
            });
        }
    };


    ActiveBtn = (item,index) =>{
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 0){
                    this.setState({
                        ActiveId:item.id,
                        ActiveType:'info',
                        User:User,
                        ActiveList:item,
                    });
                    get({url:"activity/show_activity_enroll/"+item.id}).then(res => {
                        if(res.errno === '200'){
                            this.setState({
                                SingUpType:'wait',
                                SingUpInfo:res.data[0],
                            });
                        }else {
                            this.setState({
                                SingUpType:'btn',
                            });
                        }
                    });
                }else{
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'请使用个人账户登录！',
                    });
                }
            }else {
                window.location.href='/login';
            }
        });
    };


    BackBtn(){
        get({url:"activity/show_all_activity"}).then(res => {
            if(res.errno === '200'){
                this.setState({
                    ActiveArr:res.data,
                    ActiveId:'',
                    SingUpType:'',
                    ActiveType:'list',
                });
            }else {
                this.setState({
                    ActiveArr:[],
                });
            }
        });
    };




    ActiveOkBtn(){
        let id = this.state.ActiveList.id;
        get({url:"/activity/enroll_activity/"+id}).then(res => {
            if(res.errno === '200'){
                get({url:"activity/show_activity_enroll/"+id}).then(res => {
                    if(res.errno === '200'){
                        this.setState({
                            SingUpType:'wait',
                            SingUpInfo:res.data[0],
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'网络错误，请重试！',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };


    pay(){
        let ActiveId = this.state.SingUpInfo.id;
        let ActiveList = this.state.ActiveList;
        let address = this.state.User.province === this.state.User.city ?
            this.state.User.city+this.state.User.county+this.state.User.address :
            this.state.User.province+this.state.User.city+this.state.User.county+this.state.User.address;
        clearInterval(this.PayWeChat);
        get({url:'activity/get_nopay_order/'+ActiveId}).then(res => {
            if(res.errno === '200'){
                this.setState({
                    orderId:res.order_id,
                    payType: 'Code',
                });
                this.PayWeChat = setInterval(() => {
                    get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                        if (res.state=== 1) {
                            clearInterval(this.PayWeChat);
                            this.setState({
                                payType:'',
                                PopUp:'提示弹窗',
                                MessageTip:'支付成功！',
                            });
                        }
                    });
                }, 2000);
            }else {
                post({
                    url:'activity/activity_order',
                    data:{
                        name:ActiveList.name,
                        pay_fee:ActiveList.pay_fee,
                        address:address,
                        ae_id:ActiveId,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            orderId:res.order_id,
                            payType: 'Code',
                        });
                        this.PayWeChat = setInterval(() => {
                            get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                                if (res.state=== 1) {
                                    clearInterval(this.PayWeChat);
                                    this.setState({
                                        payType:'',
                                        PopUp:'提示弹窗',
                                        MessageTip:'支付成功！',
                                    });
                                }
                            });
                        }, 2000);
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            }
        });
    };

    notPay(){
        clearInterval(this.PayWeChat);
        this.setState({
            payType:'',
        });
    };




    query(){
        let id = this.state.ActiveList.id;
        get({url:"/activity/show_activity_enroll/"+id}).then(res => {
            if(res.errno === '200'){
                let SingUpInfo = res.data[0];
                if(SingUpInfo.ispass_write ===0){
                    window.open('/activeAsk?id='+SingUpInfo.id);
                }else {
                    this.setState({
                        SingUpInfo:SingUpInfo,
                    });
                }
            }
        });
    };






    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    my(){
        window.open('/aboutMyself');
    };




    render() {
        return (
            <div className={'GameMainBox scrollBar'} style={{height:window.innerHeight-191,}}>
                {
                    this.state.ActiveType === 'list'&&
                    <div>
                        <div className={'InfoListPositionTitBox'}>
                            <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>
                                <div className={'InfoListPosition'}>选择活动</div>
                            </div>
                        </div>
                        <div className={'GameSignUpIcoBoxes clearfix'}>
                            {
                                this.state.ActiveArr.map((item,index) => (
                                    <div className={'GameSignUpIcoBox'} key={index}>
                                        <div className={'ActiveSignUpIco'} onClick={() => this.ActiveBtn(item,index)}>
                                            <img
                                                className={'ActiveSignUpIcoImg'}
                                                alt={''}
                                                src={'http://oss.asfcyy.com/richTextImage/45db6cd67bf111eb87c4000c29ac4e4a.png'}
                                            />
                                        </div>
                                        <div className={'GameSignUpIcoTitle'}>{item.name}</div>
                                    </div>
                                ))
                            }
                            {
                                this.state.ActiveArr.length === 0 &&
                                <div className={'NotGameTip'}>
                                    <div className={'TrainDataMain'}>
                                        <div className={'GameYearNum'} style={{position:'unset',fontSize:54,}}>当前未有相关活动！</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.ActiveType === 'info'&&
                    <div>
                        <div className={'CounselorTitle'}>
                            <div className={'DetailsBackBox clearfix'} style={{marginTop:20,}} onClick={this.BackBtn.bind(this)}>
                                <div className={'DetailsBackIco'}> </div>
                                <div className={'DetailsBackWord'}>返回</div>
                            </div>
                        </div>
                        {
                            this.state.SingUpType === 'btn'&&
                            <div className={'BackBottomMain'}>
                                <div className={'TrickTitle'} style={{paddingTop:20,}}>{this.state.ActiveList.name}报名入口</div>
                                <div className={'ActiveSingUpExplanation'}>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',color:'#F5495D',marginBottom:20,}}>*请检查报名信息：</div>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',marginBottom:10,}}>姓名：{this.state.User.real_name}</div>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',marginBottom:10,}}>电话：{this.state.User.mobile}</div>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',marginBottom:10,height:'auto',minHeight:'32px',}}>地址：
                                        {
                                            this.state.User.province === this.state.User.city ?
                                            this.state.User.city+this.state.User.county+this.state.User.address :
                                            this.state.User.province+this.state.User.city+this.state.User.county+this.state.User.address
                                        }
                                    </div>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',marginBottom:10,}}>报名时间：{this.state.ActiveList.enroll_start_time} — {this.state.ActiveList.enroll_end_time}</div>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',marginBottom:20,}}>活动时间：{this.state.ActiveList.activity_start_time} — {this.state.ActiveList.activity_end_time}</div>
                                    <div className={'ManualReviewWord'} style={{width:'100%',textAlign:'left',color:'#F5495D',}}>*如有问题请至会员中心修改或联系我们！</div>
                                </div>
                                <div className={'TrickInfoBtn'} onClick={this.ActiveOkBtn.bind(this)}>提交</div>
                            </div>
                        }
                        {
                            this.state.SingUpType === 'wait'&&
                            <div className={'BackBottomMain'} style={{minHeight:598,}}>
                                {
                                    this.state.SingUpInfo.ispass === 0 &&
                                    <div style={{width:900,margin:'28px auto',}}>
                                        <div className={'ManualReviewBox'}>
                                            <div className={'ManualReviewIco'}> </div>
                                            <div className={'ManualReviewWordBox'}>
                                                <div className={'ManualReviewWord'} style={{textAlign:'left',}}>已提交报名...</div>
                                                <div className={'ManualReviewWord'} style={{textAlign:'right',}}>请您等待审核</div>
                                            </div>
                                            <div className={'ManualReviewStep'}><div className={'ManualReviewStepHave'}> </div></div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.SingUpInfo.ispass === 1 &&
                                    <div style={{width:900,margin:'28px auto',height:290,}}>
                                        <div className={'ManualReviewBox'}>
                                            <div className={'ManualReviewIco'} style={{backgroundImage:'url('+yes+')',}}> </div>
                                            <div
                                                className={'ManualReviewWordBox ManualReviewWord'}
                                                style={{fontSize:20,textAlign:'center',}}
                                            >审核通过</div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.SingUpInfo.ispass === 2 &&
                                    <div style={{width:900,margin:'28px auto',height:290,}}>
                                        <div className={'ManualReviewBox'}>
                                            <div className={'ManualReviewIco'} style={{backgroundImage:'url('+not+')',}}> </div>
                                            <div
                                                className={'ManualReviewWordBox ManualReviewWord'}
                                                style={{fontSize:20,textAlign:'center',}}
                                            >审核未通过</div>
                                        </div>
                                    </div>
                                }
                                <div className={'ActiveSingUpExplanation'}>
                                    {
                                        this.state.ActiveList.isquery === 1 &&this.state.SingUpInfo.ispass_write === 0 &&
                                        <div
                                            className={'ManualReviewWord'}
                                            style={{width:'100%',textAlign:'left',color:'#F5495D',}}
                                        >
                                            *该活动需要进行答题&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className={'ClickDetails'} onClick={this.query.bind(this)}>开始答题</span>
                                        </div>
                                    }
                                    {
                                        this.state.ActiveList.isquery === 1 &&this.state.SingUpInfo.ispass_write !== 0 &&
                                        <div
                                            className={'ManualReviewWord'}
                                            style={{width:'100%',textAlign:'left',color:'#F5495D',}}
                                        >
                                            *该活动需要进行答题&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style={{color:'#353535',}}>您已答题：<span style={{color:'#ff0000',}}>{this.state.SingUpInfo.iswrite_score}</span> 分</span>
                                        </div>
                                    }
                                    {
                                        this.state.SingUpInfo.payment_status === 0 && this.state.ActiveList.ispay === 1 &&
                                        <div
                                            className={'ManualReviewWord'}
                                            style={{width:'100%',textAlign:'left',color:'#F5495D',}}
                                        >
                                            *该活动需要缴纳费用&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className={'ClickDetails'} onClick={this.pay.bind(this)}>点击缴费</span>
                                        </div>
                                    }
                                    <div
                                        className={'ManualReviewWord'}
                                        style={{width:'100%',textAlign:'left',color:'#F5495D',}}
                                    >
                                        *该活动如有任何问题&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className={'ClickDetails'} onClick={this.my.bind(this)}>联系我们</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.payType === 'Code'&&
                    <div className={'PopUpBoxBg'}>
                        <div className={'PopUpBox'}
                             style={{width:200,height:280,backgroundColor:'#fff',borderRadius:'unset',}}
                        >
                            <div className={'clearfix'} style={{width:200,height:30,margin:'5px 0'}}>
                                <div className={'ActiveCodeTipsIco'}> </div>
                                <div className={'ActiveCodeTipsWord'}>请使用微信扫码支付：</div>
                            </div>
                            <div style={{width:200,height:200,}}>
                                <img
                                    className={'PayCodePhoto'}
                                    alt={''}
                                    src={API_URL+"/payment/WXpay_goods_order/"+this.state.orderId}
                                />
                            </div>
                            <div className={'ErCodeBtn ClickDetails'} onClick={this.notPay.bind(this)}>&gt;&gt;&gt;&gt; 取消订单 &lt;&lt;&lt;&lt;</div>
                        </div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default Activity;
