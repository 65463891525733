import React, { Component } from 'react';
import './member.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import InfoPopUp from "../round/InfoPopUp";
import PeTeamJoin from "./PeTeamJoin";
import PeTeamCreate from "./PeTeamCreate";
import PeTeamManage from "./PeTeamManage";
import PeTeamNews from "./PeTeamNews";
import Upload from "../round/Upload";


class PeTeam extends Component {

    constructor(props){
        super(props);
        this.state = {
            MemberTopNav:'竞技团队',
            TopNavArr:['竞技团队','表演团队','团队消息',],
            NavSelected:{
                backgroundColor:'#0488CA',
                color:'#FFFFFF',
                borderLeftColor:'#000000',
                borderTopColor:'#000000',
                borderRightColor:'#000000',
            },
            NoSelected:{
                borderBottomColor:'#000000',
            },
            SportsTeamState:'',
            PerformTeamState:'',
            Authority:'',
            TeamUser:{},
            TeamInfoArr:[],

            FileList: [],
            Uploading: false,
            FileSrc:'',
            UploadBtnState:'NotShow',


            TeamPeInfoArr:{},
            TeamPePopUp:'NoShow',


            AddPick:'',
        };
    }
    componentDidMount=function() {
        get({url:'flyteam/query_flyteam/0'}).then((res) => {
            if(res.errno === '200'){
                let AuthorityType = res.data[0].admin;
                if(AuthorityType === 1){
                    this.setState({
                        SportsTeamState:'有',
                        Authority:'管理员',
                        TeamUser:res.data[0],
                    });
                }else {
                    this.setState({
                        SportsTeamState:'有',
                        Authority:'队员',
                        TeamUser:res.data[0],
                    });
                }
                get({url:'flyteam/get_flyteam_info/'+res.data[0].id}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TeamInfoArr:res.data,
                        });
                    }else{
                        this.setState({
                            TeamInfoArr:[],
                        });
                    }
                });
            }else {
                this.setState({
                    SportsTeamState:'无',
                });
            }
        });
    };

    //创建团队部分的自定义弹窗特殊处理
    PeTeamCreateOutBackClick=(event)=>{
        if(event === 'OutPeTeam'){
            if(this.state.MemberTopNav === '竞技团队'){
                this.setState({
                    SportsTeamState:'无',
                });
            }else if(this.state.MemberTopNav === '表演团队'){
                this.setState({
                    PerformTeamState:'无',
                });
            }
        }else if(event === 'OutAndShow'){
            if(this.state.MemberTopNav === '竞技团队'){
                this.setState({TeamUser:{},});
                get({url:'flyteam/query_flyteam/0'}).then((res) => {
                    if(res.errno === '200'){
                        let AuthorityType = res.data[0].admin;
                        if(AuthorityType === 1){
                            this.setState({
                                Authority:'管理员',
                            });
                        }else {
                            this.setState({
                                Authority:'队员',
                            });
                        }
                        get({url:'flyteam/get_flyteam_info/'+res.data[0].id}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    TeamInfoArr:res.data,
                                });
                            }else{
                                this.setState({
                                    TeamInfoArr:[],
                                });
                            }
                        });
                        this.setState({
                            SportsTeamState:'有',
                            TeamUser:res.data[0],
                        });
                    }
                });
            }else if(this.state.MemberTopNav === '表演团队'){
                this.setState({TeamUser:{},});
                get({url:'flyteam/query_flyteam/1'}).then((res) => {
                    if(res.errno === '200'){
                        let AuthorityType = res.data[0].admin;
                        if(AuthorityType === 1){
                            this.setState({
                                Authority:'管理员',
                            });
                        }else {
                            this.setState({
                                Authority:'队员',
                            });
                        }
                        get({url:'flyteam/get_flyteam_info/'+res.data[0].id}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    TeamInfoArr:res.data,
                                });
                            }else{
                                this.setState({
                                    TeamInfoArr:[],
                                });
                            }
                        });
                        this.setState({
                            PerformTeamState:'有',
                            TeamUser:res.data[0],
                        });
                    }
                });
            }
        } else if(event === 'TeamManage'){
            if(this.state.MemberTopNav === '竞技团队'){
                this.setState({
                    SportsTeamState:'团队管理',
                    AddPick:'Add',
                });
            }else if(this.state.MemberTopNav === '表演团队'){
                this.setState({
                    PerformTeamState:'团队管理',
                    AddPick:'Add',
                });
            }
        }
    };


    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        if(item === '竞技团队'){
            this.props.ChildBackNavClick('竞技团队');
            this.setState({TeamUser:{},});
            get({url:'flyteam/query_flyteam/0'}).then((res) => {
                if(res.errno === '200'){
                    let AuthorityType = res.data[0].admin;
                    if(AuthorityType === 1){
                        this.setState({
                            Authority:'管理员',
                        });
                    }else {
                        this.setState({
                            Authority:'队员',
                        });
                    }
                    get({url:'flyteam/get_flyteam_info/'+res.data[0].id}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                TeamInfoArr:res.data,
                            });
                        }else{
                            this.setState({
                                TeamInfoArr:[],
                            });
                        }
                    });
                    this.setState({
                        SportsTeamState:'有',
                        TeamUser:res.data[0],
                    });
                }else {
                    this.setState({
                        SportsTeamState:'无',
                    });
                }
            });
        }else if(item === '表演团队'){
            this.props.ChildBackNavClick('表演团队');
            this.setState({TeamUser:{},});
            get({url:'flyteam/query_flyteam/1'}).then((res) => {
                if(res.errno === '200'){
                    let AuthorityType = res.data[0].admin;
                    if(AuthorityType === 1){
                        this.setState({
                            Authority:'管理员',
                        });
                    }else {
                        this.setState({
                            Authority:'队员',
                        });
                    }
                    get({url:'flyteam/get_flyteam_info/'+res.data[0].id}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                TeamInfoArr:res.data,
                            });
                        }else{
                            this.setState({
                                TeamInfoArr:[],
                            });
                        }
                    });
                    this.setState({
                        PerformTeamState:'有',
                        TeamUser:res.data[0],
                    });
                }else {
                    this.setState({
                        PerformTeamState:'无',
                    });
                }
            });
        }else if(item === '团队消息'){
            this.props.ChildBackNavClick('团队消息');
            this.setState({

            });
        }
        this.setState({
            MemberTopNav:item,
        });
    };

    //当点击创建自己团队时
    NoTeamCreate=(even)=>{
        if(even === '竞技团队'){
            this.setState({
                SportsTeamState:'创建自己团队',
            });
        }else if(even === '表演团队'){
            this.setState({
                PerformTeamState:'创建自己团队',
            });
        }
    };
    //当点击加入已建团队时
    NoTeamJoin=(even)=>{
        if(even === '竞技团队'){
            this.setState({
                SportsTeamState:'加入已建团队',
            });
        }else if(even === '表演团队'){
            this.setState({
                PerformTeamState:'加入已建团队',
            });
        }
    };



    //当点击返回时
    DropOutTeamJoin=(even)=>{
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否确认返回该'+even+'上一级！',
        });
    };

    //返回弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.MemberTopNav === '竞技团队'){
                if(this.state.SportsTeamState === '加入已建团队') {
                    this.setState({
                        SportsTeamState: '无',
                        PopUp: '',
                    });
                }else if(this.state.SportsTeamState === '团队管理') {
                    get({url:'flyteam/query_flyteam/0'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                TeamUser:res.data[0],
                                SportsTeamState: '有',
                                PopUp: '',
                            });
                        }
                    });
                }
            }else if(this.state.MemberTopNav === '表演团队'){
                if(this.state.PerformTeamState === '加入已建团队') {
                    this.setState({
                        PerformTeamState: '无',
                        PopUp: '',
                    });
                }else if(this.state.PerformTeamState === '团队管理') {
                    get({url:'flyteam/query_flyteam/1'}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                TeamUser:res.data[0],
                                PerformTeamState: '有',
                                PopUp: '',
                            });
                        }
                    });
                }
            }
        }
    };


    //当点击退出/解散团队时
    DropOutTeam=(even)=>{
        if(this.state.Authority === '管理员') {
            this.setState({
                PopUp:'退出团队的提示框',
                MessageTip:'是否确认解散该'+even+'！',
            });
        }else if(this.state.Authority === '队员') {
            this.setState({
                PopUp:'退出团队的提示框',
                MessageTip:'是否确认退出该'+even+'！',
            });
        }
    };
    //退出团队的弹窗处理
    OutBackClick=(event)=>{
        let TeamUser = this.state.TeamUser;
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.MemberTopNav === '竞技团队'){
                if(this.state.Authority === '管理员') {
                    get({url:'flyteam/del_flyteam/'+TeamUser.id}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                SportsTeamState: '无',
                                PopUp: '',
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'解散失败，请稍后重试！',
                            });
                        }
                    });
                }else if(this.state.Authority === '队员') {
                    get({url:'flyteam/sign_out_team/'+TeamUser.fm_id}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                SportsTeamState: '无',
                                PopUp: '',
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'退出失败，请稍后重试！',
                            });
                        }
                    });
                }
            }else if(this.state.MemberTopNav === '表演团队'){
                if(this.state.Authority === '管理员') {
                    get({url:'flyteam/del_flyteam/'+TeamUser.id}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                PerformTeamState: '无',
                                PopUp: '',
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'解散失败，请稍后重试！',
                            });
                        }
                    });
                }else if(this.state.Authority === '队员') {
                    get({url:'flyteam/sign_out_team/'+TeamUser.fm_id}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                PerformTeamState: '无',
                                PopUp: '',
                            });
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'退出失败，请稍后重试！',
                            });
                        }
                    });
                }
            }
        }
    };



    //当管理员点击团队管理时
    PeTeamManage=(even)=>{
        if(this.state.MemberTopNav === '竞技团队'){
            this.setState({
                SportsTeamState:'团队管理',
                AddPick:'Pick',
            });
        }else if(this.state.MemberTopNav === '表演团队'){
            this.setState({
                PerformTeamState:'团队管理',
                AddPick:'Pick',
            });
        }
    };


    //点击点击团队成员查看详情
    YesTeamPeoList=(item,index)=>{
        this.setState({
            TeamPeInfoArr:item,
            TeamPePopUp:'Show',
        });
    };



    //上传旗帜照片
    UploadBtnClick(){
        const formData = new FormData();
        let TeamUser = this.state.TeamUser;
        let TeamTypeNum = this.state.MemberTopNav === '竞技团队' ? 0 : (this.state.MemberTopNav === '表演团队' ? 1 : '');
        if(this.state.FileList.length !== 0){
            this.setState({
                Uploading:true,
                PopUp:'上传屏蔽',
                MessageTip:'正在上传，请稍后...',
            });
            this.state.FileList.forEach((file) => {
                formData.append('file', file);
            });
            post({
                url:'flyteam/up_flyteam_img/'+TeamUser.id,
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    get({url:'flyteam/query_flyteam/'+TeamTypeNum}).then((res) => {
                        if(res.errno === '200'){
                            this.setState({
                                TeamUser:res.data[0],
                                FileList: [],
                                Uploading: false,
                                FileSrc:'',
                                UploadBtnState:'NotShow',
                                PopUp:'提示弹窗',
                                MessageTip:'上传保存成功！',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'上传失败，请稍后重试！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'图片已损坏，请重新选择！',
            });
        }
    };



    //上传屏蔽弹窗
    ChildBackUpload=(event)=>{
    };
    //查看人员详情弹窗
    InfoPopUpBack=(event)=>{
        if(event.type === 'CrossOut'){
            this.setState({
                TeamPePopUp:'NoShow',
            });
        }else if(event.type === 'Confirm'){
            if(event.BackInfo.name === '返回'){
                this.setState({
                    TeamPePopUp:'',
                });
            }
        }
    };
    render() {
        const props = {
            UploadState:this.state.UploadBtnState,
            RemoveUpload:(file) => {
                let FileList = this.state.FileList;
                let index = FileList.indexOf(file);
                let NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileSrc:'',
                    FileList:NewFileList,
                    Uploading: false,
                    UploadBtnState:'NotShow',
                });
            },
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = props.BeforeUpload;
                let FileSrc = window.URL.createObjectURL(file);
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/x-icon'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileSrc:FileSrc,
                            FileList:NewFileList,
                            Uploading: false,
                            UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };
        const NoTeam =
            <div>
                <div className={'NoTeamPrompt'}>您还未加入任何{this.state.MemberTopNav}，您可自己创建、受邀加入或申请加入团队</div>
                <div className={'NoTeamComment'}>(注：您只能有且仅有一个{this.state.MemberTopNav})</div>
                <button className={'NoTeamButton'} onClick={() => this.NoTeamCreate(this.state.MemberTopNav)}>创建自己团队</button>
                <button className={'NoTeamButton'} onClick={() => this.NoTeamJoin(this.state.MemberTopNav)}>加入已建团队</button>
            </div>;
        const YesTeam =
            <div className={'scrollBar'} style={{position:'relative',height:window.innerHeight-270,width:978,}}>
                <button className={'DropOutTeamBtn'} onClick={() => this.DropOutTeam(this.state.MemberTopNav)}>
                    {this.state.Authority === '队员' ? '退出团队' : (this.state.Authority === '管理员' ? '解散团队' : '')}
                </button>
                {
                    this.state.Authority === '管理员'&&
                    <button className={'DropOutTeamBtn'}
                            onClick={() => this.PeTeamManage(this.state.MemberTopNav)}
                            style={{right:156,}}
                    >团队管理</button>
                }

                <div className={'YesTeamTop'} style={{marginBottom:40,}}>
                    <div className={'YesTeamLogoBox'}>
                        <img className={'YesTeamLogo'}
                             alt={''}
                             src={this.state.FileSrc === '' ? this.state.TeamUser.flag_url : this.state.FileSrc}
                             style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',}}
                        />
                        {
                            this.state.Authority === '管理员' &&
                            <div className={'YesTeamLogoMask'}>
                                更改旗帜<Upload{...props}/>
                            </div>
                        }
                        {
                            this.state.UploadBtnState === 'Show' && this.state.Authority === '管理员' &&
                            <button
                                className={'MemberUploadBtn'}
                                onClick={this.state.Uploading ? null : this.UploadBtnClick.bind(this)}
                                disabled={this.state.Uploading === true}
                                style={this.state.Uploading ? {backgroundColor:'#D2D2D2',cursor:'wait',top:'-36px',left:0,}:{top:'-36px',left:0,}}
                            >{this.state.Uploading ? '正在上传···' : '确认上传'}</button>
                        }
                    </div>
                    <div className={'YesTeamInfo'}>
                        <div className={'YesTeamInfoList'} style={{marginBottom:40,}}>
                            <div className={'YesTeamInfoTit'}>团队名称</div>
                            <div className={'YesTeamInfoCon'}>{this.state.TeamUser.name}</div>
                        </div>
                        <div className={'YesTeamInfoList'}>
                            <div className={'YesTeamInfoTit'}>团队ID</div>
                            <div className={'YesTeamInfoCon'}>{this.state.TeamUser.team_num}</div>
                        </div>
                    </div>
                </div>
                <div className={'YesTeamPeople'}>
                    <div className={'YesTeamPeopleTitle'}>团队成员/ID</div>
                    <div className={'YesTeamPeopleConBox clearfix'}>
                        {
                            this.state.TeamInfoArr.map((item,index) =>
                                <div className={'YesTeamPeoList'}
                                     key={index}
                                     onClick={() => this.YesTeamPeoList(item,index)}
                                >
                                    {item.real_name}（{item.yy_num}）
                                </div>
                            )
                        }
                    </div>
                    <div className={'YesTeamPeopleComment'}>点击团队成员查看详情</div>
                </div>
            </div>;
        let ButtonType = [
            {
                name:'返回',
                bgColor:'#6EC2FF',
                color:'#FFFFFF',
            }
        ];
        return (
                <div className={'MemberPackageBox'} style={{minHeight:window.innerHeight-418,}}>
                    <div className={'MemberTopNavBox'}>
                        {
                            this.state.TopNavArr.map((item,index) =>
                                <div className={'MemberTopNav'} 
                                    key={index}
                                    onClick={() => this.MemberTopNav(item,index)}
                                    style={item === this.state.MemberTopNav ? this.state.NavSelected : this.state.NoSelected}
                                >
                                    {item}
                                </div>
                            )
                        }
                        <div className={'MemberPosition'}  style={{width:464,height:61,borderBottom:'1px solid #000000',}}> </div>
                    </div>
                    {
                        this.state.MemberTopNav === '竞技团队' &&
                        <div className={'PeTeamInfoBox'} style={{position:'relative',height:window.innerHeight-270,}}>
                            {
                                this.state.SportsTeamState === '无' &&
                                NoTeam
                            }
                            {
                                this.state.SportsTeamState === '有' &&
                                YesTeam
                            }
                            {
                                this.state.SportsTeamState === '加入已建团队' &&
                                <div>
                                    <button className={'DropOutTeamJoinBtn'} onClick={() => this.DropOutTeamJoin(this.state.MemberTopNav)}>返回</button>
                                    <PeTeamJoin TeamType={this.state.MemberTopNav}/>
                                </div>
                            }
                            {
                                this.state.SportsTeamState === '创建自己团队' &&
                                <PeTeamCreate
                                    TeamType={this.state.MemberTopNav}
                                    PeTeamCreateOutBackClick={this.PeTeamCreateOutBackClick.bind(this)}
                                />
                            }
                            {
                                this.state.SportsTeamState === '团队管理' &&
                                <div>
                                    <button className={'DropOutTeamJoinBtn'} onClick={() => this.DropOutTeamJoin(this.state.MemberTopNav)}>返回</button>
                                    <PeTeamManage
                                        AddPick={this.state.AddPick}
                                        TeamType={this.state.MemberTopNav}
                                        PeTeamCreateOutBackClick={this.PeTeamCreateOutBackClick.bind(this)}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '表演团队' &&
                        <div className={'PeTeamInfoBox scrollBar'} style={{position:'relative',height:window.innerHeight-270,}}>
                            {
                                this.state.PerformTeamState === '无' &&
                                NoTeam
                            }
                            {
                                this.state.PerformTeamState === '有' &&
                                YesTeam
                            }
                            {
                                this.state.PerformTeamState === '加入已建团队' &&
                                <div>
                                    <button className={'DropOutTeamJoinBtn'} onClick={() => this.DropOutTeamJoin(this.state.MemberTopNav)}>返回</button>
                                    <PeTeamJoin TeamType={this.state.MemberTopNav}/>
                                </div>
                            }
                            {
                                this.state.PerformTeamState === '创建自己团队' &&
                                <PeTeamCreate
                                    TeamType={this.state.MemberTopNav}
                                    PeTeamCreateOutBackClick={this.PeTeamCreateOutBackClick.bind(this)}
                                />

                            }
                            {
                                this.state.PerformTeamState === '团队管理' &&
                                <div>
                                    <button className={'DropOutTeamJoinBtn'} onClick={() => this.DropOutTeamJoin(this.state.MemberTopNav)}>返回</button>
                                    <PeTeamManage
                                        AddPick={this.state.AddPick}
                                        TeamType={this.state.MemberTopNav}
                                        PeTeamCreateOutBackClick={this.PeTeamCreateOutBackClick.bind(this)}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.MemberTopNav === '团队消息' &&
                        <PeTeamNews/>
                    }
                    {
                        this.state.TeamPePopUp === 'Show'&&
                        <InfoPopUp
                            UserInfo={this.state.TeamPeInfoArr}
                            InfoPopUpBack={this.InfoPopUpBack.bind(this)}
                            ButtonType={ButtonType}
                        />
                    }
                    {
                        this.state.PopUp === '有两个选择按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '退出团队的提示框' &&
                        <PopUp
                            ChildBackClick={this.OutBackClick.bind(this)}
                            type={'失败'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '上传屏蔽' &&
                        <PopUp
                            ChildBackClick={this.ChildBackUpload.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default PeTeam;
