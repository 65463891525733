import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import ExamRoomInfo from "./ExamRoomInfo";
import ExamRoomList from "./ExamRoomList";
import ExamPersonal from "./ExamPersonal";
import ExamPeopleList from "./ExamPeopleList";
import ExamPeInfoList from "./ExamPeInfoList";
import ChangeEditor from "./ChangeEditor";
import PayActive from "../pay/PayActive";
import ExamGroup from "./ExamGroup";
import ExamGroupList from "./ExamGroupList";
import ExamGrInfoList from "./ExamGrInfoList";
import './exam.css';
import {get,} from "../../ajax/tools";
import PopUp from "../round/PopUp";



class Exam extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight: '',
            NowNavListZhName:'',
            NowNavListEnName:'',
            ExamNavArr:[],
            ExamState:'',//申请考试模块的页面状态
            GroupOrPersonalName:'',//团体或个人用户的名字，默认为空，登陆后验证赋值
            UserIdNumber:'',//此个人用户id
            UserIdentity:'',//此个人用户身份
            PageType:'',//从何页面转到交钱支付

            OrderInfo:{},//订单信息
            OrderIdInfo:{},//订单信息
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 0){
                    this.setState({
                        User:User,
                        GroupOrPersonalName:User.real_name+'（'+User.yy_num+'）',
                        ExamNavArr:[
                            {
                                id:'0',
                                ZhName:'返回会员中心',
                                EnName:'aviation school',
                                router:'/member',
                            },
                            {
                                id:'1',
                                ZhName:'个人测试',
                                EnName:'Personal test',
                                router:'/exam_p',
                            },
                        ],
                    });
                }else if (User.type === 1){
                    this.setState({
                        User:User,
                        GroupOrPersonalName:User.org_name,
                        ExamNavArr:[
                            {
                                id:'0',
                                ZhName:'返回会员中心',
                                EnName:'aviation school',
                                router:'/member',
                            },
                            {
                                id:'2',
                                ZhName:'临时考场',
                                EnName:'Temporary room',
                                router:'/exam_r',
                            },
                            {
                                id:'3',
                                ZhName:'团体测试',
                                EnName:'Group test',
                                router:'/exam_g',
                            },
                        ],
                    });
                }
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });


        let ThreeIndex = this.props.location.pathname.lastIndexOf ("_");
        let sb = this.props.location.pathname.substring(ThreeIndex+1);
        if (sb === 'p'){
            this.setState({
                NowNavListZhName:'个人测试',
                NowNavListEnName:'Personal test',
            });
            get({url:'exam/read_order'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'个人测试的信息列表',
                    });
                }else if(res.errno === '4002'){
                    this.setState({
                        ExamState:'无个人测试的申请信息',
                    });
                }else {
                    this.setState({
                        PopUp:'接口未知错误请求提示框',
                        MessageTip:'未知错误，请联系我们！',
                    });
                }
            });
        }else if (sb === 'r') {
            this.setState({
                NowNavListZhName:'临时考场',
                NowNavListEnName:'Temporary room',
            });
            get({url:'exam/get_exam_room_all'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'申请临时考场的信息列表',
                    });
                }else if(res.errno === '4002'){
                    this.setState({
                        ExamState:'无申请临时考场信息',
                    });
                }else {
                    this.setState({
                        PopUp:'接口未知错误请求提示框',
                        MessageTip:'未知错误，请联系我们！',
                    });
                }
            });
        }else if (sb === 'g') {
            this.setState({
                NowNavListZhName:'团体测试',
                NowNavListEnName:'Group test',
            });
            get({url:'exam/read_order'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'团体测试的信息列表',
                    });
                }else if(res.errno === '4002'){
                    this.setState({
                        ExamState:'无团体测试的申请信息',
                    });
                }else {
                    this.setState({
                        PopUp:'接口未知错误请求提示框',
                        MessageTip:'未知错误，请联系我们！',
                    });
                }
            });
        }
    };


    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //点击左侧菜单
    AccountListClick=(item,index) =>{
        const {history} = this.props;
        history.push(item.router);
    };



    //无申请临时考场信息按钮
    ExamRoomNotStateBtn(){
        this.setState({
            ExamState:'申请临时考场的信息填写',
        });
    };
    //申请临时考场的信息填写返回
    ExamRoomInfoBack=(event)=>{
        if(event === 'ExamRoomSubmit'){
            this.setState({
                ExamState:'申请临时考场人工审核',
            });
        }
    };
    //返回已申请考场列表
    ManualReviewBlack(){
        this.setState({
            ExamState:'申请临时考场的信息列表',
        });
    };
    ExamRoomListBack=()=>{
        this.setState({
            ExamState:'无申请临时考场信息',
        });
    };






    //无个人测试申请信息按钮
    ExamPeopleNotStateBtn(){
        this.setState({
            ExamState:'个人测试的申请操作',
        });
    };

    //无团体测试申请信息按钮
    ExamGroupNotStateBtn(){
        this.setState({
            ExamState:'团体测试的申请操作',
        });
    };



    //个人测试的申请操作返回
    ExamPersonalBack=(event)=>{
        if(event === '保存稍后支付'){
            this.setState({
                ExamState:'个人测试的信息列表',
            });
        }else if(event.BackType === '立即支付'){
            this.setState({
                ExamState:'支付方式',
                PageType:'个人测试的信息列表',
                OrderIdInfo:event,
            });
        }
    };


    //团体测试的申请操作返回
    ExamGroupBack=(event)=>{
        if(event === '保存稍后支付'){
            this.setState({
                ExamState:'团体测试的信息列表',
            });
        }else if(event.BackType === '立即支付'){
            this.setState({
                ExamState:'支付方式',
                PageType:'团体测试的信息列表',
                OrderIdInfo:event,
            });
        }
    };

    //个人测试信息列表的点击返回
    ExamPeopleListBack=(event)=>{
        if(event.BackType === '查看信息'){
            get({url:'exam/read_order_detail/'+event.id}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'个人测试详细申请信息',
                        OrderInfo:event,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else if(event.BackType === '去支付'){
            this.setState({
                ExamState:'支付方式',
                PageType:'个人测试的信息列表',
                OrderIdInfo:{OrderID:event.order_id,},
            });
        }else if(event.BackType === '已无订单信息'){
            this.setState({
                ExamState:'无个人测试的申请信息',
            });
        }
    };


    //团体测试信息列表的点击返回
    ExamGroupListBack=(event)=>{
        if(event.BackType === '查看信息'){
            get({url:'exam/read_order_detail/'+event.id}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'团体测试详细申请信息',
                        OrderInfo:event,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else if(event.BackType === '去支付'){
            this.setState({
                ExamState:'支付方式',
                PageType:'团体测试的信息列表',
                OrderIdInfo:{OrderID:event.order_id,},
            });
        }else if(event.BackType === '已无订单信息'){
            this.setState({
                ExamState:'无团体测试的申请信息',
            });
        }
    };

    //个人测试信息详细信息的点击返回
    ExamPeInfoListBack=(event)=>{
        if(event.type === '变更编辑'){
            this.setState({
                ExamState:'个人测试的信息变更编辑',
                UserIdNumber:1,
                UserIdentity:'学员',
            });
        }else if (event.BackType === '立即支付') {
            this.setState({
                ExamState:'支付方式',
                PageType:'个人测试详细申请信息',
                OrderIdInfo:{OrderID:event.order_id,},
            });
        }
    };


    //团体测试信息详细信息的点击返回
    ExamGrInfoListBack=(event)=>{
        if(event.type === '变更编辑'){
            this.setState({
                ExamState:'团体测试的信息变更编辑',
                UserIdNumber:1,
                UserIdentity:'学员',
            });
        }else if (event.BackType === '立即支付') {
            this.setState({
                ExamState:'支付方式',
                PageType:'团体测试详细申请信息',
                OrderIdInfo:{OrderID:event.order_id,},
            });
        }
    };



    //个人测试详细信息列表返回个人测试的信息列表
    PeInfoBackBtn=()=>{
        this.setState({
            ExamState:'个人测试的信息列表',
        });
    };

    //团体测试详细信息列表返回个人测试的信息列表
    GrInfoBackBtn=()=>{
        this.setState({
            ExamState:'团体测试的信息列表',
        });
    };


    //个人测试的信息变更编辑页面的返回
    ExamChangeEditorBack=(event)=>{
        if(event.UserBack === '个人测试'){
            this.setState({
                ExamState:'个人测试详细申请信息',
            });
        }else if(event.UserBack === '团体测试'){
            this.setState({
                ExamState:'团体测试详细申请信息',
            });
        }
    };


    //支付方式页面的的返回
    PayActiveBack=(event)=>{
        this.setState({
            ExamState:event,
        });
    };



    //返回按钮
    BackButton(){
        let ExamState = this.state.ExamState;
        if (ExamState === '申请临时考场的信息填写'){
            get({url:'exam/get_exam_room_all'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'申请临时考场的信息列表',
                    });
                }else if(res.errno === '4002'){
                    this.setState({
                        ExamState:'无申请临时考场信息',
                    });
                }
            });
        }else if (ExamState === '团体测试的申请操作'){
            get({url:'exam/read_order'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'团体测试的信息列表',
                    });
                }else if(res.errno === '4002'){
                    this.setState({
                        ExamState:'无团体测试的申请信息',
                    });
                }
            });
        }else if (ExamState === '个人测试的申请操作'){
            get({url:'exam/read_order'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamState:'个人测试的信息列表',
                    });
                }else if(res.errno === '4002'){
                    this.setState({
                        ExamState:'无个人测试的申请信息',
                    });
                }
            });
        }
    };



    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'} style={{position:'relative',}}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>申请{this.state.NowNavListZhName}服务</div>
                                <div className={'MainConTitEN'}>applicant&nbsp;&nbsp;SERVICE</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}</div>
                        </div>
                        <div className={'ContentMainBox clearfix'} style={{height:window.innerHeight-191,}}>
                            <div className={'MainLeftNavBox'}>
                                <ul className={'MainLeftNavListBox'}>
                                    {
                                        this.state.ExamNavArr.map((item,index) =>
                                            <li className={item.ZhName === this.state.NowNavListZhName ? 'MainLeftNavActiveList' : 'MainLeftNavList'}
                                                key={index}
                                                onClick={() => this.AccountListClick(item,index)}
                                            >
                                                {item.ZhName}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className={'MainThisNavBox'} style={{height:window.innerHeight-191,}}>
                                <div className={'ExamAccountTitBox'}>
                                    <div className={'ExamAccountName'}>{this.state.GroupOrPersonalName}</div>
                                    {
                                        this.state.ExamState === '申请临时考场的信息列表' &&
                                        <button className={'ExamStateBtn'}
                                                onClick={this.ExamRoomNotStateBtn.bind(this)}
                                                style={{float:'right',height:38,lineHeight:'38px',marginTop:6,marginRight:0,minWidth:100,}}
                                        >
                                            申请临时考场
                                        </button>
                                    }
                                    {
                                        this.state.ExamState === '申请临时考场的信息填写' &&
                                        <div className={'DetailsBackBox clearfix'} style={{marginTop:16,}} onClick={this.BackButton.bind(this)}>
                                            <div className={'DetailsBackIco'}> </div>
                                            <div className={'DetailsBackWord'}>返回</div>
                                        </div>
                                    }
                                    {
                                        this.state.ExamState === '个人测试的信息列表' &&
                                        <div>
                                            <div style={{float:'right',width:124,height:50,}}> </div>
                                            <button className={'ExamStateBtn'}
                                                    onClick={this.ExamPeopleNotStateBtn.bind(this)}
                                                    style={{float:'right',height:38,lineHeight:'38px',marginTop:6,marginRight:0,minWidth:100,}}
                                            >
                                                申请测试
                                            </button>
                                        </div>
                                    }
                                    {
                                        this.state.ExamState === '个人测试详细申请信息' &&
                                        <div>
                                            <div className={'ExamAccountName'} style={{textIndent:0,}}>> 详细申请信息</div>
                                            <div className={'DetailsBackBox clearfix'} style={{marginTop:16,}} onClick={this.PeInfoBackBtn.bind(this)}>
                                                <div className={'DetailsBackIco'}> </div>
                                                <div className={'DetailsBackWord'}>返回</div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.ExamState === '团体测试的信息列表' &&
                                        <div>
                                            <div style={{float:'right',width:124,height:50,}}> </div>
                                            <button className={'ExamStateBtn'}
                                                    onClick={this.ExamGroupNotStateBtn.bind(this)}
                                                    style={{float:'right',height:38,lineHeight:'38px',marginTop:6,marginRight:6,minWidth:100,}}
                                            >
                                                申请测试
                                            </button>
                                        </div>
                                    }
                                    {
                                        this.state.ExamState === '团体测试详细申请信息' &&
                                        <div>
                                            <div className={'ExamAccountName'} style={{textIndent:0,}}>> 详细申请信息</div>
                                            <div className={'DetailsBackBox clearfix'} style={{marginTop:16,}} onClick={this.GrInfoBackBtn.bind(this)}>
                                                <div className={'DetailsBackIco'}> </div>
                                                <div className={'DetailsBackWord'}>返回</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.ExamState === '团体测试的申请操作' &&
                                        <div className={'DetailsBackBox clearfix'} style={{marginTop:16,}} onClick={this.BackButton.bind(this)}>
                                            <div className={'DetailsBackIco'}> </div>
                                            <div className={'DetailsBackWord'}>返回</div>
                                        </div>
                                    }
                                    {
                                        this.state.ExamState === '个人测试的申请操作' &&
                                        <div className={'DetailsBackBox clearfix'} style={{marginTop:16,}} onClick={this.BackButton.bind(this)}>
                                            <div className={'DetailsBackIco'}> </div>
                                            <div className={'DetailsBackWord'}>返回</div>
                                        </div>
                                    }

                                </div>
                                {
                                    this.state.NowNavListZhName === '临时考场'&&
                                    <div>
                                        {
                                            this.state.ExamState === '无申请临时考场信息' &&
                                            <div className={'NotExamConBox'}>
                                                <div className={'NotExamConBoxTit'}>您还没有任何临时考场申请信息</div>
                                                <button className={'ExamStateBtn'} onClick={this.ExamRoomNotStateBtn.bind(this)}>申请临时考场</button>
                                            </div>
                                        }
                                        {
                                            this.state.ExamState === '申请临时考场的信息列表' &&
                                            <ExamRoomList
                                                ExamRoomListBack={this.ExamRoomListBack.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '申请临时考场的信息填写' &&
                                            <ExamRoomInfo
                                                ExamRoomInfoBack={this.ExamRoomInfoBack.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '申请临时考场人工审核' &&
                                            <div className={'ManualReviewBox scrollBar'}  style={{height:window.innerHeight-286 > 368 ? window.innerHeight-286 : 368,margin:'20px auto',}}>
                                                <div className={'ManualReviewIco'}> </div>
                                                <div className={'ManualReviewWordBox'}>
                                                    <div className={'ManualReviewWord'} style={{textAlign:'left',}}>人工审核中...</div>
                                                    <div className={'ManualReviewWord'} style={{textAlign:'right',}}>请您耐心等待</div>
                                                </div>
                                                <div className={'ManualReviewStep'}><div className={'ManualReviewStepHave'}> </div></div>
                                                <div className={'ManualReviewBlackWord'}
                                                     onClick={this.ManualReviewBlack.bind(this)}
                                                     style={{marginTop:100,}}
                                                >返回已申请考场列表</div>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.NowNavListZhName === '个人测试'&&
                                    <div>
                                        {
                                            this.state.ExamState === '无个人测试的申请信息' &&
                                            <div className={'NotExamConBox'}>
                                                <div className={'NotExamConBoxTit'}>您还没有任何测试申请信息</div>
                                                <button className={'ExamStateBtn'} onClick={this.ExamPeopleNotStateBtn.bind(this)}>申请测试</button>
                                            </div>
                                        }
                                        {
                                            this.state.ExamState === '个人测试的申请操作' &&
                                            <ExamPersonal
                                                ExamPersonalBack={this.ExamPersonalBack.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '个人测试的信息列表' &&
                                            <ExamPeopleList
                                                ExamPeopleListBack={this.ExamPeopleListBack.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '个人测试详细申请信息' &&
                                            <ExamPeInfoList
                                                ExamPeInfoListBack={this.ExamPeInfoListBack.bind(this)}
                                                OrderInfo={this.state.OrderInfo}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '个人测试的信息变更编辑' &&
                                            <ChangeEditor
                                                ExamChangeEditorBack={this.ExamChangeEditorBack.bind(this)}
                                                UserIdNumber={this.state.UserIdNumber}
                                                //UserIdentity={this.state.UserIdentity}
                                                UserIdentity={'学员'}
                                                UserBack={'个人测试'}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '支付方式'&&
                                            <PayActive
                                                PayActiveBack={this.PayActiveBack.bind(this)}
                                                PageType={this.state.PageType}
                                                OrderIdInfo={this.state.OrderIdInfo}
                                            />
                                        }
                                    </div>
                                }
                                {
                                    this.state.NowNavListZhName === '团体测试'&&
                                    <div>
                                        {
                                            this.state.ExamState === '无团体测试的申请信息' &&
                                            <div className={'NotExamConBox'}>
                                                <div className={'NotExamConBoxTit'}>您还没有任何测试申请信息</div>
                                                <button className={'ExamStateBtn'} onClick={this.ExamGroupNotStateBtn.bind(this)}>申请测试</button>
                                            </div>
                                        }
                                        {
                                            this.state.ExamState === '团体测试的申请操作' &&
                                            <ExamGroup
                                                ExamGroupBack={this.ExamGroupBack.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '团体测试的信息列表' &&
                                            <ExamGroupList
                                                ExamGroupListBack={this.ExamGroupListBack.bind(this)}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '团体测试详细申请信息' &&
                                            <ExamGrInfoList
                                                ExamGrInfoListBack={this.ExamGrInfoListBack.bind(this)}
                                                OrderInfo={this.state.OrderInfo}

                                            />
                                        }
                                        {
                                            this.state.ExamState === '团体测试的信息变更编辑' &&
                                            <ChangeEditor
                                                ExamChangeEditorBack={this.ExamChangeEditorBack.bind(this)}
                                                UserIdNumber={this.state.UserIdNumber}
                                                //UserIdentity={this.state.UserIdentity}
                                                UserIdentity={'学员'}
                                                UserBack={'团体测试'}
                                            />
                                        }
                                        {
                                            this.state.ExamState === '支付方式'&&
                                            <PayActive
                                                PayActiveBack={this.PayActiveBack.bind(this)}
                                                PageType={this.state.PageType}
                                                OrderIdInfo={this.state.OrderIdInfo}
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '接口未知错误请求提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'错误'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Exam;
