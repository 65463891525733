import React, { Component } from 'react';
import './exam.css';
import PopUp from "../round/PopUp";
import Timer from "../round/Timer";
import CheckboxIcoNot from "../../style/image/CheckboxIcoNot.png";
import CheckboxIcoHave from "../../style/image/CheckboxIcoHave.png";
import {get, post} from "../../ajax/tools";



class ExamPeopleList extends Component {

    constructor(props){
        super(props);
        this.state = {
            ExamPeopleListArr:[],
            //AuditState:'已支付',//审核状态的的标记识别，值为'去支付>>'，'已支付'，
            AllCheckboxIcoToggleOn: true,//全选框
            AllCheckboxIcoBgImg:'url('+CheckboxIcoNot+')',//全选框的背景图
            AlreadyNumArr:[],//被选中项目的数组定义
            AlreadyNum: 0,//被选中项目的数据数量
        };
    }
    componentDidMount=function() {
        get({url:'exam/read_order'}).then((res) => {
            if(res.errno === '200'){
                const ExamPeopleListArr=[];
                for(let i=0;i<res.data.length;i++){
                    const ExamPeopleList = res.data[i];
                    ExamPeopleList.Checkbox= false;
                    ExamPeopleListArr.push(ExamPeopleList);
                }
                this.setState({
                    ExamPeopleListArr:ExamPeopleListArr,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };



    AllClick = () => {
        this.setState({
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{

        };
    };


    //点击多选框全选
    ExamListAllCheckbox=(event)=>{
        const ExamPeopleList=[];
        const AlreadyNumArr=[];
        for(let i=0;i<event.length;i++){
            const EventList= event[i];
            if(this.state.AllCheckboxIcoToggleOn === false){
                EventList.Checkbox = false;
                AlreadyNumArr.push(EventList);
                ExamPeopleList.push(EventList);
            }else if(this.state.AllCheckboxIcoToggleOn === true){
                EventList.Checkbox = true;
                AlreadyNumArr.push(EventList);
                ExamPeopleList.push(EventList);
            }
        }
        this.setState(prevState => ({
            AllCheckboxIcoToggleOn: !prevState.AllCheckboxIcoToggleOn,
            AllCheckboxIcoBgImg:prevState.AllCheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',
            AlreadyNum:prevState.AllCheckboxIcoToggleOn ? AlreadyNumArr.length : 0,
            ExamPeopleListArr:ExamPeopleList,
            AlreadyNumArr:prevState.AllCheckboxIcoToggleOn ? AlreadyNumArr : [],
        }));
    };



    //点击单个多选框
    OneCheckboxIco=(item,index)=>{
        let ExamPeopleList = this.state.ExamPeopleListArr;
        ExamPeopleList[index]['Checkbox'] = item.Checkbox !== true;
        const AlreadyNumArr = [...this.state.AlreadyNumArr];
        if(item.Checkbox === true){
            AlreadyNumArr.push(item);
            this.setState({
                AlreadyNumArr:AlreadyNumArr,
                AlreadyNum:AlreadyNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<AlreadyNumArr.length;i++){
                if(AlreadyNumArr[i].id === item.id){
                    AlreadyNumArr.splice(i,1);
                }
            }
            this.setState({
                AlreadyNumArr:AlreadyNumArr,
                AlreadyNum:AlreadyNumArr.length,
            });
        }
        this.setState({
            ExamPeopleListArr:ExamPeopleList,
            AllCheckboxIcoToggleOn:this.state.ExamPeopleListArr.length !== AlreadyNumArr.length,
            AllCheckboxIcoBgImg: this.state.ExamPeopleListArr.length === AlreadyNumArr.length ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',
        });
    };



    //倒计时到0秒时的渲染变化
    ChildTimerBack=(event)=>{
        let ExamPeopleList = this.state.ExamPeopleListArr;
        for(let i=0;i<ExamPeopleList.length;i++){
            if(ExamPeopleList[i].id === event.SecondId){
                ExamPeopleList[i].status = 2;
            }
        }
        this.setState({
            ExamPeopleListArr:ExamPeopleList,
        });
    };

    //批量删除按钮
    DeleteCheckBtnBox(){
        this.setState({
            PopUp:'确认是否删除弹窗',
            MessageTip:'确认是否批量删除申请信息？',
        });
    };


    //删除弹窗点击的逻辑处理
    DeleteBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            let AlreadyNumArr = this.state.AlreadyNumArr;
            post({
                url:'exam/cancel_order',
                data:{
                    order_list:AlreadyNumArr,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    get({url:'exam/read_order'}).then((res) => {
                        if(res.errno === '200'){
                            const ExamPeopleListArr=[];
                            for(let i=0;i<res.data.length;i++){
                                const ExamPeopleList = res.data[i];
                                ExamPeopleList.Checkbox= false;
                                ExamPeopleListArr.push(ExamPeopleList);
                            }
                            this.setState({
                                ExamPeopleListArr:ExamPeopleListArr,
                                AllCheckboxIcoToggleOn: true,
                                AllCheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
                                AlreadyNumArr:[],
                                AlreadyNum: 0,
                                PopUp:'',
                            });
                        }else {
                            this.props.ExamPeopleListBack({BackType:'已无订单信息',});
                            this.setState({
                                PopUp:'',
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
            this.setState({
                PopUp:'',
                AlreadyNum: 0 ,
            });
        }
    };


    //查看申请详细信息的按钮
    SeeDetails=(item,index)=>{
        item['BackType'] = '查看信息';
        this.props.ExamPeopleListBack(item);
    };
    //去支付的按钮
    ToPay=(item,index)=>{
        item['BackType'] = '去支付';
        this.props.ExamPeopleListBack(item);
    };



    render() {
        //console.log(this.state.ExamPeopleListArr)
        return (
            <div className={'ExamMainBoxes'} style={{width:970,position:'relative',}}>
                <div className={'ExamHaveHeader clearfix'}>
                    <div className={'ExamHaveHeaderList'} style={{width:40,}}>
                        <div className={'ExamHeaderListCheckbox'}
                             style={{backgroundImage:this.state.AllCheckboxIcoBgImg}}
                             onClick={() => this.ExamListAllCheckbox(this.state.ExamPeopleListArr)}
                        > </div>
                    </div>
                    <div className={'ExamHaveHeaderList'} style={{width:80,}}>序号</div>
                    <div className={'ExamHaveHeaderList'} style={{width:206,}}>申请时间</div>
                    <div className={'ExamHaveHeaderList'} style={{width:206,}}>详细信息</div>
                    <div className={'ExamHaveHeaderList'} style={{width:206,}}>等待支付</div>
                    <div className={'ExamHaveHeaderList'} style={{width:206,}}>支付状态</div>
                </div>
                <div className={'ExamMainOverflow scrollBar'} style={{height:window.innerHeight-298,}}>
                    {
                        this.state.ExamPeopleListArr.map((item,index) =>
                        <div className={'ExamHaveCon clearfix'} key={index} style={{height:20,}}>
                            <div className={'ExamHaveConList'} style={{width:40,}}>
                                <div className={'ExamHeaderListCheckbox'}
                                     style={{backgroundImage:item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',}}
                                     onClick={() => this.OneCheckboxIco(item,index)}
                                > </div>
                            </div>
                            <div className={'ExamHaveConList'} style={{width:80,}}>{index+1}</div>
                            <div className={'ExamHaveConList'} style={{width:206,}}>{item.ctime}</div>
                            <div className={'ExamHaveConList'} style={{width:206,}}>
                                <span className={'ClickDetails'} onClick={() => this.SeeDetails(item,index)}>查看</span>
                            </div>
                            <div className={'ExamHaveConList'} style={{width:206,height:20,}}>
                                {
                                    item.status === 0 &&
                                    <Timer
                                        ChildTimerBack={this.ChildTimerBack.bind(this)}
                                        Second={item.expire_time}
                                        SecondId={item.id}
                                    />
                                }
                                {
                                    item.status !== 0 &&
                                    <span>——</span>
                                }
                            </div>
                            <div className={'ExamHaveConList DisplayFlex'} style={{width:206,}}>
                                <div className={'AuditStateWord'}
                                     style={{color:item.status === 0 ? '#6EC2FF' : '',fontSize:14,
                                         cursor:item.status === 0 ? 'pointer' : '',
                                     }}
                                     onClick={() => item.status === 0 ? this.ToPay(item,index) : null}
                                >
                                    {item.status === 0 ? '去支付>>': (item.status === 1 ? '已支付': item.status === 2 ? '支付过期': '')}
                                </div>
                                {
                                    item.status === 1 &&
                                    <div className={'AuditStateIco'}> </div>
                                }
                            </div>
                        </div>
                        )
                    }
                </div>
                <div className={'DeleteCheckBtnBox'}
                     style={{marginRight:6,backgroundColor:this.state.AlreadyNum === 0 ? '#D5D5D5' : '',}}
                     onClick={this.state.AlreadyNum === 0 ? null : this.DeleteCheckBtnBox.bind(this)}
                >
                    删除({this.state.AlreadyNum})
                </div>
                {
                    this.state.PopUp === '确认是否删除弹窗' &&
                    <PopUp
                        ChildBackClick={this.DeleteBackClick.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ExamPeopleList;
