import React, { Component } from 'react';
import './member.css';
import '../round/round.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";


class PeYunCheng extends Component {

    constructor(props){
        super(props);
        this.state = {
            TrainInfoArr:[],
            YunChengState:'',
        };
    }
    componentDidMount=function() {
        get({url:'cloudprogram/show_cloudpro_stu_per'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TrainInfoArr:res.data,
                    YunChengState:'List',
                });
            }else {
                this.setState({
                    TrainInfoArr:[],
                    YunChengState:'NoList',
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };




    AgreeClick=(item,index)=>{
        post({
            url:'cloudprogram/update_cloudpro_stu',
            data:{
                tcps_id:item.id,
                status:1,
            },
        }).then((res) => {
            if (res.errno === '200') {
                get({url:'cloudprogram/show_cloudpro_stu_per'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TrainInfoArr:res.data,
                        });
                    }
                });
            } else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };
    RefuseClick=(item,index)=>{
        post({
            url:'cloudprogram/update_cloudpro_stu',
            data:{
                tcps_id:item.id,
                status:2,
            },
        }).then((res) => {
            if (res.errno === '200') {
                get({url:'cloudprogram/show_cloudpro_stu_per'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TrainInfoArr:res.data,
                        });
                    }
                });
            } else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };
    RemoveClick=(item,index)=>{
        get({url:'cloudprogram/del_cloudpro_stu/'+item.id}).then((res) => {
            if(res.errno === '200'){
                get({url:'cloudprogram/show_cloudpro_stu_per'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TrainInfoArr:res.data,
                            YunChengState:'List',
                        });
                    }else {
                        this.setState({
                            TrainInfoArr:[],
                            YunChengState:'NoList',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    {
                        this.state.YunChengState === 'NoList'&&
                        <div style={{padding:'200px 0',}}>
                            <div className={'TrainDataMain'}>
                                <div className={'keyNumLogo'}> </div>
                                <div className={'keyNumTitle'}>还未有训练中心或青少年航校等组织机构邀请您！</div>
                            </div>
                        </div>
                    }
                    {
                        this.state.YunChengState === 'List'&&
                        <div>
                            <div className={'GrGameHeaderBox'} style={{width:1000,}}>
                                <div className={'GrGameHeaderInfo'} style={{width:250,}}>组织机构名称</div>
                                <div className={'GrGameHeaderInfo'} style={{width:250,}}>班级名称</div>
                                <div className={'GrGameHeaderInfo'} style={{width:240,}}>组织机构类型</div>
                                <div className={'GrGameHeaderInfo'} style={{width:250,}}>操作</div>
                            </div>
                            <div className={'GrGameContentBox scrollBar'} style={{width:1000,margin:'0 auto',height:window.innerHeight-232,}}>
                                {
                                    this.state.TrainInfoArr.map((item,index) =>
                                        <div className={'GrGameContentList'} style={{width:1000,}} key={index}>
                                            <div className={'GrGameContentInfo'} style={{width:250,}}>{item.name}</div>
                                            <div className={'GrGameContentInfo'} style={{width:250,}}>{item.class_name}</div>
                                            <div className={'GrGameContentInfo'} style={{width:240,}}>
                                                {
                                                    item.type === 1&&
                                                    <span>设备厂商</span>
                                                }
                                                {
                                                    item.type === 2&&
                                                    <span>训练中心</span>
                                                }
                                                {
                                                    item.type === 3&&
                                                    <span>青少年航校</span>
                                                }
                                                {
                                                    item.type === 4&&
                                                    <span>实训基地</span>
                                                }
                                            </div>
                                            <div className={'GrGameContentInfo'} style={{width:250,}}>
                                                {
                                                    item.status === 0&&
                                                    <div style={{display:'table',margin:'0 auto',}}>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 10px',color:'#009944',textDecoration:'underline',cursor:'pointer',}}
                                                            onClick={() => this.AgreeClick(item,index)}
                                                        >接受</span>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 10px',color:'#ff0000',textDecoration:'underline',cursor:'pointer',}}
                                                            onClick={() => this.RefuseClick(item,index)}
                                                        >拒绝</span>
                                                    </div>
                                                }
                                                {
                                                    item.status === 1&&
                                                    <div style={{display:'table',margin:'0 auto',}}>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 10px',color:'#009944',}}
                                                        >已接受</span>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 10px',color:'#ff0000',textDecoration:'underline',cursor:'pointer',}}
                                                            onClick={() => this.RemoveClick(item,index)}
                                                        >退出</span>
                                                    </div>
                                                }
                                                {
                                                    item.status === 2&&
                                                    <div style={{display:'table',margin:'0 auto',}}>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 10px',color:'#ff0000',}}
                                                        >已拒绝</span>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 10px',color:'#009944',textDecoration:'underline',cursor:'pointer',}}
                                                            onClick={() => this.AgreeClick(item,index)}
                                                        >重新接受</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default PeYunCheng;
