import React, { Component } from 'react';
import './round.css';


class Triangle extends Component {

    constructor(props){
        super(props);
        this.state = {
            Direction:'',
            Color:'',
            Width:'',
            Height:'',
            Top:'',
            Right:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            Direction:history.Direction,
            Color:history.Color,
            Width:history.Width,
            Height:history.Height,
            Top:history.Top,
            Right:history.Right,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        this.setState({
            Direction:history.Direction,
            Color:history.Color,
            Width:history.Width,
            Height:history.Height,
            Top:history.Top,
            Right:history.Right,
        });
    };

    render() {
        return (
                <div>
                    {
                        this.state.Direction === 'down' &&
                        <div style={{
                            position:'absolute',
                            top:this.state.Top,
                            right:this.state.Right,
                            width:0,
                            height:0,
                            borderWidth:this.state.Height+' '+this.state.Width+' 0',
                            borderStyle:'solid',
                            borderColor:this.state.Color+' transparent transparent',
                            cursor: 'pointer'
                        }}> </div>
                    }
                    {
                        this.state.Direction === 'right' &&
                        <div style={{
                            position:'absolute',
                            top:this.state.Top,
                            right:this.state.Right,
                            width:0,
                            height:0,
                            borderWidth:this.state.Height+' 0'+this.state.Width+' '+this.state.Height,
                            borderStyle:'solid',
                            borderColor:'transparent transparent transparent '+this.state.Color,
                            cursor: 'pointer'
                        }}> </div>
                    }
                    {
                        this.state.Direction === 'left' &&
                        <div style={{
                            position:'absolute',
                            top:this.state.Top,
                            right:this.state.Right,
                            width:0,
                            height:0,
                            borderWidth:this.state.Height+' 0'+this.state.Width+' '+this.state.Height,
                            borderStyle:'solid',
                            borderColor:'transparent '+this.state.Color+' transparent transparent',
                            cursor: 'pointer'
                        }}> </div>
                    }
                    {
                        this.state.Direction === 'top' &&
                        <div style={{
                            position:'absolute',
                            top:this.state.Top,
                            right:this.state.Right,
                            width:0,
                            height:0,
                            borderWidth:this.state.Height+' 0'+this.state.Width+' ',
                            borderStyle:'solid',
                            borderColor:'transparent transparent '+this.state.Color,
                            cursor: 'pointer'
                        }}> </div>
                    }
                </div>
        );
    }
}


export default Triangle;
