import React, { Component } from 'react';
import './exam.css';
import PopUp from "../round/PopUp";
import BoundCoach from "./BoundCoach";
import Triangle from "../round/Triangle";
import ExamStudentArr from "./ExamStudent";
import ExamTeacherArr from "./ExamTeacher";

//import {get,} from "../../ajax/tools";



class ChangeEditor extends Component {

    constructor(props){
        super(props);
        this.state = {
            UserIdNumber:'',//此个人用户id
            UserIdentity:'',//此个人用户身份
            UserBack:'',//是何种身份来此页面
            UserExamRoom:'此个人用户此申请的考场',//此个人用户此申请的考场
            UserExamRoomArr:['1','2','3','4','5','6',],//考场选择数组
            UserExamRoomToggleOn: true,
            UserExamRoomDisplay: 'none',
            UserExamTime:'此个人用户此申请的测试时间',//此个人用户此申请的测试时间
            UserExamTimeArr:['1','2','3','4','5','6',],//测试时间选择数组
            UserExamTimeToggleOn: true,
            UserExamTimeDisplay: 'none',
            UserExamIdt:'学员',//此个人用户此申请的身份
            UserExamIdtArr:['教员','学员',],//测试时间选择身份数组
            UserExamIdtToggleOn: true,
            UserExamIdtDisplay: 'none',
            UserExamItem:'此个人用户此申请的项目',//此个人用户此申请的项目
            UserExamItemArr:ExamStudentArr,//测试时间选择项目数组
            UserExamItemToggleOn: true,
            UserExamItemDisplay: 'none',
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            UserIdNumber:history.UserIdNumber,
            UserIdentity:history.UserIdentity,
            UserBack:history.UserBack,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        this.setState({
            UserIdNumber:history.UserIdNumber,
            UserIdentity:history.UserIdentity,
            UserBack:history.UserBack,
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };



    AllClick = () => {
        this.setState({
            UserExamRoomToggleOn: true,
            UserExamRoomDisplay: 'none',
            UserExamTimeToggleOn: true,
            UserExamTimeDisplay: 'none',
            UserExamIdtToggleOn: true,
            UserExamIdtDisplay: 'none',
            UserExamItemToggleOn: true,
            UserExamItemDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.props.ExamChangeEditorBack({
                typeInfo:'保存变更并返回',
                UserBack:this.state.UserBack,
            });
            this.setState({
                PopUp:'',
            });
        }
    };


    //点击选择考场的下拉按钮
    ActiveUserExamRoom(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            UserExamRoomToggleOn: !prevState.UserExamRoomToggleOn,
            UserExamRoomDisplay:prevState.UserExamRoomToggleOn ? 'block' : 'none',
            UserExamTimeToggleOn: true,
            UserExamTimeDisplay: 'none',
            UserExamIdtToggleOn: true,
            UserExamIdtDisplay: 'none',
            UserExamItemToggleOn: true,
            UserExamItemDisplay: 'none',
        }));
    };
    //选择考场
    ActiveUserExamRoomList(item,index){
        this.setState({
            UserExamRoom:item,
        });
    };

    //点击选择时间的下拉按钮
    ActiveUserExamTime(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            UserExamTimeToggleOn: !prevState.UserExamTimeToggleOn,
            UserExamTimeDisplay:prevState.UserExamTimeToggleOn ? 'block' : 'none',
            UserExamRoomToggleOn: true,
            UserExamRoomDisplay: 'none',
            UserExamIdtToggleOn: true,
            UserExamIdtDisplay: 'none',
            UserExamItemToggleOn: true,
            UserExamItemDisplay: 'none',
        }));
    };
    //选择时间
    ActiveUserExamTimeList(item,index){
        this.setState({
            UserExamTime:item,
        });
    };


    //点击选择身份的下拉按钮
    ActiveUserExamIdt(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            UserExamIdtToggleOn: !prevState.UserExamIdtToggleOn,
            UserExamIdtDisplay:prevState.UserExamIdtToggleOn ? 'block' : 'none',
            UserExamRoomToggleOn: true,
            UserExamRoomDisplay: 'none',
            UserExamTimeToggleOn: true,
            UserExamTimeDisplay: 'none',
            UserExamItemToggleOn: true,
            UserExamItemDisplay: 'none',
        }));
    };
    //选择身份
    ActiveUserExamIdtList(item,index){
        if(item === '学员'){
            this.setState({
                UserExamIdt:item,
                UserExamItem:'',
                UserExamItemArr:ExamStudentArr,
            });
        }else if(item === '教员'){
            this.setState({
                UserExamIdt:item,
                UserExamItem:'',
                UserExamItemArr:ExamTeacherArr,
            });
        }
    };

    //点击选择项目的下拉按钮
    ActiveUserExamItem(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            UserExamItemToggleOn: !prevState.UserExamItemToggleOn,
            UserExamItemDisplay:prevState.UserExamItemToggleOn ? 'block' : 'none',
            UserExamRoomToggleOn: true,
            UserExamRoomDisplay: 'none',
            UserExamTimeToggleOn: true,
            UserExamTimeDisplay: 'none',
            UserExamIdtToggleOn: true,
            UserExamIdtDisplay: 'none',
        }));
    };
    //选择项目
    ActiveUserExamItemList(item,index){
        this.setState({
            UserExamItem:item.SubjectName,
        });
    };


    //确认是否保存并返回弹窗
    ChangeEditorSaveBtn(){
        this.setState({
            PopUp:'确认是否保存并返回弹窗',
            MessageTip:'是否保存变更信息并返回？',
        });
    };



    render() {
        return (
            <div className={'ExamMainBoxes'}>
                <div className={'ExamMainBoxesTit'}>变更申请信息</div>
                <div className={'ChangeEditorBox clearfix'}>
                    <div className={'ChangeEditorMainBox'}>
                        <div className={'ChangeEditorListBox clearfix'} style={{marginBottom:26,}}>
                            <div className={'ChangeEditorListTit'}>*考场</div>
                            <div className={'DropDownMenuBox clearfix'} style={{margin:0}}>
                                <div className={'DropDownMenuWord'}>{this.state.UserExamRoom}</div>
                                <div className={'DropDownMenuBtn'} onClick={this.ActiveUserExamRoom.bind(this)}>
                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'10px'} Right={'15px'}/>
                                    <div className={'DropDownMenuListBox'} style={{display:this.state.UserExamRoomDisplay}}>
                                        {
                                            this.state.UserExamRoomArr.map((item,index) =>
                                                <div className={'DropDownMenuList'} key={index} onClick={() => this.ActiveUserExamRoomList(item,index)}>{item}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'ChangeEditorListBox clearfix'} style={{marginBottom:26,}}>
                            <div className={'ChangeEditorListTit'}>*时间</div>
                            <div className={'DropDownMenuBox clearfix'} style={{margin:0}}>
                                <div className={'DropDownMenuWord'}>{this.state.UserExamTime}</div>
                                <div className={'DropDownMenuBtn'} onClick={this.ActiveUserExamTime.bind(this)}>
                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'10px'} Right={'15px'}/>
                                    <div className={'DropDownMenuListBox'} style={{display:this.state.UserExamTimeDisplay}}>
                                        {
                                            this.state.UserExamTimeArr.map((item,index) =>
                                                <div className={'DropDownMenuList'} key={index} onClick={() => this.ActiveUserExamTimeList(item,index)}>{item}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'ChangeEditorListBox clearfix'} style={{marginBottom:26,}}>
                            <div className={'ChangeEditorListTit'}>*身份</div>
                            <div className={'DropDownMenuBox clearfix'} style={{margin:0}}>
                                <div className={'DropDownMenuWord'}>{this.state.UserExamIdt}</div>
                                <div className={'DropDownMenuBtn'} onClick={this.ActiveUserExamIdt.bind(this)}>
                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'10px'} Right={'15px'}/>
                                    <div className={'DropDownMenuListBox'} style={{display:this.state.UserExamIdtDisplay}}>
                                        {
                                            this.state.UserExamIdtArr.map((item,index) =>
                                                <div className={'DropDownMenuList'} key={index} onClick={() => this.ActiveUserExamIdtList(item,index)}>{item}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'ChangeEditorListBox clearfix'}>
                            <div className={'ChangeEditorListTit'}>*项目</div>
                            <div className={'DropDownMenuBox clearfix'} style={{margin:0}}>
                                <div className={'DropDownMenuWord'}>{this.state.UserExamItem}</div>
                                <div className={'DropDownMenuBtn'} onClick={this.ActiveUserExamItem.bind(this)}>
                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'10px'} Right={'15px'}/>
                                    <div className={'DropDownMenuListBox'} style={{display:this.state.UserExamItemDisplay}}>
                                        {
                                            this.state.UserExamItemArr.map((item,index) =>
                                                <div className={'DropDownMenuList'} key={index} onClick={() => this.ActiveUserExamItemList(item,index)}>{item.SubjectName}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.UserIdentity === '学员' && this.state.UserExamIdt === '学员' &&
                        <BoundCoach
                            IdNum={this.state.UserIdNumber}
                        />
                    }
                </div>
                <button className={'ChangeEditorSaveBtn'} onClick={this.ChangeEditorSaveBtn.bind(this)}>保存并返回</button>
                {
                    this.state.PopUp === '确认是否保存并返回弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackClick.bind(this)}
                        type={'成功'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ChangeEditor;
