import React, { Component } from 'react';
//import Header from "../handf/Header";
//import Footer from "../handf/Footer";
import './game.css';
import {get} from "../../ajax/tools";
import Header from "../handf/Header";
//import {post,} from "../../ajax/tools";


class GYInfo extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :window.innerHeight,
            GYTitle:'',
            GYTime:'',
            GYInfo:'',
        };
    }
    componentDidMount=function() {
        let search = this.props.location.search;
        let msg = new URLSearchParams(search.slice(1))
        let GYInfoId = msg.get('id');
        get({url:"match/match_review_detail_info/"+GYInfoId}).then(res => {
            if(res.errno === '200'){
                let data = res.data[0];
                this.setState({
                    GYTitle:data.title,
                    GYTime:data.ctime,
                    GYInfo:data.content,
                });
            }else {
                alert('数据错误，请联系我们！');
                window.close();
            }
        });
    };







    render() {
        return (
                <div className={'MainBox'}>
                    <Header/>
                    <div className={'MainContent scrollBarTwo'} style={{height:window.innerHeight-85 > 569 ? window.innerHeight-85 : 569,backgroundColor:'#FFFFFF',width:1000,}}>
                        <div className={'GYInfoTitle'}>{this.state.GYTitle}</div>
                        <div className={'GYInfoTimeTitle'}>{this.state.GYTime}</div>
                        <div className={'GYInfoContent'}>
                            <div dangerouslySetInnerHTML = {{__html: this.state.GYInfo}}/>
                        </div>
                    </div>
                </div>
        );
    }
}


export default GYInfo;
