import React, { Component } from 'react';
import axios from 'axios';
import AuthHeader from "../handf/AuthHeader";
import PopUp from "../round/PopUp";
import md5 from 'md5'
import {API_URL} from "../../ajax/config";
import './auth.css';
axios.defaults.baseURL=API_URL;


class Login extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : window.innerHeight-164,
            LoginAccount:'',
            LoginPassword:'',
            LoginVerCode:'',
            transform:{},
            transformCurrent:1,
            hash: Math.random().toString(36).substr(2),
        };
    }
    componentDidMount=function() {
        sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        if (contentHeight < winHeight) {
            this.setState({
                winHeight : winHeight-164,
            });
        }else{
            this.setState({
                winHeight : contentHeight-164,
            });
        }
    };

    //登录用户名
    LoginAccountChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            LoginAccount: event.target.value,
        });
    };
    //登录密码
    LoginPasswordChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            LoginPassword: event.target.value,
        });
    };
    //登录验证码
    LoginVerCodeChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            LoginVerCode: event.target.value,
        });
    };
    //点击刷新验证码
    VCRefreshClick(){
        let transformCurrent =this.state.transformCurrent;
        let transformHove ={transform:'rotate('+(transformCurrent*360)+'deg)',};
        this.setState({
            transform:transformHove,
            transformCurrent:transformCurrent+1,
            hash: Math.random().toString(36).substr(2),
        });
    };

    //忘记密码跳转页面
    ForgetBtnClick(){
        const {history} = this.props;
        history.push('/forget');
    }
    //没有账户去注册跳转页面
    ToRegisterClick(){
        const {history} = this.props;
        history.push('/register');
    }
    //点击用户登录
    LoginButtonClick= (event) => {
        event.preventDefault(); //阻止表单默认提交
        const LoginPassword = md5(this.state.LoginPassword);
        if(this.state.LoginPassword!==''&& this.state.LoginAccount!==''){
            axios.post('user/change_password',
                {
                    username: this.state.LoginAccount,
                    password: LoginPassword,
                },
            ).then((res) => {
                if(res.data.errno === '4107'){
                    this.setState({
                        PopUp:'提示选项',
                        Message:res.data.errmsg,
                    });
                }else {
                    axios.post('passport/login',
                        {
                            username: this.state.LoginAccount,
                            password: LoginPassword,
                            image_code_client: this.state.LoginVerCode,
                            image_code_id: this.state.hash,
                        },
                    ).then((res) => {
                        if(res.data.errno === '200'){
                            //console.log(res);
                            let KeyToken = res.headers.accesstoken;
                            let headers = {};
                            headers['Access_Token'] = KeyToken
                            axios.get('user/basic_info',{headers:headers}).then((res) => {
                                if(res.data.errno === '200'){
                                    let User = res.data.data[0];
                                    let expiredTime =  new Date();
                                    let expired = Math.round((expiredTime.getTime())+1000*60*60*6);
                                    let tokenAuth = JSON.stringify(
                                        {
                                            KeyToken:KeyToken,
                                            time:expired,
                                            user_name:User.type === 0 ? User.real_name : User.type === 1 ? User.org_name : 'ssr',
                                        }
                                    );
                                    sessionStorage.setItem('tokenAuth',tokenAuth);
                                    sessionStorage.setItem('gameId','');
                                    let routesAddress = sessionStorage.getItem('routesAddress');
                                    if (!routesAddress){
                                        const {history} = this.props;
                                        history.push('/member');
                                    }else if(routesAddress === ''){
                                        const {history} = this.props;
                                        history.push('/member');
                                    }else {
                                        sessionStorage.setItem('routesAddress','');
                                        const {history} = this.props;
                                        history.push(routesAddress);
                                    }
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'错误提示同一弹窗',
                                MessageTip:res.data.errmsg,
                            });
                        }
                    });
                }
            });
        }else{
            this.setState({
                PopUp:'错误提示同一弹窗',
                MessageTip:'请输入用户名与密码！',
            });
        }
    };



    GoOn(){
        const LoginPassword = md5(this.state.LoginPassword);
        axios.post('passport/login',
            {
                username: this.state.LoginAccount,
                password: LoginPassword,
                image_code_client: this.state.LoginVerCode,
                image_code_id: this.state.hash,
            },
        ).then((res) => {
            if(res.data.errno === '200'){
                //console.log(res);
                let KeyToken = res.headers.accesstoken;
                let headers = {};
                headers['Access_Token'] = KeyToken
                axios.get('user/basic_info',{headers:headers}).then((res) => {
                    if(res.data.errno === '200'){
                        let User = res.data.data[0];
                        let expiredTime =  new Date();
                        let expired = Math.round((expiredTime.getTime())+1000*60*60*6);
                        let tokenAuth = JSON.stringify(
                            {
                                KeyToken:KeyToken,
                                time:expired,
                                user_name:User.type === 0 ? User.real_name : User.type === 1 ? User.org_name : 'ssr',
                            }
                        );
                        sessionStorage.setItem('tokenAuth',tokenAuth);
                        sessionStorage.setItem('gameId','');
                        let routesAddress = sessionStorage.getItem('routesAddress');
                        if (!routesAddress){
                            const {history} = this.props;
                            history.push('/member');
                        }else if(routesAddress === ''){
                            const {history} = this.props;
                            history.push('/member');
                        }else {
                            sessionStorage.setItem('routesAddress','');
                            const {history} = this.props;
                            history.push(routesAddress);
                        }
                    }
                });
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:res.data.errmsg,
                });
            }
        });
    };

    forget(){
        const {history} = this.props;
        history.push('/forget');
    };


    CrossOut(){
        this.setState({
            PopUp:'',
        });
    };



    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };





    render() {
        return (
                <div className={'AuthBox'}>
                    <AuthHeader/>
                    <div className={'AuthBg'} style={{height:this.state.winHeight,}}>
                        <div className={'AuthBgColor'} style={{height:this.state.winHeight,}}> </div>
                        <div className={'LoginAndForgetBox'}>
                            <div className={'LoginAndForgetLogo'}>
                                <div className={'FSLogo'}> </div>
                                <div className={'FSTitle'}>中国航协模拟飞行服务平台</div>
                            </div>
                            <div className={'LoginAndForgetContent'}>
                                <form onSubmit={this.LoginButtonClick}>
                                    <div className={'LoginAccount'}>
                                        <div className={'LoginAccountIco'}> </div>
                                        <input className={'LoginAccountInput'}
                                               type={'text'}
                                               name={'LoginAccount'}
                                               value={this.state.LoginAccount}
                                               onChange={this.LoginAccountChange.bind(this)}
                                               placeholder={'ID/用户名/个人用户身份证号'}
                                               autoComplete={'off'}
                                        />
                                    </div>
                                    <div className={'LoginPassword'}>
                                        <div className={'LoginPasswordIco'}> </div>
                                        <input className={'LoginPasswordInput'}
                                               style={{display:'none',}}
                                               type={'password'}
                                               name={'LoginPassword'}
                                               autoComplete={'new-password'}
                                        />
                                        <input className={'LoginPasswordInput'}
                                               type={'password'}
                                               name={'LoginPassword'}
                                               value={this.state.LoginPassword}
                                               onChange={this.LoginPasswordChange.bind(this)}
                                               placeholder={'密码'}
                                               autoComplete={'new-password'}
                                        />
                                    </div>
                                    <div className={'LoginVerCode'}>
                                        <input className={'LoginVerCodeInput'}
                                               type={'text'}
                                               name={'LoginVerCode'}
                                               value={this.state.LoginVerCode}
                                               onChange={this.LoginVerCodeChange.bind(this)}
                                               placeholder={'输入验证码'}
                                               autoComplete={'off'}
                                        />
                                        <div className={'LoginVCBox'}>
                                            <img src={API_URL+"/passport/image_code?imageCodeId="+this.state.hash} alt={''}/>
                                        </div>
                                        <div className={'VCRefresh'} style={this.state.transform} onClick={this.VCRefreshClick.bind(this)}> </div>
                                    </div>
                                    <button className={'LoginButton'} type={'submit'}>登录</button>
                                </form>
                                <div className={'ForgetBtn'} onClick={this.ForgetBtnClick.bind(this)}>忘记密码？</div>
                                <div className={'ToRegister'} style={{color:'#FF0000',}} onClick={this.ToRegisterClick.bind(this)}>还没有账户？去注册-{'>'}</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.PopUp === '提示选项' &&
                        <div className={'PopUpBoxBg'}>
                            <div className={'PopUpBox'}>
                                <div className={'PopUpTopImage TypeTip'}>
                                    <div className={'CrossBtn'} onClick={this.CrossOut.bind(this)}> </div>
                                </div>
                                <div className={'PopUpConBox'}>
                                    <div className={'PopUpContentBox'}>
                                        <div className={'ContentBox clearfix'}>
                                            <div className={'ContentWord'} style={{float:'unset',textIndent:'2em',fontSize:14,lineHeight:'30px',color:'#f39c12',}}>{this.state.Message}</div>
                                        </div>
                                    </div>
                                    <div className={'ButtonBox'}>
                                        <button className={'TwoBtnOk'} style={{backgroundColor:'#fff',color:'#F54746',letterSpacing:'0.1em',fontSize:15,}} onClick={this.forget.bind(this)}>去修改密码—></button>
                                        {/*<button className={'TwoBtnOut'} style={{backgroundColor:'#fff',color:'#bbb',fontSize:15,}} onClick={this.GoOn.bind(this)}>继续登录</button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '错误提示同一弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default Login;
