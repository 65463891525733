import React, { Component } from 'react';
import './inquire.css';
import {get} from "../../ajax/tools";
import ThreeLevelLinkage from "../round/ThreeLevelLinkage";
import Triangle from "../round/Triangle";
import PopUp from "../round/PopUp";


class InDepot extends Component {

    constructor(props){
        super(props);
        this.state = {
            DepotArr:[],//训练机构信息数组
            ThreeLevelLinkage:'none',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            InDepotArr:[],//训练中心所属机构
            InDepotName:'',
            InDepotToggleOn:true,
            InDepotDisplay:'none',
            loading: false,

            isThree:'q',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        if (nextProps.DepotDataArr.length !== 0){
            this.setState({
                DepotArr:nextProps.DepotDataArr,
            });
        }else {
            get({url:'query/train_base_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        DepotArr:res.data,
                    });
                }
            });
        }
    };

    componentDidMount=function() {
        let history = this.props;
        if (history.DepotDataArr.length !== 0){
            this.setState({
                DepotArr:history.DepotDataArr,
            });
        }else {
            get({url:'query/train_base_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        DepotArr:res.data,
                    });
                }
            });
        }
        get({url:'query/get_org_name'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    InDepotArr:res.data,
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllDataBtn(){
        get({url:'query/train_base_query'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    DepotArr:res.data,
                    isThree: this.state.isThree === 'q' ? 's' : 'q',
                });
            }
        });
    };

    AllClick = () => {
        this.setState({
            InDepotToggleOn: true,
            InDepotDisplay: 'none',
            ThreeLevelLinkage:'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //三级联动的逻辑处理
    ChildLinkClick=(event)=> {
        if (event.Type === '地址') {
            if (event.Title === '省') {
                get({url:'query/addr_train_base_query/'+event.ValueP}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            DepotArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            } else if (event.Title === '市') {
                get({url:'query/addr_train_base_query/'+event.ValueC}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            DepotArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            } else if (event.Title === '区') {
                get({url:'query/addr_train_base_query/'+event.ValueA}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            DepotArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }
        }
    };



    //训练中心所属机构的下拉菜单
    InDepotDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            InDepotToggleOn: !prevState.InDepotToggleOn,
            InDepotDisplay:prevState.InDepotToggleOn ? 'block' : 'none',
        }));
    };
    InDepotDownNav=(item,index)=>{
        get({url:'query/ID_train_base_query/'+item.user_id}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    DepotArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'查询无数据！',
                });
            }
        });
        this.setState({
            InDepotName:item.user_id,
            InDepotToggleOn: true,
            InDepotDisplay: 'none',
        });
    };


    //点击所属机构名称详情
    ActiveDepot=(item,index)=>{
        let ActiveDepot = {
            Inquire:'Depot',
            Id:item.id,
            DataArr:this.state.DepotArr,
        };
        this.props.ActiveSeeDetails(ActiveDepot);
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'InquirePackageBox'} style={{minHeight:window.innerHeight-430 > 450 ? window.innerHeight-430 :450,}}>
                    <div className={'InquireAllDataBtn clearfix'} onClick={this.AllDataBtn.bind(this)}>
                        <div className={'InquireAllDataIco'}> </div>
                        <div className={'InquireAllDataWord'}>全部信息</div>
                    </div>
                    <div className={'InquirePackageHeaderBox'}>
                        <div className={'InquirePackageHeaderList'} style={{width:390,}}>
                            <div className={'InquireHeaderSelect'}>
                                <div style={{height:26,}} onClick={this.InDepotDown.bind(this)}>
                                    <div className={'InquireSelectWord'}>所属团体</div>
                                    {
                                        this.state.InDepotToggleOn === true &&
                                        <Triangle Direction={'right'} Color={'#FFFFFF'}
                                                  Width={'9px'} Height={'6px'}
                                                  Top={'7px'} Right={'0'}
                                        />
                                    }
                                    {
                                        this.state.InDepotToggleOn === false &&
                                        <Triangle Direction={'down'} Color={'#FFFFFF'}
                                                  Width={'6px'} Height={'9px'}
                                                  Top={'10px'} Right={'9px'}
                                        />
                                    }
                                </div>
                                <div className={'EquipmentDownNavBox'} style={{display:this.state.InDepotDisplay,left:0,width:200,}}>
                                    {
                                        this.state.InDepotArr.map((item,index) =>
                                            <div className={'EquipmentDownNav'}
                                                 key={index}
                                                 onClick={() => this.InDepotDownNav(item,index)}
                                                 style={this.state.InDepotName === item.user_id ? {backgroundColor:'#8DCEFD',width:196,} : {width:196,}}>
                                                {item.org_name}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'InquirePackageHeaderList clearfix'} style={{width:560,}}>
                            <div style={{display:'table',margin:'0 auto',}}>
                                <span style={{float:'left',}}>所属地区</span>
                                <div className={'InAddressThreeBox'}>
                                    {
                                        this.state.isThree === 'q'&&
                                        <ThreeLevelLinkage
                                            ChildLinkClick={this.ChildLinkClick.bind(this)}
                                            type={'地址'}
                                            Width={314}
                                            Height={26}
                                            ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                        />
                                    }
                                    {
                                        this.state.isThree === 's'&&
                                        <ThreeLevelLinkage
                                            ChildLinkClick={this.ChildLinkClick.bind(this)}
                                            type={'地址'}
                                            Width={314}
                                            Height={26}
                                            ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading === true&&
                        <div className={'loadEffect'}>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </div>
                    }
                    {
                        this.state.loading === false&&
                        <div className={'InquirePackageContentBox'} style={{minHeight:window.innerHeight-272 > 460 ? window.innerHeight-272 :460,maxHeight:window.innerHeight-264 > 450 ? 'calc(100vh - 264px)' : '450px'}}>
                            {
                                this.state.DepotArr.map((item,index) =>
                                    <div className={'InquirePackageContentListBox'} key={index}>
                                        <div className={'InquirePackageContentList InquireListHover'} style={{width:390,}}>
                                            <span className={'ClickDetails'} onClick={() => this.ActiveDepot(item,index)}>{item.name}</span>
                                        </div>
                                        <div className={'InquirePackageContentList'} style={{width:560,}}>
                                            {
                                                item.province === item.city ?
                                                    item.city+item.county+item.address :
                                                    item.province+item.city+item.county+item.address
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default InDepot;
