import React, { Component } from 'react';
import './game.css';
import {get, post} from "../../ajax/tools";
import PopUp from "../round/PopUp";



class ProcessGame extends Component {

    constructor(props){
        super(props);
        this.state = {
            NetGameArr:[],
            NetGameArrLength:-1,
            NetGameErr:'',

            // NetGameState:'class',
            NetGameState:'Ico',

            MatchId:'',
            MatchName:'',
            MatchInfo: {},


            GroupId:'',//组别Id
            GroupArr:[],//组别列表
            GroupToggleOn: true,
            GroupDisplay:'none',

            ScoreArr:[],
            ScoreBrr:[],
            ScoreBrrLength:0,
            PageSize: 17,
            CurrentPage: 1,
            nowCurrentPage: 0,

            AskScoreArr:[],
            AskScoreBrr:[],
            AskScoreBrrLength:0,

            JoinInfoArr:[],
            ScheduleArr:[],
            ScheduleName:'',
            ScheduleId:'',
            ScheduleMsiId:'',
            GroupDataArr:[],
            ItemMaiId:'',
            ItemId:'',
            SubmitNum:'',
            RankType:'',
            GroupName:'',//组别名称
            ItemName:'',
            matchId:'',
            ScheduleToggleOn: true,
            ScheduleDisplay:'none',

            pageUp:'',

        };
    }
    componentDidMount=function() {
        get({url:'match/get_match_progress'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    NetGameArr:res.data,
                    NetGameArrLength:res.data.length,
                });
            }else {
                this.setState({
                    NetGameErr:'Err',
                    NetGameArrLength:0,
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
            ScheduleToggleOn: true,
            ScheduleDisplay:'none',
            GroupToggleOn: true,
            GroupDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };

    NetGameBtn=(item,index) =>{
        this.setState({
            MatchId:item.id,
            MatchName:item.match_name,
            MatchInfo:item,
        });
        post({
            url:'scoreStatistics/show_schedule',
            data:{
                match_num_id:item.id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                if (res.data.length !== 0){
                    let ScheduleDefault = res.data[0]
                    this.setState({
                        ScheduleArr:res.data,
                        ScheduleName:ScheduleDefault.name,
                        ScheduleId:ScheduleDefault.id,
                        ScheduleMsiId:ScheduleDefault.schedule_id,
                    });
                    post({
                        url:'scoreStatistics/get_check_record_num_all',
                        data:{
                            match_id:item.id,
                            schedule_id:ScheduleDefault.schedule_id,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            if (res.data.length !== 0){
                                this.setState({
                                    GroupDataArr:res.data,
                                    NetGameState:'class',
                                });
                            }
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };


    ScheduleDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            ScheduleToggleOn: !prevState.ScheduleToggleOn,
            ScheduleDisplay:prevState.ScheduleToggleOn ? 'block' : 'none',
        }));
    };
    ScheduleClick=(item,index)=> {
        let matchId = this.state.MatchId;
        post({
            url:'scoreStatistics/get_check_record_num_all',
            data:{
                match_id:matchId,
                schedule_id:item.schedule_id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                if (res.data.length !== 0){
                    this.setState({
                        GroupDataArr:res.data,
                        ScheduleName:item.name,
                        ScheduleId:item.id,
                        ScheduleMsiId:item.schedule_id,
                        NetGameState:'class',
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };
    // 赛程数据的刷新
    ScheduleBrushData(){
        let matchId = this.state.MatchId;
        let ScheduleMsiId = this.state.ScheduleMsiId;
        post({
            url:'scoreStatistics/get_check_record_num_all',
            data:{
                match_id:matchId,
                schedule_id:ScheduleMsiId,
            },
        }).then((res) => {
            if(res.errno === '200'){
                if (res.data.length !== 0){
                    this.setState({
                        GroupDataArr:res.data,
                        NetGameState:'class',
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };

    //项目的详情操作
    ItemDetails=(item,index,item2,index2)=>{
        let ScheduleMsiId = this.state.ScheduleMsiId;
        post({
            url:'scoreStatistics/show_check_record',
            data:{
                item_id:item2.item_id,
                tmai_id:item2.tmai_id,
                schedule_id:ScheduleMsiId,
                submit_num:item2.submit_num,
                rank_type:item2.rank_type,
            },
        }).then((res) => {
            if(res.errno === '200'){
                if (res.data.length === 0){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'未找到相关数据',
                    });
                }else {
                    const KeyStart = (this.state.CurrentPage-1)*this.state.PageSize;
                    const KeyEnd = this.state.CurrentPage*this.state.PageSize;
                    const SubjectList = [];
                    if(KeyEnd <= res.data.length){
                        for(let i=KeyStart; i<KeyEnd; i++){
                            const SubjectBList = res.data[i];
                            SubjectList.push(SubjectBList)
                        }
                    }else {
                        for(let i=KeyStart; i<res.data.length; i++){
                            const SubjectBList = res.data[i];
                            SubjectList.push(SubjectBList)
                        }
                    }
                    this.setState({
                        AskScoreArr:SubjectList,
                        AskScoreBrr:res.data,
                        AskScoreBrrLength:res.data.length,
                        NetGameState:'List',
                        ItemMaiId:item2.tmai_id,
                        ItemId:item2.item_id,
                        SubmitNum:item2.submit_num,
                        RankType:item2.rank_type,
                        GroupName:item.name,
                        ItemName:item2.item_name,
                    });
                }
            }else {
                this.setState({
                    JoinInfoArr:[],
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };










    BrushData(){
        let ItemMaiId = this.state.ItemMaiId;
        let ItemId = this.state.ItemId;
        let ScheduleMsiId = this.state.ScheduleMsiId;
        let SubmitNum = this.state.SubmitNum;
        let RankType = this.state.RankType;
        if (ItemMaiId !== ''){
            post({
                url:'scoreStatistics/show_check_record',
                data:{
                    item_id:ItemId,
                    tmai_id:ItemMaiId,
                    schedule_id:ScheduleMsiId,
                    submit_num:SubmitNum,
                    rank_type:RankType,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    const KeyStart = (this.state.CurrentPage-1)*this.state.PageSize;
                    const KeyEnd = this.state.CurrentPage*this.state.PageSize;
                    const SubjectList = [];
                    if(KeyEnd <= res.data.length){
                        for(let i=KeyStart; i<KeyEnd; i++){
                            const SubjectBList = res.data[i];
                            SubjectList.push(SubjectBList)
                        }
                    }else {
                        for(let i=KeyStart; i<res.data.length; i++){
                            const SubjectBList = res.data[i];
                            SubjectList.push(SubjectBList)
                        }
                    }
                    this.setState({
                        AskScoreArr:SubjectList,
                        AskScoreBrr:res.data,
                        AskScoreBrrLength:res.data.length,
                        nowCurrentPage: 0,
                        pageUp:'sb',
                    });
                    if (res.data.length === 0){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'未找到相关数据',
                            NetGameState:'class',
                        });
                    }else {

                    }
                }else {
                    this.setState({
                        NetGameState:'class',
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }
    };




    //返回选择赛事按钮
    GameInfoBackBtn=()=>{
        get({url:'match/get_match_progress'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    NetGameArr:res.data,
                    NetGameArrLength:res.data.length,
                    NetGameState:'Ico',
                    MatchId:'',
                    GroupName:'',//组别名称
                    GroupId:'',//组别Id
                    GroupArr:[],//组别列表
                    ScheduleToggleOn: true,
                    ScheduleDisplay:'none',
                    GroupToggleOn: true,
                    GroupDisplay: 'none',
                    CurrentPage: 1,
                    nowCurrentPage: 0,
                });
            }else {
                this.setState({
                    NetGameErr:'Err',
                    NetGameArrLength:0,
                });
            }
        });
    };
    InfoBackBtn=()=>{
        let matchId = this.state.MatchId;
        let ScheduleMsiId = this.state.ScheduleMsiId;
        post({
            url:'scoreStatistics/get_check_record_num_all',
            data:{
                match_id:matchId,
                schedule_id:ScheduleMsiId,
            },
        }).then((res) => {
            if(res.errno === '200'){
                if (res.data.length !== 0){
                    this.setState({
                        GroupDataArr:res.data,
                        NetGameState:'class',
                        ItemMaiId:'',
                        ItemId:'',
                        SubmitNum:'',
                        RankType:'',
                        GroupName:'',//组别名称
                        ItemName:'',
                        CurrentPage: 1,
                        nowCurrentPage: 0,
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };


    AskGetCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const SubjectList = [];
        if(KeyEnd <= this.state.AskScoreBrr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const SubjectBList = this.state.AskScoreBrr[i];
                SubjectList.push(SubjectBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.AskScoreBrr.length; i++){
                const SubjectBList = this.state.AskScoreBrr[i];
                SubjectList.push(SubjectBList)
            }
        }
        this.setState({
            nowCurrentPage:KeyStart,
            AskScoreArr:SubjectList,
        });
    }





    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
            <div>
                {
                    this.state.NetGameState === 'Ico' &&
                    <div className={'GameMainBox scrollBar'} style={{height:window.innerHeight-191,}}>
                        <div className={'InfoListPositionTitBox'}>
                            <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,marginTop:10,}}>
                                <div className={'InfoListPosition'}>选择赛事查看实时检录状态</div>
                            </div>
                        </div>
                        {
                            this.state.NetGameArrLength > 0 &&
                            <div className={'GameSignUpIcoBoxes clearfix'}>
                                {
                                    this.state.NetGameArr.map((item,index) => (
                                        <div className={'GameSignUpIcoBox'} key={index}>
                                            <div className={'GameSignUpIco'} onClick={() => this.NetGameBtn(item,index)}> </div>
                                            <div className={'GameSignUpIcoTitle'}>{item.match_name}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        {
                            this.state.NetGameArrLength === 0 &&
                            <div className={'NotGameTip'}>
                                <div className={'TrainDataMain'}>
                                    <div className={'GameYearNum'} style={{position:'unset',fontSize:62,}}>当前无可以展示检录状态的赛事！</div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.NetGameState === 'class' &&
                    <div className={'GameMainBox scrollBar'} style={{height:window.innerHeight-191,}}>
                        <div className={'GameInfoTitleBox clearfix'}>
                            <div className={'InfoListPosition'} style={{height:28,lineHeight:'28px',textAlign:'left',textIndent:'2em',}}>{this.state.MatchName}</div>
                        </div>
                        <div className={'NetGameListTitleBox'}>
                            <div className={'YunChengTimeBox GameWordPublic'} style={{width:'auto',lineHeight:'34px',}}>
                                *赛事检录状态一览：
                            </div>
                            {
                                this.state.ScheduleArr.length > 1&&
                                <div>
                                    <div className={'YunChengTimeBox GameWordPublic'} style={{width:120,lineHeight:'34px',margin:'0 4px',}}>
                                        *请选择赛程:
                                    </div>
                                    <div className={'YunChengItemBox'} style={{margin:'0 2px',}}>
                                        <div className={'YCItemName'} onClick={this.ScheduleDown.bind(this)}>{this.state.GroupName === '' ? '请选择赛程'  : this.state.GroupName}</div>
                                        <div className={'YCItemIco'} onClick={this.ScheduleDown.bind(this)}>
                                            <div className={'icon-arrow-down'}> </div>
                                        </div>
                                        <div className={'YCItemNavBox scrollBar'} style={{display:this.state.ScheduleDisplay,height:'auto',minHeight:'36px',maxHeight:'360px',}}>
                                            {
                                                this.state.ScheduleArr.map((item,index) =>
                                                    <div className={'YCItemNav'}
                                                         key={index}
                                                         onClick={() => this.ScheduleClick(item,index)}
                                                         style={this.state.ScheduleName === item.name ? {backgroundColor:'#6DC1FF'} : {}}>
                                                        {item.name}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={'TopOperateBtn clearfix'} style={{margin:'6px 20px 0 0',}}>
                                <div className={'ZXXIco brushIco'}>
                                    <div className={'sanjiao'}> </div>
                                </div>
                                <div className={'TopOperateBtnWord'} onClick={this.ScheduleBrushData.bind(this)}>刷新数据</div>
                            </div>
                            <div className={'TopOperateBtn clearfix'} style={{margin:'6px 10px 0 0',backgroundColor:'#DB6464',}} onClick={this.GameInfoBackBtn.bind(this)}>
                                <div className={'BackIcoBox'}>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-35deg)',top:'4px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-145deg)',top:'14px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{top:'9px',left:'1px',width:20,}}> </div>
                                </div>
                                <div className={'TopOperateBtnWord'}>返回</div>
                            </div>
                        </div>
                        <div className={'GameSignUpIcoBoxes clearfix'} style={{width:956,}}>
                            {
                                this.state.GroupDataArr.map((item,index) => (
                                    <div key={index} style={{marginBottom:40,}}>
                                        <div className={'GroupTitle'}>{item.name}</div>
                                        <div className={'NetGameTitleBox GameWordPublic clearfix'} style={{fontSize:13,width:954,borderTop:'1px solid #030303',borderLeft:'1px solid #030303',borderRight:'1px solid #030303',}}>
                                            <div className={'NetGameTitleList'} style={{width:230,borderRight:'1px solid #030303',}}>比赛科目</div>
                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>参赛人数</div>
                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>检录人数</div>
                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>弃赛人数</div>
                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>未检录人数</div>
                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>已完成人数</div>
                                            <div className={'NetGameTitleList'} style={{width:110,}}>操作</div>
                                        </div>
                                        {
                                            item.num_per && item.num_per.length !== 0&&
                                            <div>
                                                {
                                                    item.num_per.map((item2,index2) => (
                                                        <div className={'NetGameTitleBox GameWordPublic clearfix'} style={{fontSize:13,width:954,borderLeft:'1px solid #030303',borderRight:'1px solid #030303',}} key={index2}>
                                                            <div className={'NetGameTitleList'} style={{width:230,borderRight:'1px solid #030303',}}>{item2.item_name}</div>
                                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>{item2.all_count}</div>
                                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>{item2.checks}</div>
                                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>{item2.retire_race}</div>
                                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>{item2.nocheck}</div>
                                                            <div className={'NetGameTitleList'} style={{width:120,borderRight:'1px solid #030303',}}>{item2.finish_num}</div>
                                                            <div className={'NetGameTitleList'} style={{width:110,}}>
                                                                <div style={{display:'table',margin:'0 auto',}}>
                                                                    <div className={'ClickDetails'} style={{float:'left',margin:'0 10px',}} onClick={() => this.ItemDetails(item,index,item2,index2)}>
                                                                        详情操作
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    this.state.NetGameState === 'List' &&
                    <div className={'GameMainBox'}>
                        <div className={'GameInfoTitleBox clearfix'}>
                            <div className={'GameInfoTitleBox clearfix'}>
                                <div className={'InfoListPosition'} style={{height:28,lineHeight:'28px',textAlign:'left',textIndent:'2em',}}>{this.state.MatchName}</div>
                            </div>
                        </div>
                        <div className={'NetGameListTitleBox'}>
                            <div className={'YunChengTimeBox GameWordPublic'} style={{width:'auto',lineHeight:'34px',}}>
                                *赛事进程情况一览：
                            </div>
                            <div className={'TopOperateBtn clearfix'} style={{margin:'6px 20px 0 0',}}>
                                <div className={'ZXXIco brushIco'}>
                                    <div className={'sanjiao'}> </div>
                                </div>
                                <div className={'TopOperateBtnWord'} onClick={this.BrushData.bind(this)}>刷新数据</div>
                            </div>
                            <div className={'TopOperateBtn clearfix'} style={{margin:'6px 10px 0 0',backgroundColor:'#DB6464',}} onClick={this.InfoBackBtn.bind(this)}>
                                <div className={'BackIcoBox'}>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-35deg)',top:'4px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{transform:'rotate(-145deg)',top:'14px',}}> </div>
                                    <div className={'BackIcoBorder'} style={{top:'9px',left:'1px',width:20,}}> </div>
                                </div>
                                <div className={'TopOperateBtnWord'}>返回</div>
                            </div>
                        </div>
                        <div className={'NetGameListBox'} style={{height:window.innerHeight-294 > 360 ? window.innerHeight-294 :360,}}>
                            <div className={'NetGameTitleBox GameWordPublic clearfix'}>
                                <div className={'NetGameTitleList'} style={{width:60,}}>序号</div>
                                <div className={'NetGameTitleList'} style={{width:235,}}>姓名</div>
                                <div className={'NetGameTitleList'} style={{width:115,}}>性别</div>
                                <div className={'NetGameTitleList'} style={{width:335,}}>团队</div>
                                <div className={'NetGameTitleList'} style={{width:210,}}>状态</div>
                            </div>
                            <div className={'NetGameListMainBox scrollBar'} style={{height:window.innerHeight-327 > 320 ? window.innerHeight-327 :320,}}>
                                {
                                    this.state.AskScoreBrr.map((item,index) =>
                                        <div className={'NetGameTitleBox GameWordPublic clearfix'} style={{border:0,}} key={index}>
                                            <div className={'NetGameTitleList'} style={{width:60,height:32,}}>{this.state.nowCurrentPage+index+1}</div>
                                            <div className={'NetGameTitleList'} style={{width:235,height:32,}}>{item.real_name}</div>
                                            <div className={'NetGameTitleList'} style={{width:115,height:32,}}>{item.gender}</div>
                                            <div className={'NetGameTitleList'} style={{width:335,height:32,}}>{item.school_name == null ? '/' : item.school_name+item.team_name+'队'}</div>
                                            <div className={'NetGameTitleList'} style={{width:210,height:32,}}>
                                                {
                                                    item.sche_check === 0 && item.isdel === 0 && item.wt_check === 0 &&
                                                    <span style={{color:'#64DB76',}}>未检录</span>
                                                }
                                                {
                                                    item.sche_check === 1 && item.isdel === 0 && item.wt_check === 1 &&
                                                    <span style={{color:'#DB6464',}}>已检录</span>
                                                }
                                                {
                                                    item.ismatch === 1 &&
                                                    <span style={{color:'#6DC1FF',}}>竞赛中</span>
                                                }
                                                {
                                                    item.isdel === 1 &&
                                                    <span style={{color:'#ff0000',}}>弃赛</span>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {/*{*/}
                            {/*    this.state.AskScoreBrrLength !== 0 && this.state.pageUp === '' && window.innerHeight-432 < 747 &&*/}
                            {/*    <Pagination*/}
                            {/*        TotalSize={this.state.AskScoreBrrLength}*/}
                            {/*        PageSize={this.state.PageSize}*/}
                            {/*        pageCallbackFn={this.AskGetCurrentPage.bind(this)}*/}
                            {/*    />*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    this.state.AskScoreBrrLength !== 0 && this.state.pageUp !== '' && window.innerHeight-432 < 747 &&*/}
                            {/*    <Pagination*/}
                            {/*        TotalSize={this.state.AskScoreBrrLength}*/}
                            {/*        PageSize={this.state.PageSize}*/}
                            {/*        pageCallbackFn={this.AskGetCurrentPage.bind(this)}*/}
                            {/*    />*/}
                            {/*}*/}
                        </div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ProcessGame;
