import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import PopUp from "../round/PopUp";
import GameYear from "./GameYear";
import GameSignUpIco from "./GameSignUpIco";
import GamePersonal from "./GamePersonal";
import GameGroup from "./GameGroup";
import Activity from "./Activity";
import NetGame from "./NetGame";
import ProcessGame from "./ProcessGame";
import './game.css';
import {get, post} from "../../ajax/tools";
import SeTime from "../round/SeTime";


class Game extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : '',
            NowNavListZhName:'赛事报名',
            NowNavListEnName:'Sign up',
            GameNavArr:[
                {
                    ZhName:'赛事报名',
                    EnName:'Sign up',
                },
                {
                    ZhName:'赛事年鉴',
                    EnName:'year',
                },
                // {
                //     ZhName:'赛事专题',
                //     EnName:'review',
                // },
                {
                    ZhName:'活动报名',
                    EnName:'active',
                },
                {
                    ZhName:'成绩排行',
                    EnName:'net game',
                },
                {
                    ZhName:'检录状态',
                    EnName:'Process',
                },
                {
                    ZhName:'青少赛专题',
                    EnName:'Young game',
                }
            ],

            GameStep:'',
            GameId:'',
            GameName:'',
            UserType:null,

            ActiveId:'',
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }


        let search = this.props.location.search;
        let msg = new URLSearchParams(search.slice(1))
        let ztid = msg.get('ztid');
        let spec = msg.get('spec');
        let name = msg.get('name');
        if (ztid && spec){
            let JumpYearInfo = {
                id:ztid,
                match_name:name,
                spec:spec,
                type:'review',
            };
            sessionStorage.setItem('Jump',JSON.stringify(JumpYearInfo));
            this.setState({
                NowNavListZhName:'赛事年鉴',
                NowNavListEnName:'year',
            });
        }else {
            let Jump = JSON.parse(sessionStorage.getItem('Jump'));
            if (Jump && Jump.type && Jump.type === 'review'){
                this.setState({
                    NowNavListZhName:'赛事年鉴',
                    NowNavListEnName:'year',
                });
            }
        }


        let gameId = sessionStorage.getItem('gameId');
        let activeId = sessionStorage.getItem('activeId');


        if (activeId){
            this.setState({
                NowNavListZhName:'活动报名',
                NowNavListEnName:'active',
                ActiveId:activeId,
            });
            sessionStorage.removeItem('activeId');
        }


        if (!gameId){
            this.setState({
                GameStep:'list'
            });
        } else {
            get({url:'user/basic_info'}).then((res) => {
                if(res.errno === '200'){
                    let User = res.data[0];
                    if(User.type === 0){
                        post({
                            url:'match/query_match_enroll_per ',
                            data:{
                                match_id:gameId,
                            },
                        }).then((res) => {
                            if(res.errno === '200' && res.data[0].isexamine === 2){
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'审核未通过，不具备参赛条件！',
                                    GameStep:'list'
                                });
                            }else {
                                this.setState({
                                    UserType:0,
                                    GameId:gameId,
                                    GameStep:'info'
                                });
                            }
                        });
                    }else if (User.type === 1) {
                        get({url:'match/query_school_enroll_info/'+gameId}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    UserType:1,
                                    GameId:gameId,
                                    GameStep:'info'
                                });
                            }else if (res.errno === '4002'){
                                get({url:'match/team_enroll/'+gameId}).then((res) => {
                                    if(res.errno === '200'){
                                        this.setState({
                                            UserType:1,
                                            GameId:gameId,
                                            GameStep:'info',
                                        });
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:res.errmsg+'！',
                                            GameStep:'list'
                                        });
                                    }
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                    GameStep:'list'
                                });
                            }
                        });
                    }
                }else {
                    sessionStorage.setItem('routesAddress','/game');
                    const {history} = this.props;
                    history.push('/login');
                }
            });
            sessionStorage.removeItem('gameId');
        }
    };


    //点击左侧菜单
    AccountListClick=(item,index) =>{
        if (item.ZhName === '青少赛专题'){
            // window.open('http://yg.asfcyy.com');
            window.location.href = 'http://yg.asfcyy.com';
        }else {
            this.setState({
                NowNavListZhName:item.ZhName,
                NowNavListEnName:item.EnName,
                GameStep:'list',
                ActiveId:'',
            });
            sessionStorage.setItem('gameId','');
            sessionStorage.setItem('activeId','');
        }
    };



    GameYearBack=(event)=>{

    };

    ActivityBack=(event)=>{

    };




    GameSignUpIco=(event)=>{
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 0){
                    if (event.GameType === 1){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'本赛事不接受个人报名  请认真阅读本赛事通知及规则。',
                        });
                    }else {
                        get({url:'reuse/get_personal_info/'+User.yy_num}).then((res) => {
                            if(res.errno === '200'){
                                if (event.GameId === 148){
                                    this.setState({
                                        PopUp:'148',
                                        SecondType:'148',
                                        GameId:event.GameId,
                                    });
                                }else {
                                    post({
                                        url:'match/query_match_enroll_per',
                                        data:{
                                            match_id:event.GameId,
                                        },
                                    }).then((res) => {
                                        if(res.errno === '200' && res.data[0].isexamine === 2){
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:'审核未通过，不具备参赛条件！',
                                            });
                                        }else {
                                            this.setState({
                                                UserType:0,
                                                GameId:event.GameId,
                                                GameStep:'info'
                                            });
                                        }
                                    });
                                }
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                });
                            }
                        });
                    }
                }else if (User.type === 1) {
                    if (event.GameType === 0){
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'本赛事不接受团体报名  请认真阅读本赛事通知及规则。',
                        });
                    }else {
                        get({url:'match/query_school_enroll_info/'+event.GameId}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    UserType:1,
                                    GameId:event.GameId,
                                    GameStep:'info',
                                });
                            }else if (res.errno === '4002'){
                                get({url:'match/team_enroll/'+event.GameId}).then((res) => {
                                    if(res.errno === '200'){
                                        this.setState({
                                            UserType:1,
                                            GameId:event.GameId,
                                            GameStep:'info',
                                        });
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:res.errmsg+'！',
                                        });
                                    }
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                });
                            }
                        });
                    }
                }
            }else {
                sessionStorage.setItem('routesAddress','/game');
                const {history} = this.props;
                history.push('/login');
            }
        });
    };



    GamePersonal=(event)=>{
        this.setState({
            GameStep:'list'
        });
    };


    GameGroup=(event)=>{
        this.setState({
            GameStep:'list'
        });
    };






    SeTimeChange=(event)=>{
        if(event === 'Stop'){
            this.setState({
                SecondType:'',
            });
        }
    };
    CrossOut(){
        clearInterval();
        this.setState({
            PopUp:'',
            SecondType:'',
        });
    };

    OkBtn(){
        let GameId = this.state.GameId;
        post({
            url:'match/query_match_enroll_per',
            data:{
                match_id:GameId,
            },
        }).then((res) => {
            if(res.errno === '200' && res.data[0].isexamine === 2){
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'审核未通过，不具备参赛条件！',
                });
            }else {
                this.setState({
                    PopUp:'',
                    UserType:0,
                    GameStep:'info'
                });
            }
        });
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>{this.state.NowNavListZhName}</div>
                                <div className={'MainConTitEN'}>Events&nbsp;&nbsp;system</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}</div>
                        </div>
                        <div className={'ContentMainBox clearfix'} style={{height:window.innerHeight-191,}}>
                            <div className={'MainLeftNavBox scrollBar'} style={{height:window.innerHeight-191 > 460 ? window.innerHeight-191 :460,}}>
                                <ul className={'MainLeftNavListBox'}>
                                    {
                                        this.state.GameNavArr.map((item,index) =>
                                            <li className={item.ZhName === this.state.NowNavListZhName ? 'MainLeftNavActiveList' : 'MainLeftNavList'}
                                                key={index}
                                                onClick={() => this.AccountListClick(item,index)}
                                            >
                                                {item.ZhName}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className={'MainThisNavBox scrollBar'} style={{height:window.innerHeight-191,}}>
                                {
                                    this.state.NowNavListZhName === '赛事报名'&& this.state.GameStep === 'list'&&
                                    <GameSignUpIco
                                        GameSignUpIco={this.GameSignUpIco.bind(this)}
                                    />
                                }
                                {
                                    this.state.NowNavListZhName === '赛事报名'&& this.state.GameStep === 'info'&& this.state.UserType === 0 &&
                                    <GamePersonal
                                        MatchId={this.state.GameId}
                                        GamePersonal={this.GamePersonal.bind(this)}
                                    />
                                }
                                {
                                    this.state.NowNavListZhName === '赛事报名'&& this.state.GameStep === 'info'&& this.state.UserType === 1 &&
                                    <GameGroup
                                        MatchId={this.state.GameId}
                                        GameGroup={this.GameGroup.bind(this)}
                                    />
                                }
                                {
                                    this.state.NowNavListZhName === '赛事年鉴'&&
                                    <GameYear
                                        GameYearBack={this.GameYearBack.bind(this)}
                                    />
                                }
                                {
                                    this.state.NowNavListZhName === '活动报名'&&
                                    <Activity
                                        MatchId={this.state.ActiveId}
                                        ActivityBack={this.ActivityBack.bind(this)}
                                    />
                                }
                                {
                                    this.state.NowNavListZhName === '成绩排行'&&
                                    <NetGame />
                                }
                                {
                                    this.state.NowNavListZhName === '检录状态'&&
                                    <ProcessGame />
                                }
                            </div>
                        </div>
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '148' &&
                        <div className={'PopUpBoxBg'} style={{minHeight:200,}}>
                            <div className={'AddItemBox'} style={{width:450,height:500,}}>
                                <div className={'AddItemBigTitle'}>
                                    报名须知：
                                    <div className={'AddItemBackIco'} onClick={this.CrossOut.bind(this)}> </div>
                                </div>
                                <div className={'AddInfoMainBox'} style={{width:450,height:'auto',}}>
                                    <div className={'PopUpTipsWord1'} style={{height:'auto',minHeight:'32px',}}>
                                        如果您所在省没有举办选拔赛，所在地市无少年宫（科技馆）、中国航协模拟飞行授权训练中心组织参赛的情况下。请您仔细阅读“体育总局航管中心关于举办第十五届全国青少年模拟飞行锦标赛总决赛有关事项的通知”，按要求报名并提交资料进行参赛资质审核。报名提交后请随时关注本网站系统消息，查看报名是否成功。
                                    </div>
                                    <div className={'PopUpTipsWord1'} style={{height:'auto',minHeight:'32px',textIndent:'0',}}>
                                        备注：1、请自行咨询所在地少年宫（科技馆）有无组队。
                                    </div>
                                    <div className={'PopUpTipsWord1'} style={{height:'auto',minHeight:'32px',textIndent:'3em',}}>
                                        2、各地方中国航协模拟飞行授权训练中心相关信息
                                    </div>
                                    <div className={'PopUpTipsWord1'} style={{height:'auto',minHeight:'32px',}}>
                                        请在模拟飞行服务平台 — 信息查询板块查询。
                                    </div>
                                    <div className={'PopUpTipsWord1'} style={{height:'auto',minHeight:'32px',}}>
                                        人工审核后给予反馈系统信息.
                                    </div>
                                    {
                                        this.state.SecondType === '148' &&
                                        <div className={'PopUpBtn'} style={{width:310,backgroundColor:'#d6d6d6',}}>我已阅读须知并确认报名
                                            <SeTime
                                                SeTimeChange={this.SeTimeChange.bind(this)}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.SecondType === '' &&
                                        <div className={'PopUpBtn'} style={{width:260,}} onClick={this.OkBtn.bind(this)}>我已阅读须知并确认报名</div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Game;
