import React, { Component } from 'react';
import './member.css';
import '../round/round.css';
import PopUp from "../round/PopUp";
import Pagination2 from "../round/Pagination2";
import {get,post} from "../../ajax/tools";
class GrYunChengPer extends Component {

    constructor(props){
        super(props);
        this.state = {
            PageSize1:'',
            CurrentPage1: 1,

            GrServiceTopArr:[
                {
                    id:1,
                    nav:'个人云程',
                    router:'/member_peyc_service',
                    navNext:'',
                },
                {
                    id:3,
                    nav:'设备仓库',
                    router:'/member_service',
                    navNext:'',
                },
                {
                    id:4,
                    nav:'周边产品',
                    router:'/member_service',
                    navNext:'',
                },
            ],
            MemberTopNav:'个人云程',

            GrServicePerNavArr:[
                {
                    id:3,
                    nav:'销售分发',
                },
                {
                    id:2,
                    nav:'采购订单',
                },
                {
                    id:1,
                    nav:'查询订单',
                },
            ],
            GrServicePerNav:'查询订单',
            isDealer:'',
            GrServicePerType:'订单',


            OrderArr:[],
            OrderBrr:[],
            OrderFrr:[],

            PeYYNumInput:'',
            OrderFrrPe:'请选择分发来源订单',
            OrderFrrPeId:'',
            GrNumInput:'',
            GrYYNumInput:'',
            OrderFrrGr:'请选择分发来源订单',
            OrderFrrGrId:'',

            PeToggleOn: true,
            PeDisplay: 'none',
            grToggleOn: true,
            grDisplay: 'none',


            GroupInfo:{},
            UserInfo:{},


            PageSize2:'',
            CurrentPage2: 1,
            OrderOneArr:[],
            OrderOneBrr:[],
            OrderCodeSearch:'',
            OrderCodeInfo:{},
            OrderCodeInfoType:'',

            AllNum:'',
            FFNum:'',
            JHNum:'',
            SYNum:'',


            OrderCodeArr:[],

            YunChengState:'',
        };
    }
    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if (User.isdealer === 0){
                    this.setState({
                        isDealer:'普通用户',
                    });
                }else if (User.isdealer === 1){
                    this.setState({
                        isDealer:'分销商',
                    });
                }
                if (User.purchase_quantity !== 0){
                    this.setState({
                        YunChengState:'Key',
                        AllNum:User.purchase_quantity,
                        SYNum:User.residue_num,
                        FFNum:User.purchase_quantity - User.residue_num,
                    });
                }else {
                    this.setState({
                        YunChengState:'NotKey',
                        AllNum:'',
                        FFNum:'',
                        JHNum:'',
                        SYNum:'',
                    });
                }
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });
        //代理商
        // get({url:"agency/agent_show_own_order_num"}).then(res => {
        //     if(res.errno === '200'){
        //         let a = res.data[0];
        //         //分销商
        //         get({url:"agency/show_own_order_num"}).then(res => {
        //             if(res.errno === '200'){
        //                 let b = res.data[0];
        //                 this.setState({
        //                     AllNum:a.all_purchase_quantity+b.all_purchase_quantity,
        //                     FFNum:a.use_num+b.use_num,
        //                     JHNum:'',
        //                     SYNum:a.all_residue_num+b.all_residue_num,
        //                     YunChengState:'Key',
        //                 });
        //             }else {
        //                 this.setState({
        //                     AllNum:a.all_purchase_quantity,
        //                     FFNum:a.use_num,
        //                     JHNum:'',
        //                     SYNum:a.all_residue_num,
        //                     YunChengState:'Key',
        //                 });
        //             }
        //         });
        //     }else {
        //         //分销商
        //         get({url:"agency/show_own_order_num"}).then(res => {
        //             if(res.errno === '200'){
        //                 let b = res.data[0];
        //                 this.setState({
        //                     AllNum:b.all_purchase_quantity,
        //                     FFNum:b.use_num,
        //                     JHNum:'',
        //                     SYNum:b.all_residue_num,
        //                     YunChengState:'Key',
        //                 });
        //             }else {
        //                 this.setState({
        //                     AllNum:'',
        //                     FFNum:'',
        //                     JHNum:'',
        //                     SYNum:'',
        //                     YunChengState:'NotKey',
        //                 });
        //             }
        //         });
        //     }
        // });


        let H1 = window.innerHeight-622;
        let PageSize2 = Math.floor(H1/30)
        //分销展示代理
        get({url:"agency/show_agent_order"}).then(res => {
            if(res.errno === '200'){
                let a = res.data;
                //分销展示直销
                get({url:"agency/show_direct_sales_order"}).then(res => {
                    if(res.errno === '200'){
                        let b = res.data;
                        let dsb = a.concat(b);
                        let sb = [];
                        for(let i=0;i<dsb.length;i++){
                            let q = dsb[i].isdel;
                            if (q === 0){
                                sb.push(dsb[i]);
                            }
                        }
                        let KeyStart = (this.state.CurrentPage2-1)*PageSize2;
                        let KeyEnd = this.state.CurrentPage2*PageSize2;
                        let DownloadList = [];
                        if(KeyEnd <= sb.length){
                            for(let i=KeyStart; i<KeyEnd; i++){
                                const DownloadListBrr = sb[i];
                                DownloadList.push(DownloadListBrr)
                            }
                        }else {
                            for(let i=KeyStart; i<sb.length; i++){
                                const DownloadListBrr = sb[i];
                                DownloadList.push(DownloadListBrr)
                            }
                        }
                        this.setState({
                            OrderOneArr:sb,
                            OrderOneBrr:DownloadList,
                            PageSize2:PageSize2,
                        });
                    }else {
                        let sb = [];
                        for(let i=0;i<a.length;i++){
                            let q = a[i].isdel;
                            if (q === 0){
                                sb.push(a[i]);
                            }
                        }
                        let KeyStart = (this.state.CurrentPage2-1)*PageSize2;
                        let KeyEnd = this.state.CurrentPage2*PageSize2;
                        let DownloadList = [];
                        if(KeyEnd <= sb.length){
                            for(let i=KeyStart; i<KeyEnd; i++){
                                const DownloadListBrr = sb[i];
                                DownloadList.push(DownloadListBrr)
                            }
                        }else {
                            for(let i=KeyStart; i<sb.length; i++){
                                const DownloadListBrr = sb[i];
                                DownloadList.push(DownloadListBrr)
                            }
                        }
                        this.setState({
                            OrderOneArr:sb,
                            OrderOneBrr:DownloadList,
                            PageSize2:PageSize2,
                        });
                    }
                });
            }else {
                //分销展示直销
                get({url:"agency/show_direct_sales_order"}).then(res => {
                    if(res.errno === '200'){
                        let a = res.data;
                        let b = [];
                        for(let i=0;i<a.length;i++){
                            let q = a[i].isdel;
                            if (q === 0){
                                b.push(a[i]);
                            }
                        }
                        let KeyStart = (this.state.CurrentPage2-1)*PageSize2;
                        let KeyEnd = this.state.CurrentPage2*PageSize2;
                        let DownloadList = [];
                        if(KeyEnd <= b.length){
                            for(let i=KeyStart; i<KeyEnd; i++){
                                const DownloadListBrr = b[i];
                                DownloadList.push(DownloadListBrr)
                            }
                        }else {
                            for(let i=KeyStart; i<b.length; i++){
                                const DownloadListBrr = b[i];
                                DownloadList.push(DownloadListBrr)
                            }
                        }
                        this.setState({
                            OrderOneArr:b,
                            OrderOneBrr:DownloadList,
                            PageSize2:PageSize2,
                        });
                    }else {
                        this.setState({
                            OrderOneArr:[],
                            OrderOneBrr:[],
                            PageSize2:PageSize2,
                        });
                    }
                });
            }
        });

        let vm = this;
        document.onclick=vm.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
            PeToggleOn: true,
            PeDisplay: 'none',
            grToggleOn: true,
            grDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    //顶部菜单选中
    MemberTopNav=(item,index) =>{
        // this.props.ChildBackNavClick(item.nav);
        if (item.nav !== '个人云程'){
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'暂未开放'+item.nav,
            });
        }
    };










    // 二级顶部菜单
    GrServicePer=(item,index) =>{
        this.setState({
            GrServicePerNav:item.nav,

            PeYYNumInput:'',
            OrderFrrPe:'请选择分发来源订单',
            OrderFrrPeId:'',
            GrNumInput:'',
            GrYYNumInput:'',
            OrderFrrGr:'请选择分发来源订单',
            OrderFrrGrId:'',

            OrderCodeSearch: '',
            OrderCodeInfoType: '',
        });
        // let isDealer = this.state.isDealer;
        if (item.nav ==='采购订单'){
            let H1 = window.innerHeight-622;
            let PageSize1 = Math.floor(H1/30)
            //代理商
            get({url:"agency/agent_show_own_order"}).then(res => {
                if(res.errno === '200'){
                    let a = res.data;
                    //分销商
                    get({url:"agency/show_own_order"}).then(res => {
                        if(res.errno === '200'){
                            let b = res.data;
                            let dsb = a.concat(b);
                            let sb = [];
                            for(let i=0;i<dsb.length;i++){
                                let q = dsb[i].isdel;
                                if (q === 0){
                                    sb.push(dsb[i]);
                                }
                            }
                            let KeyStart = (this.state.CurrentPage1-1)*PageSize1;
                            let KeyEnd = this.state.CurrentPage1*PageSize1;
                            let DownloadList = [];
                            if(KeyEnd <= sb.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<sb.length; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }
                            this.setState({
                                OrderArr:sb,
                                OrderBrr:DownloadList,
                                PageSize1:PageSize1,
                            });
                        }else {
                            let sb = [];
                            for(let i=0;i<a.length;i++){
                                let q = a[i].isdel;
                                if (q === 0){
                                    sb.push(a[i]);
                                }
                            }
                            let KeyStart = (this.state.CurrentPage1-1)*PageSize1;
                            let KeyEnd = this.state.CurrentPage1*PageSize1;
                            let DownloadList = [];
                            if(KeyEnd <= sb.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<sb.length; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }
                            this.setState({
                                OrderArr:sb,
                                OrderBrr:DownloadList,
                                PageSize1:PageSize1,
                            });
                        }
                    });
                }else {
                    //分销商
                    get({url:"agency/show_own_order"}).then(res => {
                        if(res.errno === '200'){
                            let a = res.data;
                            let b = [];
                            for(let i=0;i<a.length;i++){
                                let q = a[i].isdel;
                                if (q === 0){
                                    b.push(a[i]);
                                }
                            }
                            let KeyStart = (this.state.CurrentPage1-1)*PageSize1;
                            let KeyEnd = this.state.CurrentPage1*PageSize1;
                            let DownloadList = [];
                            if(KeyEnd <= b.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const DownloadListBrr = b[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<b.length; i++){
                                    const DownloadListBrr = b[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }
                            this.setState({
                                OrderArr:b,
                                OrderBrr:DownloadList,
                                PageSize1:PageSize1,
                            });
                        }else {
                            this.setState({
                                OrderArr:[],
                                OrderBrr:[],
                                PageSize1:PageSize1,
                            });
                        }
                    });
                }
            });
        }else if (item.nav ==='销售分发'){
            //代理商
            get({url:"agency/agent_show_own_order"}).then(res => {
                if(res.errno === '200'){
                    let a = res.data;
                    //分销商
                    get({url:"agency/show_own_order"}).then(res => {
                        if(res.errno === '200'){
                            let b = res.data;
                            let sb = a.concat(b);
                            this.setState({
                                OrderFrr:sb,
                            });
                        }else {
                            this.setState({
                                OrderFrr:a,
                            });
                        }
                    });
                }else {
                    //分销商
                    get({url:"agency/show_own_order"}).then(res => {
                        if(res.errno === '200'){
                            let b = res.data;
                            this.setState({
                                OrderFrr:b,
                            });
                        }else {
                            this.setState({
                                OrderFrr:[],
                            });
                        }
                    });
                }
            });
        }else if (item.nav ==='查询订单'){
            get({url:'user/basic_info'}).then((res) => {
                if(res.errno === '200'){
                    let User = res.data[0];
                    if (User.purchase_quantity !== 0){
                        this.setState({
                            YunChengState:'Key',
                            AllNum:User.purchase_quantity,
                            SYNum:User.residue_num,
                            FFNum:User.purchase_quantity - User.residue_num,
                        });
                    }else {
                        this.setState({
                            YunChengState:'NotKey',
                            AllNum:'',
                            FFNum:'',
                            JHNum:'',
                            SYNum:'',
                        });
                    }
                }
            });

            let H1 = window.innerHeight-622;
            let PageSize2 = Math.floor(H1/30)
            //分销展示代理
            get({url:"agency/show_agent_order"}).then(res => {
                if(res.errno === '200'){
                    let a = res.data;
                    //分销展示直销
                    get({url:"agency/show_direct_sales_order"}).then(res => {
                        if(res.errno === '200'){
                            let b = res.data;
                            let dsb = a.concat(b);
                            let sb = [];
                            for(let i=0;i<dsb.length;i++){
                                let q = dsb[i].isdel;
                                if (q === 0){
                                    sb.push(dsb[i]);
                                }
                            }
                            let KeyStart = (this.state.CurrentPage2-1)*PageSize2;
                            let KeyEnd = this.state.CurrentPage2*PageSize2;
                            let DownloadList = [];
                            if(KeyEnd <= sb.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<sb.length; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }
                            this.setState({
                                OrderOneArr:sb,
                                OrderOneBrr:DownloadList,
                                PageSize2:PageSize2,
                            });
                        }else {
                            let sb = [];
                            for(let i=0;i<a.length;i++){
                                let q = a[i].isdel;
                                if (q === 0){
                                    sb.push(a[i]);
                                }
                            }
                            let KeyStart = (this.state.CurrentPage2-1)*PageSize2;
                            let KeyEnd = this.state.CurrentPage2*PageSize2;
                            let DownloadList = [];
                            if(KeyEnd <= sb.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<sb.length; i++){
                                    const DownloadListBrr = sb[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }
                            this.setState({
                                OrderOneArr:sb,
                                OrderOneBrr:DownloadList,
                                PageSize2:PageSize2,
                            });
                        }
                    });
                }else {
                    //分销展示直销
                    get({url:"agency/show_direct_sales_order"}).then(res => {
                        if(res.errno === '200'){
                            let a = res.data;
                            let b = [];
                            for(let i=0;i<a.length;i++){
                                let q = a[i].isdel;
                                if (q === 0){
                                    b.push(a[i]);
                                }
                            }
                            let KeyStart = (this.state.CurrentPage2-1)*PageSize2;
                            let KeyEnd = this.state.CurrentPage2*PageSize2;
                            let DownloadList = [];
                            if(KeyEnd <= b.length){
                                for(let i=KeyStart; i<KeyEnd; i++){
                                    const DownloadListBrr = b[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }else {
                                for(let i=KeyStart; i<b.length; i++){
                                    const DownloadListBrr = b[i];
                                    DownloadList.push(DownloadListBrr)
                                }
                            }
                            this.setState({
                                OrderOneArr:b,
                                OrderOneBrr:DownloadList,
                                PageSize2:PageSize2,
                            });
                        }else {
                            this.setState({
                                OrderOneArr:[],
                                OrderOneBrr:[],
                                PageSize2:PageSize2,
                            });
                        }
                    });
                }
            });
        }
    };




    CurrentPage1(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize1;
        const KeyEnd = CurrentPage*this.state.PageSize1;
        const DownloadList = [];
        if(KeyEnd <= this.state.OrderArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const DownloadBList = this.state.OrderArr[i];
                DownloadList.push(DownloadBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.OrderArr.length; i++){
                const DownloadBList = this.state.OrderArr[i];
                DownloadList.push(DownloadBList)
            }
        }
        this.setState({
            OrderBrr:DownloadList,
        });
    }


    CurrentPage2(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize2;
        const KeyEnd = CurrentPage*this.state.PageSize2;
        const DownloadList = [];
        if(KeyEnd <= this.state.OrderOneArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const DownloadBList = this.state.OrderOneArr[i];
                DownloadList.push(DownloadBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.OrderOneArr.length; i++){
                const DownloadBList = this.state.OrderOneArr[i];
                DownloadList.push(DownloadBList)
            }
        }
        this.setState({
            OrderOneBrr:DownloadList,
        });
    }



    // 查询订单
    OrderCodeListClick=(item,index)=>{
        if ( item.order_remark === '分销商生成代理订单'){
            this.setState({
                // GrServicePerType:'激活码',
                // OrderCodeArr:[],
                PopUp:'提示弹窗',
                MessageTip:'暂未开放。',
                OrderCodeSearch: '',
                OrderCodeInfoType: '',
            });
        }else {
            post({
                url:'agency/Activation_code_query_order',
                data:{
                    tgo_id:item.id,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    this.setState({
                        OrderCodeInfo:res.data[0],
                        OrderCodeInfoType:'sb',
                    });
                } else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                        OrderCodeSearch: '',
                        OrderCodeInfoType: '',
                    });
                }
            });
        }

    };



    OrderCodeSearch(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            OrderCodeSearch: event.target.value,
        });
    };
    OrderCodeSearchClear(){
        this.setState({
            OrderCodeSearch: '',
            OrderCodeInfoType: '',
        });
    };


    OrderCodeSearchBtn(){
        let OrderCodeSearch = this.state.OrderCodeSearch;
        if (OrderCodeSearch !== ''){
            if (OrderCodeSearch.length > 6){
                post({
                    url:'agency/Activation_code_query_code',
                    data:{
                        verificat_code:OrderCodeSearch,
                    },
                }).then((res) => {
                    if (res.errno === '200') {
                        this.setState({
                            OrderCodeInfoType:'sb',
                            OrderCodeInfo:res.data[0],
                        });
                    } else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                            OrderCodeInfoType:'',
                        });
                    }
                });
            }else {
                get({url:'reuse/adopt_yynum_get_id/'+OrderCodeSearch}).then((res) => {
                    if(res.errno === '200'){
                        let a = res.data[0];
                        post({
                            url:'agency/Activation_code_query_yynum',
                            data:{
                                user_id:a.user_id,
                            },
                        }).then((res) => {
                            if (res.errno === '200') {
                                this.setState({
                                    OrderCodeInfoType:'sb',
                                    OrderCodeInfo:res.data[0],
                                });
                            } else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                    OrderCodeInfoType:'',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入相关信息。',
            });
        }
    };


    // 销售分发
    PeYYNumInput(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            PeYYNumInput: event.target.value,
        });
    };
    PeYYNumClear(){
        this.setState({
            PeYYNumInput: '',
        });
    };


    PeSales(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            PeToggleOn: !prevState.PeToggleOn,
            PeDisplay:prevState.PeToggleOn ? 'block' : 'none',
            grToggleOn: true,
            grDisplay: 'none',
        }));
    };
    PeSalesClick=(item,index)=>{
        this.setState({
            OrderFrrPe:item.order_id+'(余'+item.residue_num+'）',
            OrderFrrPeId:item.id,
        });
    };

    PeSalesBtn(){
        let PeYYNumInput = this.state.PeYYNumInput;
        // let OrderFrrPeId = this.state.OrderFrrPeId;
        if (PeYYNumInput !== ''){
            get({url:'user/basic_info'}).then((res) => {
                if(res.errno === '200'){
                    let User = res.data[0];
                    if (User.residue_num >= 1){
                        get({url:'reuse/get_personal_info/'+PeYYNumInput}).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    UserInfo:res.data[0],
                                    PopUp:'分发个人确认提示',
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'库存数量不足。',
                        });
                    }
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请将数据填充完全。',
            });
        }
    };


    OkPeSalesBtn(){
        let PeUserId = this.state.UserInfo.id;
        let OrderFrrPeId = this.state.OrderFrrPeId;
        post({
            url:'agency/cloud_VIP_order',
            data:{
                tgo_id:OrderFrrPeId,
                user_id:PeUserId,
            },
        }).then((res) => {
            if (res.errno === '200') {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                    PeYYNumInput:'',
                    OrderFrrPe:'请选择分发来源订单',
                    OrderFrrPeId:'',
                });
                get({url:"agency/agent_show_own_order"}).then(res => {
                    if(res.errno === '200'){
                        let a = res.data;
                        //分销商
                        get({url:"agency/show_own_order"}).then(res => {
                            if(res.errno === '200'){
                                let b = res.data;
                                let sb = a.concat(b);
                                this.setState({
                                    OrderFrr:sb,
                                });
                            }else {
                                this.setState({
                                    OrderFrr:a,
                                });
                            }
                        });
                    }else {
                        //分销商
                        get({url:"agency/show_own_order"}).then(res => {
                            if(res.errno === '200'){
                                let b = res.data;
                                this.setState({
                                    OrderFrr:b,
                                });
                            }else {
                                this.setState({
                                    OrderFrr:[],
                                });
                            }
                        });
                    }
                });
            } else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };



    GrYYNumInput(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            GrYYNumInput: event.target.value,
        });
    };
    GrYYNumClear(){
        this.setState({
            GrYYNumInput: '',
        });
    };
    GrNumInput(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            GrNumInput: event.target.value,
        });
    };
    GrNumClear(){
        this.setState({
            GrNumInput: '',
        });
    };
    GrSales(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            grToggleOn: !prevState.grToggleOn,
            grDisplay:prevState.grToggleOn ? 'block' : 'none',
            PeToggleOn: true,
            PeDisplay: 'none',
        }));
    };
    GrSalesClick=(item,index)=>{
        this.setState({
            OrderFrrGr:item.order_id+'(余'+item.residue_num+'）',
            OrderFrrGrId:item.id,
        });
    };

    GrSalesBtn(){
        let GrYYNumInput = this.state.GrYYNumInput;
        // let OrderFrrGrId = this.state.OrderFrrGrId;
        let GrNumInput = this.state.GrNumInput;
        if (GrYYNumInput !== ''&&GrNumInput !== ''){
            get({url:'user/basic_info'}).then((res) => {
                if(res.errno === '200'){
                    let User = res.data[0];
                    if (User.residue_num >= GrNumInput){
                        get({url:"reuse/get_org_info/"+GrYYNumInput}).then(res => {
                            if(res.errno === '200'){
                                this.setState({
                                    GroupInfo:res.data[0],
                                    PopUp:'分发单位确认提示',
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'库存数量不足。',
                        });
                    }
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请将数据填充完全。',
            });
        }
    };


    OkGrSalesBtn(){
        let GrUserId = this.state.GroupInfo.user_id;
        let OrderFrrGrId = this.state.OrderFrrGrId;
        let GrNumInput = this.state.GrNumInput;
        post({
            url:'agency/create_dealer_order',
            data:{
                tgo_id:OrderFrrGrId,
                user_id:GrUserId,
                dealer_num:GrNumInput,
            },
        }).then((res) => {
            if (res.errno === '200') {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                    GrNumInput:'',
                    GrYYNumInput:'',
                    OrderFrrGr:'请选择分发来源订单',
                    OrderFrrGrId:'',
                });
                get({url:"agency/show_own_order"}).then(res => {
                    if(res.errno === '200'){
                        let b = res.data;
                        this.setState({
                            OrderFrr:b,
                        });
                    }else {
                        this.setState({
                            OrderFrr:[],
                        });
                    }
                });
            } else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };



    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    BackGrService(){
        this.props.ChildBackNavClick('');
    };

    CrossOut(){
        this.setState({
            PopUp:'',
        });
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'MemberPackageBox scrollBar clearfix'} style={{height:window.innerHeight-180,}}>
                    {
                        this.state.YunChengState === 'NotKey'&&
                        <div>
                            <div className={'DetailsPackageTop clearfix'} style={{padding:'6px 0',height:36,}}>
                                <div className={'DetailsBackBox clearfix'} style={{marginTop:6,}} onClick={this.BackGrService.bind(this)}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                            </div>
                            <div style={{padding:'170px 0',}}>
                                <div className={'TrainDataMain'}>
                                    <div className={'keyNumLogo'}> </div>
                                    {/*<div className={'keyNumTitle'}>贵单位缺少‘云程’服务，未加入统一电子化管理，请与服务平台联系</div>*/}
                                    <div className={'keyNumTitle'} style={{height:'auto',}}>
                                        <span>使用云程过程中如有疑问请联系:</span>
                                        {/*<span style={{color:'#60c7ef',}}>*/}
                                        {/*    您第一次使用云程，请您*/}
                                         {/*    <a*/}
                                        {/*        style={{color:'#ff0000',cursor:'pointer',}}*/}
                                        {/*        href={'http://oss.asfcyy.com/richTextImage/71c3b454a99811ec9dc700163e3521a2.docx'}*/}
                                        {/*        download={'http://oss.asfcyy.com/richTextImage/71c3b454a99811ec9dc700163e3521a2.docx'}*/}
                                        {/*    >下载文档</a>*/}
                                        {/*    填写完整后发送至*/}
                                        {/*</span>*/}
                                        {/*yuncheng@asfcyy.com*/}
                                        {/*<span style={{color:'#60c7ef',}}>邮箱，客服将在第一时间为您提供云程使用咨询，帮助您更好的安装使用云程1.0产品</span>*/}
                                    </div>
                                    <div className={'keyNumTitle'} style={{height:'auto',}}>
                                        <span style={{color:'#000',fontSize:22,fontWeight:'bold',}}>米老师：13691581580</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.YunChengState === 'Key'&&
                        <div>
                            <div className={'DetailsPackageTop clearfix'} style={{padding:'6px 0',height:36,}}>
                                {
                                    this.state.GrServiceTopArr.map((item,index) =>
                                        <div
                                            className={'GrServiceTop'}
                                            key={index} onClick={() => this.MemberTopNav(item,index)}
                                            style={item.nav === this.state.MemberTopNav ? {backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}:{}}
                                        >
                                            {item.nav}
                                        </div>
                                    )
                                }
                                <div className={'DetailsBackBox clearfix'} style={{marginTop:6,}} onClick={this.BackGrService.bind(this)}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                            </div>
                            <div className={'GrServicePerBox clearfix'} style={{height:window.innerHeight-274,}}>
                                <div className={'GrServicePerTit clearfix'}>
                                    {
                                        this.state.GrServicePerNavArr.map((item,index) =>
                                            <div
                                                className={'GrServicePerTop'}
                                                key={index} onClick={() => this.GrServicePer(item,index)}
                                                style={item.nav === this.state.GrServicePerNav ? {backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}:{}}
                                            >
                                                {item.nav}
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    this.state.GrServicePerNav === '查询订单' &&
                                    <div>
                                        <div className={'ServicePerBorder'}> </div>
                                        <div className={'GrServicePerTit clearfix'}>
                                            {
                                                this.state.AllNum !== ''&&
                                                <div>
                                                    <div className={'GrServicePerNumBox'}>购买总数：<span style={{color:'#e9783d',}}>{this.state.AllNum}</span></div>
                                                    <div className={'GrServicePerNumBox'}>已分发数量：<span style={{color:'#e9783d',}}>{this.state.FFNum}</span></div>
                                                    {/*<div className={'GrServicePerNumBox'}>已激活数量：<span style={{color:'#e9783d',}}>{this.state.JHNum}</span></div>*/}
                                                    <div className={'GrServicePerNumBox'}>当前剩余数量：<span style={{color:'#e9783d',}}>{this.state.SYNum}</span></div>
                                                </div>
                                            }
                                        </div>
                                        <div className={'GrServicePerMainBox clearfix'} style={{height:window.innerHeight-574,}}>
                                            {
                                                this.state.GrServicePerType === '订单' &&
                                                <div>
                                                    <div className={'ServiceMainTitleBox clearfix'}>
                                                        <div className={'ServiceMainTitleList'} style={{width:250,}}>订单编号</div>
                                                        <div className={'ServiceMainTitleList'} style={{width:160,}}>时间</div>
                                                        <div className={'ServiceMainTitleList'} style={{width:160,}}>分发类型（数量）</div>
                                                        <div className={'ServiceMainTitleList'} style={{width:240,}}>分发用户</div>
                                                        <div className={'ServiceMainTitleList'} style={{width:106,border:0,}}>操作</div>
                                                    </div>
                                                    <div className={'MainListBoxes'} style={{height:window.innerHeight-622}}>
                                                        {
                                                            this.state.OrderOneBrr.map((item,index) =>
                                                                <div className={'ServicePerMainList clearfix'} key={index}>
                                                                    <div className={'ServicePerLister'} style={{width:250,}}>{item.order_id}</div>
                                                                    <div className={'ServicePerLister'} style={{width:160,}}>{item.pay_time.substring(0,10)}</div>
                                                                    <div className={'ServicePerLister'} style={{width:160,}}>
                                                                        {
                                                                            item.order_remark === '分销商生成代理订单'&&
                                                                            <div>单位（{item.purchase_quantity}）</div>
                                                                        }
                                                                        {
                                                                            item.order_remark !== '分销商生成代理订单'&&
                                                                            <div>个人</div>
                                                                        }
                                                                    </div>
                                                                    <div className={'ServicePerLister'} style={{width:240,fontSize:12,}}>
                                                                        {
                                                                            item.order_remark === '分销商生成代理订单'&&
                                                                            <div>{item.org_name}</div>
                                                                        }
                                                                        {
                                                                            item.order_remark !== '分销商生成代理订单'&&
                                                                            <div>{item.real_name}</div>
                                                                        }
                                                                    </div>
                                                                    <div className={'ServicePerLister clearfix'} style={{width:106,fontSize:12,textIndent:'0.5em',}}>
                                                                        {
                                                                            item.order_remark === '分销商生成代理订单'&&
                                                                            <div
                                                                                style={{color:'#d9d9d9',float:'left',}}
                                                                                /* onClick={() => this.OrderCodeListClick(item,index)}*/
                                                                            >暂禁</div>
                                                                        }
                                                                        {
                                                                            item.order_remark !== '分销商生成代理订单'&&
                                                                            <div
                                                                                style={{color:'#e9783d',float:'left', cursor:'pointer',textDecoration:'underline',}}
                                                                                onClick={() => this.OrderCodeListClick(item,index)}
                                                                            >激活码</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                        <div style={{height:36,margin:'10px auto',}}>
                                            {
                                                this.state.OrderOneArr.length !== 0 && this.state.GrServicePerType === '订单' &&
                                                <Pagination2
                                                    TotalSize={this.state.OrderOneArr.length}
                                                    PageSize={this.state.PageSize2}
                                                    pageCallbackFn={this.CurrentPage2.bind(this)}
                                                />
                                            }
                                        </div>
                                        <div className={'ServicePerBorder'}
                                             style={{backgroundColor:'#fff',}}
                                        > </div>
                                        <div>
                                            {
                                                this.state.GrServicePerType === '订单' &&
                                                <div className={'OrderCodeSearchBox clearfix'} style={{marginBottom:10,}}>
                                                    <div className={'OrderCodeSearchTit'}>激活码 / ID查询：</div>
                                                    <div className={'GrServicePerSalesMainInput clearfix'} style={{margin:'5px 0',width:250,}}>
                                                        <input className={'SearchInput'}
                                                               type={'text'}
                                                               style={{height:26,lineHeight:'26px',width:200,float:'left',}}
                                                               name={'SearchInput'}
                                                               value={this.state.OrderCodeSearch}
                                                               onChange={this.OrderCodeSearch.bind(this)}
                                                               placeholder={'输入激活码或ID查询相关信息'}
                                                               autoComplete={'off'}
                                                        />
                                                        <div className={'ClearInput css-icon icon-close1'} onClick={this.OrderCodeSearchClear.bind(this)}> </div>
                                                    </div>
                                                    <div className={'OrderCodeSearchBtn'} onClick={this.OrderCodeSearchBtn.bind(this)}>查询</div>
                                                </div>
                                            }
                                            {
                                                this.state.OrderCodeInfoType !== ''&&
                                                <div className={'OrderCodeSearchBox clearfix'}>
                                                    <div className={'OrderCodeInfoBox clearfix'}>
                                                        <div className={'OrderCodeInfoTit'}>生成时间：</div>
                                                        <div className={'OrderCodeInfo'}>{this.state.OrderCodeInfo.ctime.substring(0,10)}</div>
                                                    </div>
                                                    <div className={'OrderCodeInfoBox'}>
                                                        <div className={'OrderCodeInfoTit'}>到期时间：</div>
                                                        <div className={'OrderCodeInfo'}>{this.state.OrderCodeInfo.expire_time.substring(0,10)}</div>
                                                    </div>
                                                    <div className={'OrderCodeInfoBox'}>
                                                        <div className={'OrderCodeInfoTit'}>MAC地址：</div>
                                                        <div className={'OrderCodeInfo'}>{this.state.OrderCodeInfo.MAC ? this.state.OrderCodeInfo.MAC : '未绑定'}</div>
                                                    </div>
                                                    <div className={'OrderCodeInfoBox'}>
                                                        <div className={'OrderCodeInfoTit'}>激活用户：</div>
                                                        <div className={'OrderCodeInfo'}>{this.state.OrderCodeInfo.real_name}（ {this.state.OrderCodeInfo.yy_num} ）</div>
                                                    </div>
                                                    <div className={'OrderCodeInfoBox'}>
                                                        <div className={'OrderCodeInfoTit'}>激活码：</div>
                                                        <div className={'OrderCodeInfo'}>{this.state.OrderCodeInfo.verificat_code}</div>
                                                    </div>
                                                    <div className={'OrderCodeInfoBox'} style={{width:480,}}>
                                                        <div className={'OrderCodeInfoTit'}>分发单位：</div>
                                                        <div className={'OrderCodeInfo'}>{this.state.OrderCodeInfo.dealer_name}</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.GrServicePerNav === '采购订单' &&
                                    <div>
                                        <div className={'GrServicePerMainBox clearfix'} style={{height:window.innerHeight-404,marginBottom:20,}}>
                                            <div className={'ServiceMainTitleBox clearfix'}>
                                                <div className={'ServiceMainTitleList'} style={{width:250,}}>订单编号</div>
                                                <div className={'ServiceMainTitleList'} style={{width:200,}}>时间</div>
                                                <div className={'ServiceMainTitleList'} style={{width:200,}}>数量</div>
                                                <div className={'ServiceMainTitleList'} style={{width:266,border:0,}}>销售商</div>
                                            </div>
                                            <div className={'MainListBoxes'} style={{height:window.innerHeight-452}}>
                                                {
                                                    this.state.OrderBrr.map((item,index) =>
                                                        <div className={'ServicePerMainList clearfix'} key={index}>
                                                            <div className={'ServicePerLister'} style={{width:250,}}>{item.order_id}</div>
                                                            <div className={'ServicePerLister'} style={{width:200,}}>{item.pay_time.substring(0,10)}</div>
                                                            <div className={'ServicePerLister'} style={{width:200,}}>{item.purchase_quantity}</div>
                                                            <div className={'ServicePerLister'} style={{width:266,fontSize:12,}}>{!item.dealer_name ? '平台分发' : item.dealer_name}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.OrderArr.length !== 0 &&
                                            <Pagination2
                                                TotalSize={this.state.OrderArr.length}
                                                PageSize={this.state.PageSize1}
                                                pageCallbackFn={this.CurrentPage1.bind(this)}
                                            />
                                        }
                                    </div>
                                }

                                {
                                    this.state.GrServicePerNav === '销售分发' &&
                                    <div>
                                        <div className={'ServicePerBorder clearfix'}> </div>
                                        {
                                            this.state.isDealer === '分销商'&&
                                            <div>
                                                <div className={'GrServicePerSalesBox'}>
                                                    <div className={'GrServicePerSalesTit clearfix'}>分发给单位用户：</div>
                                                    <div className={'GrServicePerSalesMain clearfix'}>
                                                        <div className={'GrServicePerSalesMainWord'}>用户ID：</div>
                                                        <div className={'GrServicePerSalesMainInput clearfix'}>
                                                            <input className={'SearchInput'}
                                                                   type={'text'}
                                                                   style={{height:26,lineHeight:'26px',width:250,float:'left',}}
                                                                   name={'SearchInput'}
                                                                   value={this.state.GrYYNumInput}
                                                                   onChange={this.GrYYNumInput.bind(this)}
                                                                   placeholder={'输入用户ID'}
                                                                   autoComplete={'off'}
                                                            />
                                                            <div className={'ClearInput css-icon icon-close1'} onClick={this.GrYYNumClear.bind(this)}> </div>
                                                        </div>
                                                    </div>
                                                    <div className={'GrServicePerSalesMain clearfix'}>
                                                        <div className={'GrServicePerSalesMainWord'}>分发数量：</div>
                                                        <div className={'GrServicePerSalesMainInput clearfix'}>
                                                            <input className={'SearchInput'}
                                                                   type={'text'}
                                                                   style={{height:26,lineHeight:'26px',width:250,float:'left',}}
                                                                   name={'SearchInput'}
                                                                   value={this.state.GrNumInput}
                                                                   onChange={this.GrNumInput.bind(this)}
                                                                   placeholder={'输入分发数量'}
                                                                   autoComplete={'off'}
                                                            />
                                                            <div className={'ClearInput css-icon icon-close1'} onClick={this.GrNumClear.bind(this)}> </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className={'GrServicePerSalesMain clearfix'}>*/}
                                                    {/*    <div className={'GrServicePerSalesMainWord'}>选择订单：</div>*/}
                                                    {/*    <div className={'GrServicePerSalesMainInput clearfix'} style={{cursor:'pointer',}} onClick={this.GrSales.bind(this)}>*/}
                                                    {/*        <div className={'GrServiceDownNav'} style={{color:this.state.OrderFrrGr === '请选择分发来源订单'?'#989898' : '',}}>{this.state.OrderFrrGr}</div>*/}
                                                    {/*        <div className={'GrServiceDownBtn css-icon icon-arrow-down1'}> </div>*/}
                                                    {/*        <div className={'GrServiceDownSelectBox scrollBar'} style={{display:this.state.grDisplay,}}>*/}
                                                    {/*            {*/}
                                                    {/*                this.state.OrderFrr.map((item,index) => (*/}
                                                    {/*                    <div key={index}>*/}
                                                    {/*                        {*/}
                                                    {/*                            item.residue_num > 0&&*/}
                                                    {/*                            <div className={'GrServiceDownSelectList'} onClick={() => this.GrSalesClick(item,index)}>*/}
                                                    {/*                                {item.order_id}*/}
                                                    {/*                                <span style={{fontSize:12,float:'right',color:'#ff0000',}}>(余量：{item.residue_num})</span>*/}
                                                    {/*                            </div>*/}
                                                    {/*                        }*/}
                                                    {/*                    </div>*/}
                                                    {/*                ))*/}
                                                    {/*            }*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className={'GrServicePerSalesMain clearfix'}>
                                                        <div
                                                            className={'GrServicePerTop'}
                                                            onClick={this.GrSalesBtn.bind(this)}
                                                            style={{backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}}
                                                        >
                                                            分发
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'GrServicePerSalesBox'}>
                                                    <div className={'GrServicePerSalesTit clearfix'}>分发给个人用户：</div>
                                                    <div className={'GrServicePerSalesMain clearfix'}>
                                                        <div className={'GrServicePerSalesMainWord'}>用户ID：</div>
                                                        <div className={'GrServicePerSalesMainInput clearfix'}>
                                                            <input className={'SearchInput'}
                                                                   type={'text'}
                                                                   style={{height:26,lineHeight:'26px',width:250,float:'left',}}
                                                                   name={'SearchInput'}
                                                                   value={this.state.PeYYNumInput}
                                                                   onChange={this.PeYYNumInput.bind(this)}
                                                                   placeholder={'输入用户ID'}
                                                                   autoComplete={'off'}
                                                            />
                                                            <div className={'ClearInput css-icon icon-close1'} onClick={this.PeYYNumClear.bind(this)}> </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className={'GrServicePerSalesMain clearfix'}>*/}
                                                    {/*    <div className={'GrServicePerSalesMainWord'}>选择订单：</div>*/}
                                                    {/*    <div className={'GrServicePerSalesMainInput clearfix'} style={{cursor:'pointer',}} onClick={this.PeSales.bind(this)}>*/}
                                                    {/*        <div className={'GrServiceDownNav'} style={{color:this.state.OrderFrrPe === '请选择分发来源订单'?'#989898' : '',}}>{this.state.OrderFrrPe}</div>*/}
                                                    {/*        <div className={'GrServiceDownBtn css-icon icon-arrow-down1'}> </div>*/}
                                                    {/*        <div className={'GrServiceDownSelectBox scrollBar'} style={{display:this.state.PeDisplay,}}>*/}
                                                    {/*            {*/}
                                                    {/*                this.state.OrderFrr.map((item,index) => (*/}
                                                    {/*                    <div key={index}>*/}
                                                    {/*                        {*/}
                                                    {/*                            item.residue_num > 0&&*/}
                                                    {/*                            <div className={'GrServiceDownSelectList'} onClick={() => this.PeSalesClick(item,index)}>*/}
                                                    {/*                                {item.order_id}*/}
                                                    {/*                                <span style={{fontSize:12,float:'right',color:'#ff0000',}}>(余量：{item.residue_num})</span>*/}
                                                    {/*                            </div>*/}
                                                    {/*                        }*/}
                                                    {/*                    </div>*/}
                                                    {/*                ))*/}
                                                    {/*            }*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className={'GrServicePerSalesMain clearfix'}> </div>
                                                    <div className={'GrServicePerSalesMain clearfix'}>
                                                        <div
                                                            className={'GrServicePerTop'}
                                                            onClick={this.PeSalesBtn.bind(this)}
                                                            style={{backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}}
                                                        >
                                                            分发
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.isDealer === '普通用户'&&
                                            <div className={'GrServicePerSalesBox'}>
                                                <div className={'GrServicePerSalesTit clearfix'}>分发给个人用户：</div>
                                                <div className={'GrServicePerSalesMain clearfix'}>
                                                    <div className={'GrServicePerSalesMainWord'}>用户ID：</div>
                                                    <div className={'GrServicePerSalesMainInput clearfix'}>
                                                        <input className={'SearchInput'}
                                                               type={'text'}
                                                               style={{height:26,lineHeight:'26px',width:250,float:'left',}}
                                                               name={'SearchInput'}
                                                               value={this.state.PeYYNumInput}
                                                               onChange={this.PeYYNumInput.bind(this)}
                                                               placeholder={'输入用户ID'}
                                                               autoComplete={'off'}
                                                        />
                                                        <div className={'ClearInput css-icon icon-close1'} onClick={this.PeYYNumClear.bind(this)}> </div>
                                                    </div>
                                                </div>
                                                {/*<div className={'GrServicePerSalesMain clearfix'}>*/}
                                                {/*    <div className={'GrServicePerSalesMainWord'}>选择订单：</div>*/}
                                                {/*    <div className={'GrServicePerSalesMainInput clearfix'} style={{cursor:'pointer',}} onClick={this.PeSales.bind(this)}>*/}
                                                {/*        <div className={'GrServiceDownNav'} style={{color:this.state.OrderFrrPe === '请选择分发来源订单'?'#989898' : '',}}>{this.state.OrderFrrPe}</div>*/}
                                                {/*        <div className={'GrServiceDownBtn css-icon icon-arrow-down1'}> </div>*/}
                                                {/*        <div className={'GrServiceDownSelectBox scrollBar'} style={{display:this.state.PeDisplay,}}>*/}
                                                {/*            {*/}
                                                {/*                this.state.OrderFrr.map((item,index) => (*/}
                                                {/*                    <div key={index}>*/}
                                                {/*                        {*/}
                                                {/*                            item.residue_num > 0&&*/}
                                                {/*                            <div className={'GrServiceDownSelectList'} onClick={() => this.PeSalesClick(item,index)}>*/}
                                                {/*                                {item.order_id}*/}
                                                {/*                                <span style={{fontSize:12,float:'right',color:'#ff0000',}}>(余量：{item.residue_num})</span>*/}
                                                {/*                            </div>*/}
                                                {/*                        }*/}
                                                {/*                    </div>*/}
                                                {/*                ))*/}
                                                {/*            }*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className={'GrServicePerSalesMain clearfix'}> </div>
                                                <div className={'GrServicePerSalesMain clearfix'}>
                                                    <div
                                                        className={'GrServicePerTop'}
                                                        onClick={this.PeSalesBtn.bind(this)}
                                                        style={{backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}}
                                                    >
                                                        分发
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {
                                this.state.PopUp === '分发单位确认提示' &&
                                <div className={'PopUpBoxBg'} style={{minHeight:600,}}>
                                    <div className={'AddItemBox'} style={{width:500,height:540,}}>
                                        <div className={'AddItemBigTitle'}>
                                            客户信息 -> 分发确认
                                            <div className={'AddItemBackIco'} onClick={this.CrossOut.bind(this)}> </div>
                                        </div>
                                        <div className={'VipContent'} style={{textAlign:'center',width:500,textIndent:'unset',}}>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    单位名称：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GroupInfo.org_name}
                                                </div>
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    管理员：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GroupInfo.admin_name}
                                                </div>
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    联系方式：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GroupInfo.mobile}
                                                </div>
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    单位类型：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GroupInfo.subtype}
                                                </div>
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    用户名：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GroupInfo.username}
                                                </div>
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    鹰扬ID：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GroupInfo.yy_num}
                                                </div>
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',height:'auto',minHeight:'26px',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',height:'auto',minHeight:'26px',}}>
                                                    单位地址：
                                                </div>
                                                {
                                                    this.state.GroupInfo.office_add&&
                                                    <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',height:'auto',minHeight:'26px',}}>
                                                        {
                                                            this.state.GroupInfo.office_pro === this.state.GroupInfo.office_city ?
                                                                this.state.GroupInfo.office_city+this.state.GroupInfo.office_county+this.state.GroupInfo.office_add :
                                                                this.state.GroupInfo.office_pro+this.state.GroupInfo.office_city+this.state.GroupInfo.office_county+this.state.GroupInfo.office_add
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>
                                                <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>
                                                    分发数量：
                                                </div>
                                                <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>
                                                    {this.state.GrNumInput}
                                                </div>
                                            </div>
                                            {/*<div className={'MainListBox clearfix'} style={{width:'500px',margin:'20px auto',}}>*/}
                                            {/*    <div className={'MainList'} style={{width:'30%',minWidth:'100px',textAlign:'right',}}>*/}
                                            {/*        分发来源：*/}
                                            {/*    </div>*/}
                                            {/*    <div className={'MainList'} style={{width:'70%',minWidth:'300px',textAlign:'left',textIndent:'1em',}}>*/}
                                            {/*        {this.state.OrderFrrGr}*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className={'AccountInfoBtnBox'} style={{position:'absolute',bottom:20,left:0,right:0,margin:'auto',}}>
                                                <div
                                                    className={'GrServicePerTop'}
                                                    onClick={this.OkGrSalesBtn.bind(this)}
                                                    style={{backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}}
                                                >
                                                    确认分发
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.PopUp === '分发个人确认提示' &&
                                <div className={'PopUpBoxBg'} style={{minHeight:500,}}>
                                    <div className={'AddItemBox'} style={{width:600,height:450,}}>
                                        <div className={'AddItemBigTitle'} style={{textIndent:0,textAlign:'center',}}>
                                            客户信息 -> 分发确认
                                            <div className={'AddItemBackIco'} onClick={this.CrossOut.bind(this)}> </div>
                                        </div>
                                        <div className={'AddItemMainBox scrollBar'} style={{width:600,height:420,}}>
                                            <div className={'AccountInfoBox'}>
                                                <div className={'AccountInfoPhoto'}>
                                                    <img
                                                        alt={''}
                                                        src={this.state.UserInfo.image}
                                                    />
                                                </div>
                                                <div className={'AccountInfoName'}>姓名：{this.state.UserInfo.real_name}</div>
                                                <div className={'AccountInfoNum'} style={{top:90,}}>ID号码：{this.state.UserInfo.yy_num}</div>
                                                <div className={'AccountInfoName'} style={{top:150,}}>联系方式：{this.state.UserInfo.mobile}</div>
                                                {/*<div className={'AccountInfoName'} style={{top:150,}}>教练员：{this.state.UserInfo.iscoach === 1 ? '是' : '否'}</div>*/}
                                                {/*<div className={'AccountInfoName'} style={{top:190,fontSize:15,}}>分发来源：{this.state.OrderFrrPe}</div>*/}
                                            </div>
                                            <div className={'AccountInfoBtnBox'} style={{position:'absolute',bottom:20,left:0,right:0,margin:'auto',}}>
                                                <div
                                                    className={'GrServicePerTop'}
                                                    onClick={this.OkPeSalesBtn.bind(this)}
                                                    style={{backgroundColor:'#0488ca',borderColor:'#0488ca',color:'#fff'}}
                                                >
                                                    确认分发
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GrYunChengPer;
