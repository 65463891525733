import React, { Component } from 'react';
import PopUp from "../round/PopUp";
import {get,post} from "../../ajax/tools";
import './member.css';
import {API_URL} from "../../ajax/config";

class Award extends Component {

    constructor(props){
        super(props);
        this.state = {
            GameArr:[],
            AwardType:'',

            AwardInfo:{},
            order_id:'',

            phone:'',
            address:'',
        };
    }
    componentDidMount=function() {
        get({url:'user/get_nopay_order/6'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    order_id:res.order_id,
                    AwardType:'pay',
                });
                this.PayWeChat = setInterval(() => {
                    get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                        if (res.state=== 1) {
                            clearInterval(this.PayWeChat);
                            this.setState({
                                AwardType:'ok',
                            });
                        }
                    });
                }, 2000);
            }else {
                get({url:'user/query_award_info'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            GameArr:res.data,
                            AwardType:'Game',
                        });
                    }
                });
            }
        });
    };

    About(){
        window.open('https://www.asfcyy.com/aboutMyself')
    };


    CrossOut=()=>{
        let BackInfo = {
            BackType:'关闭弹窗',

        };
        this.props.AwardButton(BackInfo);
    };


    phone(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '')
        this.setState({
            phone: event.target.value,
        });
    };
    address(event){
        // event.target.value = event.target.value.replace(/[^\d]/g, '')
        this.setState({
            address: event.target.value,
        });
    };





    AskOk(){
        this.setState({
            askSelect:true,
        });
    };
    AskErr(){
        this.setState({
            askSelect:false,
        });
    };

    AwardSave(){
        let Set = this.state.askSelect === true ? 1 : this.state.askSelect === false ? 0 : 9;
        let AwardInfo = this.state.AwardInfo;
        let phone = this.state.phone;
        let address = this.state.address;
        if (Set !== 9 && phone !== '' && address !== ''){
            post({
                url:'user/match_award_order',
                data:{
                    match_id:AwardInfo.match_num_id,
                    cash_delivery:Set,
                    address:address,
                    mobile:phone,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    if (Set === 0){
                        this.setState({
                            order_id:res.order_id,
                            AwardType:'pay',
                        });
                        this.PayWeChat = setInterval(() => {
                            get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                                if (res.state=== 1) {
                                    clearInterval(this.PayWeChat);
                                    this.setState({
                                        AwardType:'ok',
                                    });
                                }
                            });
                        }, 2000);
                    }else if (Set === 1){
                        get({url:"/payment/re_no_fee/"+res.order_id}).then(res => {
                            if (res.errno === '200') {
                                this.setState({
                                    AwardType:'ok',
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请选择是否货到付款',
            });
        }
    };


    AwardClick=(item,index)=>{
        this.setState({
            AwardInfo:item,
            AwardType:'info',
        });

    };











    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
            <div>
                <div className={'PopUpBoxBg'} style={{minHeight:600,}}>
                    <div className={'AddItemBox'} style={{width:1000,}}>
                        <div className={'AddItemBigTitle'}>
                            <div>领取赛事的获奖证书：</div>
                            <div className={'AddItemBackIco'} onClick={this.CrossOut.bind(this)}> </div>
                        </div>
                        {
                            this.state.AwardType === 'Game'&&
                            <div>
                                <div className={'AddItemMainListBox clearfix'}>
                                    <div className={'AddItemMainList'} style={{width:870,}}>获奖赛事</div>
                                    <div className={'AddItemMainList'} style={{width:120,}}>操作</div>
                                </div>
                                <div className={'AddItemMainBox scrollBar'}>
                                    {
                                        this.state.GameArr.map((item,index) => (
                                            <div className={'AddItemMainListBox clearfix'} key={index} style={{backgroundColor:'#fff',marginTop:'2px',}}>
                                                <div className={'AddItemMainList Ellipsis clearfix'} style={{width:870,}}>
                                                    {item.match_name}
                                                </div>
                                                <div className={'AddItemMainList clearfix'} style={{width:120,}}>
                                                    <div style={{display:'table',margin:'0 auto',}}>
                                                        <span
                                                            style={{display:'block',float:'left',margin:'0 16px',color:'#F99B9B',textDecoration:'underline',cursor:'pointer',}}
                                                            onClick={() => this.AwardClick(item,index)}
                                                        >申领</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.AwardType === 'info'&&
                            <div>
                                <div className={'AddItemMainListBox clearfix'} style={{backgroundColor:'#fff',marginTop:'30px',height:'auto',minHeight:'28px',}}>
                                    <div className={'AddItemMainList'} style={{width:180,height:'auto',minHeight:'28px',textAlign:'right',fontWeight:'bold',color:'#000',}}>获奖赛事：</div>
                                    <div className={'AddItemMainList'} style={{width:800,height:'auto',minHeight:'28px',textAlign:'left',}}>{this.state.AwardInfo.match_name}</div>
                                </div>
                                <div className={'AddItemMainListBox clearfix'} style={{backgroundColor:'#fff',marginTop:'30px',height:'auto',minHeight:'28px',}}>
                                    <div className={'AddItemMainList'} style={{width:180,height:'auto',minHeight:'28px',textAlign:'right',fontWeight:'bold',color:'#000',}}>邮费金额：</div>
                                    <div className={'AddItemMainList'} style={{width:800,height:'auto',minHeight:'28px',textAlign:'left',}}>￥{this.state.AwardInfo.cost_award}</div>
                                </div>
                                <div className={'AddItemMainListBox clearfix'} style={{backgroundColor:'#fff',marginTop:'30px',height:'auto',minHeight:'28px',}}>
                                    <div className={'AddItemMainList'} style={{width:180,height:'auto',minHeight:'28px',textAlign:'right',fontWeight:'bold',color:'#000',}}>申领备注：</div>
                                    <div className={'AddItemMainList'} style={{width:800,height:'auto',minHeight:'28px',textAlign:'left',}}>{this.state.AwardInfo.award_remark}</div>
                                </div>
                                <div className={'AddItemMainListBox clearfix'} style={{backgroundColor:'#fff',marginTop:'30px',height:'auto',minHeight:'28px',}}>
                                    <div className={'AddItemMainList'} style={{width:180,height:'auto',minHeight:'28px',textAlign:'right',fontWeight:'bold',color:'#000',}}>联系方式：</div>
                                    <div className={'AddItemMainList'} style={{width:800,height:'auto',minHeight:'28px',textAlign:'left',}}>
                                        <div className={'AddInfoMainCon'}>
                                            <input className={'AddInfoMainInput'}
                                                   type={'text'}
                                                   name={'SearchInput'}
                                                   value={this.state.phone}
                                                   onChange={this.phone.bind(this)}
                                                   placeholder={'请输入联系方式'}
                                                   autoComplete={'off'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'AddItemMainListBox clearfix'} style={{backgroundColor:'#fff',marginTop:'30px',height:'auto',minHeight:'28px',}}>
                                    <div className={'AddItemMainList'} style={{width:180,height:'auto',minHeight:'28px',textAlign:'right',fontWeight:'bold',color:'#000',}}>邮寄地址：</div>
                                    <div className={'AddItemMainList'} style={{width:800,height:'auto',minHeight:'28px',textAlign:'left',}}>
                                        <div className={'AddInfoMainCon'}>
                                            <input className={'AddInfoMainInput'}
                                                   type={'text'}
                                                   name={'SearchInput'}
                                                   value={this.state.address}
                                                   onChange={this.address.bind(this)}
                                                   placeholder={'请输入邮寄地址'}
                                                   autoComplete={'off'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'AddItemMainListBox clearfix'} style={{backgroundColor:'#fff',marginTop:'30px',height:'auto',minHeight:'28px',}}>
                                    <div className={'AddItemMainList'} style={{width:180,height:'auto',minHeight:'28px',textAlign:'right',fontWeight:'bold',color:'#000',}}>货到付款：</div>
                                    <div className={'AddItemMainList clearfix'} style={{width:800,height:'auto',minHeight:'28px',textAlign:'left',}}>
                                        <div className={'ActivityAskSelect clearfix'} style={{height:28,marginRight:20,marginLeft:'10px',}}>
                                            <div className={'ActivityAskIco'} style={{width:20,height:20,}} onClick={this.AskOk.bind(this)}>
                                                {
                                                    this.state.askSelect === true&&
                                                    <div className={'askSelectHave'} style={{width:12,height:12,}}> </div>
                                                }
                                            </div>
                                            <div className={'ActivityContentWord'} style={{height:26,lineHeight:'24px',fontSize:18,textIndent:'0.4em',}}>是</div>
                                        </div>
                                        <div className={'ActivityAskSelect clearfix'} style={{height:28,marginRight:20,}}>
                                            <div className={'ActivityAskIco'} style={{width:20,height:20,}} onClick={this.AskErr.bind(this)}>
                                                {
                                                    this.state.askSelect === false&&
                                                    <div className={'askSelectHave'} style={{width:12,height:12,}}> </div>
                                                }
                                            </div>
                                            <div className={'ActivityContentWord'} style={{height:26,lineHeight:'24px',fontSize:18,textIndent:'0.4em',}}>否</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'AddItemTitle'} style={{marginTop:'100px',}}>
                                    <div className={'AddItemSaveBtn'} onClick={this.AwardSave.bind(this)} style={{left:0,right:0,top:0,bottom:0,margin:'auto',}}>确认领取</div>
                                </div>
                            </div>
                        }
                        {
                            this.state.AwardType === 'ok'&&
                            <div className={'NewAppStepEndTitBox clearfix'} style={{paddingTop:180,}}>
                                <div className={'NewAppStepEndTitIco'}> </div>
                                <div className={'NewAppStepEndTitWord'}>已申领成功，请关注系统消息</div>
                            </div>
                        }
                        {
                            this.state.AwardType === 'pay'&&
                            <div>
                                <div className={'MoneyWord'}><i className={'weixinIco'}> </i>使用微信扫码支付</div>
                                <div className={'MoneyPhoto'} style={{width:192,height:192}}>
                                    <img
                                        className={'PayCodePhoto'}
                                        alt={''}
                                        src={API_URL+"/payment/WXpay_goods_order/"+this.state.order_id}
                                    />
                                </div>
                                <div className={'AltWord'}>*请稍等片刻生成二维码</div>
                                <div className={'AddressShow'} style={{marginTop:20,marginBottom:20,}}>
                                    如有问题请&nbsp;&nbsp;
                                    <span style={{color:'#ff0000',fontSize:16,cursor:'pointer',}} onClick={this.About.bind(this)}>联系我们</span>
                                    &nbsp;&nbsp;我们会及时回复！
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default Award;
