import React, { Component } from 'react';
import './showfile.css';
import {get} from "../../ajax/tools";


class Certificate extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :window.innerHeight,
            diplomaImg:'',
        };
    }
    componentDidMount=function() {
        let diplomaCode = this.props.match.params.diplomaCode;
        get({url:'reuse/show_diploma/'+diplomaCode}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    diplomaImg:res.data[0].image,
                });
            }else {
                alert('未知的查询错误：'+res.errno);
                window.close();
            }
        });
    };



    render() {
        return (
                <div className={'MainBox'} style={{minHeight:400,height:window.innerHeight-2,position:'relative',}}>
                    <div className={'CertificateBox'} style={{backgroundImage:'url('+this.state.diplomaImg+')',}}> </div>
                </div>
        );
    }
}


export default Certificate;
