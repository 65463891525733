import React, { Component } from 'react'
import './round.css'

class ReStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ReFormat: '',
      ReStepNum: '',
      ReStepBoxClass: '',
      ReStepOneName: '',
      ReStepTwoName: '',
      ReStepThreeName: '',
      ReStepFourName: '',
      ReStepStyle1: {},
      ReStepStyle2: {},
      ReStepStyle3: {},
      ReStepStyle4: {},
    }
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const history = nextProps
    const ReStepStyle = { color: '#FFFFFF' }
    if (history.ReStepNum === 1) {
      this.setState({
        ReStepBoxClass: 'ReStepBox1',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: {},
        ReStepStyle3: {},
        ReStepStyle4: {},
      })
    } else if (history.ReStepNum === 2) {
      this.setState({
        ReStepBoxClass: 'ReStepBox2',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: ReStepStyle,
        ReStepStyle3: {},
        ReStepStyle4: {},
      })
    } else if (history.ReStepNum === 3) {
      this.setState({
        ReStepBoxClass: 'ReStepBox3',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: ReStepStyle,
        ReStepStyle3: ReStepStyle,
        ReStepStyle4: {},
      })
    } else if (history.ReStepNum === 4) {
      this.setState({
        ReStepBoxClass: 'ReStepBox4',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: ReStepStyle,
        ReStepStyle3: ReStepStyle,
        ReStepStyle4: ReStepStyle,
      })
    }

    if (history.ReFormat === '个人身份证注册') {
      this.setState({
        ReStepOneName: '实名认证',
        ReStepTwoName: '填写信息',
        ReStepThreeName: '设置密码',
        ReStepFourName: '成功注册',
      })
    } else if (history.ReFormat === '个人其他证件注册') {
      this.setState({
        ReStepOneName: '实名认证',
        ReStepTwoName: '填写信息',
        ReStepThreeName: '设置密码',
        ReStepFourName: '人工审核',
      })
    } else if (history.ReFormat === '团体身份注册') {
      this.setState({
        ReStepOneName: '管理员信息',
        ReStepTwoName: '团体信息',
        ReStepThreeName: '设置密码',
        ReStepFourName: '成功注册',
      })
    }
  }
  componentDidMount = function () {
    const history = this.props
    const ReStepStyle = { color: '#FFFFFF' }
    if (history.ReStepNum === 1) {
      this.setState({
        ReStepBoxClass: 'ReStepBox1',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: {},
        ReStepStyle3: {},
        ReStepStyle4: {},
      })
    } else if (history.ReStepNum === 2) {
      this.setState({
        ReStepBoxClass: 'ReStepBox2',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: ReStepStyle,
        ReStepStyle3: {},
        ReStepStyle4: {},
      })
    } else if (history.ReStepNum === 3) {
      this.setState({
        ReStepBoxClass: 'ReStepBox3',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: ReStepStyle,
        ReStepStyle3: ReStepStyle,
        ReStepStyle4: {},
      })
    } else if (history.ReStepNum === 4) {
      this.setState({
        ReStepBoxClass: 'ReStepBox4',
        ReStepStyle1: ReStepStyle,
        ReStepStyle2: ReStepStyle,
        ReStepStyle3: ReStepStyle,
        ReStepStyle4: ReStepStyle,
      })
    }

    if (history.ReFormat === '个人身份证注册') {
      this.setState({
        ReStepOneName: '实名认证',
        ReStepTwoName: '填写信息',
        ReStepThreeName: '设置密码',
        ReStepFourName: '成功注册',
      })
    } else if (history.ReFormat === '个人其他证件注册') {
      this.setState({
        ReStepOneName: '实名认证',
        ReStepTwoName: '填写信息',
        ReStepThreeName: '设置密码',
        ReStepFourName: '人工审核',
      })
    } else if (history.ReFormat === '团体身份注册') {
      this.setState({
        ReStepOneName: '管理员信息',
        ReStepTwoName: '团体信息',
        ReStepThreeName: '设置密码',
        ReStepFourName: '成功注册',
      })
    }
  }

  render() {
    return (
      <div className={'RegisterStepBox ' + this.state.ReStepBoxClass}>
        <div
          className={'ReStepWordBox ReStepOne'}
          style={this.state.ReStepStyle1}
        >
          {this.state.ReStepOneName}
        </div>
        <div
          className={'ReStepWordBox ReStepTwo'}
          style={this.state.ReStepStyle2}
        >
          {this.state.ReStepTwoName}
        </div>
        <div
          className={'ReStepWordBox ReStepThree'}
          style={this.state.ReStepStyle3}
        >
          {this.state.ReStepThreeName}
        </div>
        <div
          className={'ReStepWordBox ReStepFour'}
          style={this.state.ReStepStyle4}
        >
          {this.state.ReStepFourName}
        </div>
      </div>
    )
  }
}

export default ReStep
