import React, { Component } from 'react';
import './showfile.css';


class Award extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight :window.innerHeight,
            awardUrl:'',
        };
    }
    componentDidMount=function() {
        let awardUrl = this.props.match.params.awardUrl;
        this.setState({
            awardUrl:'http://oss.asfcyy.com/matchcertif/'+awardUrl,
        });
    };



    render() {
        return (
                <div className={'MainBox'} style={{minHeight:900,height:window.innerHeight-2,position:'relative',}}>
                    <div className={'CertificateBox'} style={{backgroundImage:'url('+this.state.awardUrl+')',height:840,}}> </div>
                </div>
        );
    }
}


export default Award;
