import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import PeBase from "./PeBase";
import PeStudent from "./PeStudent";
import PeTeacher from "./PeTeacher";
import PeCounselor from "./PeCounselor";
import PeTeam from "./PeTeam";
// import PeData from "./PeData";
import Award from "./Award";
import PeYunCheng from "./PeYunCheng";
import PeYunChengVIP from "./PeYunChengVIP";
// import OldRealName from "./OldRealName";
import TeacherDetails from "../inquire/TeacherDetails";
import TrainDetails from "../inquire/TrainDetails";
import Six1 from "./Six1";
import PeVIP from "./Vip";
import PopUp from "../round/PopUp";
import './member.css';
import {get,} from "../../ajax/tools";




class Member extends Component {
    constructor(props){
        super(props);
        this.state = {
            winHeight :'',
            AccountType:'Personal',

            PageState:'member',
            awardType:'',

            User:{},
            NowPositionName:'',
            NowPositionNextName:'',


            PersonalArr:JSON.parse(sessionStorage.getItem('PersonalArr')),

            user_name:'',

        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }

        let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
        if (!tokenAuth) {
            sessionStorage.removeItem('tokenAuth');
            sessionStorage.removeItem('GroupArr');
            sessionStorage.removeItem('PersonalArr');
            window.location.reload();
        }else {
            this.setState({
                user_name:tokenAuth.user_name,
            });
        }
        let PersonalArr = JSON.parse(sessionStorage.getItem('PersonalArr'));
        if (!PersonalArr) {
            alert('请登录个人账号并进行实名认证！')
            sessionStorage.removeItem('tokenAuth');
            sessionStorage.removeItem('GroupArr');
            sessionStorage.removeItem('PersonalArr');
            window.location.reload();
        }

        let Route = this.props.location.pathname;
        let ThreeIndex = Route.lastIndexOf ("_");//'_'最后出现的位置

        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                let sb = Route.substring(ThreeIndex+1);
                if (sb === 'peBase'){
                    this.setState({
                        NowPositionName:'基础信息',
                    });
                }else if (sb === 'pestu'){
                    this.setState({
                        NowPositionName:'个人能力',
                        NowPositionNextName:'>技术等级',
                    });
                }else if (sb === 'peteam'){
                    this.setState({
                        NowPositionName:'飞行团队',
                        NowPositionNextName:'>竞技团队',
                    });
                }else if (sb === 'peda'){
                    this.setState({
                        NowPositionName:'训练数据',
                    });
                }else if (sb === 'pezz'){
                    this.setState({
                        NowPositionName:'组织管理',
                    });
                }else if (sb === 'peyc'){
                    this.setState({
                        NowPositionName:'云程服务',
                    });
                }else if (sb === 'pevip'){
                    this.setState({
                        NowPositionName:'VIP服务',
                    });
                }else if (sb === 'pesix'){
                    this.setState({
                        NowPositionName:'初教六激活',
                    });
                }else if (sb === 'pecou'){
                    if(User.isassist !== 0){
                        this.setState({
                            NowPositionName:'辅导员信息',
                        });
                    }else {
                        const {history} = this.props;
                        history.push('/member_peBase');
                    }

                }else if (sb === 'petea'){
                    if(User.iscoach !== 0){
                        this.setState({
                            NowPositionName:'教员信息',
                            NowPositionNextName:'>考核信息',
                        });
                    }else {
                        const {history} = this.props;
                        history.push('/member_peBase');
                    }

                }
                get({url:'user/sys_msg_show_read'}).then((res) => {
                    if(res.errno === '200'){
                        if (res.data === 0){
                            this.setState({
                                newMsg:'0',
                            });
                        }else {
                            this.setState({
                                PopUpSYS:'未读系统消息',
                                MessageTipSYS:'您有未读的系统消息！',
                            });
                        }
                    }
                });
                get({url:'user/query_award_info'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            PopUpTwo:'未申领证书',
                            MessageTipTwo:res.errmsg+'，是否领取？',
                        });
                    }
                });
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    }


    AllClick = () => {
        this.setState({
        });
    };


    //退出
    DropOut(){
        sessionStorage.removeItem('tokenAuth');
        sessionStorage.removeItem('GroupArr');
        sessionStorage.removeItem('PersonalArr');
        window.location.reload();
        this.setState({
        });
    };
    //密码修改
    ChangePassword(){
        const {history} = this.props;
        history.push('/forget');
    };

    //点击会员中心的左侧菜单
    AccountListClick=(item,index) =>{
        const {history} = this.props;
        history.push(item.router);
    };



    //vip点击返回
    BackVip=(event)=> {
        if (event.VipType !== '') {
            // console.log(event.VipType)
            sessionStorage.setItem('vip','pe');
            const {history} = this.props;
            history.push('/member_pevip');
        }
    }

    //下一级菜单组件点击返回
    ChildBackNavClick=(event)=>{
        if (event !== ''){
            this.setState({
                NowPositionNextName:'> '+event,
            });
        }else {
            this.setState({
                NowPositionNextName:'',
            });
        }
    };

    //点击子组件中的返回按钮
    BackButton=(event)=>{
        if (event === 'BackBtn'){
            this.setState({
                PageState:'member',
                TeacherId:''
            });
        }
    };




    //个人个人能力点击教练员查看详情
    BackCoachDetails=(event)=>{
        if(event.coach_id){
            this.setState({
                PageState:'coachDetails',
                TeacherId:event.coach_id,
            });
        }
    };
    //教练员信息点击查看机构详情
    BackTrainDetails=(event)=>{
        this.setState({
            PageState:'trainDetails',
            TrainId:event.id,
        });
    };


    ConfirmButton=(event)=>{
        if (event === '实名认证'){
            this.setState({
                PageState:'member',
            });
        }
    };



    //弹窗点击的逻辑处理
    ChildBackTwoClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUpTwo:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUpTwo:'',
                awardType:'未申领证书',
            });
        }
    };
    ChildBackSYSClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUpSYS:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUpSYS:'',
            });
            const {history} = this.props;
            history.push('/message');
        }
    };


    AwardButton=(event)=>{
        if (event.BackType === '关闭弹窗'){
            this.setState({
                awardType:'',
                PopUpTwo:'',
            });
            get({url:'user/query_award_info'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        PopUpTwo:'未申领证书',
                        MessageTipTwo:res.errmsg+'，是否领取？',
                    });
                }
            });
        }
    };

    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };




    render() {
        return (
                <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                    <Header/>
                    {
                        this.state.PageState === 'member'&&
                        <div className={'MainContent'}>
                            <div className={'MemberTopInfo clearfix'}>
                                <div className={'NowUserName MemberTopInfoWord'}>当前用户：{this.state.user_name}</div>
                                <div className={'MemberTopTitle MemberTopInfoWord'}>欢迎使用中国航协模拟飞行服务平台</div>
                                <div className={'MemberTopRight'}>
                                    <div className={'DropOut'} onClick={this.DropOut.bind(this)}>退出</div>
                                    <div className={'ChangePassword'} onClick={this.ChangePassword.bind(this)}>密码修改</div>
                                </div>
                            </div>
                            <div className={'MemberMainBox clearfix'}>
                                <div className={'MemberMainBoxLeft'} style={{height:window.innerHeight-137}}>
                                    <div style={{width:194,height:42,backgroundColor:'0488CA',}}> </div>
                                    <div className={'MemberMainBoxLeft clearfix scrollBarTwo'} style={{height:window.innerHeight-180}}>
                                        {
                                            this.state.PersonalArr&&
                                            <ul className={'MemberUList'}>
                                                {
                                                    this.state.PersonalArr.map((item,index) =>
                                                        <li className={'MemberList'}
                                                            key={index}
                                                            style={item.nav === this.state.NowPositionName ? {backgroundColor:'#FFFFFF',borderLeftColor:'#EB6100',color:'#000000'}:{}}
                                                            onClick={() => this.AccountListClick(item,index)}
                                                        >
                                                            {item.nav}
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        }
                                    </div>
                                </div>
                                <div className={'MemberMainBoxRight'} style={{height:window.innerHeight-137}}>
                                    <div className={'MemberMainBoxTop'}>您当前位置：会员中心 > {this.state.NowPositionName} {this.state.NowPositionNextName}</div>
                                    {
                                        this.state.NowPositionName === '基础信息'&& this.state.AccountType === 'Personal'&&
                                        <PeBase
                                            BackVip={this.BackVip.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '个人能力'&& this.state.AccountType === 'Personal'&&
                                        <PeStudent
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                            BackCoachDetails={this.BackCoachDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '教员信息'&& this.state.AccountType === 'Personal'&&
                                        <PeTeacher
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                            BackTrainDetails={this.BackTrainDetails.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '辅导员信息'&& this.state.AccountType === 'Personal'&&
                                        <PeCounselor ChildBackNavClick={this.ChildBackNavClick.bind(this)}/>
                                    }
                                    {
                                        this.state.NowPositionName === '飞行团队'&& this.state.AccountType === 'Personal'&&
                                        <PeTeam ChildBackNavClick={this.ChildBackNavClick.bind(this)}/>
                                    }
                                    {/*{*/}
                                    {/*    this.state.NowPositionName === '训练数据'&& this.state.AccountType === 'Personal'&&*/}
                                    {/*    <PeData/>*/}
                                    {/*}*/}
                                    {
                                        this.state.NowPositionName === '组织管理'&& this.state.AccountType === 'Personal'&&
                                        <PeYunCheng/>
                                    }
                                    {
                                        this.state.NowPositionName === '云程服务'&& this.state.AccountType === 'Personal'&&
                                        <PeYunChengVIP
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === 'VIP服务'&& this.state.AccountType === 'Personal'&&
                                        <PeVIP
                                            ChildBackNavClick={this.ChildBackNavClick.bind(this)}
                                        />
                                    }
                                    {
                                        this.state.NowPositionName === '初教六激活'&&
                                        <Six1/>
                                    }



                                    {/*{*/}
                                    {/*    this.state.NowPositionName === '会员福利'&& this.state.AccountType === 'Personal'&&*/}
                                    {/*    <PeBenefits/>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.PageState === 'coachDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>教员查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>INSTRUCTOR</div>
                            </div>
                            <TeacherDetails
                                TeacherId={this.state.TeacherId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    {
                        this.state.PageState === 'trainDetails'&&
                        <div className={'MainContent'}>
                            <div className={'MainTopBox clearfix'}>
                                <div className={'MainConTitle'}>
                                    <div className={'MainConTitZH'}>训练中心查询服务</div>
                                    <div className={'MainConTitEN'}>inquiry&nbsp;&nbsp;service</div>
                                </div>
                                <div className={'MainConTitBorder'}> </div>
                                <div className={'MainActiveListTitle'}>training center</div>
                            </div>
                            <TrainDetails
                                TrainId={this.state.TrainId}
                                BackButton={this.BackButton.bind(this)}
                            />
                        </div>
                    }
                    {/*{*/}
                    {/*    this.state.PageState === 'OldRealName'&&*/}
                    {/*    <div className={'MainContent'}>*/}
                    {/*        <OldRealName*/}
                    {/*            ConfirmButton={this.ConfirmButton.bind(this)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                    {
                        this.state.PopUpTwo === '未申领证书' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTwoClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTipTwo}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.awardType === '未申领证书' &&
                        <Award
                            AwardButton={this.AwardButton.bind(this)}
                        />
                    }
                    {
                        this.state.PopUpSYS=== '未读系统消息' &&
                        <PopUp
                            ChildBackClick={this.ChildBackSYSClick.bind(this)}
                            type={'失败'}
                            message={this.state.MessageTipSYS}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Member;
