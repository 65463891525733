import React, {Component} from 'react';
import './inquire.css';
import {get} from "../../ajax/tools";


class TeacherDetails2 extends Component {

    constructor(props){
        super(props);
        this.state = {
            TeacherId:'',
            teacherUser:{},
            TeacherLevelArr:[],
            TeacherPhone:'',
            TeacherMail:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        this.setState({
            TeacherId:nextProps.TeacherId,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        get({url:'reuse/user_id_show_diploma/'+history.TeacherId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeacherLevelArr:res.data,
                });
            }
        });
        get({url:'reuse/user_id_info_tuomin/'+history.TeacherId}).then((res) => {
            if(res.errno === '200'){
                let teacherUser = res.data[0];
                this.setState({
                    teacherUser:teacherUser,
                    TeacherMail:teacherUser.email.replace(/.+(?=@)/g, '**********'),
                });
            }
        });
        this.setState({
            TeacherId:history.TeacherId,
        });
    };


    BackButton=()=>{
        this.props.BackButton('BackBtn');
    };


    //点击教练员证书详情
    ActiveTeacherLevel=(item,index)=>{
        let diplomaCode = item.diploma_code;
        window.open('/certificate/'+diplomaCode);
    };



    render() {
        return (
                <div className={'DetailsPackageBox'} style={{minHeight:window.innerHeight-194 > 500 ? window.innerHeight-194 :500,}}>
                    <div className={'DetailsPackageTop'}>
                        <div className={'DetailsBackBox clearfix'} onClick={this.BackButton.bind(this)}>
                            <div className={'DetailsBackIco'}> </div>
                            <div className={'DetailsBackWord'}>返回</div>
                        </div>
                    </div>
                    <div className={'DetailsMainBox clearfix'}>
                        <div className={'TeacherDetailsLeft'}>
                            <div className={'TeacherPhotoBox'}>
                                <img alt={''}
                                     style={{height:'auto',}}
                                     src={'http://oss.asfcyy.com/richTextImage/45db6cd67bf111eb87c4000c29ac4e4a.png'}
                                />
                            </div>
                        </div>
                        <div className={'TeacherDetailsRight'}>
                            <div className={'TeacherDetailsTopTit'}>
                                <div className={'DetailsTopTitWord'}>教练员信息</div>
                                <div className={'DetailsTopTitBorder'}> </div>
                            </div>
                            <div className={'PublicInfoBox clearfix'}>
                                <div className={'PublicInfoLeft'}>
                                    <div className={'PublicInfoList clearfix'}>
                                        <div className={'TeacherInfoTit'}>姓名(ID号)</div>
                                        <div className={'TeacherInfoCon'}>{this.state.teacherUser.real_name}（ID:{this.state.teacherUser.yy_num}）</div>
                                    </div>
                                    <div className={'PublicInfoList clearfix'}>
                                        <div className={'TeacherInfoTit'}>出生日期</div>
                                        <div className={'TeacherInfoCon'}>{this.state.teacherUser.birth}</div>
                                    </div>
                                    <div className={'PublicInfoList clearfix'}>
                                        <div className={'TeacherInfoTit'}>性别</div>
                                        <div className={'TeacherInfoCon'}>{this.state.teacherUser.gender}</div>
                                    </div>
                                    <div className={'PublicInfoList clearfix'}>
                                        <div className={'TeacherInfoTit'}>手机号</div>
                                        <div className={'TeacherInfoCon'}>{this.state.teacherUser.mobile}</div>
                                    </div>
                                    <div className={'PublicInfoList clearfix'}>
                                        <div className={'TeacherInfoTit'}>邮箱</div>
                                        <div className={'TeacherInfoCon'}>{this.state.teacherUser.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'TeacherDetailsTopTit'}>
                                <div className={'DetailsTopTitWord'}>等级证书</div>
                                <div className={'DetailsTopTitBorder'}> </div>
                            </div>
                            <div className={'PublicInfoBox clearfix'} style={{display:'table',width:'auto',margin:'0 auto',}}>
                                {
                                    this.state.TeacherLevelArr.map((item,index) =>
                                        <div className={'TeacherCardListBox'} key={index} onClick={() => this.ActiveTeacherLevel(item,index)}>
                                            <div className={'TeacherCardBox'}>
                                                <img alt={''}
                                                     src={item.image}
                                                />
                                            </div>
                                            <div className={'TeacherLevelWord'}>{item.diploma_name}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}


export default TeacherDetails2;
