import React, { Component } from 'react';
import './game.css';
import PopUp from "../round/PopUp";
import Triangle from "../round/Triangle";
import {get, post} from "../../ajax/tools";


class GGItem extends Component {

    constructor(props){
        super(props);
        this.state = {
            MatchId:'',
            GGWindowId:'',
            UserInfoAndItemArr:[],
            ProjectArr: [],//项目总数据
            ProjectMaxNum: null,//最多兼项数目
            GroupArr:[],//组别
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        let GroupProjectArr = history.GroupProjectArr;
        let MatchId = history.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                post({
                    url:'match/show_team_stu',
                    data:{
                        match_num_id:MatchId,
                        team_id:history.GGWindowId,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        for(let i=0;i<res.data.length;i++){
                            res.data[i]['ProjectBtn'] = 'false';
                        }
                        this.setState({
                            UserInfoAndItemArr:res.data,
                        });
                    }
                });
            }
        });
        get({url:'match/get_groups/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                this.setState({GroupArr:res.data,});
                for(let i=0;i<res.data.length;i++){
                   let magId = res.data[i].mag_id;
                    post({
                        url:'match/get_item',
                        data:{
                            match_id:MatchId,
                            mag_id:magId,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            this.setState({ProjectMaxNum:res.allow_item_num,});
                        }
                    });
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
        this.setState({
            ProjectArr:GroupProjectArr,
            MatchId:history.MatchId,
        });
    };
    componentDidMount=function() {
        let a = this;
        document.onclick=a.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        let UserInfoAndItemArr = this.state.UserInfoAndItemArr;
        for(let i=0;i<UserInfoAndItemArr.length;i++){
            UserInfoAndItemArr[i]['ProjectBtn'] = 'false';
        }
        this.setState({
            UserInfoAndItemArr:UserInfoAndItemArr,
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };






    ClickProjectAdd=(item,index,e)=>{
        e.nativeEvent.stopImmediatePropagation();
        let UserInfoAndItemList = this.state.UserInfoAndItemArr;
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    for(let i=0;i<UserInfoAndItemList.length;i++){
                        UserInfoAndItemList[i]['ProjectBtn'] = 'false';
                    }
                    this.setState({
                        UserInfoAndItemArr:UserInfoAndItemList,
                    });
                    UserInfoAndItemList[index]['ProjectBtn'] = (item.ProjectBtn === 'false' ?  'true' : 'false');
                    this.setState({
                        UserInfoAndItemArr:UserInfoAndItemList,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };



    ClickProjectArr=(item,index,ProjectItem,ProjectIndex) => {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let User = res.data[0];
                if(User.type === 1){
                    let ProjectBrr=item.item;
                    let ProjectMaxNum = this.state.ProjectMaxNum;
                    let ProjectTest = [];
                    let ProjectTrr = [];
                    for(let i=0;i<ProjectBrr.length;i++){
                        let obList = ProjectBrr[i].mai_id;
                        let ProjectType = ProjectBrr[i].is_concurrent;//兼项
                        if (ProjectType === 1) {
                            ProjectTrr.push(ProjectBrr[i])
                        }
                        ProjectTest.push(obList)
                    }
                    let UserProjectIdListArr = [];
                    let UserInfoAndItemList = this.state.UserInfoAndItemArr;
                    for(let i=0;i<UserInfoAndItemList.length;i++){
                        let UserInfoProjectList = UserInfoAndItemList[i].item;
                        for(let i=0;i<UserInfoProjectList.length;i++){
                            let UserInfoProjectIdList = UserInfoProjectList[i].mai_id;
                            UserProjectIdListArr.push(UserInfoProjectIdList);
                        }
                    }
                    function  statisticalFieldNumber(UserProjectIdListArr) {//存在加1
                        return UserProjectIdListArr.reduce(function (prev, next) {
                            prev[next] = (prev[next] + 1) || 1;
                            return prev;
                        }, {});
                    }
                    let UserProjectIdCount = statisticalFieldNumber(UserProjectIdListArr);
                    let ProjectMaxPeo = ProjectItem.allow_man_num;
                    //console.log(UserProjectIdCount[ProjectItem.id])//存在几个
                    if (UserProjectIdCount[ProjectItem.id]){
                        if (UserProjectIdCount[ProjectItem.id] < ProjectMaxPeo){
                            if(ProjectBrr.length === 0){
                                const Projects = {};
                                Projects['item_name'] = ProjectItem.name;
                                Projects['item_id'] = ProjectItem.item_id;
                                Projects['is_concurrent'] = ProjectItem.is_concurrent;
                                Projects['mai_id'] = ProjectItem.id;
                                post({
                                    url:'match/school_add_stu_item',
                                    data:{
                                        item_id:ProjectItem.item_id,
                                        enroll_id:item.id,
                                        user_id:item.user_id,
                                        team_id:item.team_id,
                                        match_id:item.match_id,
                                        allow_item_num:ProjectMaxNum,
                                        mai_id:ProjectItem.id,
                                    },
                                }).then((res) => {
                                    if(res.errno === '200'){
                                        Projects['id'] = res.new_id;
                                        ProjectBrr.push(Projects);
                                        UserInfoAndItemList[index] = item;
                                        let ClickInfo = {BackType:'添加项目',UsersInfo:UserInfoAndItemList,};
                                        this.props.GGItem(ClickInfo);
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:res.errmsg+'！',
                                        });
                                    }
                                });
                            }else {
                                if (ProjectTrr.length < ProjectMaxNum){
                                    if(item.group_id === ProjectItem.group_id){
                                        if(ProjectTest.includes(ProjectItem.name)){
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:'此人员已经选择该项目，不要重复选择！',
                                            });
                                        }else {
                                            const Projects = {};
                                            Projects['item_name'] = ProjectItem.name;
                                            Projects['item_id'] = ProjectItem.item_id;
                                            Projects['is_concurrent'] = ProjectItem.is_concurrent;
                                            Projects['mai_id'] = ProjectItem.id;
                                            post({
                                                url:'match/school_add_stu_item',
                                                data:{
                                                    item_id:ProjectItem.item_id,
                                                    enroll_id:item.id,
                                                    user_id:item.user_id,
                                                    team_id:item.team_id,
                                                    match_id:item.match_id,
                                                    allow_item_num:ProjectMaxNum,
                                                    mai_id:ProjectItem.id,
                                                },
                                            }).then((res) => {
                                                if(res.errno === '200'){
                                                    Projects['id'] = res.new_id
                                                    ProjectBrr.push(Projects);
                                                    UserInfoAndItemList[index] = item;
                                                    let ClickInfo = {BackType:'添加项目',UsersInfo:UserInfoAndItemList,};
                                                    this.props.GGItem(ClickInfo);
                                                }else {
                                                    this.setState({
                                                        PopUp:'提示弹窗',
                                                        MessageTip:res.errmsg+'！',
                                                    });
                                                }
                                            });
                                        }
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'此项目与您已选项目组别冲突！',
                                        });
                                    }
                                }else {
                                    if(item.is_concurrent !== 1){
                                        if(ProjectTest.includes(ProjectItem.name)){
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:'此人员已经选择该项目，不要重复选择！',
                                            });
                                        }else {
                                            const Projects = {};
                                            Projects['item_name'] = ProjectItem.name;
                                            Projects['item_id'] = ProjectItem.item_id;
                                            Projects['is_concurrent'] = ProjectItem.is_concurrent;
                                            Projects['mai_id'] = ProjectItem.id;
                                            post({
                                                url:'match/school_add_stu_item',
                                                data:{
                                                    item_id:ProjectItem.item_id,
                                                    enroll_id:item.id,
                                                    user_id:item.user_id,
                                                    team_id:item.team_id,
                                                    match_id:item.match_id,
                                                    allow_item_num:ProjectMaxNum,
                                                    mai_id:ProjectItem.id,
                                                },
                                            }).then((res) => {
                                                if(res.errno === '200'){
                                                    Projects['id'] = res.new_id
                                                    ProjectBrr.push(Projects);
                                                    UserInfoAndItemList[index] = item;
                                                    let ClickInfo = {BackType:'添加项目',UsersInfo:UserInfoAndItemList,};
                                                    this.props.GGItem(ClickInfo);
                                                }else {
                                                    this.setState({
                                                        PopUp:'提示弹窗',
                                                        MessageTip:res.errmsg+'！',
                                                    });
                                                }
                                            });
                                        }
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'此比赛活动最多只能参加'+ProjectMaxNum+'项！',
                                        });
                                    }
                                }
                            }
                        }else {
                            this.setState({
                                PopUp:'提示弹窗',
                                MessageTip:'此项目的参赛人员已经达到上限!',
                            });
                        }
                    }else {
                        if(ProjectBrr.length === 0){
                            const Projects = {};
                            Projects['item_name'] = ProjectItem.name;
                            Projects['item_id'] = ProjectItem.item_id;
                            Projects['is_concurrent'] = ProjectItem.is_concurrent;
                            Projects['mai_id'] = ProjectItem.id;
                            post({
                                url:'match/school_add_stu_item',
                                data:{
                                    item_id:ProjectItem.item_id,
                                    enroll_id:item.id,
                                    user_id:item.user_id,
                                    team_id:item.team_id,
                                    match_id:item.match_id,
                                    allow_item_num:ProjectMaxNum,
                                    mai_id:ProjectItem.id,
                                },
                            }).then((res) => {
                                if(res.errno === '200'){
                                    Projects['id'] = res.new_id
                                    ProjectBrr.push(Projects);
                                    UserInfoAndItemList[index] = item;
                                    let ClickInfo = {BackType:'添加项目',UsersInfo:UserInfoAndItemList,};
                                    this.props.GGItem(ClickInfo);
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:res.errmsg+'！',
                                    });
                                }
                            });
                        }else {
                            if (ProjectTrr.length < ProjectMaxNum){
                                if(item.group_id === ProjectItem.group_id){
                                    if(ProjectTest.includes(ProjectItem.name)){
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'此人员已经选择该项目，不要重复选择！',
                                        });
                                    }else {
                                        const Projects = {};
                                        Projects['item_name'] = ProjectItem.name;
                                        Projects['item_id'] = ProjectItem.item_id;
                                        Projects['is_concurrent'] = ProjectItem.is_concurrent;
                                        Projects['mai_id'] = ProjectItem.id;
                                        post({
                                            url:'match/school_add_stu_item',
                                            data:{
                                                item_id:ProjectItem.item_id,
                                                enroll_id:item.id,
                                                user_id:item.user_id,
                                                team_id:item.team_id,
                                                match_id:item.match_id,
                                                allow_item_num:ProjectMaxNum,
                                                mai_id:ProjectItem.id,
                                            },
                                        }).then((res) => {
                                            if(res.errno === '200'){
                                                Projects['id'] = res.new_id
                                                ProjectBrr.push(Projects);
                                                UserInfoAndItemList[index] = item;
                                                let ClickInfo = {BackType:'添加项目',UsersInfo:UserInfoAndItemList,};
                                                this.props.GGItem(ClickInfo);
                                            }else {
                                                this.setState({
                                                    PopUp:'提示弹窗',
                                                    MessageTip:res.errmsg+'！',
                                                });
                                            }
                                        });
                                    }
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'此项目与您已选项目组别冲突！',
                                    });
                                }
                            }else {
                                if(item.is_concurrent !== 1){
                                    if(ProjectTest.includes(ProjectItem.name)){
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'此人员已经选择该项目，不要重复选择！',
                                        });
                                    }else {
                                        const Projects = {};
                                        Projects['item_name'] = ProjectItem.name;
                                        Projects['item_id'] = ProjectItem.item_id;
                                        Projects['is_concurrent'] = ProjectItem.is_concurrent;
                                        Projects['mai_id'] = ProjectItem.id;
                                        post({
                                            url:'match/school_add_stu_item',
                                            data:{
                                                item_id:ProjectItem.item_id,
                                                enroll_id:item.id,
                                                user_id:item.user_id,
                                                team_id:item.team_id,
                                                match_id:item.match_id,
                                                allow_item_num:ProjectMaxNum,
                                                mai_id:ProjectItem.id,
                                            },
                                        }).then((res) => {
                                            if(res.errno === '200'){
                                                ProjectBrr.push(Projects);
                                                UserInfoAndItemList[index] = item;
                                                let ClickInfo = {BackType:'添加项目',UsersInfo:UserInfoAndItemList,};
                                                this.props.GGItem(ClickInfo);
                                            }else {
                                                this.setState({
                                                    PopUp:'提示弹窗',
                                                    MessageTip:res.errmsg+'！',
                                                });
                                            }
                                        });
                                    }
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'此比赛活动最多只能参加'+ProjectMaxNum+'项！',
                                    });
                                }
                            }
                        }
                    }
                }else if (User.type === 0) {
                    this.setState({
                        PopUp:'账号被顶',
                        MessageTip:'当前设备已登录其他账户，请重新登录！',
                    });
                }
            }else {
                sessionStorage.setItem('routesAddress','/game');
                const {history} = this.props;
                history.push('/login');
            }
        });
    };



    //删除已选择的项目
    ClickProjectDelete=(UserInfoItem,UserInfoIndex,ProjectItem,ProjectIndex) => {
        let MatchId = this.state.MatchId;
        get({url:'match/query_school_enroll_team_info/'+MatchId}).then((res) => {
            if(res.errno === '200'){
                let is_editable = res.data[0].is_editable;
                if (is_editable === 1){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            let User = res.data[0];
                            if(User.type === 1){
                                let dataSource = UserInfoItem.item;
                                if (dataSource.length > 1){
                                    get({url:'match/school_del_stu_item/'+ProjectItem.id+'/'+UserInfoItem.match_id}).then((res) => {
                                        if(res.errno === '200'){
                                            dataSource.splice(ProjectIndex, 1);
                                            let UserInfoAndItemList = this.state.UserInfoAndItemArr;
                                            UserInfoAndItemList[UserInfoIndex] = UserInfoItem;
                                            let ClickInfo = {BackType:'删除项目',UsersInfo:UserInfoAndItemList,};
                                            this.props.GGItem(ClickInfo);
                                        }else {
                                            this.setState({
                                                PopUp:'提示弹窗',
                                                MessageTip:res.errmsg+'！',
                                            });
                                        }
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'每位运动员最少要参与一个项目！',
                                    });
                                }
                            }else if (User.type === 0) {
                                this.setState({
                                    PopUp:'账号被顶',
                                    MessageTip:'当前设备已登录其他账户，请重新登录！',
                                });
                            }
                        }else {
                            sessionStorage.setItem('routesAddress','/game');
                            const {history} = this.props;
                            history.push('/login');
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'已锁定报名操作，如有疑问请联系我们！',
                    });
                }
            }
        });
    };







    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };
    AccountNext=(event)=>{
        if(event === 'CrossOut'){
            window.location.reload();
        }else if(event === 'Confirm'){
            window.location.reload();
        }
    };

    render() {
        //console.log(this.state.ProjectArr)
        return (
                <div className={'GGAddPeopleBox'} style={{minHeight:520,}}>
                    <div className={'GGItemPerBox'}>
                        <div className={'GGItemPerTitle'}>分配运动员</div>
                        <div className={'GGItemPerConBox scrollBar clearfix'}>
                            {
                                this.state.UserInfoAndItemArr.map((item,index)=>
                                    <div key={index}>
                                        {
                                            item.IdtShow === '运动员'&&
                                            <div className={'GGItemPerConList clearfix'}>
                                                <div className={'GGItemPerName'}>{item.real_name}</div>
                                                <div className={'GGItemPerNum'}>({item.yy_num})</div>
                                                <div className={'GGItemPerItemNum'}>【{item.item.length}项】</div>
                                                <div className={'GGItemPerItemBtn'} onClick={(e) => this.ClickProjectAdd(item,index,e)}>
                                                    选择科目
                                                    <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'5px'} Height={'8px'} Top={'7px'} Right={'10px'}/>
                                                </div>
                                                {
                                                    item.ProjectBtn === 'true' &&
                                                    <div className={'GPProjectSelect scrollBar'} style={{right:20,left:'unset',width:282,maxHeight:180,}}>
                                                        {
                                                            this.state.ProjectArr.map((ProjectItem,ProjectIndex)=>
                                                                <div className={'GPProjectOption'}
                                                                     style={{width:272,fontSize:12,}}
                                                                     key={ProjectIndex}
                                                                     onClick={() => this.ClickProjectArr(item,index,ProjectItem,ProjectIndex)}>
                                                                    {ProjectItem.MixingName}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={'GGItemArrBox clearfix'}>
                        {
                            this.state.ProjectArr.map((item,index)=>
                                <div className={'GGItemSubjectBox'} key={index}>
                                    <div className={'GGItemSubTitle'}>
                                        {item.MixingName}(<span style={{color:'#ff0000',}}> {'<'+(item.allow_man_num+1)} </span>)
                                    </div>
                                    <div className={'GGItemSubMainBox scrollBar'}>
                                        {
                                            this.state.UserInfoAndItemArr.map((UserInfoItem,UserInfoIndex)=>
                                                <div key={UserInfoIndex}>
                                                    {
                                                        UserInfoItem.item.map((ProjectItem,ProjectIndex)=>
                                                            <div key={ProjectIndex}>
                                                                {
                                                                    item.id === ProjectItem.mai_id && UserInfoItem.IdtShow === '运动员'&&
                                                                    <div className={'GGItemSubListBox clearfix'}>
                                                                        <div className={'GGItemPerName'} style={{width:140,}}>{UserInfoItem.real_name}</div>
                                                                        <div className={'GGItemPerNum'} style={{width:66,}}>{UserInfoItem.yy_num}</div>
                                                                        <div className={'GPProjectSelectDelete'}
                                                                             style={{float:'left',margin:'4px 30px',}}
                                                                             onClick={() => this.ClickProjectDelete(UserInfoItem,UserInfoIndex,ProjectItem,ProjectIndex)}
                                                                        > </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '账号被顶' &&
                        <PopUp
                            ChildBackClick={this.AccountNext.bind(this)}
                            type={'失败'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                </div>
        );
    }
}


export default GGItem;
