import React, { Component } from 'react';
import Pagination from "../round/Pagination";
import './download.css';
import {get,} from "../../ajax/tools";


class DownloadMap extends Component {

    constructor(props){
        super(props);
        this.state = {
            DownloadListArr:[],
            DownloadListArrLength: 0,
            DownloadListBrr:[],
            PageSize:'',
            CurrentPage: 1,

        };
    }
    componentDidMount=function() {
        let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
        let PageSize = Math.ceil(H/52)
        get({url:"news/about_download"}).then(res => {
            if(res.errno === '200'){
                let KeyStart = (this.state.CurrentPage-1)*PageSize;
                let KeyEnd = this.state.CurrentPage*PageSize;
                let DownloadList = [];
                if(KeyEnd <= res.data.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const DownloadListBrr = res.data[i];
                        DownloadList.push(DownloadListBrr)
                    }
                }else {
                    for(let i=KeyStart; i<res.data.length; i++){
                        const DownloadListBrr = res.data[i];
                        DownloadList.push(DownloadListBrr)
                    }
                }
                this.setState({
                    DownloadListArr:res.data,
                    DownloadListBrr:DownloadList,
                    DownloadListArrLength:res.data.length,
                    PageSize:PageSize,
                });
            }else {
                this.setState({
                    DownloadListArr:[],
                });
            }
        });
    };



    DownloadCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const DownloadList = [];
        if(KeyEnd <= this.state.DownloadListArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const DownloadBList = this.state.DownloadListArr[i];
                DownloadList.push(DownloadBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.DownloadListArr.length; i++){
                const DownloadBList = this.state.DownloadListArr[i];
                DownloadList.push(DownloadBList)
            }
        }
        this.setState({
            DownloadListBrr:DownloadList,
        });
    }






    DownloadInfoList=(item,index)=>{
        let a = document.createElement('a');
        a.setAttribute('href',item.real_url);
        a.setAttribute('download',item.name);
        a.setAttribute('target','_blank');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
        //let NewsInfoId = index;
        //window.open(item,'download');
    };






    render() {
        return (
            <div>
                <div className={'InfoListBox'} style={{height:window.innerHeight-340 > 360 ? window.innerHeight-340 :360,}}>
                    {
                        this.state.DownloadListBrr.map((item,index) =>
                            <div className={'InfoList'} key={index} onClick={() => this.DownloadInfoList(item,index)}>
                                <div className={'InfoListKey'} style={{width:92,}}>[<span style={{color:'#ff0000',}}>点击下载</span>]</div>
                                <div className={'InfoListTitle'} style={{width:470,}}>{item.name.substring(0,item.name.lastIndexOf('.'))}</div>
                                <div className={'InfoListCenter'}>············</div>
                                <div className={'InfoListTime'} style={{width:118,}}>{item.ctime}</div>
                            </div>
                        )
                    }
                </div>
                <div className={'InfoListBorder'} style={{marginBottom:10,}}> </div>
                {
                    this.state.DownloadListArrLength !== 0 &&
                    <Pagination
                        TotalSize={this.state.DownloadListArrLength}
                        PageSize={this.state.PageSize}
                        pageCallbackFn={this.DownloadCurrentPage.bind(this)}
                    />
                }
            </div>
        );
    }
}


export default DownloadMap;
