import React, {Component} from 'react';
import eCharts from 'echarts/lib/echarts';
import 'echarts/lib/chart/radar';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import './member.css';
import {post} from "../../ajax/tools";

class HistoryECharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HistoryRadarInfo:{},
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        this.setState({
            HistoryRadarInfo:nextProps.HistoryRadarInfo,
        });
        // console.log(sbData)
    };

    componentDidMount=function() {
        let history = this.props;
        this.setState({
            HistoryRadarInfo:history.HistoryRadarInfo,
        });
        let sbData = history.HistoryRadarInfo;
        // console.log(sbData)
        // 基于准备好的dom，初始化echarts实例
        post({
            url:'cloudprogram/stu_score_analy_pentagon_five',
            data:{
                item_id:sbData.item_id,
                tts_id:sbData.tts_id,
            },
        }).then((res) => {
            if (res.errno === '200') {
                let data = res.data;
                let a = [];
                for(let i=0;i<data.length;i++){
                    let q = data[i].value;
                    let b = Math.floor(q*1000)/1000;
                    a.push(b);
                    data[i]['max'] = data[i].max_constraint;
                    data[i].name = data[i].name.substring(0,2);
                }
                let Radar = {
                    info : data,
                    value : a,
                };
                this.setState({RadarFiveInfo:Radar,});
                let myRadarChart = eCharts.init(document.getElementById('RadarMainFive'),<span> </span>,{height:340,},);
                // 绘制图表
                myRadarChart.setOption({
                    title: {
                        text: '五边得分率分析',
                        left: 'center',
                        textStyle: {
                            color: '#2f4554',
                            marginBottom:'8px',
                            fontSize: 16,
                        }
                    },
                    legend: {
                        show:false,
                    },
                    radar: {
                        indicator: Radar.info,
                        shape: 'circle',
                        center: ['50%', '55%'],
                        radius: 100,
                        name: {
                            color:'#222',
                            fontSize: 12,
                            fontWeight:'500',
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(102, 102, 102, 0.5)'
                            }
                        },
                        silent: false ,
                        splitNumber: 4,
                        splitLine: {
                            lineStyle: {
                                color: [
                                    'rgba(102, 102, 102, 0.2)',
                                    'rgba(102, 102, 102, 0.4)',
                                    'rgba(102, 102, 102, 0.6)',
                                    'rgba(102, 102, 102, 0.8)',
                                    'rgba(102, 102, 102, 1)'
                                ].reverse()
                            }
                        },
                        splitArea: {
                            show: false
                        },
                    },
                    series: [
                        {
                            type: 'radar',
                            data: [
                                {
                                    symbol: 'circle',
                                    symbolSize: 10,
                                    value: Radar.value,
                                    label: {
                                        show: true,
                                        formatter: function (params) {
                                            return params.value+'%';
                                        },
                                        color:'#222',
                                        position:'bottom',
                                        fontSize:12,
                                        fontWeight:'bold',
                                    },
                                    lineStyle: {
                                        type: 'solid'
                                    },

                                },
                            ],
                            itemStyle: {
                                color: '#DB6464'
                            },
                            areaStyle: {
                                opacity: 0.3
                            }
                        },
                    ]
                });
            }else {
                this.setState({
                    tts_id:'noData',
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };




    AllClick = () => {
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };





    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };




    render() {
        // console.log(this.state)
        return (
            <div className={'EChartsBox'} style={{float:'left',minWidth:300,}}>
                <div id={'RadarMainFive'} style={{width:300,}}> </div>
            </div>
        );
    }
}

export default HistoryECharts;
