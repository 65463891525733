import React, { Component } from 'react';
import Pagination from "../round/Pagination";
import './game.css';
import {get} from "../../ajax/tools";
import PopUp from "../round/PopUp";

class GameYear extends Component {

    constructor(props){
        super(props);
        this.state = {
            GameYearArr:[],
            GameYearType:'',
            YearNum:null,

            GameYearListArr:[],
            GameYearListArrLength: 0,
            GameYearListBrr:[],
            PageSize: '',
            TwoPageSize: '',
            CurrentPage: 1,

            ThisGameArr:[],
            ThisGameBrr:[],
            ThisGameArrLength:0,
            thisGameName:'',
        };
    }
    componentDidMount=function() {
        get({url:'match/get_match_review_year'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GameYearArr:res.data,
                });
            }
        });

        let Jump = JSON.parse(sessionStorage.getItem('Jump'));
        if (Jump && Jump.type && Jump.type === 'review' && Jump.spec){
            let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
            let PageSize = Math.ceil(H/52)
            if (Jump.id){
                get({url:'match/get_match_year/'+Jump.spec}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            GameYearListArr:res.data,
                            YearNum:Jump.spec,
                            GameYearType:'thisGame',
                        });
                        get({url:"match/match_review_detail/"+Jump.id}).then(res => {
                            if(res.errno === '200'){
                                this.setState({
                                    ThisGameArr:res.data,
                                    thisGameName:Jump.match_name,
                                });
                                const KeyStart = (this.state.CurrentPage-1)*PageSize;
                                const KeyEnd = this.state.CurrentPage*PageSize;
                                const GameYearList = [];
                                if(KeyEnd <= res.data.length){
                                    for(let i=KeyStart; i<KeyEnd; i++){
                                        const GameYearBList = res.data[i];
                                        GameYearList.push(GameYearBList)
                                    }
                                }else {
                                    for(let i=KeyStart; i<res.data.length; i++){
                                        const GameYearBList = res.data[i];
                                        GameYearList.push(GameYearBList)
                                    }
                                }

                                this.setState({
                                    ThisGameBrr:GameYearList,
                                    ThisGameArrLength:res.data.length,
                                    TwoPageSize:PageSize,
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            }else {
                get({url:'match/get_match_year/'+Jump.spec}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            GameYearListArr:res.data,
                            GameYearType: 'thisYear',
                            YearNum:Jump.spec,
                        });
                        const KeyStart = (this.state.CurrentPage-1)*PageSize;
                        const KeyEnd = this.state.CurrentPage*PageSize;
                        const GameYearList = [];
                        if(KeyEnd <= res.data.length){
                            for(let i=KeyStart; i<KeyEnd; i++){
                                const GameYearBList = res.data[i];
                                GameYearList.push(GameYearBList)
                            }
                        }else {
                            for(let i=KeyStart; i<res.data.length; i++){
                                const GameYearBList = res.data[i];
                                GameYearList.push(GameYearBList)
                            }
                        }
                        this.setState({
                            GameYearListBrr:GameYearList,
                            GameYearListArrLength:res.data.length,
                            PageSize:PageSize,
                        })
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            }
            sessionStorage.removeItem('Jump');
        }else {
            this.setState({
                GameYearType:'year',
            });
        }
    };





    GameYearMore=(item,index)=>{
        let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
        let PageSize = Math.ceil(H/52)
        get({url:'match/get_match_year/'+item.time}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GameYearListArr:res.data,
                    GameYearType: 'thisYear',
                    YearNum:item.time,
                });
                const KeyStart = (this.state.CurrentPage-1)*PageSize;
                const KeyEnd = this.state.CurrentPage*PageSize;
                const GameYearList = [];
                if(KeyEnd <= res.data.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const GameYearBList = res.data[i];
                        GameYearList.push(GameYearBList)
                    }
                }else {
                    for(let i=KeyStart; i<res.data.length; i++){
                        const GameYearBList = res.data[i];
                        GameYearList.push(GameYearBList)
                    }
                }
                this.setState({
                    GameYearListBrr:GameYearList,
                    GameYearListArrLength:res.data.length,
                    PageSize:PageSize,
                })
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };

    BackGameYear(){
        this.setState({
            GameYearType: 'year',
        });
    };
    BackThisYear(){
        let YearNum = this.state.YearNum;
        let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
        let PageSize = Math.ceil(H/52)
        get({url:'match/get_match_year/'+YearNum}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    GameYearType: 'thisYear',
                    thisGameName: '',
                });
                const KeyStart = (this.state.CurrentPage-1)*PageSize;
                const KeyEnd = this.state.CurrentPage*PageSize;
                const GameYearList = [];
                if(KeyEnd <= res.data.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const GameYearBList = res.data[i];
                        GameYearList.push(GameYearBList)
                    }
                }else {
                    for(let i=KeyStart; i<res.data.length; i++){
                        const GameYearBList = res.data[i];
                        GameYearList.push(GameYearBList)
                    }
                }
                this.setState({
                    GameYearListBrr:GameYearList,
                    GameYearListArrLength:res.data.length,
                    PageSize:PageSize,
                })
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };





    getCurrentPage(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const GameYearList = [];
        if(KeyEnd <= this.state.GameYearListArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const GameYearBList = this.state.GameYearListArr[i];
                GameYearList.push(GameYearBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.GameYearListArr.length; i++){
                const GameYearBList = this.state.GameYearListArr[i];
                GameYearList.push(GameYearBList)
            }
        }
        this.setState({
            GameYearListBrr:GameYearList,
        });
    };





    GameYearList=(item,index)=>{
        let H = window.innerHeight-340 > 360 ? window.innerHeight-340 :360;
        let PageSize = Math.ceil(H/52)
        get({url:"match/match_review_detail/"+item.id}).then(res => {
            if(res.errno === '200'){
                this.setState({
                    ThisGameArr:res.data,
                    GameYearType:'thisGame',
                    thisGameName:item.match_name,
                });
                const KeyStart = (this.state.CurrentPage-1)*PageSize;
                const KeyEnd = this.state.CurrentPage*PageSize;
                const GameYearList = [];
                if(KeyEnd <= res.data.length){
                    for(let i=KeyStart; i<KeyEnd; i++){
                        const GameYearBList = res.data[i];
                        GameYearList.push(GameYearBList)
                    }
                }else {
                    for(let i=KeyStart; i<res.data.length; i++){
                        const GameYearBList = res.data[i];
                        GameYearList.push(GameYearBList)
                    }
                }

                this.setState({
                    ThisGameBrr:GameYearList,
                    ThisGameArrLength:res.data.length,
                    TwoPageSize:PageSize,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:res.errmsg+'！',
                });
            }
        });
    };


    getCurrentPageOne(CurrentPage) {
        const KeyStart = (CurrentPage-1)*this.state.PageSize;
        const KeyEnd = CurrentPage*this.state.PageSize;
        const GameYearList = [];
        if(KeyEnd <= this.state.ThisGameArr.length){
            for(let i=KeyStart; i<KeyEnd; i++){
                const GameYearBList = this.state.ThisGameArr[i];
                GameYearList.push(GameYearBList)
            }
        }else {
            for(let i=KeyStart; i<this.state.ThisGameArr.length; i++){
                const GameYearBList = this.state.ThisGameArr[i];
                GameYearList.push(GameYearBList)
            }
        }
        this.setState({
            ThisGameBrr:GameYearList,
        });
    };


    ThisGameList=(item,index)=>{
        if (item.is_pdf && item.is_pdf === 1){
            window.open(item.content);
        }else {
            window.open('/GYInfo?id='+item.id);
        }
    };



    GoLink(){
        window.open('http://move.asfcyy.com/gsyh');
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'GameYearMainBox'}>
                    {
                        this.state.GameYearType === 'year'&&
                        <div>
                            <div className={'InfoListPositionTitBox'}>
                                <div className={'InfoListPositionTit'}>
                                    <div className={'InfoListPosition'}>赛事专题</div>
                                </div>
                            </div>
                            <div>
                                {
                                    this.state.GameYearArr.map((item,index) =>
                                        <div className={'GameYearBox'} key={index}>
                                            <div className={'GameYearNumBox'}>
                                                <div className={'GameYearNum'}>{item.time.substring(2)}</div>
                                                <div className={'GameYearWord'}>year</div>
                                            </div>
                                            <div className={'GameYearContentBox'} onClick={() => this.GameYearMore(item,index)}>
                                                <div className={'GameYearContentTit'}>{item.title}</div>
                                                <div className={'GameYearContent'}>
                                                    {item.content}
                                                </div>
                                                <div className={'GameYearMore'}>
                                                    <span onClick={() => this.GameYearMore(item,index)}>更多内容&gt;&gt;</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.GameYearType === 'thisYear'&&
                        <div>
                            <div className={'InfoListPositionTitBox'}>
                                <div className={'DetailsBackBox clearfix'} onClick={this.BackGameYear.bind(this)} style={{marginTop:16,}}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                                <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,}}>
                                    <div className={'InfoListPosition'}>
                                        <span onClick={this.BackGameYear.bind(this)}>赛事专题</span>
                                        &gt;&gt;{this.state.YearNum}年赛事专题
                                    </div>
                                </div>
                            </div>
                            <div className={'InfoListBorder'}> </div>
                            <div className={'InfoListBox'} style={{height:window.innerHeight-340 > 360 ? window.innerHeight-340 :360,}}>
                                {
                                    this.state.GameYearListBrr.map((item,index) =>
                                        <div className={'InfoList'} title={item.match_name} key={index} onClick={() => this.GameYearList(item,index)}>
                                            <div className={'InfoListTitle'} style={{width:580,}}>{item.match_name}</div>
                                            <div className={'InfoListTime'} style={{width:100,float:'right',}}>{item.match_start_time}</div>
                                            <div className={'InfoListCenter'} style={{width:190,float:'right',}}>············</div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className={'InfoListBorder'} style={{marginBottom:10,}}> </div>
                            <div style={{marginBottom:20,}}>
                                {
                                    this.state.GameYearListArrLength !== 0 &&
                                    <Pagination
                                        TotalSize={this.state.GameYearListArrLength}
                                        PageSize={this.state.PageSize}
                                        pageCallbackFn={this.getCurrentPage.bind(this)}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.GameYearType === 'thisGame'&&
                        <div>
                            <div className={'InfoListPositionTitBox'}>
                                <div className={'DetailsBackBox clearfix'} onClick={this.BackThisYear.bind(this)} style={{marginTop:16,}}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                                {
                                    this.state.thisGameName !== ''&&
                                    <div className={'InfoListPositionTit'} style={{float:'left',marginLeft:30,maxWidth:800,}}>
                                        <div className={'InfoListPosition'} style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap',}}>
                                            <span onClick={this.BackGameYear.bind(this)}>赛事专题</span>
                                            &gt;&gt;<span onClick={this.BackGameYear.bind(this)}>{this.state.YearNum}年赛事专题</span>
                                            &gt;&gt;<span>{this.state.thisGameName}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={'InfoListBorder'}> </div>
                            <div className={'InfoListBox'} style={{height:window.innerHeight-340 > 360 ? window.innerHeight-340 :360,}}>
                                {
                                    this.state.ThisGameBrr.map((item,index) =>
                                        <div className={'InfoList'} key={index} onClick={() => this.ThisGameList(item,index)}>
                                            <div className={'InfoListTitle'} style={{width:'100%',}}>{item.title}</div>
                                            {/*<div className={'InfoListCenter'} style={{width:200,}}>············</div>*/}
                                            {/*<div className={'InfoListTime'} style={{width:200,}}>{item.ctime}</div>*/}
                                        </div>
                                    )
                                }
                            </div>
                            <div className={'InfoListBorder'} style={{marginBottom:10,}}> </div>
                            <div style={{marginBottom:20,}}>
                                {
                                    this.state.ThisGameArrLength !== 0 &&
                                    <Pagination
                                        TotalSize={this.state.ThisGameArrLength}
                                        PageSize={this.state.TwoPageSize}
                                        pageCallbackFn={this.getCurrentPageOne.bind(this)}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GameYear;
