import React, { Component } from 'react';
import './handf.css';
import BeiAnIco from "./handfimage/备案图标.png";
import AsfcLogo from "./handfimage/asfc.png";
import FootNav from "./footNav";
import {withRouter} from 'react-router-dom';
import moment from 'moment'

let ShowCode = 'http://oss.asfcyy.com/richTextImage/237d60d0bc9211ec9dc800163e3521a2.jpg';
let ShowCodeBo = 'http://oss.asfcyy.com/richTextImage/87b693f6e97811ec9dc800163e3521a2.png';
class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {
            FootNavArr:FootNav,
            Year:moment().year(),
            FootToggleOn: true,
            FootDisplay:'none',
        };
    }
    componentDidMount=function() {
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
            // FootToggleOn: true,
            // FootDisplay:'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    FooterBtn(e){
        this.setState(prevState => ({
            FootToggleOn: !prevState.FootToggleOn,
            FootDisplay:prevState.FootToggleOn ? 'block' : 'none',
        }));
    };



    //底部内容跳转
    FooterConListClick=(item,index) =>{
        window.open(item.router);
    };
    ICP(){
        window.open('https://beian.miit.gov.cn');
    };


    render() {
        return (
            <div style={{width:'100%',height:'auto',position:'fixed', left:'0', bottom:'0',zIndex:'9999999999999999999999999999',}}>
                {
                    this.state.FootDisplay === 'block'&&
                    <div className={'FooterBtnBox'} onClick={this.FooterBtn.bind(this)}>
                        <div className={'FooterBtnBorLeft'}> </div>
                        <div className={'FooterBtnBorRight'}> </div>
                    </div>
                }
                {
                    this.state.FootDisplay === 'none'&&
                    <div className={'FooterBtnBoxN'} onClick={this.FooterBtn.bind(this)}>
                        <div className={'FooterBtnBorLeftN'}> </div>
                        <div className={'FooterBtnBorRightN'}> </div>
                    </div>
                }
                <div className={'NewFooter'} style={{display:this.state.FootDisplay,}}>
                    <div className={'WeBoxesBg'}>
                        <div className={'WeBoxes'}>
                            <div className={'FooterLogoBox'}>
                                <div className={'FooterLogo'}> </div>
                                <div className={'FooterLogos'}> </div>
                                <div className={'FooterAboutBox'}>
                                    {
                                        this.state.FootNavArr.map((item,index) => (
                                            <div className={'FooterAboutList'} key={index} onClick={() => this.FooterConListClick(item,index)}>{item.sontitlename}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={'FooterCon'} style={{width:652,marginTop:40,}}>
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>中华人民共和国工业和信息化部网站备案ICP证：<span style={{textDecoration:'underline',cursor:'pointer',float:'right',}} onClick={this.ICP.bind(this)}>京ICP备18035925号-1</span></div>
                                </div>
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>
                                        <span>北京市计算机信息网络公安备案：</span>
                                        <img src={BeiAnIco} alt={'.'}/>
                                        <span>京公网安备 11010502039731号</span>
                                    </div>
                                </div>
                                {/*<div className={'Copyright'}>*/}
                                {/*    <div className={'CopyrightCon'}>网络安全等级保护网站备案：41010099131-21001</div>*/}
                                {/*</div>*/}
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>
                                        <img className={'AsfcLogo'} src={AsfcLogo} alt={'.'}/>
                                        <span>中国航空运动协会授权</span>
                                    </div>
                                </div>
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>
                                        Copyright&nbsp;&nbsp;&copy;2018-{this.state.Year}&nbsp;&nbsp;www.asfcyy.com&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved&nbsp;&nbsp;&nbsp;&nbsp;北京鹰扬科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;版权所有
                                    </div>
                                </div>
                            </div>
                            <div className={'FooterTwoCode'}>
                                <div className={'Wx'}>
                                    <div className={'WxTitWord'}>微博平台</div>
                                    <div className={'WxShowCode'}><img src={ShowCodeBo} alt={''}/></div>
                                </div>
                                <div className={'Wx'}>
                                    <div className={'WxTitWord'}>微信平台</div>
                                    <div className={'WxShowCode'}><img src={ShowCode} alt={''}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(Footer);
