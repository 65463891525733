import React, { Component } from 'react';
import './member.css';
import Triangle from "../round/Triangle";
import PopUp from "../round/PopUp";
import CheckboxIcoNot from "./memberimage/CheckboxIcoNot.png";
import CheckboxIcoHave from "./memberimage/CheckboxIcoHave.png";
import Upload from "../round/Upload";
import {get, post} from "../../ajax/tools";



class PeTeamCreate extends Component {

    constructor(props){
        super(props);
        this.state = {
            User:{},
            TeamType:'',//团队类型
            TeamName:'',//团队名称
            TeamNameNote:null,//团队名称是否存在的提示

            FileList: [],
            Uploading: false,
            PostLoading: false,
            UploadBtnState:'NotShow',

            PeId:'',//用户id
            PeIdPeIdNote:'No',//用户id提示
            PeIdPeIdNoteWord:'',
            PeInfoArr:[],


            PeSendNum: 0,
            PeSendNumArr: [],
            CheckboxIcoBgImg:'url('+CheckboxIcoNot+')',
            CheckboxIcoToggleOn: true,


        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            TeamType:history.TeamType,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        this.setState({
            TeamType:history.TeamType,
        });
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    User:res.data[0],
                });
            }
        });
    };


    //返回上一级
    DropOutPeJoin=()=>{
        this.setState({
            PopUp:'有两个选择按钮的提示框',
            MessageTip:'是否取消创建团队返回上一级？',
            DropOutPe:'OutYes',
        });
    };



    //输入团队的名字
    TeamNameChange(event){
        if (event.target.value !== ''){
            post({
                url:'flyteam/check_flyteam_name',
                data:{
                    name:event.target.value,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TeamNameNote: true,
                    });
                }else {
                    this.setState({
                        TeamNameNote: false,
                    });
                }
            });
        }else if(!event.target.value){
            this.setState({
                TeamNameNote: null,
            });
        }
        this.setState({
            TeamName: event.target.value,
            PeId:'',
        });
    };



    //输入个人的ID
    PeIdChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            PeId: event.target.value,
        });
    };

    //点击重置备注
    PeIdClick(){
        this.setState({
            PeIdPeIdNote:'No',
        });
    };
    //点击搜索用户
    PeJoinInput=()=>{
        let PeId = this.state.PeId;
        let User = this.state.User;
        let PeInfoArr = this.state.PeInfoArr;
        let PeInfoArrListIdNumArr = [];
        if (PeId !== ''){
            if(PeId !== User.yy_num){
                get({url:'reuse/get_personal_info/'+PeId}).then((res) => {
                    if(res.errno === '200'){
                        const PeInfoCbArr=[];
                        if(PeInfoArr.length !==0){
                            for(let i=0;i<PeInfoArr.length;i++){
                                let PeInfoArrListId = PeInfoArr[i].id;
                                PeInfoArrListIdNumArr.push(PeInfoArrListId);
                            }
                            if(PeInfoArrListIdNumArr.includes(res.data[0].id)){
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'您已经搜索添加过该人员！',
                                });
                            }else {
                                let PeTheList = res.data[0];
                                PeTheList.Checkbox = false;
                                PeInfoArr.push(PeTheList);
                            }
                            this.setState({
                                PeInfoArr:PeInfoArr,
                            });
                        }else {
                            let PeTheList = res.data[0];
                            PeTheList.Checkbox = false;
                            PeInfoCbArr.push(PeTheList);
                            this.setState({
                                PeInfoArr:PeInfoCbArr,
                            });
                        }
                    }else {
                        this.setState({
                            PeIdPeIdNote:'Yes',
                            PeIdPeIdNoteWord:'未搜索到相关信息，请您检查ID是否正确',
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'您为团队创建者，已自动添加进此团队！',
                });
            }
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入人员ID或身份证号！',
            });
        }
    };



    //察看人员等级详情
    DetailsClick=(item,index)=>{
        console.log(item)
    };



    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            if(this.state.DropOutPe === 'More'){
                this.props.PeTeamCreateOutBackClick('OutAndShow');
                this.setState({
                    PopUp:'',
                });
            }else if(this.state.DropOutPe === 'OutYes'){
                this.setState({
                    PopUp:'',
                });
            }
        }else if(event === 'Confirm'){
            if(this.state.DropOutPe === 'More'){
                this.props.PeTeamCreateOutBackClick('TeamManage');
                this.setState({
                    PopUp:'',
                });
            }else if(this.state.DropOutPe === 'OutYes'){
                this.props.PeTeamCreateOutBackClick('OutPeTeam');
                this.setState({
                    PopUp:'',
                });
            }
        }
    };


    //点击发送多个申请
    PePleaseSend=()=>{
        const formData = new FormData();
        let TeamName = this.state.TeamName;
        let TeamType = this.state.TeamType;
        let UserId = this.state.User.id;
        let PeSendNumArr = this.state.PeSendNumArr;
        let PeSendNumArrId = [];
        for(let i=0;i<PeSendNumArr.length;i++){
            let PeInfoArrListId = PeSendNumArr[i].id;
            PeSendNumArrId.push(PeInfoArrListId);
        }
        PeSendNumArrId.push(UserId);
        this.setState({
            Uploading:true,
            PostLoading:true,
        });
        if (this.state.FileList.length !== 0){
            this.state.FileList.forEach((file) => {
                formData.append('file', file);
                formData.append('name', TeamName);
                formData.append('type', TeamType === '竞技团队' ?  '0' :(TeamType === '表演团队' ?  '1' : ''));
                formData.append('member', PeSendNumArrId);
            });
            post({
                url:'flyteam/create_flyteam',
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        FileList: [],
                        Uploading: false,
                        PostLoading: false,
                        UploadBtnState:'NotShow',
                        DropOutPe:'More',
                        PopUp:'有两个自定按钮的提示框',
                        MessageTip:'成功发送'+this.state.PeSendNum+'封邀请函,是否继续？',
                    });
                }else {
                    this.setState({
                        PostLoading: false,
                        PopUp:'提示弹窗',
                        MessageTip:'发送申请失败，请稍后重试！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您还未选择您的团队旗帜！',
            });
        }
    };


    //点击多选框全选
    CheckboxIcoAll=(event)=>{
        const PeInfoCbArr=[];
        const PeSendNumArr=[];
        for(let i=0;i<event.length;i++){
            const PeList= event[i];
            if(this.state.CheckboxIcoToggleOn === false){
                PeList.Checkbox = false;
                PeSendNumArr.push(PeList);
                PeInfoCbArr.push(PeList);
            }else if(this.state.CheckboxIcoToggleOn === true){
                PeList.Checkbox = true;
                PeSendNumArr.push(PeList);
                PeInfoCbArr.push(PeList);
            }
        }
        this.setState(prevState => ({
            CheckboxIcoToggleOn: !prevState.CheckboxIcoToggleOn,
            CheckboxIcoBgImg:PeSendNumArr.length === 0 ? 'url('+CheckboxIcoNot+')' : (prevState.CheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
            PeSendNum:prevState.CheckboxIcoToggleOn ? PeSendNumArr.length : 0,
            PeInfoArr:PeInfoCbArr,
            PeSendNumArr:prevState.CheckboxIcoToggleOn ? PeSendNumArr : [],
        }));
    };
    //点击单个多选框
    CheckboxIco=(item,index)=>{
        const PeInfoList = this.state.PeInfoArr;
        PeInfoList[index]['Checkbox'] = item.Checkbox !== true;
        const PeSendNumArr = [...this.state.PeSendNumArr];
        if(item.Checkbox === true){
            PeSendNumArr.push(item);
            this.setState({
                PeSendNumArr:PeSendNumArr,
                PeSendNum:PeSendNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<PeSendNumArr.length;i++){
                if(PeSendNumArr[i].id === item.id){
                    PeSendNumArr.splice(i,1);
                }
            }
            this.setState({
                PeSendNumArr:PeSendNumArr,
                PeSendNum:PeSendNumArr.length,
            });
        }
        this.setState({
            PeInfoArr:PeInfoList,
            CheckboxIcoToggleOn:this.state.PeInfoArr.length !== PeSendNumArr.length,
        });
    };





    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        const props = {
            UploadState:this.state.UploadBtnState,
            RemoveUpload:(file) => {
                let FileList = this.state.FileList;
                let index = FileList.indexOf(file);
                let NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileList:NewFileList,
                    Uploading: false,
                    UploadBtnState:'NotShow',
                });
            },
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = props.BeforeUpload;
                let NewFileList = [];
                if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/x-icon'){
                    if(file.size > 0.5*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过500KB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                            UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '不支持的文件格式!';
                }
            },
        };
        return (
                <div className={'PeTeamModule'} style={{paddingTop:66,height:602,}}>
                    <button className={'DropOutTeamJoinBtn'} onClick={this.DropOutPeJoin.bind(this)}>返回</button>
                    <div className={'PeTeamCreateInfoBox'}>
                        <div className={'TeamCreateStep'}>
                            <div className={'TeamCreateStepWord'}>1、团队信息</div>
                            <Triangle Direction={'right'} Color={'#0068B7'} Width={'17px'} Height={'17px'} Top={'0'} Right={'-17px'}/>
                        </div>
                        <div className={'PeTeamCreateInfoConBox'}>
                            <div className={'PeTeamCreateInfoConTit'}><span style={{color:'#FF0000',}}>*</span>团队名称</div>
                            <input className={'TeamNameInput'}
                                   type={'text'}
                                   name={'TeamName'}
                                   value={this.state.TeamName}
                                   onChange={this.TeamNameChange.bind(this)}
                                   placeholder={'请输入创建的团队名称'}
                                   autoComplete={'off'}
                            />
                            {
                                this.state.TeamNameNote === false &&
                                <div className={'PeTeamCreateNote'}>名称已存在</div>
                            }
                            {
                                this.state.TeamNameNote === true &&
                                <div className={'TeamNameNoteIco'}> </div>
                            }
                        </div>
                        <div className={'PeTeamCreateInfoConBox'}>
                            <div className={'PeTeamCreateInfoConTit'}>团队旗帜</div>
                            <div  className={'TeamCreateLogoBox'}>
                                <Upload {...props}/>
                                <div className={'TeamCreateLogo'}
                                     style={this.state.UploadBtnState === 'Show' ? {backgroundColor:'#D2D2D2',} : {}}
                                >上传图片</div>
                            </div>
                        </div>
                    </div>
                    <div className={'PeTeamCreateInfoBox'} style={{height:104,}}>
                        <div className={'TeamCreateStep'}>
                            <div className={'TeamCreateStepWord'} style={{backgroundColor:this.state.TeamNameNote === true ? '#0068B7':'#CCCCCC'}}>2、邀请成员</div>
                            <div style={{position:'absolute', top:0, right:0, width:0, height:0, borderWidth:'17px 0 17px 17px', borderStyle:'solid', borderColor:'transparent transparent transparent '+(this.state.TeamNameNote === true ? '#0068B7':'#CCCCCC'),}}> </div>
                        </div>
                        <div className={'PeTeamCreateInfoConBox'}>
                            <div className={'PeTeamCreateInfoConTit'}><span style={{color:'#FF0000',}}>*</span>成员ID</div>
                            <div className={'TeamIdInputBox clearfix'} style={{borderColor:this.state.TeamNameNote === true ? ' ':'#CCCCCC'}}>
                                <input className={'TeamIdInput'}
                                       type={'text'}
                                       name={'PeId'}
                                       value={this.state.TeamNameNote === true ? this.state.PeId:''}
                                       onChange={this.PeIdChange.bind(this)}
                                       onClick={this.PeIdClick.bind(this)}
                                       placeholder={'请输入正确的个人ID或身份证号'}
                                       autoComplete={'off'}
                                       disabled={this.state.TeamNameNote !== true}
                                />
                                <button className={'TeamIdInputBtn'}
                                        style={{backgroundColor:this.state.TeamNameNote === true ? ' ':'#CCCCCC',
                                            color:this.state.TeamNameNote === true ? ' ':'#FFFFFF',}}
                                        onClick={this.state.TeamNameNote === null ? null:this.PeJoinInput.bind(this)}
                                >添加</button>
                            </div>
                            {
                                this.state.PeIdPeIdNote === 'Yes' &&
                                <div className={'TeamIdInputNote'} style={{maxWidth:336,}}>{this.state.PeIdPeIdNoteWord}</div>
                            }
                        </div>
                    </div>
                    <button className={'TeamPleaseSendBtn'}
                            style={this.state.PostLoading === true ? {backgroundColor:'#D2D2D2',} : {}}
                            onClick={this.PePleaseSend.bind(this)}
                    >
                        发送申请（{this.state.PeSendNum}）
                    </button>
                    <div className={'TeamListHeaderBox'}>
                        <div className={'TeamHeaderList'} style={{width:40,}}>
                            <div className={'TeamHeaderCheckbox'}
                                 onClick={() => this.CheckboxIcoAll(this.state.PeInfoArr)}>
                                {
                                    this.state.CheckboxIcoToggleOn === false &&
                                    <div className={'TeamHeaderCheckboxActive'}> </div>
                                }
                            </div>
                        </div>
                        <div className={'TeamHeaderList'} style={{width:210,}}>序号</div>
                        <div className={'TeamHeaderList'} style={{width:214,}}>姓名</div>
                        <div className={'TeamHeaderList'} style={{width:214,}}>ID</div>
                        <div className={'TeamHeaderList'} style={{width:212,}}>详情</div>
                    </div>
                    <div className={'TeamListConBox'} style={{height:250,}}>
                        {
                            this.state.PeInfoArr.map((item,index) =>
                                <div className={'TeamListCon'} key={index}>
                                    <div className={'TeamConList'} style={{width:40,}}>
                                    <div className={'CheckboxIco'}
                                         style={{backgroundImage:item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')',marginTop:7,}}
                                         onClick={() => this.CheckboxIco(item,index)}>
                                    </div>
                                </div>
                                    <div className={'TeamConList'} style={{width:210,}}>{index+1}</div>
                                    <div className={'TeamConList'} style={{width:214,}}>{item.real_name}</div>
                                    <div className={'TeamConList'} style={{width:214,}}>{item.yy_num}</div>
                                    <div className={'TeamConList'} style={{width:212,}}>
                                        <span className={'DetailsViews'} onClick={() => this.DetailsClick(item,index)}>查看</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.PopUp === '有两个选择按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '有两个自定按钮的提示框' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'PeTeamCreate'}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default PeTeamCreate;
