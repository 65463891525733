import React, { Component } from 'react';
import './inquire.css';
import {get} from "../../ajax/tools";
import ThreeLevelLinkage from "../round/ThreeLevelLinkage";
import PopUp from "../round/PopUp";


class InSchool extends Component {

    constructor(props){
        super(props);
        this.state = {
            ThreeLevelLinkage:'none',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            InSchoolArr:[],//训练中心所属机构
            loading: false,

            isThree:'q',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        if (nextProps.SchoolDataArr.length !== 0){
            this.setState({
                InSchoolArr:nextProps.SchoolDataArr,
            });
        }else {
            get({url:'query/youth_aviat_school_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        InSchoolArr:res.data,
                    });
                }
            });
        }
    };

    componentDidMount=function() {
        let history = this.props;
        if (history.SchoolDataArr.length !== 0){
            this.setState({
                InSchoolArr:history.SchoolDataArr,
            });
        }else {
            get({url:'query/youth_aviat_school_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        InSchoolArr:res.data,
                    });
                }
            });
        }
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllDataBtn(){
        get({url:'query/youth_aviat_school_query'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    InSchoolArr:res.data,
                    isThree: this.state.isThree === 'q' ? 's' : 'q',
                });
            }
        });
    };


    AllClick = () => {
        this.setState({
            InSchoolToggleOn: true,
            InSchoolDisplay: 'none',
            ThreeLevelLinkage:'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //三级联动的逻辑处理
    ChildLinkClick=(event)=> {
        if (event.Type === '地址') {
            if (event.Title === '省') {
                get({url:'query/addr_youth_aviat_school_query/'+event.ValueP}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            InSchoolArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            } else if (event.Title === '市') {
                get({url:'query/addr_youth_aviat_school_query/'+event.ValueC}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            InSchoolArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            } else if (event.Title === '区') {
                get({url:'query/addr_youth_aviat_school_query/'+event.ValueA}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            InSchoolArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }
        }
    };


    //点击航校名称详情
    ActiveSchool=(item,index)=>{
        let ActiveSchool = {
            Inquire:'School',
            Id:item.id,
            DataArr:this.state.InSchoolArr,
        };
        this.props.ActiveSeeDetails(ActiveSchool);
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'InquirePackageBox'} style={{minHeight:window.innerHeight-430 > 450 ? window.innerHeight-430 :450,}}>
                    <div className={'InquireAllDataBtn clearfix'} onClick={this.AllDataBtn.bind(this)}>
                        <div className={'InquireAllDataIco'}> </div>
                        <div className={'InquireAllDataWord'}>全部信息</div>
                    </div>
                    <div className={'InquirePackageHeaderBox'}>
                        <div className={'InquirePackageHeaderList'} style={{width:390,}}>航校名称</div>
                        <div className={'InquirePackageHeaderList clearfix'} style={{width:560,}}>
                            <div style={{display:'table',margin:'0 auto',}}>
                                <span style={{float:'left',}}>所属地区</span>
                                <div className={'InAddressThreeBox'}>
                                    {
                                        this.state.isThree === 'q'&&
                                        <ThreeLevelLinkage
                                            ChildLinkClick={this.ChildLinkClick.bind(this)}
                                            type={'地址'}
                                            Width={314}
                                            Height={26}
                                            ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                        />
                                    }
                                    {
                                        this.state.isThree === 's'&&
                                        <ThreeLevelLinkage
                                            ChildLinkClick={this.ChildLinkClick.bind(this)}
                                            type={'地址'}
                                            Width={314}
                                            Height={26}
                                            ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading === true&&
                        <div className={'loadEffect'}>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </div>
                    }
                    {
                        this.state.loading === false&&
                        <div className={'InquirePackageContentBox'} style={{minHeight:window.innerHeight-272 > 460 ? window.innerHeight-272 :460,maxHeight:window.innerHeight-264 > 450 ? 'calc(100vh - 264px)' : '450px'}}>
                            {
                                this.state.InSchoolArr.map((item,index) =>
                                    <div className={'InquirePackageContentListBox'} key={index}>
                                        <div className={'InquirePackageContentList'} style={{width:390,}}>
                                            <span className={'ClickDetails'} onClick={() => this.ActiveSchool(item,index)}>{item.name}</span>
                                        </div>
                                        <div className={'InquirePackageContentList'} style={{width:560,}}>
                                            {
                                                item.province === item.city ?
                                                    item.city+item.county+item.address :
                                                    item.province+item.city+item.county+item.address
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default InSchool;
