import React, { Component } from 'react';
import './handf.css';
import BeiAnIco from "./handfimage/备案图标.png";
import AsfcLogo from "./handfimage/asfc.png";
import FootNav from "./footNav";
import {withRouter} from 'react-router-dom';
import moment from 'moment'

let ShowCode = 'http://oss.asfcyy.com/richTextImage/237d60d0bc9211ec9dc800163e3521a2.jpg';
let ShowCodeBo = 'http://oss.asfcyy.com/richTextImage/87b693f6e97811ec9dc800163e3521a2.png';
class FooterHome extends Component {
    constructor(props){
        super(props);
        this.state = {
            FooterStyle:{},
            FootNavArr:FootNav,
            Year:moment().year(),
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        //const ua = navigator.userAgent.toLowerCase().indexOf('windows');//媒体查询当前设备
        const FooterStyle = {position: "fixed",};
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                FooterStyle: FooterStyle,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                FooterStyle: {},
            });
            //const {history} = this.props;
            //history.push('/login');
        } else{
            this.setState({
                FooterStyle: {},
            });
        }
    };
    //底部内容跳转
    FooterConListClick=(item,index) =>{
        window.open(item.router);
    };
    ICP(){
        window.open('https://beian.miit.gov.cn');
    };


    render() {
        return (
                <div className={'Footer'} style={this.state.FooterStyle}>
                    <div className={'WeBoxesBg'}>
                        <div className={'WeBoxes'}>
                            <div className={'FooterLogoBox'}>
                                <div className={'FooterLogo'}> </div>
                                <div className={'FooterLogos'}> </div>
                                <div className={'FooterAboutBox'}>
                                    {
                                        this.state.FootNavArr.map((item,index) => (
                                            <div className={'FooterAboutList'} key={index} onClick={() => this.FooterConListClick(item,index)}>{item.sontitlename}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={'FooterCon'} style={{width:652,marginTop:24,}}>
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>中华人民共和国工业和信息化部网站备案ICP证：<span style={{textDecoration:'underline',cursor:'pointer',float:'right',}} onClick={this.ICP.bind(this)}>京ICP备18035925号-1</span></div>
                                </div>
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>
                                        <span>北京市计算机信息网络公安备案：</span>
                                        <img src={BeiAnIco} alt={'.'}/>
                                        <span>京公网安备 11010502039731号</span>
                                    </div>
                                </div>
                                {/*<div className={'Copyright'}>*/}
                                {/*    <div className={'CopyrightCon'}>网络安全等级保护网站备案：41010099131-21001</div>*/}
                                {/*</div>*/}
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>
                                        <img className={'AsfcLogo'} src={AsfcLogo} alt={'.'}/>
                                        <span>中国航空运动协会授权</span>
                                    </div>
                                </div>
                                <div className={'Copyright'}>
                                    <div className={'CopyrightCon'}>
                                        Copyright&nbsp;&nbsp;&copy;2018-{this.state.Year}&nbsp;&nbsp;www.asfcyy.com&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved&nbsp;&nbsp;&nbsp;&nbsp;北京鹰扬科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;版权所有
                                    </div>
                                </div>
                            </div>
                            <div className={'FooterTwoCode'}>
                                <div className={'Wx'}>
                                    <div className={'WxTitWord'}>微博平台</div>
                                    <div className={'WxShowCode'}><img src={ShowCodeBo} alt={''}/></div>
                                </div>
                                <div className={'Wx'}>
                                    <div className={'WxTitWord'}>微信平台</div>
                                    <div className={'WxShowCode'}><img src={ShowCode} alt={''}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*
                        <div className={'FriendBoxes'}> </div>
                        <div className={'FooterCopyright'}>
                            <div className={'Copyright'}>
                                <div className={'CopyrightCon'}>中华人民共和国工业和信息化部网站备案ICP证：京ICP备18035925号-1</div>
                                <div className={'CopyrightCon'}>
                                    <span>北京市计算机信息网络公安备案：</span>
                                    <img src={BeiAnIco} alt={'.'}/>
                                    <span>京公网安备 11010502039731号</span>
                                </div>
                            </div>
                            <div className={'Copyright'}>
                                <div className={'CopyrightCon'}>
                                    <img className={'AsfcLogo'} src={AsfcLogo} alt={'.'}/>
                                    <span>中国航空运动协会授权</span>
                                </div>
                            </div>
                            <div className={'Copyright'}>
                                <div className={'CopyrightCon'}>
                                    Copyright&nbsp;&nbsp;&copy;2019-{this.state.Year}&nbsp;&nbsp;www.asfcyy.com&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved&nbsp;&nbsp;&nbsp;&nbsp;北京鹰扬科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;版权所有
                                </div>
                            </div>
                        </div>
                    */}
                </div>
        );
    }
}


export default withRouter(FooterHome);
