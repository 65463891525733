import React, { Component } from 'react';
import {get} from "../../ajax/tools";
import ThreeLevelLinkage from "../round/ThreeLevelLinkage";
import Triangle from "../round/Triangle";
import './inquire.css';
import '../round/Loading.css';
import PopUp from "../round/PopUp";


class InTeacher extends Component {

    constructor(props){
        super(props);
        this.state = {
            TeacherArr:[],//教员信息数组
            ThreeLevelLinkage:'none',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            TeacherInput:'',//搜索教练员的框
            BelongToFirmArr:[],//教员所属机构
            BelongToFirmName:'',
            BelongToFirmToggleOn:true,
            BelongToFirmDisplay:'none',
            TeacherLevelArr:[
                '基础教练员',
                '虚拟民航特长教练员',
                '虚拟特技飞行教练员',
                '虚拟军航特长教练员',
                '虚拟民航高级教练员'
            ],
            TeacherLevelName:'',
            TeacherLevelToggleOn:true,
            TeacherLevelDisplay:'none',
            loading: false,
            isThree:'q',

        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        if (nextProps.TeacherDataArr.length !== 0){
            this.setState({
                TeacherArr:nextProps.TeacherDataArr,
            });
        }else {
            get({url:'query/coach_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TeacherArr:res.data,
                    });
                }
            });
        }
    };

    componentDidMount=function() {
        let history = this.props;
        if (history.TeacherDataArr.length !== 0){
            this.setState({
                TeacherArr:history.TeacherDataArr,
            });
        }else {
            get({url:'query/coach_query'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TeacherArr:res.data,
                    });
                    // setTimeout(() => {
                    //         this.setState({
                    //             loading: false,
                    //         });
                    //     },
                    //     100
                    // );
                }
            });
        }
        get({url:'query/train_inst_query'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    BelongToFirmArr:res.data,
                });
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllDataBtn(){
        get({url:'query/coach_query'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeacherArr:res.data,
                    TeacherInput:'',
                    isThree: this.state.isThree === 'q' ? 's' : 'q',
                });
            }
        });
    };


    AllClick = () => {
        this.setState({
            ThreeLevelLinkage: 'none',
            BelongToFirmToggleOn: true,
            BelongToFirmDisplay: 'none',
            TeacherLevelToggleOn: true,
            TeacherLevelDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //三级联动的逻辑处理
    ChildLinkClick=(event)=> {
        if (event.Type === '地址') {
            if (event.Title === '省') {
                get({url:'query/coach_addr_query/'+event.ValueP}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TeacherArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            } else if (event.Title === '市') {
                get({url:'query/coach_addr_query/'+event.ValueC}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TeacherArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            } else if (event.Title === '区') {
                get({url:'query/coach_addr_query/'+event.ValueA}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            TeacherArr:res.data,
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'查询无数据！',
                        });
                    }
                });
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }
        }
    };



    //训练中心所属机构的下拉菜单
    BelongToFirmDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            BelongToFirmToggleOn: !prevState.BelongToFirmToggleOn,
            BelongToFirmDisplay:prevState.BelongToFirmToggleOn ? 'block' : 'none',
            TeacherLevelToggleOn: true,
            TeacherLevelDisplay: 'none',
        }));
    };
    BelongToFirmDownNav=(item,index)=>{
        get({url:'query/coach_train_query/'+item.id}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeacherArr:res.data,
                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'查询无数据！',
                });
            }
        });
        this.setState({
            BelongToFirmName:item.id,
            BelongToFirmToggleOn: true,
            BelongToFirmDisplay: 'none',
        });
    };

    //教练员的等级特长的下拉菜单
    TeacherLevelDown(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            TeacherLevelToggleOn: !prevState.TeacherLevelToggleOn,
            TeacherLevelDisplay:prevState.TeacherLevelToggleOn ? 'block' : 'none',
            BelongToFirmToggleOn: true,
            BelongToFirmDisplay: 'none',
        }));
    };
    TeacherLevelDownNav=(item,index)=>{
        let IdtTypeId = null;
        switch(item){
            case '基础教练员':
                IdtTypeId = 1;
                break;
            case '虚拟民航特长教练员':
                IdtTypeId = 2;
                break;
            case '虚拟特技飞行教练员':
                IdtTypeId = 3;
                break;
            case '虚拟军航特长教练员':
                IdtTypeId = 4;
                break;
            case '虚拟民航高级教练员':
                IdtTypeId = 5;
                break;
            default:
                IdtTypeId = null;
        }
        get({url:'query/coach_level_query/'+IdtTypeId}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    TeacherArr:res.data,
                });
            }else {
                this.setState({
                    TeacherArr:[],
                });
            }
        });
        this.setState({
            TeacherLevelName:item,
            TeacherLevelToggleOn: true,
            TeacherLevelDisplay: 'none',
        });
    };




    //点击教练员姓名详情
    ActiveTeacherName=(item,index)=>{
        get({url:'reuse/user_id_info/'+item.user_id}).then((res) => {
            if(res.errno === '200'){
                let teacherUser = res.data[0];
                if (teacherUser.isshow !== 0){
                    let ActiveTeacherName = {
                        Inquire:'TeacherName',
                        Id:item.user_id,
                        DataArr:this.state.TeacherArr,
                    };
                    this.props.ActiveSeeDetails(ActiveTeacherName);
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'该教员未公开信息！',
                    });
                }
            }
        });
    };


    //搜索教练员的框
    TeacherInput(event){
        this.setState({
            TeacherInput: event.target.value,
        });
    };
    TeacherInputBtn(){
        let TeacherInput = this.state.TeacherInput;
        if (TeacherInput !== ''){
            get({url:'query/coach_query_name/'+TeacherInput}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        TeacherArr:res.data,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'InquirePackageBox'} style={{minHeight:window.innerHeight-430 > 450 ? window.innerHeight-430 :450,}}>
                    <div className={'InquireInputBox'} style={{position:'absolute',top:'-100px',right:0,}}>
                        <input className={'InquireInput'}
                               type={'text'}
                               name={'InquireInput'}
                               value={this.state.TeacherInput}
                               onChange={this.TeacherInput.bind(this)}
                               placeholder={'您可输入教员姓名以查询···'}
                               autoComplete={'off'}
                        />
                        <button className={'InquireInputBtn'} style={{float:'left',height:'35.2px',}} onClick={this.TeacherInputBtn.bind(this)}>搜索</button>
                    </div>
                    <div className={'InquireAllDataBtn clearfix'} onClick={this.AllDataBtn.bind(this)} style={{top:5,right:12,}}>
                        <div className={'InquireAllDataIco'}> </div>
                        <div className={'InquireAllDataWord'}>全部信息</div>
                    </div>
                    <div className={'InquirePackageHeaderBox'}>
                        <div className={'InquirePackageHeaderList'} style={{width:140,}}>教员姓名</div>
                        <div className={'InquirePackageHeaderList'} style={{width:320,}}>
                            <div className={'InquireHeaderSelect'}>
                                <div style={{height:26,}} onClick={this.BelongToFirmDown.bind(this)}>
                                    <div className={'InquireSelectWord'}>所属机构</div>
                                    {
                                        this.state.BelongToFirmToggleOn === true &&
                                        <Triangle Direction={'right'} Color={'#FFFFFF'}
                                                  Width={'9px'} Height={'6px'}
                                                  Top={'7px'} Right={'0'}
                                        />
                                    }
                                    {
                                        this.state.BelongToFirmToggleOn === false &&
                                        <Triangle Direction={'down'} Color={'#FFFFFF'}
                                                  Width={'6px'} Height={'9px'}
                                                  Top={'10px'} Right={'9px'}
                                        />
                                    }
                                </div>
                                <div className={'EquipmentDownNavBox'} style={{display:this.state.BelongToFirmDisplay,left:0,width:320,}}>
                                    {
                                        this.state.BelongToFirmArr.map((item,index) =>
                                            <div className={'EquipmentDownNav'}
                                                 key={index}
                                                 onClick={() => this.BelongToFirmDownNav(item,index)}
                                                 style={this.state.BelongToFirmName === item.id ? {backgroundColor:'#8DCEFD',width:316,} : {width:316,}}>
                                                {item.org_name}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'InquirePackageHeaderList clearfix'} style={{width:340,}}>
                            <div style={{display:'table',margin:'0 auto',}}>
                                <span style={{float:'left',}}>地区</span>
                                <div className={'InAddressThreeBox'} style={{width:'auto',}}>
                                    {
                                        this.state.isThree === 'q'&&
                                        <ThreeLevelLinkage
                                            ChildLinkClick={this.ChildLinkClick.bind(this)}
                                            type={'地址'}
                                            Width={296}
                                            Height={26}
                                            ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                        />
                                    }
                                    {
                                        this.state.isThree === 's'&&
                                        <ThreeLevelLinkage
                                            ChildLinkClick={this.ChildLinkClick.bind(this)}
                                            type={'地址'}
                                            Width={296}
                                            Height={26}
                                            ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'InquirePackageHeaderList'} style={{width:160,}}>
                            <div className={'InquireHeaderSelect'}>
                                <div style={{height:26,}} onClick={this.TeacherLevelDown.bind(this)}>
                                    <div className={'InquireSelectWord'}>教员特长</div>
                                    {
                                        this.state.TeacherLevelToggleOn === true &&
                                        <Triangle Direction={'right'} Color={'#FFFFFF'}
                                                  Width={'9px'} Height={'6px'}
                                                  Top={'7px'} Right={'0'}
                                        />
                                    }
                                    {
                                        this.state.TeacherLevelToggleOn === false &&
                                        <Triangle Direction={'down'} Color={'#FFFFFF'}
                                                  Width={'6px'} Height={'9px'}
                                                  Top={'10px'} Right={'9px'}
                                        />
                                    }
                                </div>
                                <div className={'EquipmentDownNavBox'} style={{display:this.state.TeacherLevelDisplay,left:0,width:160,}}>
                                    {
                                        this.state.TeacherLevelArr.map((item,index) =>
                                            <div className={'EquipmentDownNav'}
                                                 key={index}
                                                 onClick={() => this.TeacherLevelDownNav(item,index)}
                                                 style={this.state.TeacherLevelName === item ? {backgroundColor:'#8DCEFD',width:160,} : {width:160,}}>
                                                {item}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading === true&&
                        <div className={'loadEffect'}>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </div>
                    }
                    {
                        this.state.loading === false&&
                        <div className={'InquirePackageContentBox'} style={{minHeight:window.innerHeight-272 > 460 ? window.innerHeight-272 :460,maxHeight:window.innerHeight-264 > 450 ? 'calc(100vh - 264px)' : '450px'}}>
                            {
                                this.state.TeacherArr.map((item,index) =>
                                    <div className={'InquirePackageContentListBox'} key={index}>
                                        <div className={'InquirePackageContentList InquireListHover'} style={{width:140,}}>
                                            <div className={'InquirePaConLiWord'} style={{maxWidth:138,height:40,lineHeight:'40px',}}>
                                                <span className={'ClickDetails'} onClick={() => this.ActiveTeacherName(item,index)}>{item.user_name}</span>
                                            </div>
                                        </div>
                                        {
                                            item.name !== null &&
                                            <div className={'InquirePackageContentList'} style={{width:320,}}>{item.name}</div>
                                        }
                                        {
                                            item.name === null &&
                                            <div className={'InquirePackageContentList'} style={{width:320,}}>自由教练员</div>
                                        }
                                        {
                                            item.province !== ''&&
                                            <div className={'InquirePackageContentList'} style={{width:340,fontSize:12,}}>
                                                {
                                                    item.province === item.city ?
                                                        item.city+item.county :
                                                        item.province+item.city+item.county
                                                }
                                            </div>
                                        }
                                        {
                                            item.province === ''&&
                                            <div className={'InquirePackageContentList'} style={{width:340,fontSize:12,}}>未更新(修改)通讯地址信息</div>
                                        }
                                        <div className={'InquirePackageContentList'} style={{width:160,}}>{item.diploma_name}</div>
                                    </div>
                                )
                            }
                        </div>
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default InTeacher;
