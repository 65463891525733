import React, {Component} from 'react';
import eCharts from 'echarts/lib/echarts';
import 'echarts/lib/chart/radar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import './member.css';
import {post} from "../../ajax/tools";
import Triangle from "../round/Triangle";

let Num = function (num) {
    if (num === 10) {
        return '十'
    } else if (num === 1) {
        return '一'
    }
    const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const units = ['', '十', '百', '千', '万'];
    let result = '';
    let numStr = num.toString();
    for (let i = 0; i < numStr.length; i++) {
        let digit = parseInt(numStr.charAt(i));
        let unit = units[numStr.length - i - 1];
        if (digit === 0) {
            // 当前数字为0时不需要输出汉字，但需要考虑上一个数字是否为0，避免出现连续的零
            if (result.charAt(result.length - 1) !== '零') {
                result += '零';
            }
        } else {
            result += digits[digit] + unit;
        }
    }
    // 对于一些特殊的数字，如10、100等，需要在最前面加上“一”
    if (result.charAt(0) === '一') {
        result = result.substring(1, result.length);
    } else if (result.charAt(0) === '百') {
        result = '一' + result;
    } else if (result.charAt(0) === '千') {
        result = '一' + result;
    }
    return result;
};

class RadarECharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RadarInfo:{},

            tts_id:'',
            ttsIdArr:[],
            RadarFiveInfo:{},
            ttsIndex:'',

            TipsArr:[],


            TipsFive1:[],
            TipsFive2:[],
            TipsFive3:[],
            TipsFive4:[],
            TipsFive5:[],

            FiveLineMainArr:[1,2,3,4,5],


            TipsBrr:[],
            TipWord:'',


        };
    }






    UNSAFE_componentWillReceiveProps= (nextProps) => {
        this.setState({
            RadarInfo:nextProps.RadarInfo,
        });
        let sbData = nextProps.RadarInfo;
        // console.log(sbData)
        // 基于准备好的dom，初始化echarts实例
        // 非五边：竞速类
        if (sbData.num === 0){
            let myRadarChart = eCharts.init(document.getElementById('RadarMain'),<span> </span>, {height:360,width:500,});
            // 绘制图表
            myRadarChart.setOption({
                title: {
                    text: sbData.item_name+' 成绩分析',
                    left: 'center',
                    textStyle: {
                        color: '#2f4554',
                        marginBottom:'13px',
                        fontSize: 16,
                    }
                },
                legend: {
                    show:false,
                },
                radar: {
                    indicator: sbData.info,
                    shape: 'circle',
                    center: ['50%', '58%'],
                    radius: 140,
                    name: {
                        color:'#232323',
                        fontSize: 12,
                        fontWeight:'bold',
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(102, 102, 102, 0.5)'
                        }
                    },
                    silent: false ,
                    splitNumber: 4,
                    splitLine: {
                        lineStyle: {
                            color: [
                                'rgba(102, 102, 102, 0.2)',
                                'rgba(102, 102, 102, 0.4)',
                                'rgba(102, 102, 102, 0.6)',
                                'rgba(102, 102, 102, 0.8)',
                                'rgba(102, 102, 102, 1)'
                            ].reverse()
                        }
                    },
                    splitArea: {
                        show: false
                    },
                },
                series: [
                    {
                        type: 'radar',
                        data: [
                            {
                                symbol: 'circle',
                                symbolSize: 10,
                                value: sbData.value,
                                label: {
                                    show: true,
                                    formatter: function (params) {
                                        if (params.dimensionIndex === 3 || params.dimensionIndex === 4){
                                            return params.value+'%';
                                        }else {
                                            return params.value;
                                        }
                                    },
                                    color:'#222',
                                    position:'top',
                                    fontSize:15,
                                },
                                lineStyle: {
                                    type: 'solid'
                                },

                            },
                        ],
                        itemStyle: {
                            color: '#0488CA'
                        },
                        areaStyle: {
                            opacity: 0.3
                        }
                    },
                ]
            });





            // 非五边竞速类折线图
            post({
                url:'cloudprogram/stu_score_analy_chart_jingsu',
                data:{
                    item_id:sbData.item_id,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    // console.log(res.data)
                    let sb = res.data;
                    let s = [];
                    let b = [];
                    for(let i=0;i<sb.length;i++){
                        let q = i+1;
                        let p = sb[i].total_time;
                        s.push(q);
                        b.push(p);
                    }
                    let Line = {
                        LineX : s,
                        LineY : b,
                    };
                    if (document.getElementById('LineMain')){
                        let myLineChart = eCharts.init(document.getElementById('LineMain'),<span> </span>, {height:360,});
                        myLineChart.setOption({
                            title: {
                                text: sbData.item_name+' 成绩走势',
                                left: 'center',
                                subtext:'区域内（至多二十次）成绩',
                                textStyle: {
                                    color: '#2f4554',
                                    marginBottom:'10px',
                                    fontSize: 16,
                                },
                                subtextStyle:{
                                    color:'#777',
                                    fontSize: 11,
                                },
                            },
                            // 提示框
                            tooltip: {
                                show:true,
                                trigger: 'axis',
                                formatter:
                                    '第{b}次<br/>' +
                                    '<div style="float: left;width: 10px;height: 10px;border-radius: 50%;background-color: #0488CA;margin: 5.5px 4px 0 0;"> </div>' +
                                    '时间：{c}s',
                            },
                            legend: {
                                show:false,
                            },
                            grid: {
                                left: 'left',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: Line.LineX,
                                name:'区域内次数',
                                boundaryGap:false,
                            },
                            yAxis: {
                                type: 'value',
                                name:'成绩',
                            },
                            series: [
                                {
                                    type: 'line',
                                    data: Line.LineY,
                                    label: {
                                        show: false,
                                        formatter: function (params) {
                                            return Math.round(params.value);
                                        },
                                        color:'#000',
                                        position:'top',
                                        fontSize:10,
                                    },
                                    itemStyle: {
                                        color: '#0488CA',
                                        borderWidth:4,

                                    },
                                    areaStyle: {
                                        opacity: 0.1
                                    },
                                },
                            ]
                        });
                    }
                }
            });



            // 根据平均成绩获取提示信息
            let aSb = sbData.info;
            function sortId(a,b) {
                for(let i=0;i<a.length;i++){
                    let q = a[i].name;
                    if (q === b){
                        return a[i].value;
                    }
                }
            }
            post({
                url:'cloudprogram/show_score_reminder',
                data:{
                    item_id:sbData.item_id,
                    type_list: [
                        {
                            type:0,
                            value:sortId(aSb,'区域内平均成绩'),
                        },
                        {
                            type:1,
                            value:sortId(aSb,'历史最好成绩'),
                        },
                        {
                            type:2,
                            value:sortId(aSb,'区域内最好成绩'),
                        },
                        {
                            type:3,
                            value:sortId(aSb,'区域内成功率'),
                        },
                        {
                            type:4,
                            value:sortId(aSb,'区域内合规率'),
                        },
                    ],
                },
            }).then((res) => {
                if (res.errno === '200') {
                    let sb = res.data;
                    if (sb.length !== 0){
                        let result = '';
                        for (let i = 0; i < sb.length; i++) {
                            let sv = sb[i];
                            for (let k= 0; k < sv.length; k++) {
                                let a = sv[k].isdel;
                                if (a === 0){
                                    result += sv[k].content;
                                }
                            }
                        }
                        this.setState({
                            TipWord:result,
                            TipsArr:sb,
                        });
                    }else {
                        this.setState({
                            TipsArr:[],
                        });
                    }
                }else if (res.errno === '4502'){
                    window.location.href='/login';
                }else {
                    this.setState({
                        TipsArr:[],
                    });
                }
            });






            // 五边：得分类
        }else if (sbData.num === 1){
            let myRadarChart = eCharts.init(document.getElementById('RadarMain'),<span> </span>,{height:340,width:450,},);
            // 绘制图表
            myRadarChart.setOption({
                title: {
                    text: sbData.item_name+' 成绩分析',
                    left: 'center',
                    textStyle: {
                        color: '#2f4554',
                        marginBottom:'13px',
                        fontSize: 16,
                    }
                },
                legend: {
                    show:false,
                },
                radar: {
                    indicator: sbData.info,
                    shape: 'circle',
                    center: ['50%', '58%'],
                    radius: 120,
                    name: {
                        color:'#232323',
                        fontSize: 12,
                        fontWeight:'bold',
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(102, 102, 102, 0.5)'
                        }
                    },
                    silent: false ,
                    splitNumber: 4,
                    splitLine: {
                        lineStyle: {
                            color: [
                                'rgba(102, 102, 102, 0.2)',
                                'rgba(102, 102, 102, 0.4)',
                                'rgba(102, 102, 102, 0.6)',
                                'rgba(102, 102, 102, 0.8)',
                                'rgba(102, 102, 102, 1)'
                            ].reverse()
                        }
                    },
                    splitArea: {
                        show: false
                    },
                },
                series: [
                    {
                        type: 'radar',
                        data: [
                            {
                                symbol: 'circle',
                                symbolSize: 10,
                                value: sbData.value,
                                label: {
                                    show: true,
                                    formatter: function (params) {
                                        if (params.dimensionIndex === 3 || params.dimensionIndex === 4){
                                            return params.value+'%';
                                        }else {
                                            return params.value;
                                        }
                                    },
                                    color:'#222',
                                    position:'top',
                                    fontSize:12,
                                },
                                lineStyle: {
                                    type: 'solid'
                                },

                            },
                        ],
                        itemStyle: {
                            color: '#0488CA'
                        },
                        areaStyle: {
                            opacity: 0.3
                        }
                    },
                ]
            });








            // 五边竞的分类成绩折线图
            post({
                url:'cloudprogram/stu_score_analy_chart_defen',
                data:{
                    item_id:sbData.item_id,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    // console.log(res.data)
                    let sb = res.data;
                    let s = [];
                    let b = [];
                    for(let i=0;i<sb.length;i++){
                        let q = i+1;
                        let p = sb[i].total_score;
                        s.push(q);
                        b.push(p);
                    }
                    let Line = {
                        LineX : s,
                        LineY : b,
                    };
                    if (document.getElementById('FiveLineMain')){
                        let myLineChart = eCharts.init(document.getElementById('FiveLineMain'),<span> </span>, {height:340,});
                        myLineChart.setOption({
                            title: {
                                text: sbData.item_name+' 成绩走势',
                                left: 'center',
                                subtext:'区域内（至多二十次）成绩',
                                textStyle: {
                                    color: '#2f4554',
                                    marginBottom:'10px',
                                    fontSize: 16,
                                },
                                subtextStyle:{
                                    color:'#777',
                                    fontSize: 11,
                                },
                            },
                            // 提示框
                            tooltip: {
                                show:true,
                                trigger: 'axis',
                                formatter:
                                    '第{b}次<br/>' +
                                    '<div style="float: left;width: 10px;height: 10px;border-radius: 50%;background-color: #0488CA;margin: 5.5px 4px 0 0;"> </div>' +
                                    '分值：{c}',
                            },
                            legend: {
                                show:false,
                            },
                            grid: {
                                left: 'left',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: Line.LineX,
                                name:'区域内次数',
                                boundaryGap:false,
                                nameTextStyle:{
                                    fontSize:10,
                                },
                            },
                            yAxis: {
                                type: 'value',
                                name:'成绩',
                            },
                            series: [
                                {
                                    type: 'line',
                                    data: Line.LineY,
                                    label: {
                                        show: false,
                                        formatter: function (params) {
                                            return params.value;
                                        },
                                        color:'#000',
                                        position:'top',
                                        fontSize:11,
                                    },
                                    itemStyle: {
                                        color: '#0488CA',
                                        borderWidth:4,


                                    },
                                    areaStyle: {
                                        opacity: 0.1
                                    },
                                },
                            ]
                        });
                    }
                }
            });


            // 根据平均成绩获取提示信息
            let bSb = sbData.info;
            function sortFiveId(a,b) {
                for(let i=0;i<a.length;i++){
                    let q = a[i].name;
                    if (q === b){
                        return a[i].value;
                    }
                }
            }
            post({
                url:'cloudprogram/show_score_reminder',
                data:{
                    item_id:sbData.item_id,
                    type_list: [
                        {
                            type:0,
                            value:sortFiveId(bSb,'区域内平均成绩'),
                        },
                        {
                            type:1,
                            value:sortFiveId(bSb,'历史最好成绩'),
                        },
                        {
                            type:2,
                            value:sortFiveId(bSb,'区域内最好成绩'),
                        },
                        {
                            type:3,
                            value:sortFiveId(bSb,'区域内成功率'),
                        },
                        {
                            type:4,
                            value:sortFiveId(bSb,'区域内合规率'),
                        },
                    ],
                },
            }).then((res) => {
                if (res.errno === '200') {
                    let sb = res.data;
                    if (sb.length !== 0){
                        let result = '';
                        for (let i = 0; i < sb.length; i++) {
                            let sv = sb[i];
                            for (let k= 0; k < sv.length; k++) {
                                let a = sv[k].isdel;
                                if (a === 0){
                                    result += sv[k].content;
                                }
                            }
                        }
                        this.setState({
                            TipWord:result,
                            TipsArr:sb,
                        });
                    }else {
                        this.setState({
                            TipsArr:[],
                        });
                    }
                }else if (res.errno === '4502'){
                    window.location.href='/login';
                }else {
                    this.setState({
                        TipsArr:[],
                    });
                }
            });









            // 五边分析雷达图
            post({
                url:'cloudprogram/limits_stu_score_analy_pentagon_five',
                data:{
                    item_id:sbData.item_id,
                    type:2
                },
            }).then((res) => {
                if (res.errno === '200' && res.data.length !== 0) {
                    let data = res.data;
                    let a = [];
                    for(let i=0;i<data.length;i++){
                        let q = data[i].value;
                        let b = Math.floor(q*1000)/1000;
                        a.push(b);
                        data[i]['max'] = data[i].max_constraint;
                    }
                    let Radar = {
                        info : data,
                        value : a,
                    };
                    // console.log(sbData)
                    this.setState({RadarFiveInfo:Radar,});
                    if (document.getElementById('RadarMainFive')){
                        let myRadarChart = eCharts.init(document.getElementById('RadarMainFive'),<span> </span>,{height:340,},);
                        myRadarChart.setOption({
                            title: {
                                text: sbData.item_name+' 各边得分率分析',
                                left: 'center',
                                textStyle: {
                                    color: '#2f4554',
                                    marginBottom:'13px',
                                    fontSize: 16,
                                }
                            },
                            legend: {
                                show:false,
                            },
                            radar: {
                                indicator: Radar.info,
                                shape: 'circle',
                                center: ['50%', '55%'],
                                radius: 120,
                                name: {
                                    color:'#232323',
                                    fontSize: 12,
                                    fontWeight:'bold',
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: 'rgba(102, 102, 102, 0.5)'
                                    }
                                },
                                silent: false ,
                                splitNumber: 4,
                                splitLine: {
                                    lineStyle: {
                                        color: [
                                            'rgba(102, 102, 102, 0.2)',
                                            'rgba(102, 102, 102, 0.4)',
                                            'rgba(102, 102, 102, 0.6)',
                                            'rgba(102, 102, 102, 0.8)',
                                            'rgba(102, 102, 102, 1)'
                                        ].reverse()
                                    }
                                },
                                splitArea: {
                                    show: false
                                },
                            },
                            series: [
                                {
                                    type: 'radar',
                                    data: [
                                        {
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            value: Radar.value,
                                            label: {
                                                show: true,
                                                formatter: function (params) {
                                                    return params.value+'%';
                                                },
                                                color:'#222',
                                                position:'top',
                                                fontSize:12,
                                            },
                                            lineStyle: {
                                                type: 'solid'
                                            },

                                        },
                                    ],
                                    itemStyle: {
                                        color: '#f9713c'
                                    },
                                    areaStyle: {
                                        opacity: 0.3
                                    }
                                },
                            ]
                        });
                    }
                }else {
                    this.setState({
                        tts_id:'noData',
                    });
                }
            });


            // 五边得分类成绩折线图
            post({
                url:'cloudprogram/limits_stu_score_analy_pentagon_five_line_chart',
                data:{
                    item_id:sbData.item_id,
                },
            }).then((res) => {
                if (res.errno === '200') {
                    let LineFiveData = [];
                    let Average = [];
                    for(let i=0;i<res.data.length;i++){
                        let q = res.data[i];
                        let w = [];
                        let e = [];
                        let b = [];
                        let f = [];
                        for(let k=0;k<q.length;k++){
                            let t = k+1;
                            let y = q[k].sum_score;
                            let c = Math.floor(y*100)/100;
                            w.push(t);
                            e.push(c);
                            f.push(y);
                        }
                        b['X'] = w;
                        b['Va'] = e;
                        LineFiveData.push(b);
                        // 数组内部求和
                        function sum(arr) {
                            return arr.reduce(function(prev, curr){
                                return prev + curr;
                            });
                        }
                        let dsb = sum(f)/f.length
                        Average.push(dsb);
                    }
                    LineFiveData[0]['color'] = '#91cc75';
                    LineFiveData[1]['color'] = '#ee6666';
                    LineFiveData[2]['color'] = '#73c0de';
                    LineFiveData[3]['color'] = '#fac858';
                    LineFiveData[4]['color'] = '#5470c6';
                    for(let i=0;i<LineFiveData.length;i++){
                        if (document.getElementById('FiveLineMain'+i)){
                            let myLineChartFive = eCharts.init(document.getElementById('FiveLineMain'+i),<span> </span>, {height:340,});
                            myLineChartFive.setOption({
                                title: {
                                    text: sbData.item_name+' 第'+Num(i+1)+'边数据分析',
                                    left: 'center',
                                    subtext:'区域内（至多二十次）成绩',
                                    textStyle: {
                                        color: '#2f4554',
                                        marginBottom:'10px',
                                        fontSize: 16,
                                    },
                                    subtextStyle:{
                                        color:'#777',
                                        marginBottom:'10px',
                                        fontSize: 11,
                                    },
                                },
                                // 提示框
                                tooltip: {
                                    show:true,
                                    trigger: 'axis',
                                    formatter:
                                        '第{b}次<br/>' +
                                        '<div style="float: left;width: 10px;height: 10px;border-radius: 50%;background-color: '+LineFiveData[i].color+';margin: 5.5px 4px 0 0;"> </div>' +
                                        '分值：{c}',
                                },
                                legend: {
                                    show:false,
                                },
                                grid: {
                                    left: 'left',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                xAxis: {
                                    type: 'category',
                                    data: LineFiveData[i].X,
                                    name:'区域内次数',
                                    boundaryGap:false,
                                    nameTextStyle:{
                                        fontSize:10,
                                    },
                                },
                                yAxis: {
                                    type: 'value',
                                    name:'成绩',
                                },
                                series: [
                                    {
                                        type: 'line',
                                        data: LineFiveData[i].Va,
                                        label: {
                                            show: false,
                                            formatter: function (params) {
                                                return params.value+'%';
                                            },
                                            color:'#000',
                                            position:'bottom',
                                            fontSize:10,
                                        },
                                        itemStyle: {
                                            color: LineFiveData[i].color,
                                            borderWidth:4,

                                        },
                                        areaStyle: {
                                            opacity: 0.1
                                        },
                                    },
                                ]
                            });
                        }

                    }


                    // 根据平均成绩获取五边类提示信息
                    for(let j=0;j<Average.length;j++){
                        post({
                            url:'cloudprogram/show_score_reminder_five',
                            data:{
                                item_id:sbData.item_id,
                                average_best_score:Average[j],
                                type: 1,
                                pentagon: j+1,
                            },
                        }).then((res) => {
                            if (res.errno === '200') {
                                let sb = res.data;
                                if (sb.length !== 0){
                                    let a = [];
                                    for(let u=0;u<sb.length;u++){
                                        if (sb[u].isdel === 0 && sb[u].pentagon === j+1){
                                            a.push(sb[u]);
                                        }
                                    }

                                    this.setState({
                                        ['TipsFive'+(j+1)]:a,
                                    });
                                }else {
                                    this.setState({
                                        ['TipsFive'+(j+1)]:[],
                                    });
                                }
                            }else if (res.errno === '4502'){
                                window.location.href='/login';
                            }else {
                                this.setState({
                                    ['TipsFive'+(j+1)]:[],
                                });
                            }
                        });
                    }
                }
            });
        }
    };

    componentDidMount=function() {
        let history = this.props;
        this.setState({
            RadarInfo:history.RadarInfo,
        });
        // let sbData = history.RadarInfo;


        let vm = this;
        document.onclick=vm.AllClick.bind(this);//下拉菜单监听隐藏
    };




    AllClick = () => {
        this.props.RadarBackChild('关闭菜单');
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };




    TipsMainMore(){
        let sb = this.state.TipsArr;
        this.setState({
            TipsBrr:sb,
            PopUp:'全部分析',
        });
    }

    TipsFiveMainMore=(item,index)=>{
        let sb = this.state['TipsFive'+item];
        let dsb = [];
        dsb.push(sb);
        this.setState({
            TipsBrr:dsb,
            PopUp:'全部分析',
        });
    }











    CrossOut(){
        this.setState({
            TipsBrr:[],
            PopUp:'',
        });
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };




    render() {
        // console.log(this.state.TipsFive1)
        return (
            <div className={'EChartsBox'} style={{width:'100%',}}>
                <div className={'clearfix'} style={{width:'100%',}}>
                    <div id={'RadarMain'} style={this.state.RadarInfo.num === 0 ? {width:500,float:'left',margin:20,} : {width:450,float:'left',margin:20,}}> </div>


                    {
                        this.state.RadarInfo.num === 0&&
                        <div className={'RadarMainTipsBox'}>
                            {
                                this.state.TipsArr.length !== 0 && this.state.TipWord !== ''&&
                                <div>
                                    <div className={'TipsBoxTit'}>数据分析建议：</div>
                                    <div className={'TipsMainBox'}>
                                        {
                                            this.state.TipsArr.map((item,index) =>
                                                <div key={index}>
                                                    {
                                                        item.map((item2,index2) =>
                                                            <div key={index2}>
                                                                {
                                                                    item2.isdel === 0&&
                                                                    <div className={'TipsBoxCon'}>{item2.content}</div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className={'TipsMainMore'} onClick={this.TipsMainMore.bind(this)}>
                                        <span>全部分析</span>
                                        <Triangle Direction={'right'} Color={'#FB611D'}
                                                  Width={'12px'} Height={'7px'}
                                                  Top={'14px'} Right={'0'}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }


                    {
                        this.state.RadarInfo.num === 1&&
                        <div id={'RadarMainFive'} style={{width:450,float:'left',margin:20,}}> </div>
                    }

                </div>

                <div className={'RadarBorder'}> </div>

                {
                    this.state.RadarInfo.num === 0&&
                    <div id={'LineMain'} style={{width:900,float:'left',margin:20,}}> </div>
                }

                {
                    this.state.RadarInfo.num === 1&&
                    <div className={'FiveLineMainBox clearfix'}>
                        <div id={'FiveLineMain'} style={{width:656,float:'left',margin:20,}}> </div>
                        <div className={'FiveLineTipsBox'}>
                            {
                                this.state.TipsArr.length !== 0 && this.state.TipWord !== ''&&
                                <div>
                                    <div className={'TipsBoxTit'}>数据分析建议：</div>
                                    <div className={'TipsMainBox'} style={{height:290,}}>
                                        {
                                            this.state.TipsArr.map((item,index) =>
                                                <div key={index}>
                                                    {
                                                        item.map((item2,index2) =>
                                                            <div key={index2}>
                                                                {
                                                                    item2.isdel === 0&&
                                                                    <div className={'TipsBoxCon'}>{item2.content}</div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className={'TipsMainMore'} onClick={this.TipsMainMore.bind(this)} style={{height:30,width:95,}}>
                                        <span style={{lineHeight:'30px',fontSize:15,}}>全部分析</span>
                                        <Triangle Direction={'right'} Color={'#FB611D'}
                                                  Width={'12px'} Height={'7px'}
                                                  Top={'8.2px'} Right={'0'}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        {
                            this.state.FiveLineMainArr.map((item,index) =>
                                <div className={'FiveLineMainBox clearfix'} key={index}>
                                    <div id={'FiveLineMain'+index} style={{width:656,float:'left',margin:20,}}> </div>
                                    <div className={'FiveLineTipsBox'}>
                                        {
                                            this.state['TipsFive'+item].length !== 0 &&
                                            <div>
                                                <div className={'TipsBoxTit'}>第{Num(item)}边数据分析建议：</div>
                                                <div className={'TipsMainBox'} style={{height:290,}}>
                                                    {
                                                        this.state['TipsFive'+item].map((item2,index2) =>
                                                            <div key={index2}>
                                                                {
                                                                    item2.isdel === 0&&
                                                                    <div className={'TipsBoxCon'}>{item2.content}</div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className={'TipsMainMore'} onClick={() => this.TipsFiveMainMore(item,index)} style={{height:30,width:95,}}>
                                                    <span style={{lineHeight:'30px',fontSize:15,}}>全部分析</span>
                                                    <Triangle Direction={'right'} Color={'#FB611D'}
                                                              Width={'12px'} Height={'7px'}
                                                              Top={'8.2px'} Right={'0'}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                }
                {
                    this.state.PopUp === '全部分析' &&
                    <div className={'PopUpBoxBg'}>
                        <div className={'AddItemBox'} style={{width:700,height:600,}}>
                            <div className={'AddItemBigTitle'}>
                                <div>全部分析：</div>
                                <div className={'AddItemBackIco'} style={{fontSize:27,}} onClick={this.CrossOut.bind(this)}> </div>
                            </div>
                            <div className={'TipPopUpContent scrollBar scrollBarFox'}>
                                {
                                    this.state.TipsBrr.map((item,index) =>
                                        <div key={index}>
                                            {
                                                item.map((item2,index2) =>
                                                    <div key={index2}>
                                                        {
                                                            item2.isdel === 0&&
                                                            <div>{item2.content}</div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default RadarECharts;
